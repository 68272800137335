import React, { useState } from 'react'
import { transparentize } from 'polished'

import { ButtonTabs } from '@pulse-analytics/pulse-design-system'

import Color from 'frontend/utils/color'

import PolicyInfo from './PolicyInfo'

const POLICY_INFO = 'Policy Info'
// const QUALITY_OF_ACCESS = 'Quality of Access'

const COMPONENT_MAP = {
  [POLICY_INFO]: PolicyInfo,
  // [QUALITY_OF_ACCESS]: () => <div>qoa</div>,
}

const PolicyContent = ({ data, policyOptions, input, setInput }) => {
  const [selectedTab, setTab] = useState(POLICY_INFO)
  const Component = COMPONENT_MAP[selectedTab]

  return (
    <div style={{ width: '25%', overflow: 'auto' }}>
      <ButtonTabs
        tabsData={[POLICY_INFO]}
        onTabClick={setTab}
        selectedTab={selectedTab}
        tabStyle={{ margin: '8px 12px' }}
      />
      <div
        style={{ borderTop: `1px solid ${transparentize(0.9, Color.BLACK)}` }}
      >
        <Component
          data={data}
          policyOptions={policyOptions}
          input={input}
          setInput={setInput}
        />
      </div>
    </div>
  )
}

export default PolicyContent
