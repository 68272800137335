import React from 'react'
import { Link } from '@mui/material'

import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'

interface CustomLinkProps {
  text: string
  iconStyles: any
  link: string
}

const BASE_STYLE = {
  fontSize: '12px',
  fontWeight: 600,
  fontFamily: 'Inter',
  letterSpacing: '0.20000000298023224px',
  lineHeight: '18px',
  display: 'flex',
  alignItems: 'center',
}

export const CustomLink = ({ text, iconStyles, link }: CustomLinkProps) => {
  const { iconColor, ...styles } = iconStyles

  return (
    <Link
      href={link}
      underline="hover"
      sx={BASE_STYLE}
      rel="noopener noreferrer"
      target="_blank"
    >
      <CustomSvgIcon iconColor={iconColor} iconName="Link" styles={styles} />
      {text}
    </Link>
  )
}
