import React from 'react'
import { transparentize } from 'polished'
import format from 'date-fns/format'
import _ from 'lodash'

import {
  DISPLAY_CELL_TYPES,
  FILTER_TYPES,
  SORT_TYPES,
  EDIT_CELL_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'
import { MultiSelectFilter } from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'
import Icon from 'frontend/components/Icon'

import Color from 'frontend/utils/color'

import {
  BENEFIT_TYPE,
  LINK_STATUS,
  BOOK,
  APPROVAL_STATUS,
  PAYER,
  PRODUCT,
  DOC_TYPE,
  LINK_TYPE,
  LINK,
  LINK_STEPS,
  POLICY_SEARCH_WORD,
  LINK_NOTE,
  LAST_APPROVED_REVIEW_DATE,
  LAST_TDG_REVIEW_DATE,
  LAST_IDENTIFIED_CHANGE_DATE,
  ASSIGNED_POLARIS_USER_AUTH_IDS,
  PRODUCT_PAYER_POLICY_TRACKING_PROJECTS,
} from 'frontend/PayerPolicyLinkMgmt/v0/utils/enums'

import StatusTag from '../StatusTag'
import { POLICY_VIEW_MODAL, DELETE_LINK_MODAL } from '../PolicyLinkModals/util'
import { mapIdToLabelCellFormatter } from '../../../Orion/Organizations/Pathways/Positioning/utils/cellFormatterUtils'

const { BUTTON } = DISPLAY_CELL_TYPES
const { MULTI_SELECT } = FILTER_TYPES
const { STRING, TEXT_ARRAY, DISPLAY } = SORT_TYPES
const { TEXT_INPUT, SINGLE_SELECT_INPUT, MULTI_SELECT_INPUT } = EDIT_CELL_TYPES

export const dateFormatter = (value) => {
  return value ? format(new Date(value), 'yyyy-MM-dd') : value
}

export const getColConfig = ({
  isLoading,
  policyOptions,
  handleProductPolicyUpdate,
  handlePolicyLinkUpdate,
  setModalType,
}) => {
  if (isLoading) return []

  return [
    {
      Header: '',
      accessor: 'x',
      sticky: 'left',
      displayCellConfig: {
        type: BUTTON,
        style: {
          background: transparentize(0.9, Color.PRIMARY),
          borderRadius: 4,
          padding: 2,
          cursor: 'pointer',
        },
        label: (
          <Icon
            style={{ display: 'flex' }}
            width={14}
            height={14}
            iconName="view"
            color1={Color.PRIMARY}
            color2={Color.PRIMARY}
          />
        ),
        onClick: (props) => {
          const { policyLinkId, productPolicyId } = props
          setModalType({
            type: POLICY_VIEW_MODAL,
            policyLinkId,
            productPolicyId,
          })
        },
      },
      width: 50,
      disableSortBy: true,
    },
    {
      Header: 'Alert',
      accessor: 'shouldAlert',
      sortType: ({ original }) => (original.shouldAlert ? -1 : 1),
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      cellValueFormatter: (value) => {
        if (value) {
          return (
            <Icon
              style={{ display: 'flex' }}
              width={18}
              height={18}
              iconName="caution-solid"
              color1={'#FFCD1D'}
            />
          )
        } else {
          return '--'
        }
      },
      width: 100,
    },
    {
      Header: 'Link Status',
      accessor: LINK_STATUS,
      sortType: (a, b) => {
        const linkStatusLabelMap = policyOptions[LINK_STATUS]?.labelMap ?? {}

        let linkStatusA = a.original[LINK_STATUS]
        let linkStatusB = b.original[LINK_STATUS]

        if (linkStatusLabelMap[linkStatusA])
          linkStatusA = linkStatusLabelMap[linkStatusA]
        if (linkStatusLabelMap[linkStatusB])
          linkStatusB = linkStatusLabelMap[linkStatusB]

        if (linkStatusA > linkStatusB) return 1
        if (linkStatusB > linkStatusA) return -1

        return 0
      },
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      cellValueFormatter: (value) => {
        return <StatusTag value={value} />
      },
      width: 150,
    },
    {
      Header: 'New Policy Approval Status',
      accessor: APPROVAL_STATUS,
      sortType: (a, b) => {
        if (a.original[APPROVAL_STATUS] > b.original[APPROVAL_STATUS]) return 1
        if (b.original[APPROVAL_STATUS] > a.original[APPROVAL_STATUS]) return -1

        return 0
      },
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      width: 150,
      cellValueFormatter: (value) => {
        return (
          <StatusTag
            value={value}
            labelMap={policyOptions[APPROVAL_STATUS].labelMap}
          />
        )
      },
      editCellConfig: {
        type: SINGLE_SELECT_INPUT,
        updateFn: handleProductPolicyUpdate,
        options: policyOptions[APPROVAL_STATUS].options,
        validationConfig: {
          validationFn: (input) => Boolean(input),
          errorMessage: 'Field cannot be empty',
        },
      },
    },
    {
      Header: 'Projects',
      accessor: PRODUCT_PAYER_POLICY_TRACKING_PROJECTS,
      sortType: TEXT_ARRAY,
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      width: 200,
      labelMap: policyOptions[PRODUCT_PAYER_POLICY_TRACKING_PROJECTS].labelMap,
      editCellConfig: {
        type: MULTI_SELECT_INPUT,
        updateFn: handleProductPolicyUpdate,
        options: policyOptions[PRODUCT_PAYER_POLICY_TRACKING_PROJECTS].options,
      },
    },
    {
      Header: 'Assignee',
      accessor: ASSIGNED_POLARIS_USER_AUTH_IDS,
      sortType: TEXT_ARRAY,
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      width: 200,
      labelMap: policyOptions[ASSIGNED_POLARIS_USER_AUTH_IDS].labelMap,
      editCellConfig: {
        type: MULTI_SELECT_INPUT,
        updateFn: handleProductPolicyUpdate,
        options: policyOptions[ASSIGNED_POLARIS_USER_AUTH_IDS].options,
      },
    },
    {
      Header: 'Payer',
      accessor: PAYER,
      sortType: DISPLAY,
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      cellValueFormatter: (value) =>
        mapIdToLabelCellFormatter(value, policyOptions[PAYER].labelMap),
      editCellConfig: {
        type: SINGLE_SELECT_INPUT,
        updateFn: handlePolicyLinkUpdate,
        options: policyOptions[PAYER].options,
        validationConfig: {
          validationFn: (input) => {
            return Boolean(input)
          },
          errorMessage: 'Field cannot be empty',
        },
      },
      width: 200,
    },
    {
      Header: 'Book',
      accessor: BOOK,
      sortType: STRING,
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      labelMap: policyOptions[BOOK].labelMap,
      editCellConfig: {
        type: SINGLE_SELECT_INPUT,
        updateFn: handlePolicyLinkUpdate,
        options: policyOptions.book.options,
        validationConfig: {
          validationFn: (input) => Boolean(input),
          errorMessage: 'Field cannot be empty',
        },
      },
      width: 200,
    },
    {
      Header: 'Product',
      accessor: PRODUCT,
      sortType: DISPLAY,
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      cellValueFormatter: (value) =>
        mapIdToLabelCellFormatter(value, policyOptions[PRODUCT].labelMap),
      width: 200,
    },
    {
      Header: 'Benefit Type',
      accessor: BENEFIT_TYPE,
      sortType: DISPLAY,
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      cellValueFormatter: (value) =>
        mapIdToLabelCellFormatter(value, policyOptions[BENEFIT_TYPE].labelMap),
      editCellConfig: {
        type: SINGLE_SELECT_INPUT,
        updateFn: handlePolicyLinkUpdate,
        options: policyOptions[BENEFIT_TYPE].options,
        validationConfig: {
          validationFn: (input) => Boolean(input),
          errorMessage: 'Field cannot be empty',
        },
      },
      width: 200,
    },
    {
      Header: 'Document Type',
      accessor: DOC_TYPE,
      sortType: DISPLAY,
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      cellValueFormatter: (value) =>
        mapIdToLabelCellFormatter(value, policyOptions[DOC_TYPE].labelMap),
      editCellConfig: {
        type: SINGLE_SELECT_INPUT,
        updateFn: handlePolicyLinkUpdate,
        options: policyOptions[DOC_TYPE].options,
        validationConfig: {
          validationFn: (input) => Boolean(input),
          errorMessage: 'Field cannot be empty',
        },
      },
      width: 200,
    },
    {
      Header: 'Link Type',
      accessor: LINK_TYPE,
      sortType: DISPLAY,
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      cellValueFormatter: (value) =>
        mapIdToLabelCellFormatter(value, policyOptions[LINK_TYPE].labelMap),
      width: 200,
    },
    {
      Header: 'Policy Starting Link',
      accessor: LINK,
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      editCellConfig: {
        type: TEXT_INPUT,
        updateFn: handleProductPolicyUpdate,
        validationConfig: {
          validationFn: (input) => {
            if (_.isEmpty(input)) {
              return true
            } else {
              try {
                new URL(input)
                return true
              } catch {
                return false
              }
            }
          },
          errorMessage: 'Link is not a valid URL',
        },
      },
      width: 200,
    },
    {
      Header: 'Link Steps',
      accessor: LINK_STEPS,
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      editCellConfig: {
        type: TEXT_INPUT,
        updateFn: handleProductPolicyUpdate,
      },
      width: 200,
    },
    {
      Header: 'Search Term',
      accessor: POLICY_SEARCH_WORD,
      sortType: STRING,
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      editCellConfig: {
        type: TEXT_INPUT,
        updateFn: handleProductPolicyUpdate,
      },
      width: 200,
    },
    {
      Header: 'Link Notes',
      accessor: LINK_NOTE,
      sortType: STRING,
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      editCellConfig: {
        type: TEXT_INPUT,
        updateFn: handleProductPolicyUpdate,
      },
      width: 200,
    },
    {
      Header: 'Last Updated by Payer',
      accessor: LAST_APPROVED_REVIEW_DATE,
      sortType: STRING,
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      width: 200,
      cellValueFormatter: (value) => dateFormatter(value),
    },
    {
      Header: 'Last Updated by TDG',
      accessor: LAST_TDG_REVIEW_DATE,
      sortType: STRING,
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      width: 200,
      cellValueFormatter: (value) => dateFormatter(value),
    },
    {
      Header: 'Last Change Date (Scraper)',
      accessor: LAST_IDENTIFIED_CHANGE_DATE,
      sortType: STRING,
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      width: 200,
      cellValueFormatter: (value) => dateFormatter(value),
    },
    {
      Header: 'S3 Document Link',
      accessor: 'policy_doc_link',
      sortType: STRING,
      Filter: MultiSelectFilter,
      filter: MULTI_SELECT,
      width: 200,
    },
    {
      Header: 'Delete',
      accessor: 'y',
      displayCellConfig: {
        style: {
          background: transparentize(0.9, Color.PRIMARY),
          color: Color.PRIMARY,
          fontSize: 11,
          borderRadius: 4,
          padding: 2,
        },
        type: BUTTON,
        label: (
          <>
            <Icon
              style={{ display: 'flex', margin: '0 2px' }}
              width={10}
              height={10}
              iconName="delete"
              color1={Color.PRIMARY}
            />
            <span style={{ margin: '0 2px' }}>Delete</span>
          </>
        ),
        onClick: (props) => {
          const { policyLinkId, productPolicyId } = props
          setModalType({
            type: DELETE_LINK_MODAL,
            policyLinkId,
            productPolicyId,
          })
        },
      },
      width: 100,
      disableSortBy: true,
    },
  ]
}
