import React, { useState } from 'react'
import styled from '@emotion/styled'

import DataTable from 'frontend/components/DataTable'
import Header from 'frontend/components/Header'
import ExportExcelButton from 'frontend/components/ExportExcelButton'
import PathwaysModal from 'frontend/components/BusinessObjectModal/PathwaysModal'
import PathwaysModalButton from 'frontend/components/BusinessObjectModal/PathwaysModal/PathwaysModalButton'
import createButtonStyle from 'frontend/components/BusinessObjectModal/PeopleModal/createButtonStyle'
import Icon from 'frontend/components/Icon'

import Color from 'frontend/utils/color'
import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'

import { usePathwaysInfluencerData } from 'frontend/hooks/usePathwaysInfluencersData'

import getPathwaysAccountsInjectedColumns from './utils/getPathwaysAccountsInjectedColumns'
import getExportData from './utils/getExportData'

const PAGE_TITLE = 'Pathways Accounts'

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const TitleSection = styled.section({
  display: 'flex',
  justifyContent: 'space-between',
  padding: Spacing.S7,
  alignItems: 'center',
})

const ButtonSection = styled.section({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: `0 ${Spacing.S7}`,
  alignItems: 'center',
})

const buttonStyle = {
  padding: `${Spacing.S2} ${Spacing.S3}`,
  margin: `${Spacing.S4} ${Spacing.S4}`,
}

const Accounts = () => {
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  const { pathwaysOrganizations } = usePathwaysInfluencerData()
  const {
    data: pathwaysOrganizationsData,
    loading: isPathwaysOrganizationsLoading,
  } = pathwaysOrganizations

  const pathwaysAccountsColumns = getPathwaysAccountsInjectedColumns(
    setSelectedRowData
  )

  const exportData = getExportData(pathwaysOrganizationsData)

  const closeHandler = () => {
    setSelectedRowData(null)
  }

  return (
    <Container>
      <TitleSection>
        <Header header={PAGE_TITLE} headerStyle={{ ...FontSpace.FS5 }} />
        <ButtonSection>
          <PathwaysModalButton buttonStyle={(createButtonStyle, buttonStyle)}>
            <Icon
              iconName="add"
              color1={Color.WHITE}
              width={16}
              style={{ marginRight: 8 }}
            />
            Create Pathways Account
          </PathwaysModalButton>
          <ExportExcelButton
            data={exportData}
            filename={`PathwaysAccounts`}
            sheetName={'Pathways Accounts'}
            isDisabled={!exportData.length}
          />
        </ButtonSection>
      </TitleSection>

      {isCreateModalOpen && (
        <PathwaysModal closeModal={() => setIsCreateModalOpen(false)} />
      )}

      {selectedRowData && (
        <PathwaysModal
          entityId={selectedRowData.id}
          closeModal={closeHandler}
        />
      )}

      <DataTable
        isLoading={isPathwaysOrganizationsLoading}
        data={pathwaysOrganizationsData}
        columns={pathwaysAccountsColumns}
      />
    </Container>
  )
}

export default Accounts
