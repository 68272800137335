import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { useRewriteSearchString } from 'frontend/hooks'

import { useModalMap } from './useModal'

const PolicyLinkModals = () => {
  const { search } = useLocation()
  const rewriteSearchString = useRewriteSearchString()
  const modalMap = useModalMap()

  const searchParams = queryString.parse(search)
  if (!searchParams?.modal) return null

  const Modal = modalMap[searchParams.modal]
  if (!Modal) return null

  const closeModal = () => {
    rewriteSearchString({
      modal: undefined,
      policyId: undefined,
      productId: undefined,
      view: undefined,
    })
  }

  return <Modal closeModal={closeModal} searchParams={searchParams} />
}

export default PolicyLinkModals
