export const schemaV1 = `
  id
  payer
  book
  doc_type
  benefit_type
  document_tags
  created_at
  updated_at
`
