import gql from 'graphql-tag'

export const PUSH_TEAM_USER_MARKET_BASKET_SUBSCRIPTIONS = gql`
  mutation PushUserMarketBasketSubsToDevAndProd($input: PushUserSubsToDevAndProdInput!) {
    pushUserMarketBasketSubsToDevAndProd(input: $input)
  }
`

export const SUBSCRIBE_TEAM_TO_MARKET_BASKET = gql`
  mutation SubscribeTeamToMarketBasket($input: SubscribeTeamToMarketBasketInput!) {
    subscribeTeamToMarketBasket(input: $input) {
      id
      team
      market_basket
      created_at
      updated_at
    }
  }
`

export const UNSUBSCRIBE_TEAM_TO_MARKET_BASKET = gql`
  mutation UnsubscribeTeamToMarketBasket($input: UnsubscribeTeamToMarketBasketInput!) {
    unsubscribeTeamToMarketBasket(input: $input) {
      id
      team
      market_basket
      created_at
      updated_at
    }
  }
`

export const SUBSCRIBE_TEAM_TO_MARKET_BASKET_CATEGORY = gql`
  mutation SubscribeTeamToMarketBasketCategory($input: SubscribeTeamToMarketBasketCategoryInput!) {
    subscribeTeamToMarketBasketCategory(input: $input) {
      id
      market_basket_survey_category
      market_basket_subscription
      created_at
      updated_at
    }
  }
`

export const UNSUBSCRIBE_TEAM_TO_MARKET_BASKET_CATEGORY = gql`
  mutation UnsubscribeTeamToMarketBasketCategory($input: UnsubscribeTeamToMarketBasketCategoryInput!) {
    unsubscribeTeamToMarketBasketCategory(input: $input) {
      id
      market_basket_survey_category
      market_basket_subscription
      created_at
      updated_at
    }
  }
`

export const PUSH_TEAM_USER_REGIONS = gql`
  mutation PushUserRegionsToDevAndProd($input: PushUserRegionsToDevAndProdInput!) {
    pushUserRegionsToDevAndProd(input: $input)
  }
`
