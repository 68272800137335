import React from 'react'

import { Dialog, Spinner } from 'frontend/components'
import { usePayerPolicyDocument } from 'frontend/entity-hooks'
import { useQueryString } from 'frontend/hooks'

import PDFViewer from './PDFViewer'

const InnerContainer = () => {
  const { payer_policy_document_id } = useQueryString()

  const { isLoading } = usePayerPolicyDocument({
    queryInput: { id: payer_policy_document_id }
  })

  if (isLoading) return (
    <Dialog
      contentStyle={{
        alignItems: 'center',
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center'
      }}
      contentWrapperStyle={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Spinner />
    </Dialog>
  )

  return <PDFViewer />
}

export default InnerContainer
