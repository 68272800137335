import {
  IndicationsPanelListHeader,
  IndicationsPanelListItem,
} from './IndicationsPanel'

import { RegimensPanelListHeader, RegimensPanelListItem } from './RegimensPanel'

interface PanelInjectables {
  indications: any
  regimens: any
}

const panelHeight = 'calc(100vh - 40px)'

const getPanels = ({ indications, regimens }: PanelInjectables) => [
  {
    data: indications.data,
    loading: indications.loading,
    error: indications.error,
    searchParamConfig: {
      searchParam: 'indicationId',
      searchParamKey: '_id',
    },
    listWrapperStyle: {
      height: panelHeight,
      minHeight: panelHeight,
      maxHeight: panelHeight,
    },
    listHeaderConfig: {
      ListHeader: IndicationsPanelListHeader,
    },
    listConfig: {
      ListItem: IndicationsPanelListItem,
    },
  },
  {
    data: regimens.data,
    searchParamConfig: {
      searchParam: 'regimenId',
      searchParamKey: '_id',
    },
    listWrapperStyle: {
      height: panelHeight,
      minHeight: panelHeight,
      maxHeight: panelHeight,
    },
    listHeaderConfig: {
      ListHeader: RegimensPanelListHeader,
    },
    listConfig: {
      ListItem: RegimensPanelListItem,
    },
  },
]

export default getPanels
