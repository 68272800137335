import React, { Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'

import { Client } from './Client'
import { ClientSource } from './ClientSource'
import { DefaultLandingPage } from './DefaultLandingPage'
import { EmailAddress } from './EmailAddress'
import { EmailSubscriptions } from './EmailSubscriptions'
import { FirstName } from './FirstName'
import { LastName } from './LastName'
import { OncologyKdmToolPermissions } from './OncologyKdmToolPermissions'
import { Password } from './Password'
import { PrimaryTeam } from './PrimaryTeam'
import { Teams } from './Teams'
import { Username } from './Username'

interface Props {
  fieldState: any
  clientSourceOptions: any
  onChange: any
  setFieldState: Dispatch<SetStateAction<any>>
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 36px 12px 36px',
})

export const UserForm = ({
  fieldState,
  clientSourceOptions,
  onChange,
  setFieldState,
}: Props) => {
  return (
    <Wrapper>
      <Client onChange={onChange} />
      <ClientSource options={clientSourceOptions} onChange={onChange} />
      <FirstName onChange={onChange} />
      <LastName onChange={onChange} />
      <EmailAddress onChange={onChange} />
      <Username onChange={onChange} />
      <Password fieldState={fieldState} onChange={onChange} />
      <EmailSubscriptions onChange={onChange} />
      <Teams fieldState={fieldState} setFieldState={setFieldState} />
      <PrimaryTeam fieldState={fieldState} onChange={onChange} />
      <DefaultLandingPage onChange={onChange} />
      <OncologyKdmToolPermissions fieldState={fieldState} onChange={onChange} />
    </Wrapper>
  )
}
