import React from 'react'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'
import { transparentize, mix } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons'
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import '../../TreatmentPlansTabContent/sortableContainerStyles.css'
import { Colors, Spacing } from '../../../../utils/pulseStyles'
import { Span } from '../../TreatmentPlansTabContent/styledComponents'
import styled from '@emotion/styled'
import {
  IndicationPanelContainer,
  ListHeader,
  ActiveRow,
  InactiveRow,
  UnorderedList,
  ListRow,
} from '../../TreatmentPlansTabContent/styledComponents'
import _ from 'lodash'

const DragHandle = sortableHandle(() => (
  <span
    style={{
      padding: `${Spacing.SMALL} ${Spacing.NORMAL}`,
      opacity: 0.3,
      fontSize: 16,
    }}
  >
    <FontAwesomeIcon icon={faArrowsAltV} color={Colors.BLACK} />
  </span>
))

const SWITCH_COLOR = Colors.GREEN

const PhoenixSwitch = withStyles({
  switchBase: {
    color: mix(0.4, Colors.BLACK, Colors.WHITE),
    '&$checked': {
      color: SWITCH_COLOR,
    },
    '&$checked + $track': {
      backgroundColor: SWITCH_COLOR,
    },
  },
  checked: {},
  track: {
    backgroundColor: transparentize(0.7, Colors.BLACK),
  },
})(Switch)

const SortableItem = sortableElement(
  ({ book, disableBook, selectedBookId, selectBook }) => {
    const isSelected = book._id === selectedBookId
    return (
      <ListRow
        isSelected={isSelected}
        isActive
        onClick={() => selectBook(book)}
      >
        <div>
          <DragHandle />
          <Span isSelected={isSelected}>{book.name}</Span>
        </div>
        <PhoenixSwitch
          checked
          value={book._id}
          onChange={() => disableBook(book)}
        />
      </ListRow>
    )
  }
)

const SortableContainer = sortableContainer(({ children }) => {
  return <UnorderedList>{children}</UnorderedList>
})

const SelectedBook = styled.span({
  color: Colors.PRIMARY,
  textTransform: 'normal',
})

const BooksPanel = ({
  selectedAccountId,
  baseAccounts,
  enabledBooks,
  disabledBooks,
  selectBook,
  setStagedAccounts,
  selectedBookId,
  enabledAccounts,
}) => {
  const copyAccount = _.cloneDeep(
    baseAccounts.find(({ _id }) => _id === selectedAccountId) || {
      books: [],
    }
  )
  let targetAccount = enabledAccounts.find(({ _id }) => _id === copyAccount._id)
  const targetAccountIndex = enabledAccounts.findIndex(
    ({ _id }) => _id === copyAccount._id
  )
  const isSelectedAccountEnable = Boolean(targetAccount)

  const enableBook = (book) => {
    const copyBook = _.cloneDeep(book)
    const bookToAdd = { _id: copyBook._id, name: copyBook.name }
    selectBook(bookToAdd)
    if (!isSelectedAccountEnable) {
      const newAccount = {
        _id: copyAccount._id,
        name: copyAccount.organization,
        slug: copyAccount.slug,
        books: [bookToAdd],
      }
      enabledAccounts = enabledAccounts.concat([newAccount])
    } else {
      if (targetAccount.hasOwnProperty('books')) {
        targetAccount.books = targetAccount.books.concat([bookToAdd])
      } else {
        // this is for old payer save in database
        enabledAccounts[targetAccountIndex].books = [bookToAdd]
      }
    }
    setStagedAccounts(enabledAccounts)
  }
  const disableBook = (book) => {
    const targetBookId = targetAccount.books.findIndex(
      ({ _id }) => _id === book._id
    )
    targetAccount.books.splice(targetBookId, 1)
    setStagedAccounts(enabledAccounts)
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const [removedItem] = enabledBooks.splice(oldIndex, 1)
    enabledBooks.splice(newIndex, 0, removedItem)

    targetAccount.books = enabledBooks
    setStagedAccounts(enabledAccounts)
  }
  return (
    <>
      <IndicationPanelContainer>
        <ListHeader>
          Book of Business /{' '}
          <SelectedBook>{copyAccount.organization} </SelectedBook>
        </ListHeader>
        <ActiveRow>ACTIVE ({enabledBooks.length})</ActiveRow>
        <SortableContainer
          onSortEnd={onSortEnd}
          helperClass="sortableHelper"
          useDragHandle
        >
          {enabledBooks.map((book, index) => (
            <SortableItem
              key={book._id}
              index={index}
              book={book}
              disableBook={disableBook}
              selectedBookId={selectedBookId}
              selectBook={selectBook}
            />
          ))}
        </SortableContainer>
        <InactiveRow>INACTIVE ({disabledBooks.length})</InactiveRow>
        <UnorderedList>
          {disabledBooks.map((book) => {
            const isSelected = book._id === selectedBookId
            return (
              <ListRow
                isSelected={isSelected}
                key={book._id}
                onClick={() => selectBook(book)}
              >
                <Span>{book.name}</Span>
                <PhoenixSwitch
                  checked={false}
                  value={book._id}
                  onChange={() => enableBook(book)}
                />
              </ListRow>
            )
          })}
          <div style={{ marginTop: '25px' }} />
        </UnorderedList>
      </IndicationPanelContainer>
    </>
  )
}

export default BooksPanel
