import { useMutation } from '@apollo/client'

import { UseMutationOptions } from './../interfaces'
import { getMutationOptions } from './getMutationOptions'

export const useCreate = ({
  tag,
  readTag,
  variables,
}: UseMutationOptions) => {
  return useMutation(tag, getMutationOptions(readTag, variables))
}
