import React from 'react'
import Select from 'react-select'
import _ from 'lodash'

import getUniqueFilterOptions from './utils/getUniqueFilterOptions'
import getMappedFilterOptions from './utils/getMappedFilterOptions'
import getSelectedFilterOptions from './utils/getSelectedFilterOptions'

import { customTableSelectStyles } from './customTableSelectStyles'

const getHandleFilterOnChange = (setFilter) => (option) => {
  if (_.isEmpty(option)) {
    setFilter(undefined)
    return
  }

  const options = _.isArray(option) ? option : [option]
  setFilter(options.map(({ value }) => value))

  return option
}

const HydratedRefsMultiSelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
  labelMap,
}) => {
  const options = React.useMemo(() => {
    return getUniqueFilterOptions(id, preFilteredRows)
  }, [id, preFilteredRows])

  const mappedFilterOptions = getMappedFilterOptions(options, labelMap)

  const selectedOptions = getSelectedFilterOptions(filterValue, labelMap)

  const handleFilterOnChange = getHandleFilterOnChange(setFilter)

  return (
    <Select
      isMulti
      value={selectedOptions}
      options={mappedFilterOptions}
      styles={customTableSelectStyles}
      onChange={handleFilterOnChange}
    />
  )
}

export default HydratedRefsMultiSelectColumnFilter
