import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'

import Spinner from 'frontend/components/Spinner'
import {
  Label,
  LabelText,
  SectionTitle,
} from 'frontend/Orion/shared/AccountModals/AccountModalButton/styledAccountModalButtonComponents'
import { SingleActionDialog } from 'frontend/components/Dialog'
import Input from 'frontend/components/Input'

import { GET_PAYER_ORGANIZATIONS } from 'frontend/api/queries'
import { UPDATE_PAYER_ORGANIZATION } from 'frontend/api/mutations'

import getIndexByBinarySearch from 'frontend/utils/getIndexByBinarySearch'

import DeletePayerAccountForm from './DeletePayerAccountForm'

const editPayerAccountsFormColumnsStyles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
}

const PAGE_HEADER = 'Edit Payer Account'

const updateCacheWithSortedPayersAccounts = (
  cache,
  { data: { updatePayerOrganization } }
) => {
  const { payerOrganizations } = cache.readQuery({
    query: GET_PAYER_ORGANIZATIONS,
  })

  const sortedPayerOrganizations = [...payerOrganizations].filter(
    ({ _id }) => _id !== updatePayerOrganization._id
  )
  const newSortedAccountIndex = getIndexByBinarySearch({
    data: sortedPayerOrganizations,
    newDatum: updatePayerOrganization,
    accessor: 'organization',
  })
  sortedPayerOrganizations.splice(
    newSortedAccountIndex,
    0,
    updatePayerOrganization
  )
  cache.writeQuery({
    query: GET_PAYER_ORGANIZATIONS,
    data: {
      payerOrganizations: sortedPayerOrganizations,
    },
  })
}

const EditPayerAccountForm = ({
  selectedPayerOrganizationData,
  closeHandler,
}) => {
  const {
    _id,
    slug,
    type,
    organization,
    organizationTiny,
  } = selectedPayerOrganizationData

  const [form, setForm] = useState({
    slug,
    organization,
    organizationTiny,
  })

  const handleChange = ({ name, value }) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const input = {
    _id,
    type,
    ...form,
  }

  const [updatePayerAccount, { loading: isPayerAccountLoading }] = useMutation(
    UPDATE_PAYER_ORGANIZATION,
    {
      variables: { input },
      update: updateCacheWithSortedPayersAccounts,
      onError: alert,
      onCompleted: () => {
        closeHandler()
      },
    }
  )

  return (
    <SingleActionDialog
      header={PAGE_HEADER}
      submitText={PAGE_HEADER}
      submitHandler={updatePayerAccount}
      cancelHandler={closeHandler}
    >
      {isPayerAccountLoading ? (
        <Spinner />
      ) : (
        <div style={editPayerAccountsFormColumnsStyles}>
          <div
            style={{
              display: 'flex',
              padding: '30px 30px',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <SectionTitle>Payer Account Info</SectionTitle>

            <Label>
              <LabelText>Name</LabelText>

              <Input
                name="organization"
                type="text"
                onChange={handleChange}
                value={form.organization}
              />
            </Label>
            <Label>
              <LabelText>Short Name</LabelText>
              <Input
                name="organizationTiny"
                type="text"
                onChange={handleChange}
                value={form.organizationTiny}
              />
            </Label>
            <Label>
              <LabelText> Slug </LabelText>
              <Input
                name="slug"
                type="text"
                onChange={handleChange}
                value={form.slug}
              />
            </Label>

            <DeletePayerAccountForm
              accountId={_id}
              closeHandler={closeHandler}
            />
          </div>
        </div>
      )}
    </SingleActionDialog>
  )
}

EditPayerAccountForm.propTypes = {
  selectedPayerOrganizationData: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
}

export default EditPayerAccountForm
