import _ from 'lodash'

export const formatUpdateInput = (
  providerKdmDatum: any,
  personData: any,
  providersOptions: any,
  isLoading: boolean
) => {
  if (isLoading || !providerKdmDatum) return null
  if (_.isEmpty(providersOptions)) return null
  if (_.isEmpty(personData) || !personData[0]) return null

  const formattedInput = {
    start_date: providerKdmDatum?.start_date,
    end_date: providerKdmDatum?.end_date,
    person: personData[0].full_name,
    provider_organization: providerKdmDatum.provider_organization.name,
    id: providerKdmDatum?.id,
  }

  return formattedInput
}
