import React, { Dispatch, SetStateAction } from 'react'

import { Checkbox } from './Checkbox'

interface Props {
  selectedTeams: [any]
  clientTeams: any
  setFieldState: Dispatch<SetStateAction<any>>
}

export const CheckboxList = ({
  clientTeams,
  selectedTeams,
  setFieldState,
}: Props) => {
  if (!clientTeams) return null

  return (
    <>
      {clientTeams.map((team: any) => (
        <Checkbox
          team={team}
          selectedTeams={selectedTeams}
          setFieldState={setFieldState}
        />
      ))}
    </>
  )
}
