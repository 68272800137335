import gql from 'graphql-tag'

const GET_PATHWAYS_ORGANIZATIONS_HISTORY = gql`
  query GetPathwaysOrganizationsHistory($input: HistoryInput!) {
    pathwaysOrganizationsHistory(input: $input) {
      entity_name
      changed_by {
        email
        auth0_id
        name
        username
      }
      operation
      changes
      timestamp
    }
  }
`

export default GET_PATHWAYS_ORGANIZATIONS_HISTORY
