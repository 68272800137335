import React from 'react'
import styled from '@emotion/styled'

import SidebarHeader from './SidebarHeader'
import PolicyLinkTable from './PolicyLinkTable'

import usePayerPolicyLinkMgmtData from './usePayerPolicyLinkMgmtData'
import PolicyLinkModals from './PolicyLinkModals'

const ContentContainer = styled.div({
  width: 'calc(100vw - 104px)',
})

const HeaderText = styled.h1({
  fontSize: 20,
  lineHeight: '28px',
  letterSpacing: '-0.2',
  padding: '24px 12px 12px',
})

const PayerPolicyLink = () => {
  const {
    data,
    isLoading,
    policyOptions,
    updateConfig,
  } = usePayerPolicyLinkMgmtData()

  return (
    <div style={{ display: 'flex' }}>
      <SidebarHeader />
      <ContentContainer>
        <HeaderText>Payer Policy Tracker</HeaderText>
        <PolicyLinkTable
          data={data}
          isLoading={isLoading}
          policyOptions={policyOptions}
          updateConfig={updateConfig}
        />
        <PolicyLinkModals />
      </ContentContainer>
    </div>
  )
}

export default PayerPolicyLink
