import React from 'react'

import { Type } from '../../../../../../types'
import EditButton from './EditButton'

interface Props {
  type: Type
  data: any
  sitemap: any
}

const Container = ({
  type,
  data,
  sitemap,
}: Props) => {
  const key = `${type}s`
  const isActive = sitemap[key].has(data.id)

  if (!isActive || !data.has_resources) return null

  return (
    <EditButton
      type={type}
      data={data}
    />
  )
}

export default Container
