import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'

import Icon from 'frontend/components/Icon'
import Button from 'frontend/components/Button'
import Color from 'frontend/utils/color'
import Spinner from 'frontend/components/Spinner'
import ConnectionListItem from './ConnectionListItem'
import ConnectionsList from '../../shared/widget/orgInfluencerConnectionComponents/ConnectionsList'
import InfluencerConnectionPanelContainer from './InfluencerConnectionPanelContainer'
import PathwaysInfluencersForm from '../../shared/widget/PathwaysInfluencersForm'
import { INFLUENCER_ALERT_TYPE } from './../../shared/widget/alert-types'
import { usePathwaysInfluencerData } from 'frontend/hooks/usePathwaysInfluencersData'

// TODO: Move overlay code into shared components
const OVERLAY_1_STYLE_RAW = `
  position: absolute;
  top: 0px;
  left: 0px;
  background: #0A2E4D;
  width: 100%;
  height: 102%;
  opacity: 0.5;
  border-bottom: 0px;
`

const OVERLAY_2_STYLE_RAW = `
  position: absolute;
  top: 0px;
  left: 0px;
  background: #0A2E4D;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  border-bottom: 0px;
`

const WidgetContainer = styled.div({
  display: 'flex',
  width: '100%',
})

const getStubbedNewInfluencer = (pathwaysId) => ({
  pathways_organization: pathwaysId,
  personDatum: {
    first_name: 'New',
    last_name: 'Person Connection',
  },
  indication_permissions: [],
  pathways_influencer_types: [],
  tumor_type_specialty: '',
  internal_tdg_note: '',
  internal_pathways_management_types: [],
  value_chair_indications: [],
  position: '',
  priority: null,
  alert_date: null,
  alert_type: INFLUENCER_ALERT_TYPE,
  alert_description: '',
  is_excluded: false,
  exclusion_reason: '',
  start_date: null,
  end_date: null,
  start_quarter: null,
  end_quarter: null,
})

const FORM_CONFIG = {
  Form: PathwaysInfluencersForm,
  refKey: 'personId',
}

const WIDGET_TITLE = 'Influencer Connections'

const InfluencerConnectionsWidget = ({ entity }) => {
  const { id: pathwaysId } = entity

  const { pathwaysInfluencers } = usePathwaysInfluencerData()
  const {
    data: pathwaysInfluencersData,
    loading: isPathwaysInfluencersLoading,
  } = pathwaysInfluencers

  const connectionsData = pathwaysInfluencersData.filter(
    (pathwayInfluencer) =>
      pathwayInfluencer.pathways_organization === pathwaysId
  )

  const connectionsLoading = isPathwaysInfluencersLoading

  const [selectedConnection, changeConnection] = useState({})
  const [
    isNewConnectionBeingCreated,
    setWhetherNewConnectionBeingCreated,
  ] = useState(false)
  const [anyUnsavedChanges, setWhetherUnsavedChanges] = useState(false)

  useEffect(() => {
    if (!connectionsLoading && connectionsData.length) {
      changeConnection(connectionsData[0])
    }
  }, [connectionsLoading])

  if (connectionsLoading) return <Spinner size={28} />

  // TODO: Move overlay code into shared components
  const boModalHeaderNode = document.querySelector('#BoModalHeader')
  const bomSidebar = document.querySelector('#BomSidebar')
  const headerOverlay = boModalHeaderNode.querySelector('#bomHeaderOverlay')
  const sidebarOverlay = bomSidebar.querySelector('#bomSidebarOverlay')

  if (anyUnsavedChanges) {
    if (!headerOverlay) {
      const overlayDiv = document.createElement('div')
      overlayDiv.id = 'bomHeaderOverlay'
      overlayDiv.style = OVERLAY_1_STYLE_RAW
      boModalHeaderNode.appendChild(overlayDiv)
    }

    if (!sidebarOverlay) {
      const overlayDiv2 = document.createElement('div')
      overlayDiv2.id = 'bomSidebarOverlay'
      overlayDiv2.style = OVERLAY_2_STYLE_RAW
      bomSidebar.appendChild(overlayDiv2)
    }
  } else {
    if (headerOverlay) boModalHeaderNode.removeChild(headerOverlay)
    if (sidebarOverlay) bomSidebar.removeChild(sidebarOverlay)
  }

  const createInfluencerConnectionHandler = () => {
    if (anyUnsavedChanges) {
      alert(
        "You have unsaved changes! Please save or cancel the connection you're on."
      )
    } else {
      const stagedNewInfluencer = getStubbedNewInfluencer(pathwaysId)
      setWhetherNewConnectionBeingCreated(true)
      changeConnection(stagedNewInfluencer)
    }
  }

  const createButton = (
    <Button type="secondary" onClick={createInfluencerConnectionHandler}>
      <Icon iconName="add" color1={Color.PRIMARY} width={16} />
    </Button>
  )

  const { personDatum } = selectedConnection || {}
  const { first_name, last_name } = personDatum || {}
  const panelTitle = `${first_name} ${last_name}`
  return (
    <WidgetContainer>
      <ConnectionsList
        widgetTitle={WIDGET_TITLE}
        isNewConnectionBeingCreated={isNewConnectionBeingCreated}
        createButton={createButton}
        ConnectionListItem={ConnectionListItem}
        connectionsData={connectionsData}
        selectedConnection={selectedConnection}
        changeConnection={changeConnection}
        anyUnsavedChanges={anyUnsavedChanges}
      />
      <InfluencerConnectionPanelContainer
        entityId={pathwaysId}
        title={panelTitle}
        formConfig={FORM_CONFIG}
        selectedConnection={selectedConnection}
        changeConnection={changeConnection}
        setWhetherNewConnectionBeingCreated={
          setWhetherNewConnectionBeingCreated
        }
        isNewConnectionBeingCreated={isNewConnectionBeingCreated}
        connectionsData={connectionsData}
        setWhetherUnsavedChanges={setWhetherUnsavedChanges}
      />
    </WidgetContainer>
  )
}

InfluencerConnectionsWidget.propTypes = {
  entity: PropTypes.object.isRequired,
}

export default InfluencerConnectionsWidget
