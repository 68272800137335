import gql from 'graphql-tag'
import { schemaV0 } from './schemas'

export const DELETE_ALTERNATIVE_PAYMENT_MODEL_ORGANIZATION = gql`
  mutation DeleteAlternativePaymentModelOrganization(
    $input: DeleteAlternativePaymentModelOrganizationInput!
  ) {
    deleteAlternativePaymentModelOrganization(input: $input) {
      ${schemaV0}
    }
  }
`
