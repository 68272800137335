import React from 'react'

import { useQueryString } from 'frontend/hooks'

import { ALL_USERS_DATUM } from '../../constants'
import SitemapButton from './SitemapButton'

interface SitemapButtonContainerProps {
  isHoveredListItem: boolean
  teamId: string
}

const SitemapButtonContainer = ({
  isHoveredListItem,
  teamId,
}: SitemapButtonContainerProps) => {
  const { team } = useQueryString()

  const isAllUsersListItem = teamId === ALL_USERS_DATUM.id
  const isSelectedListItem = team === teamId

  if (isAllUsersListItem || (!isSelectedListItem && !isHoveredListItem))
    return null

  return <SitemapButton teamId={teamId} />
}

export default SitemapButtonContainer
