import { gql } from '@apollo/client'
import { schema } from './schemas'

export const CREATE_VEGA_BOOK_OF_BUSINESS = gql`
  mutation CreateVegaBookOfBusiness(
    $input: CreateVegaBookOfBusinessInput!
  ) {
    createVegaBookOfBusiness(input: $input) {
      ${schema}
    }
  }
`
