import { useQuery } from '@apollo/client'

import { GET_REPORT_USERS } from 'frontend/api/queries'

const useReportData = () => {
  const reportUserData = useQuery(GET_REPORT_USERS)

  return {
    reportUsers: {
      data: reportUserData.data,
      loading: reportUserData.loading,
    },
  }
}

export default useReportData
