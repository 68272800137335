import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const GET_SECONDARY_ORGANIZATIONS_PEOPLE = gql`
  query GetSecondaryOrganizationsPeople($input: SecondaryOrganizationsPeopleInput){
    secondaryOrganizationsPeople(input: $input){
      ${schemaV0}
    }
  }
`
