import React, { MutableRefObject, useRef } from 'react'

import { SingleActionDialog } from 'frontend/components/Dialog'
import { Spinner } from 'frontend/components'
import { usePolarisUsers } from 'frontend/api/usePolarisUsers'

import CreateForm from './CreateForm'

const MODAL_TITLE = 'Create New Polaris User'

const DEFAULT_FORM_INPUT = {
  name: undefined,
  username: undefined,
  email: undefined,
  password: undefined,
}

const CreateModal = ({
  isModalOpen,
  setIsModalOpen,
}: { isModalOpen: boolean, setIsModalOpen: Function }
) => {
  const inputRef: MutableRefObject<any> = useRef(DEFAULT_FORM_INPUT)

  const { create, isCreating } = usePolarisUsers()

  const handleCancel = () => {
    inputRef.current = DEFAULT_FORM_INPUT
    setIsModalOpen(false)
  }
  
  const handleSubmit = () => {
    const input = inputRef.current
    create({ variables: { input } })
    handleCancel()
  }

  const submitText = isCreating
    ? <Spinner/>
    : 'save'

  if (!isModalOpen) return null

  return (
    <SingleActionDialog
      header={MODAL_TITLE}
      cancelHandler={handleCancel}
      submitHandler={handleSubmit}
      submitText={submitText}
    >
      <CreateForm
        inputRef={inputRef}
      />
    </SingleActionDialog>
  )
}

export default CreateModal

