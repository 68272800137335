import React, { Dispatch, SetStateAction } from 'react'
import { Tabs } from '@mui/base/Tabs'
import styled from '@emotion/styled'

import Comparison from './Comparison'
import MostRecent from './MostRecent'
import StyledTabsList from './TabList'

interface Props {
  activeTab: string
  previousApprovedPolicyOptions?: any[]
  selectedPreviousApprovedPolicy?: any
  setActiveTab: Dispatch<SetStateAction<string>>
}

const Container = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  fontFamily: 'inherit',
})

export const HeaderTabs = ({
  activeTab,
  previousApprovedPolicyOptions,
  selectedPreviousApprovedPolicy,
  setActiveTab
}: Props) => {

  const handleChange = (event: any, newValue: any) => {
    setActiveTab(newValue)
  }

  return (
    <Container>
      <Tabs
        aria-label='header tabs'
        onChange={handleChange}
        value={activeTab}
      >
        <StyledTabsList>
          <MostRecent
            activeTab={activeTab}
          />
          <Comparison
            activeTab={activeTab}
            previousApprovedPolicyOptions={previousApprovedPolicyOptions}
            selectedPreviousApprovedPolicy={selectedPreviousApprovedPolicy}
          />
        </StyledTabsList>
      </Tabs>
    </Container>
  )
}
