import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import Panels from './Panels'

const PanelsWrapper = () => {
  const location = useLocation()
  const { client, team }: any = queryString.parse(location.search)
  if (!client || !team) return null
  return (
    <div>
      <Panels team={team} />
    </div>
  )
}

export default PanelsWrapper
