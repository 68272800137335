import React, { useEffect, useState } from 'react'
import { transparentize } from 'polished'
import styled from '@emotion/styled'
import _ from 'lodash'

import Dialog, { ErrorDialog } from 'frontend/components/Dialog'

import Color from 'frontend/utils/color'

import { POLICY_INFO_FORM_CONFIG } from './PolicyContent/PolicyInfo/utils'

import MostRecentPolicyView from './MostRecentPolicyView'
import PdfComparisonViewer from './PdfComparisonViewer'
import usePolicyViewerData from './usePolicyViewerData'
import PlaceholderModal from './PlaceholderModal'
import PolicyContent from './PolicyContent'
import ModalHeader from './ModalHeader'

import { useHandlers } from './utils'

const Container = styled.section({
  display: 'flex',
  height: 'calc(100% - 54px)',
  borderTop: `1px solid ${transparentize(0.9, Color.BLACK)}`,
})

const populateInitialData = (data) =>
  POLICY_INFO_FORM_CONFIG.reduce((acc, { accessor }) => {
    let value = data[accessor]

    acc[accessor] = value
    return acc
  }, {})

const PolicyViewerModal = ({ closeModal, searchParams }) => {
  const [input, setInput] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  const { policyId, productId } = searchParams
  const { data, policyOptions, updateConfig } = usePolicyViewerData({
    policyId,
    productId,
  })

  const { update } = useHandlers({
    searchParams,
    updateConfig,
    closeModal,
    input,
    setErrorMessage,
  })

  useEffect(() => {
    // * populate the input for the first time using data
    if (!_.isEmpty(data) && !input) {
      const defaultInput = populateInitialData(data)

      setInput(defaultInput)
    }
  }, [data]) //eslint-disable-line

  if (_.isEmpty(data) || !input) {
    return <PlaceholderModal closeModal={closeModal} />
  }

  const { policy_doc_link, policy_doc_link_change } = data

  return (
    <Dialog contentStyle={{ borderRadius: 0 }}>
      <ErrorDialog
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <ModalHeader
        data={data}
        closeModal={closeModal}
        handleUpdate={update}
        setPolicyContentInput={setInput}
      />
      <Container>
        <MostRecentPolicyView url={policy_doc_link_change} />
        <PdfComparisonViewer
          originalUrl={policy_doc_link}
          comparisonUrl={policy_doc_link_change}
        />
        <PolicyContent
          data={data}
          policyOptions={policyOptions}
          input={input}
          setInput={setInput}
        />
      </Container>
    </Dialog>
  )
}

export default PolicyViewerModal
