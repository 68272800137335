import { gql } from '@apollo/client'
import { schema } from './schemas'

export const CREATE_PAYER_POLICY_DOCUMENT = gql`
  mutation CreatePayerPolicyDocument(
    $input: CreatePayerPolicyDocumentInput!
  ) {
    createPayerPolicyDocument(input: $input) {
      ${schema}
    }
  }
`
