import { unwrapGqlData } from 'frontend/utils'

import { CrudHookOptions } from './../../interfaces/CrudHookOptions'
import * as CRUD_UTILS from '../../api/utils'

import { getSecondaryOrganizationConfigs } from './getSecondaryOrganizationConfigs'

export const useSecondaryOrganizations = ({
  deleteOptions,
  queryInput,
}: CrudHookOptions = {}) => {
  const {
    createConfig,
    deleteConfig,
    updateConfig,
    readConfig,
    optionsConfig,
  } = getSecondaryOrganizationConfigs(deleteOptions, queryInput)

  const [create, { loading: isCreating }] = CRUD_UTILS.useCreate(createConfig)
  const [destroy, { loading: isDeleting }] = CRUD_UTILS.useDelete(deleteConfig)
  const [update, { loading: isUpdating }] = CRUD_UTILS.useUpdate(updateConfig)

  const { data: readData, loading: isLoading } = CRUD_UTILS.useRead(readConfig)
  const {
    data: optionsData,
    loading: areOptionsLoading,
  } = CRUD_UTILS.useReadOptions(optionsConfig.tag, { labelMap: true })

  const data = readData ? unwrapGqlData(readData) : []
  const options = optionsData ? unwrapGqlData(optionsData) : {}

  return {
    create,
    isCreating,
    update,
    isUpdating,
    destroy,
    isDeleting,
    data,
    isLoading,
    options,
    areOptionsLoading,
  }
}
