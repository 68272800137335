import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'

import './sliding-pane.css'

const CLOSE_TIMEOUT = 500

interface Props {
  isOpen?: boolean
  title?: React.ReactNode
  subtitle?: React.ReactNode
  from?: 'left' | 'right' | 'bottom' // "right" — default
  children: React.ReactNode
  className?: string
  overlayClassName?: string
  width?: string // CSS string for width
  closeIcon?: React.ReactNode
  shouldCloseOnEsc?: boolean
  hideHeader?: boolean
  onRequestClose?: () => void
  onAfterOpen?: () => void
  onAfterClose?: () => void
}

const useUpdateStateIfMounted = (initialValue: any) => {
  const isMountedRef = useRef(true)

  useEffect(() => {
    isMountedRef.current = true

    return () => {
      isMountedRef.current = false
    }
  }, [])

  const useStateResult = useState(initialValue)
  const state = useStateResult[0]
  const setState = useStateResult[1]

  const setStateIfMounted = (value: any) => {
    if (isMountedRef.current === true) {
      setState(value)
    }
  }

  return [state, setStateIfMounted]
}

const SlidingPane = ({
  isOpen = true,
  onRequestClose,
  onAfterOpen,
  onAfterClose,
  children,
  className,
  overlayClassName,
  closeIcon,
  from = 'right',
  width,
  hideHeader = false,
}: Props) => {
  const directionClass = `slide-pane_from_${from}`

  const state = useUpdateStateIfMounted(false)
  const wasOpen = state[0]
  const setWasOpen = state[1]

  const handleAfterOpen = () => {
    // Timeout fixes animation in Safari
    onAfterOpen?.()
    setTimeout(() => {
      setWasOpen(true)
    }, 0)
  }

  const handleAfterClose = () => {
    onAfterClose?.()
    setTimeout(() => {
      setWasOpen(false)
    }, 0)
  }

  return (
    <Modal
      ariaHideApp={false}
      overlayClassName={{
        base: `slide-pane__overlay ${overlayClassName || ''}`,
        afterOpen: wasOpen ? 'overlay-after-open' : '',
        beforeClose: 'overlay-before-close',
      }}
      className={{
        base: `slide-pane ${directionClass} ${className || ''}`,
        afterOpen: wasOpen ? 'content-after-open' : '',
        beforeClose: 'content-before-close',
      }}
      style={{
        content: { width: '30%' },
      }}
      closeTimeoutMS={CLOSE_TIMEOUT}
      isOpen={isOpen}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      onRequestClose={onRequestClose}
    >
      <div className="slide-pane__content">{children}</div>
    </Modal>
  )
}

export default SlidingPane
