import { useEffect, useState } from 'react'
import { OptionTypeBase } from 'react-select'

import { Person } from 'frontend/interfaces'
import { CrudHookOptions } from 'frontend/interfaces/CrudHookOptions'
import * as CRUD_UTILS from 'frontend/api/utils'

import { getPersonConfigs } from './getPersonConfigs'

const VERSION = 'v1'

export const usePerson = ({
  deleteOptions,
  queryInput,
}: CrudHookOptions = {}) => {
  const [options, setOptions]: any = useState([])
  const [labelMap, setLabelMap]: any = useState({})

  queryInput
    ? (queryInput.version = VERSION)
    : (queryInput = { version: VERSION })
  const { readConfig } = getPersonConfigs(deleteOptions, queryInput)

  const { data, loading: isLoading } = CRUD_UTILS.useRead(readConfig)

  useEffect(() => {
    if (!data) return

    const options: OptionTypeBase[] = []
    const labelMap: { [key: string]: string } = {}
    data.people.forEach(({ id, full_name }: Person) => {
      const option = { label: full_name, value: id }
      options.push(option)
      labelMap[id] = full_name
    })

    setOptions(options)
    setLabelMap(labelMap)
  }, [data])

  const peopleData: any = data ? Object.values(data)[0] : []

  return {
    data: peopleData,
    isLoading,
    options,
    labelMap,
  }
}
