import * as GQL_TAGS from './gql-tags'

export const getPayerPolicyDocumentConfigs = (queryInput: any) => ({
  readConfig: {
    tag: GQL_TAGS.GET_PAYER_POLICY_DOCUMENTS,
    input: queryInput,
  },
  updateConfig: {
    tag: GQL_TAGS.UPDATE_PAYER_POLICY_DOCUMENT,
  },
})
