import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const GET_TEAM_NODES = gql`
  query GetTeamsNodes($input: TeamNodesInput) {
    teamNodes(input: $input) {
      ${schemaV0}
    }
  }
`
