import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

interface Props {
  children: any
}

const Background = styled.div({
  display: 'flex',
  position: 'fixed',
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  background: transparentize(0.9, '#000000'),
  zIndex: 1000,
  padding: 8,
})

const Overlay = styled.div({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
})

export const PolarisDialog = ({ children }: Props) => {
  return (
    <Background>
      <Overlay>{children}</Overlay>
    </Background>
  )
}
