import gql from 'graphql-tag'

const GET_VEGA_PAYER_ORGANIZATIONS = gql`
  query GetVegaPayerOrganizations {
    vegaPayerOrganizations {
      id
      slug
      name
      name_tiny
      created_at
      updated_at
    }
  }
`

export default GET_VEGA_PAYER_ORGANIZATIONS
