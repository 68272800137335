import React from 'react'

import { useQueryString, useRewriteSearchString } from 'frontend/hooks'
import Modal from 'frontend/components/Modal'
import Form from './Form'

const Style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const ModalStyle = {
  padding: 0,
  width: '80%',
  height: '90%',
  top: '45%',
  justifyContent: 'flex-start',
}

const ModalContainer = () => {
  const rewriteSearchString = useRewriteSearchString()

  const { node, nodeType, team } = useQueryString()

  const closeModal = (): void => {
    rewriteSearchString({
      node: undefined,
      nodeType: undefined,
    })
  }

  if (!node || !nodeType) return null

  return (
    <Modal
      disableHeader
      show
      handleClose={closeModal}
      modalStyle={ModalStyle}
      style={Style}
    >
      <Form
        node={node}
        nodeType={nodeType}
        team={team}
        closeModal={closeModal}
      />
    </Modal>
  )
}

export default ModalContainer
