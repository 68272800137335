import getSelectedDashboardToolTitle from '../getSelectedDashboardToolTitle'

const getListsConfigs = ({
  selectedDashboardTool,
  dashboardToolsData,
  listsConfigData: {
    data,
    loading,
    error,
  },
}: any) => {
  const isListsConfigQueried = selectedDashboardTool && !loading && !error
  const listHeaderTitle = getSelectedDashboardToolTitle(selectedDashboardTool, dashboardToolsData.data?.nodes)
  const listsConfigs = {
    data: isListsConfigQueried ? data.listsConfig : [],
    loading,
    error,
    listHeaderTitle,
  }
  return listsConfigs
}

export default getListsConfigs
