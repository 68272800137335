import styled from '@emotion/styled'

import Spacing from 'frontend/utils/spacing'
import Color from 'frontend/utils/color'

export const InputSection = styled.section({
  display: 'flex',
  flexDirection: 'column',
  padding: `${Spacing.S4} 0`,
})

export const FormLabel = styled.label({
  fontSize: 12,
  fontWeight: 700,
  lineHeight: '20px',
  padding: `${Spacing.S3} 0`,
})

export const BlueText = styled.span({
  color: Color.PRIMARY,
})
