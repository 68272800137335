import React from 'react'

import { Spinner } from 'frontend/components'

import ErrorMessages from './ErrorMessages'
import Tag from './Tag'
import UploadedFile from './UploadedFile'

interface Props {
  isLoading: boolean
  status: {
    errors?: string[]
    fileName?: string
    tempUsers?: any[]
    users?: any[]
  }
}

const Status = ({ isLoading, status }: Props) => {
  return (
    <>
      <div style={{ paddingBottom: 24 }}>
        <span style={{ fontWeight: 500, paddingRight: 12 }}>
          Uploaded File:
        </span>
        <UploadedFile fileName={status.fileName} />
      </div>
      {isLoading && <Spinner />}
      <Tag {...status} />
      <ErrorMessages errors={status.errors} />
    </>
  )
}

export default Status
