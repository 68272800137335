import React from 'react'
import Select from 'react-select'

import Tooltip from 'frontend/components/Tooltip/Tooltip'
import { useFilterConfigFeatureFlags } from 'frontend/hooks'

import {
  FormLabel,
  FieldContainer,
} from '../../../shared/styledComponents'
import FIELD_TYPES, { FieldType } from './field-types'

interface FieldTypeProps {
  stagedType: string
  setType: (selected: any) => void
}

const TOOLTIP_PROPS = {
  id: 'sheet-import-field-type-tooltip',
  place: 'top',
  effect: 'solid',
}

const FieldTypeInput = ({ stagedType, setType }: FieldTypeProps) => {
  const filterConfigFeatureFlags = useFilterConfigFeatureFlags()
  const types = filterConfigFeatureFlags(FIELD_TYPES)

  const currentType = types.find(({ value }: FieldType) => value === stagedType) || types[0]

  return (
    <FieldContainer>
      <FormLabel>
        <span>Field Type</span>
        <Tooltip
          {...TOOLTIP_PROPS}
          text={currentType.tooltip}
        >
          <span style={{ fontWeight: 100, padding: 12, fontSize: 12, textDecoration: 'underline dotted' }}>More Info</span>
        </Tooltip>
      </FormLabel>

      <Select
        styles={{ container: (base) => ({ ...base, flex: 1 }) }}
        value={{ value: stagedType, label: stagedType }}
        defaultValue={{ value: currentType.value, label: currentType.value }}
        onChange={type => setType(type?.value)}
        options={types.map(({ value }: FieldType) => ({ value, label: value }))}
      />
    </FieldContainer>
  )
}

export default FieldTypeInput
