import React from 'react'
import styled from '@emotion/styled'

import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'

import PushToProductionButton from './PushToProductionButton'

const HeaderSection = styled.section({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const MarketBasketPanelHeader = () => {
  return (
    <HeaderSection>
      <h4 style={{ ...FontSpace.FS5, padding: Spacing.S4 }}>Market Basket</h4>
      <PushToProductionButton />
    </HeaderSection>
  )
}

export default MarketBasketPanelHeader
