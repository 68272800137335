import { useMutation } from '@apollo/client'

import { GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS } from 'frontend/api/queries'
import {
  CREATE_PATHWAYS_INDICATION_REGIMEN_POSITION,
  UPDATE_PATHWAYS_INDICATION_REGIMEN_POSITION,
  DELETE_PATHWAYS_INDICATION_REGIMEN_POSITION,
} from 'frontend/api/mutations'

import compareDates from 'frontend/utils/compareDates'

const useHistoricalPositioningData = () => {
  const [createHistoricalPathwaysPosition] = useMutation(
    CREATE_PATHWAYS_INDICATION_REGIMEN_POSITION,
    {
      onCompleted: ({ createPathwaysIndicationRegimenPosition }) => {
        alert('Pathways Position created.')
      },
      onError: alert,
    }
  )
  const [updateHistoricalPathwaysPosition] = useMutation(
    UPDATE_PATHWAYS_INDICATION_REGIMEN_POSITION,
    {
      update: (
        cache,
        { data: { updatePathwaysIndicationRegimenPosition } }
      ) => {
        updateHistoricalCacheOnUpdate(
          updatePathwaysIndicationRegimenPosition,
          cache
        )
        updatePositioningCacheOnUpdate(
          updatePathwaysIndicationRegimenPosition,
          cache
        )
      },

      onCompleted: ({ updatePathwaysIndicationRegimenPosition }) => {},
      onError: alert,
    }
  )

  const [deleteHistoricalPathwaysPosition] = useMutation(
    DELETE_PATHWAYS_INDICATION_REGIMEN_POSITION,
    {
      update: (
        cache,
        { data: { deletePathwaysIndicationRegimenPosition } }
      ) => {
        updateHistoricalCacheOnDelete(
          deletePathwaysIndicationRegimenPosition,
          cache
        )
        updatePositioningCacheOnDelete(
          deletePathwaysIndicationRegimenPosition,
          cache
        )
      },
      onCompleted: ({ deletePathwaysIndicationRegimenPosition }) => {
        alert('Pathways Position deleted.')
      },
      onError: alert,
    }
  )

  return {
    create: createHistoricalPathwaysPosition,
    update: updateHistoricalPathwaysPosition,
    delete: deleteHistoricalPathwaysPosition,
  }
}

export default useHistoricalPositioningData

const updateHistoricalCacheOnUpdate = (
  updateHistoricalPathwaysIndicationRegimenPosition,
  cache
) => {
  const variables = {
    pathwaysOrganizationId:
      updateHistoricalPathwaysIndicationRegimenPosition.pathways_organization,
    indicationId: updateHistoricalPathwaysIndicationRegimenPosition.indication,
    regimenId: updateHistoricalPathwaysIndicationRegimenPosition.regimen,
    populationsIds:
      updateHistoricalPathwaysIndicationRegimenPosition.populations,
  }
  const { pathwaysIndicationRegimenPositions } = cache.readQuery({
    query: GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS,
    variables,
  })

  const newPathwaysPositions = [...pathwaysIndicationRegimenPositions]

  newPathwaysPositions.sort((a, b) => {
    const date1 = new Date(a.position_date)
    const date2 = new Date(b.position_date)
    return compareDates(date2, date1)
  })

  cache.writeQuery({
    query: GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS,
    data: {
      pathwaysIndicationRegimenPositions: newPathwaysPositions,
    },
    variables,
  })
}
const updateHistoricalCacheOnDelete = (
  deleteHistoricalPathwaysIndicationRegimenPosition,
  cache
) => {
  const variables = {
    pathwaysOrganizationId:
      deleteHistoricalPathwaysIndicationRegimenPosition.pathways_organization,
    indicationId: deleteHistoricalPathwaysIndicationRegimenPosition.indication,
    regimenId: deleteHistoricalPathwaysIndicationRegimenPosition.regimen,
    populationsIds:
      deleteHistoricalPathwaysIndicationRegimenPosition.populations,
  }

  const { pathwaysIndicationRegimenPositions } = cache.readQuery({
    query: GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS,
    variables,
  })

  const newPathwaysPositions = pathwaysIndicationRegimenPositions.filter(
    ({ id }) => id !== deleteHistoricalPathwaysIndicationRegimenPosition.id
  )

  cache.writeQuery({
    query: GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS,
    data: {
      pathwaysIndicationRegimenPositions: newPathwaysPositions,
    },
    variables,
  })
}

const updatePositioningCacheOnUpdate = (
  updatedHistoricalPathwaysPosition,
  cache
) => {
  const variables = {
    pathwaysOrganizationId:
      updatedHistoricalPathwaysPosition.pathways_organization,
    indicationId: updatedHistoricalPathwaysPosition.indication,
    regimenId: updatedHistoricalPathwaysPosition.regimen,
    populationsIds: updatedHistoricalPathwaysPosition.populations,
  }

  const { pathwaysIndicationRegimenPositions } = cache.readQuery({
    query: GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS,
  })

  const {
    pathwaysIndicationRegimenPositions: historicalPathwaysPositions,
  } = cache.readQuery({
    query: GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS,
    variables,
  })

  const historicalPathwaysPositionsCopy = [...historicalPathwaysPositions]
  const [latestPathwayPosition] = historicalPathwaysPositionsCopy
  let updatedPathwaysPositions = [...pathwaysIndicationRegimenPositions]

  let pathwayPositionsIds = {}
  for (const i in updatedPathwaysPositions) {
    pathwayPositionsIds[updatedPathwaysPositions[i].id] = i
  }

  let updatedIndex = -1

  for (const historicalPosition of historicalPathwaysPositionsCopy) {
    if (historicalPosition.id in pathwayPositionsIds) {
      updatedIndex = pathwayPositionsIds[historicalPosition.id]
      break
    }
  }

  updatedPathwaysPositions[updatedIndex] = latestPathwayPosition

  cache.writeQuery({
    query: GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS,
    data: {
      pathwaysIndicationRegimenPositions: updatedPathwaysPositions,
    },
  })
}

const updatePositioningCacheOnDelete = (
  deletePathwaysIndicationRegimenPosition,
  cache
) => {
  const variables = {
    pathwaysOrganizationId:
      deletePathwaysIndicationRegimenPosition.pathways_organization,
    indicationId: deletePathwaysIndicationRegimenPosition.indication,
    regimenId: deletePathwaysIndicationRegimenPosition.regimen,
    populationsIds: deletePathwaysIndicationRegimenPosition.populations,
  }
  const { pathwaysIndicationRegimenPositions } = cache.readQuery({
    query: GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS,
  })

  const {
    pathwaysIndicationRegimenPositions: historicalPathwaysPositions,
  } = cache.readQuery({
    query: GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS,
    variables,
  })

  const [latestPathwaysPosition] = historicalPathwaysPositions

  const newPathwaysPositions = pathwaysIndicationRegimenPositions.reduce(
    (acc, pathwaysPosition) => {
      if (pathwaysPosition.id === deletePathwaysIndicationRegimenPosition.id) {
        if (latestPathwaysPosition) {
          acc.push(latestPathwaysPosition)
        }
      } else {
        acc.push(pathwaysPosition)
      }
      return acc
    },
    []
  )

  cache.writeQuery({
    query: GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS,
    data: {
      pathwaysIndicationRegimenPositions: newPathwaysPositions,
    },
  })
}
