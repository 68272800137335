import React from 'react'
import { InputContainer, InputLabel, StyledInput } from './styledComponents'
import Color from 'frontend/utils/color'
import _ from 'lodash'

export const getTextInputs = (
  nodeData = {},
  handleInputChange,
  handleRemoveTextField
) => {
  const text = nodeData.text || {}
  const orderedKeys = Object.keys(text).sort()

  return orderedKeys.map((key) => {
    return (
      <InputContainer key={key}>
        <InputLabel>{_.startCase(key)}</InputLabel>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <StyledInput
            style={{ flex: 25 }}
            value={text[key] || ''}
            name={key}
            type="text"
            onChange={handleInputChange}
          />
          <button
            style={{
              borderRadius: 4,
              cursor: 'pointer',
              flex: 1,
              background: Color.RED,
              color: Color.WHITE,
              fontWeight: 700,
              padding: 6,
              margin: 6,
            }}
            onClick={() => handleRemoveTextField(key)}
          >
            -
          </button>
        </div>
      </InputContainer>
    )
  })
}
