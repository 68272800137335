import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import _ from 'lodash'

import { useQueryString } from 'frontend/hooks'

import { LIST_HEADER } from '../../../enums'
import { List, ListHeader, ListItem } from '../../shared'
import './sortableContainerStyles.css'

const SortableList = SortableContainer(({ children }) => {
  return <List>{children}</List>
})

const SortableListItem = SortableElement((props) => {
  return <ListItem {...props} />
})

const ActiveList = ({ regimenIdToLabel, regimens, setResources }) => {
  const { indication } = useQueryString()

  const sortResource = ({ oldIndex, newIndex }) => {
    setResources(({ treatmentPlans, ...rest }) => {
      const index = treatmentPlans.findIndex(
        ({ resource }) => resource === indication
      )
      const newTreatmentPlan = _.cloneDeep(treatmentPlans[index])
      const [resource] = newTreatmentPlan.children.splice(oldIndex, 1)
      newTreatmentPlan.children.splice(newIndex, 0, resource)
      treatmentPlans[index] = newTreatmentPlan
      return {
        treatmentPlans,
        ...rest,
      }
    })
  }

  const disableResource = (resource) => {
    setResources(({ treatmentPlans, ...rest }) => {
      const index = treatmentPlans.findIndex(
        ({ resource }) => resource === indication
      )
      const newTreatmentPlan = _.cloneDeep(treatmentPlans[index])
      newTreatmentPlan.children = newTreatmentPlan.children.filter(
        (child) => child.resource !== resource.resource
      )
      treatmentPlans[index] = newTreatmentPlan
      return {
        treatmentPlans,
        ...rest,
      }
    })
  }

  return (
    <>
      <ListHeader listLength={regimens.length} type={LIST_HEADER.ACTIVE} />
      <SortableList
        useDragHandle
        helperClass={'sortableHelper'}
        onSortEnd={sortResource}
      >
        {regimens.map((resource, index) => (
          <SortableListItem
            index={index}
            key={resource.resource}
            checked={true}
            label={regimenIdToLabel[resource.resource]}
            resource={resource}
            toggleResource={disableResource}
            type={LIST_HEADER.ACTIVE}
          />
        ))}
      </SortableList>
    </>
  )
}

export default ActiveList
