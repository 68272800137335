import { gql } from '@apollo/client'
import { schemaV1 } from './schemas'

export const UPDATE_NCCN_PANEL_VOTING_MEMBER = gql`
  mutation UpdateNccnPanelVotingMember($input: UpdateNccnPanelVotingMemberInput!) {
    updateNccnPanelVotingMember(input: $input) {
      ${schemaV1}
    }
  }
`
