import { gql } from '@apollo/client'
import { schema } from './schemas'

export const UPDATE_VEGA_BOOK_OF_BUSINESS = gql`
  mutation UpdateVegaBookOfBusiness($input: UpdateVegaBookOfBusinessInput!) {
    updateVegaBookOfBusiness(input: $input) {
      ${schema}
    }
  }
`
