import React, { Dispatch, SetStateAction } from 'react'

import { Checkbox } from './Checkbox'

interface Props {
  clientTeams: any
  setFieldState: Dispatch<SetStateAction<any>>
}

export const CheckboxList = ({ clientTeams, setFieldState }: Props) => {
  if (!clientTeams) return null

  return (
    <>
      {clientTeams.map((team: any) => (
        <Checkbox team={team} setFieldState={setFieldState} />
      ))}
    </>
  )
}
