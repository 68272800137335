import { useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import queryString from 'query-string'

import {
  GET_VEGA_INDICATIONS,
  GET_VEGA_PROVIDERS,
  GET_VEGA_PATHWAYS_ORGANIZATIONS,
} from 'frontend/api/queries'

import { UPDATE_VEGA_PERSON } from 'frontend/api/mutations'

import { useProviderKeyDecisionMakers, useNccnPanels } from 'frontend/api'

import { useRewriteSearchString } from 'frontend/hooks'

import { UseProviderKeyDecisionMakersOptions } from 'frontend/interfaces'

import getLabelMapsAndOptions from './getLabelMapsAndOptions'
import { usePaginatedProviderKeyDecisionMakers, usePerson } from 'frontend/entity-hooks'

interface UseProviderKdmDataOptions {
  isPaginated?: boolean
  searchStringParams?: string[]
}

const useProviderKdmData = ({
  isPaginated = false,
  searchStringParams,
}: UseProviderKdmDataOptions = {}) => {
  const useProviderKeyDecisionMakersOptions = {
    isPaginated,
    ...useSearchStringOptions(isPaginated, searchStringParams),
  }

  const providersResponse = useQuery(GET_VEGA_PROVIDERS)
  const {
    create,
    delete: destroy,
    isCreating,
    isDeleting,
    options: providerKdmOptionsResponse,
    update,
  } = useProviderKeyDecisionMakers(useProviderKeyDecisionMakersOptions)

  const { data, isLoading } = usePaginatedProviderKeyDecisionMakers(
    useProviderKeyDecisionMakersOptions
  )

  const nccnPanels = useNccnPanels({})

  const peopleResponse = usePerson()
  const [updatePerson] = useMutation(UPDATE_VEGA_PERSON, { onError: alert })

  const indicationsResponse = useQuery(GET_VEGA_INDICATIONS)

  const pathwaysOrganizationsResponse = useQuery(
    GET_VEGA_PATHWAYS_ORGANIZATIONS
  )


  const {
    indicationsLabelMap,
    indicationsOptions,
    keyDecisionMakerTypeLabelMap,
    keyDecisionMakerTypeOptions,
    peopleOptions,
    providerOptions,
    providerLabelMap,
    pathwaysOrganizationsLabelMap,
  } = getLabelMapsAndOptions({
    peopleResponse,
    providersResponse,
    indicationsResponse,
    providerKdmOptionsResponse,
    pathwaysOrganizationsResponse,
  })

  return {
    providerKeyDecisionMakers: {
      create,
      data,
      delete: destroy,
      isCreating,
      isDeleting,
      isLoading,
      keyDecisionMakerTypeLabelMap,
      keyDecisionMakerTypeOptions,
      update,
    },
    people: {
      ...peopleResponse,
      update: updatePerson,
      options: peopleOptions,
    },
    providers: {
      ...providersResponse,
      options: providerOptions,
      labelMap: providerLabelMap,
    },
    indications: {
      ...indicationsResponse,
      options: indicationsOptions,
      labelMap: indicationsLabelMap,
    },
    nccnPanels,
    pathwaysOrganizations: {
      labelMap: pathwaysOrganizationsLabelMap,
    },
  }
}

export default useProviderKdmData

const useSearchStringOptions = (
  isPaginated: boolean,
  searchStringParams?: string[]
): UseProviderKeyDecisionMakersOptions => {
  const { search } = useLocation()
  const rewriteSearchString = useRewriteSearchString()

  if (isPaginated) return {}

  let deleteOptions
  if (searchStringParams) {
    const deleteSearchArgs = searchStringParams.reduce(
      (acc: any, param: string) => {
        acc[param] = undefined
        return acc
      },
      {}
    )
    const onCompleted = () => rewriteSearchString(deleteSearchArgs)
    deleteOptions = { onCompleted }
  }

  let queryInput
  const { providerKdmId } = queryString.parse(search)
  if (providerKdmId && !Array.isArray(providerKdmId))
    queryInput = { id: providerKdmId }

  return { deleteOptions, queryInput }
}
