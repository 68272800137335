// 'description' here refers to the text that appears as the third line
// of each item in the org connections panel (middle panel in widget); it can be a
// date if the connection is outdated, or an exclusion reason
const getConnectionDescriptionAndStatus = (
  is_excluded,
  exclusion_reason,
  end_date
) => {
  let description = end_date
  let status = 'outdated'

  if (is_excluded) {
    description = exclusion_reason
    status = 'excluded'
  } else if (!end_date) {
    description = null
    status = 'active'
  }

  return {
    description,
    status,
  }
}

export default getConnectionDescriptionAndStatus
