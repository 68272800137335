import useRead from './useRead'

export const usePayerOrganizations = () => {
  const {
    data: vegaPayerOrganizationsData,
    loading: isLoading,
  } = useRead()

  const data = vegaPayerOrganizationsData?.vegaPayerOrganizations

  return {
    data,
    isLoading,
  }
}
