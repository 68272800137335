const compareDates = (date1: Date | undefined | null, date2: Date | undefined | null): number => {
  if(date1 && !date2) return 1
  if(!date1 && date2) return -1
  if(!date1 && !date2) return 0
  
  const dateTime1 = date1 !== null && date1 !== undefined && date1.getTime()
  const dateTime2 = date2 !== null && date2 !== undefined && date2.getTime()

  if(dateTime1 === dateTime2) return 0
  if(dateTime1 > dateTime2) return 1
  return -1;
}

export default compareDates
