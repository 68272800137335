import React from 'react'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'
import { transparentize, mix } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons'
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import { Span } from '../styledComponents'
import { Colors, Spacing } from '../../../../utils/pulseStyles'
import '../sortableContainerStyles.css'
import {
  IndicationPanelContainer,
  ListHeader,
  ActiveRow,
  InactiveRow,
  ListRow,
  UnorderedList,
  SelectedHeader,
} from '../styledComponents'

const DragHandle = sortableHandle(() => (
  <span
    style={{
      padding: `${Spacing.SMALL} ${Spacing.NORMAL}`,
      opacity: 0.3,
      fontSize: 16,
    }}
  >
    <FontAwesomeIcon icon={faArrowsAltV} color={Colors.BLACK} />
  </span>
))

const SWITCH_COLOR = Colors.GREEN

const PhoenixSwitch = withStyles({
  switchBase: {
    color: mix(0.4, Colors.BLACK, Colors.WHITE),
    '&$checked': {
      color: SWITCH_COLOR,
    },
    '&$checked + $track': {
      backgroundColor: SWITCH_COLOR,
    },
  },
  checked: {},
  track: {
    backgroundColor: transparentize(0.7, Colors.BLACK),
  },
})(Switch)

const SortableItem = sortableElement(
  ({ line, selectedLineId, selectLine, disableLine }) => {
    const isSelected = line._id === selectedLineId
    return (
      <ListRow
        isSelected={isSelected}
        isActive
        onClick={() => selectLine(line)}
      >
        <div>
          <DragHandle />
          <Span isSelected={isSelected}>{line.name}</Span>
        </div>
        <PhoenixSwitch
          checked
          value={line._id}
          onChange={() => disableLine(line)}
        />
      </ListRow>
    )
  }
)

const SortableContainer = sortableContainer(({ children }) => {
  return <UnorderedList>{children}</UnorderedList>
})

const LineOfTherapiesPanel = ({
  selectedLineId,
  selectLine,
  selectedPopulation,
  enabledLines,
  disabledLines,
  enableLine,
  disableLine,
  onSortEnd,
}) => {
  return (
    <IndicationPanelContainer>
      <ListHeader>
        Line Of Therapies /{' '}
        <SelectedHeader>
          {selectedPopulation ? selectedPopulation.name : ''}
        </SelectedHeader>
      </ListHeader>

      <ActiveRow>ACTIVE ({enabledLines.length})</ActiveRow>

      <SortableContainer helperClass="sortableHelper" useDragHandle>
        {enabledLines.map((line, index) => (
          <SortableItem
            key={line._id}
            index={index}
            line={line}
            selectLine={selectLine}
            selectedLineId={selectedLineId}
            disableLine={disableLine}
          />
        ))}
      </SortableContainer>

      <InactiveRow>INACTIVE ({disabledLines.length})</InactiveRow>
      <SortableContainer>
        {disabledLines.map((line, index) => {
          const isSelected = line._id === selectedLineId
          return (
            <ListRow
              isSelected={isSelected}
              key={line._id}
              onClick={() => selectLine(line)}
            >
              <Span>{line.name}</Span>
              <PhoenixSwitch
                checked={false}
                color="primary"
                value={line._id}
                onChange={() => enableLine(line)}
              />
            </ListRow>
          )
        })}
        <div style={{ marginTop: '25px' }} />
      </SortableContainer>
    </IndicationPanelContainer>
  )
}

export default LineOfTherapiesPanel
