import React from 'react'
import _ from 'lodash'

import { formatDateTime } from '../../utils/formatDate'

import { ListWrapper } from './styled-components'
import { MetaDataProps } from './interfaces'
import HistoryEvent from './HistoryEvent/HistoryEvent'

// !Need to add the warnings
interface OperationOptions {
  [key: string]: string
}

const OPERATION_KEY_TO_LABEL: OperationOptions = {
  "create" : "created",
  "update" : "updated",
  "delete" : "deleted"
}

const MetaDataList = ({
  data,
  keyToLabel,
}:MetaDataProps) => {
  return (
    <ListWrapper>
      {data.map((datum:any) => (
        <HistoryEvent 
          key={_.uniqueId()}
          action={OPERATION_KEY_TO_LABEL[datum.operation]}
          deltas={Object.entries(datum.changes)}
          username={datum.changed_by.username}
          timestamp={formatDateTime(datum.timestamp)}
          keyToLabel={keyToLabel}
        />
      ))}
    </ListWrapper>
  )
}

export default MetaDataList
