import { useMutation } from '@apollo/client'

import { GET_PAGINATED_PROVIDER_KEY_DECISION_MAKERS } from 'frontend/entity-hooks/usePaginatedProviderKeyDecisionMakers/gql-tags'
import tag from './gql-tag'

const useDelete = (deleteOptions: any = {}) => {
  const options = {
    update,
    onError: alert,
    ...deleteOptions,
  }
  return useMutation(tag, options)
}

export default useDelete

const update = (
  cache: any,
  { data: { deleteProviderKeyDecisionMaker } }: any
) => {
  const { paginatedProviderKeyDecisionMakers } = cache.readQuery({
    query: GET_PAGINATED_PROVIDER_KEY_DECISION_MAKERS,
  })

  const newProviderKdms = paginatedProviderKeyDecisionMakers.filter(
    ({ id }: any) => id !== deleteProviderKeyDecisionMaker.id
  )

  cache.writeQuery({
    query: GET_PAGINATED_PROVIDER_KEY_DECISION_MAKERS,
    data: {
      paginatedProviderKeyDecisionMakers: newProviderKdms,
    },
  })
}
