import { INPUT_TYPES } from 'frontend/components/Form/constants/input-type-to-component-map'

const { SINGLE_SELECT, TEXT } = INPUT_TYPES

const getFormConfig = ({ type }: any) => [
  [
    {
      label: 'Name',
      accessor: 'name',
      inputType: TEXT,
      isRequired: true,
    },
    {
      label: 'Type',
      accessor: 'type',
      inputType: SINGLE_SELECT,
      options: type?.options ?? [],
      isRequired: true,
    },
    {
      label: 'Alias',
      accessor: 'alias',
      inputType: TEXT,
    },
    {
      label: 'Committees',
      accessor: 'committees',
      inputType: TEXT,
    },
  ],
]

export default getFormConfig
