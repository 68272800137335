import React from 'react'
import { CreatableMultiSelect } from 'frontend/components'

type AliasesMultiSelectProps = {
  handleChange: any,
  values: Array<String>
}

const CREATABLE_MULTI_SELECT_COMPONENTS = {
  DropdownIndicator: null,
}

const NAME = 'aliases'

const AliasesMultiSelect = ({handleChange, values}: AliasesMultiSelectProps) => {

  const handleCreatableChange = (value: any, { name }:{name: String}) => {
    const newValue = value.map(({ value }:{value:String}) => value)
    const e = { target: { value: newValue, name } }
    handleChange(e)
  }

  const convertValueArrayToOptionsArray = (values: Array<String>) => {
    if (!values) return values
    const mappedValues = values.map((value) => ({ value, label: value }))
    return mappedValues
  }

  return (
    <CreatableMultiSelect
      name={NAME}
      components={CREATABLE_MULTI_SELECT_COMPONENTS}
      menuIsOpen={false}
      onChange={handleCreatableChange}
      value={convertValueArrayToOptionsArray(values)}
    />
  )
}

export default AliasesMultiSelect
