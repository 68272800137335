import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const CREATE_TEAM_AUTH0_USER = gql`
  mutation CreateTeamAuth0User($input: CreateTeamAuth0UserInput!) {
    createTeamAuth0User(input: $input) {
      ${schemaV0}
    }
  }
`
