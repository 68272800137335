import styled from '@emotion/styled'
import { TabsList } from '@mui/base/TabsList'

export const StyledTabsList = styled(TabsList)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  `
)
