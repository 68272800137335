import { CrudHookOptions } from 'frontend/interfaces/CrudHookOptions'
import * as CRUD_UTILS from 'frontend/api/utils'

import { getNccnPanelConfigs } from './getNccnPanelConfigs'

export const useNccnPanels = ({
  deleteOptions,
  queryInput,
}: CrudHookOptions) => {
  const {
    createConfig,
    deleteConfig,
    updateConfig,
    readConfig,
  } = getNccnPanelConfigs(deleteOptions, queryInput)

  const [
    create,
    { loading: isCreating, error: createError },
  ] = CRUD_UTILS.useCreate(createConfig)
  const [destroy, { loading: isDeleting }] = CRUD_UTILS.useDelete(deleteConfig)
  const [
    update,
    { loading: isUpdating, error: updateError },
  ] = CRUD_UTILS.useUpdate(updateConfig)

  const { data: nccnPanelData, loading: isLoading, error } = CRUD_UTILS.useRead(
    readConfig
  )
  const nccnPanelsData: any = nccnPanelData
    ? Object.values(nccnPanelData)[0]
    : []

  let labelMap: { [key: string]: string } = {}
  let options: { value: any; label: any }[] = []
  if (!isLoading) {
    labelMap = getLabelMap(nccnPanelsData)
    options = getOptions(nccnPanelsData)
  }

  return {
    create,
    createError,
    data: nccnPanelsData,
    delete: destroy,
    error,
    isCreating,
    isDeleting,
    isLoading,
    isUpdating,
    update,
    updateError,
    labelMap,
    options,
  }
}

const getLabelMap = (nccnPanels: any[]) => {
  return nccnPanels.reduce(
    (acc: { [x: string]: any }, panel: { id: string | number; name: any }) => {
      acc[panel.id] = panel.name
      return acc
    },
    {}
  )
}

const getOptions = (nccnPanels: any[]) => {
  return nccnPanels.map((panel) => ({ value: panel.id, label: panel.name }))
}
