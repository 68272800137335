const handleMarketBasketCategoryToggle = (
  e,
  {
    market_basket_subscription,
    category_sub_id,
    subscribeToMarketBasketCategory,
    unsubscribeToMarketBasketCategory,
  }
) => {
  e.stopPropagation()
  const { checked, value } = e.target

  if (checked) {
    const input = {
      market_basket_survey_category: value,
      market_basket_subscription,
    }

    subscribeToMarketBasketCategory({ variables: { input } })
  } else {
    const input = { id: category_sub_id }

    unsubscribeToMarketBasketCategory({ variables: { input } })
  }
}

export default handleMarketBasketCategoryToggle
