import React, { useState } from 'react'

import Button from 'frontend/components/Button'

import CreateModal from './CreateModal'

const CreateButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <CreateModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Button onClick={() => setIsModalOpen(true)}>+ User</Button>
    </>
  )
}

export default CreateButton
