import gql from 'graphql-tag'

export const CREATE_INDICATION = gql`
  mutation CreateIndication($input: CreateIndicationInput!) {
    createIndication(input: $input) {
      id
      name
      therapeutic_area_id
      aliases
      regimens
    }
  }
`

export const UPDATE_INDICATION = gql`
  mutation UpdateIndication($input: UpdateIndicationInput!) {
    updateIndication(input: $input) {
      id
      name
      therapeutic_area_id
      aliases
      regimens
      is_flagged_for_data_extraction
    }
  }
`

export const DELETE_INDICATION = gql`
  mutation DeleteIndication($input: DeleteIndicationInput!) {
    deleteIndication(input: $input) {
      id
      name
      therapeutic_area_id
      aliases
      regimens
    }
  }
`
