import React from 'react'

import { useQueryString } from 'frontend/hooks'

import ParentNode from './ParentNode'

const Container = () => {
  const { nodeType } = useQueryString()

  if (nodeType === 'tool') return null

  return (
    <ParentNode />
  )
}

export default Container
