import React from 'react'

import { Form, Spinner } from 'frontend/components'
import { SingleActionDialog } from 'frontend/components/Dialog'

interface FormModalPropTypes {
  header: any
  handleCancel: () => void
  handleSubmit: () => void
  submitText: string
  buttonRef: React.RefObject<HTMLButtonElement>
  data: any
  setData: (arg: any) => void
  formConfig: any
  isLoading: boolean
}

export const FormModal = ({
  header,
  handleCancel,
  handleSubmit,
  submitText,
  buttonRef,
  data,
  setData,
  formConfig,
  isLoading,
}: FormModalPropTypes) => {
  return (
    <SingleActionDialog
      header={header}
      cancelHandler={handleCancel}
      submitHandler={handleSubmit}
      submitText={submitText}
      buttonRef={buttonRef}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <Form datum={data} formConfig={formConfig} setFormState={setData} />
      )}
    </SingleActionDialog>
  )
}
