import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import {
  GET_VEGA_INDICATIONS,
  GET_VEGA_LINES_OF_THERAPY,
  GET_VEGA_POPULATIONS,
  GET_VEGA_REGIMENS,
} from 'frontend/api/queries'

const formatOption = ({ id, name }) => ({ value: id, label: name })

const useFdaApprovalData = () => {
  const [indicationsOptions, setIndicationsOptions] = useState([])
  const [regimensOptions, setRegimensOptions] = useState([])
  const [populationsOptions, setPopulationsOptions] = useState([])
  const [linesOfTherapyOptions, setLinesOfTherapyOptions] = useState([])

  const { data: indicationsData, loading: isIndicationsLoading } = useQuery(
    GET_VEGA_INDICATIONS
  )
  const { data: regimensData, loading: isRegimenLoading } = useQuery(
    GET_VEGA_REGIMENS
  )
  const { data: populationsData, loading: isPopulationsLoading } = useQuery(
    GET_VEGA_POPULATIONS
  )
  const {
    data: linesOfTherapyData,
    loading: isLinesOfTherapyLoading,
  } = useQuery(GET_VEGA_LINES_OF_THERAPY)

  useEffect(() => {
    if (!isIndicationsLoading) {
      const options = indicationsData.vegaIndications.map(formatOption)
      setIndicationsOptions(options)
    }
  }, [indicationsData, isIndicationsLoading])

  useEffect(() => {
    if (!isRegimenLoading) {
      const options = regimensData.vegaRegimens.map(formatOption)
      setRegimensOptions(options)
    }
  }, [regimensData, isRegimenLoading])

  useEffect(() => {
    if (!isPopulationsLoading) {
      const options = populationsData.vegaPopulations.map(formatOption)
      setPopulationsOptions(options)
    }
  }, [populationsData, isPopulationsLoading])

  useEffect(() => {
    if (!isLinesOfTherapyLoading) {
      const options = linesOfTherapyData.vegaLinesOfTherapy.map(formatOption)
      setLinesOfTherapyOptions(options)
    }
  }, [linesOfTherapyData, isLinesOfTherapyLoading])

  return {
    indication: { options: indicationsOptions, loading: isIndicationsLoading },
    regimen: { options: regimensOptions, loading: isRegimenLoading },
    population: { options: populationsOptions, loading: isPopulationsLoading },
    lineOfTherapy: {
      options: linesOfTherapyOptions,
      loading: isRegimenLoading,
    },
  }
}

export default useFdaApprovalData
