import React, { useState, Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'
import _ from 'lodash'

import { TextInput } from 'frontend/components'
import { Colors } from 'frontend/utils/pulseStyles'

import { NodeState } from '../../types'
import { Label } from '../shared'

interface Props {
  setNodeState: Dispatch<SetStateAction<NodeState>>
  text: { [key: string]: string }
}

const Wrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  marginTop: 12,
})

const Button = styled.button({
  background: Colors.PRIMARY,
  borderRadius: 4,
  color: Colors.WHITE,
  cursor: 'pointer',
  fontWeight: 700,
  marginLeft: 12,
  padding: 6,
})

const AddField = ({
  setNodeState,
  text,
}: Props) => {
  const [fieldState, setFieldState] = useState<string | undefined>()

  const updateFieldState = ({ target: { value } }: any) => {
    setFieldState(value)
  }

  const addField = () => {
    const newField = _.camelCase(fieldState)
    if (!newField || text.hasOwnProperty(newField)) return null

    setNodeState((nodeState) => ({
      ...nodeState,
      text: {
        ...text,
        [newField]: '',
      },
    }))
  }

  return (
    <>
      <Label>
        Add New Text Field
      </Label>
      <Wrapper>
        <Label>
          Key Name
        </Label>
        <TextInput
          onChange={updateFieldState}
          value={fieldState}
        />
        <Button
          onClick={addField}
        >
          Add
        </Button>
      </Wrapper>
    </>
  )
}

export default AddField
