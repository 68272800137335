import React, { Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'

import { CollapsibleSidePanel } from 'frontend/components'
import DocumentsSection from './DocumentsSection'
import ProductsSection from './ProductsSection'
import Notes from './Notes'

const SIDE_PANEL_WRAPPER_STYLE = {
  paddingBottom: '15px',
  fontSize: 20,
  fontFamily: 'Inter',
  fontWeight: 500,
  paddingRight: '5px',
}

const Content = styled.div({
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
})

const POLICY_DETAILS_LABEL = 'Policy Details'

interface Props {
  activeTab: string
  mostRecentPolicy: any
  policyState: any
  previousApprovedPolicyOptions?: any[]
  selectedPreviousApprovedPolicy?: any
  setPolicyState: Dispatch<SetStateAction<any>>
  setSelectedPreviousApprovedPolicy: Dispatch<SetStateAction<any>>
}

const PolicyDetails = ({
  activeTab,
  mostRecentPolicy,
  policyState,
  previousApprovedPolicyOptions,
  selectedPreviousApprovedPolicy,
  setPolicyState,
  setSelectedPreviousApprovedPolicy,
}: Props) => {
  return (
    <CollapsibleSidePanel
      label={POLICY_DETAILS_LABEL}
      wrapperStyles={SIDE_PANEL_WRAPPER_STYLE}
    >
      <Content>
        <DocumentsSection
          activeTab={activeTab}
          mostRecentPolicy={mostRecentPolicy}
          policyState={policyState}
          previousApprovedPolicyOptions={previousApprovedPolicyOptions}
          selectedPreviousApprovedPolicy={selectedPreviousApprovedPolicy}
          setPolicyState={setPolicyState}
          setSelectedPreviousApprovedPolicy={setSelectedPreviousApprovedPolicy}
        />
        <ProductsSection
          mostRecentPolicy={mostRecentPolicy}
          policyState={policyState}
          setPolicyState={setPolicyState}
        />
        <Notes
          mostRecentPolicy={mostRecentPolicy}
          policyState={policyState}
          setPolicyState={setPolicyState}
        />
      </Content>
    </CollapsibleSidePanel>
  )
}

export default PolicyDetails
