import * as CRUD_UTILS from '../../api/utils'

import { getAlternativePaymentModelOrganizationConfigs } from './getAlternativePaymentModelOrganizationConfigs'

export const useAlternativePaymentModelOrganizations = () => {
  const {
    createConfig,
    deleteConfig,
    updateConfig,
    readConfig,
  } = getAlternativePaymentModelOrganizationConfigs()

  const [create, { loading: isCreating }] = CRUD_UTILS.useCreate(createConfig)
  const [destroy, { loading: isDeleting }] = CRUD_UTILS.useDelete(deleteConfig)
  const [update, { loading: isUpdating }] = CRUD_UTILS.useUpdate(updateConfig)

  const {
    data: alternativePaymentModelOrganizationData,
    loading: isLoading,
  } = CRUD_UTILS.useRead(readConfig)

  const data =
    alternativePaymentModelOrganizationData?.alternativePaymentModelOrganizations ??
    []

  return {
    create,
    isCreating,
    update,
    isUpdating,
    delete: destroy,
    isDeleting,
    data,
    isLoading,
  }
}
