import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import NccnPanels from './NccnPanels'
import SecondaryOrganizations from './SecondaryOrganizations'

const Institutions = () => (
  <Switch>
    <Route
      path={'/orion/organizations/institutions/nccn-panels'}
      component={NccnPanels}
    />
    <Route
      path={'/orion/organizations/institutions/secondary-organizations'}
      component={SecondaryOrganizations}
    />
    <Redirect to={'/orion/organizations/institutions/nccn-panels'} />
  </Switch>
)

export default Institutions
