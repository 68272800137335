import { gql } from '@apollo/client'
import { schema } from './schemas'

export const DELETE_VEGA_PAYER_ORGANIZATION = gql`
  mutation DeleteVegaPayerOrganization($input: DeleteVegaPayerOrganizationInput!) {
    deleteVegaPayerOrganization(input: $input) {
      ${schema}
    }
  }
`
