import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'

import socket from 'frontend/api/socket'

import { CELL_VALUE_UPDATING_SEND_ID } from './useDataTableConcurrentEdit'

const DATA_UPDATE_SEND_ID = 'send_data_table_update'
const DATA_UPDATE_RECEIVE_ID = 'receive_data_table_update'

const useDataTableDataRefresh = ({
  updateId,
  gqlQuery,
  options = {},
  tableId,
}) => {
  const [query, { data, loading }] = useLazyQuery(gqlQuery, {
    fetchPolicy: 'cache-and-network',
    ...options,
  })

  useEffect(() => {
    query()

    const receiveDataUpdate = ({ updateId: receivedUpdateId }) => {
      if (receivedUpdateId === updateId) {
        query()

        socket.emit(DATA_UPDATE_SEND_ID, {
          updateId,
          tableId,
          updateCompleted: true,
        })
      }
    }

    socket.on(DATA_UPDATE_RECEIVE_ID, receiveDataUpdate)

    return () => socket.off(DATA_UPDATE_RECEIVE_ID, receiveDataUpdate)
  }, [query, updateId])

  const emitCellUpdateCompleted = ({ input, tableId }) => {
    socket.emit(CELL_VALUE_UPDATING_SEND_ID, {
      tableId,
      input,
      isCellValueUpdating: false,
    })
  }

  const emitCellUpdateError = ({ input, tableId }) => {
    socket.emit(CELL_VALUE_UPDATING_SEND_ID, {
      tableId,
      input,
      isCellValueUpdating: false,
      hasError: true,
    })
  }

  const emitDataShouldUpdate = (tableId) => {
    socket.emit(DATA_UPDATE_SEND_ID, {
      updateId,
      tableId,
    })
  }

  return {
    data,
    loading: loading && !data, // * only show loading on first load
    query,
    emitCellUpdateCompleted,
    emitCellUpdateError,
    emitDataShouldUpdate,
  }
}

export default useDataTableDataRefresh
