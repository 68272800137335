import React from 'react'
import styled from '@emotion/styled'

import { ListItem } from './ListItem'

interface Props {
  isSelected: boolean
  data: any
}

const BORDER = `1px solid #E6E8EA`

const Wrapper = styled.div({
  borderBottom: BORDER,
})

export const Container = ({ isSelected, data }: Props) => {
  return (
    <Wrapper>
      <ListItem isSelected={isSelected} data={data} />
    </Wrapper>
  )
}
