import _ from 'lodash'

import { usePaginatedRead } from 'frontend/api/utils/useCrudHookApi'
import { UseProviderKeyDecisionMakersOptions } from 'frontend/interfaces'
import { GET_PAGINATED_PROVIDER_KEY_DECISION_MAKERS } from './gql-tags'

export const usePaginatedProviderKeyDecisionMakers = ({
  queryInput,
  isPaginated = false,
}: UseProviderKeyDecisionMakersOptions = {}) => {
  let { data } = usePaginatedRead({
    tag: GET_PAGINATED_PROVIDER_KEY_DECISION_MAKERS,
    input: queryInput,
    isPaginated,
  })

  data = data ?? []
  return {
    data,
    isLoading: _.isEmpty(data),
  }
}
