import _ from 'lodash'

const getMarketBasketsWithSubscriptionStatuses = (
  marketBaskets,
  marketBasketSubscriptions
) => {
  const marketBasketSubscriptionsMap = _.keyBy(
    marketBasketSubscriptions,
    'market_basket'
  )

  return marketBaskets.map((marketBasket) => {
    const subscription = marketBasketSubscriptionsMap[marketBasket.id]
    const isSubscribed = Boolean(subscription)
    const mbSubIdObject = isSubscribed
      ? { subscriptionId: subscription.id }
      : {}
    return { ...marketBasket, isSubscribed, ...mbSubIdObject }
  })
}

export default getMarketBasketsWithSubscriptionStatuses
