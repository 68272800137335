const getColumns = () => [
  {
    Header: 'Provider Slug',
    accessor: 'providerSlug',
  },
  {
    Header: 'Provider Account Name',
    accessor: 'providerAccountName',
  },
  {
    Header: 'Provider Affiliate Name',
    accessor: 'providerAffiliateName',
  },
  {
    Header: 'Provider Affiliate NPI',
    accessor: 'npi',
  },
  {
    Header: '340B Participation',
    accessor: 'participation340bStatus',
  },
  {
    Header: 'Participating Start Date',
    accessor: 'participatingStartDate',
  },
  {
    Header: 'Termination Date',
    accessor: 'terminationDate',
  },
  {
    Header: 'Medicare Provider Number (CCN)',
    accessor: 'ccn',
  },
  {
    Header: 'Covered Entity Address 1',
    accessor: 'coveredEntityAddress1',
  },
  {
    Header: 'Covered Entity Address 2',
    accessor: 'coveredEntityAddress2',
  },
  {
    Header: 'Covered Entity Address 3',
    accessor: 'coveredEntityAddress3',
  },
  {
    Header: 'Covered Entity City',
    accessor: 'coveredEntityCity',
  },
  {
    Header: 'Covered Entity State',
    accessor: 'coveredEntityState',
  },
  {
    Header: 'Covered Entity Zip Code',
    accessor: 'coveredEntityZipCode',
  },
]

export default getColumns
