import dayjs, { Dayjs } from 'dayjs'
import { zonedTimeToUtc } from 'date-fns-tz'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { getSlotProps } from './getSlotProps'

const DEFAULT_TIMEZONE = 'America/New_York'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault(DEFAULT_TIMEZONE)

const MIN_DATE = dayjs.tz('1900-01-01')

export const DATE_TIME_PICKER_PROPS = {
  TIMEZONE: DEFAULT_TIMEZONE,
  VIEWS: ['year', 'month', 'day'],
  GET_SLOT_PROPS: (props: any) =>
    getSlotProps(props, MIN_DATE, DEFAULT_TIMEZONE),
  MIN_DATE_TIME: MIN_DATE,
  GET_VALUE: (props: { value: string }): dayjs.Dayjs | null =>
    props.value
      ? dayjs.tz(zonedTimeToUtc(props.value, DEFAULT_TIMEZONE))
      : null,
  GET_ON_CHANGE: (props: { onChange: Function; name: string }) => (
    value: Dayjs | null
  ) =>
    props.onChange({
      name: props.name,
      value: value && zonedTimeToUtc(value.format(), DEFAULT_TIMEZONE),
    }),
}
