import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

import { Buttons } from './Buttons'
import { ClientSourceTag } from './ClientSourceTag'

interface Props {
  isHoveredListItem: boolean
  isSelected: boolean
  data: any
}

const Wrapper = styled.div({
  width: '100%',
})

const NameButtons = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const TagDescription = styled.div({
  display: 'flex',
  flexDirection: 'row',
  padding: '0 12px 6px 14px',
})

const Name = styled.div({
  fontWeight: 600,
  fontSize: 12,
  padding: '12px 0 8px 14px',
  color: Colors.BLACK,
})

const Description = styled.div({
  fontWeight: 400,
  fontSize: 11,
  fontStyle: 'italic',
  width: 'auto',
  color: Colors.GRAY_500,
})

const activeStyle = {
  color: Colors.PRIMARY,
}

export const Item = ({ isHoveredListItem, isSelected, data }: Props) => {
  const textStyle = isSelected || isHoveredListItem ? activeStyle : {}

  return (
    <Wrapper>
      <NameButtons>
        <Name style={textStyle}>{data.name}</Name>
        <Buttons
          isHoveredListItem={isHoveredListItem}
          isSelected={isSelected}
          id={data.id}
        />
      </NameButtons>
      {(data.client_source || data.description) && (
        <TagDescription>
          <ClientSourceTag
            isHoveredListItem={isHoveredListItem}
            isSelected={isSelected}
            clientSource={data.client_source}
          />
          <Description style={textStyle}>{data.description}</Description>
        </TagDescription>
      )}
    </Wrapper>
  )
}
