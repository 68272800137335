import React, { useState } from 'react'
import styled from '@emotion/styled'
import format from 'date-fns/format'

import { Button } from '@pulse-analytics/pulse-design-system'
import Icon from 'frontend/components/Icon'

import Color from 'frontend/utils/color'
import Spacing from 'frontend/utils/spacing'

import { useRewriteSearchString } from 'frontend/hooks'

import { LINK_STATUS_ALERT_LABELS } from 'frontend/PayerPolicyLinkMgmt/v0/utils/enums'

import ImportPolicyPdfModal from '../../ImportPolicyPdfModal'

import CopyButton from './CopyButton'
import ImportButton from './ImportButton'

const fontStyles = {
  fontSize: 12,
  lineHeight: '18px',
  letterSpacing: -0.1,
  fontWeight: 500,
}

const Container = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '6px 12px',
  ...fontStyles,
})

const LeftContent = styled.section({
  display: 'flex',
  justifyContent: 'space-between',
  width: '75%',
  alignItems: 'center',
})

export const buttonStyle = {
  padding: Spacing.S3,
  margin: Spacing.S3,
}

const DATA_PRIMARY_YELLOW = '#FFCD1D'

const formatDate = (date) => format(new Date(date), 'PP')

const PolicyModalHeader = ({
  data,
  linkStatusLabelMap,
  closeModal,
  handleUpdate,
  setPolicyContentInput,
}) => {
  const rewriteSearchString = useRewriteSearchString()

  const setShowModalSearchString = (shouldShow) => {
    const args = {
      shouldShow,
    }
    rewriteSearchString(args)
  }

  const {
    last_approved_change_date,
    last_identified_change_date,
    policy_doc_link,
    policy_doc_link_change,
    policy_doc_link_change_source,
    link_status,
  } = data

  let shouldShowPolicyAlert = false

  if (!LINK_STATUS_ALERT_LABELS.has(linkStatusLabelMap[link_status])) {
    if (last_identified_change_date && last_approved_change_date) {
      shouldShowPolicyAlert =
        last_identified_change_date >= last_approved_change_date
    } else if (last_identified_change_date && !last_approved_change_date) {
      shouldShowPolicyAlert = true
    }
  }

  const lastApprovedDate = last_approved_change_date
    ? formatDate(last_approved_change_date)
    : 'N/A'

  const onButtonClick = () => {
    setShowModalSearchString(true)
  }

  return (
    <Container>
      <LeftContent>
        <section>
          <span style={{ padding: '0 12px' }}>
            Last Approved Policy: {lastApprovedDate}
          </span>
          <CopyButton text={policy_doc_link} />
        </section>
        <section style={{ display: 'flex' }}>
          {shouldShowPolicyAlert && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                height={18}
                width={18}
                iconName={'caution-solid'}
                color1={DATA_PRIMARY_YELLOW}
              />
              <span
                style={{
                  ...fontStyles,
                  padding: '0 12px 0 6px',
                }}
              >
                Latest Policy Downloaded on{' '}
                {formatDate(last_identified_change_date)}
              </span>
            </div>
          )}
          <ImportButton onClick={onButtonClick} />

          <CopyButton text={policy_doc_link_change} />
          <a
            href={policy_doc_link_change_source}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              type="secondary"
              style={{
                padding: Spacing.S2,
                margin: Spacing.S2,
              }}
            >
              <span style={{ padding: '0 4px' }}>Source</span>
              <Icon
                height={14}
                width={14}
                iconName={'link'}
                color1={Color.PRIMARY}
              />
            </Button>
          </a>
        </section>
      </LeftContent>
      <section>
        <Button onClick={handleUpdate} style={buttonStyle}>
          Save
        </Button>
        <Button
          onClick={closeModal}
          style={buttonStyle}
          color={Color.GRAY_DARK}
          type="secondary"
        >
          Cancel
        </Button>
      </section>
      <ImportPolicyPdfModal
        policyId={data.policyId}
        productId={data.product}
        productPolicyId={data.productId}
        setShowModalSearchString={setShowModalSearchString}
        setPolicyContentInput={setPolicyContentInput}
      />
    </Container>
  )
}

export default PolicyModalHeader
