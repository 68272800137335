import _ from 'lodash'

import { LINK } from 'frontend/PayerPolicyLinkMgmt/v0/utils/enums'
import { PRODUCT_POLICY_FIELDS } from '../PolicyContent/PolicyInfo/utils'

export const getProductPolicyInput = ({ input }: any) => {
  const productPolicyInput = PRODUCT_POLICY_FIELDS.reduce(
    (acc: any, key: any) => {
      let value = input[key]

      if (key === LINK && _.isEmpty(value)) {
        value = null
      }

      acc[key] = value
      return acc
    },
    {}
  )
  return productPolicyInput
}
