import { INDICATION, REGIMEN, PATHWAYS_ORGANIZATION } from './utils'
import updateLatestPathwaysPositionsOnCreate from './updateLatestPathwaysPositionsOnCreate'
import updateHistoricalPathwaysPositionsOnCreate from './updateHistoricalPathwaysPositionsOnCreate'

const getPositioningTableColumnsInjectables = ({
  positioningData,
  indications,
  regimens,
  pathwaysOrgs,
  openHistoricalPositionModal,
}) => {
  const onCreateButtonClick = (input) => {
    positioningData.create({
      variables: { input },
      update: (
        cache,
        {
          data: {
            createPathwaysIndicationRegimenPosition: createdPathwaysPosition,
          },
        }
      ) => {
        // TODO: Remove stubbing of fda_indication_regimen_approval when it is patched to be returned on createPathwaysIndicationRegimenPosition
        /* 
          fda_indication_regimen_approval needs to be stubbed because it is defined in the return fields of the api.
          Vega doesn't currently return fda_indication_regimen_approval on createPathwaysIndicationRegimenPosition.
          When createPathwaysIndicationRegimenPosition goes into the query's data,
          it causes the data to be undefined because the field is missing on the new entity.
        */
        createdPathwaysPosition.fda_indication_regimen_approval = null

        const fieldLabelMap = {
          [INDICATION]: indications.labelMap,
          [REGIMEN]: regimens.labelMap,
          [PATHWAYS_ORGANIZATION]: pathwaysOrgs.labelMap,
        }
        updateLatestPathwaysPositionsOnCreate(
          cache,
          createdPathwaysPosition,
          fieldLabelMap
        )
        updateHistoricalPathwaysPositionsOnCreate(
          cache,
          createdPathwaysPosition
        )
      },
    })
  }

  const onEdit = (input) => {
    positioningData.update({ variables: { input } })
  }

  //! onClick will be passed just row.original, so onClick(row.original) instead of onClick(rowState, row.original)
  const onHistoryButtonClick = (row) => {
    openHistoricalPositionModal(row)
  }

  return {
    onCreateButtonClick,
    onEdit,
    onHistoryButtonClick,
  }
}

export default getPositioningTableColumnsInjectables
