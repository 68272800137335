import React from 'react'

const Info = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="-2 -5 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.99967 0.333336C10.6747 0.333336 13.6663 3.325 13.6663 7C13.6663 10.675 10.6747 13.6667 6.99967 13.6667C3.32467 13.6667 0.333008 10.675 0.333008 7C0.333008 3.325 3.32467 0.333336 6.99967 0.333336ZM7.33301 5.66667H6.66634C6.48225 5.66667 6.33301 5.81591 6.33301 6V9.33334C6.33301 9.51743 6.48225 9.66667 6.66634 9.66667H7.33301C7.5171 9.66667 7.66634 9.51743 7.66634 9.33334V6C7.66634 5.81591 7.5171 5.66667 7.33301 5.66667ZM7.33301 3.66667H6.66634C6.48225 3.66667 6.33301 3.81591 6.33301 4V4.66667C6.33301 4.85076 6.48225 5 6.66634 5H7.33301C7.5171 5 7.66634 4.85076 7.66634 4.66667V4C7.66634 3.81591 7.5171 3.66667 7.33301 3.66667Z"
        fill={iconColor}
      />
    </svg>
  )
}
export default Info
