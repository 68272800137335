import _ from 'lodash'

import {
  NEEDS_APPROVAL,
  APPROVED,
  INCORRECT_POLICY,
  LINK_STATUS_ALERT_LABELS,
} from './enums'

export const getPayerProductData = ({
  payers,
  payerPolicyData,
  productPolicyData,
  policyOptions,
}) => {
  const payerPolicyDataById = _.keyBy(payerPolicyData, 'id')
  const payerDataById = _.keyBy(payers, 'id')
  const linkStatusLabelMap = policyOptions.link_status.labelMap
  if (!productPolicyData) return []

  return productPolicyData.reduce((acc, datum) => {
    const {
      id: productPolicyId,
      policy_link,
      approval_status,
      link_status,
    } = datum
    const linkData = payerPolicyDataById[policy_link]
    if (!linkData) return acc

    const { id: policyLinkId, payer } = linkData
    const payerData = payerDataById[payer]
    const slug = payerData ? payerData.slug : null

    const shouldAlert = shouldPolicyBeAlerted({
      approval_status,
      link_status,
      linkStatusLabelMap,
    })

    // * product data (datum) must always be de-structured last to account for edge case where we have overlapping fields
    return acc.concat({
      ...linkData,
      ...datum,
      slug,
      productPolicyId,
      policyLinkId,
      shouldAlert,
    })
  }, [])
}

const shouldPolicyBeAlerted = ({
  approval_status,
  link_status,
  linkStatusLabelMap,
}) => {
  // * return true if link status is broken
  // * or if the policy approval status needs approval || has been approved
  return (
    LINK_STATUS_ALERT_LABELS.has(linkStatusLabelMap[link_status]) ||
    [APPROVED, INCORRECT_POLICY, NEEDS_APPROVAL].includes(approval_status)
  )
}
