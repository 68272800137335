import React from 'react'
import styled from '@emotion/styled'

import { Spacing } from 'frontend/utils/pulseStyles'

import IndicationLabel from './IndicationLabel'

const ListHeader = styled.div({
  fontSize: 16,
  fontWeight: 700,
  lineHeight: '22px',
  padding: `${Spacing.NORMAL} ${Spacing.LARGE}`,
})

const Header = () => {
  return (
    <ListHeader>
      Regimens / <IndicationLabel />
    </ListHeader>
  )
}

export default Header
