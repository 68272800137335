import React from 'react'

import { EditableDataTable } from 'frontend/components'
import { useRewriteSearchString } from 'frontend/hooks'

import CreateButton from './CreateButton'
import CreateModal from './CreateModal'
import DeleteDialog from './DeleteDialog'

import { getColConfig, useNccnPanelsData } from './utils'

const NccnPanels = () => {
  const addDeleteSearchString = useAddDeleteSearchString()

  const nccnPanelsData = useNccnPanelsData()

  const isLoading = nccnPanelsData.isLoading
  const colConfig = getColConfig(nccnPanelsData, addDeleteSearchString)
  const data = nccnPanelsData.nccnPanels.data ?? []

  const utilityBarNodes = [<CreateButton />]

  return (
    <>
      <DeleteDialog />
      <CreateModal />
      <EditableDataTable
        tableId={'nccn-panels-table'}
        hasRowCount
        isLoading={isLoading}
        colConfig={colConfig}
        data={data}
        excelLabel={'NCCN Panels'}
        utilityBarNodes={utilityBarNodes}
      />
    </>
  )
}

export default NccnPanels

const useAddDeleteSearchString = () => {
  const rewriteSearchString = useRewriteSearchString()

  const addDeleteSearchString = ({ id }) => {
    const deleteSearchArgs = {
      deleteNccnPanel: true,
      nccnPanelId: id,
    }
    rewriteSearchString(deleteSearchArgs)
  }

  return addDeleteSearchString
}
