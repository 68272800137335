import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import _ from 'lodash'

import { CREATE_BULK_USERS } from 'frontend/api/mutations'
import { GET_USERS } from 'frontend/entity-hooks/ember/useEmberUser/gql-tags'

import { Colors } from 'frontend/utils/pulseStyles'

import Status from './Status'
import { upload } from './upload'

interface StatusState {
  errors?: string[]
  fileName?: string
  tempUsers?: any[]
  users?: any[]
}

const TEXT = 'Upload the filled-out file to bulk create users.'

const Container = styled.section({
  paddingLeft: 48,
  paddingRight: 96,
})

const Title = styled.div({
  color: Colors.PRIMARY,
  fontWeight: 700,
  padding: '12px 0',
})

const UploadSection = () => {
  const [status, setStatus] = useState<StatusState>({})

  const [createBulkUsers, { loading: isLoading }] = useMutation(
    CREATE_BULK_USERS,
    {
      onCompleted: () => {
        setStatus(({ fileName, tempUsers }) => {
          return {
            fileName,
            users: tempUsers,
          }
        })
      },
      onError: (e) => {
        setStatus(({ fileName }) => {
          const errors = e.message.split('\n')
          return {
            errors,
            fileName,
          }
        })
      },
      update: (cache, { data: { createBulkUsers } }) => {
        const { client, teams } = createBulkUsers[0]
        const clientQuery: any = cache.readQuery({
          query: GET_USERS,
          variables: { input: { client: client.id } },
        })
        if (clientQuery) {
          cache.writeQuery({
            query: GET_USERS,
            variables: { input: { client: client.id } },
            data: {
              emberUsers: [...createBulkUsers, ...clientQuery.emberUsers],
            },
          })
        }

        const teamQuery: any = cache.readQuery({
          query: GET_USERS,
          variables: { input: { team: teams[0].id } },
        })

        if (teamQuery) {
          cache.writeQuery({
            query: GET_USERS,
            variables: { input: { team: teams[0].id } },
            data: {
              emberUsers: [...createBulkUsers, ...teamQuery.emberUsers],
            },
          })
        }
      },
    }
  )

  return (
    <Container>
      <Title>STEP 2</Title>
      <p>{TEXT}</p>
      <div style={{ display: 'flex', paddingTop: 24 }}>
        <div style={{ flex: 1 }}>
          <input
            onChange={(e) => upload(e, setStatus, createBulkUsers)}
            type={'file'}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Status isLoading={isLoading} status={status} />
        </div>
      </div>
    </Container>
  )
}

export default UploadSection
