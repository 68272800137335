import React from 'react'

interface Checkbox {
  name: string
  onChange: any
  value: any
  label: string
  style?: any
  labelStyle?: any
}

const Checkbox = ({
  name,
  onChange,
  value,
  label,
  style,
  labelStyle,
}: Checkbox) => {

  const handleChange = ({ target: { name, checked } }: any) => (
    onChange({ target: { name, value: checked } })
  )

  return (
    <label style={labelStyle}>
      <input
        type="checkbox"
        name={name}
        onChange={handleChange}
        checked={value}
        style={style}
      />
      {label}
    </label>
  )
}

export default Checkbox
