import React from 'react'
import { FormModal } from 'frontend/components/Modal/FormModal'

interface UpdateModalProps {
  handleCancel: () => void
  handleSubmit: () => void
  data: any
  setData: (arg: any) => void
  buttonRef: React.RefObject<HTMLButtonElement>
  formConfig: any
  isLoading: boolean
}

const HEADER = 'Update Start and End Date'
const SUBMIT_TEXT = 'Update'

export const UpdateModal = ({
  handleCancel,
  handleSubmit,
  data,
  setData,
  buttonRef,
  formConfig,
  isLoading,
}: UpdateModalProps) => {
  return (
    <FormModal
      header={HEADER}
      submitText={SUBMIT_TEXT}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      data={data}
      setData={setData}
      buttonRef={buttonRef}
      formConfig={formConfig}
      isLoading={isLoading}
    />
  )
}
