import formatDataForExport from 'frontend/components/ExportExcelButton/formatDataForExport'

const EXPORT_FILENAME = 'ClinicalTrials'

const getClinicalTrialsExportConfig = (clinicalTrialColumns: any) => {
  const formatExportRows = (rows: any) =>
    formatDataForExport({
      data: rows,
      columns: clinicalTrialColumns,
      isReactTableData: true,
    })

  return { filename: EXPORT_FILENAME, formatRows: formatExportRows }
}

export default getClinicalTrialsExportConfig
