import _ from 'lodash'

const validatePermissionTeam = (data) => {
  const providerChannelProductsRaw = data['Provider Channel Products']
  const clientPermissionsRaw = data['NCR Client Permissions']
  const providerChannelRaw = data['Client Provider Channel']

  // * removing the first two rows
  const providerChannelProduct = providerChannelProductsRaw.slice(2)
  const clientPermissions = clientPermissionsRaw.slice(2)
  const providerChannel = providerChannelRaw.slice(2)

  const providerChannelProductsTeams = _.keyBy(
    providerChannelProduct,
    ({ client, team }) => `${client}-${team}`
  )
  const clientPermissionsTeams = _.keyBy(
    clientPermissions,
    ({ client, team }) => `${client}-${team}`
  )
  const providerChannelTeams = _.keyBy(
    providerChannel,
    ({ client, team }) => `${client}-${team}`
  )

  const teamsSuperSet = new Set(
    [
      Object.keys(providerChannelProductsTeams),
      Object.keys(clientPermissionsTeams),
      Object.keys(providerChannelTeams),
    ].flat()
  )

  const teamValidation = Array.from(teamsSuperSet).reduce((acc, team) => {
    const hasProviderChannelProducts = Boolean(
      providerChannelProductsTeams[team]
    )
    const hasClientPermissions = Boolean(clientPermissionsTeams[team])
    const hasProviderChannel = Boolean(providerChannelTeams[team])

    if (
      !hasProviderChannelProducts ||
      !hasClientPermissions ||
      !hasProviderChannel
    ) {
      acc.push({
        team,
        hasProviderChannelProducts: hasProviderChannelProducts ? '✅' : '❌',
        hasClientPermissions: hasClientPermissions ? '✅' : '❌',
        hasProviderChannel: hasProviderChannel ? '✅' : '❌',
      })
    }

    return acc
  }, [])

  return teamValidation
}

export default validatePermissionTeam
