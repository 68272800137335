import { gql } from '@apollo/client'
import { schemaV1 } from 'frontend/entity-hooks/useProductPolicyLink/gql-tags/schemas'

export const UPDATE_IMPORT_POLICY_LINK = gql`
  mutation UpdateImportPolicyLink($input: UpdateImportPolicyLinkInput!) {
    updateImportPolicyLink(input: $input) {
      updatedProductPolicyLinks {
        ${schemaV1}
      }
    }
  }
`
