import _ from 'lodash'
import XLSX from 'xlsx'

export const processExcelFile = (e, name, onChange, fileName) => {
  const arrayData = new Uint8Array(e.target.result)

  const workbook = XLSX.read(arrayData, { type: 'array' })
  const sheetNames = workbook.SheetNames
  const data = sheetNames.reduce((acc, sheetName) => {
    const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
      blankrows: false,
      defval: null,
    })

    return {
      [_.snakeCase(sheetName)]: sheetData,
      ...acc,
    }
  }, {})

  onChange({ fileName, name, value: data })
}
