import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

import Label from './Label'

const Wrapper = styled.h2({
  color: Colors.BLACK,
  fontSize: 14,
  fontWeight: 700,
})

const Title = () => (
  <Wrapper>
    <Label />
  </Wrapper>
)

export default Title
