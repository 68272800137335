import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import BulkCreateUsersModal from './BulkCreateUsersModal'

const BulkCreateUsersModalContainer = () => {
  const isModalClosed = useIsModalClosed()

  if (isModalClosed) return null

  return <BulkCreateUsersModal />
}

export default BulkCreateUsersModalContainer

const useIsModalClosed = () => {
  const location = useLocation()
  const queryStringObj = queryString.parse(location.search)
  const { bulkCreateUsers } = queryStringObj
  return bulkCreateUsers !== 'true'
}
