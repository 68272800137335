import gql from 'graphql-tag'

export const GET_VEGA_PATHWAYS_INFLUENCERS = gql`
  query getVegaPathwaysInfluencers {
    vegaPathwaysInfluencers {
      id
      person
      pathways_organization
      pathways_influencer_types
      indication_permissions
      tumor_type_specialty
      position
      priority
      start_date
      start_quarter
      end_date
      end_quarter
      source
      contact
      internal_role
      value_chair_indications
      internal_tdg_note
      internal_pathways_management_types
      is_excluded
      exclusion_reason
      alert_date
      alert_type
      alert_description
      created_at
      updated_at
    }
  }
`

export const GET_VEGA_PATHWAYS_INFLUENCERS_OPTIONS = gql`
  query getVegaPathwaysInfluencersOptions {
    vegaPathwaysInfluencersOptions
  }
`

export const CREATE_PATHWAYS_INFLUENCER_CONNECTION = gql`
  mutation CreatePathwaysInfluencer($input: CreatePathwaysInfluencerInput!) {
    createPathwaysInfluencer(input: $input) {
      id
      person
      pathways_organization
      indication_permissions
      pathways_influencer_types
      tumor_type_specialty
      internal_tdg_note
      internal_pathways_management_types
      value_chair_indications
      position
      priority
      alert_date
      alert_type
      alert_description
      is_excluded
      exclusion_reason
      start_date
      end_date
      start_quarter
      end_quarter
    }
  }
`
export const UPDATE_PATHWAYS_INFLUENCER_CONNECTION = gql`
  mutation UpdatePathwaysInfluencer($input: UpdatePathwaysInfluencerInput!) {
    updatePathwaysInfluencer(input: $input) {
      id
      person
      pathways_organization
      indication_permissions
      pathways_influencer_types
      tumor_type_specialty
      internal_tdg_note
      internal_pathways_management_types
      value_chair_indications
      position
      priority
      alert_date
      alert_type
      alert_description
      is_excluded
      exclusion_reason
      start_date
      end_date
      start_quarter
      end_quarter
    }
  }
`

export const DELETE_PATHWAYS_INFLUENCER_CONNECTION = gql`
  mutation DeletePathwaysInfluencer($input: DeletePathwaysInfluencerInput!) {
    deletePathwaysInfluencer(input: $input) {
      id
      person
      pathways_organization
      indication_permissions
      pathways_influencer_types
      tumor_type_specialty
      internal_tdg_note
      internal_pathways_management_types
      value_chair_indications
      position
      priority
      alert_date
      alert_type
      alert_description
      is_excluded
      exclusion_reason
      start_date
      end_date
      start_quarter
      end_quarter
    }
  }
`
export default {
  GET_VEGA_PATHWAYS_INFLUENCERS,
  CREATE_PATHWAYS_INFLUENCER_CONNECTION,
  UPDATE_PATHWAYS_INFLUENCER_CONNECTION,
  DELETE_PATHWAYS_INFLUENCER_CONNECTION,
}
