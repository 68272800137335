import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

interface ItemProps {
  isHoveredListItem: boolean
  isSelected: boolean
  name: string
  icon: string
}

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const ImageContainer = styled.div({
  background: Colors.WHITE,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 24,
  height: 24,
  marginRight: 16,
  '@media (max-width: 1000px)': {
    marginRight: 4,
  },
})

const Client = styled.span({
  fontSize: 12,
  fontWeight: 600,
  color: transparentize(0.3, '#000000'),
  '@media (max-width: 1000px)': {
    display: 'none',
  },
})

const activeStyle = {
  color: Colors.PRIMARY,
}

const Item = ({ isHoveredListItem, isSelected, name, icon }: ItemProps) => {
  const textStyle = isSelected || isHoveredListItem ? activeStyle : {}

  return (
    <Wrapper>
      <ImageContainer>
        <img style={{ width: 20 }} src={icon} alt={`${name} icon`} />
      </ImageContainer>
      <Client style={textStyle}>{name}</Client>
    </Wrapper>
  )
}

export default Item
