import formatDataForExport from 'frontend/components/ExportExcelButton/formatDataForExport'

const getExportConfig = (filename: string, columns: any, ) => {
  const formatExportRows = (rows: any) => formatDataForExport({
    columns,
    data: rows,
    isReactTableData: true,
  })

  return { filename: filename, formatRows: formatExportRows }
}

export default getExportConfig
