import React from 'react'
import styled from '@emotion/styled'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import { transparentize, mix } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

const Wrapper = styled.div({
  alignItems: 'center',
  borderBottom: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
  borderRadius: 8,
  color: Colors.BLACK,
  display: 'flex',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '18px',
  marginBottom: 8,
  paddingLeft: '12px',
  ':hover': {
    backgroundColor: transparentize(0.92, Colors.BLACK),
  },
})

const PhoenixSwitch = withStyles({
  switchBase: {
    color: mix(0.4, Colors.BLACK, Colors.WHITE),
    '&$checked': {
      color: Colors.GREEN,
    },
    '&$checked + $track': {
      backgroundColor: Colors.GREEN,
    },
  },
  checked: {},
  track: {
    backgroundColor: transparentize(0.7, Colors.BLACK),
  },
})(Switch)

export const ListItem = ({ checked, label, resource, toggleResource }) => (
  <Wrapper>
    <PhoenixSwitch
      checked={checked}
      onChange={() => toggleResource(resource)}
    />
    {label}
  </Wrapper>
)
