const getUniqueFilterOptions = (id, preFilteredRows) => {
  const options = new Set()
  preFilteredRows.forEach((row) => {
    const rowValue = row.values[id]
    if (Array.isArray(rowValue)) {
      for (const option of rowValue) {
        options.add(option)
      }
    } else {
      options.add(rowValue)
    }
  })
  return [...options.values()]
}

export default getUniqueFilterOptions
