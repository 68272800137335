import { useQuery } from "@apollo/client"
import { MetaHistoryQueryInput } from "frontend/interfaces"

import tag from './gql-tag'

const useRead = (input?: MetaHistoryQueryInput) => useQuery(
  tag,
  {
    variables: { input },
  }
)

export default useRead
