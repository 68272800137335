import React, { Dispatch, SetStateAction } from 'react'

import { Label, Wrapper } from '../shared'
import { InnerContainer } from './InnerContainer'

interface Props {
  fieldState: any
  setFieldState: Dispatch<SetStateAction<any>>
}

const LABEL_TEXT = 'Teams'

export const OuterContainer = ({ fieldState, setFieldState }: Props) => {
  const {
    client: { id: selectedClientId },
    teams: selectedTeams,
  } = fieldState

  return (
    <Wrapper>
      <Label required={true}>
        {LABEL_TEXT} <span className="required">(required)</span>
      </Label>
      <InnerContainer
        selectedClientId={selectedClientId}
        selectedTeams={selectedTeams}
        setFieldState={setFieldState}
      />
    </Wrapper>
  )
}
