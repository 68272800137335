import styled from '@emotion/styled'

import { Tab, tabClasses } from '@mui/base/Tab'

export const StyledTab = styled(Tab)`
  font-family: 'Inter, Arial, sans-serif',
  font-size: 12px;
  font-weight: 700;
  background-color: #F1F5FA;
  color: #607282;
  width: 110px;
  height: 30px;
  padding: 6px 8px;
  margin: 12px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #DDE9F4;
    cursor: pointer;
  }

  &.${tabClasses.selected} {
    background-color: rgba(6, 104, 217, 0.15);
    color: #0668D9;

    &:hover {
      background-color: rgba(6, 104, 217, 0.25);
    }
  }
`
