import Color from 'frontend/utils/color'

const defaultStyle = {
  menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
}

const invalidStyle = {
  control: (provided: any) => ({
    ...provided,
    borderColor: Color.RED,
    '&:hover': {
      borderColor: Color.RED,
    },
  }),
}

export const getLabelStyle = (isInvalid: boolean, style: any) =>
  isInvalid
    ? { ...invalidStyle, ...style, ...defaultStyle }
    : { ...style, ...defaultStyle }
