import { useQueryString } from 'frontend/hooks'

export const useQueryInput = () => {
  const {
    client: selectedClient,
    team: selectedTeam,
  }: {
    client?: string
    team?: string
  } = useQueryString()

  let queryInput: any = {}

  if (selectedTeam === 'all-users') {
    queryInput['client'] = selectedClient
  } else {
    queryInput['team'] = selectedTeam
  }
  return queryInput
}
