import React from 'react'

import { DeleteDialog } from 'frontend/components'
import { useEmberNode, useTeamSitemap } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'

import { LABEL } from './constants'

const Dialog = () => {
  const { node, nodeType }: any = useQueryString()

  const { destroy, isDestroying } = useTeamSitemap()

  const { data }: any = useEmberNode({
    queryInput: {
      type: nodeType,
      id: node,
    },
  })

  const entityDescription = `${data[0].name} (${nodeType}) from teams and sitemaps`

  const removeFromSitemaps = () => {
    destroy({
      variables: {
        input: {
          nodeType,
          [`${nodeType}_id`]: node,
        },
      },
    })
  }

  return (
    <DeleteDialog
      isDeleting={isDestroying}
      entityDescription={entityDescription}
      handleDelete={removeFromSitemaps}
      label={LABEL}
      searchStringParams={['removeFromSitemaps']}
    />
  )
}

export default Dialog
