import { gql } from '@apollo/client'

export const GET_PATHWAYS_INFLUENCERS = gql`
  query GetPathwaysInfluencers {
    vegaPathwaysInfluencers {
      id
      person
      pathways_organization
      pathways_influencer_types
      indication_permissions
      tumor_type_specialty
      position
      priority
      start_date
      start_quarter
      end_date
      end_quarter
      source
      contact
      internal_role
      value_chair_indications
      internal_tdg_note
      internal_pathways_management_types
      is_excluded
      exclusion_reason
      alert_date
      alert_type
      alert_description
      created_at
      updated_at
    }
  }
`
