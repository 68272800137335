import React, { Dispatch, SetStateAction } from 'react'
import { useQuery } from '@apollo/client'

import { GET_SOURCE_INDICATIONS } from 'frontend/api/queries'
import { useQueryString } from 'frontend/hooks'

import { ToggleAllButtonsWrapper } from '../../shared'
import ToggleOffAllButton from './ToggleOffAllButton'
import ToggleOnAllButton from './ToggleOnAllButton'

interface Props {
  areParentResourcesLoading: boolean
  parentResources: any[]
  setResources: Dispatch<SetStateAction<any>>
  treatmentPlans?: any[]
}

const ToggleAllButtons = ({
  areParentResourcesLoading,
  parentResources,
  setResources,
  treatmentPlans,
}: Props) => {
  const { indication } = useQueryString()

  const { loading: areIndicationsLoading } = useQuery(GET_SOURCE_INDICATIONS)

  const disabled = !treatmentPlans ||
    areParentResourcesLoading ||
    areIndicationsLoading ||
    !indication

  return (
    <ToggleAllButtonsWrapper>
      <ToggleOnAllButton
        disabled={disabled}
        parentResources={parentResources}
        setResources={setResources}
      />
      <ToggleOffAllButton
        disabled={disabled}
        setResources={setResources}
      />
    </ToggleAllButtonsWrapper>
  )
}

export default ToggleAllButtons
