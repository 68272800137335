import { useLocation } from 'react-router-dom'

export const useQueryStringParams = () => {
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const entityId = queryParams.get('id') ?? undefined
  const personId = queryParams.get('person') ?? undefined
  const secondaryOrganizationId = queryParams.get('secondary_organization') ?? undefined

  return { entityId, personId, secondaryOrganizationId }
}
