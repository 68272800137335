import gql from 'graphql-tag'

export const CREATE_VEGA_FDA_INDICATION_REGIMEN_APPROVAL = gql`
  mutation CreateVegaFdaIndicationRegimenApproval($input: CreateVegaFdaIndicationRegimenApprovalInput!) {
    createVegaFdaIndicationRegimenApproval(input: $input) {
      id
      indication {
        id
        name
        created_at
        updated_at
      }
      regimen {
        id
        name
      }
      populations {
        id
        name
      }
      lines_of_therapy {
        id
        name
      }
      approval_date
      created_at
      updated_at
    }
  }
`

export const DELETE_VEGA_FDA_INDICATION_REGIMEN_APPROVAL = gql`
  mutation DeleteVegaFdaIndicationRegimenApproval($input: DeleteVegaFdaIndicationRegimenApprovalInput!) {
    deleteVegaFdaIndicationRegimenApproval(input: $input) {
      id
      indication {
        id
        name
        created_at
        updated_at
      }
      regimen {
        id
        name
      }
      populations {
        id
        name
      }
      lines_of_therapy {
        id
        name
      }
      approval_date
      created_at
      updated_at
    }
  }
`

export const UPDATE_VEGA_FDA_INDICATION_REGIMEN_APPROVAL = gql`
  mutation UpdateVegaFdaIndicationRegimenApproval($input: UpdateVegaFdaIndicationRegimenApprovalInput!) {
    updateVegaFdaIndicationRegimenApproval(input: $input) {
      id
      indication {
        id
        name
        created_at
        updated_at
      }
      regimen {
        id
        name
      }
      populations {
        id
        name
      }
      lines_of_therapy {
        id
        name
      }
      approval_date
      created_at
      updated_at
    }
  }
`
