import React from 'react'

import { Header } from './Header'
import { TitleSection } from './TitleSection'

const HEADER_TEXT = 'Users'

const UsersPanelHeader = () => (
  <TitleSection>
    <Header>{HEADER_TEXT}</Header>
  </TitleSection>
)

export default UsersPanelHeader
