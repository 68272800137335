import React from 'react'
// @ts-ignore
import { Button } from '@pulse-analytics/pulse-design-system'

import Spacing from 'frontend/utils/spacing'
import styled from '@emotion/styled'

interface Props {
  onSave: () => void
  onCancel: () => void
}

const BUTTON_STYLE = {
  padding: Spacing.S3,
  margin: Spacing.S3,
  fontFamily: 'inherit',
}

const Container = styled.div({
  display: 'inline-flex',
})

export const ModalControls = ({ onCancel, onSave }: Props) => {
  return (
    <Container>
      <Button
        onClick={onCancel}
        style={BUTTON_STYLE}
        color="#607282"
        type="secondary"
      >
        Cancel
      </Button>
      <Button onClick={onSave} style={BUTTON_STYLE}>
        Save
      </Button>
    </Container>
  )
}

export default ModalControls
