import React from 'react'
import styled from '@emotion/styled'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { transparentize } from 'polished'

import { useRewriteSearchString } from 'frontend/hooks'
import { Colors } from 'frontend/utils/pulseStyles'

const Icon = styled(FontAwesomeIcon)({
  borderRadius: 4,
  color: transparentize(0.7, Colors.BLACK),
  cursor: 'pointer',
  ':hover': {
    color: Colors.PRIMARY,
    background: transparentize(0.9, Colors.PRIMARY),
  },
})

const CreateButton = ({ type }) => {
  const rewriteSearchString = useRewriteSearchString()

  const addQueryStringArguments = () =>
    rewriteSearchString({
      nodeType: type,
    })

  if (type === 'tool') return null

  return (
    <button onClick={addQueryStringArguments}>
      <Icon size="lg" icon={faPlus} />
    </button>
  )
}

export default CreateButton
