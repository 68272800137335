import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import _ from 'lodash'

import { GET_SOURCE_INDICATIONS } from 'frontend/api/queries'
import { NODE_TYPE_TO_PARENT_TYPE } from 'frontend/ClientManagement/Sitemap/constants'
import { Params } from 'frontend/ClientManagement/Sitemap/types'
import { useQueryString, useRewriteSearchString } from 'frontend/hooks'

import ActiveList from './ActiveList'
import InactiveList from './InactiveList'

interface Props {
  parentResources: any[]
  treatmentPlans: any[]
  setResources: Dispatch<SetStateAction<any>>
}

const IndicationsListPanel = ({
  parentResources,
  treatmentPlans,
  setResources,
}: Props) => {
  const rewriteSearchString = useRewriteSearchString()

  const { team }: Params = useParams()

  const queryString: any = useQueryString()

  const { indication, node, nodeType }: { [key: string]: string } = queryString

  const parentType = NODE_TYPE_TO_PARENT_TYPE[queryString.nodeType]

  const { data: { indications } } = useQuery(GET_SOURCE_INDICATIONS)

  const indicationIdToLabel = useMemo(() => {
    return indications.reduce((
      acc: { [key: string]: string },
      { _id, name }: any,
    ) => {
      acc[_id] = name
      return acc
    }, {})
  }, [indications])

  const enabledIndicationsSet = treatmentPlans.reduce(
    (acc: Set<string>, { resource }: any) => {
      acc.add(resource)
      return acc
    }, new Set()
  )

  const disabledIndications: any[] = parentType ? parentResources.reduce(
    (acc: any[], {
      id: teamParentResource,
      resource: {
        entity_id,
        type: resourceType,
      },
    }: any) => {
      if (resourceType !== 'indication' ||
        enabledIndicationsSet.has(entity_id)) return acc
      acc.push({
        team,
        [nodeType]: node,
        resource: entity_id,
        [`team_${parentType}_resource`]: teamParentResource,
        children: [],
      })
      return acc
    }, []
  ) : indications.reduce(
    (acc: any[], { _id }: any) => {
      if (enabledIndicationsSet.has(_id)) return acc
      acc.push({
        team,
        [nodeType]: node,
        resource: _id,
        children: [],
      })
      return acc
    }, []
  )

  useEffect(() => {
    if (!indication) {
      let indication
      const indications = _.isEmpty(treatmentPlans) ? disabledIndications : treatmentPlans
      if (!_.isEmpty(indications)) {
        [{ resource: indication }] = indications
      }
      if (indication) rewriteSearchString({ indication })
    }
  }, [])

  const selectResource = (indication: string) => rewriteSearchString({ indication })

  return (
    <>
      <ActiveList
        indicationIdToLabel={indicationIdToLabel}
        indications={treatmentPlans}
        selectResource={selectResource}
        setResources={setResources}
      />
      <InactiveList
        indicationIdToLabel={indicationIdToLabel}
        indications={disabledIndications}
        selectResource={selectResource}
        setResources={setResources}
      />
    </>
  )
}

export default IndicationsListPanel
