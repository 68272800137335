import { gql } from '@apollo/client'

import { schemaV0 } from './schemas'

export const CREATE_EMBER_NODE = gql`
  mutation CreateEmberNode($input: CreateEmberNodeInput!) {
    createEmberNode(input: $input) {
      ${schemaV0}
    }
  }
`
