import { INPUT_TYPES } from 'frontend/components/Form/constants/input-type-to-component-map'
import {
  LINK_TYPE,
  LINK,
  LINK_STEPS,
  LAST_APPROVED_REVIEW_DATE,
  POLICY_SEARCH_WORD,
  DOC_TYPE,
  BENEFIT_TYPE,
  PAYER,
  PRODUCT,
  BOOK,
  LINK_NOTE,
  APPROVAL_STATUS,
  ASSIGNED_POLARIS_USER_AUTH_IDS,
  PRODUCT_PAYER_POLICY_TRACKING_PROJECTS,
} from 'frontend/PayerPolicyLinkMgmt/v0/utils/enums'

export const POLICY_LINK_FIELDS = [DOC_TYPE]

export const PRODUCT_POLICY_FIELDS = [
  APPROVAL_STATUS,
  LAST_APPROVED_REVIEW_DATE,
  LINK,
  LINK_TYPE,
  LINK_NOTE,
  LINK_STEPS,
  POLICY_SEARCH_WORD,
  ASSIGNED_POLARIS_USER_AUTH_IDS,
  PRODUCT_PAYER_POLICY_TRACKING_PROJECTS,
]

export const POLICY_INFO_FORM_CONFIG = [
  {
    label: 'Need Policy Approval',
    accessor: APPROVAL_STATUS,
    inputType: INPUT_TYPES.SINGLE_SELECT,
    isRequired: false,
  },
  {
    label: 'Policy Last Updated By Payer',
    accessor: LAST_APPROVED_REVIEW_DATE,
    inputType: INPUT_TYPES.DATE,
    isRequired: false,
  },
  {
    label: 'Link Notes',
    accessor: LINK_NOTE,
    inputType: INPUT_TYPES.TEXT,
    isRequired: false,
  },
  {
    label: 'Starting Link',
    accessor: LINK,
    inputType: INPUT_TYPES.TEXT,
    isRequired: false,
  },
  {
    label: 'Project',
    accessor: PRODUCT_PAYER_POLICY_TRACKING_PROJECTS,
    inputType: INPUT_TYPES.MULTI_SELECT,
    isRequired: true,
  },
  {
    label: 'Assignee',
    accessor: ASSIGNED_POLARIS_USER_AUTH_IDS,
    inputType: INPUT_TYPES.MULTI_SELECT,
    isRequired: false,
  },
  {
    label: 'Document Type',
    accessor: DOC_TYPE,
    inputType: INPUT_TYPES.SINGLE_SELECT,
    isRequired: true,
  },
  {
    label: 'Link Steps',
    accessor: LINK_STEPS,
    inputType: INPUT_TYPES.TEXT,
    isRequired: false,
  },
  {
    label: 'Search Term(s)',
    accessor: POLICY_SEARCH_WORD,
    inputType: INPUT_TYPES.TEXT,
    isRequired: false,
  },
]

export const LABELS_TO_MAP = [PAYER, PRODUCT, BOOK, BENEFIT_TYPE]
