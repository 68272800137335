import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const UPDATE_TEAM = gql`
  mutation UpdateTeam($input: UpdateTeamInput_EMBER!) {
    updateTeam_EMBER(input: $input) {
      ${schemaV0}
    }
  }
`
