import styled from 'styled-components'
import { transparentize, mix } from 'polished'

import Color from 'frontend/utils/color'

const borderColor = Color.LIGHT_GRAY_1

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 100%;
  overflow: auto;

  .table {
    width: 100%;
    border-collapse: collapse;
    color: ${Color.BLACK};
    border-top: 1px solid ${borderColor};
    border-bottom: 1px solid ${borderColor};

    .th {
      overflow: visible;
      font-size: 12px;
      font-weight: 800;
      padding: 8px;
    }

    .th,
    .td {
      border-bottom: 2px solid ${borderColor};
      border-right: 2px solid ${transparentize(0.5, borderColor)};
      background-color: ${Color.WHITE};
      font-size: 12px;
      line-height: 1.5;
    }

    &.sticky {
      overflow: auto;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 2px 0px ${borderColor};
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -2px 0px ${borderColor};
      }

      .body {
        position: relative;
        z-index: 0;
      }

      .tr:hover {
        .td {
          background: ${transparentize(0.5, Color.LIGHT_GRAY_1)} !important;
        }
        [data-sticky-td] {
          background: ${mix(0.5, Color.WHITE, Color.LIGHT_GRAY_1)} !important;
        }
        .th {
          background-color: ${Color.WHITE} !important;
        }
      }

      [data-sticky-td] {
        position: sticky;
        background: ${Color.WHITE} !important;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 0px ${borderColor};
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 0px ${borderColor};
      }
    }
  }
`

export default Styles
