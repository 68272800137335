import React from 'react'
import styled from '@emotion/styled'

import { CustomLink } from '../../CustomLink'

interface Props {
  payerLink: string
  s3Link: string
}

const ICON_STYLES = {
  height: '16px',
  iconColor: '#0668D9',
  marginRight: '2px',
  width: '16px'
}

const Wrapper = styled.div({
  display: 'flex',
  gap: '12px'
})

const Links = ({
  payerLink,
  s3Link
}: Props) => (
  <Wrapper>
    <CustomLink
      link={payerLink}
      text={'Payer Site'}
      iconStyles={ICON_STYLES}
    />
    <CustomLink
      link={s3Link}
      text={'S3 Link'}
      iconStyles={ICON_STYLES}
    />
  </Wrapper>
)

export default Links
