import format from 'date-fns/format'

import {
  BENEFIT_TYPE,
  LINK_STATUS,
  BOOK,
  APPROVAL_STATUS,
  PAYER,
  PRODUCT,
  DOC_TYPE,
  LINK_TYPE,
  LINK,
  LINK_STEPS,
  POLICY_SEARCH_WORD,
  LINK_NOTE,
  LAST_APPROVED_REVIEW_DATE,
  LAST_TDG_REVIEW_DATE,
  LAST_IDENTIFIED_CHANGE_DATE,
  ASSIGNED_POLARIS_USER_AUTH_IDS,
  PRODUCT_PAYER_POLICY_TRACKING_PROJECTS,
} from 'frontend/PayerPolicyLinkMgmt/v0/utils/enums'

import { mapIdToLabelCellFormatter } from '../../../Orion/Organizations/Pathways/Positioning/utils/cellFormatterUtils'

export const dateFormatter = (value) => {
  return value ? format(new Date(value), 'yyyy-MM-dd') : value
}

export const getExcelExportColConfig = ({ isLoading, policyOptions }) => {
  if (isLoading) return []

  return [
    {
      Header: 'Alert',
      accessor: 'shouldAlert',
    },
    {
      Header: 'Link Status',
      accessor: LINK_STATUS,
    },
    {
      Header: 'New Policy Approval Status',
      accessor: APPROVAL_STATUS,
    },
    {
      Header: 'Projects',
      accessor: PRODUCT_PAYER_POLICY_TRACKING_PROJECTS,
      labelMap: policyOptions[PRODUCT_PAYER_POLICY_TRACKING_PROJECTS].labelMap,
    },
    {
      Header: 'Assignee',
      accessor: ASSIGNED_POLARIS_USER_AUTH_IDS,
      labelMap: policyOptions[ASSIGNED_POLARIS_USER_AUTH_IDS].labelMap,
    },
    {
      Header: 'Payer',
      accessor: PAYER,
      cellValueFormatter: (value) =>
        mapIdToLabelCellFormatter(value, policyOptions[PAYER].labelMap),
    },
    {
      Header: 'Payer Slug',
      accessor: 'slug',
    },
    {
      Header: 'Book',
      accessor: BOOK,
      labelMap: policyOptions[BOOK].labelMap,
    },
    {
      Header: 'Product',
      accessor: PRODUCT,
      cellValueFormatter: (value) =>
        mapIdToLabelCellFormatter(value, policyOptions[PRODUCT].labelMap),
    },
    {
      Header: 'Benefit Type',
      accessor: BENEFIT_TYPE,
      cellValueFormatter: (value) =>
        mapIdToLabelCellFormatter(value, policyOptions[BENEFIT_TYPE].labelMap),
    },
    {
      Header: 'Document Type',
      accessor: DOC_TYPE,
      cellValueFormatter: (value) =>
        mapIdToLabelCellFormatter(value, policyOptions[DOC_TYPE].labelMap),
    },
    {
      Header: 'Link Type',
      accessor: LINK_TYPE,
      cellValueFormatter: (value) =>
        mapIdToLabelCellFormatter(value, policyOptions[LINK_TYPE].labelMap),
    },
    {
      Header: 'Policy Starting Link',
      accessor: LINK,
    },
    {
      Header: 'Link Steps',
      accessor: LINK_STEPS,
    },
    {
      Header: 'Search Term',
      accessor: POLICY_SEARCH_WORD,
    },
    {
      Header: 'Link Notes',
      accessor: LINK_NOTE,
    },
    {
      Header: 'Last Updated by Payer',
      accessor: LAST_APPROVED_REVIEW_DATE,
      cellValueFormatter: (value) => dateFormatter(value),
    },
    {
      Header: 'Last Updated by TDG',
      accessor: LAST_TDG_REVIEW_DATE,
      cellValueFormatter: (value) => dateFormatter(value),
    },
    {
      Header: 'Last Change Date (Scraper)',
      accessor: LAST_IDENTIFIED_CHANGE_DATE,
      cellValueFormatter: (value) => dateFormatter(value),
    },
    {
      Header: 'Current Policy Document',
      accessor: 'policy_doc_link_change',
    },
    {
      Header: 'Previous Policy Document',
      accessor: 'policy_doc_link',
    },
  ]
}
