const getCount = (sitemap, currentSitemap) => {
  if (!sitemap || !currentSitemap) return

  let count = 0

  for (const [key, value] of Object.entries(sitemap)) {
    for (const id of value) {
      if (!currentSitemap[key].has(id)) count++
    }
  }

  for (const [key, value] of Object.entries(currentSitemap)) {
    for (const id of value) {
      if (!sitemap[key].has(id)) count++
    }
  }

  return count
}

export default getCount
