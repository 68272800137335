import styled from '@emotion/styled'
import { transparentize } from 'polished'

import Color from 'frontend/utils/color'
import Spacing from 'frontend/utils/spacing'

export const EventDiffWrapper = styled.div({
  margin: Spacing.S4,
})

export const EventDiff = styled.div({
  margin: `${Spacing.S2} ${Spacing.S4}`,
})

export const Field = styled.span({
  marginRight: Spacing.S3,
  color: transparentize(0.4, Color.BLACK),
  fontWeight: 400,
})

export const DiffArrow = styled.span({
  padding: `0px ${Spacing.S4}`,
  color: transparentize(0.7, Color.BLACK),
})
