import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

import { ActionBar } from './ActionBar'

interface Props {
  fieldState: any
}

const TITLE_TEXT = 'Import Policy'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: Colors.WHITE,
  padding: '16px 24px',
  borderBottom: `1px solid ${Colors.GRAY_100}`,
  height: 68,
})

const Title = styled.h1({
  fontSize: 20,
  fontWeight: 700,
})

export const Header = ({ fieldState }: Props) => {
  return (
    <Wrapper>
      <Title>{TITLE_TEXT}</Title>
      <ActionBar fieldState={fieldState} />
    </Wrapper>
  )
}
