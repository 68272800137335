import React from 'react'
import _ from 'lodash'
import styled from '@emotion/styled'

import { Form } from 'frontend/components'

import { POLICY_DETAILS_CONFIG, TRACKING_TEAM_CONFIG } from './utils'

const FormSection = styled.section({
  width: '33.333%',
  padding: '24px 12px',
})

const SectionHeader = styled.h3({
  padding: '0 24px',
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '18px',
  letterSpacing: '-0.1',
})

export const getOptionsForConfigs = (data, config) => {
  return config.reduce((acc, datum) => {
    const { accessor } = datum
    const options = data[accessor]?.options
    if (!options) {
      acc.push(datum)
    } else {
      const sortedOptions = _.sortBy(options, 'label')
      acc.push({ ...datum, options: sortedOptions })
    }
    return acc
  }, [])
}

const formStyle = {
  borderBottom: 'none',
}

const CreateForm = ({ data, input, setInput }) => {
  const [policyDetailsConfig, trackingTeamConfig] = [
    POLICY_DETAILS_CONFIG,
    TRACKING_TEAM_CONFIG,
  ].map((config) => getOptionsForConfigs(data, config))

  const handleInputSelection = (props) => {
    const { name, value } = props
    setInput({ ...input, [name]: value })
  }

  return (
    <div style={{ display: 'flex' }}>
      <FormSection>
        <SectionHeader>Policy Details</SectionHeader>
        <Form
          datum={input}
          formConfig={[policyDetailsConfig]}
          setFormState={handleInputSelection}
          style={formStyle}
        />
      </FormSection>
      <FormSection>
        <SectionHeader>Tracking Team</SectionHeader>
        <Form
          datum={input}
          formConfig={[trackingTeamConfig]}
          setFormState={handleInputSelection}
          style={formStyle}
        />
      </FormSection>
    </div>
  )
}

export default CreateForm
