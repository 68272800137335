import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

import Panel from './Panel'
import { DangerZone } from './DangerZone'

const Wrapper = styled.div({
  background: Colors.COOL_GRAY_100,
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  overflowY: 'auto',
  height: 'calc(100vh - 96px)',
  padding: 20,
  flex: 1,
})

const UsersPanel = () => {
  return (
    <Wrapper>
      <Panel />
      <DangerZone />
    </Wrapper>
  )
}

export default UsersPanel
