import _ from 'lodash'

//!DEPRECATE: This util function will be eventually deprecated as the logic is being implemented in sortText
const sortTextArray = (rowA, rowB, columnId) => {
  const valueA = rowA.values[columnId].join()
  const valueB = rowB.values[columnId].join()

  if (_.isEmpty(valueA) && _.isEmpty(valueB)) return 0
  if (_.isEmpty(valueB)) return -1
  if (_.isEmpty(valueA)) return 1

  return valueA.toLowerCase().localeCompare(valueB.toLowerCase())
}

export default sortTextArray
