import _ from 'lodash'

import { LazyQueryResponse, UsePaginatedReadConfig } from '../../../../interfaces'

const writeDataPageToCache = (
  { dataPage, dataKey }: { dataPage: any[], dataKey: string },
  { tag }: UsePaginatedReadConfig,
  { apolloClient }: LazyQueryResponse,
) => {
  const cacheData = apolloClient.readQuery({
    query: tag,
  })

  let newData = _.cloneDeep(dataPage)
  if (cacheData) {
    newData = [
      ...cacheData[dataKey],
      ...newData,
    ]
  }

  apolloClient.writeQuery({
    query: tag,
    data: { [dataKey]: newData },
  })
}

export default writeDataPageToCache
