import React from 'react'
import styled from '@emotion/styled'

import { Form } from 'frontend/components'
import Color from 'frontend/utils/color'
import StatusTag from '../../../../StatusTag'

import { LABELS_TO_MAP } from './utils'
import { LINK_STATUS } from 'frontend/PayerPolicyLinkMgmt/v0/utils/enums'

const InfoContainer = styled.div({
  padding: 24,
  borderBottom: `1px solid ${Color.GRAY_LIGHT}`,
})

const InfoText = styled.section({
  display: 'flex',
  fontSize: 13,
  lineHeight: '20px',
  letterSpacing: -0.2,
  margin: '6px 0',
})

const LastTrackingText = styled.section({
  fontStyle: 'italic',
  fontSize: 11,
  lineHeight: '12px',
  letterSpacing: -0.2,
  color: Color.GRAY_DARK,
  margin: '12px 0',
})

const PolicyInfo = ({
  data,
  policyOptions,
  configWithOptions,
  handleInputSelection,
  input,
}) => {
  const [payer, product, book, benefit_type] = LABELS_TO_MAP.map((key) => {
    const value = data[key]
    const { labelMap } = policyOptions[key]

    return labelMap[value]
  })

  const { link_status } = data

  return (
    <div>
      <InfoContainer>
        <InfoText style={{ fontWeight: 500 }}>
          {payer}: {product}
        </InfoText>
        <InfoText style={{ fontStyle: 'italic' }}>
          {book} {benefit_type}
        </InfoText>
        <StatusTag
          value={link_status}
          labelMap={policyOptions[LINK_STATUS].labelMap}
        />
        <LastTrackingText>
          Last updated by tracking team: {data?.last_tdg_review_date || 'N/A'}
        </LastTrackingText>
      </InfoContainer>
      <Form
        datum={input}
        formConfig={[configWithOptions]}
        setFormState={handleInputSelection}
        style={{
          padding: '12px 12px 0 12px',
          borderBottom: 'none',
        }}
      />
    </div>
  )
}

export default PolicyInfo
