import React from 'react'
import styled, { CSSObject } from '@emotion/styled'
import FontSpace from 'frontend/utils/fontspace'

const base = ({ isInvalid }: any): CSSObject => ({
  color: isInvalid ? 'red' : 'black',
  fontWeight: 700,
  ...FontSpace.FS2,
})

const StyledErrorLabel = styled('label')<{ isInvalid: boolean }>(base)

export const ErrorLabel = ({ isInvalid, children }: any) => {
  if (!isInvalid) return null

  return <StyledErrorLabel isInvalid={isInvalid}>{children}</StyledErrorLabel>
}
