import React, { MutableRefObject, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { useAlternativePaymentModelOrganizations } from 'frontend/entity-hooks'
import { SingleActionDialog } from 'frontend/components/Dialog'
import { Form, Spinner } from 'frontend/components'

import getFormConfig from './getFormConfig'

const MODAL_TITLE = 'Create Alternative Payment Model Organization'

const CreateModal = () => {
  const history = useHistory()

  const inputRef: MutableRefObject<any> = useRef({})
  const setInputRef = ({ name, value }: any) => {
    inputRef.current[name] = value
  }

  const { create, isCreating } = useAlternativePaymentModelOrganizations()

  const handleCancel = () => {
    inputRef.current = {}
    history.push({ search: '' })
  }

  const handleSubmit = () => {
    const input = inputRef.current
    create({ variables: { input } })
    handleCancel()
  }

  const formConfig = getFormConfig()

  const submitText = isCreating ? <Spinner /> : 'Save'

  return (
    <SingleActionDialog
      header={MODAL_TITLE}
      cancelHandler={handleCancel}
      submitHandler={handleSubmit}
      submitText={submitText}
    >
      <Form formConfig={formConfig} setFormState={setInputRef} />
    </SingleActionDialog>
  )
}

export default CreateModal
