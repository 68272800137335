import React from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Colors } from 'frontend/utils/pulseStyles'

const UploadedFile = ({ fileName }: { fileName?: string }) => {
  if (fileName) {
    return (
      <>
        <span style={{ color: Colors.PRIMARY, paddingRight: 12 }}>
          <FontAwesomeIcon icon={faFileAlt as IconProp} />
        </span>
        <span>
          {fileName}
        </span>
      </>
    )
  }

  return (
    <i>
      No file uploaded
    </i>
  )
}

export default UploadedFile
