import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Pathways from './Pathways'
import Payer from './Payer'
import Provider from './Provider'
import AlternativePaymentModel from './AlternativePaymentModel'
import Obm from './Obm'
import Lbm from './Lbm'
import Institutions from './Institutions'

const Organizations = () => (
  <Switch>
    <Route
      path={'/orion/organizations/alternative-payment-model'}
      component={AlternativePaymentModel}
    />
    <Route path={'/orion/organizations/mbm/obm'} component={Obm} />
    <Route path={'/orion/organizations/mbm/lbm'} component={Lbm} />
    <Route path={'/orion/organizations/pathways'} component={Pathways} />
    <Route path={'/orion/organizations/payer'} component={Payer} />
    <Route path={'/orion/organizations/provider'} component={Provider} />
    <Route
      path={'/orion/organizations/institutions'}
      component={Institutions}
    />
    <Redirect to={'/orion/organizations/alternative-payment-model'} />
  </Switch>
)

export default Organizations
