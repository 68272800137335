import * as GQL_TAGS from './gql-tags'

export const getAlternativePaymentModelOrganizationConfigs = () => ({
  createConfig: {
    tag: GQL_TAGS.CREATE_ALTERNATIVE_PAYMENT_MODEL_ORGANIZATION,
    readTag: GQL_TAGS.GET_VEGA_ALTERNATIVE_PAYMENT_MODEL_ORGANIZATIONS,
  },
  updateConfig: {
    tag: GQL_TAGS.UPDATE_ALTERNATIVE_PAYMENT_MODEL_ORGANIZATION,
  },
  deleteConfig: {
    tag: GQL_TAGS.DELETE_ALTERNATIVE_PAYMENT_MODEL_ORGANIZATION,
    readTag: GQL_TAGS.GET_VEGA_ALTERNATIVE_PAYMENT_MODEL_ORGANIZATIONS,
  },
  readConfig: {
    tag: GQL_TAGS.GET_VEGA_ALTERNATIVE_PAYMENT_MODEL_ORGANIZATIONS,
  },
})
