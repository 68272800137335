import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

const Wrapper = styled.div({
  color: Colors.BLACK,
  fontSize: 14,
  fontWeight: 700,
  marginBottom: 24,
  textTransform: 'uppercase',
})

const Title = () => (
  <Wrapper>
    Text Object
  </Wrapper>
)

export default Title
