import React from 'react'
import styled from '@emotion/styled'

import DataTable from 'frontend/components/DataTable'
import Header from 'frontend/components/Header'
import { CONFIG_TABLE_WIDTH } from 'frontend/components/Table/tableWidths'

import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'
import { getClinicalTrialsExportConfig } from './utils'
import useClinicalTrialData from './useClinicalTrialData'
import getClinicalTrialColumns from './getClinicalTrialColumns'

const Container = styled.div`
  width: ${CONFIG_TABLE_WIDTH};
  overflow-y: hidden;
`

const TitleSection = styled.section({
  display: 'flex',
  justifyContent: 'space-between',
  padding: Spacing.S7,
  alignItems: 'center',
})

const PAGE_TITLE = 'CLINICAL TRIALS'

const ClinicalTrials = () => {
  const {
    hydratedClinicalTrialData,
    providers,
    products,
    indications,
    clinicalTrialsOptions,
    loading,
  } = useClinicalTrialData()

  const clinicalTrialsColumns = getClinicalTrialColumns({
    providers,
    products,
    indications,
    clinicalTrialsOptions,
  })

  return (
    <Container>
      <TitleSection>
        <Header header={PAGE_TITLE} headerStyle={{ ...FontSpace.FS5 }} />
      </TitleSection>

      <DataTable
        isLoading={loading}
        data={hydratedClinicalTrialData}
        columns={clinicalTrialsColumns}
        hasExport={true}
        exportConfig={getClinicalTrialsExportConfig(clinicalTrialsColumns)}
      />
    </Container>
  )
}

export default ClinicalTrials
