import {
  DEFAULT_COL_HEADER_STYLES,
  ALL_BORDERS,
} from '@pulse-analytics/pulse-design-system/dist/components/ExportToExcelButton/export-to-excel-button-utils'

const COL_HEADER_HEIGHT = 30
const COL_HEADER_WIDTH = 50
const HEADER_ROW = 1

export const getStyleConfig = () => {
  const colHeaderStyle = {
    ...DEFAULT_COL_HEADER_STYLES,
    ...ALL_BORDERS,
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
    },
    height: COL_HEADER_HEIGHT,
    width: COL_HEADER_WIDTH,
  }

  return [
    {
      rows: [HEADER_ROW],
      style: colHeaderStyle,
    },
  ]
}
