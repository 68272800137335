export const schemaV0 = `
  tools {
    id
    team
    tool
    created_at
    updated_at
  }
  dashboards {
    id
    team
    dashboard
    created_at
    updated_at
  }
  pages {
    id
    team
    page
    created_at
    updated_at
  }
  cards {
    id
    team
    card
    created_at
    updated_at
  }
`
