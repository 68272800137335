import { useEffect, useRef } from 'react'

const DEFAULT_KEY_MAP = {
  up: 'ArrowUp',
  down: 'ArrowDown',
  left: 'ArrowLeft',
  right: 'ArrowRight',
}

const DEFAULT_ON_REF_CHANGE = () => { }

interface Config {
  maxRows: number,
  maxColumns: number,
}

interface Options {
  keyMap?: {
    up: string,
    down: string,
    left: string,
    right: string,
  },
  onRefChange?: (ref: { current: string }) => void,
}

const useKeyPressPositionRef = (
  { maxRows, maxColumns }: Config,
  options: Options = {},
) => {
  let {
    keyMap,
    onRefChange,
  } = options

  keyMap = keyMap || DEFAULT_KEY_MAP
  const onRefChangeDefined = onRefChange || DEFAULT_ON_REF_CHANGE

  const {
    up,
    down,
    left,
    right,
  } = keyMap

  const ref = useRef('0-0') // default to start of matrix

  // TODO: Extract into helper function file and rename to updateRefPosition.
  const downHandler = ({ key }: { key: string }) => {
    // step one: turn row-column id into an array
    const [stringRowId, stringColumnId] = ref.current.split('-')

    // step two: parse the strings into numbers
    const rowId = parseInt(stringRowId)
    const columnId = parseInt(stringColumnId)
    let isValidPress = false
    let newSelectedCell = [rowId, columnId]

    // step three: if possible, change the new selected cell based on keypress
    if (key === up) {
      if (rowId - 1 >= 0) {
        newSelectedCell[0] = rowId - 1
        isValidPress = true
      }
    } else if (key === down) {
      if (rowId + 1 <= maxRows) {
        newSelectedCell[0] = rowId + 1
        isValidPress = true
      }
    } else if (key === left) {
      if (columnId - 1 >= 0) {
        newSelectedCell[1] = columnId - 1
        isValidPress = true
      }
    } else if (key === right) {
      if (columnId + 1 <= maxColumns) {
        newSelectedCell[1] = columnId + 1
        isValidPress = true
      }
    }

    // step four: if valid key press, turn back to id,
    // set ref, and invoke callback function
    if (isValidPress) {
      const cellToString = newSelectedCell.join('-')
      ref.current = cellToString
      onRefChangeDefined(ref)
    }
  }

  // TODO: Extract into helper function file and rename to handleKeyDownListener.
  // useEffect: handle event listeners
  useEffect(() => {
    // add 'keydown' event listener onMount
    window.addEventListener('keydown', downHandler)

    // remove 'keydown' event listener onUnmount
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [])

  return ref
}

export default useKeyPressPositionRef
