import React from 'react'

import { Select } from 'frontend/components'

import { Label, Wrapper } from '../shared'

interface Props {
  options: any
  onChange: any
}

const LABEL_TEXT = 'Client'
const FIELD_NAME = 'client'

export const Client = ({ options, onChange }: Props) => {
  const handleChange = ({ value }: any) => {
    onChange({
      name: 'teams',
      value: [],
    })
    onChange({
      name: FIELD_NAME,
      value,
    })
  }

  return (
    <Wrapper>
      <Label required={true}>
        {LABEL_TEXT} <span className="required">(required)</span>
      </Label>
      <Select options={options} onChange={handleChange} />
    </Wrapper>
  )
}
