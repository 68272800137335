import React, { useRef, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { SingleActionDialog } from 'frontend/components/Dialog'
import { GET_VEGA_PRODUCTS } from 'frontend/api/queries'

import { Form } from 'frontend/components'

import getFormConfig from './getPolicyFormConfig'
import { useImportPolicyLink } from 'frontend/entity-hooks'

const ImportPolicyPdfModal = ({
  policyId,
  productId,
  productPolicyId,
  setShowModalSearchString,
  setPolicyContentInput,
}) => {
  const inputRef = useRef({})
  const location = useLocation()
  const { update: updatePolicyLink, isUpdating } = useImportPolicyLink()

  const setInputRef = ({ name, value }) => {
    inputRef.current[name] = value
  }

  useEffect(() => {
    setInputRef({
      name: 'products',
      value: [productId],
    })
  }, [])

  const products = useQuery(GET_VEGA_PRODUCTS, {
    variables: {
      input: {
        policy_link: policyId,
      },
    },
  })

  const queryStringArgs = queryString.parse(location.search)
  if (!queryStringArgs.shouldShow || queryStringArgs.shouldShow === 'false')
    return null

  const productsOptions = []

  products.data?.vegaProducts.forEach(({ id, brand_name }) => {
    const option = { label: brand_name, value: id }
    productsOptions.push(option)
  })

  const formConfig = getFormConfig({ productsOptions })

  const cancel = () => {
    setShowModalSearchString(false)
  }

  const submit = () => {
    const updateInput = {
      variables: {
        input: {
          id: policyId,
          file: inputRef.current.file,
          product_policy_id: productPolicyId,
          products: inputRef.current.products,
        },
      },
    }
    updatePolicyLink(updateInput).then(() => {
      setPolicyContentInput(null)
      cancel()
    })
  }

  return (
    <SingleActionDialog
      header="PDF Import Modal"
      cancelHandler={cancel}
      submitHandler={submit}
      submitText="submit"
    >
      <Form
        datum={inputRef.current}
        formConfig={formConfig}
        setFormState={setInputRef}
        style={{
          color: 'black',
          padding: '12px 12px 0 12px',
          borderBottom: 'none',
          cursor: 'default',
        }}
      />
    </SingleActionDialog>
  )
}

export default ImportPolicyPdfModal
