import React from 'react'
import styled from '@emotion/styled'

import { Type } from '../../../../../types'
import EditButton from './EditButton'

interface Props {
  type: Type
  data: any
}

const Wrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
})

const ButtonGroup = (props: Props) => {
  return (
    <Wrapper>
      <EditButton {...props} />
    </Wrapper>
  )
}

export default ButtonGroup
