import React, { Dispatch, SetStateAction } from 'react'
import { useQuery } from '@apollo/client'

import { useEmberNode } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'

import { ToggleAllButtonsWrapper } from '../../shared'
import { getAccountsQueryGqlTag } from '../utils'
import ToggleOffAllButton from './ToggleOffAllButton'
import ToggleOnAllButton from './ToggleOnAllButton'

interface Props {
  areParentResourcesLoading: boolean
  accounts?: any[]
  parentResources: any[]
  setResources: Dispatch<SetStateAction<any>>
}

const ToggleAllButtons = ({
  areParentResourcesLoading,
  accounts,
  parentResources,
  setResources,
}: Props) => {
  const { node, nodeType, tool } = useQueryString()

  const { data }: any = useEmberNode({
    queryInput: {
      type: 'tool',
      id: nodeType === 'tool' ? node : tool,
    }
  })

  const { loading: areOrganizationsLoading } = useQuery(
    getAccountsQueryGqlTag(data[0].permitted_entity_types)
  )

  const disabled = !accounts ||
    areParentResourcesLoading ||
    areOrganizationsLoading

  return (
    <ToggleAllButtonsWrapper>
      <ToggleOnAllButton
        disabled={disabled}
        parentResources={parentResources}
        setResources={setResources}
      />
      <ToggleOffAllButton
        disabled={disabled}
        setResources={setResources}
      />
    </ToggleAllButtonsWrapper>
  )
}

export default ToggleAllButtons
