import React, { MutableRefObject, useRef } from 'react'

interface UseModalRef {
  inputRef: React.MutableRefObject<any>
  setInputRef: ({ name, value }: any) => void
  buttonRef: React.RefObject<HTMLButtonElement>
}

export const useModalRef = (): UseModalRef => {
  const inputRef: MutableRefObject<any> = useRef({})
  const buttonRef = useRef<HTMLButtonElement>(null)

  const setInputRef = ({ name, value }: any) => {
    inputRef.current[name] = value
  }

  return { inputRef, setInputRef, buttonRef }
}
