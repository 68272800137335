import gql from 'graphql-tag'

export const CREATE_MANUFACTURER = gql`
  mutation CreateManufacturer($input: CreateManufacturerInput!) {
    createManufacturer(input: $input) {
      id
      name
      created_at
      updated_at
    }
  }
`

export const UPDATE_MANUFACTURER = gql`
  mutation UpdateManufacturer($input: UpdateManufacturerInput!) {
    updateManufacturer(input: $input) {
      id
      name
      created_at
      updated_at
    }
  }
`

export const DELETE_MANUFACTURER = gql`
  mutation DeleteManufacturer($input: DeleteManufacturerInput!) {
    deleteManufacturer(input: $input) {
      id
      name
      created_at
      updated_at
    }
  }
`
