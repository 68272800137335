import React, { MutableRefObject, useRef } from 'react'

import { SingleActionDialog } from 'frontend/components/Dialog'

import { useClearSearchStringParam } from 'frontend/hooks'
import { getGraphQLErrorMessage } from 'frontend/utils'
import { Form, Spinner } from 'frontend/components'

import useProviderKdmData from '../useProviderKdmData'

import { getFormConfig, getFormConfigOptions } from './utils'

const MODAL_TITLE = 'Create Provider KDM'

const CREATE_SEARCH_STRING_PARAM = 'createProviderKdm'

const CreateModal = ({ setErrorMessage }: any) => {
  const clearSearchStringParam = useClearSearchStringParam()
  const inputRef: MutableRefObject<any> = useRef({})
  const buttonRef = useRef<HTMLButtonElement>(null)

  const {
    people,
    providers,
    indications,
    providerKeyDecisionMakers,
  } = useProviderKdmData()

  const setInputRef = ({ name, value }: any) => {
    inputRef.current[name] = value
  }

  const handleCancel = () => {
    inputRef.current = {}
    clearSearchStringParam([CREATE_SEARCH_STRING_PARAM])
  }

  const handleError = (e: any) => {
    const errorMessage = getGraphQLErrorMessage(e)
    if (errorMessage === undefined) {
      alert(e)
    } else {
      setErrorMessage(errorMessage)
    }
  }

  const handleSubmit = async () => {
    const input = inputRef.current

    await providerKeyDecisionMakers.create({
      variables: { input },
      onCompleted: handleCancel,
      onError: handleError,
    })
  }

  const formConfigOptions = getFormConfigOptions({
    people,
    providers,
    indications,
    providerKeyDecisionMakers,
  })

  const submitText = providerKeyDecisionMakers.isCreating ? <Spinner /> : 'Save'

  return (
    <SingleActionDialog
      header={MODAL_TITLE}
      cancelHandler={handleCancel}
      submitHandler={handleSubmit}
      submitText={submitText}
      buttonRef={buttonRef}
    >
      <Form
        datum={inputRef.current}
        formConfig={getFormConfig(formConfigOptions)}
        setFormState={setInputRef}
      />
    </SingleActionDialog>
  )
}

export default CreateModal
