const Spacing = {
  S1: '2px',
  S2: '4px',
  S3: '8px',
  S4: '12px',
  S5: '16px',
  S6: '20px',
  S7: '24px',
  S8: '32px',
  S9: '48px',
}

export default Spacing
