import React from 'react'

import { useQueryString } from 'frontend/hooks'

import { ParentType } from '../../../../types'
import ParentTitle from './ParentTitle'

interface Props {
  parentType?: ParentType
}

const Container = ({
  parentType,
}: Props) => {
  const queryString = useQueryString()

  if (!parentType || !queryString[parentType]) return null

  return (
    <ParentTitle
      parentType={parentType}
    />
  )
}

export default Container
