import React from 'react'

import { TextInput } from 'frontend/components'

import { NodeState } from '../types'
import { Label, Wrapper } from './shared'

interface Props {
  node?: any
  nodeState: NodeState
  updateNodeState: (event: any) => void
}

const Name = ({
  node,
  nodeState,
  updateNodeState,
}: Props) => (
  <Wrapper>
    <Label>
      Name
    </Label>
    <TextInput
      name={'name'}
      onChange={updateNodeState}
      value={nodeState?.name ?? node?.name}
    />
  </Wrapper>
)

export default Name
