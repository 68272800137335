export const getClientOptions = (data: any, areClientsLoading: Boolean) => {
  if (areClientsLoading) return []

  const options: any = []
  data.forEach(({ id, name }: any) => {
    const option = { label: name, value: id }
    options.push(option)
  })

  return options
}
