import React from 'react'
import styled from '@emotion/styled'

import LoginStatus from './LoginStatus'

const HeaderWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '12px 0 0 16px',
  flexDirection: 'row',
})

const HEADER_TEXT = 'Edit User:'

const Header = ({ user }: any) => {
  if (!user) return null

  return (
    <HeaderWrapper>
      <div>{HEADER_TEXT} {user.firstName} {user.lastName}</div>
      <LoginStatus auth0Id={user?.id} />
    </HeaderWrapper>
  )
}

export default Header
