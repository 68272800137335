import React from 'react'
import _ from 'lodash'

/*
  This util function accepts data coming both from the Table component (through react-table) and
  also data that that has not been formatted through react-table. This is so that exports from
  the export button that's built into the Table component retain the table's sorting order.

  Exports from a detached export button will not retain the table's sorting order.
*/

const formatDataForExport = ({
  data = [],
  columns = [],
  isReactTableData = false,
}) => {
  const dataToFormat = isReactTableData
    ? data.map(({ values }) => values)
    : data

  const formattedData = dataToFormat.map((rowData) =>
    columns.reduce((acc, { Header, accessor, Cell, cellFormatter }) => {
      let value = rowData[accessor]

      if (_.isFunction(accessor)) {
        value = accessor(rowData)
      }

      // ! DEPRECATE Cell usage from config
      if (_.isFunction(Cell)) {
        const tempVal = Cell({ value, row: { original: rowData } })
        const isValidReactElem = React.isValidElement(tempVal)
        if (!isValidReactElem) {
          value = tempVal
        }
      }

      // ! cellFormatter may return a react node
      // TODO: separate export formatter in column config or catch nodes in this util
      if (_.isFunction(cellFormatter)) {
        value = cellFormatter(value)
      }

      value = Array.isArray(value) ? value.join(', ') : value
      // Use the Header value for proper casing on the exported column header
      acc[Header] = value

      return acc
    }, {})
  )

  return formattedData
}

export default formatDataForExport
