import { useEffect } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'

import { GET_MARKET_BASKETS_CATEGORIES_SUBSCRIPTIONS } from 'frontend/api/queries'

import _ from 'lodash'
import {
  SUBSCRIBE_TEAM_TO_MARKET_BASKET_CATEGORY,
  UNSUBSCRIBE_TEAM_TO_MARKET_BASKET_CATEGORY,
} from 'frontend/api/mutations'

const useCategorySubscriptionData = (marketBasketId, mbSubscriptions) => {
  let market_basket_subscription = undefined
  const selectedMbSubscription = mbSubscriptions.find(
    ({ id }) => id === marketBasketId
  )

  if (selectedMbSubscription && selectedMbSubscription.isSubscribed) {
    market_basket_subscription = selectedMbSubscription.subscriptionId
  }

  const [
    queryCategorySubscriptions,
    { data: categorySubscriptionsData, loading },
  ] = useLazyQuery(GET_MARKET_BASKETS_CATEGORIES_SUBSCRIPTIONS)

  useEffect(() => {
    if (market_basket_subscription && !loading) {
      queryCategorySubscriptions({ variables: { market_basket_subscription } })
    }
  }, [market_basket_subscription])

  const categorySubsMap = categorySubscriptionsData
    ? _.keyBy(
        categorySubscriptionsData.marketBasketsCategoriesSubscriptions,
        'market_basket_survey_category'
      )
    : {}

  const selectedMarketBasketSubCategories =
    selectedMbSubscription && selectedMbSubscription.categories

  const [subscribeToMarketBasketCategory, { loading: isSubbing }] = useMutation(
    SUBSCRIBE_TEAM_TO_MARKET_BASKET_CATEGORY,
    {
      update: (cache, { data }) => {
        const newSubs = [
          ...categorySubscriptionsData.marketBasketsCategoriesSubscriptions,
          Object.values(data)[0],
        ]

        cache.writeQuery({
          query: GET_MARKET_BASKETS_CATEGORIES_SUBSCRIPTIONS,
          data: { marketBasketsCategoriesSubscriptions: newSubs },
          variables: { market_basket_subscription },
        })
      },
      onError: alert,
    }
  )

  const [
    unsubscribeToMarketBasketCategory,
    { loading: isUnsubbing },
  ] = useMutation(UNSUBSCRIBE_TEAM_TO_MARKET_BASKET_CATEGORY, {
    update: (cache, { data }) => {
      const newSubs = categorySubscriptionsData.marketBasketsCategoriesSubscriptions.filter(
        ({ id }) => id !== Object.values(data)[0].id
      )

      cache.writeQuery({
        query: GET_MARKET_BASKETS_CATEGORIES_SUBSCRIPTIONS,
        data: { marketBasketsCategoriesSubscriptions: newSubs },
        variables: { market_basket_subscription },
      })
    },
    onError: alert,
  })

  return {
    subscribeToMarketBasketCategory,
    isSubbing,
    unsubscribeToMarketBasketCategory,
    isUnsubbing,
    market_basket_subscription,
    selectedMarketBasketSubCategories,
    categorySubsMap,
    isLoadingCategorySubs: loading,
  }
}

export default useCategorySubscriptionData
