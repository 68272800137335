import React from 'react'
import Select from 'react-select'
import _ from 'lodash'

import { customTableSelectStyles } from '../customTableSelectStyles'

const MultiSelectDateColumnFilter = (args, formatMonthDayYearSlash) => {
  const {
    column: { filterValue, setFilter, preFilteredRows, id },
  } = args[0]

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach((row) => {
      options.add(formatMonthDayYearSlash(row.values[id]))
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  const reactSelectOptions = options.map((option, i) => ({
    label: option,
    value: option,
  }))

  const selectedOptions = !_.isEmpty(filterValue)
    ? filterValue.map((value) => ({
        value,
        label: value,
      }))
    : null

  const selectChangeHandler = (option) => {
    if (_.isEmpty(option)) {
      setFilter(undefined)
    } else {
      const options = _.isArray(option) ? option : [option]
      setFilter(options.map(({ value }) => value))
    }
  }

  // Render a multi-select box
  return (
    <Select
      isMulti
      value={selectedOptions}
      options={reactSelectOptions}
      styles={customTableSelectStyles}
      onChange={(option) => selectChangeHandler(option)}
    />
  )
}

export default MultiSelectDateColumnFilter
