const STATUS_HIERARCHY_MAP: any = {
  3: 'needs_approval',
  2: 'incorrect_policy',
  1: 'approved',
  0: 'not_applicable',
}

export const getPolicyStatus = (policyStatuses: string[]) => {
  let needsApprovalCounter = 0
  let incorrectCounter = 0
  let approvedCounter = 0
  let notApplicableCounter = 0
  let priority = 0

  for (const policyStatus of policyStatuses) {
    if (policyStatus === 'needs_approval') {
      priority = 3
      needsApprovalCounter++
    } else if (policyStatus === 'incorrect_policy' && priority < 3) {
      priority = 2
      incorrectCounter++
    } else if (policyStatus === 'approved' && priority < 2) {
      priority = 1
      approvedCounter++
    } else if (policyStatus === 'not_applicable' && priority < 1) {
      priority = 0
      notApplicableCounter++
    }
  }

  const policyStatus = STATUS_HIERARCHY_MAP[priority]

  const count = needsApprovalCounter || incorrectCounter || approvedCounter || notApplicableCounter
  return { policyStatus, count }
}
