import gql from 'graphql-tag'
import { schemaV0 } from './schemas'

export const GET_VEGA_ALTERNATIVE_PAYMENT_MODEL_ORGANIZATIONS = gql`
  query GetAlternativePaymentModelOrganizations {
    alternativePaymentModelOrganizations {
      ${schemaV0}
    }
  }
`
