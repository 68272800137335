import React, { Dispatch, SetStateAction } from 'react'

import { NodeState } from '../types'
import ComponentPath from './ComponentPath'
import Name from './Name'
import Order from './Order'
import ParentNode from './ParentNode'
import RemoveFromSitemapsButton from './RemoveFromSitemapsButton'
import TextObject from './TextObject'
import Type from './Type'

interface Props {
  node?: any
  nodeState: NodeState
  setNodeState: Dispatch<SetStateAction<NodeState>>
}

const Form = ({
  setNodeState,
  ...props
}: Props) => {
  const updateNodeState = ({
    target: {
      name,
      value,
    },
  }: any) => {
    setNodeState((nodeState) => ({
      ...nodeState,
      [name]: value,
    }))
  }

  return (
    <>
      <RemoveFromSitemapsButton />
      <ParentNode />
      <Name
        updateNodeState={updateNodeState}
        {...props}
      />
      <Type />
      <ComponentPath
        updateNodeState={updateNodeState}
        {...props}
      />
      <Order
        setNodeState={setNodeState}
        {...props}
      />
      <TextObject
        setNodeState={setNodeState}
        {...props}
      />
    </>
  )
}

export default Form
