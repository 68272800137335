const getInjectables = (
  {
    indications,
    people,
    nccnPanels,
    providers,
    providerKeyDecisionMakers,
    pathwaysOrganizations,
  }: any,
  addDeleteSearchString: any,
  onExpandClick: () => void
) => {
  const indication = getIndication(indications)
  const kdmType = getKdmType(providerKeyDecisionMakers)
  const providerKdms = getProviderKdms(providerKeyDecisionMakers)
  const isExcludedFromTools = getIsExcludedFromTools()
  const { options: providerOptions, labelMap: providerLabelMap } = providers
  const nccnPanel = getNccnPanel(nccnPanels)
  const person = getPerson(people)

  return {
    onExpandClick,
    addDeleteSearchString,
    indication,
    kdmType,
    providerKdms,
    isExcludedFromTools,
    providerOptions,
    providerLabelMap,
    nccnPanel,
    person,
    pathwaysOrganizations,
  }
}

export default getInjectables

const getNccnPanel = ({ labelMap, options }: any) => ({
  labelMap,
  options,
})

const getIndication = ({ labelMap, options }: any) => ({
  labelMap,
  options,
})

const getKdmType = ({
  keyDecisionMakerTypeLabelMap: labelMap,
  keyDecisionMakerTypeOptions: options,
}: any) => ({
  labelMap,
  options,
})

const getProviderKdms = (providerKeyDecisionMakers: any) => {
  const update = (input: any) =>
    providerKeyDecisionMakers.update({ variables: { input } })

  return {
    update,
  }
}

const getPerson = (people: any) => {
  const update = (input: any) => people.update({ variables: { input } })

  return {
    update,
  }
}

const getIsExcludedFromTools = () => {
  const options = [
    { label: 'No', value: false },
    { label: 'Yes', value: true },
  ]

  return {
    options,
  }
}
