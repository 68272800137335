import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'
import { FontSpace } from 'frontend/utils'

interface LabelProps {
  required?: boolean
}

export const Label = styled.label<LabelProps>(({ required }) => ({
  color: Colors.BLACK,
  fontWeight: 700,
  ...FontSpace.FS2,
  display: 'inline-block',
  '& .required': {
    color: required ? Colors.RED : 'inherit',
    fontWeight: 700,
    marginLeft: '4px',
  },
}))
