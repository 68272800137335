import * as GQL_TAGS from './gql-tags'

export const getTeamNodeConfigs = (deleteOptions = {}, queryInput: any) => ({
  updateConfig: {
    tag: GQL_TAGS.UPDATE_TEAM_NODES,
    input: queryInput,
  },
  readConfig: {
    tag: GQL_TAGS.GET_TEAM_NODES,
    input: queryInput,
  },
})
