import React from 'react'

import NccnPanelAffiliations from './NccnPanelAffiliations'
import {
  useFormatTableData,
  useColConfig,
  getExportConfig
} from './utils'

const NccnPanelAffiliationsContainer = () => {
  const { data, isLoading } = useFormatTableData()
  const colConfig = useColConfig()
  const exportConfig = getExportConfig()

  return (
    <NccnPanelAffiliations
      data={data}
      isLoading={isLoading}
      colConfig={colConfig}
      exportConfig={exportConfig}
    />
  )
}

export default NccnPanelAffiliationsContainer
