import React from 'react'
import styled from '@emotion/styled'

import { EditButton } from '../../../../shared/EditButton'
import SitemapButton from './SitemapButton'

interface Props {
  isHoveredListItem: boolean
  isSelected: boolean
  id: string
}

const ENTITY = 'team'
const QUERY_STRING_VALUE = 'editTeam'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  paddingTop: 4,
  paddingRight: 6,
})

export const Buttons = ({ isHoveredListItem, isSelected, id }: Props) => {
  return (
    <Wrapper>
      <SitemapButton isHoveredListItem={isHoveredListItem} teamId={id} />
      <EditButton
        isHoveredListItem={isHoveredListItem}
        isSelected={isSelected}
        datumId={id}
        entity={ENTITY}
        queryStringValue={QUERY_STRING_VALUE}
      />
    </Wrapper>
  )
}
