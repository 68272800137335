import gql from 'graphql-tag'
import { schemaV1 } from './schemas'

export const GET_PEOPLE = gql`
  query GetPeople($input: PeopleInput) {
    people(input: $input) {
      ${schemaV1}
    }
  }
`
