import React from 'react'

import { useQueryString } from 'frontend/hooks'

import RemoveFromSitemapsButton from './RemoveFromSitemapsButton'

const Container = () => {
  const { node } = useQueryString()

  if (!node) return null

  return (
    <RemoveFromSitemapsButton />
  )
}

export default Container
