import MultiSelectColumnFilter from "frontend/components/Table/custom-filters/MultiSelect/MultiSelectColumnFilter"
import customMultiSelectFilterFn from "frontend/components/Table/custom-filters/MultiSelect/customMultiSelectFilterFn"

const getColumns = () => [
  {
    Header: 'Provider Slug',
    accessor: 'provider_slug',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sticky: 'left',
    sortType: 'text',
  },
  {
    Header: 'Provider Account Name',
    accessor: 'provider_account_name',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sticky: 'left',
    sortType: 'text',
  },
  {
    Header: 'Provider Affiliate Name',
    accessor: 'provider_affiliate_name',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sticky: 'left',
    sortType: 'text',
  },
  {
    Header: 'Provider Affiliate NPI',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    accessor: 'npi',
    sticky: 'left',
    sortType: 'text',
  },
  {
    Header: 'Medicare Provider Number (CCN)',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    accessor: 'ccn',
    sortType: 'text',
  },
  {
    Header: 'Provider Type',
    accessor: 'provider_type',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
  },
  {
    Header: 'Business State Territory',
    accessor: 'business_state_territory',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
  },
  {
    Header: 'Zip Code',
    accessor: 'zip',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
  },
  {
    Header: 'Hospital Type',
    accessor: 'hospital_type',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
  },
  {
    Header: 'Program Type',
    accessor: 'program_type',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
  },
  {
    Header: 'Program Year',
    accessor: 'program_year',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
  },
  {
    Header: 'Provider Stage Number',
    accessor: 'provider_stage_number',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
  },
  {
    Header: 'Payment Year',
    accessor: 'payment_year',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
  },
  {
    Header: 'Attestation Month',
    accessor: 'attestation_month',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
  },
    {
    Header: 'Attestation Year',
    accessor: 'attestation_year',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
  },
  {
    Header: 'EHR Product CHP Id',
    accessor: 'ehr_product_chp_id',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
  },
  {
    Header: 'Vendor Name',
    accessor: 'vendor_name',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
  },
  {
    Header: 'EHR Product Name',
    accessor: 'ehr_product_name',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
  },
  {
    Header: 'Product Classification',
    accessor: 'product_classification',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
  },
  {
    Header: 'Product Setting',
    accessor: 'product_setting',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
  },
]

export default getColumns
