import React, { Dispatch, SetStateAction } from 'react'

import { CheckboxList } from './CheckboxList'
import { LoadingState } from './LoadingState'

interface Props {
  isLoading: boolean
  selectedTeams: [any]
  clientTeams: any
  setFieldState: Dispatch<SetStateAction<any>>
}

export const TeamsContent = ({
  isLoading,
  selectedTeams,
  clientTeams,
  setFieldState,
}: Props) => {
  if (isLoading) return <LoadingState />

  return (
    <CheckboxList
      selectedTeams={selectedTeams}
      clientTeams={clientTeams}
      setFieldState={setFieldState}
    />
  )
}
