import {
  DEFAULT_COL_HEADER_STYLES,
  ALL_BORDERS,
} from '@pulse-analytics/pulse-design-system/dist/components/ExportToExcelButton/export-to-excel-button-utils'

import {
  mapIdToLabelCellFormatter,
  mapIdsToLabelsCellFormatter,
} from '../Positioning/utils/cellFormatterUtils'

const COL_HEADER_HEIGHT = 30
const COL_HEADER_WIDTH = 50
const HEADER_ROW = 1

const getExportStyleConfig = () => {
  const colHeaderStyle = {
    ...DEFAULT_COL_HEADER_STYLES,
    ...ALL_BORDERS,
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
    },
    height: COL_HEADER_HEIGHT,
    width: COL_HEADER_WIDTH,
  }

  return [
    {
      rows: [HEADER_ROW],
      style: colHeaderStyle,
    },
  ]
}

const getExcelTitle = () => {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth() + 1
  const currentYear = currentDate.getFullYear()
  return `Pathways Mock Positioning - ${currentMonth}-${currentYear}`
}

const getExportColConfig = (colConfig) => {
  const exportColConfig = colConfig.reduce((acc, col) => {
    if (col.columns) {
      const nestedConfig = col.columns.map(({ Header, accessor }) => ({
        title: Header,
        key: accessor,
      }))
      acc.push(...nestedConfig)
      return acc
    }

    const { Header, accessor } = col
    acc.push({
      title: Header,
      key: accessor,
    })
    return acc
  }, [])

  return exportColConfig
}

const getKeyToFormatterMap = ({
  pathwaysOrgs,
  indications,
  regimens,
  populations,
  lines,
  cancerStages,
  positionStatus,
  relativeAccess,
}) => ({
  pathways_organization: {
    valFormatter: mapIdToLabelCellFormatter,
    labelMap: pathwaysOrgs.labelMap,
  },
  indication: {
    valFormatter: mapIdToLabelCellFormatter,
    labelMap: indications.labelMap,
  },
  regimen: {
    valFormatter: mapIdToLabelCellFormatter,
    labelMap: regimens.labelMap,
  },
  populations: {
    valFormatter: mapIdsToLabelsCellFormatter,
    labelMap: populations.labelMap,
  },
  cancer_stages: {
    valFormatter: mapIdsToLabelsCellFormatter,
    labelMap: cancerStages.labelMap,
  },
  'fda_indication_regimen_approval.lines_of_therapy': {
    valFormatter: mapIdsToLabelsCellFormatter,
    labelMap: lines.labelMap,
  },
  position_status: {
    valFormatter: mapIdToLabelCellFormatter,
    labelMap: positionStatus.labelMap,
  },
  lines_of_therapy: {
    valFormatter: mapIdsToLabelsCellFormatter,
    labelMap: lines.labelMap,
  },
  relative_access: {
    valFormatter: mapIdToLabelCellFormatter,
    labelMap: relativeAccess.labelMap,
  },
})

export {
  getExcelTitle,
  getExportStyleConfig,
  getExportColConfig,
  getKeyToFormatterMap,
}
