import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Accounts from './Accounts'
import Positioning from './Positioning'
import Influencers from './Influencers'
import MockPositioning from './MockPositioning'
import MockClientToolAccess from './MockClientToolAccess'

const Pathways = () => (
  <Switch>
    <Route
      path={'/orion/organizations/pathways/accounts'}
      component={Accounts}
    />
    <Route
      path={'/orion/organizations/pathways/positioning'}
      component={Positioning}
    />
    {/*// TODO (Anh) Remove the two mock routes and components below once completed development of DataTable v2 */}
    <Route
      path={'/orion/organizations/pathways/mock-positioning'}
      component={MockPositioning}
    />
    <Route
      path={'/orion/organizations/pathways/mock-client-tool-access'}
      component={MockClientToolAccess}
    />
    <Route
      path={'/orion/organizations/pathways/influencers'}
      component={Influencers}
    />
    <Redirect to={'/orion/organizations/pathways/accounts'} />
  </Switch>
)

export default Pathways
