import { useMutation, useQuery } from '@apollo/client'

import { useRewriteSearchString } from 'frontend/hooks'

import { CrudHookOptions } from '../../../interfaces'
import * as GQL_TAGS from './gql-tags'

export const useTeamNodeResource = ({
  queryInput,
}: any = {}) => {
  const rewriteSearchString = useRewriteSearchString()

  const [update, {
    loading: isUpdating,
  }] = useMutation(GQL_TAGS.UPDATE_TEAMS_NODES_RESOURCES, {
    onCompleted: () => rewriteSearchString({
      node: undefined,
      nodeType: undefined,
      indication: undefined,
    }),
    onError: alert,
  })

  const {
    data: teamsNodesResourcesData,
    loading: isLoading,
  } = useQuery(GQL_TAGS.GET_TEAMS_NODES_RESOURCES, {
    variables: { input: queryInput },
    fetchPolicy: 'network-only',
  })
  const data = teamsNodesResourcesData ? Object.values(teamsNodesResourcesData)[0] : []

  return {
    data,
    update,
    isLoading,
    isUpdating,
  }
}
