import { useHistory } from 'react-router-dom'

import queryString from 'query-string'

export const useExpandOnClick = () => {
  const history = useHistory()

  const onExpandClick = (rowData: any) => {
    if (!rowData || !rowData.id) return
    const updateParam = queryString.stringify({
      updateSecondaryOrganizationConnection: true,
      id: rowData.id,
      person: rowData.person,
      secondary_organization: rowData.secondary_organization,
    })
    history.push({ search: updateParam })
  }

  return onExpandClick
}
