import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import iconPaths from './Svgs'

interface CustomSvgIconProps {
  iconName: string
  iconColor?: string
  styles?: React.CSSProperties
  viewBox?: string
  props?: any
}

export const CustomSvgIcon = ({
  iconName,
  iconColor = 'black',
  styles,
  viewBox,
  ...props
}: CustomSvgIconProps) => {
  if (!iconName) return null

  const Icon = iconPaths[iconName as keyof typeof iconPaths]

  if (!Icon) return null
  return (
    <SvgIcon {...props} sx={{ ...styles }} viewBox={viewBox}>
      <Icon iconColor={iconColor} />
    </SvgIcon>
  )
}
