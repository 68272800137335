import React, { useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import _ from 'lodash'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import Button from 'frontend/components/Button'
import Spinner from 'frontend/components/Spinner'
import { GET_EVENTS, GET_VEGA_PATHWAYS_INFLUENCERS } from 'frontend/api/queries'

import GET_PATHWAYS_INFLUENCERS_HISTORY from 'frontend/api/usePathwaysOrganization/usePathwaysInfluencersHistory/useRead/gql-tag'

import Color from 'frontend/utils/color'
import Spacing from 'frontend/utils/spacing'
import stripTypename from 'frontend/Orion/shared/strip-typename'

// TODO: Pass in mutations and refetch gql tags to button cluster
import {
  CREATE_PATHWAYS_INFLUENCER_CONNECTION,
  DELETE_PATHWAYS_INFLUENCER_CONNECTION,
  UPDATE_PATHWAYS_INFLUENCER_CONNECTION,
} from 'frontend/api/mutations'

const REQUIRED_FIELD_KEYS = [
  'pathways_organization',
  'person',
  'indication_permissions',
  'pathways_influencer_types',
  'position',
  'internal_pathways_management_types',
]

const areRequiredFieldsBlank = (data) =>
  REQUIRED_FIELD_KEYS.some((key) => _.isEmpty(data[key]))

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const SpinnerWrapper = styled.div({
  width: 43,
  height: 30,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: `0 ${Spacing.S3}`,
})

const ButtonsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const ButtonCluster = ({
  isNewConnectionBeingCreated,
  cancelHandler,
  connectionData,
  setWhetherUnsavedChanges,
  setWhetherNewConnectionBeingCreated,
  changeConnection,
  connectionsData,
}) => {
  const [snackbarOpen, toggleSnackbar] = useState(false)

  connectionData = stripTypename(_.cloneDeep(connectionData))

  const {
    id,
    person,
    personId,
    pathways_organization,
    pathways_influencer_types,
    indication_permissions,
    tumor_type_specialty,
    position,
    priority,
    start_date,
    start_quarter,
    end_date,
    end_quarter,
    value_chair_indications,
    internal_tdg_note,
    internal_pathways_management_types,
    is_excluded,
    exclusion_reason,
    alert_date,
    alert_type,
    alert_description,
  } = connectionData

  const dataToPersist = {
    id,
    pathways_organization,
    person: person || personId,
    indication_permissions,
    pathways_influencer_types,
    tumor_type_specialty,
    value_chair_indications,
    internal_tdg_note,
    internal_pathways_management_types,
    position,
    priority,
    alert_date: alert_date ? new Date(alert_date) : alert_date,
    alert_type,
    alert_description,
    is_excluded,
    exclusion_reason: exclusion_reason ?? undefined,
    start_date: start_date ? new Date(start_date) : start_date,
    start_quarter: start_quarter ? new Date(start_quarter) : start_quarter,
    end_date: end_date ? new Date(end_date) : end_date,
    end_quarter: end_quarter ? new Date(end_quarter) : end_quarter,
  }

  let influencerHistoryRefetchQuery = []
  if (id) {
    influencerHistoryRefetchQuery = [
      { query: GET_PATHWAYS_INFLUENCERS_HISTORY, variables: { input: { id } } },
    ]
  }

  const [upsert, { loading: upsertLoading }] = useMutation(
    CREATE_PATHWAYS_INFLUENCER_CONNECTION,
    {
      variables: {
        input: dataToPersist,
      },
      refetchQueries: [
        { query: GET_EVENTS },
        { query: GET_VEGA_PATHWAYS_INFLUENCERS },
        ...influencerHistoryRefetchQuery,
      ],
      awaitRefetchQueries: true,
      onCompleted: (res) => {
        if (isNewConnectionBeingCreated) {
          setWhetherNewConnectionBeingCreated(false)
          const newConnectionId = Object.values(res)[0].id
          changeConnection(
            connectionsData.find(({ id }) => id === newConnectionId)
          )
        }

        toggleSnackbar(true)
        setWhetherUnsavedChanges(false)
      },
      onError: window.alert,
    }
  )

  const [updateConnection] = useMutation(
    UPDATE_PATHWAYS_INFLUENCER_CONNECTION,
    {
      variables: {
        input: dataToPersist,
      },
      refetchQueries: [
        { query: GET_EVENTS },
        { query: GET_VEGA_PATHWAYS_INFLUENCERS },
        ...influencerHistoryRefetchQuery,
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        toggleSnackbar(true)
        setWhetherUnsavedChanges(false)
      },
      onError: window.alert,
    }
  )

  const [deleteConnection] = useMutation(
    DELETE_PATHWAYS_INFLUENCER_CONNECTION,
    {
      variables: {
        input: { id },
      },
      refetchQueries: [
        { query: GET_EVENTS },
        { query: GET_VEGA_PATHWAYS_INFLUENCERS },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        changeConnection(connectionsData[0] || {}) // ! if no more connections, pass empty object
        setWhetherUnsavedChanges(false)
      },
      onError: window.alert,
    }
  )

  const deleteHandler = () => {
    if (
      window.confirm(
        'Are you sure you want to permanently delete this connection?'
      )
    ) {
      deleteConnection()
    }
  }

  const handleUpsertion = () => {
    if (areRequiredFieldsBlank(connectionData)) {
      window.alert(
        `Please fill out the fields marked required OR check off "Exclude From Tool"`
      )
      return
    }

    isNewConnectionBeingCreated ? upsert() : updateConnection()
  }

  return (
    <>
      <ButtonsWrapper>
        <Button
          color={Color.WHITE}
          onClick={cancelHandler}
          buttonStyle={{ color: Color.GRAY_DARK, margin: `0 ${Spacing.S3}` }}
        >
          Cancel
        </Button>

        {upsertLoading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <Button
            type="secondary"
            onClick={handleUpsertion}
            color={Color.GREEN}
            buttonStyle={{ margin: `0 ${Spacing.S3}` }}
          >
            Save
          </Button>
        )}

        {!isNewConnectionBeingCreated && (
          <Button
            buttonStyle={{ margin: `0 ${Spacing.S3}` }}
            onClick={deleteHandler}
            type="secondary"
            color={Color.RED}
            iconName="delete"
            iconColor1={Color.RED}
          />
        )}
      </ButtonsWrapper>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        onClose={() => toggleSnackbar(false)}
        autoHideDuration={5000}
      >
        <Alert onClose={() => toggleSnackbar(false)} severity="success">
          Connection saved!
        </Alert>
      </Snackbar>
    </>
  )
}

ButtonCluster.propTypes = {
  isNewConnectionBeingCreated: PropTypes.bool.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  connectionData: PropTypes.object.isRequired,
  setWhetherUnsavedChanges: PropTypes.func.isRequired,
  setWhetherNewConnectionBeingCreated: PropTypes.func.isRequired,
  changeConnection: PropTypes.func.isRequired,
  connectionsData: PropTypes.array.isRequired,
}

ButtonCluster.defaultProps = {}

export default ButtonCluster
