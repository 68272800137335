import React from 'react'
import { CalendarIcon } from '@mui/x-date-pickers'

export const getOpenPickerIcon = ({
  openPickerIcon: Icon,
  iconName,
  customIconProps,
}: any) => {
  return Icon
    ? (iconProps: any) => (
        <Icon iconName={iconName} {...iconProps} {...customIconProps} />
      )
    : CalendarIcon
}
