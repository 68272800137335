import { formatMonthDayYearSlash } from 'frontend/utils/formatDate'

const injectExportConfig = ({
  indication,
  kdmType,
  providerLabelMap,
  nccnPanel,
  pathwaysOrganizations,
}) => [
  {
    Header: 'Person ID',
    accessor: 'person_id',
  },
  {
    Header: 'Provider Slug',
    accessor: 'provider_slug',
  },
  {
    Header: 'First Name',
    accessor: 'person_first_name',
  },
  {
    Header: 'Last Name',
    accessor: 'person_last_name',
  },
  {
    Header: 'Provider Account',
    accessor: 'provider_organization',
    labelMap: providerLabelMap,
  },
  {
    Header: 'Status',
    accessor: 'dynamic_status',
  },
  {
    Header: 'KDM Type(s)',
    accessor: 'key_decision_maker_types',
    labelMap: kdmType.labelMap,
  },
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Specialty',
    accessor: 'specialty',
  },
  {
    Header: 'Indication(s)',
    accessor: 'indications',
    labelMap: indication.labelMap,
  },
  {
    Header: 'Pathways Organization(s)',
    accessor: 'person_pathways_organizations',
    labelMap: pathwaysOrganizations.labelMap,
  },
  {
    Header: 'Twitter Handle',
    accessor: 'person_twitter_username',
  },
  {
    Header: 'Exclude From KDM Tool',
    accessor: 'is_excluded_from_kdm_tool',
    cellValueFormatter: (is_excluded_from_tool) =>
      is_excluded_from_tool ? 'Yes' : 'No',
  },
  {
    Header: 'Exclude From All Tools',
    accessor: 'is_excluded',
    cellValueFormatter: (is_excluded) => (is_excluded ? 'Yes' : 'No'),
  },
  {
    Header: 'Update Log',
    accessor: 'updated_at',
    cellValueFormatter: formatMonthDayYearSlash,
  },
  {
    Header: 'External Link',
    accessor: 'person_professional_profile',
  },
  {
    Header: 'NCCN Panels',
    accessor: 'person_nccn_panels',
    labelMap: nccnPanel.labelMap,
  },
  {
    Header: 'NPI',
    accessor: 'person_npi',
  },
]

export default injectExportConfig
