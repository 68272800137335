import { getGqlDataKey } from 'frontend/utils'

import { LazyQueryResponse, UsePaginatedReadConfig } from '../interfaces'

const emptyCacheAndGetFirstDataPage = (
  {
    tag,
    input = {},
  }: UsePaginatedReadConfig,
  {
    getData,
    apolloClient,
  }: LazyQueryResponse,
) => {
  const cacheData = apolloClient.readQuery({
    query: tag,
  })

  if (cacheData) {
    const dataKey = getGqlDataKey(cacheData)
    apolloClient.writeQuery({
      query: tag,
      data: { [dataKey]: [] },
    })
  }

  setTimeout(() => getData({
    variables: { input: { page: '1', page_size: input.page_size } }
  }), 0)
}

export default emptyCacheAndGetFirstDataPage
