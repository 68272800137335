import React from 'react'
import styled from '@emotion/styled'

import { NoSelectListPanel } from 'frontend/components'
import NodeListItem from './NodeListItem'

type Type = 'tool' | 'dashboard' | 'page' | 'card'
interface Props {
  type: Type
  data: any
  onSelect?: (item: any) => void
  selected?: any
}

const Wrapper = styled.div({
  height: 'calc(100vh - 112px - 87px)',
})

const NodesListPanel = ({ data, type, onSelect, selected }: Props) => {
  return (
    <Wrapper>
      <NoSelectListPanel
        data={data}
        searchStringAccessor={'id'}
        ListItem={(listItemProps: any) => (
          <NodeListItem
            type={type}
            isSelected={
              selected && selected.node.id === listItemProps.data.node.id
            }
            data={listItemProps.data}
            onClick={() => onSelect && onSelect(listItemProps.data)}
          />
        )}
      />
    </Wrapper>
  )
}

export default NodesListPanel
