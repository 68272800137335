import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

// ? menuPosition 'fixed' inspiration: // https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown

const getStyles = () => ({
  control: () => ({
    display: 'flex',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: -0.2,
    border: '1px solid transparent',
    cursor: 'pointer',
    caretColor: 'transparent',
    borderRadius: 4,
    transition: 'all 250ms ease',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
  }),
})

const overrideComponents = {
  IndicatorSeparator: () => null,
  Placeholder: () => null,
}

const SelectCell = ({ cellConfig, cellValue, rowInputRef, column }) => {
  const { optionMap, isMulti, defaultValue, options, isDisabled } = cellConfig
  if (defaultValue) cellValue = defaultValue
  const { id: columnId } = column

  const [stagedValue, setStagedValue] = useState()

  useEffect(() => {
    handleCellValueMapping(cellValue, setStagedValue, optionMap)
  }, [cellValue, optionMap])

  useEffect(() => {
    setRowInputRefToDefault({ defaultValue, rowInputRef, columnId })
  }, [])

  const onSelectChange = (option) => {
    if (isMulti && option === null) {
      option = []
    }

    const input = isMulti ? option.map(({ value }) => value) : option.value

    setStagedValue(option)
    rowInputRef.current[columnId] = input
  }

  return (
    <Select
      isMulti={isMulti}
      value={stagedValue}
      options={options}
      onChange={onSelectChange}
      isDisabled={isDisabled}
      components={overrideComponents}
      styles={getStyles()}
      menuPosition={'fixed'}
    />
  )
}

SelectCell.propTypes = {
  cellConfig: PropTypes.object.isRequired,
  cellValue: PropTypes.any,
  rowInputRef: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
}

export default SelectCell

// TODO: This helper function likely deserves a comment explaining how it works with the cell config to be as clear as possible.
const handleCellValueMapping = (cellValue, setStagedValue, optionMap) => {
  if (cellValue && optionMap) {
    let newStagedValue = undefined

    if (cellValue instanceof Array) {
      newStagedValue = []
      for (let i = 0; i < cellValue.length; i++) {
        const currentMappedValue = optionMap[cellValue[i]]
        if (!currentMappedValue) {
          newStagedValue = undefined
          break
        }

        newStagedValue.push(currentMappedValue)
      }
    } else {
      newStagedValue = optionMap[cellValue]
    }

    setStagedValue(newStagedValue)
  }
}

const setRowInputRefToDefault = ({ defaultValue, rowInputRef, columnId }) => {
  if (defaultValue) rowInputRef.current[columnId] = defaultValue
}
