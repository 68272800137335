import React, { Dispatch, SetStateAction } from 'react'

import { LabelCheckbox } from 'frontend/components'

interface Props {
  team: any
  setFieldState: Dispatch<SetStateAction<any>>
}

export const Checkbox = ({ team, setFieldState }: Props) => {
  const handleChange = ({ target: { checked, name } }: any) => {
    setFieldState(
      ({
        teams,
        primaryTeam,
        isCustomOncologyKdmToolScorer,
        ...fieldState
      }: any) => {
        const newTeam = {
          id: team.id,
          name: team.name,
        }
        if (checked) teams = [...teams, newTeam]
        else {
          teams = teams.filter(({ id }: any) => id !== name)
          if (name === primaryTeam) {
            primaryTeam = null
            isCustomOncologyKdmToolScorer = false
          }
        }

        return {
          teams,
          primaryTeam,
          isCustomOncologyKdmToolScorer,
          ...fieldState,
        }
      }
    )
  }

  return (
    <LabelCheckbox label={team.name} name={team.id} onChange={handleChange} />
  )
}
