import { useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { PUSH_PRODUCTION_PERMISSIONS, GET_LATEST_PERMISSIONS_PUSHER } from './gql-tags'

const TOAST: any = {
  SUCCESS_MESSAGE: 'Production permissions pushed successfully!',
  SUCCESS_OPTIONS: {
    icon: true,
    theme: 'dark',
    style: {
      width: '500px', borderRadius: '8px', backgroundColor: '#0E2539'
    },
  },
  ERROR_OPTIONS: {
    icon: true,
    theme: 'dark',
    style: {
      width: '500px', borderRadius: '8px', backgroundColor: '#FCEEED', color: '#AF3528' ,
    },
  },
}

export const useProductionPermissions = () => {
  const [push, { loading: isPushing }] = useMutation(PUSH_PRODUCTION_PERMISSIONS, {
    onCompleted: () => {
      toast.success(TOAST.SUCCESS_MESSAGE, TOAST.SUCCESS_OPTIONS)
    },
    onError: (error) => {
      toast.error(error.message, TOAST.ERROR_OPTIONS)
    },
    refetchQueries: [{ query: GET_LATEST_PERMISSIONS_PUSHER }],
  })

  const { data, loading: isLoadingLatestPusher } = useQuery(GET_LATEST_PERMISSIONS_PUSHER, {
    pollInterval: 1000,
  })

  return {
    push,
    isPushing,
    latestPusher: data?.getLatestPermissionsPusher,
    isLoadingLatestPusher,
  }
}
