import React from 'react'
import styled from '@emotion/styled'

import { NewPolicy } from './NewPolicy'
import { LastApprovedPolicy } from './LastApprovedPolicy'

interface BottomContainerProps {
  lastIdentifiedChangeDate: string
  policyDocLinkChange: string
  policyDocLinkChangeSource: string
  lastApprovedDate: string
  policyDocLink: string
}

const Container = styled.div({
  display: 'flex',
  borderTop: '1px solid #E0E0E0',
})

export const BottomContainer = ({
  lastIdentifiedChangeDate,
  policyDocLinkChange,
  policyDocLinkChangeSource,
  lastApprovedDate,
  policyDocLink,
}: BottomContainerProps) => {
  return (
    <Container>
      <LastApprovedPolicy
        lastApprovedDate={lastApprovedDate}
        policyDocLink={policyDocLink}
      />
      <NewPolicy
        lastIdentifiedChangeDate={lastIdentifiedChangeDate}
        policyDocLinkChange={policyDocLinkChange}
        policyDocLinkChangeSource={policyDocLinkChangeSource}
      />
    </Container>
  )
}
