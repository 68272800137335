import { useQuery } from '@apollo/client'

import {
  GET_CLINICAL_TRIALS,
  GET_VEGA_PROVIDERS,
  GET_VEGA_INDICATIONS,
  GET_VEGA_PRODUCTS,
  GET_CLINICAL_TRIALS_OPTIONS,
} from 'frontend/api/queries'

import { getLabelMap, getScoreMap, getOptionsMap } from './utils'

interface HydratedDataParams {
  clinicalTrialsData: any
  providerSlugLabelMap: any
  hydratedClinicalTrialData: any
}
const useClinicalTrialData = () => {
  const clinicalTrialsData = useQuery(GET_CLINICAL_TRIALS)
  const providerData = useQuery(GET_VEGA_PROVIDERS)
  const indicationData = useQuery(GET_VEGA_INDICATIONS)
  const productData = useQuery(GET_VEGA_PRODUCTS)
  const clinicalTrialsOptionsData = useQuery(GET_CLINICAL_TRIALS_OPTIONS)

  const selectLabelMapDependencies = [
    {
      data: providerData,
      accessor: 'name',
    },
    {
      data: providerData,
      accessor: 'slug',
    },
    {
      data: indicationData,
      accessor: 'name',
    },
    {
      data: productData,
      accessor: ['brand_name', 'generic_name'],
    },
  ]

  const [
    providerLabelMap,
    providerSlugLabelMap,
    indicationLabelMap,
    productLabelMap,
  ] = selectLabelMapDependencies.map((dependency) => getLabelMap(dependency))

  const selectOptionsMapDependencies = [
    {
      data:
        clinicalTrialsOptionsData.data?.clinicalTrialsOptions.actions.POST
          .phases.child.choices,
      loading: clinicalTrialsOptionsData.loading,
    },
    {
      data:
        clinicalTrialsOptionsData.data?.clinicalTrialsOptions.actions.POST
          .review_status.choices,
      loading: clinicalTrialsOptionsData.loading,
    },
    {
      data:
        clinicalTrialsOptionsData.data?.clinicalTrialsOptions.actions.POST
          .verification_reason.choices,
      loading: clinicalTrialsOptionsData.loading,
    },
  ]

  const [
    phaseOptions,
    reviewStatusOptions,
    verificationReasonOptions,
  ] = selectOptionsMapDependencies.map((dependency) =>
    getOptionsMap(dependency)
  )

  const confidenceScoreLabelMap = getScoreMap({
    data: clinicalTrialsData,
    accessor: 'confidence_score',
  })

  const hydratedClinicalTrialData: any = []

  addSlugToClinicalTrialsData({
    clinicalTrialsData,
    providerSlugLabelMap,
    hydratedClinicalTrialData,
  })

  return {
    hydratedClinicalTrialData,
    providers: {
      data: providerData.data,
      loading: providerData.loading,
      labelMap: providerLabelMap,
      slugMap: providerSlugLabelMap,
    },
    indications: {
      data: indicationData.data,
      loading: indicationData.loading,
      labelMap: indicationLabelMap,
    },
    products: {
      data: productData.data,
      loading: productData.loading,
      labelMap: productLabelMap,
    },
    clinicalTrialsOptions: {
      phases: {
        options: phaseOptions,
      },
      review_status: {
        options: reviewStatusOptions,
      },
      verification_reasons: {
        options: verificationReasonOptions,
      },
      confidence_score: {
        labelMap: confidenceScoreLabelMap,
      },
    },
    loading:
      providerData.loading ||
      indicationData.loading ||
      productData.loading ||
      clinicalTrialsOptionsData.loading ||
      clinicalTrialsData.loading,
  }
}

export default useClinicalTrialData

const addSlugToClinicalTrialsData = ({
  clinicalTrialsData,
  providerSlugLabelMap,
  hydratedClinicalTrialData,
}: HydratedDataParams) => {
  if (!clinicalTrialsData.loading) {
    clinicalTrialsData.data.clinicalTrials.forEach((datum: any) => {
      const newDatum = Object.assign(
        {
          slug: providerSlugLabelMap[datum.provider],
        },
        datum
      )
      hydratedClinicalTrialData.push(newDatum)
    })
  }
}
