import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

import { useRewriteSearchString } from 'frontend/hooks'

import { Tabs } from '@mui/base/Tabs'
import { StyledTab } from './Tab'
import { StyledTabsList } from './TabList'

export const HeaderTabs = () => {
  const location = useLocation()
  const rewriteSearchString = useRewriteSearchString()
  const queryParams = qs.parse(location.search)
  const initialTab =
    typeof queryParams.view === 'string' ? queryParams.view : 'mostRecent'
  const [value, setValue] = useState<string>(initialTab)

  useEffect(() => {
    setValue(initialTab)
  }, [initialTab])

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
    const view = newValue === 'comparison' ? 'comparison' : 'mostRecent'
    rewriteSearchString({ view })
  }

  return (
    <Tabs value={value} onChange={handleChange} aria-label="header tabs">
      <StyledTabsList>
        <StyledTab value="mostRecent">Most Recent</StyledTab>
        <StyledTab value="comparison">Comparison</StyledTab>
      </StyledTabsList>
    </Tabs>
  )
}
