import { Options } from '../../interfaces'

export const removeEntityFromCache = ({ cache, data, readTag }: Options) => {
  if (!readTag) return
  const response: any = Object.values(data)[0] || {}
  const responseData: any = Object.values(response)[0] || {}
  const cacheId = cache.identify(responseData)
  cache.evict({ id: cacheId })
  cache.gc()
}
