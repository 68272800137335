import React from 'react'
import styled from '@emotion/styled'

import ContentWrapper from './ContentWrapper'

interface Props {
  isHoveredListItem: boolean
  isSelected: boolean
  data: any
}

const Wrapper = styled.section({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const Item = ({ isHoveredListItem, isSelected, data }: Props) => {
  return (
    <Wrapper>
      <ContentWrapper
        isHoveredListItem={isHoveredListItem}
        isSelected={isSelected}
        data={data}
      />
    </Wrapper>
  )
}

export default Item
