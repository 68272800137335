import React from 'react'
import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'

const ICON_STYLES = { width: '18px', height: '18px' }

export const getStatusOptions = () => {
  return {
    approved: {
      icon: (
        <CustomSvgIcon
          iconName="CircleCheck"
          iconColor="#12934E"
          styles={ICON_STYLES}
        />
      ),
      label: 'Approved',
    },
    needs_approval: {
      icon: (
        <CustomSvgIcon
          iconName="Caution"
          iconColor="#FF8B33"
          styles={ICON_STYLES}
        />
      ),
      label: 'Needs Approval',
    },
    incorrect_policy: {
      icon: (
        <CustomSvgIcon
          iconName="CircleX"
          iconColor="#AF3528"
          styles={ICON_STYLES}
        />
      ),
      label: 'Incorrect Policy',
    },
    not_applicable: {
      icon: (
        <CustomSvgIcon
          iconName="Error"
          iconColor="#798BA3"
          styles={ICON_STYLES}
        />
      ),
      label: 'N/A',
    },
  }
}
