import { GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS } from 'frontend/api/queries'

import findCreatedPathwaysPositionIndex from './findCreatedPathwaysPositionIndex'

const updateLatestPathwaysPositionsOnCreate = (
  cache,
  createdPathwaysPosition,
  fieldLabelMap
) => {
  let pathwaysPositions = undefined

  try {
    const pathwaysPositionsData = cache.readQuery({
      query: GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS,
    })
    pathwaysPositions = pathwaysPositionsData.pathwaysIndicationRegimenPositions
  } catch (e) {}

  if (!pathwaysPositions) return

  /*
    Find index of where createdPathwaysPosition should go, and if it contains a new pathways organization,
    indication, regimen, and populations combination.
  */
  const [
    createdPathwaysPositionIndex,
    isNewPathOrgIndReg,
    isNewPopulations,
  ] = findCreatedPathwaysPositionIndex(
    createdPathwaysPosition,
    pathwaysPositions,
    fieldLabelMap
  )

  /*
    If pathways position with matching unique fields + populations already exists, check if latest.
  */
  const isNewPathwaysPosition = isNewPathOrgIndReg || isNewPopulations
  let isLatestPathwaysPosition = false
  if (!isNewPathwaysPosition) {
    const currentPathwaysPosition =
      pathwaysPositions[createdPathwaysPositionIndex]
    isLatestPathwaysPosition =
      new Date(createdPathwaysPosition.position_date).getTime() >
      new Date(currentPathwaysPosition.position_date).getTime()
  }

  /*
    If new pathways position is unique, splice. If not unique and is latest, replace.
  */
  if (isNewPathwaysPosition || isLatestPathwaysPosition) {
    const newPathwaysPositions = [...pathwaysPositions]

    isNewPathwaysPosition
      ? newPathwaysPositions.splice(
          createdPathwaysPositionIndex,
          0,
          createdPathwaysPosition
        )
      : (newPathwaysPositions[
          createdPathwaysPositionIndex
        ] = createdPathwaysPosition)

    cache.writeQuery({
      query: GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS,
      data: { pathwaysIndicationRegimenPositions: newPathwaysPositions },
    })
  }
}

export default updateLatestPathwaysPositionsOnCreate
