import React, { useEffect, useState } from 'react'

import Comparison from './Comparison'

interface Props {
  comparisonUrl: string
  originalUrl: string
}

const InnerContainer = ({
  comparisonUrl,
  originalUrl
}: Props) => {
  const [viewer, setViewer] = useState<any>(null)

  useEffect(() => {
    if (viewer) setViewer(null)
  }, [originalUrl])

  useEffect(() => {
    if (!viewer) {
      setViewer(
        <Comparison
          comparisonUrl={comparisonUrl}
          originalUrl={originalUrl}
        />
      )
    }
  }, [viewer])

  return (
    <div style={{ height: '100%', width: '100%', marginLeft: '48px' }}>
      {viewer}
    </div>
  )
}

export default InnerContainer
