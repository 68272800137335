import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { GET_PAYER_PROJECT_IMPORT_TIMESTAMPS } from 'frontend/api/queries'

import { formatDateMonthYearLong } from 'frontend/utils/formatDate'
import Color from 'frontend/utils/color'
import { Colors } from 'frontend/utils/pulseStyles'
import Transitions from 'frontend/utils/transitions'

const PlacardWrapper = styled.div`
  padding: 12px;
  width: 20%;
  display: flex;
  @media (max-width: 1600px) {
    width: 25%;
  }
  @media (max-width: 1300px) {
    width: calc(100% / 3);
  }
  @media (max-width: 1000px) {
    width: 50%;
  }
  @media (max-width: 732px) {
    width: 100%;
  }
`

const Placard = styled(Link)`
  padding: 32px 24px;
  background: ${Colors.WHITE};
  width: 100%;
  border-radius: 8px;
  transition: background ${Transitions.NORMAL};
  &:hover {
    box-shadow: 0 0 0 4px ${transparentize(0.4, Colors.PRIMARY)};
  }
`

const ProjectName = styled.div`
  color: ${Color.BLACK};
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.2px;
`

const LastImport = styled.div(
  {
    color: transparentize(0.4, Color.BLACK),
    fontWeight: 500,
    transition: `opacity ${Transitions.NORMAL}`,
    fontSize: 12,
    marginTop: 8,
  },
  ({ isVisible }) => ({
    opacity: isVisible ? 1 : 0,
  })
)

const ProjectPlacard = ({ projectName, projectId }) => {
  const [lastImportDate, setLastImportDate] = useState(null)

  const { data, loading } = useQuery(GET_PAYER_PROJECT_IMPORT_TIMESTAMPS, {
    variables: { projectId },
  })

  useEffect(() => {
    if (!loading) {
      const [sortedTimestamps] = [
        ...data.payerProjectPtpsImportTimestamps.timestamps,
      ]
        .sort()
        .reverse()

      setLastImportDate(sortedTimestamps)
    }
  }, [loading])

  const formattedDate = lastImportDate
    ? formatDateMonthYearLong(lastImportDate)
    : null

  return (
    <PlacardWrapper>
      <Placard to={`/payer-projects/${projectId}/import-historical-data`}>
        <ProjectName>{projectName}</ProjectName>
        <LastImport isVisible={Boolean(formattedDate)}>
          Last Import: {formattedDate}
        </LastImport>
      </Placard>
    </PlacardWrapper>
  )
}

ProjectPlacard.propTypes = {
  projectName: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
}

export default ProjectPlacard
