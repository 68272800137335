import React from 'react'
import styled from '@emotion/styled'
import Color from 'frontend/utils/color'

import Header from './Header'
import NodesListPanel from './NodesListPanel/NodesListPanel'
import { Type } from '../../types'

interface Props {
  type: Type
  data: any
  onSelect?: (item: any) => void
  selected?: any
}

const Wrapper = styled.div(
  {
    flex: 1,
  },
  ({ type }: Props) => ({
    borderRight: type !== 'card' ? `2px solid ${Color.GRAY_LIGHT}` : undefined,
  })
)

const NodesPanel = ({ type, data, onSelect, selected }: Props) => {
  return (
    <Wrapper type={type} data={undefined}>
      <Header type={type} />
      <NodesListPanel
        type={type}
        data={data}
        onSelect={onSelect}
        selected={selected}
      />
    </Wrapper>
  )
}

export default NodesPanel
