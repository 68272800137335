import React from 'react'
import format from 'date-fns/format'

import Icon from 'frontend/components/Icon'

interface NewPolicyLabelProps {
  lastIdentifiedChangeDate: string
}

const DATA_PRIMARY_YELLOW = '#FFCD1D'

const FONT_STYLES = {
  fontSize: 12,
  lineHeight: '18px',
  letterSpacing: -0.1,
  fontWeight: 500,
  padding: '0 12px 0 6px',
}

const formatDate = (date: any) => format(new Date(date), 'PP')

export const NewPolicyLabel = ({
  lastIdentifiedChangeDate,
}: NewPolicyLabelProps) => {
  const newPolicyDate = formatDate(lastIdentifiedChangeDate)

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon
        height={18}
        width={18}
        iconName={'caution-solid'}
        color1={DATA_PRIMARY_YELLOW}
      />
      <span style={FONT_STYLES}>New Policy: {newPolicyDate}</span>
    </div>
  )
}
