import { NccnPanel } from 'frontend/interfaces'

const getInjectables = (nccnPanelsData: any, addDeleteSearchString: any) => {
  const nccnPanels = getNccnPanels(nccnPanelsData.nccnPanels)
  const indications = getIndications(nccnPanelsData.indications)
  return {
    addDeleteSearchString,
    indications,
    nccnPanels,
  }
}

export default getInjectables

const getNccnPanels = (nccnPanels: any) => {
  const update = (input: NccnPanel) =>
    nccnPanels.update({ variables: { input } })

  return {
    update,
  }
}

const getIndications = ({ labelMap, options }: any) => ({
  labelMap,
  options,
})
