import gql from 'graphql-tag'
import { schemaV0 } from './schemas'

export const UPDATE_NCCN_PANEL = gql`
  mutation updateNccnPanel($input: UpdateNccnPanelInput!) {
    updateNccnPanel(input: $input) {
      ${schemaV0}
    }
  }
`
