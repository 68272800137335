import React from 'react'
import styled from '@emotion/styled'

const HEADER_TEXT = 'Danger Zone'

const Title = styled.h1({
  fontSize: 18,
  fontWeight: 525,
  marginBottom: 24,
})

export const Header = () => {
  return <Title>{HEADER_TEXT}</Title>
}
