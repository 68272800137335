import React, { Dispatch, SetStateAction } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { useEmberNode } from 'frontend/entity-hooks/ember'

import { ParentType, Sitemap, Type } from '../../../types'
import LoadingPanel from './LoadingPanel'
import NodesListPanel from './NodesListPanel'

interface Props {
  type: Type
  parentType?: ParentType
  sitemap?: Sitemap
  setSitemap: Dispatch<SetStateAction<Sitemap | undefined>>
}

const InnerContainer = ({
  type,
  parentType,
  sitemap,
  ...props
}: Props) => {
  const location = useLocation()
  const queryStringObj = queryString.parse(location.search)

  let queryInput = { type }
  if (parentType) queryInput = {
    [parentType]: queryStringObj[parentType],
    ...queryInput
  }

  const { isLoading } = useEmberNode({ queryInput })

  if (!sitemap || isLoading) return (
    <LoadingPanel />
  )

  return (
    <NodesListPanel
      type={type}
      parentType={parentType}
      sitemap={sitemap}
      {...props}
    />
  )
}

export default InnerContainer
