import React from 'react'
import { useQuery } from '@apollo/client'

import { GET_SOURCE_INDICATIONS } from 'frontend/api/queries'

import { useQueryString } from "frontend/hooks"

import IndicationLabel from './IndicationLabel'

const Container = () => {
  const { indication } = useQueryString()

  const { loading: areTreatmentPlansLoading } = useQuery(GET_SOURCE_INDICATIONS)

  if (areTreatmentPlansLoading || !indication) return null

  return (
    <IndicationLabel />
  )
}

export default Container
