import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Home from './Home'
import Sitemap from './Sitemap'

const ClientManagement = () => {
  return (
    <div style={{ flex: 1 }}>
      <Switch>
        <Route exact path={'/client-management'} component={Home} />
        <Route
          path={'/client-management/sitemap/:client/:team'}
          component={Sitemap}
        />
      </Switch>
    </div>
  )
}

export default ClientManagement
