import React, { Dispatch, SetStateAction } from 'react'
import { useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import queryString from 'query-string'

import { ListPanel } from 'frontend/components'
import { useEmberNode } from 'frontend/entity-hooks/ember'

import { ParentType, Sitemap, Type } from '../../../types'
import NodeListItem from './NodeListItem'

interface Props {
  type: Type
  parentType?: ParentType
  sitemap: Sitemap
  setSitemap: Dispatch<SetStateAction<Sitemap | undefined>>
}

interface ListItemProps {
  isSelected: boolean
  data: any
}

const Wrapper = styled.div({
  height: 'calc(100vh - 112px - 87px)',
})

const NodesListPanel = ({
  type,
  parentType,
  ...props
}: Props) => {
  const location = useLocation()
  const queryStringObj = queryString.parse(location.search)

  let queryInput = { type }
  if (parentType) queryInput = {
    [parentType]: queryStringObj[parentType],
    ...queryInput
  }

  const { data } = useEmberNode({ queryInput })

  const ListItem = (listItemProps: ListItemProps) => (
    <NodeListItem
      type={type}
      {...listItemProps}
      {...props}
    />
  )

  return (
    <Wrapper>
      <ListPanel
        data={data}
        searchStringAccessor={'id'}
        searchStringKey={type}
        ListItem={ListItem}
      />
    </Wrapper>
  )
}

export default NodesListPanel
