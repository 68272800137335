import React from 'react'

import { NewPolicyLabel } from './NewPolicyLabel'
import { NewPolicyControls } from './NewPolicyControls'
import styled from '@emotion/styled'

const Container = styled.section({
  display: 'flex',
  padding: '0 0 0 20px',
  alignItems: 'center',
})

export const NewPolicy = ({
  lastIdentifiedChangeDate,
  policyDocLinkChange,
  policyDocLinkChangeSource,
}: any) => {
  return (
    <Container>
      <NewPolicyLabel lastIdentifiedChangeDate={lastIdentifiedChangeDate} />
      <NewPolicyControls
        policyDocLinkChange={policyDocLinkChange}
        policyDocLinkChangeSource={policyDocLinkChangeSource}
      />
    </Container>
  )
}
