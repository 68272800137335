import { MultiSelectFilter } from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'
import {
  DISPLAY_CELL_TYPES,
  EDIT_CELL_TYPES,
  FILTER_TYPES,
  SORT_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'

const { CONDENSED_BUTTON } = DISPLAY_CELL_TYPES
const { CONDENSED_SINGLE_SELECT_INPUT, CONDENSED_TEXT_INPUT } = EDIT_CELL_TYPES
const { MULTI_SELECT } = FILTER_TYPES
const { DISPLAY, STRING, TEXT_ARRAY } = SORT_TYPES

export const getColConfig = (handleUpdate, { type }, addDeleteSearchString) => [
  {
    Header: 'Name',
    accessor: 'name',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: handleUpdate,
    },
  },
  {
    Header: 'Type',
    accessor: 'type',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: DISPLAY,
    labelMap: type?.labelMap,
    editCellConfig: {
      type: CONDENSED_SINGLE_SELECT_INPUT,
      options: type?.options,
      updateFn: handleUpdate,
    },
  },
  {
    Header: 'Alias',
    accessor: 'alias',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: handleUpdate,
    },
  },
  {
    Header: 'Committees',
    accessor: 'committees',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: TEXT_ARRAY,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: handleUpdate,
    },
  },
  {
    Header: 'Internal Notes',
    accessor: 'tdg_internal_note',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: TEXT_ARRAY,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: handleUpdate,
    },
  },
  {
    Header: 'Delete',
    accessor: 'delete',
    displayCellConfig: {
      type: CONDENSED_BUTTON,
      label: 'Delete',
      onClick: addDeleteSearchString,
    },
  },
]
