import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'

import { GET_EXPORT_CONFIGURATIONS } from 'frontend/api/queries'

const useExportConfigurations = (entityId) => {
  const { data: configurationData, loading } = useQuery(
    GET_EXPORT_CONFIGURATIONS,
    {
      variables: { configId: entityId },
      skip: !entityId,
      fetchPolicy: 'network-only',
      onError: (e) =>
        alert(
          `Error while fetching export configuration data\nFull Error: ${e}`
        ),
    }
  )

  if (!entityId) return { loading: false, data: [] }

  let data = []
  if (!loading) data = configurationData.exportConfigurations

  return {
    loading,
    data,
  }
}

useExportConfigurations.propTypes = {
  entityId: PropTypes.string.isRequired,
}

export default useExportConfigurations
