import React, { Dispatch, SetStateAction } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { ParentType, Sitemap, Type } from '../../../types'
import InnerContainer from './InnerContainer'

interface Props {
  type: Type
  parentType?: ParentType
  sitemap?: Sitemap
  setSitemap: Dispatch<SetStateAction<Sitemap | undefined>>
}

const OuterContainer = ({
  parentType,
  ...props
}: Props) => {
  const location = useLocation()
  const queryStringObj = queryString.parse(location.search)

  if (parentType && !queryStringObj[parentType]) return null

  return (
    <InnerContainer
      parentType={parentType}
      {...props}
    />
  )
}

export default OuterContainer
