import React, { useState } from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

import { Label, Wrapper } from '../shared'
import { Checkbox } from './Checkbox'
import { Input } from './Input'

interface Props {
  fieldState: any
  onChange: any
}

const FIELD_NAME = 'isManualPassword'
const LABEL_TEXT = 'Password'
const DESCRIPTION_TEXT =
  'By not manually creating a password, an automated email will be sent to the user requesting signup.'

const Description = styled.p({
  paddingBottom: 4,
  color: Colors.GRAY_400,
  fontWeight: 400,
  fontSize: 12,
})

export const Password = ({ fieldState, onChange }: Props) => {
  const [isManualPassword, setIsManualPassword] = useState(
    fieldState[FIELD_NAME]
  )

  const toggleCheckbox = ({ target: { checked, name } }: any) => {
    if (!checked) onChange({ name: 'password', value: undefined })
    setIsManualPassword(checked)
    onChange({ name, value: checked })
  }

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Description>{DESCRIPTION_TEXT}</Description>
      <Checkbox checked={isManualPassword} onChange={toggleCheckbox} />
      <Input isManualPassword={isManualPassword} onChange={onChange} />
    </Wrapper>
  )
}
