const isSameTempPathwaysPosition = (
  tempPathwaysPosition,
  pathwaysPositionB
) => {
  for (const key in tempPathwaysPosition) {
    if (tempPathwaysPosition[key] !== pathwaysPositionB[key]) return false
  }
  return true
}

export default isSameTempPathwaysPosition
