import { useEffect } from 'react'

import { useNccnPanelVotingMember, usePerson } from 'frontend/entity-hooks'
import { useNccnPanels } from 'frontend/api'
import { useQueryStringParams } from './useQueryStringParams'
import { getFormData } from './getFormData'

export const useFormData = ({ inputRef }: any) => {
  const { entityId, panel, member } = useQueryStringParams()

  const VotingMember = useNccnPanelVotingMember({
    queryInput: { id: entityId },
  })
  const Person = usePerson({ queryInput: { id: member } })
  const Panel = useNccnPanels({ queryInput: { id: panel } })

  const isLoading =
    VotingMember.isLoading || Person.isLoading || Panel.isLoading
  const data = getFormData({
    isLoading,
    Person,
    Panel,
    VotingMember,
  })

  useEffect(() => {
    if (!isLoading) {
      inputRef.current.id = entityId
      inputRef.current.start_date = data.start_date
      inputRef.current.end_date = data.end_date
    }
  }, [isLoading, data, entityId])

  return {
    data,
    isLoading,
  }
}
