import React, { useState } from 'react'
import styled from '@emotion/styled'
import {
  FormLabel,
  FieldContainer,
  StyledInput,
  StyledButton,
} from '../../../shared/styledComponents'

import NewFieldSection from './NewFieldSection'

interface DynamicTextInputProps {
  text: { [key: string]: string }
  handleInput: (event: React.ChangeEvent<HTMLInputElement>) => void
  setNewField: ([key, value]: [string, string]) => void
  removeField: (key: string) => void
}

const Container = styled.div({
  overflowY: 'auto',
  height: 800,
})

const HeaderContainer = styled.div({
  justifyContent: 'space-between',
  padding: '12px 24px',
})

const InputsContainer = styled.div({
  padding: '12px 24px',
})

const DynamicTextInput: React.FC<DynamicTextInputProps> = ({
  text,
  handleInput,
  setNewField,
  removeField,
}) => {
  const [isAddingNewField, setIsAddingNewField] = useState(false)

  return (
    <Container>
      <HeaderContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h4>Dynamic Text Fields</h4>
          {!isAddingNewField && (
            <StyledButton onClick={() => setIsAddingNewField(true)}>
              +
            </StyledButton>
          )}
        </div>
        <p style={{ fontSize: 12, padding: '4px 0' }}>
          To update a dynamic list item, delete the list item and create it
          again.
        </p>
      </HeaderContainer>
      <InputsContainer>
        <NewFieldSection
          setNewField={setNewField}
          isAddingNewField={isAddingNewField}
          setIsAddingNewField={setIsAddingNewField}
        />
        {Object.entries(text).map(([key, value], index) => {
          return (
            <div key={index}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <FieldContainer style={{ width: '100%' }}>
                  <FormLabel>{key}</FormLabel>
                  <StyledInput
                    name={key}
                    type="text"
                    value={value}
                    onChange={handleInput}
                  />
                </FieldContainer>
                <div
                  style={{
                    cursor: 'pointer',
                    color: 'red',
                    fontWeight: 700,
                    padding: 6,
                  }}
                  onClick={() => removeField(key)}
                >
                  X
                </div>
              </div>
            </div>
          )
        })}
      </InputsContainer>
    </Container>
  )
}

export default DynamicTextInput
