import React from 'react'

import { Colors } from 'frontend/utils/pulseStyles'

interface Props {
  hasHazardIcon?: boolean
}

export const Icon = ({ hasHazardIcon }: Props) => {
  if (!hasHazardIcon) return null

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 24 24"
      style={{ marginRight: 8 }}
    >
      <path
        fill={Colors.RED_600}
        fill-rule="evenodd"
        d="M9.95469561,0.00129753542 C10.2671895,-0.0163736234 10.5632717,0.147542318 10.7210806,0.426392792 L10.7210806,0.426392792 L19.8873032,16.7126594 C20.0373043,16.9778502 20.0373043,17.3049003 19.8888652,17.5700912 C19.7396481,17.8360676 19.4646535,18 19.1662193,18 L19.1662193,18 L0.833777346,18 C0.535343188,18 0.260346571,17.8360841 0.111131492,17.5700912 C-0.037303203,17.3049003 -0.037303203,16.9778502 0.112693861,16.7126594 L0.112693861,16.7126594 L9.27891484,0.426392792 C9.42031207,0.176478583 9.6742271,0.0165659103 9.95468161,0.00129753542 Z M9.99999273,13.7138289 C9.53984173,13.7138289 9.16640904,14.0979451 9.16640904,14.5712606 C9.16640904,14.7986817 9.25468931,15.0164545 9.41094426,15.1771613 C9.5671992,15.337868 9.77889705,15.4286924 9.99999273,15.4286924 C10.2210884,15.4286924 10.4328043,15.3378865 10.5890412,15.1771613 C10.7452781,15.016436 10.8335764,14.7986817 10.8335764,14.5712606 C10.8335764,14.3438396 10.7452961,14.1260668 10.5890412,13.96536 C10.4327863,13.8046533 10.2210884,13.7138289 9.99999273,13.7138289 Z M9.98671099,6.42865439 C9.52733998,6.43668973 9.16016716,6.82482573 9.16640704,7.29814123 L9.16640704,7.29814123 L9.16640704,11.2405171 L9.17223771,11.3541885 C9.19411983,11.5418417 9.2760399,11.7185161 9.40624235,11.8544613 C9.56327127,12.0175893 9.7765551,12.1100039 9.99999073,12.1100039 C10.2234264,12.1100039 10.4367022,12.0175914 10.5937391,11.8544613 C10.749986,11.6913333 10.8366943,11.4695427 10.8335744,11.2405171 L10.8335744,11.2405171 L10.8335744,7.29814123 L10.8276142,7.1830547 C10.8052986,6.99334003 10.7218566,6.815997 10.5890392,6.67938324 C10.4296683,6.51545084 10.2124866,6.42544519 9.98671099,6.42865439 Z"
        transform="translate(2 3)"
      />
    </svg>
  )
}
