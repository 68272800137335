export const KEY_TO_LABEL = {
  person: 'Conneced Influencer',
  pathways_organization: 'Connected Pathways',
  tumor_type_speciality: 'Tumor Type Speciality',
  internal_tdg_note: 'Internal TDG Notes [Format - YYQQ (MM/DD:____);]',
  value_chair_indications:
    'ClinicalPath / Value Chairs Indication(s) (Internal TDG Only)',
  position: 'Pathways Position',
  priority: 'Priority',
  alert_date: 'Alert Date',
  alert_type: 'Alert Type',
  alert_description: 'Alert Description',
  is_excluded: 'Exclude From Tool',
  exclusion_reason: 'Exclude Reason',
  start_date: 'Start Date',
  end_date: 'End Date',
  start_quarter: 'Start Quarter',
  end_quarter: 'End Quarter',
  indication_permissions: 'Indications (for permissions)',
  pathways_influencer_types: 'Pathways Influencer Type',
  internal_pathways_management_types:
    'Pathways Management Type (Internal TDG Only)',
}
