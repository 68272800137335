import { gql } from '@apollo/client'
import { schemaV1 } from './schemas'

export const UPDATE_PRODUCT_POLICY_LINK = gql`
  mutation UpdateProductPolicyLink($input: UpdateProductPolicyLinkInput!) {
    updateProductPolicyLink(input: $input) {
      ${schemaV1}
    }
  }
`
