import { MetaHistoryQueryInput } from 'frontend/interfaces'

import useRead from './useRead'

const usePeopleHistory = (queryInput: MetaHistoryQueryInput = {}) =>{
  const {
    data: peopleHistoryData,
    loading: isLoading,
  } = useRead(queryInput)

  return {
    data: peopleHistoryData?.peopleHistory,
    isLoading
  }
}

export default usePeopleHistory
