import React, { useState } from 'react'

import { Label, Wrapper } from '../shared'
import { Checkbox } from './Checkbox'
import { Input } from './Input'

interface Props {
  onChange: any
}

const LABEL_TEXT = 'Default Landing Page'

export const DefaultLandingPage = ({ onChange }: Props) => {
  const [shouldLockLandingPage, setShouldLockLandingPage] = useState(false)

  const toggleCheckbox = ({ target: { checked } }: any) => {
    if (!checked) onChange({ name: 'defaultLanding', value: undefined })
    setShouldLockLandingPage(checked)
  }

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Checkbox checked={shouldLockLandingPage} onChange={toggleCheckbox} />
      <Input
        shouldLockLandingPage={shouldLockLandingPage}
        onChange={onChange}
      />
    </Wrapper>
  )
}
