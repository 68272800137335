import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useTeamSitemap } from 'frontend/entity-hooks/ember'

import Header from './Header'
import Panels from './Panels'
import ResourcesModal from './ResourcesModal'
import { Params, Sitemap as SitemapState } from './types'
import { getSitemap } from './utils'

const Sitemap = () => {
  const {
    team,
  }: Params = useParams()

  const [
    sitemap,
    setSitemap,
  ] = useState<SitemapState>()

  const {
    data,
    isLoading,
  }: any = useTeamSitemap({ queryInput: { team } })

  useEffect(() => {
    if (!isLoading) {
      const sitemap = getSitemap(data[0])
      setSitemap(sitemap)
    }
  }, [isLoading])

  return (
    <>
      <ResourcesModal />
      <Header
        sitemap={sitemap}
      />
      <Panels
        sitemap={sitemap}
        setSitemap={setSitemap}
      />
    </>
  )
}

export default Sitemap
