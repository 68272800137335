import React from 'react'
import { Button } from '@pulse-analytics/pulse-design-system'
import _ from 'lodash'

import { useRewriteSearchString } from 'frontend/hooks'

import { CreateModal } from './CreateModal'

const BUTTON_STYLE = { padding: 12 }

export const CreateModalButton = ({
  buttonStyle = BUTTON_STYLE,
  entity,
  ...props
}) => {
  const addCreateParam = useAddCreateParam(entity)

  const label = `Create ${entity}`

  return (
    <>
      <Button onClick={addCreateParam} style={buttonStyle}>
        {label}
      </Button>
      <CreateModal entity={entity} {...props} />
    </>
  )
}

const useAddCreateParam = (entity) => {
  const rewriteSearchString = useRewriteSearchString()

  const searchStringKey = _.camelCase('create ' + entity)
  const addCreateParam = () => rewriteSearchString({ [searchStringKey]: true })

  return addCreateParam
}
