import { gql } from '@apollo/client'

import { schemaV0 } from './schemas'

export const GET_TEAM_SITEMAPS = gql`
  query GetTeamSitemaps($input: TeamSitemapsInput) {
    teamSitemaps(input: $input) {
      ${schemaV0}
    }
  }
`
