import React, { useState } from 'react'

import { ErrorDialog } from 'frontend/components/Dialog'

import {
  DataTableHeader,
  DataTableContainer,
  EditableDataTable,
} from 'frontend/components'

import { useExpandOnClick } from './UpdateModal/utils'

import { useSecondaryOrganizationsConnectionsData } from './useSecondaryOrganizationsConnectionsData'
import CreateButton from './CreateButton'
import DeleteDialog from './DeleteDialog'
import {
  getExportConfig,
  getColConfig,
  useAddDeleteSearchString,
  formatTableData,
} from './utils'
import UpdateModal from './UpdateModal'

const HEADER = 'Secondary Organization Connections'

const SecondaryOrganizationsConnections = () => {
  const onExpandClick = useExpandOnClick()
  const addDeleteSearchString = useAddDeleteSearchString()
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )

  const {
    people,
    secondaryOrganizations,
    secondaryOrganizationsConnections,
  } = useSecondaryOrganizationsConnectionsData()

  const {
    data,
    isLoading,
    update: updateSecondaryOrganizationsConnections,
  } = secondaryOrganizationsConnections

  const handleUpdate = (input: any) => {
    updateSecondaryOrganizationsConnections({ variables: { input } })
  }

  const tableData = formatTableData(data)

  const colConfig = getColConfig({
    people,
    secondaryOrganizations,
    handleUpdate,
    addDeleteSearchString,
    onExpandClick,
  })

  const exportConfig = getExportConfig({ people, secondaryOrganizations })

  const { options: peopleOptions } = people
  const { options: secondaryOrganizationsOptions } = secondaryOrganizations

  const utilityBarNodes = [
    <CreateButton
      peopleOptions={peopleOptions}
      secondaryOrganizationsOptions={secondaryOrganizationsOptions}
      setErrorMessage={setErrorMessage}
    />,
  ]

  return (
    <DataTableContainer>
      <ErrorDialog
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <UpdateModal
        setErrorMessage={setErrorMessage}
      />
      <DeleteDialog />
      <DataTableHeader header={HEADER} />
      <EditableDataTable
        tableId={'secondary-organizations-connections-table'}
        hasRowCount
        isLoading={isLoading}
        colConfig={colConfig}
        data={tableData}
        exportConfig={exportConfig}
        excelLabel={'Secondary Organizations Connections'}
        utilityBarNodes={utilityBarNodes}
      />
    </DataTableContainer>
  )
}

export default SecondaryOrganizationsConnections
