import React from 'react'

import { Input, YesNoBlankSelect, Import, DateInput } from 'frontend/components'

import Select from '../Select'

export const INPUT_TYPES = {
  SINGLE_SELECT: 'singleSelect',
  MULTI_SELECT: 'multiSelect',
  YES_NO_BLANK_SELECT: 'yesNoBlankSelect',
  TEXT: 'text',
  DATE: 'date',
  EMAIL: 'email',
  NUMBER: 'number',
  RANGE: 'range',
  TIME: 'time',
  CHECKBOX: 'checkbox',
  FILE: 'file',
}

const INPUT_TYPE_TO_COMPONENT: any = {
  singleSelect: Select,
  multiSelect: (props: any) => <Select isMulti {...props} />,
  yesNoBlankSelect: YesNoBlankSelect,
  text: Input,
  date: (props: any) => <DateInput isRequired={props.isRequired} {...props} />,
  email: Input,
  number: Input,
  range: Input,
  time: Input,
  checkbox: Input,
  file: Import,
}

export default INPUT_TYPE_TO_COMPONENT
