import React from 'react'

import { Button } from '@pulse-analytics/pulse-design-system'

import Spacing from 'frontend/utils/spacing'
import Icon from 'frontend/components/Icon'
import Color from 'frontend/utils/color'

export const SourceButton = ({ policyDocLinkChangeSource }) => {
  return (
    <a
      href={policyDocLinkChangeSource}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Button
        type="secondary"
        style={{
          padding: Spacing.S2,
          margin: Spacing.S2,
        }}
      >
        <span style={{ padding: '0 4px' }}>Payer Website</span>
        <Icon height={14} width={14} iconName={'link'} color1={Color.PRIMARY} />
      </Button>
    </a>
  )
}
