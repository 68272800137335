import { Button } from '@pulse-analytics/pulse-design-system'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'
import { useClearSearchStringParam } from 'frontend/hooks'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
})

const cancelButtonStyle = {
  padding: '8px 12px',
  backgroundColor: Colors.GRAY_50,
  marginRight: 24,
  height: 28,
}

const saveButtonStyle = {
  padding: '8px 12px',
  backgroundColor: transparentize(0.85, Colors.PRIMARY),
  color: Colors.PRIMARY,
  height: 28,
}

export const ActionBar = ({ handleSubmit, deleteParams }) => {
  const clearSearchStringParam = useClearSearchStringParam()

  const cancel = () => clearSearchStringParam(['deleteDialog'])
  const closeSubmit = () =>
    clearSearchStringParam(['deleteDialog', ...deleteParams])

  const submit = () => {
    handleSubmit()
    closeSubmit()
  }

  return (
    <Wrapper>
      <Button
        type="secondary"
        onClick={cancel}
        color={Colors.GRAY_500}
        style={cancelButtonStyle}
      >
        Cancel + Close
      </Button>
      <Button type="primary" onClick={submit} style={saveButtonStyle}>
        Delete
      </Button>
    </Wrapper>
  )
}
