import MapParams from '../interfaces/MapParams.interface'

const getScoreMap = ({ data, accessor }: MapParams) => {
  const labelMap: any = {}
  if (!data.loading) {
    const [dataKey] = Object.keys(data.data)
    data.data[dataKey].forEach(
      (datum: any) => (labelMap[datum[accessor]] = datum[accessor] + '%')
    )
  }
  return labelMap
}

export default getScoreMap
