import React from 'react'

import { Button } from '@pulse-analytics/pulse-design-system'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router'

import { PUSH_TEAM_USER_REGIONS } from 'frontend/api/mutations'

import Spinner from 'frontend/components/Spinner'

const PushToProductionButton = () => {
  const { clientTeamId } = useParams()

  const [push, { loading: mutationLoading }] = useMutation(
    PUSH_TEAM_USER_REGIONS,
    {
      variables: { input: { clientTeamId } },
      onCompleted: () => alert('push success'),
    }
  )

  const buttonContent = mutationLoading ? (
    <Spinner />
  ) : (
    'Push Regions to Production'
  )

  return (
    <Button
      type="secondary"
      style={{
        fontFamily: 'inherit',
        margin: 12,
        padding: '0 6px',
      }}
      onClick={push}
    >
      {buttonContent}
    </Button>
  )
}

export default PushToProductionButton
