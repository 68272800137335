import { useClearSearchStringParam } from 'frontend/hooks'
import { getGraphQLErrorMessage } from 'frontend/utils'
import { URL_PARAM_KEY } from './url-param-key'
import { useSecondaryOrganizationsConnectionsData } from '../../useSecondaryOrganizationsConnectionsData'

interface useHandlersProps {
  inputRef: any
  setErrorMessage: any
}

export const useHandlers = ({
  inputRef,
  setErrorMessage,
}: useHandlersProps) => {
  const cancel = useCancel(inputRef)
  const submit = useSubmit({
    setErrorMessage,
    inputRef,
    cancel,
  })

  return { submit, cancel, }
}

interface UseSubmitProps {
  setErrorMessage: any
  inputRef: any
  cancel: any
}

const useSubmit = ({
  setErrorMessage,
  inputRef,
  cancel,
}: UseSubmitProps) => {
  const { secondaryOrganizationsConnections } = useSecondaryOrganizationsConnectionsData()

  return () => {
    const onError = (e: any) => {
      const errorMessage = getGraphQLErrorMessage(e)
      setErrorMessage(errorMessage)
    }

    const formattedInput = {
      start_date: inputRef.current.start_date,
      end_date: inputRef.current.end_date,
      id: inputRef.current.id,
    }

    secondaryOrganizationsConnections.update({
      variables: { input: formattedInput },
      onError,
      onCompleted: cancel,
    })
  }
}

const useCancel = (inputRef: any) => {
  const clearSearchStringParam = useClearSearchStringParam()

  return () => {
    inputRef.current = {}
    const searchStringParams = [URL_PARAM_KEY, 'id', 'person']
    clearSearchStringParam(searchStringParams)
  }
}
