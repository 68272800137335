import React from 'react'
import styled from '@emotion/styled'

import { Icon } from './Icon'

interface Props {
  hasHazardIcon?: boolean
  headerText: string
}

const Wrapper = styled.div({
  display: 'flex',
  marginBottom: 24,
})

const Title = styled.h1({
  fontSize: 20,
  fontWeight: 700,
})

export const Header = ({ hasHazardIcon, headerText }: Props) => {
  return (
    <Wrapper>
      <Icon hasHazardIcon={hasHazardIcon} />
      <Title>{headerText}</Title>
    </Wrapper>
  )
}
