import React from 'react'
import styled from '@emotion/styled'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@pulse-analytics/pulse-design-system'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'
import Spacing from 'frontend/utils/spacing'

import { downloadExcelTemplate } from './utils'

const BUTTON_STYLE = {
  background: transparentize(0.85, Colors.PRIMARY),
  color: Colors.PRIMARY,
  margin: '24px 0',
  padding: Spacing.S3,
}

const TEXT =
  'Download the excel template from below. After filling it out, upload the file in the next step.'

const Container = styled.section({
  paddingLeft: 48,
})

const Title = styled.div({
  color: Colors.PRIMARY,
  fontWeight: 700,
  paddingBottom: 12,
})

const DownloadSection = () => {
  return (
    <Container>
      <Title>STEP 1</Title>
      <p>{TEXT}</p>
      <Button onClick={downloadExcelTemplate} style={BUTTON_STYLE}>
        <span style={{ paddingRight: 6 }}>Download Excel Template</span>
        <FontAwesomeIcon icon={faDownload} />
      </Button>
    </Container>
  )
}

export default DownloadSection
