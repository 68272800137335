import React, { Dispatch, SetStateAction } from 'react'
import _ from 'lodash'

import { useEmberNode } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'

import { ToggleAllButton, ToggleAllButtonsWrapper } from '../../shared'
import { Colors } from 'frontend/utils/pulseStyles'

import ToggleAllButtons from './ToggleAllButtons'

interface Props {
  areParentResourcesLoading: boolean
  accounts?: any[]
  parentResources: any[]
  setResources: Dispatch<SetStateAction<any>>
}

const PLACEHOLDER = (
  <ToggleAllButtonsWrapper>
    <ToggleAllButton
      color={Colors.GREEN}
    >
      Toggle on All Accounts
    </ToggleAllButton>
    <ToggleAllButton
      color={Colors.MEDIUM_GRAY_2}
    >
      Toggle off All Accounts
    </ToggleAllButton>
  </ToggleAllButtonsWrapper>
)

const Container = (props: Props) => {
  const { node, nodeType, tool } = useQueryString()

  const { data, isLoading }: any = useEmberNode({
    queryInput: {
      type: 'tool',
      id: nodeType === 'tool' ? node : tool,
    }
  })

  if (isLoading) return PLACEHOLDER

  if (_.isEmpty(data[0].permitted_entity_types)) return PLACEHOLDER

  return (
    <ToggleAllButtons
      {...props}
    />
  )
}

export default Container
