import React from 'react'
import Select from 'react-select'

import { getTeamOptions } from './utils'
import { useRewriteSearchString } from 'frontend/hooks'

export const TeamSelect = ({
  selectedClient,
  selectedTeam,
  setSelectedTeam,
  teams,
}: any) => {
  const teamOptions = getTeamOptions({ teams, selectedClient })
  const rewriteSearchString = useRewriteSearchString()

  const getSelectValue = () => {
    if (selectedTeam) {
      return { value: selectedTeam.id, label: selectedTeam.name }
    }
    return null
  }

  const onChange = (selectedOption: any) => {
    const { value } = selectedOption
    const selectedTeam = teams.find((team: any) => team.id === value)
    setSelectedTeam(selectedTeam)
    rewriteSearchString({ team: value })
  }

  return (
    <div style={{ paddingBottom: '24px' }}>
      <div style={{ marginBottom: 24 }}>2. Choose a Team: </div>
      <Select
        value={getSelectValue()}
        options={teamOptions}
        onChange={onChange}
      />
    </div>
  )
}
