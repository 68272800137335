import React, { MutableRefObject, useRef } from 'react'
import _ from 'lodash'

import { Form, Spinner } from 'frontend/components'
import { SingleActionDialog } from 'frontend/components/Dialog'
import { useRewriteSearchString } from 'frontend/hooks'

import { CreateModalProps } from '../interfaces'

const CreateModal = ({
  isCreating,
  entity,
  formConfig,
  submit: handleSubmit,
  initialState = {},
}: CreateModalProps) => {
  const rewriteSearchString = useRewriteSearchString()
  const inputRef: MutableRefObject<any> = useRef(initialState)

  const setInputRef = ({ name, value }: any) => {
    inputRef.current[name] = value
  }

  const cancel = () => {
    const searchStringKey = _.camelCase('create ' + entity)
    rewriteSearchString({ [searchStringKey]: undefined })
  }
  const submit = () => handleSubmit(inputRef.current, cancel)

  const header = `Create ${entity}`
  const submitText = isCreating ? <Spinner /> : 'Save'

  return (
    <SingleActionDialog
      cancelHandler={cancel}
      header={header}
      submitHandler={submit}
      submitText={submitText}
    >
      <Form
        datum={inputRef.current}
        formConfig={formConfig}
        setFormState={setInputRef}
      />
    </SingleActionDialog>
  )
}

export default CreateModal
