interface GetData {
  isLoading: boolean
  person: any
  secondaryOrganization: any
  secondaryOrganizationConnection: any
}

export const getFormData = ({
  isLoading,
  person,
  secondaryOrganization,
  secondaryOrganizationConnection,
}: GetData) => {
  return isLoading
    ? {}
    : {
      id: secondaryOrganizationConnection.data[0].id,
      person: person.data[0].full_name,
      secondaryOrganization: secondaryOrganization.data[0].name,
      start_date: secondaryOrganizationConnection.data[0].start_date,
      end_date: secondaryOrganizationConnection.data[0].end_date,
    }
}
