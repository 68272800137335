import formatDataForExport from 'frontend/components/ExportExcelButton/formatDataForExport'

const FILENAME = 'affiliate-sites-340b-status'

const getExportConfig = (columns: any) => {
  const formatExportRows = (rows: any) => formatDataForExport({
    columns,
    data: rows,
    isReactTableData: true,
  })

  return { filename: FILENAME, formatRows: formatExportRows }
}

export default getExportConfig
