import React from 'react'
import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'

export const getCustomIcon = ({ ...props }) => {
  return (
    <CustomSvgIcon
      {...props}
      iconName="ChevronDown"
      iconColor="#BBBFC3"
      styles={{
        width: '10px',
        height: '10px',
      }}
    />
  )
}
