import { DataProxy } from '@apollo/client'

import { GET_USERS } from 'frontend/entity-hooks/ember/useEmberUser/gql-tags'

export const addUserToTeamCache = (
  teamId: string,
  user: any,
  cache: DataProxy
) => {
  const variables = {
    input: {
      team: teamId,
    },
  }
  const query: any = cache.readQuery({
    query: GET_USERS,
    variables,
  })

  if (query) {
    const queryName = Object.keys(query)[0]

    cache.writeQuery({
      query: GET_USERS,
      variables,
      data: {
        [queryName]: [user, ...query[queryName]],
      },
    })
  }
}
