export const schemaV1 = `
  id
  product_payer_policy_tracking_projects
  assigned_polaris_user_auth0_ids
  product
  policy_link
  s3_uri
  link
  link_last_updated
  link_note
  link_steps
  link_type
  link_status
  policy_doc_link
  policy_doc_link_source
  policy_doc_link_change
  policy_doc_link_change_source
  policy_search_word
  approval_status
  last_tdg_review_date
  last_policy_review_date
  last_approved_change_date
  last_identified_change_date
  ui_path_template_id
  ui_path_template_last_updated
  created_at
  updated_at
`
