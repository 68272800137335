import React, { useState } from 'react'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

import { DateInputWrapper } from './DateInputWrapper'
import {
  getCustomStyles,
  getOpenPickerIcon,
  DATE_TIME_PICKER_PROPS,
} from './utils'

export const DateInput = ({ isRequired, customStyles, ...props }: any) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleChange = (value: any) => {
    setIsFocused(true)
    DATE_TIME_PICKER_PROPS.GET_ON_CHANGE(props)(value)
  }

  const CUSTOM_STYLES = getCustomStyles({ customStyles })
  const OPEN_PICKER_ICON = getOpenPickerIcon({ ...props })

  return (
    <DateInputWrapper>
      <DateTimePicker
        {...props}
        value={DATE_TIME_PICKER_PROPS.GET_VALUE(props)}
        onChange={handleChange}
        minDateTime={DATE_TIME_PICKER_PROPS.MIN_DATE_TIME}
        views={DATE_TIME_PICKER_PROPS.VIEWS}
        timezone={DATE_TIME_PICKER_PROPS.TIMEZONE}
        sx={CUSTOM_STYLES}
        slots={{
          openPickerIcon: OPEN_PICKER_ICON,
        }}
        slotProps={{
          ...DATE_TIME_PICKER_PROPS.GET_SLOT_PROPS({
            ...props,
            isFocused,
            isRequired,
          }),
        }}
      />
    </DateInputWrapper>
  )
}
