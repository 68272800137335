import gql from 'graphql-tag'
import { schemaV0 } from './schemas'

export const CREATE_NCCN_PANEL = gql`
  mutation CreateNccnPanel($input: CreateNccnPanelInput!) {
    createNccnPanel(input: $input) {
      ${schemaV0}
    }
  }
`
