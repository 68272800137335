import React from 'react'

import {
  DataTableHeader,
  DataTableContainer,
  EditableDataTable,
} from 'frontend/components'
import { useSecondaryOrganizations } from 'frontend/entity-hooks'
import { useRewriteSearchString } from 'frontend/hooks'

import CreateButton from './CreateButton'
import DeleteDialog from './DeleteDialog'
import { getColConfig } from './utils'

const HEADER = 'Secondary Organizations'

const SecondaryOrganizations = () => {
  const { data, isLoading, update, options } = useSecondaryOrganizations()

  const addDeleteSearchString = useAddDeleteSearchString()

  const handleUpdate = (input: any) => {
    const { committee } = input
    if (committee) {
      input.committee = committee.split(/\s*,\s*/)
    }
    update({ variables: { input } })
  }

  const colConfig = getColConfig(handleUpdate, options, addDeleteSearchString)

  const utilityBarNodes = [<CreateButton />]

  return (
    <DataTableContainer>
      <DeleteDialog />
      <DataTableHeader header={HEADER} />
      <EditableDataTable
        tableId={'secondary-organizations-table'}
        hasRowCount
        isLoading={isLoading}
        colConfig={colConfig}
        data={data}
        excelLabel={'Secondary Organizations'}
        utilityBarNodes={utilityBarNodes}
      />
    </DataTableContainer>
  )
}

export default SecondaryOrganizations

const useAddDeleteSearchString = () => {
  const rewriteSearchString = useRewriteSearchString()

  const addDeleteSearchString = ({ id }: any) => {
    const deleteSearchArgs = {
      deleteSecondaryOrganization: true,
      secondaryOrganizationId: id,
    }
    rewriteSearchString(deleteSearchArgs)
  }

  return addDeleteSearchString
}
