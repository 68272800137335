import * as GQL_TAGS from './gql-tags'

export const getTeamSitemapConfigs = (
  queryInput: any,
) => ({
  readConfig: {
    tag: GQL_TAGS.GET_TEAM_SITEMAPS,
    input: queryInput,
  },
})
