import React from 'react'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import { useTeam } from 'frontend/entity-hooks/ember'

import { Params } from '../../types'

const Wrapper = styled.div({
  fontWeight: 500,
  fontSize: 20,
  height: '24px',
  marginTop: 8,
})

const TeamLabel = () => {
  const {
    team,
  }: Params = useParams()

  const { data, isLoading }: any = useTeam({ queryInput: { id: team } })

  const text = isLoading ? null : data[0].name

  return (
    <Wrapper>
      {text}
    </Wrapper>
  )
}

export default TeamLabel
