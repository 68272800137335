import { CrudHookOptions } from './../../interfaces/CrudHookOptions'
import * as CRUD_UTILS from '../../api/utils'

import { getPathwaysInfluencerConfigs } from './getPathwaysInfluencerConfigs'

export const usePathwaysInfluencer = ({
  deleteOptions,
  queryInput,
}: CrudHookOptions = {}) => {
  const {
    createConfig,
    deleteConfig,
    updateConfig,
    readConfig,
    readOptionsConfig,
  } = getPathwaysInfluencerConfigs(deleteOptions, queryInput)

  const {
    data: pathwaysInfluencerOptionsData,
    loading: areOptionsLoading,
  } = CRUD_UTILS.useReadOptions(readOptionsConfig.tag)

  const options = pathwaysInfluencerOptionsData?.vegaPathwaysInfluencersOptions

  const {
    data: pathwaysInfluencerData,
    loading: isLoading,
  } = CRUD_UTILS.useRead(readConfig)
  const pathwaysInfluencersData = pathwaysInfluencerData
    ? Object.values(pathwaysInfluencerData)[0]
    : []
  const [create, { loading: isCreating }] = CRUD_UTILS.useCreate(createConfig)
  const [destroy, { loading: isDeleting }] = CRUD_UTILS.useDelete(deleteConfig)
  const [update, { loading: isUpdating }] = CRUD_UTILS.useUpdate(updateConfig)

  return {
    create,
    isCreating,
    update,
    isUpdating,
    destroy,
    isDeleting,
    data: pathwaysInfluencersData,
    isLoading,
    options: areOptionsLoading ? {} : options,
    areOptionsLoading,
  }
}
