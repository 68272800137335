import { GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS } from 'frontend/api/queries'

const updateHistoricalPathwaysPositionsOnCreate = (
  cache,
  createdPathwaysPosition
) => {
  const variables = {
    pathwaysOrganizationId: createdPathwaysPosition.pathways_organization,
    indicationId: createdPathwaysPosition.indication,
    regimenId: createdPathwaysPosition.regimen,
    populationsIds: createdPathwaysPosition.populations,
  }
  let pathwaysPositions = undefined

  try {
    const pathwaysPositionsData = cache.readQuery({
      query: GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS,
      variables,
    })
    pathwaysPositions = pathwaysPositionsData.pathwaysIndicationRegimenPositions
  } catch (e) {}

  if (!pathwaysPositions) return

  let createdPathwaysPositionIndex = 0
  while (
    createdPathwaysPositionIndex < pathwaysPositions.length &&
    new Date(createdPathwaysPosition.position_date).getTime() <
      new Date(
        pathwaysPositions[createdPathwaysPositionIndex].position_date
      ).getTime()
  )
    createdPathwaysPositionIndex++

  const newPathwaysPositions = [...pathwaysPositions]
  newPathwaysPositions.splice(
    createdPathwaysPositionIndex,
    0,
    createdPathwaysPosition
  )
  cache.writeQuery({
    query: GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS,
    data: { pathwaysIndicationRegimenPositions: newPathwaysPositions },
    variables,
  })
}

export default updateHistoricalPathwaysPositionsOnCreate
