import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { BrowserRouter } from 'react-router-dom'

export const formatData = (tableData, colConfig) => {
  const excelData = []

  tableData.forEach((datum) => {
    const formattedDatum = {}

    colConfig.forEach(({ accessor, cellValueFormatter, labelMap }) => {
      let value = datum[accessor]

      if (labelMap) {
        if (Array.isArray(value)) {
          value = value.map((value) =>
            labelMap[value] ? labelMap[value] : value
          )
        } else {
          value = labelMap[value] ? labelMap[value] : value
        }
      }

      if (cellValueFormatter) {
        value = cellValueFormatter(value)
        if (React.isValidElement(value)) {
          value = convertReactElemToText(value)
        }
      } else if (Array.isArray(value)) {
        value = value.join(', ')
      }

      formattedDatum[accessor] = value
    })

    excelData.push({ ...datum, ...formattedDatum })
  })

  return excelData
}

// From pulse design system: src/components/DataTable/utils/table-utils.tsx
const convertReactElemToText = (reactElem) => {
  // * To avoid "should not use <Link> outside a <Router>" error
  // * in the case that the React elem contains a Link element
  const wrappedReactElem = <BrowserRouter>{reactElem}</BrowserRouter>

  const htmlString = ReactDOMServer.renderToString(wrappedReactElem)
  const htmlDoc = new DOMParser().parseFromString(htmlString, 'text/html')

  return htmlDoc?.body.innerText
}
