import React from 'react'
import styled from '@emotion/styled'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import { transparentize, mix } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

import DragHandle from './DragHandle'

const Wrapper = styled.div(
  {
    alignItems: 'center',
    borderBottom: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
    borderRadius: 8,
    cursor: 'pointer',
    display: 'flex',
    fontSize: 12,
    fontWeight: 500,
    justifyContent: 'space-between',
    lineHeight: '18px',
    marginBottom: 8,
    paddingLeft: '12px',
  },
  ({ isSelected }) => ({
    backgroundColor: isSelected
      ? transparentize(0.9, Colors.PRIMARY)
      : undefined,
    color: isSelected ? Colors.PRIMARY : Colors.BLACK,
    ':hover': {
      backgroundColor: isSelected
        ? undefined
        : transparentize(0.92, Colors.BLACK),
    },
  })
)

const PhoenixSwitch = withStyles({
  switchBase: {
    color: mix(0.4, Colors.BLACK, Colors.WHITE),
    '&$checked': {
      color: Colors.GREEN,
    },
    '&$checked + $track': {
      backgroundColor: Colors.GREEN,
    },
  },
  checked: {},
  track: {
    backgroundColor: transparentize(0.7, Colors.BLACK),
  },
})(Switch)

export const ListItem = ({
  checked,
  label,
  resource,
  selectedEntity,
  selectResource,
  toggleResource,
  type,
}) => {
  const { resource: resourceId } = resource
  const isSelected = resourceId === selectedEntity

  return (
    <Wrapper
      isSelected={isSelected}
      onClick={() => {
        if (!isSelected) selectResource?.(resourceId)
      }}
    >
      <div>
        <DragHandle type={type} />
        {label}
      </div>
      <PhoenixSwitch
        checked={checked}
        onChange={() => toggleResource(resource)}
      />
    </Wrapper>
  )
}
