import React from 'react'
import { Link, useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { transparentize } from 'polished'
import queryString from 'query-string'

import { useQueryString } from 'frontend/hooks'
import { Colors, Spacing } from 'frontend/utils/pulseStyles'

const StyledLink = styled(Link)({
  background: transparentize(0.85, Colors.PRIMARY),
  borderRadius: 4,
  color: Colors.PRIMARY,
  fontSize: 12,
  fontWeight: 700,
  padding: `${Spacing.SMALL}`,
  textDecoration: 'none',
  ':hover': {
    background: transparentize(0.75, Colors.PRIMARY),
  },
})

const CancelButton = () => {
  const { client, team } = useParams()

  const { user } = useQueryString()

  const formattedQueryString = queryString.stringify({
    client,
    team,
    user,
  })

  return (
    <StyledLink to={`/client-management?${formattedQueryString}`}>
      Return to Clients/Teams View
    </StyledLink>
  )
}

export default CancelButton
