import React from 'react'
import _ from 'lodash'

import RegimensPanel from './RegimensPanel'

const RegimensPanelContainer = ({
  selectedIndicationId,
  selectRegimen,
  baseTreatmentPlans,
  setStagedTreatmentPlans,
  enabledTreatmentPlansHash,
  enabledTreatmentPlans,
  selectedRegimenId,
}) => {
  const copyInd = _.cloneDeep(
    baseTreatmentPlans.find(({ _id }) => _id === selectedIndicationId) || {
      regimens: [],
    }
  )

  const enabledRegimensHash = enabledTreatmentPlansHash[copyInd._id]

  const targetInd = enabledTreatmentPlans.find(({ _id }) => _id === copyInd._id)
  const isSelectedIndEnabled = Boolean(targetInd)

  const enableRegimen = (regObj) => {
    const copyReg = _.cloneDeep(regObj)
    selectRegimen(copyReg)

    const { _id, name } = copyReg
    const regimen = { _id, name, populations: [] }
    if (!isSelectedIndEnabled) {
      // replace all base regimens with just the one that was toggled on
      copyInd.regimens = [regimen]
      enabledTreatmentPlans = enabledTreatmentPlans.concat([
        _.cloneDeep(copyInd),
      ])
    } else {
      targetInd.regimens = targetInd.regimens.concat([regimen])
    }
    setStagedTreatmentPlans(enabledTreatmentPlans)
  }

  const disableRegimen = (regObj) => {
    const targetRegIdx = targetInd.regimens.findIndex(
      ({ _id }) => _id === regObj._id
    )

    targetInd.regimens.splice(targetRegIdx, 1)

    setStagedTreatmentPlans(enabledTreatmentPlans)
  }

  let enabledRegimens = []
  // targetInd.regimens has already been ordered appropriately
  if (isSelectedIndEnabled) enabledRegimens = targetInd.regimens

  const disabledRegimens = copyInd.regimens.filter(
    ({ _id }) => !enabledRegimensHash || !enabledRegimensHash[_id]
  )

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const [removedItem] = enabledRegimens.splice(oldIndex, 1)
    enabledRegimens.splice(newIndex, 0, removedItem)

    targetInd.regimens = enabledRegimens
    setStagedTreatmentPlans(enabledTreatmentPlans)
  }

  return (
    <RegimensPanel
      selectRegimen={selectRegimen}
      selectedRegimenId={selectedRegimenId}
      selectedIndicationName={copyInd.name}
      enabledRegimens={enabledRegimens}
      disabledRegimens={disabledRegimens}
      enableRegimen={enableRegimen}
      disableRegimen={disableRegimen}
      onSortEnd={onSortEnd}
    />
  )
}

export default RegimensPanelContainer
