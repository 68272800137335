import { gql } from '@apollo/client'
import { schemaV1 } from './schemas'

export const GET_PAYER_POLICY_LINKS = gql`
  query GetPayerPolicyLinks($input: PayerPolicyLinksInput) {
    payerPolicyLinks(input: $input) {
      ${schemaV1}
    }
  }
`
