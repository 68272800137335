import React from 'react'
import { CopyButton } from '../CopyButton'

interface LastApprovedPolicyProps {
  lastApprovedDate: string
  policyDocLink: string
}

const FONT_STYLES = {
  fontSize: 12,
  lineHeight: '18px',
  letterSpacing: -0.1,
  fontWeight: 500,
  padding: '0 12px 0 6px',
}

export const LastApprovedPolicy = ({
  lastApprovedDate,
  policyDocLink,
}: LastApprovedPolicyProps) => {
  return (
    <section style={{ padding: '5px 75px 5px 20px' }}>
      <span style={FONT_STYLES}>Last Approved: {lastApprovedDate}</span>
      <CopyButton text={policyDocLink} />
    </section>
  )
}
