import React, { MutableRefObject, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { useClearSearchStringParam, useShouldShowModal } from 'frontend/hooks'
import { useProviderKeyDecisionMakers } from 'frontend/api'
import { getGraphQLErrorMessage } from 'frontend/utils'

import { formatUpdateInput } from '../utils'

import { StartEndDateModal } from './StartEndDateModal'
import { getFormConfig } from './getFormConfig'
import { usePerson } from 'frontend/entity-hooks'

interface StartEndDateModalContainerPropTypes {
  setErrorMessage: (arg: any) => void
  providersResponse: any
}

const UPDATE_PROVIDER_KDM = 'updateProviderKdm'
const searchStringParams = [UPDATE_PROVIDER_KDM, 'id', 'person']

export const StartEndDateModalContainer = ({
  setErrorMessage,
  providersResponse,
}: StartEndDateModalContainerPropTypes) => {
  const location = useLocation()
  const inputRef: MutableRefObject<any> = useRef({})
  const buttonRef = useRef<HTMLButtonElement>(null)
  const shouldShowModal = useShouldShowModal()
  const clearSearchStringParam = useClearSearchStringParam()

  const queryParams = new URLSearchParams(location.search)
  const entityId = queryParams.get('id') ?? undefined
  const person = queryParams.get('person') ?? undefined

  const {
    data: providerKeyDecisionMaker,
    isLoading: isProviderKdmLoading,
    update,
  } = useProviderKeyDecisionMakers({
    queryInput: { id: entityId },
  })

  const { data: personData, isLoading: isPersonLoading } = usePerson({
    queryInput: { id: person },
  })

  const isLoading =
    providersResponse.loading ||
    isProviderKdmLoading ||
    isPersonLoading ||
    providerKeyDecisionMaker.id !== entityId

  const formattedData = formatUpdateInput(
    providerKeyDecisionMaker,
    personData,
    providersResponse.options,
    isLoading
  )

  useEffect(() => {
    inputRef.current = formattedData
  }, [formattedData])

  if (!shouldShowModal(UPDATE_PROVIDER_KDM)) return null

  const setData = ({ name, value }: any) => {
    inputRef.current[name] = value
  }

  const handleCancel = () => {
    inputRef.current = {}
    clearSearchStringParam(searchStringParams)
  }

  const handleSubmit = async () => {
    const onUpdateError = (e: any) => {
      const errorMessage = getGraphQLErrorMessage(e)
      if (errorMessage === undefined) {
        alert(e)
      } else {
        setErrorMessage(errorMessage)
      }
    }

    const formattedInput = {
      start_date: inputRef.current.start_date,
      end_date: inputRef.current.end_date,
      id: inputRef.current.id,
    }
    await update({
      variables: { input: formattedInput },
      onError: onUpdateError,
      onCompleted: handleCancel,
    })
  }

  const formConfig = getFormConfig()

  return (
    <StartEndDateModal
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      data={formattedData}
      setData={setData}
      buttonRef={buttonRef}
      formConfig={formConfig}
      isLoading={isLoading}
    />
  )
}
