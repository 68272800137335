import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

interface Props {
  isHoveredListItem: boolean
  isSelected: boolean
  clientSource: string
}

const CLIENT_SOURCE_MAP: any = {
  dedham: 'Dedham',
  dedham_demo: 'Demo (Dedham)',
  mmit: 'MMIT',
  mmit_demo: 'Demo (MMIT)',
  pulse: 'Pulse',
}

const Tag = styled.span({
  background: '#E2EAF4',
  color: Colors.GRAY_600,
  fontSize: 11,
  fontWeight: 600,
  marginRight: 6,
  padding: '0 8px',
  borderRadius: 2,
})

const activeStyle = {
  color: Colors.PRIMARY,
  background: '#CCDCF4',
}

export const ClientSourceTag = ({
  isHoveredListItem,
  isSelected,
  clientSource,
}: Props) => {
  const style = isSelected || isHoveredListItem ? activeStyle : {}
  if (!clientSource) return null

  return <Tag style={style}>{CLIENT_SOURCE_MAP[clientSource]}</Tag>
}
