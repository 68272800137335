import React from 'react'
import styled from '@emotion/styled'

interface Props {
  bodyText: string
}

const Wrapper = styled.div({
  display: 'flex',
  marginBottom: 25,
})

const Text = styled.p({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 1.6,
})

export const Body = ({ bodyText }: Props) => {
  return (
    <Wrapper>
      <Text>{bodyText}</Text>
    </Wrapper>
  )
}
