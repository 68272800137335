import { CrudHookOptions } from './../../interfaces/CrudHookOptions'
import * as CRUD_UTILS from '../../api/utils'
import { getSecondaryOrganizationPersonConfigs } from './getSecondaryOrganizationPersonConfigs'

export const useSecondaryOrganizationPerson = ({
  deleteOptions,
  queryInput,
}: CrudHookOptions = {}) => {
  const {
    createConfig,
    deleteConfig,
    updateConfig,
    readConfig,
  } = getSecondaryOrganizationPersonConfigs(deleteOptions, queryInput)

  const { data, loading: isLoading } = CRUD_UTILS.useRead(readConfig)
  const [create, { loading: isCreating }] = CRUD_UTILS.useCreate(createConfig)
  const [destroy, { loading: isDeleting }] = CRUD_UTILS.useDelete(deleteConfig)
  const [update, { loading: isUpdating }] = CRUD_UTILS.useUpdate(updateConfig)

  const secondaryOrganizationsPeopleData = data ? Object.values(data)[0] : []

  return {
    create,
    isCreating,
    update,
    isUpdating,
    destroy,
    isDeleting,
    data: secondaryOrganizationsPeopleData,
    isLoading,
  }
}
