import React from 'react'
import styled from '@emotion/styled'
import { useQuery } from '@apollo/client'

import { Colors } from 'frontend/utils/pulseStyles'
import { Select } from 'frontend/components'
import { GET_VEGA_PRODUCTS } from 'frontend/api/queries'

import { formatOptions } from '../../utils'

import { selectStyles } from './selectStyles'

interface Props {
  onChange: any
}

const TITLE_TEXT = 'Products'
const SELECT_PLACEHOLDER = 'Product(s)'
const FIELD_NAME = 'products'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  borderRadius: 6,
  backgroundColor: Colors.WHITE,
  padding: 24,
  height: 125,
  width: 464,
})

const Title = styled.h1({
  fontSize: 16,
  fontWeight: 500,
  marginBottom: 24,
})

export const Products = ({ onChange }: Props) => {
  const { data, loading: isLoading } = useQuery(GET_VEGA_PRODUCTS)
  const handleChange = (products: any[]) => {
    onChange({
      name: FIELD_NAME,
      value: products,
    })
  }

  const products = data ? data.vegaProducts : []
  const productOptions = formatOptions(products, isLoading, 'brand_name')

  return (
    <Wrapper>
      <Title>{TITLE_TEXT}</Title>
      <Select
        isMulti
        options={productOptions}
        styles={selectStyles}
        placeholder={SELECT_PLACEHOLDER}
        onChange={handleChange}
      />
    </Wrapper>
  )
}
