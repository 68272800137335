import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { useIndications, useNccnPanels, usePeople } from 'frontend/api'

import { useRewriteSearchString } from 'frontend/hooks'

import { UseNccnPanelsOptions } from 'frontend/interfaces'

interface UseNccnPanelDataOptions {
  searchStringParams?: string[]
}

export const useNccnPanelsData = ({
  searchStringParams,
}: UseNccnPanelDataOptions = {}) => {
  const useNccnPanelsOptions = useOptions(searchStringParams)

  const nccnPanels = useNccnPanels(useNccnPanelsOptions)
  const indications = useIndications()

  const isLoading = nccnPanels.isLoading || indications.isLoading

  return {
    isLoading,
    nccnPanels,
    indications,
  }
}

const useOptions = (searchStringParams?: string[]): UseNccnPanelsOptions => {
  const { search } = useLocation()
  const rewriteSearchString = useRewriteSearchString()

  let deleteOptions
  if (searchStringParams) {
    const deleteSearchArgs = searchStringParams.reduce(
      (acc: any, param: string) => {
        acc[param] = undefined
        return acc
      },
      {}
    )
    const onCompleted = () => rewriteSearchString(deleteSearchArgs)
    deleteOptions = { onCompleted }
  }

  let queryInput
  const { nccnPanelId } = queryString.parse(search)
  if (nccnPanelId && !Array.isArray(nccnPanelId))
    queryInput = { id: nccnPanelId }

  return { deleteOptions, queryInput }
}
