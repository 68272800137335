import XLSX from 'xlsx'

const onFileAdded = ({ ref, sheetName, setSheetsData, sheetsData }) => {
  const file = ref.current.files[0]

  const reader = new FileReader()

  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result)
    const nextWorkbook = XLSX.read(data, { cellDates: true, type: 'array' })

    const selectedSheetObj = nextWorkbook.Sheets[sheetName]
    const json = XLSX.utils.sheet_to_json(selectedSheetObj, {
      blankrows: true,
      defval: null,
    })

    setSheetsData({
      ...sheetsData,
      [sheetName]: json,
    })
  }

  reader.readAsArrayBuffer(file)
}

export default onFileAdded
