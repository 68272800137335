import { useEffect } from 'react'

import { usePerson, useSecondaryOrganizations, useSecondaryOrganizationPerson } from 'frontend/entity-hooks'
import { useQueryStringParams } from './useQueryStringParams'
import { getFormData } from './getFormData'

export const useFormData = ({ inputRef }: any) => {
  const { entityId, personId, secondaryOrganizationId } = useQueryStringParams()

  const secondaryOrganizationConnection = useSecondaryOrganizationPerson({
    queryInput: { id: entityId },
  })
  const person = usePerson({ queryInput: { id: personId } })
  const secondaryOrganization = useSecondaryOrganizations({ queryInput: { id: secondaryOrganizationId } })

  const isLoading =
    secondaryOrganizationConnection.isLoading || person.isLoading || secondaryOrganization.isLoading
  
  const data = getFormData({
    isLoading,
    person,
    secondaryOrganization,
    secondaryOrganizationConnection,
  })

  useEffect(() => {
    if (!isLoading) {
      inputRef.current.id = entityId
      inputRef.current.start_date = data.start_date
      inputRef.current.end_date = data.end_date
    }
  }, [isLoading, data, entityId])

  return {
    data,
    isLoading,
  }
}
