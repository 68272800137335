import { useQuery } from "@apollo/client"

import { MetaHistoryQueryInput } from "frontend/interfaces"

import tag from './gql-tag'

const useRead = (queryInput?: MetaHistoryQueryInput) => useQuery(
  tag,
  {
    variables: { input: queryInput },
  }
)

export default useRead
