import React from 'react'
import Header from './Header'
import Panels from './Panels'
import EditRoleNodeModal from './EditRoleNodeModal'

const Sitemap = () => {
  return (
    <div style={{ flex: 1 }}>
      <EditRoleNodeModal />
      <Header />
      <Panels />
    </div>
  )
}

export default Sitemap
