// * product-payer-policy-links/
const PRODUCT = 'product'
const LAST_APPROVED_REVIEW_DATE = 'last_policy_review_date'
const LAST_TDG_REVIEW_DATE = 'last_tdg_review_date'
const LAST_IDENTIFIED_CHANGE_DATE = 'last_identified_change_date'
const ASSIGNED_POLARIS_USER_AUTH_IDS = 'assigned_polaris_user_auth0_ids'
const LINK_STEPS = 'link_steps'
const POLICY_SEARCH_WORD = 'policy_search_word'
const LINK_NOTE = 'link_note'
const APPROVAL_STATUS = 'approval_status'
const PRODUCT_PAYER_POLICY_TRACKING_PROJECTS =
  'product_payer_policy_tracking_projects'

// * payer-policy-links/
const PAYER = 'payer'
const BOOK = 'book'
const BENEFIT_TYPE = 'benefit_type'
const DOC_TYPE = 'doc_type'
const LINK_TYPE = 'link_type'
const LINK = 'link'
const LINK_STATUS = 'link_status'

// * approval status values
const NOT_APPLICABLE = 'not_applicable'
const NEEDS_APPROVAL = 'needs_approval'
const APPROVED = 'approved'
const INCORRECT_POLICY = 'incorrect_policy'
const NO_POLICY_CHANGE = 'no_policy_change'

// * link status values
const NOT_TRACKED = 'not_tracked'

// * link status labels
const BROKEN_PULSE = 'Broken - Pulse'
const BROKEN_TDG = 'Broken - TDG'
const GOOD = 'Good'
const PENDING = 'Pending'
const WATCHED = 'Watched'
const LINK_STATUS_ALERT_LABELS = new Set([BROKEN_PULSE, BROKEN_TDG])

export {
  BROKEN_PULSE,
  BROKEN_TDG,
  PAYER,
  PRODUCT,
  BOOK,
  BENEFIT_TYPE,
  DOC_TYPE,
  LINK_TYPE,
  LINK,
  LINK_STEPS,
  POLICY_SEARCH_WORD,
  LINK_NOTE,
  LINK_STATUS,
  LINK_STATUS_ALERT_LABELS,
  APPROVAL_STATUS,
  LAST_APPROVED_REVIEW_DATE,
  LAST_TDG_REVIEW_DATE,
  LAST_IDENTIFIED_CHANGE_DATE,
  ASSIGNED_POLARIS_USER_AUTH_IDS,
  PRODUCT_PAYER_POLICY_TRACKING_PROJECTS,
  NOT_APPLICABLE,
  NEEDS_APPROVAL,
  APPROVED,
  INCORRECT_POLICY,
  NO_POLICY_CHANGE,
  NOT_TRACKED,
  GOOD,
  PENDING,
  WATCHED,
}
