import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import queryString from 'query-string'

import Form from './Form'
import { UpdateFormLabel } from '../shared/styledComponents'
import { Colors } from 'frontend/utils/pulseStyles'

import { GET_LISTS_CONFIG } from 'frontend/api/queries'
import { UPDATE_LISTS_CONFIG } from 'frontend/api/mutations'
import { NEW_LIST_ITEM, updateListItems } from '../utils'

const UPDATE_LIST_ITEM_TITLE = 'Update List Item'

const getListItemKey = (listItemObj) => {
  return listItemObj ? { listItemKey: listItemObj.labelKey } : {}
}

const UpdateListItemPanel = () => {
  const history = useHistory()
  const location = useLocation()

  const {
    toolId: selectedDashboardTool,
    listsConfigId: selectedListsConfigId,
    listItemKey,
  } = (location.search && queryString.parse(location.search)) || {}

  const selectedListItemKey = listItemKey

  const { data, loading } = useQuery(GET_LISTS_CONFIG, {
    variables: { toolId: selectedDashboardTool },
  })

  const handleClick = (listItemObj) => {
    const prevQueryParams = queryString.parse(location.search)
    const nextParams = {
      ...prevQueryParams,
      ...getListItemKey(listItemObj),
    }

    history.push({
      search: queryString.stringify(nextParams),
    })
  }

  const [updateListItemsMutation] = useMutation(UPDATE_LISTS_CONFIG, {
    onError: alert,
  })

  const selectedList =
    !loading &&
    data.listsConfig.find(({ _id }) => _id === selectedListsConfigId)

  const selectedListItems = (selectedList && selectedList.labelKeys) || []

  const selectedListItem =
    selectedListItemKey === NEW_LIST_ITEM.labelKey
      ? NEW_LIST_ITEM
      : selectedListItems.find(
          ({ labelKey }) => labelKey === selectedListItemKey
        )

  const existingLabelKeys = selectedListItems.map(({ labelKey }) => labelKey)
  const invalidLabelKeys = [...existingLabelKeys, NEW_LIST_ITEM.labelKey, '']

  const deleteListItem = (labelKeyToFilter) => {
    let newListItems = selectedListItems.map(({ __typename, ...rest }) => rest)

    newListItems = newListItems.filter(
      (listItem) => listItem.labelKey !== labelKeyToFilter
    )

    const input = {
      _id: selectedListsConfigId,
      labelKeys: newListItems,
    }

    updateListItemsMutation({ variables: { input } })

    const nextListItemSelection = newListItems.find(
      ({ labelKey }) => labelKey !== labelKeyToFilter
    )

    if (nextListItemSelection) {
      handleClick(nextListItemSelection)
    } else {
      // * handle edge case where last item is deleted 🎐
      handleClick({ listItemKey: null })
    }
  }

  const updateListItemsPanel = updateListItems(
    selectedListItems,
    selectedListItemKey,
    selectedListsConfigId,
    updateListItemsMutation,
    handleClick
  )

  const panelHeight = 'calc(100vh - 40px)'

  const panelStyle = {
    height: panelHeight,
    minHeight: panelHeight,
    maxHeight: panelHeight,
  }

  return (
    <div style={{ flex: 1, background: Colors.COOL_GRAY_100, ...panelStyle }}>
      {selectedListItemKey ? (
        <>
          <UpdateFormLabel>{UPDATE_LIST_ITEM_TITLE}</UpdateFormLabel>
          <Form
            selectedListItemKey={selectedListItemKey}
            data={selectedListItem}
            invalidLabelKeys={invalidLabelKeys}
            mutationFunc={updateListItemsPanel}
            deleteListItem={deleteListItem}
          />
        </>
      ) : (
        <div style={{ padding: 24 }}>Click "+" to add list item details</div>
      )}
    </div>
  )
}

export default UpdateListItemPanel
