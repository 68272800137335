import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const DELETE_SECONDARY_ORGANIZATION_PERSON = gql`
  mutation DeleteSecondaryOrganizationPerson($input: DeleteSecondaryOrganizationPersonInput!) {
    deleteSecondaryOrganizationPerson(input: $input) {
      ${schemaV0}
    }
  }
`
