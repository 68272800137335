import React, { Dispatch, SetStateAction } from 'react'
import { useQuery } from '@apollo/client'

import { GET_SOURCE_INDICATIONS } from 'frontend/api/queries'
import { Spinner } from 'frontend/components'

import IndicationsListPanel from './IndicationsListPanel'

interface Props {
  areParentResourcesLoading: boolean
  parentResources: any[]
  setResources: Dispatch<SetStateAction<any>>
  treatmentPlans?: any[]
}

const Container = ({
  areParentResourcesLoading,
  parentResources,
  treatmentPlans,
  setResources,
}: Props) => {
  const { loading: areTreatmentPlansLoading } = useQuery(GET_SOURCE_INDICATIONS)

  if (areParentResourcesLoading || areTreatmentPlansLoading || !treatmentPlans) return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '75%',
        justifyContent: 'center',
      }}
    >
      <Spinner />
    </div>
  )

  return (
    <IndicationsListPanel
      parentResources={parentResources}
      treatmentPlans={treatmentPlans}
      setResources={setResources}
    />
  )
}

export default Container
