export const schemaV0 = `
  id
  name
  component_path
  order
  tdg_timestamp
  text
  has_resources
  permitted_entity_types
  tool
  dashboard
  page
  created_at
  updated_at
`
