import React, { useState } from 'react'
import { transparentize } from 'polished'

import { useRewriteSearchString } from 'frontend/hooks'
import { Colors } from 'frontend/utils/pulseStyles'

import { Wrapper } from './Wrapper'
import { Item } from './Item'

interface ListItemProps {
  data: any
  isSelected: boolean
}

export const ACTIVE_ITEM_STYLE = {
  background: transparentize(0.9, Colors.BLUE),
  fontWeight: 600,
}

export const ListItem = ({ data, isSelected }: ListItemProps) => {
  const [isHoveredListItem, setIsHoveredListItem] = useState(false)
  const rewriteSearchString = useRewriteSearchString()

  const rewriteClientParam = () => {
    if (!isSelected) {
      rewriteSearchString({
        team: data.id,
        user: undefined,
      })
    }
  }

  const wrapperStyle = isSelected ? ACTIVE_ITEM_STYLE : {}

  return (
    <Wrapper
      onClick={rewriteClientParam}
      onMouseEnter={() => setIsHoveredListItem(true)}
      onMouseLeave={() => setIsHoveredListItem(false)}
      style={wrapperStyle}
    >
      <Item
        isHoveredListItem={isHoveredListItem}
        isSelected={isSelected}
        data={data}
      />
    </Wrapper>
  )
}
