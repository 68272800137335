import React, { Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'

import { Sitemap, Type } from '../../../../../types'
import EditButton from './EditButton'
import SitemapSwitch from './SitemapSwitch'

interface Props {
  type: Type
  data: any
  sitemap: Sitemap
  setSitemap: Dispatch<SetStateAction<Sitemap | undefined>>
}

const Wrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
})

const ButtonGroup = (props: Props) => {
  return (
    <Wrapper>
      <SitemapSwitch
        {...props}
      />
      <EditButton
        {...props}
      />
    </Wrapper>
  )
}

export default ButtonGroup
