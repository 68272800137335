import React from 'react'
import _ from 'lodash'

import MetaDataList from 'frontend/components/MetaHistory/MetaDataList'
import { usePeopleHistory } from 'frontend/api/usePeople'

import { KEY_TO_LABEL } from './key-label-map'
import Spinner from 'frontend/components/Spinner'
import useMongoVegaBridge from 'frontend/hooks/useMongoVegaBridge'

const PeopleHistoryList = ({ id }: { id: string }) => {
  const {
    data: metaHistory,
    isLoading: isHistoryLoading,
  } = usePeopleHistory({ id })

  if (!isHistoryLoading && _.isEmpty(metaHistory)){
    return <NoHistoryMessage />
  } else if (isHistoryLoading) {
    return <Spinner />
  }

  return (
    <div style={{ width: '100%' }}>
      <MetaDataList
        data={metaHistory}
        keyToLabel={KEY_TO_LABEL}
      />
    </div>
  )
}

const PeopleHistoryListContainer = ({ entity }: { entity: any }) => {
  const { data } = useMongoVegaBridge({ mongoId: entity._id })
  if (_.isEmpty(data)) return <Spinner />
  return (
    <PeopleHistoryList id={data[0].vegaId} />
  )
}

export default PeopleHistoryListContainer


const NoHistoryMessage = () => {
  return (
    <div style={{ padding: 24 }}>
      No history on record.
    </div>
  )
}

