import gql from 'graphql-tag'
import { schemaV0 } from './schemas'

export const CREATE_ALTERNATIVE_PAYMENT_MODEL_ORGANIZATION = gql`
  mutation CreateAlternativePaymentModelOrganization(
    $input: CreateAlternativePaymentModelOrganizationInput!
  ) {
    createAlternativePaymentModelOrganization(input: $input) {
      ${schemaV0}
    }
  }
`
