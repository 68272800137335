import { formatMonthDayYearSlash } from 'frontend/utils/formatDate'

const getExcelInjectables = ({ indicationLabelMap }) => [
  {
    Header: 'Person ID',
    accessor: 'person',
  },
  {
    Header: 'Pathways Slug',
    accessor: 'organization_slug',
  },
  {
    Header: 'First Name',
    accessor: 'personFirstName',
  },
  {
    Header: 'Last Name',
    accessor: 'personLastName',
  },
  {
    Header: 'Pathways Organization',
    accessor: 'pathwaysName',
  },
  {
    Header: 'Pathways Role',
    accessor: 'position',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Primary Affiliation',
    accessor: 'personPrimaryAffiliation',
  },
  {
    Header: 'Management Type',
    accessor: 'internal_pathways_management_types',
  },
  {
    Header: 'Influencer Type',
    accessor: 'pathways_influencer_types',
  },
  {
    Header: 'Internal TDG Notes',
    accessor: 'internal_tdg_note',
  },
  {
    Header: 'ClinicalPath / Value Chairs Indication(s) (Internal TDG Only)',
    accessor: 'value_chair_indications',
  },
  {
    Header: 'Tumor Type Specialty',
    accessor: 'tumor_type_specialty',
  },
  {
    Header: 'Priority',
    accessor: 'priority',
  },
  {
    Header: 'Alert Date',
    accessor: 'alert_date',
    cellValueFormatter: formatMonthDayYearSlash,
  },
  {
    Header: 'Alert Type',
    accessor: 'alert_type',
  },
  {
    Header: 'Alert Description',
    accessor: 'alert_description',
  },
  {
    Header: 'Excluded From Tool',
    accessor: 'is_excluded',
    cellValueFormatter: (value) => String(value),
  },
  {
    Header: 'Excluded Reason',
    accessor: 'exclusion_reason',
  },
  {
    Header: 'Start Date',
    accessor: 'start_date',
    cellValueFormatter: formatMonthDayYearSlash,
  },
  {
    Header: 'Start Quarter',
    accessor: 'start_quarter',
    cellValueFormatter: formatMonthDayYearSlash,
  },
  {
    Header: 'End Date',
    accessor: 'end_date',
    cellValueFormatter: formatMonthDayYearSlash,
  },
  {
    Header: 'End Quarter',
    accessor: 'end_quarter',
    cellValueFormatter: formatMonthDayYearSlash,
  },
  {
    Header: 'Indications (for permissions)',
    accessor: 'indication_permissions', // injected in custom hook from connection 'indicationIds' field
    labelMap: indicationLabelMap,
  },
  {
    Header: 'Date Updated',
    accessor: 'updated_at',
    cellValueFormatter: formatMonthDayYearSlash,
  },
]

export default getExcelInjectables
