import React from 'react'
import Select from 'react-select'

import { getClientOptions } from './utils'
import {
  useRewriteSearchString,
  useClearSearchStringParam,
} from 'frontend/hooks'

export const ClientSelect = ({
  clients,
  selectedClient,
  setSelectedClient,
  setSelectedTeam,
}: any) => {
  const clientOptions = getClientOptions({ clients })
  const rewriteSearchString = useRewriteSearchString()
  const clearSearchStringParams = useClearSearchStringParam()

  const onChange = (selectedOption: any) => {
    const selectedClient = clients.find(
      (client: any) => client.id === selectedOption.value.id
    )
    clearSearchStringParams(['team'])
    rewriteSearchString({ client: selectedClient.id })
    setSelectedClient(selectedClient)
    setSelectedTeam(null)
  }

  return (
    <div style={{ paddingBottom: '24px' }}>
      <div style={{ marginBottom: 24 }}>1. Choose a Client: </div>
      <Select
        value={
          selectedClient
            ? { value: selectedClient.id, label: selectedClient.name }
            : null
        }
        options={clientOptions}
        onChange={onChange}
      />
    </div>
  )
}
