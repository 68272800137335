import styled from '@emotion/styled'

const Img = styled.img({
  width: '25px',
  height: '25px',
  marginRight: '6px',
})

export const Icon = ({ iconLink }) => {
  if (!iconLink) return null
  return <Img src={iconLink} />
}
