import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import _ from 'lodash'

import { GET_MANUFACTURERS } from 'frontend/api/queries'
import { CREATE_MANUFACTURER } from 'frontend/api/mutations'

import { SingleActionDialog } from 'frontend/components/Dialog'
import Spinner from 'frontend/components/Spinner'
import Input from 'frontend/components/Input'

import Spacing from 'frontend/utils/spacing'

import {
  InputSection,
  FormLabel,
} from '../MarketBaskets/MarketBasketDetail/Surveys/SurveyView/SurveyForms/utils'

const CreateManufacturerForm = ({ closeHandler }) => {
  const [manufacturerName, setManufacturerName] = useState('')

  const [createManufacturer, { loading: mutationLoading }] = useMutation(
    CREATE_MANUFACTURER,
    {
      variables: {
        input: {
          name: manufacturerName,
        },
      },
      update: (cache, { data: { createManufacturer } }) => {
        const { manufacturers } = cache.readQuery({
          query: GET_MANUFACTURERS,
        })
        let newManufacturers = [...manufacturers, createManufacturer]
        newManufacturers = _.sortBy(newManufacturers, ({ name }) =>
          name.toLowerCase()
        )
        cache.writeQuery({
          query: GET_MANUFACTURERS,
          data: { manufacturers: newManufacturers },
        })
      },
      onError: alert,
      onCompleted: () => {
        closeHandler()
      },
    }
  )

  const onTextChange = ({ value }) => {
    setManufacturerName(value)
  }

  const isLoading = mutationLoading

  return (
    <SingleActionDialog
      header={'Create Manufacturer'}
      submitText="Create Manufacturer"
      submitHandler={createManufacturer}
      cancelHandler={closeHandler}
    >
      <div style={{ padding: `${Spacing.S4} ${Spacing.S7} ${Spacing.S7}` }}>
        {isLoading ? (
          <Spinner />
        ) : (
          <div>
            <form>
              <InputSection>
                <FormLabel>Name (required)</FormLabel>
                <Input
                  name="name"
                  type="text"
                  value={manufacturerName}
                  onChange={onTextChange}
                />
              </InputSection>
            </form>
          </div>
        )}
      </div>
    </SingleActionDialog>
  )
}

CreateManufacturerForm.propTypes = {
  closeHandler: PropTypes.func.isRequired,
}

export default CreateManufacturerForm
