import gql from 'graphql-tag'

export const CREATE_PROVIDER = gql`
  mutation CreateProvider(
    $input: CreateProviderInput!
  ) {
    createProvider(input: $input) {
      _id
      slug
      type
      organization
      organizationTiny
      providerCancerCenter
      state
      city
      oncologistsCount
      sitesCount
      connections
      groupPracticePacId
      aliases
      isFlaggedForDataExtraction
    }
  }
`

export const UPDATE_PROVIDER = gql`
  mutation UpdateProvider(
    $input: UpdateProviderInput!
  ) {
    updateProvider(input: $input) {
      _id
      slug
      type
      organization
      organizationTiny
      providerCancerCenter
      state
      city
      oncologistsCount
      sitesCount
      connections
      groupPracticePacId
      aliases
      isFlaggedForDataExtraction
    }
  }
`

export const DELETE_PROVIDER = gql`
  mutation DeleteProvider(
    $input: DeleteProviderInput!
  ) {
    deleteProvider(input: $input) {
      _id
      slug
      type
      organization
      organizationTiny
      providerCancerCenter
      sitesCount
      state
      city
      oncologistsCount
      sitesCount
      connections
      groupPracticePacId
      aliases
      isFlaggedForDataExtraction
    }
  }
`
