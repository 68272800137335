import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'

import { SingleActionDialog } from 'frontend/components/Dialog'
import Spinner from 'frontend/components/Spinner'
import {
  Label,
  LabelText,
  SectionTitle,
} from 'frontend/Orion/shared/AccountModals/AccountModalButton/styledAccountModalButtonComponents'
import Input from 'frontend/components/Input'

import { CREATE_PAYER_ORGANIZATION } from 'frontend/api/mutations'
import { GET_PAYER_ORGANIZATIONS } from 'frontend/api/queries'

import getIndexByBinarySearch from 'frontend/utils/getIndexByBinarySearch'

const wrapperStyle = {
  padding: '30px 30px',
}

const PAGE_HEADER = 'Create Payer Account'

const addNewPayerAccountToCache = (
  cache,
  { data: { createPayerOrganization } }
) => {
  const { payerOrganizations } = cache.readQuery({
    query: GET_PAYER_ORGANIZATIONS,
  })
  const newAccountIndex = getIndexByBinarySearch({
    data: payerOrganizations,
    newDatum: createPayerOrganization,
    accessor: 'organization',
  })
  const newPayerAccounts = [...payerOrganizations]
  newPayerAccounts.splice(newAccountIndex, 0, createPayerOrganization)
  cache.writeQuery({
    query: GET_PAYER_ORGANIZATIONS,
    data: { payerOrganizations: newPayerAccounts },
  })
}

const CreatePayerAccountForm = ({ closeHandler }) => {
  const [form, setForm] = useState({})

  const handleChange = ({ name, value }) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const [createPayerAccount, { loading: isPayerAccountLoading }] = useMutation(
    CREATE_PAYER_ORGANIZATION,
    {
      variables: { input: form },
      update: addNewPayerAccountToCache,
      onError: alert,
      onCompleted: () => {
        closeHandler()
      },
    }
  )

  return (
    <SingleActionDialog
      header={PAGE_HEADER}
      submitText={PAGE_HEADER}
      submitHandler={createPayerAccount}
      cancelHandler={closeHandler}
    >
      {isPayerAccountLoading ? (
        <Spinner />
      ) : (
        <div style={wrapperStyle}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SectionTitle>Payer Account Info</SectionTitle>
          </div>

          <Label>
            <LabelText>Name</LabelText>

            <Input
              name="organization"
              type="text"
              onChange={handleChange}
              value={form.organization}
            />
          </Label>
          <Label>
            <LabelText>Short Name</LabelText>
            <Input
              name="organizationTiny"
              type="text"
              onChange={handleChange}
              value={form.organizationTiny}
            />
          </Label>
          <Label>
            <LabelText> Slug </LabelText>
            <Input
              name="slug"
              type="text"
              onChange={handleChange}
              value={form.slug}
            />
          </Label>
        </div>
      )}
    </SingleActionDialog>
  )
}

CreatePayerAccountForm.propTypes = {
  closeHandler: PropTypes.func.isRequired,
}

export default CreatePayerAccountForm
