import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Button } from '@pulse-analytics/pulse-design-system'

import Icon from 'frontend/components/Icon'
import Color from 'frontend/utils/color'
import Spacing from 'frontend/utils/spacing'

const BUTTON_TEXT = 'Import PDF'

const ImportButton = ({ onClick }) => {
  const FLAGS = useFlags()

  if (!FLAGS.shouldShowManuallyImportPayerPolicy) return null

  return (
    <Button
      type="secondary"
      style={{
        padding: Spacing.S2,
        margin: Spacing.S2,
      }}
      onClick={onClick}
    >
      <Icon height={14} width={14} iconName={'add'} color1={Color.PRIMARY} />
      <span style={{ padding: '0 0 0 4px' }}>{BUTTON_TEXT}</span>
    </Button>
  )
}

export default ImportButton
