import React from 'react'
import styled from '@emotion/styled'

import { Form, Spinner } from 'frontend/components'
import { Colors } from 'frontend/utils/pulseStyles'

interface Props {
  formConfig?: any
  setInputRef?: any
  isLoading?: Boolean
  datum?: any
  childForm?: any
}

const SpinnerWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '75vh',
})

const FormWrapper = styled.div({
  overflowY: 'auto',
  height: `calc(100vh - 150px)`,
})

const formStyle = {
  backgroundColor: Colors.WHITE,
  padding: '12px 36px 0px 36px',
  borderBottom: 'none',
}

export const FormContainer = ({
  formConfig,
  setInputRef,
  isLoading,
  datum,
  childForm,
}: Props) => {
  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    )

  return (
    <FormWrapper>
      <Form
        formConfig={formConfig}
        style={formStyle}
        setFormState={setInputRef}
        datum={datum}
      />
      {childForm}
    </FormWrapper>
  )
}
