import React from 'react'
import { TextField } from '@mui/material'

const TEXT_FIELD_STYLE = {
  width: '375px',
  '& .MuiFilledInput-root': {
    height: '91px',
    overflow: 'auto',
    backgroundColor: '#F1F5FA',
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: '5px',
    border: '1.5px solid #CEDBEA',
    borderRadius: '4px',
  },
  '& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after': {
    display: 'none',
  },
  '& .MuiInputBase-input': {
    fontSize: '12px',
    fontFamily: 'Inter',
    lineHeight: '1.3',
  },
}

interface Props {
  text?: string
  onChange?: any
}

const ScrollableTextInput = ({ text, onChange }: Props) => {
  return (
    <TextField
      id="filled-multiline-static"
      multiline
      variant="filled"
      sx={TEXT_FIELD_STYLE}
      value={text}
      onChange={onChange}
    />
  )
}

export default ScrollableTextInput
