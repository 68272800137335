const getListItems = ({
  listsConfigs,
  selectedListsConfigId,
  listsConfigData: {
    loading,
    error,
  },
  updateListItemsMutation
}: any) => {
  const listItems = {
    data: [],
    loading,
    error,
    listHeaderTitle: '',
    sortableConfigUpdateFunc: (newListItems: any[]) => { },
  }

  const sortableConfigUpdateFunc = (newListItems: any[]) => updateListItemsOnSortEnd({
    newListItems,
    selectedListsConfigId,
    updateListItemsMutation,
  })

  if (selectedListsConfigId) {
    const listsConfig = listsConfigs.data.find(
      ({ _id }: { _id: string }) => _id === selectedListsConfigId
    )

    if (listsConfig) {
      listItems.data = listsConfig.labelKeys
      listItems.listHeaderTitle = listsConfig.listId
      listItems.sortableConfigUpdateFunc = sortableConfigUpdateFunc
    }
  }

  return listItems
}

export default getListItems

const updateListItemsOnSortEnd = ({
  newListItems,
  selectedListsConfigId,
  updateListItemsMutation,
}: any) => {
  newListItems = newListItems.map(({ __typename, ...rest }: any) => rest)

  const input = {
    _id: selectedListsConfigId,
    labelKeys: newListItems,
  }

  updateListItemsMutation({ variables: { input } })
}
