import getCoercedLabel from './getCoercedLabel'

const getMappedFilterOptions = (uniqueFilterOptions, labelMap) => {
  return uniqueFilterOptions.map((option) => ({
    label: getCoercedLabel(labelMap[option]),
    value: option,
  }))
}

export default getMappedFilterOptions
