import React, { Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'
import _ from 'lodash'

import { TextInput } from 'frontend/components'
import Color from 'frontend/utils/color'

import { NodeState } from '../../types'
import { Label, Wrapper } from '../shared'

interface Props {
  field: string
  setNodeState: Dispatch<SetStateAction<NodeState>>
  text: { [key: string]: string }
  value: string
}

const InputWrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
})

const DeleteButton = styled.button({
  background: Color.RED,
  borderRadius: 4,
  color: Color.WHITE,
  cursor: 'pointer',
  fontWeight: 700,
  marginLeft: 12,
  padding: '6px 12px',
})

const Field = ({
  field,
  setNodeState,
  text,
  value,
}: Props) => {
  const updateField = ({ target: { value } }: any) => {
    setNodeState((nodeState) => {
      const textObject = { ...text }

      textObject[field] = value

      return {
        ...nodeState,
        text: textObject,
      }
    })
  }

  const deleteField = () => {
    setNodeState((nodeState) => {
      const textObject = { ...text }

      delete textObject[field]

      return {
        ...nodeState,
        text: textObject,
      }
    })
  }

  return (
    <Wrapper>
      <Label>
        {_.startCase(field)}
      </Label>
      <InputWrapper>
        <TextInput
          onChange={updateField}
          value={value}
        />
        <DeleteButton
          onClick={deleteField}
        >
          -
        </DeleteButton>
      </InputWrapper>
    </Wrapper>
  )
}

export default Field
