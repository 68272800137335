import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

interface WrapperProps {
  count: number
}

interface Props {
  count?: number
}

const Wrapper = styled.span({
  alignItems: 'center',
  background: Colors.WHITE,
  borderRadius: '20px',
  color: Colors.PRIMARY,
  display: 'flex',
  height: '20px',
  justifyContent: 'center',
}, ({ count }: WrapperProps) => ({
  width: count < 100 ? '20px' : '25px',
}))

const Counter = ({
  count,
}: Props) => {
  if (!count) return null

  return (
    <Wrapper
      count={count}
    >
      {count}
    </Wrapper>
  )
}

export default Counter
