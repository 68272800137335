import gql from 'graphql-tag'
import { schemaV0 } from './schemas'

export const GET_NCCN_PANELS = gql`
  query GetNccnPanels($input: NccnPanelsInput) {
    nccnPanels(input: $input) {
      ${schemaV0}
    }
  }
`
