import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Spinner } from 'frontend/components'
import { useTeamSitemap } from 'frontend/entity-hooks/ember'
import { Colors, Spacing } from 'frontend/utils/pulseStyles'

import { getSitemap } from '../../../utils'
import Counter from './Counter'
import getCount from './getCount'

const Button = styled.button(
  {
    alignItems: 'center',
    background: Colors.PRIMARY,
    borderRadius: 4,
    color: Colors.WHITE,
    cursor: 'pointer',
    display: 'flex',
    fontSize: 12,
    fontWeight: 700,
    justifyContent: 'space-between',
    padding: `5px ${Spacing.SMALL}`,
    marginLeft: 16,
    ':hover': {
      background: transparentize(0.2, Colors.PRIMARY),
    },
    ':disabled': {
      cursor: 'default',
      background: transparentize(0.5, Colors.PRIMARY),
    },
  },
  ({ count }) => ({
    width: count ? 143 : undefined,
  })
)

const SubmitButton = ({ sitemap }) => {
  const { team } = useParams()

  const { data, isLoading, update, isUpdating } = useTeamSitemap({
    queryInput: { team },
  })

  const currentSitemap = useMemo(() => {
    if (isLoading) return
    return getSitemap(data[0])
  }, [data])

  const count = getCount(sitemap, currentSitemap)

  const isDisabled = !count || isUpdating

  const submit = () => {
    const input = Object.entries(sitemap).reduce((acc, [key, nodes]) => {
      acc[key] = Array.from(nodes)
      return acc
    }, {})
    const updateInput = {
      variables: {
        input: {
          id: team,
          ...input,
        },
      },
    }
    update(updateInput)
  }

  const label = isUpdating ? <Spinner /> : 'Submit Changes'

  return (
    <Button disabled={isDisabled} count={count} onClick={submit}>
      {label}
      <Counter count={count} />
    </Button>
  )
}

export default SubmitButton
