import gql from 'graphql-tag'

export const GET_SECONDARY_ORGANIZATION_OPTIONS = gql`
  query GetSecondaryOrganizationOptions($input: SecondaryOrganizationOptionsInput!) {
    secondaryOrganizationOptions(input: $input) {
      type {
        options {
          label
          value
        }
        labelMap
      }
    }
  }
`
