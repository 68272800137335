import { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'

import { UseProviderKeyDecisionMakersOptions } from 'frontend/interfaces'

import useReadOptions from './useReadOptions'
import useCreate from './useCreate'
import useDelete from './useDelete'
import useUpdate from './useUpdate'
import { GET_PAGINATED_PROVIDER_KEY_DECISION_MAKERS } from 'frontend/entity-hooks/usePaginatedProviderKeyDecisionMakers/gql-tags'
import { unwrapGqlData } from 'frontend/utils'

export const useProviderKeyDecisionMakers = ({
  deleteOptions,
  queryInput,
}: UseProviderKeyDecisionMakersOptions = {}) => {
  const [queryProviderKeyDecisionMaker, { data }] = useLazyQuery(
    GET_PAGINATED_PROVIDER_KEY_DECISION_MAKERS
  )

  useEffect(() => {
    if (queryInput?.id) {
      queryProviderKeyDecisionMaker({
        variables: {
          input: {
            id: queryInput.id,
          },
        },
      })
    }
  }, [queryInput?.id])

  const options = useReadOptions()
  const [create, { loading: isCreating }] = useCreate()
  const [destroy, { loading: isDeleting }] = useDelete(deleteOptions)
  const [update, { loading: isUpdating }] = useUpdate()

  return {
    data: data && unwrapGqlData(data)[0] || {},
    create,
    delete: destroy,
    isCreating,
    isDeleting,
    isLoading: _.isEmpty(data),
    isUpdating,
    options,
    update,
  }
}
