import React from 'react'
import styled from '@emotion/styled'

import { useEmberNode } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'
import { Colors } from 'frontend/utils/pulseStyles'
import { NODE_TYPE_TO_PARENT_TYPE } from 'frontend/ClientManagement/Sitemap/constants'

const Wrapper = styled.span({
  color: Colors.PRIMARY,
})

const Label = () => {
  const queryString: any = useQueryString()
  const { node, nodeType } = queryString
  const parentNodeType = NODE_TYPE_TO_PARENT_TYPE[nodeType]

  const {
    data: parentNodeData,
    isLoading: isParentNodeDataLoading,
  }: any = useEmberNode({
    queryInput: {
      type: parentNodeType ?? nodeType,
      id: parentNodeType ? queryString[parentNodeType] : node,
    }
  })

  const {
    data: nodeData,
    isLoading: isNodeDataLoading,
  }: any = useEmberNode({
    queryInput: {
      type: nodeType,
      id: node,
    }
  })

  if (isParentNodeDataLoading || isNodeDataLoading) return (
    <>
      /
    </>
  )

  const parentLabel = `${parentNodeData[0].name} / `

  return (
    <>
      {parentLabel}
      <Wrapper>
        {nodeData[0].name}
      </Wrapper>
    </>
  )
}

export default Label
