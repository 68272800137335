import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import styled from '@emotion/styled'

import DynamicTextInput from './DynamicTextInput'
import { DeleteButton } from '../Buttons'

import {
  FormLabel,
  FieldContainer,
  StyledInput,
  StyledButton,
} from './../../shared/styledComponents'

import { NEW_LIST_ITEM } from '../../utils/new-list-item'

const FieldsFormContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(-98px + 100vh)',
})

const ButtonsContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: 24,
})
const InputsContainer = styled.div({
  padding: '12px 24px',
})

const DELETE_MODAL_TITLE = 'Delete List Item'

const UNIQUE_KEY_ERROR = 'Key must be unique.'
const FORM_LABELS = {
  KEY_LABEL: 'Key (Required)',
  LABEL_LABEL: 'Label',
  NOTE_LABEL: 'Note (Tooltip)',
  VALUE_WRAPPER_TYPE_LABEL: 'Value Wrapper Type',
}
const TAG = 'Tag'
const STAR_RATING = 'StarRating'
const VALUE_WRAPPER_TYPE_VALUES = [undefined, TAG, STAR_RATING]
const VALUE_WRAPPER_TYPE_OPTION_MAP = {
  [undefined]: { value: '', label: '' },
  [null]: { value: '', label: '' },
  [TAG]: { value: TAG, label: TAG },
  [STAR_RATING]: { value: STAR_RATING, label: 'Star Rating' },
}
const VALUE_WRAPPER_TYPE_OPTIONS = VALUE_WRAPPER_TYPE_VALUES.map((value) =>
  value === undefined
    ? { value, label: '(BLANK)' }
    : VALUE_WRAPPER_TYPE_OPTION_MAP[value]
)
const SUBMIT_LABEL = 'Save List Item'

const getDeleteModalText = (listItem) => {
  const labelNameString = listItem.labelName ? `, ${listItem.labelName}` : ``

  return `You are about to delete ${
    listItem.labelKey + labelNameString
  }. Are you sure?`
}

const Form = ({
  selectedListItemKey,
  data,
  invalidLabelKeys,
  mutationFunc,
  deleteListItem,
}) => {
  const originalLabelKey = data.labelKey
  const [stagedListItemLabelKey, setListItemLabelKey] = useState(data.labelKey)
  const [stagedListItemLabelName, setListItemLabelName] = useState(
    data.labelName
  )
  const [stagedListItemLabelInfo, setListItemLabelInfo] = useState(
    data.labelInfo
  )
  const [stagedListItemText, setListItemText] = useState(data.text)

  const [
    stagedListItemValueWrapperType,
    setListItemValueWrapperType,
  ] = useState(data.valueWrapperType)

  const saveField = () => {
    const isCreate = originalLabelKey === NEW_LIST_ITEM.labelKey
    const isUpdateSameKey = originalLabelKey !== stagedListItemLabelKey
    const isInvalidKey = invalidLabelKeys.includes(stagedListItemLabelKey)

    if (isCreate || isUpdateSameKey) {
      if (isInvalidKey) {
        alert(UNIQUE_KEY_ERROR)
        return
      }
    }

    const newListItem = {
      text: stagedListItemText,
      labelKey: stagedListItemLabelKey,
      labelName: stagedListItemLabelName,
      labelInfo: stagedListItemLabelInfo,
      valueWrapperType:
        stagedListItemValueWrapperType === undefined
          ? null
          : stagedListItemValueWrapperType,
    }

    mutationFunc(newListItem, originalLabelKey)
  }

  const handleLabelKeyChange = (e) => {
    e.persist()
    const value = e.currentTarget && e.currentTarget.value
    setListItemLabelKey(value)
  }

  const handleLabelNameChange = (e) => {
    e.persist()
    const value = e.currentTarget && e.currentTarget.value
    setListItemLabelName(value)
  }

  const handleLabelInfoChange = (e) => {
    e.persist()
    const value = e.currentTarget && e.currentTarget.value
    setListItemLabelInfo(value)
  }

  const handleValueWrapperTypeSelection = (obj) =>
    setListItemValueWrapperType(obj.value)

  useEffect(() => {
    setListItemLabelKey(data.labelKey)
    setListItemLabelName(data.labelName)
    setListItemLabelInfo(data.labelInfo)
    setListItemValueWrapperType(data.valueWrapperType)
    setListItemText(data.text)
  }, [data])

  return (
    <FieldsFormContainer>
      <InputsContainer>
        <FieldContainer>
          <FormLabel>{FORM_LABELS.KEY_LABEL}</FormLabel>
          <StyledInput
            type="text"
            value={stagedListItemLabelKey || ''}
            onChange={handleLabelKeyChange}
            disabled={!data.labelKey}
          />
        </FieldContainer>

        <FieldContainer>
          <FormLabel>{FORM_LABELS.LABEL_LABEL}</FormLabel>
          <StyledInput
            type="text"
            value={stagedListItemLabelName || ''}
            onChange={handleLabelNameChange}
            disabled={!data.labelKey}
          />
        </FieldContainer>

        <FieldContainer>
          <FormLabel>{FORM_LABELS.NOTE_LABEL}</FormLabel>
          <StyledInput
            type="text"
            value={stagedListItemLabelInfo || ''}
            onChange={handleLabelInfoChange}
            disabled={!data.labelKey}
          />
        </FieldContainer>

        <FieldContainer>
          <FormLabel>{FORM_LABELS.VALUE_WRAPPER_TYPE_LABEL}</FormLabel>

          <Select
            styles={{ container: (base) => ({ ...base, flex: 1 }) }}
            value={
              VALUE_WRAPPER_TYPE_OPTION_MAP[stagedListItemValueWrapperType]
            }
            defaultValue={
              VALUE_WRAPPER_TYPE_OPTION_MAP[stagedListItemValueWrapperType]
            }
            onChange={handleValueWrapperTypeSelection}
            options={VALUE_WRAPPER_TYPE_OPTIONS}
            isDisabled={!data.labelKey}
          />
        </FieldContainer>
      </InputsContainer>
      <div style={{ border: '1px solid black', margin: '0 24px' }} />
      <DynamicTextInput
        text={stagedListItemText || {}}
        setListItemText={setListItemText}
      />
      <div style={{ border: '1px solid black', margin: '0 24px' }} />
      <ButtonsContainer>
        <StyledButton onClick={saveField} disabled={!data.labelKey}>
          {SUBMIT_LABEL}
        </StyledButton>
        {data && selectedListItemKey !== NEW_LIST_ITEM.labelKey && (
          <div style={{ padding: '0 24px' }}>
            <DeleteButton
              modalTitle={DELETE_MODAL_TITLE}
              modalText={getDeleteModalText(data)}
              deleteFunc={() => deleteListItem(selectedListItemKey)}
            />
          </div>
        )}
      </ButtonsContainer>
    </FieldsFormContainer>
  )
}

Form.propTypes = {
  data: PropTypes.object,
  invalidLabelKeys: PropTypes.array.isRequired,
  mutationFunc: PropTypes.func.isRequired,
}

Form.defaultProps = {
  data: {},
}

export default Form
