import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import CreateModal from './CreateModal'

const CreateModalContainer = () => {
  const isModalOpen = useIsModalOpen()

  if (!isModalOpen) return null

  return <CreateModal />
}

export default CreateModalContainer

const useIsModalOpen = () => {
  const location = useLocation()

  const { createNccnPanel } = queryString.parse(location.search)

  return Boolean(createNccnPanel)
}
