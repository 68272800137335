import React from 'react'

const Link = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          className="color1"
          id="link-a"
          d="M4.53231728,6.48900535 C6.25204229,4.79996023 9.06613775,4.79996023 10.7868399,6.48900535 L10.7868399,6.48900535 L11.6215584,7.3088325 C11.736756,7.42197494 11.7366781,7.61182404 11.61745,7.72900798 L11.61745,7.72900798 L10.6675706,8.66260127 C10.5423424,8.78568242 10.3567863,8.781572 10.239718,8.66659217 L10.239718,8.66659217 L9.40422005,7.84599954 C8.4407832,6.89879072 6.87739684,6.89879072 5.91395999,7.84599954 L5.91395999,7.84599954 L3.17217114,10.5388749 C2.14913019,11.5436648 2.21361988,13.2163953 3.36368597,14.1376926 C4.36425325,14.9380697 5.85240165,14.7624473 6.76502844,13.8661018 L6.76502844,13.8661018 L7.69524333,12.9534416 C8.07631876,12.5782049 8.69581061,12.5782049 9.07688603,12.9534416 C9.45893858,13.3277187 9.45893858,13.9351991 9.07688603,14.3104358 L9.07688603,14.3104358 L8.1916185,15.1799102 C6.5608111,16.781624 3.91380255,17.0560938 2.12665651,15.6242442 C0.0766661327,13.9793446 -0.0357022625,10.9755314 1.78955132,9.18188068 L1.78955132,9.18188068 Z M9.8083335,1.87564293 C11.4391409,0.273929129 14.0861494,-0.000540701889 15.8732955,1.43130891 C17.9232859,3.07620852 18.0356543,6.08002171 16.2104007,7.87367245 L16.2104007,7.87367245 L13.4676347,10.5665478 C11.7479097,12.2555929 8.93381425,12.2555929 7.21311213,10.5665478 L7.21311213,10.5665478 L6.3783936,9.74672064 C6.26319603,9.6335782 6.26327389,9.4437291 6.38250205,9.32654516 L6.38250205,9.32654516 L7.33238141,8.39295186 C7.45760964,8.26987071 7.64316571,8.27398113 7.76023403,8.38896096 L7.76023403,8.38896096 L8.59573195,9.20955359 C9.5591688,10.1567624 11.1225552,10.1567624 12.085992,9.20955359 L12.085992,9.20955359 L14.8277809,6.51667826 C15.8508218,5.51188835 15.7863321,3.83915788 14.636266,2.91786054 C13.6356988,2.11748348 12.1475504,2.29310579 11.2349236,3.18945132 L11.2349236,3.18945132 L10.3047087,4.10211149 C9.92363324,4.47734822 9.30414139,4.47734822 8.92306597,4.10211149 C8.54101342,3.72783445 8.54101342,3.12035402 8.92306597,2.74511729 L8.92306597,2.74511729 Z"
        />
      </defs>
      <use
        fill={iconColor}
        fill-rule="evenodd"
        transform="translate(3 3.5)"
        xlinkHref="#link-a"
      />
    </svg>
  )
}

export default Link
