import React from 'react'
import _ from 'lodash'

import { useQueryString } from 'frontend/hooks'

import { LIST_HEADER } from '../../../enums'
import { List, ListHeader, ListItem } from '../../shared'

const InactiveList = ({ regimenIdToLabel, regimens, setResources }) => {
  const { indication } = useQueryString()

  const enableResource = ({ treatmentPlan, ...resource }) => {
    setResources(({ treatmentPlans, ...rest }) => {
      const index = treatmentPlans.findIndex(
        ({ resource }) => resource === indication
      )
      if (index >= 0) {
        const treatmentPlan = _.cloneDeep(treatmentPlans[index])
        treatmentPlan.children.push(resource)
        treatmentPlans[index] = treatmentPlan
      } else {
        treatmentPlans.push(treatmentPlan)
      }
      return {
        treatmentPlans,
        ...rest,
      }
    })
  }

  return (
    <>
      <ListHeader listLength={regimens.length} type={LIST_HEADER.INACTIVE} />
      <List>
        {regimens.map((resource) => (
          <ListItem
            key={resource.resource}
            checked={false}
            label={regimenIdToLabel[resource.resource]}
            resource={resource}
            toggleResource={enableResource}
            type={LIST_HEADER.INACTIVE}
          />
        ))}
      </List>
    </>
  )
}

export default InactiveList
