import React from 'react'

import Modal from 'frontend/components/Modal'

import ModalContent from './ModalContent'

interface Props {
  closeModal: () => void
}

const NodeModal = ({ closeModal }: Props) => (
  <Modal
    disableHeader
    show
    handleClose={closeModal}
  >
    <ModalContent
      closeModal={closeModal}
    />
  </Modal>
)

export default NodeModal
