import _ from 'lodash'

const validateIndicationProduct = (data) => {
  const providerChannelProductsRaw = data['Provider Channel Products']
  const serviceDeliveryCostRaw = data['Service Delivery Costs']
  const prevalenceAccessChallengesRaw = data['Prevalence Access Challenges']
  const payerReimbursementCostRaw = data['Payer Reimbursement Costs']
  const dosingAdministrationRaw = data['Dosing Administration']
  const currentSnapshotSegmentationRaw = data['Current Snapshot Segmentation']

  // * removing the first two rows
  const providerChannelProduct = providerChannelProductsRaw.slice(2)
  const serviceDeliveryCost = serviceDeliveryCostRaw.slice(2)
  const prevalenceAccessChallenges = prevalenceAccessChallengesRaw.slice(2)
  const payerReimbursementCost = payerReimbursementCostRaw.slice(2)
  const dosingAdministration = dosingAdministrationRaw.slice(2)
  const currentSnapshotSegmentation = currentSnapshotSegmentationRaw.slice(2)

  const serviceDeliveryCostByProductProviderChannel = _.keyBy(
    serviceDeliveryCost,
    'product'
  )
  const prevalenceAccessChallengesByProductProviderChannel = _.keyBy(
    prevalenceAccessChallenges,
    'product'
  )
  const payerReimbursementCostByProduct = _.keyBy(
    payerReimbursementCost,
    'product'
  )
  const dosingAdministrationByProduct = _.keyBy(dosingAdministration, 'product')
  const currentSnapshotSegmentationByProduct = _.keyBy(
    currentSnapshotSegmentation,
    'currentSnapshotSegmentation'
  )

  // * extract all products

  const superSetProducts = providerChannelProduct.reduce(
    (acc, { products }) => {
      const splitProducts = products.split(',').map((product) => product.trim())
      splitProducts.forEach((product) => {
        acc.push(product)
      })
      return acc
    },
    []
  )

  const uniqueProducts = _.uniq(superSetProducts)

  // * validate if all product is present in the other sheets

  const errors = []

  uniqueProducts.forEach((product) => {
    const hasServiceDeliveryCost = Boolean(
      serviceDeliveryCostByProductProviderChannel[product]
    )
    const hasPrevalenceAccessChallenges = Boolean(
      prevalenceAccessChallengesByProductProviderChannel[product]
    )
    const hasPayerReimbursementCost = Boolean(
      payerReimbursementCostByProduct[product]
    )
    const hasDosingAdministration = Boolean(
      dosingAdministrationByProduct[product]
    )
    const hasCurrentSnapshotSegmentation = Boolean(
      currentSnapshotSegmentationByProduct[product]
    )

    if (
      !hasServiceDeliveryCost ||
      !hasPrevalenceAccessChallenges ||
      !hasPayerReimbursementCost
    ) {
      errors.push({
        product,
        hasServiceDeliveryCost: hasServiceDeliveryCost ? '✅' : '❌',
        hasPrevalenceAccessChallenges: hasPrevalenceAccessChallenges
          ? '✅'
          : '❌',
        hasPayerReimbursementCost: hasPayerReimbursementCost ? '✅' : '❌',
        hasDosingAdministration: hasDosingAdministration ? '✅' : '🟡',
        hasCurrentSnapshotSegmentation: hasCurrentSnapshotSegmentation
          ? '✅'
          : '🟡',
      })
    }
  })

  return errors
}

export default validateIndicationProduct
