import _ from 'lodash'

function compareText(stringA: String | undefined | null, stringB: String | undefined | null): number {
  if(stringA === undefined || stringA === null) stringA = ''
  if(stringB === undefined || stringB === null) stringB = ''

  if (_.isEmpty(stringA) && _.isEmpty(stringB)) return 0
  if (_.isEmpty(stringB)) return 1
  if (_.isEmpty(stringA)) return -1

  return stringA.toLowerCase().localeCompare(stringB.toLowerCase())
}

export default compareText
