import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import _ from 'lodash'

import { GET_VEGA_PRODUCTS } from 'frontend/api/queries'

import Spinner from 'frontend/components/Spinner'
import DataTable from 'frontend/components/DataTable'
import { EXPAND } from 'frontend/components/DataTable/Cells/cellTypes'

import MultiSelectColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/MultiSelectColumnFilter'
import customMultiSelectFilterFn from 'frontend/components/Table/custom-filters/MultiSelect/customMultiSelectFilterFn'

import EditProductForm from './EditProductForm'

const ProductsTable = () => {
  const [selectedRowId, setSelectedRowId] = useState(null)

  const { data, loading } = useQuery(GET_VEGA_PRODUCTS)

  const onRowClick = (row) => {
    const { original } = row
    setSelectedRowId(original.id)
  }

  let selectedProductData = {}
  if (selectedRowId) {
    const rowData = data.vegaProducts.find(({ id }) => id === selectedRowId)
    if (rowData) selectedProductData = rowData
  }

  const closeHandler = () => {
    setSelectedRowId(null)
  }

  const tableData = getTableData(data)

  const COLUMNS = [
    {
      Header: 'ID',
      accessor: 'id',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      sticky: 'left',
    },
    {
      Header: 'Brand Name',
      accessor: 'brand_name',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      sticky: 'left',
    },
    {
      Header: 'Generic Name',
      accessor: 'generic_name',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
    },
    {
      Header: 'Logo Link',
      accessor: 'logo_link',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
    {
      Header: 'Color',
      accessor: 'color',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
    {
      Header: 'Messaging',
      accessor: 'messaging',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
    {
      Header: 'Manufacturers',
      accessor: 'manufacturers',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
  ]

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <DataTable data={tableData} columns={COLUMNS} />
          {!_.isEmpty(selectedProductData) && (
            <EditProductForm
              selectedProviderData={selectedProductData}
              closeHandler={closeHandler}
            />
          )}
        </>
      )}
    </>
  )
}

export default ProductsTable

const getTableData = (data) => {
  if (!data) return []

  return data.vegaProducts.map(({ manufacturers, ...rest }) => ({
    manufacturers: manufacturers.map(({ name }) => name),
    ...rest,
  }))
}
