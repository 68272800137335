import React from 'react'
import MultiSelectColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/MultiSelectColumnFilter'
import customMultiSelectFilterFn from 'frontend/components/Table/custom-filters/MultiSelect/customMultiSelectFilterFn'
import HydratedRefsMultiSelectColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/HydratedRefsMultiSelectColumnFilter'

const getMappedOptions = (value, labelMap) => {
  return value.map((datum) => labelMap[datum]).join(', ')
}

const getHydratedFilter = (labelMap) => (filteredProps) => (
  <HydratedRefsMultiSelectColumnFilter {...filteredProps} labelMap={labelMap} />
)

const getClinicalTrialColumns = ({
  providers,
  indications,
  products,
  clinicalTrialsOptions,
}) => [
  {
    Header: 'Slug',
    accessor: 'slug',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    sticky: 'left',
    width: 150,
  },
  {
    Header: 'Account',
    accessor: 'account',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    sticky: 'left',
    width: 150,
  },
  {
    Header: 'Indication',
    accessor: 'indication',
    Filter: getHydratedFilter(indications.labelMap),
    filter: customMultiSelectFilterFn,
    cellFormatter: (value) => indications.labelMap[value],
    sortType: 'text',
    sticky: 'left',
    width: 150,
  },
  {
    Header: 'Product',
    accessor: 'product',
    Filter: getHydratedFilter(products.labelMap),
    filter: customMultiSelectFilterFn,
    cellFormatter: (value) => products.labelMap[value],
    sortType: 'text',
    sticky: 'left',
    width: 150,
  },
  {
    Header: 'Confidence Score',
    accessor: 'confidence_score',
    Filter: getHydratedFilter(clinicalTrialsOptions.confidence_score.labelMap),
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    cellFormatter: (value) => value + '%',
    sticky: 'left',
    width: 150,
  },
  {
    Header: 'Conditions',
    accessor: 'conditions',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    width: 300,
  },
  {
    Header: 'Trial Title',
    accessor: 'trial_name',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    width: 300,
  },
  {
    Header: 'NCT ID',
    accessor: 'nct_id',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    width: 150,
  },
  {
    Header: 'Trial Link',
    accessor: 'trial_link',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    width: 300,
  },
  {
    Header: 'Phases',
    accessor: 'phases',
    Filter: getHydratedFilter(clinicalTrialsOptions.phases.options),
    filter: customMultiSelectFilterFn,
    cellFormatter: (value) =>
      getMappedOptions(value, clinicalTrialsOptions.phases.options),
    sortType: 'text',
    width: 150,
  },
  {
    Header: 'Overall Status',
    accessor: 'trial_status',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    width: 150,
  },
  {
    Header: 'Account Affiliate String (NPI Registry)',
    accessor: 'scraping_fields.account_affiliate',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    width: 200,
  },
  {
    Header: 'Account 1st String Match On',
    accessor: 'scraping_fields.account_first_string_match_on',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    width: 200,
  },
  {
    Header: 'Account 1st String Match Results',
    accessor: 'scraping_fields.account_first_string_match_results',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    width: 200,
  },
  {
    Header: 'Account 2nd String Match On',
    accessor: 'scraping_fields.account_second_string_match_on',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    width: 200,
  },
  {
    Header: 'Account 2nd String Match Results',
    accessor: 'scraping_fields.account_second_string_match_results',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    width: 200,
  },
  {
    Header: 'Indication String Match',
    accessor: 'scraping_fields.indication_string_match',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    width: 200,
  },
  {
    Header: 'Intervention Names',
    accessor: 'scraping_fields.intervention_names',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    width: 150,
  },
  {
    Header: 'Location Facilities',
    accessor: 'location_facilities',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    cellFormatter: (value) => `${value.slice(0, 10).join(', ')}...`,
    sortType: 'text',
    width: 300,
  },
  {
    Header: 'Lead Sponsor Name',
    accessor: 'lead_sponsor_name',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    width: 300,
  },
  {
    Header: 'NPI Numbers',
    accessor: 'npi_numbers',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    width: 300,
  },
]

export default getClinicalTrialColumns
