import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import Button from 'frontend/components/Button'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const ButtonCell = ({ cellConfig, row }) => {
  const { label, onClick } = cellConfig

  let buttonLabel = label
  let onButtonClick = onClick

  return (
    <Container>
      <Button
        onClick={() => {
          onButtonClick(row.original)
        }}
      >
        {buttonLabel}
      </Button>
    </Container>
  )
}

ButtonCell.propTypes = {
  cellConfig: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
}

export default ButtonCell
