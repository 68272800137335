// ! FLAGGED FOR DESIGN SYSTEM !
import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

const Tooltip = ({ id, type, place, effect, text, children }) => {
  return (
    <>
      <a data-tip data-for={id}>
        {children}
      </a>
      <ReactTooltip id={id} type={type} place={place} effect={effect}>
        {text}
      </ReactTooltip>
    </>
  )
}

// to view prop explanations:
// https://wwayne.github.io/react-tooltip/
Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  place: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
  effect: PropTypes.oneOf(['float', 'solid']),
  type: PropTypes.oneOf([
    'dark',
    'success',
    'error',
    'warning',
    'info',
    'light',
  ]),
  text: PropTypes.any,
  children: PropTypes.any,
}

Tooltip.defaultProps = {
  place: 'top',
  effect: 'float',
  type: 'dark',
  text: '',
  children: null,
}

export default Tooltip
