import formatDataForExport from 'frontend/components/ExportExcelButton/formatDataForExport'

const EXPORT_FILENAME = 'external-pathways-protocols'

const getExportConfig = (positioningTableColumns: any) => {
  const formatExportRows = (rows: any) => formatDataForExport({
    data: rows,
    columns: positioningTableColumns.slice(1),
    isReactTableData: true,
  })

  return { filename: EXPORT_FILENAME, formatRows: formatExportRows }
}

export default getExportConfig
