import { POLICY_LINK_FIELDS } from '../PolicyContent/PolicyInfo/utils'

export const getPolicyLinkInput = ({ input }: any) => {
  const policyLinkInput = POLICY_LINK_FIELDS.reduce((acc: any, key: any) => {
    let value = input[key]
    acc[key] = value
    return acc
  }, {})
  return policyLinkInput
}
