import React from 'react'
import styled from '@emotion/styled'

import { Colors, Spacing } from '../utils/pulseStyles'

import { useAuth0 } from '../../react-auth0-spa'
import superUsersById from '../utils/super-users'
import {
  DATA_IMPORT_CONFIG,
  ORGANIZATIONS_CONFIG,
  GENERAL_DATA_CONFIG,
  ADMINISTRATOR_DATA_CONFIG,
  SPECIALIZED_DATA_CONFIG,
} from './sidebar-link-utils'
import NavigationLinks from '../components/NavigationLinks'

const SidebarContainer = styled.div`
  width: 300px;
  min-width: 300px;
  background-color: ${Colors.NAVY};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  @media (max-width: 1200px) {
    width: 256px;
    min-width: 256px;
  }
`

const OrionHeader = styled.div({
  alignItems: 'center',
  // background: transparentize(0.1, Colors.BLACK),
  color: Colors.ORION,
  display: 'flex',
  fontSize: 12,
  fontWeight: 700,
  padding: `${Spacing.MEDIUM} ${Spacing.EXTRA_LARGE}`,
  textTransform: 'uppercase',
})

const OrionLogo = styled.img({
  display: 'inline',
  marginRight: Spacing.SMALL,
})

const Sidebar = () => {
  const { user } = useAuth0()
  const isSuperUser = user.sub in superUsersById

  return (
    <SidebarContainer>
      <OrionHeader>
        <OrionLogo src="https://res.cloudinary.com/pulsedatatools/image/upload/v1573837414/polaris/icons/orion-1-color.svg" />
        Orion Data Mgmt
      </OrionHeader>
      <div style={{ padding: `0 0 24px` }}>
        <NavigationLinks
          sectionHeader="Import/Export Data"
          linkConfig={DATA_IMPORT_CONFIG}
        />
        <NavigationLinks
          sectionHeader="Organizations"
          linkConfig={ORGANIZATIONS_CONFIG}
          isSuperUser={isSuperUser}
        />
        <NavigationLinks
          sectionHeader="General Data"
          linkConfig={GENERAL_DATA_CONFIG}
        />
        <NavigationLinks
          sectionHeader="Specialized Data"
          linkConfig={SPECIALIZED_DATA_CONFIG}
          isSuperUser={isSuperUser}
        />
        <NavigationLinks
          sectionHeader="Administrator"
          linkConfig={ADMINISTRATOR_DATA_CONFIG}
          isSuperUser={isSuperUser}
        />
      </div>
    </SidebarContainer>
  )
}

export default Sidebar
