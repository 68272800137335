import React, { Dispatch, SetStateAction } from 'react'

import { TextInput } from 'frontend/components'

import { NodeState } from '../types'
import { Label, Wrapper } from './shared'

interface Props {
  node?: any
  nodeState: NodeState
  setNodeState: Dispatch<SetStateAction<NodeState>>
}

const Order = ({
  node,
  nodeState,
  setNodeState,
}: Props) => {
  const updateNodeState = ({
    target: {
      name,
      value,
    },
  }: any) => {
    setNodeState((nodeState) => {
      value = parseInt(value)

      return {
        ...nodeState,
        [name]: value,
      }
    })
  }

  return (
    <Wrapper>
      <Label>
        Order
      </Label>
      <TextInput
        name={'order'}
        onChange={updateNodeState}
        type={'number'}
        value={nodeState?.order ?? node?.order}
      />
    </Wrapper>
  )
}

export default Order
