import React from 'react'
import _ from 'lodash'
import LineOfTherapiesPanel from './LineOfTherapiesPanel'

const LineOfTherapiesPanelContainer = ({
  selectedIndicationId,
  selectedRegimenId,
  selectedPopulationId,
  selectedLineId,
  selectLine,
  baseTreatmentPlans,
  setStagedTreatmentPlans,
  enabledTreatmentPlans,
  enabledTreatmentPlansHash,
}) => {
  const selectedIndication = _.cloneDeep(
    baseTreatmentPlans.find(({ _id }) => _id === selectedIndicationId) || {
      regimens: [],
    }
  )
  const selectedRegimen = selectedIndication.regimens.find(
    ({ _id }) => _id === selectedRegimenId
  ) || { populations: [] }

  const selectedPopulation = selectedRegimen.populations.find(
    ({ _id }) => _id === selectedPopulationId
  ) || { lines: [] }

  const targetInd = enabledTreatmentPlans.find(
    ({ _id }) => _id === selectedIndication._id
  )

  const isSelectedIndEnabled = Boolean(targetInd)

  const targetRegimen = isSelectedIndEnabled
    ? targetInd.regimens.find(({ _id }) => _id === selectedRegimen._id)
    : null

  const isSelectedRegEnabled = Boolean(targetRegimen)

  const targetPopulation = isSelectedRegEnabled
    ? targetRegimen.populations.find(
        ({ _id }) => _id === selectedPopulation._id
      )
    : null

  const isSelectedPopEnabled = Boolean(targetPopulation)

  const isIndicationRegimenInHash =
    enabledTreatmentPlansHash[selectedIndication._id] &&
    enabledTreatmentPlansHash[selectedIndication._id][selectedRegimen._id]
  const enabledLineHash = isIndicationRegimenInHash
    ? enabledTreatmentPlansHash[selectedIndication._id][selectedRegimen._id][
        selectedPopulation._id
      ]
    : {}

  const enableLine = (lineToEnable) => {
    selectLine(lineToEnable)

    if (!isSelectedPopEnabled) {
      selectedPopulation.lines = [lineToEnable]
      if (!isSelectedRegEnabled) {
        selectedRegimen.populations = [{ ...selectedPopulation }]
        if (!isSelectedIndEnabled) {
          selectedIndication.regimens = [{ ...selectedRegimen }]
          enabledTreatmentPlans = enabledTreatmentPlans.concat([
            selectedIndication,
          ])
        } else {
          targetInd.regimens = targetInd.regimens.concat([selectedRegimen])
        }
      } else {
        targetRegimen.populations = targetRegimen.populations.concat([
          selectedPopulation,
        ])
      }
    } else {
      targetPopulation.lines = targetPopulation.lines.concat([lineToEnable])
    }
    const activeTreatmentPlans = _.cloneDeep(enabledTreatmentPlans)
    setStagedTreatmentPlans(activeTreatmentPlans)
  }

  const disableLine = (lineToDisable) => {
    const lineIndexToDelete = targetPopulation.lines.findIndex(
      ({ _id }) => _id === lineToDisable._id
    )
    targetPopulation.lines.splice(lineIndexToDelete, 1)

    setStagedTreatmentPlans(enabledTreatmentPlans)
  }

  let enabledLines = []
  if (isSelectedPopEnabled) enabledLines = targetPopulation.lines
  const disabledLines = selectedPopulation.lines.filter(({ _id }) => {
    return !enabledLineHash || !enabledLineHash[_id]
  })

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const [removedItem] = enabledLines.splice(oldIndex, 1)
    enabledLines.splice(newIndex, 0, removedItem)
    targetPopulation.lines = enabledLines
    setStagedTreatmentPlans(enabledTreatmentPlans)
  }

  return (
    <LineOfTherapiesPanel
      selectedLineId={selectedLineId}
      selectLine={selectLine}
      selectedPopulation={selectedPopulation}
      enabledLines={enabledLines}
      disabledLines={disabledLines}
      enableLine={enableLine}
      disableLine={disableLine}
      onSortEnd={onSortEnd}
    />
  )
}

export default LineOfTherapiesPanelContainer
