import React, { Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'
import Select from 'react-select'

import { CustomLink } from '../../CustomLink'
import { PreviousPolicyLabel } from './PreviousPolicyLabel'

interface Props {
  activeTab: string
  previousApprovedPolicyOptions?: any[]
  selectedPreviousApprovedPolicy?: any
  setSelectedPreviousApprovedPolicy: Dispatch<SetStateAction<any>>
}

const ICON_STYLES = {
  iconColor: '#0668D9',
  width: '16px',
  height: '16px',
  marginRight: '2px',
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '12px 0',
  width: '375px'
})

const HeaderWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between'
})

const PreviousPolicy = ({
  activeTab,
  previousApprovedPolicyOptions,
  selectedPreviousApprovedPolicy,
  setSelectedPreviousApprovedPolicy
}: Props) => {
  if (activeTab === 'mostRecent') return null

  const selectPreviousApprovedPolicy = (option: any) => {
    setSelectedPreviousApprovedPolicy(option)
  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <PreviousPolicyLabel />
        <CustomLink
          link={selectedPreviousApprovedPolicy?.value}
          text={'S3 Link'}
          iconStyles={ICON_STYLES}
        />
      </HeaderWrapper>
      <Select
        onChange={selectPreviousApprovedPolicy}
        options={previousApprovedPolicyOptions}
        value={selectedPreviousApprovedPolicy}
      />
    </Wrapper>
  )
}

export default PreviousPolicy
