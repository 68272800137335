import { GET_PAGINATED_PROVIDER_KEY_DECISION_MAKERS } from 'frontend/entity-hooks/usePaginatedProviderKeyDecisionMakers/gql-tags'

const update = (
  cache: any,
  { data: { createProviderKeyDecisionMaker } }: { data?: any }
) => {
  const { paginatedProviderKeyDecisionMakers } = cache.readQuery({
    query: GET_PAGINATED_PROVIDER_KEY_DECISION_MAKERS,
  })

  cache.writeQuery({
    query: GET_PAGINATED_PROVIDER_KEY_DECISION_MAKERS,
    data: {
      paginatedProviderKeyDecisionMakers: [
        createProviderKeyDecisionMaker,
        ...paginatedProviderKeyDecisionMakers,
      ],
    },
  })
}

export default update
