import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { useEmberNode } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'
import { Colors } from 'frontend/utils/pulseStyles'
import { NODE_TYPE_TO_PARENT_TYPE } from 'frontend/ClientManagement/Sitemap/constants'
import { ACCOUNTS } from 'frontend/ClientManagement/Sitemap/ResourcesModal/constants'

import { ToggleAllButton } from '../../shared'
import { getAccountsQueryGqlTag } from '../utils'

const ToggleOnAllButton = ({ disabled, parentResources, setResources }) => {
  const { team } = useParams()

  const { node, nodeType, tool } = useQueryString()
  const parentType = NODE_TYPE_TO_PARENT_TYPE[nodeType]

  const { data: tools } = useEmberNode({
    queryInput: {
      type: 'tool',
      id: nodeType === 'tool' ? node : tool,
    },
  })

  const { data: organizations } = useQuery(
    getAccountsQueryGqlTag(tools[0].permitted_entity_types)
  )

  const toggleOnAllAccounts = () => {
    setResources(({ accounts, ...rest }) => {
      if (parentType) {
        accounts = parentResources.reduce(
          (
            acc,
            {
              id: teamParentResource,
              resource: { entity_id, type: resourceType },
            }
          ) => {
            if (!ACCOUNTS.has(resourceType)) return acc

            acc.push({
              team,
              [nodeType]: node,
              resource: entity_id,
              [`team_${parentType}_resource`]: teamParentResource,
            })

            return acc
          },
          []
        )
      } else {
        accounts = Object.values(organizations)[0].reduce((acc, { _id }) => {
          acc.push({
            team,
            [nodeType]: node,
            resource: _id,
          })

          return acc
        }, [])
      }

      return {
        accounts,
        ...rest,
      }
    })
  }

  return (
    <ToggleAllButton
      disabled={disabled}
      color={Colors.GREEN}
      onClick={toggleOnAllAccounts}
      style={{ marginRight: '20px' }}
    >
      Toggle on All Accounts
    </ToggleAllButton>
  )
}

export default ToggleOnAllButton
