import DefaultCell from './DefaultCell'
import ButtonCell from './ButtonCell'
import ExpandCell from './ExpandCell'
import InputCell from './InputCell'
import LinkCell from './LinkCell'
import MenuCell from './MenuCell'
import SelectCell from './SelectCell'

export const DEFAULT = 'default'
export const BUTTON = 'button'
export const EXPAND = 'expand'
export const INPUT = 'input'
export const LINK = 'link'
export const MENU = 'menu'
export const SELECT = 'select'

export const cellTypes = {
  [DEFAULT]: DefaultCell,
  [BUTTON]: ButtonCell,
  [EXPAND]: ExpandCell,
  [INPUT]: InputCell,
  [LINK]: LinkCell,
  [MENU]: MenuCell,
  [SELECT]: SelectCell,
}
