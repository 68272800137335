import gql from 'graphql-tag'

export const CREATE_PATHWAYS_INFLUENCER_CONNECTION = gql`
  mutation CreatePathwaysInfluencer(
    $input: CreatePathwaysInfluencerInput!
  ) {
    createPathwaysInfluencer(input: $input) {
      id
      person
      pathways_organization
      indication_permissions
      pathways_influencer_types
      tumor_type_specialty
      internal_tdg_note
      internal_pathways_management_types
      value_chair_indications
      position
      priority
      alert_date
      alert_type
      alert_description
      is_excluded
      exclusion_reason
      start_date
      end_date
      start_quarter
      end_quarter
    }
  }
`
export const UPDATE_PATHWAYS_INFLUENCER_CONNECTION = gql`
  mutation UpdatePathwaysInfluencer(
    $input: UpdatePathwaysInfluencerInput!
  ) {
    updatePathwaysInfluencer(input: $input) {
      id
      person
      pathways_organization
      indication_permissions
      pathways_influencer_types
      tumor_type_specialty
      internal_tdg_note
      internal_pathways_management_types
      value_chair_indications
      position
      priority
      alert_date
      alert_type
      alert_description
      is_excluded
      exclusion_reason
      start_date
      end_date
      start_quarter
      end_quarter
    }
  }
`

export const DELETE_PATHWAYS_INFLUENCER_CONNECTION = gql`
  mutation DeletePathwaysInfluencer(
    $input: DeletePathwaysInfluencerInput!
  ) {
    deletePathwaysInfluencer(input: $input) {
      id
      person
      pathways_organization
      indication_permissions
      pathways_influencer_types
      tumor_type_specialty
      internal_tdg_note
      internal_pathways_management_types
      value_chair_indications
      position
      priority
      alert_date
      alert_type
      alert_description
      is_excluded
      exclusion_reason
      start_date
      end_date
      start_quarter
      end_quarter
    }
  }
`
