import React from 'react'
import { useQuery } from '@apollo/client'
import _ from 'lodash'

import Panel from '../../../../components/Panel'
import ModalButtonWithForm from '../../../shared/ModalButtonWithForm'
import DeleteButton from '../../../shared/DeleteButton'
import CopyOneOfStringButton from '../../../shared/CopyOneOfStringButton'
import ProductsSelect from './ProductsSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { transparentize } from 'polished'

import {
  StyledInput,
  FormLabel,
  createObjectModalStyle,
  defaultPanelItemStyle,
} from '../../../Organizations/styledComponents'

import {
  GET_SOURCE_REGIMENS,
  // GET_VEGA_REGIMENS,
  // GET_VEGA_REGIMENS is not currently used in polaris, but may be used in the future
  GET_VEGA_PRODUCTS_REGIMENS,
  GET_SOURCE_INDICATIONS,
  GET_SOURCE_TREATMENT_PLANS,
  GET_MARKET_BASKETS,
} from '../../../../api/queries'

import {
  CREATE_REGIMEN,
  UPDATE_SOURCE_REGIMEN,
  DELETE_SOURCE_REGIMEN,
} from '../../../../api/mutations'

import Color from '../../../../utils/color'
import Spacing from '../../../../utils/spacing'

const editIcon = <FontAwesomeIcon size="lg" icon={faEdit} />

const CREATE_BUTTON_TXT = 'Create Regimen'

const CREATE_MODAL_TITLE = 'Create New Regimen'

const buttonStyle = {
  background: Color.PRIMARY,
  color: Color.WHITE,
  fontWeight: 700,
}

const getInputFields = (state, handleChange) => {
  const products = state.input.products || []

  return (
    <>
      <div style={{ marginBottom: Spacing.S5 }}>
        <FormLabel>Name:</FormLabel>
        <StyledInput
          type="text"
          name="name"
          onChange={handleChange}
          value={state.input.name || ''}
        />
      </div>

      <div>
        <FormLabel>Products:</FormLabel>
        <ProductsSelect products={products} handleChange={handleChange} />
      </div>
    </>
  )
}

const headerChildren = (
  <div>
    <ModalButtonWithForm
      modalTitle={CREATE_MODAL_TITLE}
      buttonLabel={CREATE_BUTTON_TXT}
      buttonStyle={buttonStyle}
      modalStyle={createObjectModalStyle}
      mutationDoc={CREATE_REGIMEN}
      refetchQueries={[
        { query: GET_SOURCE_REGIMENS },
        // { query: GET_VEGA_REGIMENS },
        // GET_VEGA_REGIMENS is not currently used in polaris, but may be used in the future
        { query: GET_VEGA_PRODUCTS_REGIMENS },
      ]}
      getInputFields={getInputFields}
    />

    <CopyOneOfStringButton
      queryDoc={GET_SOURCE_REGIMENS}
      dataKey="regimens"
      datumKey="name"
    />
  </div>
)

const getButtonGroupCallback = (treatmentPlansByReg) => (regimen) => (
  <>
    <span
      style={{
        padding: Spacing.S2,
        margin: Spacing.S7,
        background: Color.LIGHT_BLUE_GRAY_1,
        borderRadius: 5,
        color: Color.ORION,
      }}
    >
      {`(${(treatmentPlansByReg[regimen.name] || []).length} Treatment Plans)`}
    </span>

    <ModalButtonWithForm
      modalTitle="Edit Regimen"
      buttonLabel={editIcon}
      buttonStyle={{ border: 'none', background: 'none', color: '#b6b9bc' }}
      modalStyle={createObjectModalStyle}
      data={{ input: regimen }}
      mutationDoc={UPDATE_SOURCE_REGIMEN}
      refetchQueries={[
        { query: GET_SOURCE_REGIMENS },
        // { query: GET_VEGA_REGIMENS },
        // GET_VEGA_REGIMENS is not currently used in polaris, but may be used in the future
        { query: GET_SOURCE_INDICATIONS },
        { query: GET_VEGA_PRODUCTS_REGIMENS },
        { query: GET_MARKET_BASKETS },
      ]}
      getInputFields={getInputFields}
    />

    <DeleteButton
      itemId={regimen._id}
      mutationDoc={DELETE_SOURCE_REGIMEN}
      refetchQueries={[
        { query: GET_SOURCE_REGIMENS },
        // { query: GET_VEGA_REGIMENS },
        // GET_VEGA_REGIMENS is not currently used in polaris, but may be used in the future
        { query: GET_SOURCE_INDICATIONS },
        { query: GET_VEGA_PRODUCTS_REGIMENS },
        { query: GET_MARKET_BASKETS },
      ]}
    />
  </>
)

const RegimensPanel = () => {
  const { data, loading } = useQuery(GET_SOURCE_TREATMENT_PLANS)

  if (loading) return null

  const { treatmentPlans } = data

  const treatmentPlansByReg = _.groupBy(treatmentPlans, 'regimen')

  const buttonGroupCallback = getButtonGroupCallback(treatmentPlansByReg)

  const panelItemConfig = {
    style: defaultPanelItemStyle,
    buttonGroupCallback,
    label1Callback: ({ name }) => name,
  }

  return (
    <Panel
      title="Regimens"
      headerChildren={headerChildren}
      headerContainerStyle={{
        background: Color.WHITE,
        borderBottom: `1px solid ${transparentize(0.9, Color.BLACK)}`,
      }}
      queryDocs={{
        fetchAllQueryProps: { query: GET_SOURCE_REGIMENS },
      }}
      panelItemConfig={panelItemConfig}
    />
  )
}

export default RegimensPanel
