import update from './update'
import onError from './onError'

const getMutationOptions = () => {
  return {
    update,
    onError,
  }
}

export default getMutationOptions
