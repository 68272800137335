import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const GET_TEAM_AUTH0_USERS = gql`
  query GetTeamAuth0Users($input: TeamAuth0UserInput!) {
    teamAuth0Users(input: $input) {
      ${schemaV0}
    }
  }
`
