import * as GQL_TAGS from './gql-tags'

export const getEmberNodeConfigs = (
  queryInput: any,
) => ({
  readConfig: {
    tag: GQL_TAGS.GET_EMBER_NODES,
    input: queryInput,
  },
})
