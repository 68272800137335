import { DocumentNode } from 'graphql'

import onError from '../../onError'
import { prependNewEntityToCache } from './prependNewEntityToCache'

export const getMutationOptions = (
  readTag?: DocumentNode,
  variables?: any
) => {
  return {
    update: (cache: any, data: any) =>
      prependNewEntityToCache({ cache, data, readTag, variables }),
    onError,
  }
}
