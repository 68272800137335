import React from 'react'

import { CreateModalButton } from 'frontend/components'
import { useSecondaryOrganizations } from 'frontend/entity-hooks'

import getFormConfig from './getFormConfig'

const ENTITY = 'Secondary Organization'

const CreateButton = () => {
  const { create, isCreating, options } = useSecondaryOrganizations()

  const formConfig = getFormConfig(options)

  const submit = (input: any, cancel: () => void): any => {
    const { committee } = input
    if (committee) {
      input.committee = committee.split(/\s*,\s*/)
    }
    create({ variables: { input } }).then(cancel)
  }

  return (
    <CreateModalButton
      isCreating={isCreating}
      entity={ENTITY}
      formConfig={formConfig}
      submit={submit}
    />
  )
}

export default CreateButton
