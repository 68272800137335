import styled from '@emotion/styled'

export const FormLabel = styled.label({
  fontSize: 12,
  fontWeight: 700,
  lineHeight: '20px',
})

export const BoldText = styled.span({
  fontWeight: 700,
})
