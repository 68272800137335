import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import Select from 'react-select'

import { UPDATE_VEGA_FDA_INDICATION_REGIMEN_APPROVAL } from 'frontend/api/mutations'

import { SingleActionDialog } from 'frontend/components/Dialog'
import Spinner from 'frontend/components/Spinner'
import Input from 'frontend/components/Input'

import {
  convertIsoShortToDateObj,
  formatDateMonthYearDash,
} from 'frontend/utils/formatDate'
import Spacing from 'frontend/utils/spacing'

import {
  InputSection,
  FormLabel,
} from 'frontend/Orion/SpecializedData/ValuePerception/MarketBaskets/MarketBasketDetail/Surveys/SurveyView/SurveyForms/utils'
import DeleteFdaApproval from './DeleteFdaApproval'

import useFdaApprovalData from './useFdaApprovalData'

const formatOption = ({ id, name }) => ({ value: id, label: name })
const formatArrayOfOptions = (data) =>
  data.map(({ id, name }) => ({ value: id, label: name }))

const LoadingIndicator = () => {
  return (
    <div style={{ paddingRight: '8px' }}>
      <Spinner />
    </div>
  )
}

const EditFdaApprovalForm = ({ selectedFdaApprovalData, closeHandler }) => {
  const {
    id,
    indication,
    regimen,
    populations,
    lines_of_therapy,
    approval_date,
  } = selectedFdaApprovalData

  const defaultIndication = indication ? formatOption(indication) : {}
  const defaultRegimen = regimen ? formatOption(regimen) : {}
  const defaultPopulations = populations
    ? formatArrayOfOptions(populations)
    : []
  const defaultLinesOfTherapy = lines_of_therapy
    ? formatArrayOfOptions(lines_of_therapy)
    : []
  const defaultApprovalDate = approval_date
    ? formatDateMonthYearDash(approval_date)
    : approval_date

  const [formData, setFormData] = useState({
    regimen: defaultRegimen,
    indication: defaultIndication,
    populations: defaultPopulations,
    lines_of_therapy: defaultLinesOfTherapy,
    approval_date: defaultApprovalDate,
  })

  const {
    indication: indicationData,
    regimen: regimenData,
    population: populationData,
    lineOfTherapy: lineOfTherapyData,
  } = useFdaApprovalData()

  const input = {
    id,
    indication_id: formData.indication.value,
    regimen_id: formData.regimen.value,
    populations_ids: formData.populations.map(({ value }) => value),
    lines_of_therapy_ids: formData.lines_of_therapy.map(({ value }) => value),
    approval_date: formData.approval_date
      ? convertIsoShortToDateObj(formData.approval_date)
      : null,
  }

  const [updateFdaApproval, { loading: isFdaApprovalLoading }] = useMutation(
    UPDATE_VEGA_FDA_INDICATION_REGIMEN_APPROVAL,
    {
      variables: { input },
      onError: alert,
      onCompleted: () => {
        closeHandler()
      },
    }
  )

  const onIndicationChange = (option) => {
    setFormData((prevState) => ({ ...prevState, indication: option }))
  }

  const onRegimenChange = (option) => {
    setFormData((prevState) => ({ ...prevState, regimen: option }))
  }

  const onPopulationsChange = (option) => {
    option = option || []
    setFormData((prevState) => ({ ...prevState, populations: option }))
  }

  const onLinesOfTherapyChange = (option) => {
    option = option || []
    setFormData((prevState) => ({ ...prevState, lines_of_therapy: option }))
  }

  const onDateChange = ({ value }) => {
    setFormData((prevState) => ({ ...prevState, approval_date: value }))
  }

  return (
    <SingleActionDialog
      header={'Edit FDA Approval'}
      submitText="Edit FDA Approval"
      submitHandler={updateFdaApproval}
      cancelHandler={closeHandler}
    >
      <div style={{ padding: `${Spacing.S4} ${Spacing.S7} ${Spacing.S7}` }}>
        {isFdaApprovalLoading ? (
          <Spinner />
        ) : (
          <div>
            <form>
              <InputSection>
                <FormLabel>Indication (required)</FormLabel>
                <Select
                  value={formData.indication}
                  options={indicationData.options}
                  onChange={onIndicationChange}
                  isLoading={indicationData.loading}
                  components={{ LoadingIndicator }}
                />
              </InputSection>
              <InputSection>
                <FormLabel>Regimen (required)</FormLabel>
                <Select
                  value={formData.regimen}
                  options={regimenData.options}
                  onChange={onRegimenChange}
                  isLoading={regimenData.loading}
                  components={{ LoadingIndicator }}
                />
              </InputSection>
              <InputSection>
                <FormLabel>Populations (required)</FormLabel>
                <Select
                  isMulti
                  value={formData.populations}
                  options={populationData.options}
                  onChange={onPopulationsChange}
                  isLoading={populationData.loading}
                  components={{ LoadingIndicator }}
                />
              </InputSection>
              <InputSection>
                <FormLabel>Lines of Therapy (required)</FormLabel>
                <Select
                  isMulti
                  value={formData.lines_of_therapy}
                  options={lineOfTherapyData.options}
                  onChange={onLinesOfTherapyChange}
                  isLoading={lineOfTherapyData.loading}
                  components={{ LoadingIndicator }}
                />
              </InputSection>
              <InputSection>
                <FormLabel>Approval Date (required)</FormLabel>
                <Input
                  name="approval_date"
                  type="date"
                  value={formData.approval_date}
                  onChange={onDateChange}
                />
              </InputSection>
            </form>
            <DeleteFdaApproval fdaApprovalId={id} closeHandler={closeHandler} />
          </div>
        )}
      </div>
    </SingleActionDialog>
  )
}

EditFdaApprovalForm.propTypes = {
  selectedFdaApprovalData: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
}

export default EditFdaApprovalForm
