import { useLocation } from 'react-router-dom'

export const useQueryStringParams = () => {
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const entityId = queryParams.get('id') ?? undefined
  const member = queryParams.get('member') ?? undefined
  const panel = queryParams.get('panel') ?? undefined

  return { entityId, member, panel }
}
