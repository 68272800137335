import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useQuery } from '@apollo/client'

import { Button } from '@pulse-analytics/pulse-design-system'

import { GET_VEGA_FDA_INDICATION_REGIMEN_APPROVALS } from 'frontend/api/queries'

import DataTable from 'frontend/components/DataTable'
import Header from 'frontend/components/Header'
import Caption from 'frontend/components/Caption'

import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'

import CreateFdaApprovalForm from './CreateFdaApprovalForm'
import EditFdaApprovalForm from './EditFdaApprovalForm'
import getFdaApprovalsColumns from './getFdaApprovalsColumns'

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const TitleSection = styled.section({
  display: 'flex',
  padding: Spacing.S7,
  alignItems: 'center',
})

const ButtonSection = styled.section({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `0 ${Spacing.S7}`,
  alignItems: 'center',
})

const FdaApprovalData = () => {
  const [selectedRowId, setSelectedRowId] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { data, loading } = useQuery(GET_VEGA_FDA_INDICATION_REGIMEN_APPROVALS)

  const closeHandler = () => {
    setSelectedRowId(null)
  }

  let selectedFdaApprovalData = {}
  if (selectedRowId) {
    const rowData = data.vegaFdaIndicationRegimenApprovals.find(
      ({ id }) => id === selectedRowId
    )
    if (rowData) selectedFdaApprovalData = rowData
  }

  const fdaApprovalsTableColumnsDependencies = { setSelectedRowId }
  const fdaApprovalsTableColumnsInjectables = getFdaApprovalsTableColumnsInjectables(
    fdaApprovalsTableColumnsDependencies
  )
  const fdaApprovalsTableData = getFdaApprovalsTableData(data)
  const fdaApprovalsTableColumns = getFdaApprovalsColumns(
    fdaApprovalsTableColumnsInjectables
  )

  return (
    <Container>
      <TitleSection>
        <Header
          header="FDA Approvals"
          subheader="Select a table row to view and edit an FDA Approval"
          headerStyle={{ ...FontSpace.FS5 }}
        />
      </TitleSection>
      <ButtonSection>
        <Caption>Click a row to edit or delete an FDA Approval</Caption>
        <Button
          onClick={() => setIsModalOpen(true)}
          style={{
            padding: `${Spacing.S2} ${Spacing.S3}`,
            margin: `${Spacing.S4} 0`,
          }}
        >
          + Create FDA Approval
        </Button>
      </ButtonSection>
      {isModalOpen && (
        <CreateFdaApprovalForm closeHandler={() => setIsModalOpen(false)} />
      )}
      {selectedRowId && (
        <EditFdaApprovalForm
          selectedFdaApprovalData={selectedFdaApprovalData}
          closeHandler={closeHandler}
        />
      )}

      <DataTable
        isLoading={loading}
        data={fdaApprovalsTableData}
        columns={fdaApprovalsTableColumns}
      />
    </Container>
  )
}

export default FdaApprovalData

const getFdaApprovalsTableColumnsInjectables = ({ setSelectedRowId }) => {
  const onRowClick = (row) => {
    const { original } = row
    setSelectedRowId(original.id)
  }
  return {
    onRowClick,
  }
}

const mapDataToNameField = (value) => value.map(({ name }) => name)

const getFdaApprovalsTableData = (data) => {
  if (!data) return []

  return data.vegaFdaIndicationRegimenApprovals.map(
    ({ indication, regimen, populations, lines_of_therapy, ...rest }) => ({
      indication: indication.name,
      regimen: regimen.name,
      populations: mapDataToNameField(populations),
      lines_of_therapy: mapDataToNameField(lines_of_therapy),
      ...rest,
    })
  )
}
