import { INPUT_TYPES } from 'frontend/components/Form/constants/input-type-to-component-map'

import { Colors } from 'frontend/utils/pulseStyles'

const { TEXT } = INPUT_TYPES

export const getFormConfig = () => [
  [
    {
      label: 'Client Name',
      accessor: 'name',
      inputType: TEXT,
      style: {
        backgroundColor: Colors.COOL_GRAY_100,
      },
    },
    {
      label: 'Icon',
      accessor: 'icon',
      inputType: TEXT,
      style: {
        backgroundColor: Colors.COOL_GRAY_100,
      },
    },
    {
      label: 'Note',
      accessor: 'tdg_internal_note',
      inputType: TEXT,
      style: {
        backgroundColor: Colors.COOL_GRAY_100,
      },
    },
  ],
]
