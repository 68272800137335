import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from '@apollo/client'
import Select from 'react-select'

import { GET_USER_TEAMS } from 'frontend/api/queries'
import { UPDATE_USER_PRIMARY_CLIENT_TEAM } from 'frontend/api/mutations'

import { SingleActionDialog } from 'frontend/components/Dialog'
import Spinner from 'frontend/components/Spinner'

import Spacing from 'frontend/utils/spacing'

import { InputSection, FormLabel, BlueText } from './utils'

const EditUserForm = ({ selectedUserData, closeHandler }) => {
  const {
    _id,
    username,
    primaryClientTeamVegaId,
    primaryClientTeam,
  } = selectedUserData
  const [formData, setFormData] = useState({
    primaryClientTeam: primaryClientTeamVegaId
      ? {
          label: primaryClientTeam,
          value: primaryClientTeamVegaId,
        }
      : {},
  })
  const [teamOptions, setTeamOptions] = useState([])

  const { data: teamsData, loading: teamsLoading } = useQuery(GET_USER_TEAMS, {
    variables: { userId: _id },
  })

  const [updateUser, { loading: mutationLoading }] = useMutation(
    UPDATE_USER_PRIMARY_CLIENT_TEAM,
    {
      variables: {
        input: {
          _id,
          primaryClientTeamVegaId: formData.primaryClientTeam.value,
        },
      },
      onError: alert,
      onCompleted: () => {
        closeHandler()
      },
    }
  )

  useEffect(() => {
    const teamOptions = getTeamOptions(teamsLoading, teamsData)
    setTeamOptions(teamOptions)
  }, [teamsData, _id])

  const handlePrimaryClientTeamChange = (value) => {
    setFormData((prevState) => ({ ...prevState, primaryClientTeam: value }))
  }

  const header = (
    <p>
      Edit <BlueText>{username}</BlueText> User
    </p>
  )

  return (
    <SingleActionDialog
      header={header}
      submitText="Edit User"
      submitHandler={updateUser}
      cancelHandler={closeHandler}
    >
      <div style={{ padding: Spacing.S7 }}>
        {mutationLoading ? (
          <div style={{ height: 236, textAlign: 'center' }}>
            <Spinner size={32} />
          </div>
        ) : (
          <div>
            <form>
              <InputSection>
                <FormLabel>Primary Client Team</FormLabel>
                <Select
                  onChange={handlePrimaryClientTeamChange}
                  value={formData.primaryClientTeam}
                  options={teamOptions}
                  isDisabled={teamsLoading}
                />
              </InputSection>
            </form>
          </div>
        )}
      </div>
    </SingleActionDialog>
  )
}

EditUserForm.propTypes = {
  selectedUserData: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
}

export default EditUserForm

function getTeamOptions(teamsLoading, teamsData) {
  let teamOptions = []

  if (!teamsLoading) {
    teamOptions = teamsData.teams.map(({ uuid, name }) => ({
      label: name,
      value: uuid,
    }))
  }

  return teamOptions
}
