import formatDataForExport from 'frontend/components/ExportExcelButton/formatDataForExport'

const EXPORT_FILENAME = 'CMS Provider Staffing'
const EXPORT_SHEETNAME = 'Staffing (CMS) Count'

const getCmsProviderExportConfig = (cmsProviderColumns: any) => {
  const formatExportRows = (rows: any) =>
    formatDataForExport({
      data: rows,
      columns: cmsProviderColumns,
      isReactTableData: true,
    })

  return {
    filename: EXPORT_FILENAME,
    sheetName: EXPORT_SHEETNAME,
    formatRows: formatExportRows,
  }
}

export default getCmsProviderExportConfig
