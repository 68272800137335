import React, { Dispatch, SetStateAction } from 'react'

import { useQueryString } from 'frontend/hooks'

import { LIST_HEADER } from '../../../enums'
import { List, ListHeader, ListItem } from '../../shared'

interface Props {
  indicationIdToLabel: { [key: string]: string }
  indications: any[]
  selectResource: (indication: string) => void
  setResources: Dispatch<SetStateAction<any>>
}

const InactiveList = ({
  indicationIdToLabel,
  indications,
  selectResource,
  setResources,
}: Props) => {
  const { indication } = useQueryString()

  const enableResource = (resource: any) => {
    setResources(({ treatmentPlans, ...rest }: any) => {
      treatmentPlans.push(resource)
      return {
        treatmentPlans,
        ...rest
      }
    })
  }

  return (
    <>
      <ListHeader
        listLength={indications.length}
        type={LIST_HEADER.INACTIVE}
      />
      <List>
        {indications.map((resource, index) => (
          <ListItem
            key={index}
            checked={false}
            label={indicationIdToLabel[resource.resource]}
            resource={resource}
            selectedEntity={indication}
            selectResource={selectResource}
            toggleResource={enableResource}
            type={LIST_HEADER.INACTIVE}
          />
        ))}
      </List>
    </>
  )
}

export default InactiveList
