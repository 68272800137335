import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import Select from 'react-select'

import { GET_REPORT_TOOL_PERMISSIONS } from 'frontend/api/queries'

import {
  DataTable,
  ReportsDataTableContainer,
  Spinner,
} from 'frontend/components'

import {
  PageHeaderContainer,
  PageHeader,
} from 'frontend/Orion/ImportExportData/styledComponents'

import useClientData from './useClientData'

import getColConfig from './getColConfig'

const customStyles = {
  container: ({ styles }: any) => ({ ...styles, width: '500px' }),
  menu: ({ styles }: any) => ({ ...styles, width: '500px' }),
}

const LoadingIndicator = () => {
  return (
    <div style={{ paddingRight: '8px' }}>
      <Spinner />
    </div>
  )
}

const ClientToolAccess = () => {
  const [clientName, setClientName] = useState<any>({})

  const { clients: clientsData } = useClientData()

  const {
    data: clientPermissionsData,
    loading: isClientPermissionLoading,
  } = useQuery(GET_REPORT_TOOL_PERMISSIONS, {
    variables: {
      input: { clientName: clientName.value },
    },
  })

  let tableData = []
  if (clientPermissionsData?.reportToolPermissions) {
    tableData = clientPermissionsData.reportToolPermissions.map(
      ({
        _id: {
          client,
          tool,
          indication,
          regimen,
        },
        teams,
      }: any) => {
        return {
          client,
          tool,
          indication,
          regimen,
          teams,
        }
      }
    )
  }

  const handleChange = (option: any) => {
    setClientName(option)
  }

  const colConfig = getColConfig()

  return (
    <ReportsDataTableContainer>
      <PageHeaderContainer>
        <PageHeader>Client Tool Access</PageHeader>
      </PageHeaderContainer>
      <Select
        styles={customStyles}
        value={clientName}
        options={clientsData.options}
        onChange={handleChange}
        isLoading={clientsData.loading}
        components={{ LoadingIndicator }}
      />
      <DataTable
        tableId={'client-tool-access-report-table'}
        isEditable={false}
        isLoading={isClientPermissionLoading}
        colConfig={colConfig}
        data={tableData}
        excelLabel={'Client Tool Access Report'}
      />
    </ReportsDataTableContainer>
  )
}
export default ClientToolAccess
