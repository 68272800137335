import gql from 'graphql-tag'

export const DELETE_SECONDARY_ORGANIZATION = gql`
  mutation DeleteSecondaryOrganization(
    $input: DeleteSecondaryOrganizationInput!
  ) {
    deleteSecondaryOrganization(input: $input) {
      id
      name
      type
      alias
      committees
      tdg_internal_note
      created_at
      updated_at
    }
  }
`
