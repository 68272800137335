import React from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { transparentize } from 'polished'

import { useRewriteSearchString } from 'frontend/hooks'
import { Colors } from 'frontend/utils/pulseStyles'

const Icon = styled(FontAwesomeIcon)({
  color: Colors.GRAY_300,
  cursor: 'pointer',
  marginLeft: 8,
  ':hover': {
    color: Colors.PRIMARY,
    background: transparentize(0.9, Colors.PRIMARY),
  },
})

const EditButton = ({ type, data }) => {
  const rewriteSearchString = useRewriteSearchString()

  const modifyQueryString = (e) => {
    e.stopPropagation()
    rewriteSearchString({
      node: data.id,
      nodeType: type,
    })
  }

  return (
    <button onClick={modifyQueryString}>
      <Icon icon={faEdit} size={'lg'} />
    </button>
  )
}

export default EditButton
