import { useMutation } from "@apollo/client"

import { UseMutationOptions } from './../interfaces'
import onError from "../onError"

export const useUpdate = ({ tag, readTag, mutationOptions = {} }: UseMutationOptions) => {
  return useMutation(tag, { 
    onError,
    ...mutationOptions
  })
}
