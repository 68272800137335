import React from 'react'
import Select from 'react-select'
import _ from 'lodash'

import getUniqueFilterOptions from './utils/getUniqueFilterOptions'
import getCoercedLabel from './utils/getCoercedLabel'

import { customTableSelectStyles } from './customTableSelectStyles'

const MultiSelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    return getUniqueFilterOptions(id, preFilteredRows)
  }, [id, preFilteredRows])

  const reactSelectOptions = options.map((option) => ({
    label: getCoercedLabel(option),
    value: option,
  }))

  const selectedOptions = !_.isEmpty(filterValue)
    ? filterValue.map((value) => ({
        value,
        label: getCoercedLabel(value),
      }))
    : null

  // Render a multi-select box
  return (
    <Select
      isMulti
      value={selectedOptions}
      options={reactSelectOptions}
      styles={customTableSelectStyles}
      onChange={(option) => {
        if (_.isEmpty(option)) {
          setFilter(undefined)
          return
        }

        const options = _.isArray(option) ? option : [option]

        setFilter(options.map(({ value }) => value))

        return option
      }}
    />
  )
}

export default MultiSelectColumnFilter
