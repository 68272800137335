import React from 'react'

import { useClient } from 'frontend/entity-hooks/ember'

import { Client } from './Client'

interface Props {
  onChange: any
}

export const Container = ({ onChange }: Props) => {
  const { data: clients }: any = useClient()

  const options = []
  for (const { id, name } of clients) {
    options.push({ label: name, value: id })
  }

  return <Client options={options} onChange={onChange} />
}
