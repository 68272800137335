import { useRewriteSearchString } from 'frontend/hooks'

export const useAddDeleteSearchString = () => {
  const rewriteSearchString = useRewriteSearchString()

  const addDeleteSearchString = ({ id }: { id: string }) => {
    const deleteSearchArgs = {
      deleteNccnPanelVotingMember: true,
      nccnPanelVotingMemberId: id,
    }
    rewriteSearchString(deleteSearchArgs)
  }

  return addDeleteSearchString
}
