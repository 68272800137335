import React, { Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors, Spacing } from 'frontend/utils/pulseStyles'

import AccountsListPanel from './AccountsListPanel'

interface Props {
  areParentResourcesLoading: boolean
  accounts: any[]
  parentResources: any[]
  setResources: Dispatch<SetStateAction<any>>
}

const Wrapper = styled.div({
  borderRight: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
  borderTop: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
  flex: 1,
  background: Colors.WHITE,
})

const ListHeader = styled.div({
  fontSize: 16,
  fontWeight: 700,
  lineHeight: '22px',
  padding: `${Spacing.NORMAL} ${Spacing.LARGE}`,
})

const Panel = (props: Props) => (
  <Wrapper>
    <ListHeader>
      Accounts
    </ListHeader>
    <div style={{ height: 'calc(100% - 46px)', overflowY: 'auto' }}>
      <AccountsListPanel
        {...props}
      />
    </div>
  </Wrapper>
)

export default Panel
