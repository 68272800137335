import React from 'react'

import {
  DataTable,
  ReportsDataTableContainer,
} from 'frontend/components'

import {
  PageHeaderContainer,
  PageHeader,
} from 'frontend/Orion/ImportExportData/styledComponents'

import useReportData from './useReportData'

import getColConfig from './getColConfig'

const Users = () => {
  const { reportUsers } = useReportData()

  const userData = reportUsers.data?.reportUsers ?? []

  const colConfig = getColConfig()

  return (
    <ReportsDataTableContainer>
      <PageHeaderContainer>
        <PageHeader>All Users</PageHeader>
      </PageHeaderContainer>
      <DataTable
        tableId={'user-report-table'}
        isEditable={false}
        isLoading={reportUsers.loading}
        colConfig={colConfig}
        data={userData}
        excelLabel={'User Report'}
      />
    </ReportsDataTableContainer>
  )
}

export default Users
