import React, { Dispatch, SetStateAction } from 'react'

import { Description } from './Description'
import { Teams } from './Teams'

interface Props {
  selectedClientId: string
  selectedTeams: [any]
  setFieldState: Dispatch<SetStateAction<any>>
}

export const InnerContainer = ({
  selectedClientId,
  selectedTeams,
  setFieldState,
}: Props) => {
  if (!selectedClientId)
    return <Description selectedClientId={selectedClientId} />

  return (
    <Teams
      selectedClientId={selectedClientId}
      selectedTeams={selectedTeams}
      setFieldState={setFieldState}
    />
  )
}
