import React from 'react'
import styled from '@emotion/styled'

import Header from './Header'
import Panels from './Panels'
import NodeModal from './NodeModal'

const Wrapper = styled.div({
  width: '100%',
})

const NodeManagement = () => {
  return (
    <Wrapper>
      <NodeModal />
      <Header />
      <Panels />
    </Wrapper>
  )
}

export default NodeManagement
