import React, { Dispatch, SetStateAction } from 'react'
import { useParams } from 'react-router-dom'

import { useTeamNodeResource } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'
import { NODE_TYPE_TO_PARENT_TYPE } from 'frontend/ClientManagement/Sitemap/constants'
import { Params } from 'frontend/ClientManagement/Sitemap/types'

import Panel from './Panel'
import ToggleAllButtons from './ToggleAllButtons'

interface Props {
  accounts: any[]
  setResources: Dispatch<SetStateAction<any>>
}

const Accounts = (props: Props) => {
  const { team }: Params = useParams()

  const queryString: any = useQueryString()
  const { nodeType } = queryString
  const parentType = NODE_TYPE_TO_PARENT_TYPE[nodeType]

  const {
    data: parentResources,
    isLoading: areParentResourcesLoading,
  }: any = useTeamNodeResource({
    queryInput: {
      nodeType: parentType,
      team,
      [parentType]: queryString[parentType],
    },
  })

  return (
    <>
      <ToggleAllButtons
        areParentResourcesLoading={areParentResourcesLoading}
        parentResources={parentResources}
        {...props}
      />
      <div style={{ display: 'flex', height: 'calc(100% - 156px)' }}>
        <Panel
          areParentResourcesLoading={areParentResourcesLoading}
          parentResources={parentResources}
          {...props}
        />
      </div>
    </>
  )
}

export default Accounts
