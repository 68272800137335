import { getColConfig } from './getColConfig'

const getInfluencersTableColumns = ({
  onPeopleClick,
  options,
  update,
  indicationLabelMap,
  indicationsOptions,
}) => {
  const updateFn = (input) => update({ variables: { input } })

  const colConfig = getColConfig({
    onPeopleClick,
    options,
    updateFn,
    indicationLabelMap,
    indicationsOptions,
  })

  return colConfig
}

export default getInfluencersTableColumns
