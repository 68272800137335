import React from 'react'

import { RequiredLabel } from './styled-components'

const RequiredTag = ({ isRequired }: { isRequired: boolean }) => {
  return isRequired
    ? <RequiredLabel> (required)</RequiredLabel>
    : null
}

export default RequiredTag
