import React from 'react'

import { POLICY_INFO_FORM_CONFIG } from './utils'
import { getOptionsForConfigs } from '../../../AddLinkModal/CreateForm'
import PolicyInfo from './PolicyInfo'

const PolicyInfoContainer = ({ data, policyOptions, input, setInput }) => {
  const configWithOptions = getOptionsForConfigs(
    policyOptions,
    POLICY_INFO_FORM_CONFIG
  )

  const handleInputSelection = (props) => {
    const { name, value } = props
    setInput({ ...input, [name]: value })
  }

  return (
    <PolicyInfo
      data={data}
      policyOptions={policyOptions}
      configWithOptions={configWithOptions}
      handleInputSelection={handleInputSelection}
      input={input}
    />
  )
}

export default PolicyInfoContainer
