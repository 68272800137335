import React from 'react'
import { Box } from '@mui/material'

export const getRenderValue = ({ selected, statusOptions }: any) => {
  return (
    <Box display="flex" alignItems="center">
      {statusOptions[selected as keyof typeof statusOptions]?.icon}
    </Box>
  )
}
