import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

const SuperHeader = styled.div({
  fontWeight: 500,
  color: Colors.COOL_GRAY_400,
  fontSize: 12,
  textTransform: 'uppercase',
})

const LabelGroup = () => {
  return (
    <div>
      <SuperHeader>Edit Team Role Nodes</SuperHeader>
    </div>
  )
}

export default LabelGroup
