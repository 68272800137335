import React from 'react'
import _ from 'lodash'

import { useQueryInput } from '../useQueryInput'
import UsersListPanelInnerContainer from './UsersListPanelInnerContainer'

const UsersListPanelOuterContainer = () => {
  const queryInput: any = useQueryInput()

  if (_.isEmpty(queryInput)) return null

  return <UsersListPanelInnerContainer />
}

export default UsersListPanelOuterContainer
