import React from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

import { GET_VEGA_TEAMS_REGIONS } from 'frontend/api/queries'
import { DELETE_VEGA_TEAM_REGION } from 'frontend/api/mutations'

import Spinner from 'frontend/components/Spinner'
import { SingleActionDialog } from 'frontend/components/Dialog'

import Color from 'frontend/utils/color'
import FontSpace from 'frontend/utils/fontspace'

import { BoldText } from '../utils'

const DeleteRegionForm = ({
  closeHandler,
  region,
  regions,
  handleListItemSearchUpdate,
}) => {
  const { clientTeamId } = useParams()

  const handleListItemDelete = () => {
    if (_.isEmpty(regions)) {
      handleListItemSearchUpdate({ teamRegionId: undefined })
    } else {
      handleListItemSearchUpdate({ teamRegionId: regions[0].id })
    }
  }

  const [deleteVegaTeamRegion, { loading: mutationLoading }] = useMutation(
    DELETE_VEGA_TEAM_REGION,
    {
      variables: { input: { id: region.id } },
      update: (cache) => {
        const { vegaTeamsRegions } = cache.readQuery({
          query: GET_VEGA_TEAMS_REGIONS,
          variables: { clientTeamId },
        })

        const newVegaTeamsRegions = vegaTeamsRegions.filter(
          ({ id }) => id !== region.id
        )

        cache.writeQuery({
          query: GET_VEGA_TEAMS_REGIONS,
          variables: { clientTeamId },
          data: { vegaTeamsRegions: newVegaTeamsRegions },
        })
      },
      onError: alert,
      onCompleted: () => {
        closeHandler()
        handleListItemDelete()
      },
    }
  )

  const handleClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    deleteVegaTeamRegion()
  }

  const shouldShowSpinner = mutationLoading

  return (
    <SingleActionDialog
      header="Delete Region"
      submitText="Delete Forever"
      submitHandler={handleClick}
      cancelHandler={closeHandler}
      headerStyle={{ color: Color.RED }}
      submitColor={Color.RED}
      contentStyle={{ width: 450 }}
    >
      {shouldShowSpinner ? (
        <Spinner />
      ) : (
        <div style={{ padding: 36, textAlign: 'center', ...FontSpace.FS3 }}>
          <p>
            Are you sure you want to delete
            <BoldText> {region.name}</BoldText>
          </p>
          <p style={{ fontWeight: 700, marginTop: 12 }}>
            THIS CANNOT BE UNDONE
          </p>
        </div>
      )}
    </SingleActionDialog>
  )
}

DeleteRegionForm.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  region: PropTypes.object.isRequired,
  regions: PropTypes.array.isRequired,
  handleListItemSearchUpdate: PropTypes.func.isRequired,
}

export default DeleteRegionForm
