import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import MarketBaskets from './MarketBaskets'
import MarketBasketDetail from './MarketBaskets/MarketBasketDetail'
import Stakeholders from './Stakeholders'
import StakeholderRoles from './StakeholderRoles'
import Providers from './Providers'
import Institutions from './Institutions'
import CommunityPracticeNetworks from './CommunityPracticeNetworks'
import Products from './Products'
import Manufacturers from './Manufacturers'
import ClientTeams from './ClientTeams'
import ClientTeamDetail from './ClientTeams/ClientTeamDetail'

const ValuePerception = () => (
  <Switch>
    <Route
      path={
        '/orion/specialized/value-perception/market-baskets/:marketBasketId'
      }
      component={MarketBasketDetail}
    />
    <Route
      path={'/orion/specialized/value-perception/market-baskets'}
      component={MarketBaskets}
    />
    <Route
      path={'/orion/specialized/value-perception/stakeholders'}
      component={Stakeholders}
    />
    <Route
      path={'/orion/specialized/value-perception/stakeholder-roles'}
      component={StakeholderRoles}
    />
    <Route
      path={'/orion/specialized/value-perception/providers'}
      component={Providers}
    />
    <Route
      path={'/orion/specialized/value-perception/institutions'}
      component={Institutions}
    />
    <Route
      path={'/orion/specialized/value-perception/community-practice-network'}
      component={CommunityPracticeNetworks}
    />
    <Route
      path={'/orion/specialized/value-perception/products'}
      component={Products}
    />
    <Route
      path={'/orion/specialized/value-perception/manufacturers'}
      component={Manufacturers}
    />
    <Route
      path={
        '/orion/specialized/value-perception/client-teams/:clientId/:clientTeamId'
      }
      component={ClientTeamDetail}
    />
    <Route
      path={'/orion/specialized/value-perception/client-teams'}
      component={ClientTeams}
    />
    <Redirect to={'/orion/specialized/value-perception/market-baskets'} />
  </Switch>
)

export default ValuePerception
