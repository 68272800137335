import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { ifSettingAllowed, PRIVILEGES } from '../toolPrivileges'
import AccountsPanel from './AccountsPanel'
import BooksPanel from './BooksPanel'

const AccountsTabContent = ({
  activeTool,
  booksOfBusiness,
  baseAccounts,
  accounts,
  setStagedAccounts,
}) => {
  const defaultSelectedAccount = () => {
    const hasSavedConfiguration = accounts.length > 0
    if (hasSavedConfiguration) {
      return accounts[0]
    } else {
      return baseAccounts[0]
    }
  }
  const toolName = activeTool.label.toLowerCase()
  const [selectedAccount, setSelectedAccount] = useState(defaultSelectedAccount)
  const [selectedBook, setSelectedBook] = useState(null)
  const enabledAccountsKeyHash = _.mapValues(
    _.keyBy(accounts, '_id'),
    ({ books }) => {
      return _.keyBy(books, '_id')
    }
  )

  const isBooksPanelAllowed = () => {
    return ifSettingAllowed(toolName, PRIVILEGES.BOOKS)
  }

  let enabledAccounts = accounts
  const disabledAccounts = _.filter(
    baseAccounts,
    ({ _id: accountId }) => !enabledAccountsKeyHash[accountId]
  )

  enabledAccounts = _.cloneDeep(enabledAccounts)

  let [enabledBooks, disabledBooks] = _.partition(
    booksOfBusiness,
    ({ _id: bookId }) =>
      enabledAccountsKeyHash[selectedAccount._id] &&
      enabledAccountsKeyHash[selectedAccount._id][bookId]
  )
  disabledBooks = _.sortBy(disabledBooks, ['name'])
  const accountObjFromTeam = accounts.find(
    ({ _id: accountId }) => accountId === selectedAccount._id
  )
  const hasActiveBooks = Boolean(
    accountObjFromTeam && accountObjFromTeam.hasOwnProperty('books')
  )
  if (hasActiveBooks) {
    const booksFromTeam = accountObjFromTeam.books

    enabledBooks = _.sortBy(accountObjFromTeam.books, [
      ({ _id }) => booksFromTeam.findIndex(({ _id: bookId }) => bookId === _id),
    ])
  }

  const getDefaultBookByAccount = (accountId) => {
    const activeAccount = enabledAccounts.find(
      (account) => account._id === accountId
    )

    const hasActiveBook = Boolean(
      _.size(activeAccount) > 0 &&
        activeAccount.hasOwnProperty('books') &&
        activeAccount.books.length > 0
    )

    const defaultBook = hasActiveBook
      ? activeAccount.books[0]
      : booksOfBusiness[0]

    return defaultBook
  }
  useEffect(() => {
    if (isBooksPanelAllowed()) {
      const defaultBook = getDefaultBookByAccount(selectedAccount._id)
      setSelectedBook(defaultBook)
    }
  }, [selectedAccount])

  return (
    <>
      <div style={{ display: 'flex' }}>
        <AccountsPanel
          selectedAccountId={selectedAccount._id}
          enabledAccounts={enabledAccounts}
          disabledAccounts={disabledAccounts}
          selectAccount={setSelectedAccount}
          setStagedAccounts={setStagedAccounts}
          isBooksPanelAllowed={isBooksPanelAllowed()}
          booksOfBusiness={booksOfBusiness}
        />
        {isBooksPanelAllowed() && (
          <>
            <div style={{ flex: 1 }} />
            <BooksPanel
              selectedAccountId={selectedAccount._id}
              selectedBookId={selectedBook?._id}
              selectBook={setSelectedBook}
              setStagedAccounts={setStagedAccounts}
              enabledBooks={enabledBooks}
              disabledBooks={disabledBooks}
              baseAccounts={baseAccounts}
              accounts={accounts}
              enabledAccounts={enabledAccounts}
            />
          </>
        )}
      </div>
    </>
  )
}

export default AccountsTabContent
