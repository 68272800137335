import { useMutation } from '@apollo/client'

import { GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS } from 'frontend/api/queries'
import {
  CREATE_PATHWAYS_INDICATION_REGIMEN_POSITION,
  UPDATE_PATHWAYS_INDICATION_REGIMEN_POSITION,
} from 'frontend/api/mutations'
import useDataTableDataRefresh from 'frontend/hooks/useDataTableDataRefresh'

const usePositioningData = (tableId) => {
  const {
    data: pathwaysPositionsData,
    loading: pathwaysPositionsLoading,
    emitCellUpdateCompleted,
    emitCellUpdateError,
    emitDataShouldUpdate,
  } = useDataTableDataRefresh({
    updateId: 'orion-organizations-pathways_positioning',
    gqlQuery: GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS,
    tableId,
  })

  const [createPathwaysPosition] = useMutation(
    CREATE_PATHWAYS_INDICATION_REGIMEN_POSITION,
    {
      onCompleted: ({ createPathwaysIndicationRegimenPosition }) => {
        emitDataShouldUpdate(tableId)
        alert('Pathways Position created.')
      },
      onError: alert,
    }
  )
  const [updatePathwaysPosition] = useMutation(
    UPDATE_PATHWAYS_INDICATION_REGIMEN_POSITION,
    {
      onCompleted: (
        { updatePathwaysIndicationRegimenPosition },
        { variables: { input } }
      ) => {
        emitCellUpdateCompleted({ input, tableId })
        emitDataShouldUpdate(tableId)
      },
      onError: (error, { variables: { input } }) => {
        emitCellUpdateError({ input, tableId })
      },
    }
  )

  return {
    data: pathwaysPositionsData,
    loading: pathwaysPositionsLoading,
    create: createPathwaysPosition,
    update: updatePathwaysPosition,
  }
}

export default usePositioningData
