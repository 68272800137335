import { gql } from '@apollo/client'
import { schemaV1 } from './schemas'

export const GET_PRODUCT_POLICY_LINKS = gql`
  query GetProductPolicyLinks($input: ProductPolicyLinksInput) {
    productPolicyLinks(input: $input) {
      ${schemaV1}
    }
  }
`
