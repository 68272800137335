import { gql } from '@apollo/client'
import { schemaV1 } from './schemas'

export const CREATE_PAYER_POLICY_LINK = gql`
  mutation CreatePayerPolicyLink($input: CreatePayerPolicyLinkInput!) {
    createPayerPolicyLink(input: $input) {
      ${schemaV1}
    }
  }
`
