import React from 'react'

import StyledTab from '../Tab'
import Icon from './Icon'

interface Props {
  activeTab: string
}

const MostRecent = (props: Props) => (
  <StyledTab value="mostRecent">
    <Icon
      {...props}
    />
    Most Recent
  </StyledTab>
)

export default MostRecent
