import { useFlags } from 'launchdarkly-react-client-sdk'

import AddLinkModal from './AddLinkModal'
import ManageProjectsModal from './ManageProjectsModal'
import PdfViewer from './PolicyViewerModal/PolicyViewerModal'
import DeleteLinkModal from './DeleteLinkModal'
import OldPolicyViewerModal from './OldPolicyViewerModal'

import {
  ADD_LINK_MODAL,
  MANAGE_PROJECTS_MODAL,
  POLICY_VIEW_MODAL,
  DELETE_LINK_MODAL,
} from './util'

const MOST_RECENT_POLICY_MODAL = 'viewMostRecentPolicy'

export const useModalMap = () => {
  const FLAGS = useFlags()

  return {
    [ADD_LINK_MODAL]: AddLinkModal,
    [MANAGE_PROJECTS_MODAL]: ManageProjectsModal,
    [POLICY_VIEW_MODAL]: FLAGS[MOST_RECENT_POLICY_MODAL]
      ? PdfViewer
      : OldPolicyViewerModal,
    [DELETE_LINK_MODAL]: DeleteLinkModal,
  }
}
