import React from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@pulse-analytics/pulse-design-system'

import { SEND_PAYER_POLICY_UPDATES } from 'frontend/api/mutations'

const MERCK_ID = '86cd12ba-efcb-4b23-88d5-6cf22be59e5d'
const REGENERON_ID = '563b70df-9f98-48a6-b1bb-ec1d164759a7'

const PayerPolicyEmails = () => {
  const [sendEmails] = useMutation(SEND_PAYER_POLICY_UPDATES, {
    onError: alert,
  })

  const sendEmailsWithInput = (product_payer_policy_tracking_project) => {
    const input = {
      variables: {
        input: {
          product_payer_policy_tracking_project,
        },
      },
    }
    return sendEmails(input)
  }

  return (
    <div>
      <div>
        <Button
          onClick={() => sendEmailsWithInput(MERCK_ID)}
          style={{
            padding: '8px 12px',
            margin: '10px',
          }}
        >
          Send Merck Emails
        </Button>
      </div>
      <div>
        <Button
          onClick={() => sendEmailsWithInput(REGENERON_ID)}
          style={{
            padding: '8px 12px',
            margin: '10px',
          }}
        >
          Send Regeneron Emails
        </Button>
      </div>
    </div>
  )
}

export default PayerPolicyEmails
