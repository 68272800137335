import _ from 'lodash'

import {
  SingleSelectFilter,
  MultiSelectFilter,
  DateMultiSelectFilter,
  CondensedSingleSelectFilter,
  CondensedMultiSelectFilter,
  CondensedDateMultiSelectFilter,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'
import {
  DISPLAY_CELL_TYPES,
  EDIT_CELL_TYPES,
  SORT_TYPES,
  FILTER_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'

import {
  mapIdToLabelCellFormatter,
  mapIdsToLabelsCellFormatter,
} from '../Positioning/utils/cellFormatterUtils'

const { STRING, DATE, TEXT_ARRAY } = SORT_TYPES
const { DATE_MULTI_SELECT, MULTI_SELECT, SINGLE_SELECT } = FILTER_TYPES
const {
  BUTTON,
  CONDENSED_BUTTON,
  EXPAND,
  CONDENSED_EXPAND,
} = DISPLAY_CELL_TYPES
const {
  TEXT_INPUT,
  CONDENSED_TEXT_INPUT,
  DATE_INPUT,
  CONDENSED_DATE_INPUT,
  SINGLE_SELECT_INPUT,
  CONDENSED_SINGLE_SELECT_INPUT,
  MULTI_SELECT_INPUT,
  CONDENSED_MULTI_SELECT_INPUT,
} = EDIT_CELL_TYPES

const testOnClick = (rowData) => {
  alert(
    _.sample(['😱 ', '😀 ', '🥳 ']).concat(JSON.stringify(rowData, null, 4))
  )
}

const getPositioningTableColumns = ({
  isCondensed,
  onHistoryButtonClick,
  onEdit,
  pathwaysOrgs,
  indications,
  regimens,
  populations,
  lines,
  cancerStages,
  positionStatus,
  relativeAccess,
}) => [
  {
    Header: 'History and Date',
    columns: [
      {
        Header: 'History',
        accessor: 'history',
        displayCellConfig: {
          type: isCondensed ? CONDENSED_BUTTON : BUTTON,
          label: 'History',
          onClick: testOnClick,
        },
        width: 60,
      },
      {
        Header: 'Position Date',
        accessor: 'position_date',
        sortType: DATE,
        cellValueFormatter: (value) => {
          if (_.isEmpty(value)) return '--'
          return value
        },
        Filter: isCondensed
          ? CondensedDateMultiSelectFilter
          : DateMultiSelectFilter,
        filter: DATE_MULTI_SELECT,
        width: 200,
        editCellConfig: {
          type: isCondensed ? CONDENSED_DATE_INPUT : DATE_INPUT,
          updateFn: onEdit,
        },
      },
    ],
  },
  {
    Header: 'Organization and Indication',
    columns: [
      {
        Header: 'Pathways Organization',
        accessor: 'pathways_organization',
        sortType: STRING,
        cellValueFormatter: (value) => {
          if (_.isNumber(value) || value === '10') return value
          if (_.isEmpty(value)) return '--'
          return mapIdToLabelCellFormatter(value, pathwaysOrgs.labelMap)
        },
        Filter: isCondensed ? CondensedSingleSelectFilter : SingleSelectFilter,
        filter: SINGLE_SELECT,
        width: 200,
        displayCellConfig: {
          type: isCondensed ? CONDENSED_EXPAND : EXPAND,
          onClick: testOnClick,
        },
      },
    ],
  },
  {
    Header: 'Cancer Stages',
    accessor: 'cancer_stages',
    sortType: 'textArray',
    cellValueFormatter: (value) =>
      mapIdsToLabelsCellFormatter(value, cancerStages.labelMap),
    editCellConfig: {
      type: isCondensed ? CONDENSED_MULTI_SELECT_INPUT : MULTI_SELECT_INPUT,
      options: cancerStages.options,
      validationConfig: {
        validationFn: (options) => {
          return Boolean(options.length)
        },
        errorMessage: 'Cannot be empty',
      },
      updateFn: onEdit,
    },
    Filter: isCondensed ? CondensedMultiSelectFilter : MultiSelectFilter,
    filter: MULTI_SELECT,
    width: 250,
  },
  {
    Header: 'Position Status',
    accessor: 'position_status',
    sortType: STRING,
    cellValueFormatter: (value) =>
      mapIdToLabelCellFormatter(value, positionStatus.labelMap),
    editCellConfig: {
      type: isCondensed ? CONDENSED_SINGLE_SELECT_INPUT : SINGLE_SELECT_INPUT,
      options: positionStatus.options,
      validationConfig: {
        validationFn: (option) => {
          return Boolean(option)
        },
        errorMessage: 'Cannot be empty',
      },
      updateFn: onEdit,
    },
    Filter: isCondensed ? CondensedMultiSelectFilter : MultiSelectFilter,
    filter: MULTI_SELECT,
    width: 250,
  },
  {
    Header: 'Alert Note',
    accessor: 'alert_note',
    sortType: STRING,
    editCellConfig: {
      type: isCondensed ? CONDENSED_TEXT_INPUT : TEXT_INPUT,
      updateFn: onEdit,
    },
    width: 250,
  },
  {
    Header: 'Populations',
    accessor: 'populations',
    sortType: TEXT_ARRAY,
    cellValueFormatter: (value) =>
      mapIdsToLabelsCellFormatter(value, populations.labelMap),
  },
  {
    Header: 'FDA Lines',
    accessor: 'fda_indication_regimen_approval.lines_of_therapy',
    sortType: STRING,
    cellValueFormatter: (value) =>
      mapIdsToLabelsCellFormatter(value, lines.labelMap),
  },
  {
    Header: 'Lines Of Therapy',
    accessor: 'lines_of_therapy',
    sortType: STRING,
    cellValueFormatter: (value) =>
      mapIdsToLabelsCellFormatter(value, lines.labelMap),
  },
  {
    Header: 'Relative Access',
    accessor: 'relative_access',
    sortType: STRING,
    cellValueFormatter: (value) =>
      mapIdToLabelCellFormatter(value, relativeAccess.labelMap),
  },

  {
    Header: 'Alert Date',
    accessor: 'alert_date',
    sortType: STRING,
  },
]

export default getPositioningTableColumns
