import React from 'react'

import { Colors } from 'frontend/utils/pulseStyles'

const Message = ({ error, index }: { error: string, index: number }) => {
  const [email, message] = error.split('|')
  index += 1
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '12px 0' }}>
      <span>
        <span style={{ paddingRight: 12 }}>
          {`${index}.`}
        </span>
        <span>
          {email}
        </span>
      </span>
      <span style={{ color: Colors.RED_900, paddingRight: 12 }}>
        {message}
      </span>
    </div>
  )
}

export default Message
