import { formatMonthDayYearSlash } from 'frontend/utils/formatDate'

export const getExportConfig = () => [
  {
    Header: 'Payer',
    accessor: 'payer_organization_name',
  },
  {
    Header: 'Book',
    accessor: 'book_of_business_name',
  },
  {
    Header: 'Product(s)',
    accessor: 'product_names',
  },
  {
    Header: 'Benefit Type',
    accessor: 'benefit_type',
    cellValueFormatter: snakeToTitleCase,
  },
  {
    Header: 'Policy Status',
    accessor: 'product_policy_status',
    cellValueFormatter: snakeToTitleCase,
  },
  {
    Header: 'Instruction Status',
    accessor: 'instruction_status',
    cellValueFormatter: snakeToTitleCase,
  },
  {
    Header: 'Payer Website Link',
    accessor: 'payer_website_link',
  },
  {
    Header: 'Last Change Date (Scraper)',
    accessor: 'updated_at',
    cellValueFormatter: formatMonthDayYearSlash,
  },
  {
    Header: 'Current Policy Link',
    accessor: 'link',
  },
]

const snakeToTitleCase = (str: string) =>
  str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
