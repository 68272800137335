import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const CREATE_USER = gql`
  mutation CreateUser($input: CreateEmberUserInput!) {
    createEmberUser(input: $input) {
      ${schemaV0}
    }
  }
`
