import React from 'react'
import styled from '@emotion/styled'

import { NodeState } from '../types'
import ButtonGroup from './ButtonGroup'
import Title from './Title'

interface Props {
  closeModal: () => void
  nodeState: NodeState
}

const Wrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  padding: '20px',
})

const Header = (
  props: Props,
) => (
  <Wrapper>
    <Title />
    <ButtonGroup
      {...props}
    />
  </Wrapper>
)

export default Header
