import React from 'react'
import styled from '@emotion/styled'
import { mix } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

type type = 'active' | 'inactive'

interface ListHeaderProps {
  listLength: number
  type: type
}

interface WrapperProps {
  type: type
}

const TYPE_TO_BACKGROUND_COLOR = {
  active: mix(0.8, Colors.WHITE, Colors.GREEN),
  inactive: mix(0.8, Colors.WHITE, Colors.MEDIUM_GRAY_2),
}

const TYPE_TO_COLOR = {
  active: Colors.GREEN,
  inactive: Colors.MEDIUM_GRAY_2,
}

const Wrapper = styled.div({
  fontSize: 10,
  fontWeight: 700,
  lineHeight: '18px',
  padding: '8px 24px',
  position: 'sticky',
  textTransform: 'uppercase',
  top: 0,
  zIndex: 5,
}, ({ type }: WrapperProps) => ({
  backgroundColor: TYPE_TO_BACKGROUND_COLOR[type],
  color: TYPE_TO_COLOR[type],
}))

export const ListHeader = ({
  listLength,
  type,
}: ListHeaderProps) => {
  return (
    <Wrapper
      type={type}
    >
      {`${type} (${listLength})`}
    </Wrapper>
  )
}
