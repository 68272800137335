import React, { Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'

import NodesPanel from './NodesPanel'
import { ParentType, Sitemap, Type } from '../types'

interface Panel {
  type: Type
  parentType?: ParentType
}

interface Props {
  sitemap?: Sitemap
  setSitemap: Dispatch<SetStateAction<Sitemap | undefined>>
}

const PANELS: Panel[] = [
  {
    type: 'tool',
  },
  {
    type: 'dashboard',
    parentType: 'tool',
  },
  {
    type: 'page',
    parentType: 'dashboard',
  },
  {
    type: 'card',
    parentType: 'page',
  },
]

const Wrapper = styled.div({
  display: 'flex',
})

const Panels = (props: Props) => {
  return (
    <Wrapper>
      {PANELS.map((panel, index) => (
        <NodesPanel
          key={index}
          {...panel}
          {...props}
        />
      ))}
    </Wrapper>
  )
}

export default Panels
