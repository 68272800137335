import MultiSelectColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/MultiSelectColumnFilter'
import customMultiSelectFilterFn from 'frontend/components/Table/custom-filters/MultiSelect/customMultiSelectFilterFn'

const getCmsProviderColumns = () => [
  {
    Header: 'Slug',
    accessor: 'slug',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    width: 200,
  },
  {
    Header: 'Provider',
    accessor: 'provider',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    width: 400,
  },
  {
    Header: 'Specialty',
    accessor: 'spec',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    width: 400,
  },
  {
    Header: 'Specialty Count',
    accessor: 'specSlugCount',
    sortType: 'float',
    width: 300,
  },
]

export default getCmsProviderColumns
