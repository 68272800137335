import React from 'react'

import {
  ListHeader,
  ListTitle,
  StyledNavHeader,
} from './../shared/styledComponents'

import { CreateButton } from './Buttons'

const LISTS_PANEL_LABEL = 'Lists / '

const ListsPanelListHeader = ({ title, handleClick }) => {
  return (
    <ListHeader>
      <ListTitle>
        <span>{LISTS_PANEL_LABEL}</span>
        <StyledNavHeader>{title}</StyledNavHeader>
      </ListTitle>
      <CreateButton handleClick={handleClick} />
    </ListHeader>
  )
}

export default ListsPanelListHeader
