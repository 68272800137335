import { gql } from '@apollo/client'
import { schemaV1 } from './schemas'

export const CREATE_PRODUCT_POLICY_LINK = gql`
  mutation CreateProductPolicyLink($input: CreateProductPolicyLinkInput!) {
    createProductPolicyLink(input: $input) {
      ${schemaV1}
    }
  }
`
