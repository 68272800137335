import { useEffect, useState } from 'react'

import { unwrapGqlData } from 'frontend/utils'
import * as CRUD_UTILS from 'frontend/api/utils'

import { GET_VEGA_STATES } from './gql-tags'

export const useStates = () => {
  const [data, setData] = useState()

  const {
    data: gqlData,
    loading: isLoading,
  } = CRUD_UTILS.useRead({ tag: GET_VEGA_STATES })

  useEffect(() => {
    if (!gqlData) return
    const newData = unwrapGqlData(gqlData)
    setData(newData)
  }, [gqlData])

  return {
    data,
    isLoading,
  }
}
