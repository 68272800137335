import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import _ from 'lodash'

import { useRewriteSearchString } from 'frontend/hooks'

export const ListPanel = ({
  data,
  searchStringKey,
  searchStringAccessor,
  ListItem,
}: {
  data: any
  searchStringKey: string
  searchStringAccessor: string
  ListItem: any
}) => {
  const location = useLocation()
  const currentSearchParams =
    (location.search && queryString.parse(location.search)) || {}
  const selectedListItem = currentSearchParams[searchStringKey]

  const selectListItem = useRewriteSearchStringParam(
    searchStringKey,
    searchStringAccessor
  )

  const useSetFirstListItem = () => {
    if (!_.isEmpty(data) && !selectedListItem) {
      const [firstListItem] = data
      selectListItem(firstListItem)
    }
  }

  useEffect(useSetFirstListItem)

  return (
    <div style={{ height: '100%', overflowY: 'auto' }}>
      {data.map((datum: any) => {
        const isSelected =
          datum[searchStringAccessor] === currentSearchParams[searchStringKey]

        return (
          <ListItem
            key={datum[searchStringAccessor]}
            isSelected={isSelected}
            data={datum}
            selectListItem={selectListItem}
          />
        )
      })}
    </div>
  )
}

const useRewriteSearchStringParam = (
  searchStringKey: string,
  searchStringAccessor: string
) => {
  const rewriteSearchString = useRewriteSearchString()
  const rewriteSearchStringParam = (data: any) =>
    rewriteSearchString({ [searchStringKey]: data[searchStringAccessor] })

  return rewriteSearchStringParam
}
