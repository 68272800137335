import { useMutation } from "@apollo/client"

import readTag from '../useRead/gql-tag'
import tag from "./gql-tag"

const useDelete = (deleteOptions: any = {}) => {
  const options = {
    update,
    onError: alert,
    ...deleteOptions
  }
  return useMutation(tag, options)
}

export default useDelete

const update = (
  cache: any,
  { data: { deletePolarisUser } }: any,
) => {
  const { polarisUsers } = cache.readQuery({
    query: readTag,
  })

  const newPolarisUsers = polarisUsers.filter(
    ({ user_id }: any) => (user_id !== deletePolarisUser.user_id)
  )

  cache.writeQuery({
    query: readTag,
    data: {
      polarisUsers: newPolarisUsers,
    },
  })
}
