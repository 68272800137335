import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import styled from '@emotion/styled'
import { useQuery } from '@apollo/client'

import { GET_ORGANIZATION_TYPES } from 'frontend/api/queries'
import Spinner from 'frontend/components/Spinner'
import manualBoModalLockOverlay from 'frontend/components/BusinessObjectModal/shared/widget/manualBoModalLockOverlay'

import ConnectionListItem from './ConnectionListItem'
import ConnectionsList from '../../../shared/widget/orgInfluencerConnectionComponents/ConnectionsList'
import OrganizationConnectionWidgetContainer from './OrganizationConnectionWidgetContainer'
import { INFLUENCER_ALERT_TYPE } from '../../../shared/widget/alert-types'
import CreateButton from './CreateButton'
import PathwaysInfluencerForm from '../../../shared/widget/PathwaysInfluencersForm'
import { usePathwaysInfluencerData } from 'frontend/hooks/usePathwaysInfluencersData'
import useMongoVegaBridge from 'frontend/hooks/useMongoVegaBridge'

const WidgetContainer = styled.div({
  display: 'flex',
  width: '100%',
})

const ORG_TYPE_TO_FORM_GENERATOR_MAP = {
  Pathways: (personId) => ({
    personId,
    organization: `New Pathways Organization Connection`,
    organizationType: 'Pathways',
    indicationIds: [],
    pathwaysInfluencerTypes: [],
    tumorTypeSpecialty: '',
    internalFields: {
      internalNotes: '',
      pathwaysManagementTypes: [],
      valueChairsIndications: [],
    },
    position: '',
    priority: null,
    alert: {
      date: null,
      type: INFLUENCER_ALERT_TYPE,
      description: '',
    },
    exclusionSettings: {
      isExcluded: false,
      reason: '',
    },
    startDate: null,
    endDate: null,
    startQuarter: null,
    endQuarter: null,
  }),
}

const FORM_CONFIG = {
  Form: PathwaysInfluencerForm,
  refKey: 'pathwaysId',
}

const WIDGET_TITLE = 'Organization Connections'

const OrganizationConnectionsWidget = ({ entity }) => {
  const { data: bridgeData } = useMongoVegaBridge({
    mongoId: entity._id,
  })

  const isBridgeDataPresent = !_.isEmpty(bridgeData)

  const personId = isBridgeDataPresent ? bridgeData[0].vegaId : undefined

  const { data: organizationTypeData, loading: orgTypeLoading } = useQuery(
    GET_ORGANIZATION_TYPES
  )

  const { pathwaysInfluencers } = usePathwaysInfluencerData()
  const {
    data: pathwaysInfluencersData,
    loading: isPathwaysInfluencersLoading,
  } = pathwaysInfluencers

  const connectionsData = pathwaysInfluencersData.filter(
    (pathwayInfluencer) => pathwayInfluencer.person === personId
  )

  const connectionsLoading = isPathwaysInfluencersLoading

  const [selectedConnection, changeConnection] = useState({})
  const [
    isNewConnectionBeingCreated,
    setWhetherNewConnectionBeingCreated,
  ] = useState(false)
  const [anyUnsavedChanges, setWhetherUnsavedChanges] = useState(false)

  useEffect(() => {
    if (!orgTypeLoading && !connectionsLoading && connectionsData.length) {
      changeConnection(connectionsData[0])
    }
  }, [connectionsLoading, orgTypeLoading])

  if (orgTypeLoading || connectionsLoading) return <Spinner size={28} />

  const { organizationTypes } = organizationTypeData

  manualBoModalLockOverlay(anyUnsavedChanges)

  const createOrgConnectionHandler = (orgType) => {
    if (anyUnsavedChanges) {
      alert(
        "You have unsaved changes! Please save or cancel the connection you're on."
      )
    } else {
      const stagedNewOrg = ORG_TYPE_TO_FORM_GENERATOR_MAP[orgType](personId)
      setWhetherNewConnectionBeingCreated(true)
      changeConnection(stagedNewOrg)
    }
  }

  const createButton = (
    <CreateButton
      clickHandler={createOrgConnectionHandler}
      organizationTypes={organizationTypes}
    />
  )

  const { pathwaysDatum } = selectedConnection || {}
  const { name } = pathwaysDatum || {}

  return (
    <WidgetContainer>
      <ConnectionsList
        widgetTitle={WIDGET_TITLE}
        isNewConnectionBeingCreated={isNewConnectionBeingCreated}
        createButton={createButton}
        ConnectionListItem={ConnectionListItem}
        connectionsData={connectionsData}
        selectedConnection={selectedConnection}
        changeConnection={changeConnection}
        anyUnsavedChanges={anyUnsavedChanges}
      />
      {isBridgeDataPresent ? (
        <OrganizationConnectionWidgetContainer
          entityId={personId}
          title={name}
          formConfig={FORM_CONFIG}
          selectedConnection={selectedConnection}
          changeConnection={changeConnection}
          setWhetherNewConnectionBeingCreated={
            setWhetherNewConnectionBeingCreated
          }
          isNewConnectionBeingCreated={isNewConnectionBeingCreated}
          connectionsData={connectionsData}
          setWhetherUnsavedChanges={setWhetherUnsavedChanges}
        />
      ) : (
        <Spinner />
      )}
    </WidgetContainer>
  )
}

OrganizationConnectionsWidget.propTypes = {
  entity: PropTypes.object.isRequired,
}

export default OrganizationConnectionsWidget
