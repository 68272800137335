export const PROVIDER_TYPE_MAP = {
  '': 'No Type',
  institution: 'Institution',
  community_practice: 'Community Practice',
}

export const PROVIDER_TYPE_OPTIONS = [
  { value: '', label: 'No Type' },
  { value: 'institution', label: 'Institution' },
  { value: 'community_practice', label: 'Community Practice' },
]

export const NO_CPN_OPTION = {
  label: 'No Community Practice Network',
  value: null,
}
