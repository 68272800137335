import { useEffect, useState } from 'react'
import { OptionTypeBase } from 'react-select'
import { useQuery } from '@apollo/client'

import { Indication } from 'frontend/interfaces'

import { GET_INDICATIONS } from './gql-tags'

export const useIndications = () => {
  const { data, error, loading: isLoading } = useQuery(GET_INDICATIONS)
  const [state, setState]: any = useState({
    labelMap: {},
    options: [],
  })

  useEffect(() => {
    if (!data) return

    const labelMap: any = {}
    const options: OptionTypeBase[] = []
    data.vegaIndications.forEach(({ id, name }: Indication) => {
      labelMap[id] = name
      const option = { label: name, value: id }
      options.push(option)
    })

    setState({
      labelMap,
      options,
    })
  }, [data])

  return {
    data,
    error,
    isLoading,
    labelMap: state.labelMap,
    options: state.options,
  }
}
