import { useHistory } from 'react-router-dom'

import queryString from 'query-string'
import { URL_PARAM_KEY } from './url-param-key'

export const useExpandOnClick = () => {
  const history = useHistory()

  const onExpandClick = (rowData: any) => {
    if (!rowData || !rowData.id) return

    const updateParam = queryString.stringify({
      [URL_PARAM_KEY]: true,
      id: rowData.id,
      member: rowData.member,
      panel: rowData.panel,
    })
    history.push({ search: updateParam })
  }

  return onExpandClick
}
