import React from 'react'
import _ from 'lodash'
import MetaDataList from 'frontend/components/MetaHistory/MetaDataList'
import { KEY_TO_LABEL } from './key-label-map'


const MetaDataListContainer = ({ metaHistory }: any) => {
  if (_.isEmpty(metaHistory)){
    return <NoHistoryMessage />
  }
  
  return (
      <MetaDataList data={metaHistory} keyToLabel={KEY_TO_LABEL} />
  )
}

export default MetaDataListContainer



const NoHistoryMessage = () => {
  return (
    <div style={{ padding: 24 }}>
      New unsaved connection doesn't have history
    </div>
  )
}
