export const schemaV1 = `
  id
  member_full_name
  member {
    id
    first_name
    last_name
    middle_name
    created_at
    updated_at
  }
  panel {
    id
    name
  }
  indication {
    id
    name
  }
  tdg_internal_note
  start_date
  end_date
  created_at
  updated_at
  dynamic_status
`
