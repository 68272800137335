import { useMutation } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { toast } from 'react-toastify'

import { UseReadConfigInput } from 'frontend/interfaces/CrudHookOptions'
import * as CRUD_UTILS from '../../../api/utils'
import { getTeamConfigs } from './getTeamConfigs'
import { CREATE_TEAM, UPDATE_TEAM } from './gql-tags'
import { CREATE_TOAST, UPDATE_TOAST } from './constants'

interface UseReadTeamConfigInput extends UseReadConfigInput {
  client?: string
}

interface UseTeamProps {
  deleteOptions?: any
  queryInput?: UseReadTeamConfigInput
}

export const useTeam = ({ deleteOptions, queryInput }: UseTeamProps = {}) => {
  const location = useLocation()
  const { client } =
    (location.search && queryString.parse(location.search)) || {}

  const createVariables = { input: { client } }

  const { deleteConfig, readConfig, optionsConfig } = getTeamConfigs(
    deleteOptions,
    queryInput,
    createVariables
  )

  const [create, { loading: isCreating }] = useMutation(CREATE_TEAM, {
    onCompleted: () => {
      toast.success(CREATE_TOAST.SUCCESS_MESSAGE, CREATE_TOAST.SUCCESS_OPTIONS)
    },
  })
  const [destroy, { loading: isDeleting }] = CRUD_UTILS.useDelete(deleteConfig)
  const [update, { loading: isUpdating }] = useMutation(UPDATE_TEAM, {
    onCompleted: () => {
      toast.success(UPDATE_TOAST.SUCCESS_MESSAGE, UPDATE_TOAST.SUCCESS_OPTIONS)
    },
  })

  const { data: readData, loading: isLoading } = CRUD_UTILS.useRead(readConfig)
  const {
    data: optionsData,
    loading: areOptionsLoading,
  } = CRUD_UTILS.useReadOptions(optionsConfig.tag, {
    labelMap: true,
  })

  const data = readData ? Object.values(readData)[0] : []
  const options = optionsData ? Object.values(optionsData)[0] : []

  return {
    data,
    create,
    isCreating,
    update,
    isUpdating,
    destroy,
    isDeleting,
    isLoading,
    options,
    areOptionsLoading,
  }
}
