import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Cell = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
`

const defaultCellFormatter = (value) =>
  Array.isArray(value) ? value.join(', ') : value

const DefaultCell = ({ column, cellValue }) => {
  const formatter = column.cellFormatter || defaultCellFormatter
  const formattedVal = formatter(cellValue ?? '')

  return <Cell>{formattedVal}</Cell>
}

DefaultCell.propTypes = {
  column: PropTypes.object.isRequired,
  cellValue: PropTypes.any,
}

export default DefaultCell
