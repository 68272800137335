import React from 'react'
import styled from '@emotion/styled'

import DataTable from 'frontend/components/DataTable'
import Header from 'frontend/components/Header'
import { CONFIG_TABLE_WIDTH } from 'frontend/components/Table/tableWidths'

import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'

import useCmsProviderData from './useCmsProviderData'
import getCmsProviderExportConfig from './utils'
import getCmsProviderColumns from './getCmsProviderColumns'

const Container = styled.div`
  width: ${CONFIG_TABLE_WIDTH};
  overflow-y: hidden;
`

const TitleSection = styled.section({
  display: 'flex',
  justifyContent: 'space-between',
  padding: Spacing.S7,
  alignItems: 'center',
})

const PAGE_TITLE = 'Staffing (CMS)'

const CmsProviderStaff = () => {
  const { cmsProviders } = useCmsProviderData()

  const cmsProviderColumns = getCmsProviderColumns()

  return (
    <Container>
      <TitleSection>
        <Header header={PAGE_TITLE} headerStyle={{ ...FontSpace.FS5 }} />
      </TitleSection>

      <DataTable
        isLoading={cmsProviders.loading}
        data={cmsProviders.data}
        columns={cmsProviderColumns}
        hasExport={true}
        exportConfig={getCmsProviderExportConfig(cmsProviderColumns)}
      />
    </Container>
  )
}

export default CmsProviderStaff
