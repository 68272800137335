import React from 'react'
import { HeaderTabs } from './HeaderTabs'
import { HeaderControls } from './HeaderContols'
import styled from '@emotion/styled'

interface TopContainerProps {
  closeModal: () => void
  handleUpdate: () => void
  onClick: () => void
}

const Container = styled.div({
  justifyContent: 'center',
  display: 'flex',
  padding: '6px 12px',
  width: '100%',
})

export const TopContainer = ({
  closeModal,
  handleUpdate,
  onClick,
}: TopContainerProps) => {
  return (
    <Container>
      <HeaderTabs />
      <HeaderControls
        onClick={onClick}
        handleUpdate={handleUpdate}
        closeModal={closeModal}
      />
    </Container>
  )
}
