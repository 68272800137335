import compareText from "frontend/utils/compareText"

const sortMappedValues = (args: any[], labelMap: any): number => {
  const [rowA, rowB, columnId] = args
  const stringA = convertRowValuesToStringIfArray(rowA.values[columnId], labelMap)
  const stringB = convertRowValuesToStringIfArray(rowB.values[columnId], labelMap)
  return compareText(stringA, stringB)
}

const convertRowValuesToStringIfArray = (value: any, labelMap: any): string => {
  return Array.isArray(value) ? value.map((value)=>labelMap[value]).join() : labelMap[value]
}

export default sortMappedValues
