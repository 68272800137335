export const schemaV0 = `
  team_id
  text
  order
  id
  node {
    id
    order
    text
    has_resources
    name
    permitted_entity_types
    parent_id
  }
`
