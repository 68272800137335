import React from 'react'
import { IconButton } from '@mui/material'

import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'

interface Props {
  documentProduct: any
  policyState: any
  setPolicyState: any
}

export const FlaggedButton = ({
  documentProduct,
  policyState,
  setPolicyState
}: Props) => {
  const value = policyState.products[documentProduct.id]?.has_language_changes ??
    documentProduct.has_language_changes

  const toggleFlag = () => {
    const has_language_changes = !value === documentProduct.has_language_changes ? undefined : !value

    setPolicyState(({ products, ...policyState }: any) => {
      if (products[documentProduct.id]) {
        products[documentProduct.id].has_language_changes = has_language_changes
      } else {
        products[documentProduct.id] = { has_language_changes }
      }

      return {
        ...policyState,
        products
      }
    })
  }

  return (
    <IconButton
      color="error"
      disableRipple
      onClick={toggleFlag}
      sx={{
        width: '13px',
        height: '14px',
      }}
    >
      <CustomSvgIcon
        styles={{ width: '13px', height: '14px' }}
        iconName={value ? 'FlagFilled' : 'Flag'}
        iconColor={value ? '#FF8B33' : '#BBBFC3'}
        viewBox="0 0 13 14"
      />
    </IconButton>
  )
}
