import React from "react"
import _ from "lodash"

import {FieldChangesProps} from './interfaces'
import {
  EventDiffWrapper,
  EventDiff,
  Field,
  DiffArrow,
} from './styled-components'

const coerceFieldValueForDisplay = (value:any) => {
  if(_.isBoolean(value)) return value.toString()
  if(value === '') return "none"
  return value ?? "none"
}

const FieldChanges = ({ deltas, keyToLabel }:FieldChangesProps) => {
  return (
    <EventDiffWrapper>
      {deltas.map(([key, value]:any) => {
        return (
          <EventDiff key={key}>
            <Field>{keyToLabel[key] || key}:</Field>
            <span>{coerceFieldValueForDisplay(value.old)}</span>
            <DiffArrow>➔</DiffArrow>
            <span>{coerceFieldValueForDisplay(value.new)}</span>
          </EventDiff>
        )
      })}
    </EventDiffWrapper>
  )
}

export default FieldChanges
