import React from 'react'
import { Box } from '@mui/material'
import { SectionLabel } from './SectionLabel'
import { ScrollableTextInput } from 'frontend/components'

const NOTES_STYLE = {
  marginTop: '24px',
  marginLeft: '12px',
  padding: '24px',
  borderRadius: '6px',
  border: '1px solid #e0e0e0',
  width: '423px',
  height: '187px',
}

interface Props {
  mostRecentPolicy: any
  policyState: any
  setPolicyState: any
}

const Notes = ({
  mostRecentPolicy,
  policyState,
  setPolicyState
}: Props) => {
  const onTextChange = (e: any) => {
    const tdg_internal_note = e.target.value

    setPolicyState((policyState: any) => ({
      ...policyState,
      tdg_internal_note
    }))
  }

  return (
    <Box sx={NOTES_STYLE}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '135px' }}>
        <SectionLabel label="Notes" />
      </Box>
      <div style={{ marginTop: '20px' }}>
        <ScrollableTextInput
          onChange={onTextChange}
          text={policyState.tdg_internal_note ?? mostRecentPolicy.tdg_internal_note}
        />
      </div>
    </Box>
  )
}

export default Notes
