import { MultiSelectFilter } from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'
import {
  FILTER_TYPES,
  SORT_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'

const { MULTI_SELECT } = FILTER_TYPES
const { STRING } = SORT_TYPES

export const getColConfig = () => [
  {
    Header: 'Client',
    accessor: 'client',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
  },
  {
    Header: 'Username',
    accessor: 'username',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
  },
  {
    Header: 'Email',
    accessor: 'email',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
  },
]
