import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

interface Props {
  selectedClientId: string
}

const LABEL_TEXT = 'Complete "Client" field to select a team.'

const Label = styled.p({
  color: Colors.GRAY_400,
  fontWeight: 500,
  fontSize: 12,
})

export const Description = ({ selectedClientId }: Props) => {
  if (selectedClientId) return null

  return <Label>{LABEL_TEXT}</Label>
}
