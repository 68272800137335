import React from 'react'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'

import { GET_VEGA_CLIENT_TEAMS } from 'frontend/api/queries'

import Spinner from 'frontend/components/Spinner'
import DataTable from 'frontend/components/DataTable'
import Header from 'frontend/components/Header'
import MultiSelectColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/MultiSelectColumnFilter'
import customMultiSelectFilterFn from 'frontend/components/Table/custom-filters/MultiSelect/customMultiSelectFilterFn'
import { LINK } from 'frontend/components/DataTable/Cells/cellTypes'

import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const HeaderWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: Spacing.S7,
})

const ClientTeams = () => {
  const { data, loading } = useQuery(GET_VEGA_CLIENT_TEAMS)

  const tableData = loading ? [] : data.vegaClientTeams

  const COLUMNS = [
    {
      Header: 'Client',
      accessor: 'client.name',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      sticky: 'left',
      cellConfig: {
        type: LINK,
        onEvent: ({
          row: {
            original: {
              id,
              client: { id: clientId },
            },
          },
        }) =>
          `/orion/specialized/value-perception/client-teams/${clientId}/${id}`,
      },
    },
    {
      Header: 'Team (From Phoenix)',
      accessor: 'name',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
    },
  ]

  const table = loading ? (
    <>
      <Spinner />
    </>
  ) : (
    <DataTable data={tableData} columns={COLUMNS} />
  )

  return (
    <Container>
      <HeaderWrapper>
        <Header
          header="Client Teams"
          subheader="Select a table row to view and edit Client Team subscriptions and regions"
          headerStyle={{ ...FontSpace.FS5 }}
        />
      </HeaderWrapper>
      {table}
    </Container>
  )
}

export default ClientTeams
