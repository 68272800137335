import React, { Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'

import Color from 'frontend/utils/color'

import { NodeState } from '../../types'
import AddField from './AddField'
import Field from './Field'
import Title from './Title'

interface Props {
  node?: any
  nodeState: NodeState
  setNodeState: Dispatch<SetStateAction<NodeState>>
}

const Wrapper = styled.div({
  border: `1px solid ${Color.MEDIUM_GRAY_1}`,
  padding: 12,
})

const TextObject = ({
  node,
  nodeState,
  ...props
}: Props) => {
  let text: { [key: string]: string } = {}
  if (nodeState?.text) text = nodeState.text
  else if (node) text = node.text

  return (
    <Wrapper>
      <Title />
      {Object.entries(text).map(([field, value], index) => (
        <Field
          key={index}
          field={field}
          text={text}
          value={value}
          {...props}
        />
      ))}
      <AddField
        text={text}
        {...props}
      />
    </Wrapper>
  )
}

export default TextObject
