import React, { Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { useRewriteSearchString } from 'frontend/hooks'
import { Colors } from 'frontend/utils/pulseStyles'

import { Sitemap, Type } from '../../../../types'
import ButtonGroup from './ButtonGroup'

interface WrapperProps {
  isSelected: boolean
}

interface Props {
  isSelected: boolean
  type: Type
  data: any
  sitemap: Sitemap
  setSitemap: Dispatch<SetStateAction<Sitemap | undefined>>
}

const TYPE_TO_QUERY_STRING_OBJ = {
  tool: (id: string) => ({
    tool: id,
    dashboard: undefined,
    page: undefined,
    card: undefined,
  }),
  dashboard: (id: string) => ({
    dashboard: id,
    page: undefined,
    card: undefined,
  }),
  page: (id: string) => ({
    page: id,
    card: undefined,
  }),
  card: (id: string) => ({
    card: id,
  }),
}

const Wrapper = styled.div({
  alignItems: 'center',
  borderBottom: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
  borderRadius: 8,
  cursor: 'pointer',
  display: 'flex',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '18px',
  justifyContent: 'space-between',
  padding: '8px 12px',
  margin: '8px 12px 0px',
}, ({ isSelected }: WrapperProps) => ({
  backgroundColor: isSelected ? transparentize(0.9, Colors.PRIMARY) : undefined,
  color: isSelected ? Colors.PRIMARY : Colors.BLACK,
  ':hover': isSelected ? undefined : { backgroundColor: Colors.COOL_GRAY_100 },
}))

const NodeListItem = ({
  isSelected,
  type,
  data,
  ...props
}: Props) => {
  const rewriteSearchString = useRewriteSearchString()
  const rewriteNodeParams = () => {
    if (!isSelected) rewriteSearchString(TYPE_TO_QUERY_STRING_OBJ[type](data.id))
  }

  return (
    <Wrapper
      isSelected={isSelected}
      onClick={rewriteNodeParams}
    >
      <span>
        {data.name}
      </span>
      <ButtonGroup
        type={type}
        data={data}
        {...props}
      />
    </Wrapper>
  )
}

export default NodeListItem
