import React, { useState, useRef } from 'react'

import DataTable from 'frontend/components/DataTable'

import {
  FieldContainer,
  FileInput,
} from '../../Orion/ImportExportData/NewImport/styledImportComponents'

import onFileAdded from '../onFileAdd'
import validateIndicationProduct from './validateIndicationProduct'

const ERROR_COLUMNS = [
  {
    Header: 'Product',
    accessor: 'product',
    sortType: 'text',
  },
  {
    Header: 'Payer Reimbursement Cost',
    accessor: 'hasPayerReimbursementCost',
    sortType: 'text',
  },
  {
    Header: 'Prevalence Access Challenges',
    accessor: 'hasPrevalenceAccessChallenges',
    sortType: 'text',
  },
  {
    Header: 'Service Delivery Cost',
    accessor: 'hasServiceDeliveryCost',
    sortType: 'text',
  },
  {
    Header: 'Dosing Administration',
    accessor: 'hasDosingAdministration',
    sortType: 'text',
  },
  {
    Header: 'Current Snapshot Segmentation',
    accessor: 'hasCurrentSnapshotSegmentation',
    sortType: 'text',
  },
]

const IndicationProduct = () => {
  const [sheetsData, setSheetsData] = useState({})
  const [validatorStatus, setValidatorStatus] = useState(null)
  const [sheetsErrors, setSheetErrors] = useState([])

  const fileInputRefProviderChannelProducts = useRef(null)
  const fileInputRefServiceDeliveryCost = useRef(null)
  const fileInputRefPrevalenceChallenges = useRef(null)
  const fileInputRefPayerReimbursementCost = useRef(null)
  const fileInputRefDosingAdministration = useRef(null)
  const fileInputRefSnapshotSegmentation = useRef(null)

  const onValidate = () => {
    const errors = validateIndicationProduct(sheetsData)
    setSheetErrors(errors)

    if (errors && errors.length) {
      setValidatorStatus(
        "You have errors :( Please fix the X's below in order to see the product reflected correctly"
      )
    } else {
      setValidatorStatus("You're good to import :)")
    }
  }

  return (
    <div>
      <section style={{ padding: '6px 12px' }}>
        <p>
          This validator uses the "Provider Channel Products" sheet in the Net
          Cost Recovery Mappings workbook as the source of truth.
        </p>
        <br />
        <p>
          <b>Validator Methodology:</b>
          <ol>
            <li>Take all products from "Provider Channel Products" sheet</li>
            <li>
              Cross reference the "Payer Reimbursement Cost" sheet confirm the
              product is represented{' '}
            </li>
            <li>
              Cross reference the "Prevalence Access Challenges" sheet confirm
              the product is represented{' '}
            </li>
            <li>
              Cross reference the "Service Delivery Cost" sheet confirm the
              product is represented{' '}
            </li>
            <li>
              Cross reference the "Dosing Administration" sheet confirm the
              product is represented (if not, will default to general)
            </li>
            <li>
              Cross reference the "Current Snapshot Segmentation" sheet confirm
              the product is represented (if not, will default to general){' '}
            </li>
          </ol>
        </p>
      </section>

      <section style={{ padding: '6px 12px' }}>
        <h4>Upload "Provider Channel Products" Sheet (NCR Mapping WB)</h4>
        <FieldContainer>
          <FileInput
            ref={fileInputRefProviderChannelProducts}
            type="file"
            onChange={() =>
              onFileAdded({
                ref: fileInputRefProviderChannelProducts,
                sheetName: 'Provider Channel Products',
                setSheetsData,
                sheetsData,
              })
            }
          />
        </FieldContainer>
      </section>

      <section style={{ padding: '6px 12px' }}>
        <h4>Upload "Service Delivery Costs" (NCR Master WB)</h4>
        <FieldContainer>
          <FileInput
            ref={fileInputRefServiceDeliveryCost}
            type="file"
            onChange={() =>
              onFileAdded({
                ref: fileInputRefServiceDeliveryCost,
                sheetName: 'Service Delivery Costs',
                setSheetsData,
                sheetsData,
              })
            }
          />
        </FieldContainer>
      </section>

      <section style={{ padding: '6px 12px' }}>
        <h4>Upload "Prevalence Access Challenges" (NCR Master WB)</h4>
        <FieldContainer>
          <FileInput
            ref={fileInputRefPrevalenceChallenges}
            type="file"
            onChange={() =>
              onFileAdded({
                ref: fileInputRefPrevalenceChallenges,
                sheetName: 'Prevalence Access Challenges',
                setSheetsData,
                sheetsData,
              })
            }
          />
        </FieldContainer>
      </section>

      <section style={{ padding: '6px 12px' }}>
        <h4>Upload "Payer Reimbursement Costs" (NCR Master WB)</h4>
        <FieldContainer>
          <FileInput
            ref={fileInputRefPayerReimbursementCost}
            type="file"
            onChange={() =>
              onFileAdded({
                ref: fileInputRefPayerReimbursementCost,
                sheetName: 'Payer Reimbursement Costs',
                setSheetsData,
                sheetsData,
              })
            }
          />
        </FieldContainer>
      </section>

      <section style={{ padding: '6px 12px' }}>
        <h4>Upload "Dosing Administration" (NCR Master WB)</h4>
        <FieldContainer>
          <FileInput
            ref={fileInputRefDosingAdministration}
            type="file"
            onChange={() =>
              onFileAdded({
                ref: fileInputRefDosingAdministration,
                sheetName: 'Dosing Administration',
                setSheetsData,
                sheetsData,
              })
            }
          />
        </FieldContainer>
      </section>

      <section style={{ padding: '6px 12px' }}>
        <h4>Upload "Current Snapshot Segmentation" (NCR Mapping WB)</h4>
        <FieldContainer>
          <FileInput
            ref={fileInputRefSnapshotSegmentation}
            type="file"
            onChange={() =>
              onFileAdded({
                ref: fileInputRefSnapshotSegmentation,
                sheetName: 'Current Snapshot Segmentation',
                setSheetsData,
                sheetsData,
              })
            }
          />
        </FieldContainer>
      </section>

      <div style={{ display: 'flex', justifyContent: 'end', padding: 8 }}>
        <button
          style={{
            outline: '1px solid black',
            padding: 4,
            borderRadius: 4,
            fontWeight: 600,
          }}
          onClick={onValidate}
        >
          Validate
        </button>
      </div>

      <section style={{ outline: '1px solid black', padding: 12 }}>
        {validatorStatus && <div>{validatorStatus}</div>}
        {sheetsErrors && (
          <DataTable data={sheetsErrors} columns={ERROR_COLUMNS} />
        )}
      </section>
    </div>
  )
}
export default IndicationProduct
