import { MultiSelectFilter } from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'
import {
  DISPLAY_CELL_TYPES,
  EDIT_CELL_TYPES,
  FILTER_TYPES,
  SORT_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'

const { CONDENSED_BUTTON } = DISPLAY_CELL_TYPES
const { MULTI_SELECT } = FILTER_TYPES
const { DISPLAY, STRING } = SORT_TYPES
const { CONDENSED_TEXT_INPUT, CONDENSED_MULTI_SELECT_INPUT } = EDIT_CELL_TYPES

const injectColConfig = ({
  addDeleteSearchString,
  indications,
  nccnPanels: { update },
}) => [
  {
    Header: 'Name',
    accessor: 'name',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: update,
    },
  },
  {
    Header: 'Indications',
    accessor: 'indications',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: DISPLAY,
    labelMap: indications.labelMap,
    editCellConfig: {
      type: CONDENSED_MULTI_SELECT_INPUT,
      options: indications.options,
      updateFn: update,
    },
  },
  {
    Header: 'Delete',
    accessor: 'delete',
    displayCellConfig: {
      type: CONDENSED_BUTTON,
      label: 'Delete',
      onClick: addDeleteSearchString,
    },
  },
]

export default injectColConfig
