import React from 'react'
import styled from '@emotion/styled'

import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'

const HeaderSection = styled.section({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const CategoryPanelHeader = () => {
  return (
    <HeaderSection>
      <h4 style={{ ...FontSpace.FS5, padding: Spacing.S4 }}>Category</h4>
    </HeaderSection>
  )
}

export default CategoryPanelHeader
