import React from 'react'
import {
  INPUT,
  SELECT,
  BUTTON,
} from 'frontend/components/DataTable/Cells/cellTypes'

import HydratedRefsMultiSelectColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/HydratedRefsMultiSelectColumnFilter'
import MultiSelectColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/MultiSelectColumnFilter'
import customMultiSelectFilterFn from 'frontend/components/Table/custom-filters/MultiSelect/customMultiSelectFilterFn'
import { sortMappedValues } from 'frontend/components/DataTable/custom-sort-types'

import MultiSelectDateColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/DateFilter/MultiSelectDateColumnFilter'
import customMultiSelectDateFilterFn from 'frontend/components/Table/custom-filters/MultiSelect/DateFilter/customMultiSelectDateFilterFn'

import { formatMonthDayYearSlash } from 'frontend/utils/formatDate'

import {
  mapIdToLabelCellFormatter,
  mapIdsToLabelsCellFormatter,
} from '../../utils/cellFormatterUtils'

const getHydratedFilter = (labelMap) => (filteredProps) => (
  <HydratedRefsMultiSelectColumnFilter {...filteredProps} labelMap={labelMap} />
)

const LARGER_COLUMN_WIDTH = 250

const getHistoricalPositionTableColumns = ({
  onDeleteButtonClick,
  onEdit,
  pathwaysOrgs,
  indications,
  regimens,
  populations,
  lines,
  cancerStages,
  positionStatus,
  relativeAccess,
  lockedInputs,
}) => [
  {
    Header: 'Delete',
    accessor: 'delete',
    sticky: 'left',
    cellConfig: {
      type: BUTTON,
      label: 'Delete',
      onClick: onDeleteButtonClick,
    },
  },
  {
    Header: 'Position Date (Required)',
    accessor: 'position_date',
    Filter: (...args) =>
      MultiSelectDateColumnFilter(args, formatMonthDayYearSlash),
    filter: customMultiSelectDateFilterFn,
    sortType: 'date',
    sticky: 'left',
    onEdit: onEdit,
    onFocusCellConfig: {
      type: INPUT,
      validationConfig: {
        inputType: 'date',
      },
    },
    createRowCellConfig: {
      type: INPUT,
      validationConfig: {
        inputType: 'date',
      },
    },
  },
  {
    Header: 'Pathways Organization',
    accessor: 'pathways_organization',
    Filter: getHydratedFilter(pathwaysOrgs.labelMap),
    filter: customMultiSelectFilterFn,
    sortType: (...args) => sortMappedValues(args, pathwaysOrgs.labelMap),
    cellFormatter: (value) =>
      mapIdToLabelCellFormatter(value, pathwaysOrgs.labelMap),
    sticky: 'left',
    onFocusCellConfig: {
      type: SELECT,
      optionMap: pathwaysOrgs.optionMap,
      options: pathwaysOrgs.options,
      isDisabled: true,
    },
    createRowCellConfig: {
      type: SELECT,
      optionMap: pathwaysOrgs.optionMap,
      defaultValue: lockedInputs.pathways_organization,
      isDisabled: true,
    },
  },
  {
    Header: 'Indication',
    accessor: 'indication',
    Filter: getHydratedFilter(indications.labelMap),
    filter: customMultiSelectFilterFn,
    sortType: (...args) => sortMappedValues(args, indications.labelMap),
    cellFormatter: (value) =>
      mapIdToLabelCellFormatter(value, indications.labelMap),
    sticky: 'left',
    onFocusCellConfig: {
      type: SELECT,
      optionMap: indications.optionMap,
      options: indications.options,
      isDisabled: true,
    },
    createRowCellConfig: {
      type: SELECT,
      optionMap: indications.optionMap,
      defaultValue: lockedInputs.indication,
      isDisabled: true,
    },
  },
  {
    Header: 'Regimen',
    accessor: 'regimen',
    Filter: getHydratedFilter(regimens.labelMap),
    filter: customMultiSelectFilterFn,
    sortType: (...args) => sortMappedValues(args, regimens.labelMap),
    cellFormatter: (value) =>
      mapIdToLabelCellFormatter(value, regimens.labelMap),
    sticky: 'left',
    onFocusCellConfig: {
      type: SELECT,
      optionMap: regimens.optionMap,
      options: regimens.options,
      isDisabled: true,
    },
    createRowCellConfig: {
      type: SELECT,
      optionMap: regimens.optionMap,
      defaultValue: lockedInputs.regimen,
      isDisabled: true,
    },
  },
  {
    Header: 'Position Note',
    accessor: 'position_note',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    onEdit: onEdit,
    onFocusCellConfig: {
      type: INPUT,
    },
    createRowCellConfig: {
      type: INPUT,
    },
  },
  {
    Header: 'Populations',
    accessor: 'populations',
    width: LARGER_COLUMN_WIDTH,
    /*
        ! This filter is not currently working as intended. We need to build a custom filterType
        ! for react-table that can generate filter options from array values.
      */
    Filter: getHydratedFilter(populations.labelMap),
    filter: customMultiSelectFilterFn,
    sortType: (...args) => sortMappedValues(args, populations.labelMap),
    cellFormatter: (value) =>
      mapIdsToLabelsCellFormatter(value, populations.labelMap),
    onEdit: onEdit,
    onFocusCellConfig: {
      type: SELECT,
      isMulti: true,
      options: populations.options,
      optionMap: populations.optionMap,
      isDisabled: true,
    },
    createRowCellConfig: {
      type: SELECT,
      isMulti: true,
      options: populations.options,
      optionMap: populations.optionMap,
      defaultValue: lockedInputs.populations,
      isDisabled: true,
    },
  },
  {
    Header: 'Cancer Stages (Default Stage IV)',
    accessor: 'cancer_stages',
    width: LARGER_COLUMN_WIDTH,
    /*
        ! This filter is not currently working as intended. We need to build a custom filterType
        ! for react-table that can generate filter options from array values.
      */
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: (...args) => sortMappedValues(args, cancerStages.labelMap),
    cellFormatter: (value) =>
      mapIdsToLabelsCellFormatter(value, cancerStages.labelMap),
    onEdit: onEdit,
    onFocusCellConfig: {
      type: SELECT,
      isMulti: true,
      options: cancerStages.options,
      optionMap: cancerStages.optionMap,
    },
    createRowCellConfig: {
      type: SELECT,
      isMulti: true,
      options: cancerStages.options,
      optionMap: cancerStages.optionMap,
    },
  },
  {
    Header: 'FDA Lines',
    accessor: 'fda_indication_regimen_approval.lines_of_therapy',
    Filter: getHydratedFilter(lines.labelMap),
    filter: customMultiSelectFilterFn,
    sortType: (...args) => sortMappedValues(args, lines.labelMap),
    cellFormatter: (value) =>
      mapIdsToLabelsCellFormatter(value, lines.labelMap),
  },
  {
    Header: 'FDA Approval Date',
    accessor: 'fda_indication_regimen_approval.approval_date',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
  },
  {
    Header: 'Position Status (Required)',
    accessor: 'position_status',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: (...args) => sortMappedValues(args, positionStatus.labelMap),
    cellFormatter: (value) =>
      mapIdToLabelCellFormatter(value, positionStatus.labelMap),
    onEdit: onEdit,
    onFocusCellConfig: {
      type: SELECT,
      options: positionStatus.options,
      optionMap: positionStatus.optionMap,
    },
    createRowCellConfig: {
      type: SELECT,
      options: positionStatus.options,
    },
  },
  {
    Header: 'Lines Of Therapy (Required if status `On Pathways`)',
    accessor: 'lines_of_therapy',
    width: LARGER_COLUMN_WIDTH,
    /*
        ! This filter is not currently working as intended. We need to build a custom filterType
        ! for react-table that can generate filter options from array values.
      */
    Filter: getHydratedFilter(lines.labelMap),
    filter: customMultiSelectFilterFn,
    sortType: (...args) => sortMappedValues(args, lines.labelMap),
    cellFormatter: (value) =>
      mapIdsToLabelsCellFormatter(value, lines.labelMap),
    onEdit: onEdit,
    onFocusCellConfig: {
      type: SELECT,
      isMulti: true,
      options: lines.options,
      optionMap: lines.optionMap,
    },
    createRowCellConfig: {
      type: SELECT,
      isMulti: true,
      options: lines.options,
    },
  },
  {
    Header: 'Relative Access (Required)',
    accessor: 'relative_access',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: (...args) => sortMappedValues(args, relativeAccess.labelMap),
    cellFormatter: (value) =>
      mapIdToLabelCellFormatter(value, relativeAccess.labelMap),
    onEdit: onEdit,
    onFocusCellConfig: {
      type: SELECT,
      options: relativeAccess.options,
      optionMap: relativeAccess.optionMap,
    },
    createRowCellConfig: {
      type: SELECT,
      options: relativeAccess.options,
    },
  },
  {
    Header: 'Alert Note',
    accessor: 'alert_note',
    Filter: MultiSelectColumnFilter,
    filter: customMultiSelectFilterFn,
    sortType: 'text',
    onEdit: onEdit,
    onFocusCellConfig: {
      type: INPUT,
    },
    createRowCellConfig: {
      type: INPUT,
    },
  },
  {
    Header: 'Alert Date',
    accessor: 'alert_date',
    Filter: (...args) =>
      MultiSelectDateColumnFilter(args, formatMonthDayYearSlash),
    filter: customMultiSelectDateFilterFn,
    sortType: 'date',
    onEdit: onEdit,
    onFocusCellConfig: {
      type: INPUT,
      validationConfig: {
        inputType: 'date',
      },
    },
    createRowCellConfig: {
      type: INPUT,
      validationConfig: {
        inputType: 'date',
      },
    },
  },
]

export default getHistoricalPositionTableColumns
