import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Products from './ProductsPanel'
import PeopleManagement from './PeopleManagement'
import UsStates from './UsStates'
import TreatmentPlans from './TreatmentPlans'
import FdaApprovals from './FdaApprovals'

const GeneralData = () => (
  <Switch>
    <Route path={'/orion/general/products'} component={Products} />
    <Route
      path={'/orion/general/people-management'}
      component={PeopleManagement}
    />
    <Route path={'/orion/general/us-states'} component={UsStates} />
    <Route path={'/orion/general/treatment-plans'} component={TreatmentPlans} />
    <Route path={'/orion/general/fda-approvals'} component={FdaApprovals} />
    <Redirect to={'/orion/general/products'} />
  </Switch>
)

export default GeneralData
