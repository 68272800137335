import { useQuery } from '@apollo/client'

import { GET_MONGO_TO_VEGA_BRIDGE } from "frontend/api/queries"

interface MongoToVegaBridgeQueryInput {
  mongoId?: String
  vegaId?: String
  entityType?: String
}

const useMongoVegaBridge = (mongoToVegaBridgeQueryInput: MongoToVegaBridgeQueryInput) => {
  const { data: bridgeData, loading: isBridgeDataLoading } = useQuery(
    GET_MONGO_TO_VEGA_BRIDGE,
    { variables: { input: mongoToVegaBridgeQueryInput } },
  )

  return {
    data: isBridgeDataLoading ? [] : bridgeData.mongoToVegaBridge,
    loading: isBridgeDataLoading
  }
}

export default useMongoVegaBridge
