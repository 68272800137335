import React from 'react'

import { LabelCheckbox } from 'frontend/components'

interface Props {
  checked?: boolean
  onChange: any
}

const CHECKBOX_LABEL = 'Manually create password'
const FIELD_NAME = 'isManualPassword'

export const Checkbox = ({ checked, onChange }: Props) => {
  return (
    <LabelCheckbox
      checked={checked}
      label={CHECKBOX_LABEL}
      onChange={onChange}
      name={FIELD_NAME}
    />
  )
}
