import React from 'react'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import Spacing from 'frontend/utils/spacing'
import { Colors } from 'frontend/utils/pulseStyles'

const LINK = {
  LABEL: 'Download Tutorial',
  ICON: faDownload,
  HREF:
    'https://norstellaglobal.sharepoint.com/:b:/s/PulseAnalytics-TDG/EcbqzOKNHT5HhDVkT3KvEoQB2sdrHYSmqYGKQdrEty_N4Q?e=wIESe8',
  REL: 'noopener noreferrer',
  TARGET: '_blank',
  A_TAG_STYLE: {
    background: 'transparent',
    color: Colors.PRIMARY,
    margin: '24px',
    padding: Spacing.S3,
  },
  SPAN_TAG_STYLE: { paddingRight: 6 },
}

export const DownloadTutorialLink = () => {
  return (
    <a
      href={LINK.HREF}
      rel={LINK.REL}
      target={LINK.TARGET}
      style={LINK.A_TAG_STYLE}
    >
      <span style={LINK.SPAN_TAG_STYLE}>{LINK.LABEL}</span>
      <FontAwesomeIcon icon={LINK.ICON as IconProp} />
    </a>
  )
}
