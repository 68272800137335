import { useMutation, useQuery } from '@apollo/client'

import {
  GET_MARKET_BASKETS,
  GET_MARKET_BASKETS_SUBSCRIPTIONS,
} from 'frontend/api/queries'

import {
  SUBSCRIBE_TEAM_TO_MARKET_BASKET,
  UNSUBSCRIBE_TEAM_TO_MARKET_BASKET,
} from 'frontend/api/mutations'

const useMarketBasketSubscriptionData = (clientTeamId) => {
  const { marketBaskets } = useMarketBaskets()
  const { marketBasketSubscriptions } = useMarketBasketSubscriptions(
    clientTeamId
  )

  return {
    marketBaskets,
    marketBasketSubscriptions,
  }
}

export default useMarketBasketSubscriptionData

const useMarketBaskets = () => {
  const { data: marketBasketData, loading: mbsLoading } = useQuery(
    GET_MARKET_BASKETS
  )

  const marketBaskets = mbsLoading ? [] : marketBasketData.marketBaskets

  return {
    marketBaskets: {
      data: marketBaskets,
      loading: mbsLoading,
    },
  }
}

const useMarketBasketSubscriptions = (clientTeamId) => {
  const { data: marketBasketSubData, loading: subsLoading } = useQuery(
    GET_MARKET_BASKETS_SUBSCRIPTIONS,
    {
      variables: { clientTeamId },
    }
  )

  const subscriptions = subsLoading
    ? []
    : marketBasketSubData.marketBasketsSubscriptions

  const [subscribeToMarketBasket, { loading: isSubbing }] = useMutation(
    SUBSCRIBE_TEAM_TO_MARKET_BASKET,
    {
      update: (cache, { data }) => {
        const newSubs = [...subscriptions, Object.values(data)[0]]

        cache.writeQuery({
          query: GET_MARKET_BASKETS_SUBSCRIPTIONS,
          data: { marketBasketsSubscriptions: newSubs },
          variables: { clientTeamId },
        })
      },
      onError: alert,
    }
  )

  const [unsubscribeToMarketBasket, { loading: isUnsubbing }] = useMutation(
    UNSUBSCRIBE_TEAM_TO_MARKET_BASKET,
    {
      update: (cache, { data }) => {
        const newSubs = subscriptions.filter(
          ({ market_basket }) =>
            market_basket !== Object.values(data)[0].market_basket
        )

        cache.writeQuery({
          query: GET_MARKET_BASKETS_SUBSCRIPTIONS,
          data: { marketBasketsSubscriptions: newSubs },
          variables: { clientTeamId },
        })
      },
      onError: alert,
    }
  )

  return {
    marketBasketSubscriptions: {
      data: subscriptions,
      loading: subsLoading,
      subscribe: subscribeToMarketBasket,
      unsubscribe: unsubscribeToMarketBasket,
      isSubbing,
      isUnsubbing,
    },
  }
}
