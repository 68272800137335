import { useLazyQuery } from '@apollo/client'

import { unwrapGqlData } from 'frontend/utils'

import { UsePaginatedReadConfig } from '../../../interfaces'

import writeLazyDataToCache from './writeLazyDataToCache'

export const useLazyData = (paginatedReadConfig: UsePaginatedReadConfig) => {
  const [getData, { data: lazyData, client: apolloClient }] = useLazyQuery(
    paginatedReadConfig.tag,
    {
      onCompleted: (data) => {
        const lazyQueryResponse = {
          data,
          getData,
          apolloClient,
        }
        writeLazyDataToCache(
          paginatedReadConfig,
          lazyQueryResponse,
        )
      },
      nextFetchPolicy: 'standby',
    }
  )

  const data = unwrapGqlData(lazyData)

  return {
    data,
    getData,
    apolloClient,
  }
}
