import React, { Dispatch, SetStateAction } from 'react'

import { CheckboxList } from './CheckboxList'
import { LoadingState } from './LoadingState'

interface Props {
  isLoading: boolean
  clientTeams: any
  setFieldState: Dispatch<SetStateAction<any>>
}

export const TeamsContent = ({
  isLoading,
  clientTeams,
  setFieldState,
}: Props) => {
  if (isLoading) return <LoadingState />

  return (
    <CheckboxList clientTeams={clientTeams} setFieldState={setFieldState} />
  )
}
