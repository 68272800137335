import React from 'react'

import InnerContainer from './InnerContainer'

interface Props {
  activeTab: string
  comparisonUrl: string
  originalUrl?: string
}

const OuterContainer = ({
  activeTab,
  comparisonUrl,
  originalUrl
}: Props) => {
  if (activeTab !== 'comparison' || !originalUrl) return null

  return <InnerContainer
    comparisonUrl={comparisonUrl}
    originalUrl={originalUrl}
  />
}

export default OuterContainer
