import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { Button } from '@pulse-analytics/pulse-design-system'

import Spinner from 'frontend/components/Spinner'
import { SingleActionDialog } from 'frontend/components/Dialog'

import Color from 'frontend/utils/color'
import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'

import {
  GET_PROVIDER_ORGANIZATIONS,
  GET_PATHWAYS_ORGANIZATIONS,
  GET_VEGA_PROVIDERS,
} from 'frontend/api/queries'
import { DELETE_PROVIDER } from 'frontend/api/mutations'

const PAGE_HEADER = 'Delete Provider Account'

const updateCacheOnDeleteProvider = (cache, accountId) => {
  const { providerOrganizations } = cache.readQuery({
    query: GET_PROVIDER_ORGANIZATIONS,
  })
  const newProviderAccounts = providerOrganizations.filter(
    ({ _id }) => _id !== accountId
  )
  cache.writeQuery({
    query: GET_PROVIDER_ORGANIZATIONS,
    data: {
      providerOrganizations: newProviderAccounts,
    },
  })
}

const DeleteProviderAccountForm = ({ accountId, closeHandler }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [
    deleteProviderAccount,
    { loading: isProviderAccountLoading },
  ] = useMutation(DELETE_PROVIDER, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_PROVIDER_ORGANIZATIONS },
      { query: GET_PATHWAYS_ORGANIZATIONS },
      { query: GET_VEGA_PROVIDERS },
    ],
    variables: {
      input: {
        _id: accountId,
      },
    },
    update: updateCacheOnDeleteProvider,
    onError: alert,
    onCompleted: () => {
      closeHandler()
    },
  })

  return (
    <section>
      <h4 style={{ paddingBottom: Spacing.FS4, ...FontSpace.FS5 }}>
        {PAGE_HEADER}
      </h4>
      <p style={FontSpace.FS2}>
        Deleting a provider account removes all data associated with it and
        can’t be undone.
      </p>
      <Button
        color={Color.RED}
        onClick={() => setIsModalOpen(true)}
        style={{
          padding: `${Spacing.S2} ${Spacing.S3}`,
          margin: `${Spacing.S4} 0`,
        }}
      >
        Delete
      </Button>
      {isModalOpen && (
        <SingleActionDialog
          header={PAGE_HEADER}
          submitText="Delete Forever"
          submitHandler={deleteProviderAccount}
          cancelHandler={() => setIsModalOpen(false)}
          headerStyle={{ color: Color.RED }}
          submitColor={Color.RED}
          contentStyle={{ width: 450 }}
        >
          {isProviderAccountLoading ? (
            <Spinner />
          ) : (
            <div style={{ padding: 36, textAlign: 'center', ...FontSpace.FS3 }}>
              <p>Are you sure you want to delete the provider account?</p>
              <p style={{ fontWeight: 700, marginTop: 12 }}>
                THIS CANNOT BE UNDONE
              </p>
            </div>
          )}
        </SingleActionDialog>
      )}
    </section>
  )
}

DeleteProviderAccountForm.propTypes = {
  accountId: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
}

export default DeleteProviderAccountForm
