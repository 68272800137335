import React from 'react'
import styled from '@emotion/styled'
import { transparentize, mix } from 'polished'
import { useQuery } from '@apollo/client'
import _ from 'lodash'

import { GET_PAYER_ORGANIZATIONS } from 'frontend/api/queries'
import Card from 'frontend/components/Card'
import Spinner from 'frontend/components/Spinner'

import OrionSwitch from './OrionSwitch'

import { Colors, Spacing } from 'frontend/utils/pulseStyles'

const ActiveRow = styled.div({
  background: mix(0.8, Colors.WHITE, Colors.GREEN),
  color: Colors.GREEN,
  fontSize: 10,
  fontWeight: 700,
  lineHeight: '18px',
  padding: '8px 24px',
  position: 'sticky',
  top: 0,
  zIndex: 5,
})

const InactiveRow = styled.div({
  background: mix(0.8, Colors.WHITE, Colors.MEDIUM_GRAY_2),
  color: Colors.MEDIUM_GRAY_2,
  fontSize: 10,
  fontWeight: 700,
  lineHeight: '18px',
  padding: '8px 24px',
  position: 'sticky',
  top: 0,
  zIndex: 5,
})

const UnorderedList = styled.ul({
  padding: `0 ${Spacing.NORMAL}`,
})

const AccountRowItem = styled.div({
  borderBottom: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
  paddingLeft: Spacing.NORMAL,
  ':hover': {
    background: transparentize(0.92, Colors.BLACK),
  },
})

const PayerSelectionPanel = ({ payerIds, setPayerIds }) => {
  const { data, loading } = useQuery(GET_PAYER_ORGANIZATIONS)

  let payerOrganizations = []
  if (!loading) {
    payerOrganizations = data.payerOrganizations
  }

  const isPayerChecked = (payerId) => payerIds[payerId]

  const handleToggle = (payerId) => {
    const isChecked = isPayerChecked(payerId)

    if (isChecked) {
      const clonedPayerIds = _.cloneDeep(payerIds)
      delete clonedPayerIds[payerId]

      setPayerIds(clonedPayerIds)
    } else {
      setPayerIds({
        ...payerIds,
        [payerId]: true,
      })
    }
  }

  const [enabledAccounts, disabledAccounts] = _.partition(
    payerOrganizations,
    (account) => payerIds[account._id]
  )

  return (
    <Card title="Payers">
      <div style={{ maxHeight: 400, overflowY: 'auto' }}>
        <ActiveRow>ACTIVE ({enabledAccounts.length})</ActiveRow>
        <UnorderedList>
          {enabledAccounts.map((account) => (
            <AccountRowItem key={account._id}>
              <OrionSwitch
                _id={account._id}
                isChecked={isPayerChecked(account._id)}
                handleToggle={handleToggle}
              />
              <span>
                {account.organization} ({account.slug})
              </span>
            </AccountRowItem>
          ))}
        </UnorderedList>

        <InactiveRow>INACTIVE ({disabledAccounts.length})</InactiveRow>
        <UnorderedList>
          {disabledAccounts.map((account) => (
            <AccountRowItem key={account._id}>
              <OrionSwitch
                _id={account._id}
                isChecked={isPayerChecked(account._id)}
                handleToggle={handleToggle}
              />
              <span>
                {account.organization} ({account.slug})
              </span>
            </AccountRowItem>
          ))}
        </UnorderedList>

        {loading && <Spinner />}
      </div>
    </Card>
  )
}

export default PayerSelectionPanel
