import _ from 'lodash'
import { formatMonthDayYearSlash } from 'frontend/utils/formatDate'

const customMultiSelectDateFilterFn = (filter, row, filterValue) => {
  if (_.isEmpty(filterValue)) return filter
  const colKey = row[0]
  return filter.filter((rowDatum) => {
    const rowValue = formatMonthDayYearSlash(rowDatum.values[colKey])
    return filterValue.includes(rowValue)
  })
}

export default customMultiSelectDateFilterFn
