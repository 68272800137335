import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

import CreateButton from './CreateButton'
import DeployPermissionsButton from './DeployPermissionsButton'
import DeployPermissionsDescription from './DeployPermissionsDescription'

const TITLE_TEXT = 'Client Management'
const SUBTITLE_TEXT = 'Clients, teams, users, and subscriptions'

const Wrapper = styled.section({
  display: 'flex',
  padding: 24,
  borderBottom: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
  justifyContent: 'space-between',
})

const Title = styled.div({
  fontWeight: 600,
  fontSize: 20,
  marginBottom: 8,
  color: Colors.BLACK,
})

const Subtitle = styled.div({
  fontWeight: 600,
  fontSize: 12,
  color: Colors.GRAY_500,
})

const ButtonContent = styled.div({ paddingRight: 24 })

const ButtonGroup = styled.div({
  display: 'flex',
  gap: '16px',
  justifyContent: 'end',
})

const Header = () => {
  return (
    <Wrapper>
      <div>
        <Title>{TITLE_TEXT}</Title>
        <Subtitle>{SUBTITLE_TEXT}</Subtitle>
      </div>
      <ButtonContent>
        <ButtonGroup>
          <DeployPermissionsButton />
          <CreateButton />
        </ButtonGroup>
        <DeployPermissionsDescription />
      </ButtonContent>
    </Wrapper>
  )
}

export default Header
