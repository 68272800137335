import { gql } from '@apollo/client'
import { schemaV1 } from './schemas'

export const GET_NCCN_PANELS_VOTING_MEMBERS = gql`
  query GetNccnPanelsVotingMembers($input: NccnPanelsVotingMembersInput) {
    nccnPanelsVotingMembers(input: $input) {
      ${schemaV1}
    }
  }
`
