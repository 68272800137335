import { useNccnPanelAffiliationsData } from '../useNccnPanelAffiliationsData'

export const useFormatTableData = () => {
  const {
    nccnPanelVotingMembers,
  } = useNccnPanelAffiliationsData()

  const data = nccnPanelVotingMembers.data.map(
    ({
      member: { id: memberId, first_name, middle_name, last_name },
      panel: { id: panelId, name },
      ...rest
    }) => {
      return {
        member: memberId,
        memberFirstName: first_name,
        memberMiddleName: middle_name,
        memberLastName: last_name,
        panel: panelId,
        panelName: name,
        ...rest,
      }
    }
  )

  return {
    data,
    isLoading: nccnPanelVotingMembers.isLoading
  }
}
