import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

import { NodeState } from '../../types'
import SaveButton from './SaveButton'

interface Props {
  closeModal: () => void
  nodeState: NodeState
}

const Wrapper = styled.div({
  display: 'flex',
})

const CancelButton = styled.button({
  background: transparentize(0.85, Colors.RED),
  borderRadius: '4px',
  color: Colors.RED,
  cursor: 'pointer',
  fontSize: '12px',
  fontWeight: 700,
  marginLeft: '20px',
  padding: `8px 12px`,
  textTransform: 'uppercase',
  ':hover': {
    background: transparentize(0.7, Colors.RED),
  },
})

const ButtonGroup = ({
  closeModal,
  nodeState,
}: Props) => (
  <Wrapper>
    <CancelButton
      onClick={closeModal}
    >
      Cancel
    </CancelButton>
    <SaveButton
      nodeState={nodeState}
    />
  </Wrapper>
)

export default ButtonGroup
