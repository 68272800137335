import React from 'react'

import { useClient } from 'frontend/entity-hooks/ember'

import { Client } from './Client'

interface Props {
  fieldState: any
}

export const Container = ({ fieldState }: Props) => {
  const {
    client: { id: selectedClientId },
  } = fieldState

  const { data: clients }: any = useClient()

  let selectedClient = {}
  for (const { id, name } of clients) {
    if (selectedClientId === id) selectedClient = { label: name, value: id }
  }

  return <Client selectedClient={selectedClient} />
}
