import React from 'react'

import { LabelCheckbox } from 'frontend/components'

interface Props {
  checked?: boolean
  onChange: any
}

const CHECKBOX_LABEL = 'Lock landing page'

export const Checkbox = ({ checked, onChange }: Props) => {
  return (
    <LabelCheckbox
      checked={checked}
      label={CHECKBOX_LABEL}
      onChange={onChange}
    />
  )
}
