import React from 'react'

export const SectionLabel = ({ label }: { label: string }) => {
  return (
    <span
      style={{
        fontSize: '16px',
        fontWeight: 500,
        letterSpacing: '0.2px',
      }}
    >
      {label}
    </span>
  )
}
