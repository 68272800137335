import { gql } from '@apollo/client'
import { schema } from './schemas'

export const UPDATE_PAYER_POLICY_DOCUMENT = gql`
  mutation UpdatePayerPolicyDocument($input: UpdatePayerPolicyDocumentInput!) {
    updatePayerPolicyDocument(input: $input) {
      ${schema}
    }
  }
`
