import { useFilterConfigFeatureFlags } from 'frontend/hooks'
import { useNccnPanelAffiliationsData } from '../../../useNccnPanelAffiliationsData'
import { getFormConfig } from './getFormConfig'

export const useFormConfig = () => {
  const {
    people,
    nccnPanels,
  } = useNccnPanelAffiliationsData()
  const formConfig = getFormConfig({ people, nccnPanels })
  const filterConfigFeatureFlags = useFilterConfigFeatureFlags()
  return formConfig.map(filterConfigFeatureFlags)
}
