import * as GQL_TAGS from './gql-tags'

export const getImportPolicyLinkConfigs = (
  queryInput: any,
  updateInput: any
) => ({
  updateConfig: {
    tag: GQL_TAGS.UPDATE_IMPORT_POLICY_LINK,
    updateInput: updateInput,
  },
})
