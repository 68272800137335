import { transparentize } from 'polished'

import {
  DateMultiSelectFilter,
  MultiSelectFilter,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'
import {
  DISPLAY_CELL_TYPES,
  EDIT_CELL_TYPES,
  FILTER_TYPES,
  SORT_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'

import { formatMonthDayYearSlash } from 'frontend/utils/formatDate'
import Color from 'frontend/utils/color'
import { ExpandIcon } from 'frontend/components/Icon'

const { BUTTON } = DISPLAY_CELL_TYPES
const {
  CONDENSED_TEXT_INPUT,
  CONDENSED_DATE_INPUT,
  CONDENSED_MULTI_SELECT_INPUT,
  CONDENSED_SINGLE_SELECT_INPUT,
} = EDIT_CELL_TYPES
const { DATE_MULTI_SELECT, MULTI_SELECT } = FILTER_TYPES
const { BOOL, DATE, STRING, TEXT_ARRAY } = SORT_TYPES

export const getColConfig = ({
  onPeopleClick,
  options,
  updateFn,
  indicationLabelMap,
  indicationsOptions,
}) => [
  {
    Header: '',
    accessor: 'x',
    sticky: 'left',
    displayCellConfig: {
      type: BUTTON,
      style: {
        background: transparentize(0.9, Color.PRIMARY),
        borderRadius: 4,
        padding: 2,
        cursor: 'pointer',
      },
      label: <ExpandIcon />,
      onClick: onPeopleClick,
    },
    width: 50,
    disableSortBy: true,
  },
  {
    Header: 'First Name',
    accessor: 'personFirstName',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    sticky: 'left',
    width: 200,
  },
  {
    Header: 'Last Name',
    accessor: 'personLastName',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    sticky: 'left',
    width: 200,
  },
  {
    Header: 'Pathways Organization',
    accessor: 'pathwaysName',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    sticky: 'left',
    width: 200,
  },
  {
    Header: 'Pathways Role',
    accessor: 'position',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 200,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn,
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 100,
  },
  {
    Header: 'Primary Affiliation',
    accessor: 'personPrimaryAffiliation',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 200,
  },
  {
    Header: 'Management Type',
    accessor: 'internal_pathways_management_types',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: TEXT_ARRAY,
    width: 200,
    editCellConfig: {
      type: CONDENSED_MULTI_SELECT_INPUT,
      options: options?.internal_pathways_management_types || [],
      updateFn,
    },
  },
  {
    Header: 'Influencer Type',
    accessor: 'pathways_influencer_types',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: TEXT_ARRAY,
    width: 200,
    editCellConfig: {
      type: CONDENSED_MULTI_SELECT_INPUT,
      options: options?.pathways_influencer_types || [],
      updateFn,
    },
  },
  {
    Header: 'Internal TDG Notes',
    accessor: 'internal_tdg_note',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 200,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn,
    },
  },
  {
    Header: 'ClinicalPath / Value Chairs Indication(s) (Internal TDG Only)',
    accessor: 'value_chair_indications',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: TEXT_ARRAY,
    width: 200,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn,
    },
  },
  {
    Header: 'Tumor Type Specialty',
    accessor: 'tumor_type_specialty',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 200,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn,
    },
  },
  {
    Header: 'Priority',
    accessor: 'priority',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 200,
    editCellConfig: {
      type: CONDENSED_SINGLE_SELECT_INPUT,
      options: options?.priority || [],
      updateFn,
    },
  },
  {
    Header: 'Alert Date',
    accessor: 'alert_date',
    Filter: DateMultiSelectFilter,
    filter: DATE_MULTI_SELECT,
    sortType: DATE,
    cellValueFormatter: formatMonthDayYearSlash,
    width: 200,
    editCellConfig: {
      type: CONDENSED_DATE_INPUT,
      updateFn,
    },
  },
  {
    Header: 'Alert Type',
    accessor: 'alert_type',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 200,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn,
    },
  },
  {
    Header: 'Alert Description',
    accessor: 'alert_description',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 200,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn,
    },
  },
  {
    Header: 'Excluded From Tool',
    accessor: 'is_excluded',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: BOOL,
    cellValueFormatter: (value) => String(value),
    width: 200,
    editCellConfig: {
      type: CONDENSED_SINGLE_SELECT_INPUT,
      options: [
        { label: 'True', value: true },
        { label: 'False', value: false },
      ],
      updateFn,
    },
  },
  {
    Header: 'Excluded Reason',
    accessor: 'exclusion_reason',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 200,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn,
    },
  },
  {
    Header: 'Start Date',
    accessor: 'start_date',
    Filter: DateMultiSelectFilter,
    filter: DATE_MULTI_SELECT,
    sortType: DATE,
    cellValueFormatter: formatMonthDayYearSlash,
    width: 200,
    editCellConfig: {
      type: CONDENSED_DATE_INPUT,
      updateFn,
    },
  },
  {
    Header: 'Start Quarter',
    accessor: 'start_quarter',
    Filter: DateMultiSelectFilter,
    filter: DATE_MULTI_SELECT,
    sortType: DATE,
    cellValueFormatter: formatMonthDayYearSlash,
    width: 200,
    //TODO: Coerce dates to quarter format
    // editCellConfig: {
    //   type: CONDENSED_DATE_INPUT,
    //   updateFn,
    // },
  },
  {
    Header: 'End Date',
    accessor: 'end_date',
    Filter: DateMultiSelectFilter,
    filter: DATE_MULTI_SELECT,
    sortType: DATE,
    cellValueFormatter: formatMonthDayYearSlash,
    width: 200,
    editCellConfig: {
      type: CONDENSED_DATE_INPUT,
      updateFn,
    },
  },
  {
    Header: 'End Quarter',
    accessor: 'end_quarter',
    Filter: DateMultiSelectFilter,
    filter: DATE_MULTI_SELECT,
    sortType: DATE,
    cellValueFormatter: formatMonthDayYearSlash,
    width: 200,
    //TODO: Coerce dates to quarter format
    // editCellConfig: {
    //   type: CONDENSED_DATE_INPUT,
    //   updateFn,
    // },
  },
  {
    Header: 'Indications (for permissions)',
    accessor: 'indication_permissions', // injected in custom hook from connection 'indicationIds' field
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    width: 200,
    labelMap: indicationLabelMap,
    editCellConfig: {
      type: CONDENSED_MULTI_SELECT_INPUT,
      options: indicationsOptions,
      updateFn,
    },
  },
  {
    Header: 'Date Updated',
    accessor: 'updated_at',
    Filter: DateMultiSelectFilter,
    filter: DATE_MULTI_SELECT,
    sortType: DATE,
    cellValueFormatter: formatMonthDayYearSlash,
    sticky: 'left',
    width: 200,
  },
]
