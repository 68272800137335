import React from 'react'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

import { ListItem } from '../shared/styledComponents'

const DashboardToolPanelItem = ({
  data,
  isSelected,
  handleClick,
  searchParamKey,
}) => {
  const listItemHandleClick = isSelected
    ? () => {}
    : () => handleClick(data[searchParamKey])

  const listItemActiveStyle = {
    background: isSelected ? transparentize(0.9, Colors.PRIMARY) : null,
    color: isSelected ? Colors.PRIMARY : Colors.BLACK,
    fontWeight: isSelected ? 700 : 400,
  }

  return (
    <ListItem onClick={listItemHandleClick} style={listItemActiveStyle}>
      <div>
        <div>{data.text.title}</div>
      </div>
    </ListItem>
  )
}

export default DashboardToolPanelItem
