export const schemaV0 = `
  id
  person
  secondary_organization
  dynamic_status
  committees
  title
  tdg_internal_note
  start_date
  end_date
  created_at
  updated_at
`
