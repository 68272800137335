import React from 'react'

const Caution = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg viewBox="0 -2 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        className="icon-color-1"
        d="M17.7733 2.009C20.0745 2.009 21.94 3.87448 21.94 6.17567V17.8423C21.94 20.1435 20.0745 22.009 17.7733 22.009H6.10667C3.80548 22.009 1.94 20.1435 1.94 17.8423V6.17567C1.94 3.87448 3.80548 2.009 6.10667 2.009H17.7733ZM12.44 16.009H11.44C10.8877 16.009 10.44 16.4567 10.44 17.009V18.009C10.44 18.5613 10.8877 19.009 11.44 19.009H12.44C12.9923 19.009 13.44 18.5613 13.44 18.009V17.009C13.44 16.4567 12.9923 16.009 12.44 16.009ZM12.44 6.009H11.44C10.8877 6.009 10.44 6.45672 10.44 7.009V13.009C10.44 13.5613 10.8877 14.009 11.44 14.009H12.44C12.9923 14.009 13.44 13.5613 13.44 13.009V7.009C13.44 6.45672 12.9923 6.009 12.44 6.009Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default Caution
