import { DataProxy } from '@apollo/client'

import { GET_USERS } from 'frontend/entity-hooks/ember/useEmberUser/gql-tags'

export const addUserToCache = (variables: any, user: any, cache: DataProxy) => {
  const query: any = cache.readQuery({
    query: GET_USERS,
    variables,
  })

  if (query) {
    const queryName = Object.keys(query)[0]

    cache.writeQuery({
      query: GET_USERS,
      variables,
      data: {
        [queryName]: [user, ...query[queryName]],
      },
    })
  }
}
