import React from 'react'
import { Typography } from '@mui/material'

export const DocumentLastLoadDate = ({ date }: { date: string }) => {
  const formattedDate = new Date(date).toLocaleDateString()
  const LAST_LOAD_DATE = `Document last load date: ${formattedDate}`

  return (
    <Typography
      sx={{
        color: '#808A96',
        fontSize: '12px',
        fontFamily: 'Inter',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '0.20000000298023224px',
        textAlign: 'left',
      }}
    >
      {LAST_LOAD_DATE}
    </Typography>
  )
}
