import React from 'react'
import { useQuery } from '@apollo/client'

import { Spinner } from 'frontend/components'
import { useEmberNode } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'

import AccountsListPanel from './AccountsListPanel'
import { getAccountsQueryGqlTag } from '../../utils'

const InnerContainer = ({
  areParentResourcesLoading,
  accounts,
  parentResources,
  setResources,
}) => {
  const { node, nodeType, tool } = useQueryString()

  const { data } = useEmberNode({
    queryInput: {
      type: 'tool',
      id: nodeType === 'tool' ? node : tool,
    },
  })

  const { loading: areAccountsLoading } = useQuery(
    getAccountsQueryGqlTag(data[0].permitted_entity_types)
  )

  if (areParentResourcesLoading || areAccountsLoading || !accounts)
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '75%',
          justifyContent: 'center',
        }}
      >
        <Spinner />
      </div>
    )

  return (
    <AccountsListPanel
      accounts={accounts}
      parentResources={parentResources}
      setResources={setResources}
    />
  )
}

export default InnerContainer
