import { MultiSelectFilter } from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'
import {
  FILTER_TYPES,
  SORT_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'

const { MULTI_SELECT } = FILTER_TYPES
const { STRING, TEXT_ARRAY } = SORT_TYPES

const getColConfig = () => [
  {
    Header: 'Client',
    accessor: 'client',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 200,
  },
  {
    Header: 'Tool',
    accessor: 'tool',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 200,
  },
  {
    Header: 'Indication',
    accessor: 'indication',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 200,
  },
  {
    Header: 'Regimen',
    accessor: 'regimen',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 300,
  },
  {
    Header: 'Teams',
    accessor: 'teams',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: TEXT_ARRAY,
    width: 500,
  },
]

export default getColConfig
