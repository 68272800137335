import { gql } from '@apollo/client'

import { schemaV0 } from './schemas'

export const GET_TEAMS_NODES_RESOURCES = gql`
  query GetTeamsNodesResources($input: TeamsNodesResourcesInput!) {
    teamsNodesResources(input: $input) {
      ${schemaV0}
    }
  }
`
