import { INPUT_TYPES } from 'frontend/components/Form/constants/input-type-to-component-map'

import { Colors } from 'frontend/utils/pulseStyles'

const { SINGLE_SELECT, TEXT } = INPUT_TYPES

const singleSelectStyle = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: Colors.COOL_GRAY_100,
    border: 'none',
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: 12,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontSize: 13,
    color: Colors.GRAY_600,
  }),
}

interface Props {
  clientOptions: any
  clientSourceOptions: any
}

export const getFormConfig = ({
  clientOptions,
  clientSourceOptions,
}: Props) => [
  [
    {
      label: 'Team Name',
      accessor: 'name',
      inputType: TEXT,
      style: {
        backgroundColor: Colors.COOL_GRAY_100,
      },
    },
    {
      label: 'Team Description (optional)',
      accessor: 'description',
      inputType: TEXT,
      style: {
        backgroundColor: Colors.COOL_GRAY_100,
      },
    },
    {
      label: 'Client',
      accessor: 'client',
      inputType: SINGLE_SELECT,
      options: clientOptions,
      placeholder: null,
      style: singleSelectStyle,
    },
    {
      label: 'Client Source',
      accessor: 'client_source',
      inputType: SINGLE_SELECT,
      options: clientSourceOptions,
      placeholder: null,
      style: singleSelectStyle,
    },
    {
      label: 'Default Landing Page',
      accessor: 'default_landing_page',
      inputType: TEXT,
      style: {
        backgroundColor: Colors.COOL_GRAY_100,
      },
    },
    {
      label: 'Internal Note',
      accessor: 'tdg_internal_note',
      inputType: TEXT,
      style: {
        backgroundColor: Colors.COOL_GRAY_100,
      },
    },
  ],
]
