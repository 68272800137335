import React from 'react'
import { transparentize } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { GET_SOURCE_INDICATIONS } from 'frontend/api/queries'

import { UPDATE_INDICATION } from 'frontend/api/mutations'

import { Colors, Spacing } from 'frontend/utils/pulseStyles'

import {
  StyledInput,
  FormLabel,
  createObjectModalStyle,
  defaultPanelItemStyle,
} from '../../../../Organizations/styledComponents'
import Checkbox from 'frontend/components/Checkbox'

import ModalButtonWithForm from '../../../../shared/ModalButtonWithForm'
import RegimensSelect from '../RegimensSelect'

import { DataExtractionFlagTag } from '../shared'

const activePanelItemStyle = {
  backgroundColor: transparentize(0.85, Colors.PRIMARY),
  color: Colors.PRIMARY,
}

const checkboxStyle = {
  marginTop: 30,
  marginRight: 5,
  marginBottom: 30,
}

const Wrapper = styled.div(defaultPanelItemStyle, ({ style }) => ({
  ...style,
}))

const editIcon = <FontAwesomeIcon size="lg" icon={faEdit} />

const NAME = 'is_flagged_for_data_extraction'
const LABEL = 'Flag For Data Extraction'

const getInputFields = (state, handleChange) => (
  <>
    <div>
      <FormLabel>name: </FormLabel>
      <StyledInput
        type="text"
        name="name"
        onChange={handleChange}
        value={state.input.name}
      />
    </div>

    <div style={{ marginTop: Spacing.LARGE }}>
      <FormLabel>regimens: </FormLabel>
      <RegimensSelect state={state} handleChange={handleChange} />
    </div>

    <div style={{ marginBottom: Spacing.SMALL }}>
      <Checkbox
        name={NAME}
        onChange={handleChange}
        value={state.input.is_flagged_for_data_extraction}
        label={LABEL}
        style={checkboxStyle}
        labelStyle={{ fontWeight: 'bold' }}
      />
    </div>
  </>
)

const getButtonGroup = ({
  name,
  _id,
  regimens,
  is_flagged_for_data_extraction,
}) => (
  <ModalButtonWithForm
    modalTitle="Edit Indication"
    buttonLabel={editIcon}
    modalStyle={createObjectModalStyle}
    buttonStyle={{
      border: 'none',
      background: 'none',
      color: transparentize(0.7, Colors.BLACK),
    }}
    data={{ input: { name, _id, regimens, is_flagged_for_data_extraction } }}
    mutationDoc={UPDATE_INDICATION}
    refetchQueries={[{ query: GET_SOURCE_INDICATIONS }]}
    getInputFields={getInputFields}
  />
)

const IndicationsPanelListItem = ({
  data,
  isSelected,
  handleClick,
  searchParamKey,
}) => {
  const listItemHandleClick = isSelected
    ? () => null
    : () => handleClick(data[searchParamKey])

  const style = isSelected ? activePanelItemStyle : {}

  return (
    <Wrapper onClick={listItemHandleClick} style={style}>
      <div style={{ display: 'flex' }}>
        <div>{data.name}</div>
        <DataExtractionFlagTag
          isFlagged={data.is_flagged_for_data_extraction}
        />
      </div>

      <div>{getButtonGroup(data)}</div>
    </Wrapper>
  )
}

IndicationsPanelListItem.propTypes = {
  data: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  searchParamKey: PropTypes.string.isRequired,
}

export default IndicationsPanelListItem
