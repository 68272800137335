import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

import { useTeam, useClient } from 'frontend/entity-hooks/ember'
import { Colors } from 'frontend/utils/pulseStyles'
import { ClientSelect } from './ClientSelect'
import { TeamSelect } from './TeamSelect'

import LabelGroup from './LabelGroup'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

const Wrapper = styled.div({
  padding: 20,
  display: 'grid',
  alignItems: 'center',
  borderBottom: `2px solid ${Colors.COOL_GRAY_200}`,
})

const Header = () => {
  const { data: clients, isLoading: areClientsLoading } = useClient()
  const { data: teams, isLoading: areTeamsLoading } = useTeam()
  const location = useLocation()
  const { client: clientId, team: teamId }: any = queryString.parse(
    location.search
  )
  const [selectedClient, setSelectedClient] = useState<any>(null)
  const [selectedTeam, setSelectedTeam] = useState<any>(null)

  useEffect(() => {
    if (clients && teams) {
      const matchedClient = (clients as any[]).find(
        (client: any) => client.id === clientId
      )
      const matchedTeam = (teams as any[]).find(
        (team: any) => team.id === teamId
      )
      setSelectedClient(matchedClient || null)
      setSelectedTeam(matchedTeam || null)
    }
  }, [clients, teams, clientId, teamId])

  return (
    <Wrapper>
      <LabelGroup />
      {areClientsLoading || areTeamsLoading ? (
        <>Loading...</>
      ) : (
        <>
          <ClientSelect
            clients={clients}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            setSelectedTeam={setSelectedTeam}
          />
          <TeamSelect
            teams={teams}
            selectedTeam={selectedTeam}
            selectedClient={selectedClient}
            setSelectedTeam={setSelectedTeam}
          />
        </>
      )}
    </Wrapper>
  )
}

export default Header
