import React, { Dispatch, SetStateAction } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { GET_SOURCE_INDICATIONS } from 'frontend/api/queries'
import { useQueryString } from 'frontend/hooks'
import { Colors } from 'frontend/utils/pulseStyles'
import { NODE_TYPE_TO_PARENT_TYPE } from 'frontend/ClientManagement/Sitemap/constants'
import { Params } from 'frontend/ClientManagement/Sitemap/types'

import { ToggleAllButton } from '../../shared'

interface Props {
  disabled: boolean
  parentResources: any[]
  setResources: Dispatch<SetStateAction<any>>
}

const ToggleOnAllButton = ({
  disabled,
  parentResources,
  setResources,
}: Props) => {
  const { team }: Params = useParams()

  const { node, nodeType }: any = useQueryString()
  const parentType = NODE_TYPE_TO_PARENT_TYPE[nodeType]

  const { data: indications } = useQuery(GET_SOURCE_INDICATIONS)

  const toggleOnAllTreatmentPlans = () => {
    setResources(({ treatmentPlans, ...rest }: any) => {
      if (parentType) {
        treatmentPlans = parentResources.reduce(
          (acc: any[], {
            id: teamParentResource,
            resource: {
              entity_id,
              type: resourceType,
            },
            children,
          }: any) => {
            if (resourceType !== 'indication') return acc

            acc.push({
              team,
              [nodeType]: node,
              resource: entity_id,
              [`team_${parentType}_resource`]: teamParentResource,
              children: children.map(({
                id: teamParentResource,
                resource: {
                  entity_id,
                },
              }: any) => ({
                team,
                [nodeType]: node,
                resource: entity_id,
                [`team_${parentType}_resource`]: teamParentResource,
              })),
            })

            return acc
          }, []
        )
      } else {
        treatmentPlans = indications.indications.reduce(
          (acc: any[], { _id, regimens }: any) => {
            acc.push({
              team,
              [nodeType]: node,
              resource: _id,
              children: regimens.map(({ _id }: any) => ({
                team,
                [nodeType]: node,
                resource: _id,
              })),
            })

            return acc
          }, []
        )
      }

      return {
        treatmentPlans,
        ...rest
      }
    })
  }

  return (
    <ToggleAllButton
      disabled={disabled}
      color={Colors.GREEN}
      onClick={toggleOnAllTreatmentPlans}
      style={{ marginRight: '20px' }}
    >
      Toggle on All Indications + Regimens
    </ToggleAllButton>
  )
}

export default ToggleOnAllButton
