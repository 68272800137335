import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import FlatFileList from './FlatFileList/FlatFileList'

const FlatFileExport = () => (
  <Switch>
    <Route path="/flat-file-export/list" component={FlatFileList} />
    <Redirect to="/flat-file-export/list" />
  </Switch>
)

export default FlatFileExport
