import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

import { ActionBar } from './ActionBar'
import { Body } from './Body'
import { Header } from './Header/Header'

interface Props {
  hasHazardIcon?: boolean
  headerText: string
  bodyText: string
  deleteParams: string[]
  handleSubmit: Function
}

const Wrapper = styled.div({
  borderRadius: 8,
  backgroundColor: Colors.WHITE,
  position: 'relative',
  padding: 24,
  width: 450,
  height: 202,
  display: 'flex',
  flexDirection: 'column',
})

export const Content = ({
  hasHazardIcon,
  headerText,
  bodyText,
  deleteParams,
  handleSubmit,
}: Props) => {
  return (
    <Wrapper>
      <Header hasHazardIcon={hasHazardIcon} headerText={headerText} />
      <Body bodyText={bodyText} />
      <ActionBar handleSubmit={handleSubmit} deleteParams={deleteParams} />
    </Wrapper>
  )
}
