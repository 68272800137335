import * as CRUD_UTILS from '../../api/utils'

import { getImportPolicyLinkConfigs } from './getImportPolicyLinkConfigs'

export const useImportPolicyLink = ({
  deleteOptions,
  queryInput,
  updateInput,
}: any = {}) => {
  const { updateConfig } = getImportPolicyLinkConfigs(queryInput, updateInput)

  const [update, { loading: isUpdating }] = CRUD_UTILS.useUpdate(updateConfig)

  return {
    update,
    isUpdating,
  }
}
