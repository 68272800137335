export const schemaV1 = `
  id
  npi
  first_name
  middle_name
  last_name
  full_name
  primary_state {
    id
    full_name
    abbreviation
  }
  professional_profile
  twitter_profile
  twitter_username
  linkedin_profile
  affiliation
  affiliation_position
  created_at
  updated_at
`
