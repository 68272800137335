export const schemaV0 = `
  id
  name
  description
  client
  client_source
  default_landing_page
  tdg_internal_note
  created_at
  updated_at
`
