import { gql } from '@apollo/client'

export const UPDATE_TEAM_NODES = gql`
  mutation UpdateTeamsNodes($input: UpdateEmberTeamNodeInput!) {
    updateEmberTeamNode(input: $input) {
      id
      team_id
      text
      order
      node_id
    }
  }
`
