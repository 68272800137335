import { useQuery } from '@apollo/client'
import { DocumentNode } from 'graphql'

import { unwrapGqlData } from 'frontend/utils'

export const useCacheData = ({ tag }: { tag: DocumentNode }) => {
  const { data: cacheData } = useQuery(tag, {
    fetchPolicy: 'cache-only'
  })

  const data = unwrapGqlData(cacheData)

  return { data }
}
