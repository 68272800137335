import React, { Dispatch, SetStateAction } from 'react'

import { useTeam } from 'frontend/entity-hooks/ember'

import { TeamsContent } from './TeamsContent'

interface Props {
  selectedClientId: string
  selectedTeams: [any]
  setFieldState: Dispatch<SetStateAction<any>>
}

export const Teams = ({
  selectedClientId,
  selectedTeams,
  setFieldState,
}: Props) => {
  const { isLoading, data: teams }: any = useTeam({
    queryInput: { client: selectedClientId },
  })

  return (
    <TeamsContent
      isLoading={isLoading}
      selectedTeams={selectedTeams}
      clientTeams={teams}
      setFieldState={setFieldState}
    />
  )
}
