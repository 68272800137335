import React from 'react'
import { transparentize } from 'polished'

import { Tag } from '@pulse-analytics/pulse-design-system'
import Color from 'frontend/utils/color'

import {
  BROKEN_PULSE,
  BROKEN_TDG,
  NOT_APPLICABLE,
  NEEDS_APPROVAL,
  APPROVED,
  INCORRECT_POLICY,
  NO_POLICY_CHANGE,
  NOT_TRACKED,
  GOOD,
  PENDING,
  WATCHED,
} from './utils/enums'

const COLOR_CONFIG = {
  [NOT_APPLICABLE]: Color.GRAY_DARK,
  [NEEDS_APPROVAL]: Color.ORANGE,
  [APPROVED]: Color.GREEN,
  [INCORRECT_POLICY]: Color.RED,
  [NO_POLICY_CHANGE]: Color.YELLOW,
  [NOT_TRACKED]: Color.GRAY_DARK,
}

const LABEL_COLOR_CONFIG = {
  [BROKEN_PULSE]: Color.RED,
  [BROKEN_TDG]: Color.RED,
  [GOOD]: Color.GREEN,
  [PENDING]: Color.ORANGE,
  [WATCHED]: Color.GREEN,
}

const StatusTag = ({ value, labelMap = {} }) => {
  if (!value) return null
  let tagValue = value
  if (labelMap[value]) {
    tagValue = labelMap[value]
  }

  let color
  if (LABEL_COLOR_CONFIG[tagValue]) {
    color = LABEL_COLOR_CONFIG[tagValue]
  } else if (COLOR_CONFIG[value]) {
    color = COLOR_CONFIG[value]
  } else {
    color = Color.GRAY_DARK
  }

  const background = transparentize(0.85, color)

  return (
    <Tag
      color={color}
      style={{
        border: 'none',
        background: background,
        padding: '2px 8px',
        textTransform: 'none',
      }}
    >
      {tagValue}
    </Tag>
  )
}

export default StatusTag
