import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import Color from 'frontend/utils/color'

import { Colors } from '../../../../../utils/pulseStyles'

const Header = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 700,
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 100, // ! should be sourced from centralized style-guide file in the future
  background: Colors.WHITE,
  borderBottom: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
})

const Title = styled.div({
  color: Color.BLACK,
  fontSize: 12,
  fontWeight: 700,
  letterSpacing: '0.2px',
  padding: 24,
  textTransform: 'uppercase',
})

const IndicationsPanelListHeader = () => (
  <Header>
    <Title>Indications</Title>
  </Header>
)

export default IndicationsPanelListHeader
