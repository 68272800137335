import React from 'react'

import { PolarisDialog } from 'frontend/components/PolarisDialog'

import { Content } from './Content'

interface Props {
  hasHazardIcon?: boolean
  headerText: string
  bodyText: string
  deleteParams: string[]
  handleSubmit: Function
}

export const EmberDeleteDialog = ({
  hasHazardIcon,
  headerText,
  bodyText,
  deleteParams,
  handleSubmit,
}: Props) => {
  return (
    <PolarisDialog>
      <Content
        hasHazardIcon={hasHazardIcon}
        headerText={headerText}
        bodyText={bodyText}
        deleteParams={deleteParams}
        handleSubmit={handleSubmit}
      />
    </PolarisDialog>
  )
}
