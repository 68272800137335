import React from 'react'
import _ from 'lodash'
import PopulationsPanel from './PopulationsPanel'

const PopulationsPanelContainer = ({
  selectedIndicationId,
  selectedRegimenId,
  selectedPopulationId,
  selectPopulation,
  baseTreatmentPlans,
  enabledTreatmentPlans,
  setStagedTreatmentPlans,
  enabledTreatmentPlansHash,
}) => {
  const selectedIndication = _.cloneDeep(
    baseTreatmentPlans.find(({ _id }) => _id === selectedIndicationId) || {
      regimens: [],
    }
  )
  const selectedRegimen = selectedIndication.regimens.find(
    ({ _id }) => _id === selectedRegimenId
  ) || { populations: [] }

  const targetInd = enabledTreatmentPlans.find(
    ({ _id }) => _id === selectedIndication._id
  )

  const isSelectedIndEnabled = Boolean(targetInd)

  const targetRegimen = isSelectedIndEnabled
    ? targetInd.regimens.find(({ _id }) => _id === selectedRegimenId)
    : null

  const isSelectedRegEnabled = Boolean(targetRegimen)

  const isIndicationInHash = enabledTreatmentPlansHash[selectedIndication._id]
  const enabledPopulationHash = isIndicationInHash
    ? enabledTreatmentPlansHash[selectedIndication._id][selectedRegimenId]
    : {}

  const enablePopulation = (populationObj) => {
    const copyPopulation = _.cloneDeep(populationObj)

    selectPopulation(copyPopulation)

    const { _id, name } = copyPopulation
    const populationToEnable = { _id, name, lines: [] }

    if (!isSelectedRegEnabled) {
      selectedRegimen.populations = [populationToEnable]
      if (!isSelectedIndEnabled) {
        selectedIndication.regimens = [{ ...selectedRegimen }]
        enabledTreatmentPlans = enabledTreatmentPlans.concat([
          selectedIndication,
        ])
      } else {
        targetInd.regimens = targetInd.regimens.concat([selectedRegimen])
      }
    } else {
      targetRegimen.populations = targetRegimen.populations.concat([
        populationToEnable,
      ])
    }
    const activeTreatmentPlans = _.cloneDeep(enabledTreatmentPlans)
    setStagedTreatmentPlans(activeTreatmentPlans)
  }

  const disablePopulation = ({ _id, name }) => {
    const populationToDisable = { _id, name, lines: [] }

    const populationIndexToDelete = targetRegimen.populations.findIndex(
      ({ _id }) => _id === populationToDisable._id
    )
    targetRegimen.populations.splice(populationIndexToDelete, 1)

    setStagedTreatmentPlans(enabledTreatmentPlans)
  }

  let enabledPopulations = []
  // targetInd.regimens has already been ordered appropriately
  if (isSelectedRegEnabled) enabledPopulations = targetRegimen.populations
  const disabledPopulations = selectedRegimen.populations.filter(({ _id }) => {
    return !enabledPopulationHash || !enabledPopulationHash[_id]
  })

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const [removedItem] = enabledPopulations.splice(oldIndex, 1)
    enabledPopulations.splice(newIndex, 0, removedItem)
    targetRegimen.populations = enabledPopulations // this prob isn't needed as targetInd.regimens likely is mutated at this point
    setStagedTreatmentPlans(enabledTreatmentPlans)
  }

  return (
    <PopulationsPanel
      selectedPopulationId={selectedPopulationId}
      selectPopulation={selectPopulation}
      selectedRegimen={selectedRegimen}
      enabledPopulations={enabledPopulations}
      disabledPopulations={disabledPopulations}
      enablePopulation={enablePopulation}
      disablePopulation={disablePopulation}
      onSortEnd={onSortEnd}
    />
  )
}

export default PopulationsPanelContainer
