import React from 'react'

import { SingleActionDialog } from 'frontend/components/Dialog'

import Color from 'frontend/utils/color'
import FontSpace from 'frontend/utils/fontspace'

import { useHistoricalPositioningData } from './utils'

const DELETE_HISTORICAL_POSITION = 'Delete Historical Position'

const DIALOG_STYLE = {
  HEADER: { color: Color.RED },
  CONTENT: { width: 450 },
}
const DIALOG_SUBMIT_COLOR = Color.RED

const DeleteRowDialog = ({ selectedRowToDelete, clearSelectedRowToDelete }) => {
  const historicalPositioningData = useHistoricalPositioningData()

  const submitHandler = () => {
    const { id } = selectedRowToDelete
    const input = { id }
    historicalPositioningData.delete({ variables: { input } })
    clearSelectedRowToDelete()
  }

  return (
    <SingleActionDialog
      header={DELETE_HISTORICAL_POSITION}
      submitText={DELETE_HISTORICAL_POSITION}
      submitHandler={submitHandler}
      cancelHandler={clearSelectedRowToDelete}
      headerStyle={DIALOG_STYLE.HEADER}
      submitColor={DIALOG_SUBMIT_COLOR}
      contentStyle={DIALOG_STYLE.CONTENT}
    >
      <div style={{ padding: 36, textAlign: 'center', ...FontSpace.FS3 }}>
        <p>Are you sure you want to delete this historical data?</p>
        <p style={{ fontWeight: 700, marginTop: 12 }}>THIS CANNOT BE UNDONE</p>
      </div>
    </SingleActionDialog>
  )
}

export default DeleteRowDialog
