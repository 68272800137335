import React from 'react'

const Filter = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="-2 -7 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.999919 0.666748H12.9999C13.3681 0.666748 13.6666 0.965225 13.6666 1.33341V2.00008C13.6666 2.36827 13.3681 2.66675 12.9999 2.66675H0.999919C0.631729 2.66675 0.333252 2.36827 0.333252 2.00008V1.33341C0.333252 0.965225 0.631729 0.666748 0.999919 0.666748ZM2.99992 4.00008H10.9999C11.3681 4.00008 11.6666 4.29856 11.6666 4.66675V5.33341C11.6666 5.7016 11.3681 6.00008 10.9999 6.00008H2.99992C2.63173 6.00008 2.33325 5.7016 2.33325 5.33341V4.66675C2.33325 4.29856 2.63173 4.00008 2.99992 4.00008ZM4.99992 7.33341H8.99992C9.36811 7.33341 9.66659 7.63189 9.66659 8.00008V8.66675C9.66659 9.03494 9.36811 9.33341 8.99992 9.33341H4.99992C4.63173 9.33341 4.33325 9.03494 4.33325 8.66675V8.00008C4.33325 7.63189 4.63173 7.33341 4.99992 7.33341Z"
        fill={iconColor}
      />
    </svg>
  )
}
export default Filter
