import { useQuery } from '@apollo/client'

import {
  useBooksOfBusiness,
  usePayerOrganizations,
  usePayerPolicyTrackingProjects,
} from 'frontend/api'

import { useProductPolicyLink, usePayerPolicyLink } from 'frontend/entity-hooks'

import { usePolarisUsers } from 'frontend/api/usePolarisUsers/usePolarisUsers'
import { GET_VEGA_PRODUCTS } from 'frontend/api/queries'

import { getPayerProductData } from 'frontend/PayerPolicyLinkMgmt/v0/utils/getPayerProductData'
import { getPayerPolicyOptions } from 'frontend/PayerPolicyLinkMgmt/v0/utils/getPayerPolicyOptions'

const usePayerPolicyLinkMgmtData = () => {
  // * step 1: grab all necessary data
  const {
    data: productPolicyData,
    isLoading: areProductPoliciesLoading,
    options: productPolicyOptions,
    areOptionsLoading: areProductPolicyOptionsLoading,
    create: createProductPolicy,
    isCreating: isProductPolicyCreating,
    update: updateProductPolicy,
  } = useProductPolicyLink()

  const {
    data: payerPolicyData,
    isLoading: arePayerPoliciesLoading,
    options: payerPolicyOptions,
    areOptionsLoading: arePayerPolicyOptionsLoading,
    create: createPayerPolicy,
    isCreating: isPayerPolicyCreating,
    update: updatePayerPolicy,
  } = usePayerPolicyLink()

  const { data: books, isLoading: areBooksLoading } = useBooksOfBusiness()
  const { data: payers, isLoading: arePayersLoading } = usePayerOrganizations()
  const { data: products, loading: areProductsLoading } = useQuery(
    GET_VEGA_PRODUCTS
  )
  const {
    data: projectsData,
    isLoading: areProjectsLoading,
  } = usePayerPolicyTrackingProjects()

  const { data: usersData, isLoading: areUsersLoading } = usePolarisUsers()

  const isDataLoading =
    areProductPoliciesLoading ||
    areProductPolicyOptionsLoading ||
    arePayerPoliciesLoading ||
    arePayerPolicyOptionsLoading ||
    areBooksLoading ||
    areProductsLoading ||
    arePayersLoading ||
    areUsersLoading ||
    areProjectsLoading

  let policyOptions = {}
  let data = []

  if (!isDataLoading) {
    // * step 2: grab full set of filter options
    policyOptions = getPayerPolicyOptions({
      productPolicyOptions,
      payerPolicyOptions,
      payers,
      products,
      books,
      usersData,
      projectsData,
    })

    // * step 3: monster mash together product policy + policy link data
    data = getPayerProductData({
      payers,
      payerPolicyData,
      productPolicyData,
      policyOptions,
    })
  }

  return {
    isLoading: isDataLoading,
    data,
    policyOptions,
    updateConfig: {
      updateProductPolicy,
      updatePayerPolicy,
    },
    createConfig: {
      createProductPolicy,
      isProductPolicyCreating,
      createPayerPolicy,
      isPayerPolicyCreating,
    },
  }
}

export default usePayerPolicyLinkMgmtData
