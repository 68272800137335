import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

import Header from './Header'

const BORDER = `1px solid ${transparentize(0.9, Colors.BLACK)}`

const Wrapper = styled.section({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '12px 24px 13px',
  borderBottom: BORDER,
})

const TeamsPanelHeader = () => {
  return (
    <Wrapper>
      <Header />
    </Wrapper>
  )
}

export default TeamsPanelHeader
