import * as GQL_TAGS from './gql-tags'

export const getTeamConfigs = (
  deleteOptions = {},
  queryInput: any,
  createVariables: any
) => ({
  createConfig: {
    tag: GQL_TAGS.CREATE_TEAM,
    readTag: GQL_TAGS.GET_TEAMS,
    variables: createVariables,
  },
  updateConfig: {
    tag: GQL_TAGS.UPDATE_TEAM,
  },
  deleteConfig: {
    tag: GQL_TAGS.DELETE_TEAM,
    readTag: GQL_TAGS.GET_TEAMS,
    mutationOptions: deleteOptions,
  },
  readConfig: {
    tag: GQL_TAGS.GET_TEAMS,
    input: queryInput,
  },
  optionsConfig: {
    tag: GQL_TAGS.GET_TEAM_OPTIONS,
  },
})
