import React from 'react'

import { useEmberNode } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'

import { ParentType, Type } from '../../../types'
import LoadingPanel from './LoadingPanel'
import NodesListPanel from './NodesListPanel'

interface Props {
  type: Type
  parentType?: ParentType
}

const InnerContainer = ({
  type,
  parentType,
  ...props
}: Props) => {
  const queryString = useQueryString()

  let queryInput = { type }
  if (parentType) queryInput = {
    [parentType]: queryString[parentType],
    ...queryInput
  }

  const { isLoading } = useEmberNode({ queryInput })

  if (isLoading) return (
    <LoadingPanel />
  )

  return (
    <NodesListPanel
      type={type}
      parentType={parentType}
      {...props}
    />
  )
}

export default InnerContainer
