import React from 'react'

import MessageDialog from './MessageDialog'
export interface MessageDialogContainerProps {
  message: string | undefined
  header: string
  iconLink: string
  showModal: boolean
  handleSubmit: any
}

const MessageDialogContainer = ({
  message,
  header,
  iconLink,
  showModal,
  handleSubmit,
}: MessageDialogContainerProps) => {
  if (!showModal) return null

  return (
    <MessageDialog
      message={message}
      header={header}
      iconLink={iconLink}
      handleSubmit={handleSubmit}
    />
  )
}

export default MessageDialogContainer
