import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'

import { GET_CLIENTS } from 'frontend/api/queries'

const formatOption = ({ name }: any) => ({ value: name, label: name })

const useClientData = () => {
  const { data: clientsData, loading: isClientLoading } = useQuery(GET_CLIENTS)

  const optionsData = useMemo(() => {
    if (!isClientLoading) {
      const options = clientsData.clients.map(formatOption)
      return options
    }
  }, [clientsData])

  return {
    clients: { options: optionsData, loading: isClientLoading },
  }
}

export default useClientData
