import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const CREATE_TEAM = gql`
  mutation CreateTeam($input: CreateTeamInput_EMBER!) {
    createTeam_EMBER(input: $input) {
      ${schemaV0}
    }
  }
`
