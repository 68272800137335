import React from 'react'

import { CopyButton } from '../../CopyButton'
import { SourceButton } from './SourceButton'

interface NewPolicyControlsProps {
  policyDocLinkChange: string
  policyDocLinkChangeSource: string
}

export const NewPolicyControls = ({
  policyDocLinkChange,
  policyDocLinkChangeSource,
}: NewPolicyControlsProps) => {
  return (
    <section style={{ display: 'flex' }}>
      <CopyButton text={policyDocLinkChange} />
      <SourceButton policyDocLinkChangeSource={policyDocLinkChangeSource} />
    </section>
  )
}
