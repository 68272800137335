import React from 'react'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'
import { transparentize, mix } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons'
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import '../sortableContainerStyles.css'
import { Colors, Spacing } from '../../../../utils/pulseStyles'
import { Span } from '../styledComponents'
import {
  IndicationPanelContainer,
  ListHeader,
  ActiveRow,
  InactiveRow,
  UnorderedList,
  ListRow,
} from '../styledComponents'

const DragHandle = sortableHandle(() => (
  <span
    style={{
      padding: `${Spacing.SMALL} ${Spacing.NORMAL}`,
      opacity: 0.3,
      fontSize: 16,
    }}
  >
    <FontAwesomeIcon icon={faArrowsAltV} color={Colors.BLACK} />
  </span>
))

const SWITCH_COLOR = Colors.GREEN

const PhoenixSwitch = withStyles({
  switchBase: {
    color: mix(0.4, Colors.BLACK, Colors.WHITE),
    '&$checked': {
      color: SWITCH_COLOR,
    },
    '&$checked + $track': {
      backgroundColor: SWITCH_COLOR,
    },
  },
  checked: {},
  track: {
    backgroundColor: transparentize(0.7, Colors.BLACK),
  },
})(Switch)

const SortableItem = sortableElement(
  ({
    indication,
    disableIndication,
    selectedIndicationId,
    selectIndication,
  }) => {
    const isSelected = indication._id === selectedIndicationId
    return (
      <ListRow
        isSelected={isSelected}
        isActive
        onClick={() => selectIndication(indication)}
      >
        <div>
          <DragHandle />
          <Span isSelected={isSelected}>{indication.name}</Span>
        </div>
        <PhoenixSwitch
          checked
          value={indication._id}
          onChange={() => disableIndication(indication)}
        />
      </ListRow>
    )
  }
)

const SortableContainer = sortableContainer(({ children }) => {
  return <UnorderedList>{children}</UnorderedList>
})

const IndicationsPanel = ({
  selectedIndicationId,
  selectIndication,
  enabledTreatmentPlans,
  disabledTreatmentPlans,
  enableIndication,
  disableIndication,
  onSortEnd,
}) => {
  return (
    <IndicationPanelContainer>
      <ListHeader>Indications</ListHeader>
      <ActiveRow>ACTIVE ({enabledTreatmentPlans.length})</ActiveRow>
      <SortableContainer
        onSortEnd={onSortEnd}
        helperClass="sortableHelper"
        useDragHandle
      >
        {enabledTreatmentPlans.map((indication, index) => (
          <SortableItem
            key={indication._id}
            index={index}
            indication={indication}
            disableIndication={disableIndication}
            selectedIndicationId={selectedIndicationId}
            selectIndication={selectIndication}
          />
        ))}
      </SortableContainer>

      <InactiveRow>INACTIVE ({disabledTreatmentPlans.length})</InactiveRow>
      <UnorderedList>
        {disabledTreatmentPlans.map((indication) => {
          const isSelected = indication._id === selectedIndicationId
          return (
            <ListRow
              isSelected={isSelected}
              key={indication._id}
              onClick={() => selectIndication(indication)}
            >
              <Span>{indication.name}</Span>
              <PhoenixSwitch
                checked={false}
                value={indication._id}
                onChange={() => enableIndication(indication)}
              />
            </ListRow>
          )
        })}
        <div style={{ marginTop: '25px' }} />
      </UnorderedList>
    </IndicationPanelContainer>
  )
}

export default IndicationsPanel
