import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { transparentize } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import { UPDATE_SOURCE_REGIMEN } from 'frontend/api/mutations'

import { GET_SOURCE_INDICATIONS } from 'frontend/api/queries'

import { Colors, Spacing } from 'frontend/utils/pulseStyles'

import {
  FormLabel,
  createObjectModalStyle,
  defaultPanelItemStyle,
} from '../../../../Organizations/styledComponents'
import Checkbox from 'frontend/components/Checkbox'

import ModalButtonWithForm from '../../../../shared/ModalButtonWithForm'

import { DataExtractionFlagTag } from '../shared'

const activePanelItemStyle = {
  backgroundColor: transparentize(0.85, Colors.PRIMARY),
  color: Colors.PRIMARY,
}

const editIcon = <FontAwesomeIcon size="lg" icon={faEdit} />

const Wrapper = styled.div(defaultPanelItemStyle, ({ style }) => ({
  ...style,
}))

const LABEL = 'Flag For Data Extraction'
const NAME = 'is_flagged_for_data_extraction'

const checkboxStyle = {
  marginTop: 30,
  marginRight: 5,
  marginBottom: 30,
}
const getInputFields = (state, handleChange) => (
  <>
    <div>
      <FormLabel>{state.input.name}</FormLabel>
    </div>

    <Checkbox
      name={NAME}
      onChange={handleChange}
      value={state.input.is_flagged_for_data_extraction}
      label={LABEL}
      style={checkboxStyle}
      labelStyle={{ fontWeight: 'bold' }}
    />

    <div style={{ marginTop: Spacing.SMALL }}></div>
  </>
)

const getButtonGroup = ({
  name,
  _id,
  is_flagged_for_data_extraction,
  products,
}) => (
  <ModalButtonWithForm
    modalTitle="Edit Regimen"
    buttonLabel={editIcon}
    modalStyle={createObjectModalStyle}
    buttonStyle={{
      border: 'none',
      background: 'none',
      color: transparentize(0.7, Colors.BLACK),
    }}
    data={{ input: { _id, name, is_flagged_for_data_extraction, products } }}
    mutationDoc={UPDATE_SOURCE_REGIMEN}
    refetchQueries={[{ query: GET_SOURCE_INDICATIONS }]}
    getInputFields={getInputFields}
  />
)

const RegimensPanelListItem = ({
  data,
  isSelected,
  handleClick,
  searchParamKey,
}) => {
  const listItemHandleClick = isSelected
    ? () => null
    : () => handleClick(data[searchParamKey])

  const style = isSelected ? activePanelItemStyle : {}
  return (
    <Wrapper onClick={listItemHandleClick} style={style}>
      <div style={{ display: 'flex' }}>
        <div>{data.name}</div>
        <DataExtractionFlagTag
          isFlagged={data.is_flagged_for_data_extraction}
        />
      </div>
      <div>{getButtonGroup(data)}</div>
    </Wrapper>
  )
}
RegimensPanelListItem.propTypes = {
  data: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  searchParamKey: PropTypes.string.isRequired,
}

export default RegimensPanelListItem
