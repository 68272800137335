import React, { useState } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import queryString from 'query-string'
import styled from '@emotion/styled'

import { GET_VEGA_TEAMS_REGIONS } from 'frontend/api/queries'

import Spinner from 'frontend/components/Spinner'
import StructuralListPanels from 'frontend/components/StructuralListPanels'

import RegionForms from './RegionForms'
import UpdateRegionColumn from './UpdateRegionColumn'
import PanelHeader from './PanelHeader'
import PanelListItem from './PanelListItem'

const panelHeight = 'calc(100vh - 110px)'
const listWrapperStyle = {
  minHeight: panelHeight,
  maxHeight: panelHeight,
  height: panelHeight,
}

const Flex = styled.section({
  display: 'flex',
})

const Regions = () => {
  const location = useLocation()
  const history = useHistory()
  const [opType, setOpType] = useState(null)
  const [selectedRegion, setSelectedRegion] = useState(null)

  const { clientTeamId } = useParams()
  const { data, loading, error } = useQuery(GET_VEGA_TEAMS_REGIONS, {
    variables: { clientTeamId },
  })

  if (loading) return <Spinner />

  const handleListItemSearchUpdate = (nextParam) => {
    const prevQueryParams = queryString.parse(location.search)
    const nextParams = {
      ...prevQueryParams,
      ...nextParam,
    }

    history.push({
      search: queryString.stringify(nextParams),
    })
  }

  const regions = data.vegaTeamsRegions || []

  const listConfigPanels = [
    {
      searchParamConfig: {
        searchParam: 'teamRegionId',
        searchParamKey: 'id',
      },
      listWrapperStyle,
      listHeaderConfig: {
        title: 'Regions',
        ListHeader: (props) => (
          <PanelHeader
            {...props}
            handleCreate={() => {
              setOpType('create')
            }}
          />
        ),
      },
      listConfig: {
        ListItem: (props) => (
          <PanelListItem
            {...props}
            handleDelete={(data) => {
              setSelectedRegion(data)
              setOpType('delete')
            }}
          />
        ),
      },
      data: regions,
      loading,
      error,
    },
  ]

  return (
    <Flex>
      <Flex style={{ width: '50%' }}>
        <StructuralListPanels
          panels={listConfigPanels}
          searchParamsAncestry={['tab']}
        />
      </Flex>
      <Flex style={{ width: '50%' }}>
        <UpdateRegionColumn />
      </Flex>
      {opType && (
        <RegionForms
          closeHandler={() => setOpType(null)}
          opType={opType}
          region={selectedRegion}
          regions={regions}
          handleListItemSearchUpdate={handleListItemSearchUpdate}
        />
      )}
    </Flex>
  )
}

export default Regions
