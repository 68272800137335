import React from 'react'

import { DeleteButton } from './DeleteButton'

interface Props {
  isHoveredListItem: Boolean
  isSelected?: Boolean
  datumId: String
  entity: String
}

export const Container = ({
  isHoveredListItem,
  isSelected,
  datumId,
  entity,
}: Props) => {
  if (!isSelected && !isHoveredListItem) return null

  return <DeleteButton datumId={datumId} entity={entity} />
}
