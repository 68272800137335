import { MultiSelectFilter } from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'
import {
  FILTER_TYPES,
  SORT_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'

const { MULTI_SELECT } = FILTER_TYPES
const { STRING, TEXT_ARRAY } = SORT_TYPES

const getColConfig = () => [
  {
    Header: 'Email',
    accessor: 'email',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 150,
    sticky: 'left',
  },
  {
    Header: 'Username',
    accessor: 'username',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 150,
    sticky: 'left',
  },
  {
    Header: 'Client',
    accessor: 'client',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 200,
  },
  {
    Header: 'Teams',
    accessor: 'teams',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: TEXT_ARRAY,
    width: 500,
  },
  {
    Header: 'Tools',
    accessor: 'tools',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: TEXT_ARRAY,
    width: 500,
  },
  {
    Header: 'Email Subscriptions',
    accessor: 'emailSubscriptions',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: TEXT_ARRAY,
    width: 200,
  },
  {
    Header: 'ID',
    accessor: '_id',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 150,
  },
]

export default getColConfig
