import React from 'react'

import { useAlternativePaymentModelOrganizations } from 'frontend/entity-hooks'

import {
  DataTableContainer,
  DataTableHeader,
  EditableDataTable,
} from 'frontend/components'
import { useRewriteSearchString } from 'frontend/hooks'

import CreateAlternativePaymentModelOrganizationButton from './CreateAlternativePaymentModelButton'
import CreateModal from './CreateModal'
import getColConfig from './getColConfig'
import DeleteDialog from './DeleteDialog'

const HEADER = 'Alternative Payment Models'

const AlternativePaymentModel = () => {
  const addDeleteSearchString = useAddDeleteSearchString()

  const { data, isLoading, update } = useAlternativePaymentModelOrganizations()

  const handleUpdate = (input) => {
    update({ variables: { input } })
  }

  const colConfig = getColConfig({ handleUpdate, addDeleteSearchString })

  const utilityBarNodes = [<CreateAlternativePaymentModelOrganizationButton />]

  return (
    <DataTableContainer>
      <DeleteDialog />
      <CreateModal />
      <DataTableHeader header={HEADER} />
      <EditableDataTable
        tableId={'alternative-payment-model-accounts-table'}
        hasRowCount
        isLoading={isLoading}
        colConfig={colConfig}
        data={data}
        excelLabel={'Alternative Payment Models'}
        utilityBarNodes={utilityBarNodes}
      />
    </DataTableContainer>
  )
}

export default AlternativePaymentModel

const useAddDeleteSearchString = () => {
  const rewriteSearchString = useRewriteSearchString()

  const addDeleteSearchString = ({ id }) => {
    const deleteSearchArgs = {
      deleteAlternativePaymentModel: true,
      alternativePaymentModelId: id,
    }
    rewriteSearchString(deleteSearchArgs)
  }

  return addDeleteSearchString
}
