import React from "react"

import FieldChanges from "../FieldChanges/FieldChanges"

import HistoryEventProps from "./interfaces"
import {
  EventWrapper,
  EventHeader,
  Accent,
  Timestamp,
} from "./styled-components"


const HistoryEvent = ({
  username,
  action,
  deltas,
  timestamp,
  keyToLabel,
}: HistoryEventProps) => (
  <EventWrapper>
    <EventHeader>
      <Accent>{username}</Accent> {action}:
    </EventHeader>
    <Timestamp>{timestamp}</Timestamp>
    <FieldChanges deltas={deltas} keyToLabel={keyToLabel} />
  </EventWrapper>
)

export default HistoryEvent
