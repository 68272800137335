import { formatMonthDayYearSlash } from 'frontend/utils/formatDate'

export const getExportConfig = ({
  people,
  secondaryOrganizations,
}: any): any => [
  {
    Header: 'Person ID',
    accessor: 'person_id',
  },
  {
    Header: 'Person',
    accessor: 'person',
    labelMap: people.labelMap,
  },
  {
    Header: 'Secondary Organization',
    accessor: 'secondary_organization',
    labelMap: secondaryOrganizations.labelMap,
  },
  {
    Header: 'Status',
    accessor: 'dynamic_status',
  },
  {
    Header: 'Committees',
    accessor: 'committees',
  },
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Start Date',
    accessor: 'start_date',
    cellValueFormatter: formatMonthDayYearSlash,
  },
  {
    Header: 'End Date',
    accessor: 'end_date',
    cellValueFormatter: formatMonthDayYearSlash,
  },
  {
    Header: 'Internal Notes',
    accessor: 'tdg_internal_note',
  },
]
