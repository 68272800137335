import { transparentize } from 'polished'
import _ from 'lodash'

import {
  DateMultiSelectFilter,
  MultiSelectFilter,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'
import {
  DISPLAY_CELL_TYPES,
  EDIT_CELL_TYPES,
  FILTER_TYPES,
  SORT_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'

import Color from 'frontend/utils/color'
import { ExpandIcon } from 'frontend/components/Icon'
import { formatMonthDayYearSlash } from 'frontend/utils/formatDate'
import { StatusTag } from 'frontend/components'

const { CONDENSED_BUTTON, BUTTON } = DISPLAY_CELL_TYPES
const { CONDENSED_SINGLE_SELECT_INPUT, CONDENSED_TEXT_INPUT } = EDIT_CELL_TYPES
const { DATE_MULTI_SELECT, MULTI_SELECT } = FILTER_TYPES
const { DATE, DISPLAY, STRING } = SORT_TYPES

const PEOPLE_TRACKING_SPAN_FLAG = 'shouldShowPeopleTrackingStartEndDates'

const STATUS_TO_COLOR = {
  active: Color.GREEN,
  inactive: Color.ORANGE,
}

export const getColConfig = ({
  people,
  nccnPanels,
  nccnPanelVotingMember,
  addDeleteSearchString,
  onExpandClick,
}) => [
  {
    Header: '',
    accessor: 'x',
    sticky: 'left',
    displayCellConfig: {
      type: BUTTON,
      style: {
        background: transparentize(0.9, Color.PRIMARY),
        borderRadius: 4,
        padding: 2,
        cursor: 'pointer',
      },
      label: <ExpandIcon />,
      onClick: onExpandClick,
    },
    width: 5,
    disableSortBy: true,
    featureFlag: PEOPLE_TRACKING_SPAN_FLAG,
  },
  {
    Header: 'Name',
    accessor: 'member',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: DISPLAY,
    cellValueFormatter: (value) =>
      _.isEmpty(people.labelMap) ? '...' : people.labelMap[value],
    editCellConfig: {
      type: CONDENSED_SINGLE_SELECT_INPUT,
      options: people.options,
      updateFn: nccnPanelVotingMember.update,
    },
  },
  {
    Header: 'NCCN Panel',
    accessor: 'panel',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: DISPLAY,
    cellValueFormatter: (value) =>
      _.isEmpty(nccnPanels.labelMap) ? '...' : nccnPanels.labelMap[value],
    editCellConfig: {
      type: CONDENSED_SINGLE_SELECT_INPUT,
      options: nccnPanels.options,
      updateFn: nccnPanelVotingMember.update,
    },
  },
  {
    Header: 'Status',
    accessor: 'dynamic_status',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 150,
    cellValueFormatter: (value) => {
      if (!value) return null
      const upperCaseValue = value.toUpperCase()
      return <StatusTag color={STATUS_TO_COLOR[value]} value={upperCaseValue} />
    },
  },
  {
    Header: 'Internal TDG Notes',
    accessor: 'tdg_internal_note',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: nccnPanelVotingMember.update,
    },
  },
  {
    Header: 'Start Date',
    accessor: 'start_date',
    Filter: DateMultiSelectFilter,
    filter: DATE_MULTI_SELECT,
    sortType: DATE,
    cellValueFormatter: formatMonthDayYearSlash,
    width: 200,
    featureFlag: PEOPLE_TRACKING_SPAN_FLAG,
  },
  {
    Header: 'End Date',
    accessor: 'end_date',
    Filter: DateMultiSelectFilter,
    filter: DATE_MULTI_SELECT,
    sortType: DATE,
    cellValueFormatter: formatMonthDayYearSlash,
    width: 200,
    featureFlag: PEOPLE_TRACKING_SPAN_FLAG,
  },
  {
    Header: 'Last Updated',
    accessor: 'updated_at',
    Filter: DateMultiSelectFilter,
    filter: DATE_MULTI_SELECT,
    sortType: DATE,
    cellValueFormatter: formatMonthDayYearSlash,
  },
  {
    Header: 'Delete',
    accessor: 'delete',
    displayCellConfig: {
      type: CONDENSED_BUTTON,
      label: 'Delete',
      onClick: addDeleteSearchString,
    },
  },
]
