import React from 'react'
import styled from '@emotion/styled'

import { Import } from 'frontend/components'

interface Props {
  onChange: any
}

const LABEL_TEXT = 'Select a File'
const style: any = {}

export const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 24,
})

export const Label = styled.label({
  fontSize: 12,
  fontWeight: 700,
  marginBottom: 4,
})

export const FileSelect = ({ onChange }: Props) => {
  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Import name={'file'} style={style} onChange={onChange} />
    </Wrapper>
  )
}
