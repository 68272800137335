import React from 'react'
import styled from '@emotion/styled'

import { DateInput } from 'frontend/components'
import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'

import { DocumentLastLoadDate } from './DocumentLastLoadDate'
import Links from './Links'

interface Props {
  activeTab: string
  mostRecentPolicy: any
  policyState: any
  setPolicyState: any
}

const CUSTOM_DATE_INPUT_STYLES = {
  ICON_PROPS: {
    iconColor: '#BBBFC3',
    styles: { width: '14px', height: '14px' },
  },
  INPUT_STYLES: {
    height: '36px',
    width: '373px',
    backgroundColor: '#F1F5FA',
    borderColor: 'none',
    borderWidth: '0px',
  },
}

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '12px 0',
  width: '375px'
})

const HeaderWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between'
})

const Label = styled.span({
  color: '#0E2539',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 700
})

const PolicyLastUpdated = ({
  activeTab,
  mostRecentPolicy,
  policyState,
  setPolicyState
}: Props) => {
  let label = `New Policy Approval Date`
  if (activeTab === 'comparison') label += ' (Right)'

  const onDateChange = (e: any) => {
    const approved_date = e.value

    setPolicyState((policyState: any) => ({
      ...policyState,
      approved_date
    }))
  }

  let value = mostRecentPolicy.approved_date ?
    new Date(mostRecentPolicy.approved_date) : null

  if (policyState.hasOwnProperty('approved_date')) {
    value = policyState.approved_date
  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <Label>
          {label}
        </Label>
        <Links
          payerLink={mostRecentPolicy.instruction.payer_website_link}
          s3Link={mostRecentPolicy.link}
        />
      </HeaderWrapper>
      <DateInput
        openPickerIcon={CustomSvgIcon}
        customIconProps={CUSTOM_DATE_INPUT_STYLES.ICON_PROPS}
        helperText="Please select a date"
        iconName="Calendar"
        customStyles={CUSTOM_DATE_INPUT_STYLES.INPUT_STYLES}
        placeholder="Select a date"
        value={value}
        onChange={onDateChange}
      />
      <DocumentLastLoadDate
        date={mostRecentPolicy.created_at}
      />
    </Wrapper>
  )
}

export default PolicyLastUpdated
