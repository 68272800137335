import gql from 'graphql-tag'

export const GET_TEAM_OPTIONS = gql`
  query GetTeamOptions($input: TeamOptionsInput_EMBER!) {
    teamOptions_EMBER(input: $input) {
      client_source {
        options {
          label
          value
        }
        labelMap
      }
    }
  }
`
