import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

import { DeleteButton } from './DeleteButton'
import { Header } from './Header'

const Wrapper = styled.div({
  backgroundColor: Colors.WHITE,
  borderRadius: 8,
  padding: `24px 24px 12px 24px`,
  marginTop: 24,
})

export const DangerZone = () => {
  return (
    <Wrapper>
      <Header />
      <DeleteButton />
    </Wrapper>
  )
}
