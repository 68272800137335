import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const UPDATE_SECONDARY_ORGANIZATION_PERSON = gql`
  mutation UpdateSecondaryOrganizationPerson($input: UpdateSecondaryOrganizationPersonInput!) {
    updateSecondaryOrganizationPerson(input: $input) {
      ${schemaV0}
    }
  }
`
