import React from 'react'

import { useQueryString } from 'frontend/hooks'

import { DeleteUserDialog, DeleteTeamDialog } from './dialogs'

const QUERY_STRING_TO_DELETE_DIALOG: any = {
  user: <DeleteUserDialog />,
  team: <DeleteTeamDialog />,
}

const DeleteDialog = () => {
  const {
    deleteDialog: selectedDeleteDialog,
  }: { deleteDialog?: string } = useQueryString()

  if (!selectedDeleteDialog) return null

  const deleteDialog = QUERY_STRING_TO_DELETE_DIALOG[selectedDeleteDialog]

  return deleteDialog
}

export default DeleteDialog
