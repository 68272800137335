import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'

import Checkbox from 'frontend/components/Checkbox'
import { SingleActionDialog } from 'frontend/components/Dialog'
import Spinner from 'frontend/components/Spinner'
import {
  Label,
  LabelText,
  SectionTitle,
} from 'frontend/Orion/shared/AccountModals/AccountModalButton/styledAccountModalButtonComponents'
import Input from 'frontend/components/Input'

import getIndexByBinarySearch from 'frontend/utils/getIndexByBinarySearch'

import { CREATE_PROVIDER } from 'frontend/api/mutations'
import { GET_PROVIDER_ORGANIZATIONS } from 'frontend/api/queries'

import AliasesMultiSelect from './AliasesMultiSelect'
import { StateSelect } from './shared'

const PAGE_HEADER = 'Create Provider Account'

const FLAG_FOR_DATA_EXTRACTION = 'Flag for Data Extraction'

const wrapperStyle = {
  padding: '30px 30px',
}

const addNewProviderAccountToCache = (cache, { data: { createProvider } }) => {
  const { providerOrganizations } = cache.readQuery({
    query: GET_PROVIDER_ORGANIZATIONS,
  })
  const newAccountIndex = getIndexByBinarySearch({
    data: providerOrganizations,
    newDatum: createProvider,
    accessor: 'organization',
  })
  const newProviderAccounts = [...providerOrganizations]
  newProviderAccounts.splice(newAccountIndex, 0, createProvider)
  cache.writeQuery({
    query: GET_PROVIDER_ORGANIZATIONS,
    data: { providerOrganizations: newProviderAccounts },
  })
}

const CreateProviderAccountForm = ({ closeHandler }) => {
  const [form, setForm] = useState({})

  const handleChange = ({ name, value }) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleCheckboxChange = ({ target: { name, value } }) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSelectChange = ({ value }, { name }) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const input = {
    ...form,
    oncologistsCount: parseInt(form.oncologistsCount),
    sitesCount: parseInt(form.sitesCount),
  }

  const [
    createProviderAccount,
    { loading: isProviderAccountLoading },
  ] = useMutation(CREATE_PROVIDER, {
    variables: { input },
    update: addNewProviderAccountToCache,
    onError: alert,
    onCompleted: () => {
      closeHandler()
    },
  })

  return (
    <SingleActionDialog
      header={PAGE_HEADER}
      submitText={PAGE_HEADER}
      submitHandler={createProviderAccount}
      cancelHandler={closeHandler}
    >
      {isProviderAccountLoading ? (
        <Spinner />
      ) : (
        <div style={wrapperStyle}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SectionTitle>Provider Account Info</SectionTitle>
          </div>

          <Label>
            <LabelText>Name</LabelText>

            <Input
              name="organization"
              type="text"
              onChange={handleChange}
              value={form.organization}
            />
          </Label>
          <Label>
            <LabelText>Short Name</LabelText>
            <Input
              name="organizationTiny"
              type="text"
              onChange={handleChange}
              value={form.organizationTiny}
            />
          </Label>
          <Label>
            <LabelText> Slug </LabelText>
            <Input
              name="slug"
              type="text"
              onChange={handleChange}
              value={form.slug}
            />
          </Label>
          <Label>
            <LabelText> City </LabelText>
            <Input
              name="city"
              type="text"
              onChange={handleChange}
              value={form.city}
            />
          </Label>

          <Label>
            <LabelText> State</LabelText>
            <StateSelect name="state" onChange={handleSelectChange} />
          </Label>

          <Label>
            <LabelText> Oncologists # </LabelText>
            <Input
              name="oncologistsCount"
              type="text"
              onChange={handleChange}
              value={form.oncologistsCount}
            />
          </Label>

          <Label>
            <LabelText> Cancer Center </LabelText>
            <Input
              name="providerCancerCenter"
              type="text"
              onChange={handleChange}
              value={form.providerCancerCenter}
            />
          </Label>

          <Label>
            <LabelText> Sites # </LabelText>
            <Input
              name="sitesCount"
              type="text"
              onChange={handleChange}
              value={form.sitesCount}
            />
          </Label>

          <Label>
            <LabelText> Group Practice PAC ID </LabelText>
            <Input
              name="groupPracticePacId"
              type="text"
              onChange={handleChange}
              value={form.groupPracticePacId}
            />
          </Label>

          <Label>
            <LabelText> Aliases </LabelText>
            <AliasesMultiSelect
              name="aliases"
              onChange={handleChange}
              value={form.aliases}
            />
          </Label>

          <Checkbox
            name={'is_flagged_for_data_extraction'}
            onChange={handleCheckboxChange}
            value={form.is_flagged_for_data_extraction}
            label={FLAG_FOR_DATA_EXTRACTION}
            style={{ marginTop: 10 }}
            labelStyle={{ fontWeight: 'bold' }}
          />
        </div>
      )}
    </SingleActionDialog>
  )
}

CreateProviderAccountForm.propTypes = {
  closeHandler: PropTypes.func.isRequired,
}

export default CreateProviderAccountForm
