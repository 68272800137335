import React from 'react'
import _ from 'lodash'

import DynamicTextInput from './DynamicTextInput'

interface DynamicTextInputContainerProps {
  text: { [key: string]: string },
  setListItemText: (arg: any) => void,
}

const DynamicTextInputContainer: React.FC<DynamicTextInputContainerProps> = ({ text, setListItemText }) => {

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    setListItemText((prevInput: { [key: string]: string }) => ({...prevInput, [event.target.name]: event.target.value}))
  }

  const setNewField = ([key, value]: [string, string]) => {
    if (text[key as keyof typeof text]) {
      alert(`Text field with key ${key} already exists`)
      return
    }

    setListItemText((prevInput: any) => ({...prevInput, [key]: value}))
  }

  const removeField = (key: string) => {
    setListItemText((prevInput: any) => {
      const newInput = _.cloneDeep(prevInput)
      delete newInput[key]
      return newInput
    })
  }

  return (
    <DynamicTextInput
      text={text}
      handleInput={handleInput}
      setNewField={setNewField}
      removeField={removeField}
    />
  )
}

export default DynamicTextInputContainer

