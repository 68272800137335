import React from 'react'
import PropTypes from 'prop-types'

import CreateRegionForm from './CreateRegionForm'
import DeleteRegionForm from './DeleteRegionForm'

const FORM_MAP = {
  create: CreateRegionForm,
  delete: DeleteRegionForm,
}

const RegionForms = ({
  opType,
  closeHandler,
  region,
  regions,
  handleListItemSearchUpdate,
}) => {
  const Component = FORM_MAP[opType]

  return (
    <Component
      closeHandler={closeHandler}
      region={region}
      regions={regions}
      handleListItemSearchUpdate={handleListItemSearchUpdate}
    />
  )
}

RegionForms.propTypes = {
  opType: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
  handleListItemSearchUpdate: PropTypes.func.isRequired,
}

export default RegionForms
