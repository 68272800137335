import {
  PAYER,
  PRODUCT,
  BOOK,
  ASSIGNED_POLARIS_USER_AUTH_IDS,
  PRODUCT_PAYER_POLICY_TRACKING_PROJECTS,
} from './enums'

export const getPayerPolicyOptions = ({
  productPolicyOptions = {},
  payerPolicyOptions = {},
  payers = [],
  products = {},
  books = [],
  usersData = [],
  projectsData = {},
}) => {
  const data = {}

  data[PAYER] = getOptionsWithLabelValues({ data: payers })

  data[PRODUCT] = getOptionsWithLabelValues({
    data: products.vegaProducts,
    getLabelKey: ({ generic_name, brand_name }) => brand_name || generic_name,
  })

  data[BOOK] = getOptionsWithLabelValues({ data: books })

  data[ASSIGNED_POLARIS_USER_AUTH_IDS] = getOptionsWithLabelValues({
    data: usersData,
    getValueKey: ({ user_id }) => user_id,
  })

  data[PRODUCT_PAYER_POLICY_TRACKING_PROJECTS] = getOptionsWithLabelValues({
    data: projectsData.productPayerPolicyTrackingProjects,
  })

  const formattedData = Object.entries(data).reduce(
    (acc, [accessor, datum]) => {
      const labelMap = datum.reduce((acc, { label, value }) => {
        acc[value] = label
        return acc
      }, {})
      acc[accessor] = { options: datum, labelMap: labelMap }
      return acc
    },
    {}
  )

  return {
    ...formattedData,
    ...productPolicyOptions.productPolicyLinkOptions,
    ...payerPolicyOptions.payerPolicyLinkOptions,
  }
}

const getOptionsWithLabelValues = ({
  data,
  getLabelKey = null,
  getValueKey = null,
}) => {
  return data.map((datum) => {
    return {
      label: getLabelKey ? getLabelKey(datum) : datum.name,
      value: getValueKey ? getValueKey(datum) : datum.id,
    }
  })
}
