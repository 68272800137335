import { useMutation } from '@apollo/client'

import * as CRUD_UTILS from 'frontend/api/utils'
import { CrudHookOptions } from 'frontend/interfaces/CrudHookOptions'

import { getPayerPolicyDocumentConfigs } from './getPayerPolicyDocumentConfigs'
import * as GQL_TAGS from './gql-tags'

export const usePayerPolicyDocument = ({
  queryInput,
}: CrudHookOptions = {}) => {
  const { readConfig, updateConfig } = getPayerPolicyDocumentConfigs(queryInput)

  const { data: payerPolicyDocuments, loading: isLoading } = CRUD_UTILS.useRead(
    readConfig
  )

  const [create, { loading: isCreating, data: createData }] = useMutation(
    GQL_TAGS.CREATE_PAYER_POLICY_DOCUMENT
  )

  const [update, { loading: isUpdating }] = CRUD_UTILS.useUpdate(updateConfig)

  const data = payerPolicyDocuments
    ? Object.values(payerPolicyDocuments)[0]
    : []

  return {
    data,
    isLoading,
    create,
    isCreating,
    createData,
    update,
    isUpdating,
  }
}
