import React from 'react'
import { transparentize } from 'polished'
import { UnderlinedTabs } from '../../components/Tabs'
import AccountsTabContent from './AccountsTabContent'
import TreatmentPlansTabContent from './TreatmentPlansTabContent'
import { Colors, Spacing } from '../../utils/pulseStyles'
import { PRIVILEGES, ifSettingAllowed } from './toolPrivileges'

const TABS_DATA = ['Treatment Plans', 'Accounts']

const tabsContainerStyle = {
  borderTop: `2px solid ${transparentize(0.9, Colors.BLACK)}`,
  borderBottom: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
  paddingLeft: Spacing.NORMAL,
  paddingRight: Spacing.NORMAL,
}

const activeTabStyle = {
  borderBottomColor: Colors.PRIMARY,
  color: Colors.PRIMARY,
}

const ModalRightPanel = (props) => {
  const {
    baseAccounts,
    setStagedAccounts,
    booksOfBusiness,
    subTypes,
    lineOfTherapies,
    baseTreatmentPlans,
    setStagedTreatmentPlans,
  } = props.resources

  const { accounts, treatmentPlans, tool: activeTool } = props.state

  const areTreatmentPlansAllowed = () => {
    return ifSettingAllowed(
      activeTool.label.toLowerCase(),
      PRIVILEGES.TREATMENTPLANS
    )
  }

  const accountResources = {
    activeTool,
    booksOfBusiness,
    baseAccounts,
    accounts,
    setStagedAccounts,
  }

  const treatmentPlansResources = {
    activeTool,
    subTypes,
    lineOfTherapies,
    baseTreatmentPlans,
    treatmentPlans,
    setStagedTreatmentPlans,
  }
  return (
    <>
      {areTreatmentPlansAllowed() && (
        <UnderlinedTabs
          tabsData={TABS_DATA}
          tabsContainerStyle={tabsContainerStyle}
          activeTabStyle={activeTabStyle}
        >
          <TreatmentPlansTabContent {...treatmentPlansResources} />
          <AccountsTabContent {...accountResources} />
        </UnderlinedTabs>
      )}
      {!areTreatmentPlansAllowed() && (
        <AccountsTabContent {...accountResources} />
      )}
    </>
  )
}
export default ModalRightPanel
