interface PersonName {
  first_name: string | undefined | null
  middle_name: string | undefined | null
  last_name: string | undefined | null
}

export const concatPersonName = ({
  first_name,
  middle_name,
  last_name,
}: PersonName) => {
  if (first_name && !middle_name && last_name)
    return first_name + ' ' + last_name
  else if (first_name && !middle_name && !last_name) return first_name
  else if (!first_name && !middle_name && last_name) return last_name
  else if (!first_name && !middle_name && !last_name) return ''
  else if (!first_name && middle_name && !last_name) return middle_name
  else if (!first_name && middle_name && last_name)
    return middle_name + ' ' + last_name
  else if (first_name && middle_name && !last_name)
    return first_name + ' ' + middle_name
  return first_name + ' ' + middle_name + ' ' + last_name
}
