import React from 'react'
import { transparentize } from 'polished'

import { UnderlinedTabs } from 'frontend/components/Tabs'
import { Colors, Spacing } from 'frontend/utils/pulseStyles'

import Accounts from './Accounts'
import TreatmentPlans from './TreatmentPlans'

const TABS_DATA = ['Treatment Plans', 'Accounts']

const tabsContainerStyle = {
  borderTop: `2px solid ${transparentize(0.9, Colors.BLACK)}`,
  borderBottom: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
  paddingLeft: Spacing.NORMAL,
  paddingRight: Spacing.NORMAL,
}

const activeTabStyle = {
  borderBottomColor: Colors.PRIMARY,
  color: Colors.PRIMARY,
}

const Tabs = ({
  resources: { treatmentPlans, accounts } = {},
  setResources,
}) => {
  return (
    <UnderlinedTabs
      activeTabStyle={activeTabStyle}
      tabsContainerStyle={tabsContainerStyle}
      tabsData={TABS_DATA}
    >
      <TreatmentPlans
        treatmentPlans={treatmentPlans}
        setResources={setResources}
      />
      <Accounts accounts={accounts} setResources={setResources} />
    </UnderlinedTabs>
  )
}

export default Tabs
