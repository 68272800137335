import React from 'react'

const CircleX = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg viewBox="-1 -2 14 14" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.99984 0.166656C9.2215 0.166656 11.8332 2.77833 11.8332 5.99999C11.8332 9.22165 9.2215 11.8333 5.99984 11.8333C2.77818 11.8333 0.166504 9.22165 0.166504 5.99999C0.166504 2.77833 2.77818 0.166656 5.99984 0.166656ZM4.7999 3.8626C4.686 3.7487 4.50132 3.7487 4.38742 3.8626L3.86245 4.38757C3.74854 4.50147 3.74854 4.68615 3.86245 4.80005L5.06269 5.99961L3.86245 7.19993C3.74854 7.31383 3.74854 7.4985 3.86245 7.61241L4.38742 8.13738C4.50132 8.25128 4.686 8.25128 4.7999 8.13738L5.99984 6.93677L7.19978 8.13738C7.31368 8.25128 7.49835 8.25128 7.61225 8.13738L8.13723 7.61241C8.25113 7.4985 8.25113 7.31383 8.13723 7.19993L6.93699 5.99961L8.13723 4.80005C8.25113 4.68615 8.25113 4.50147 8.13723 4.38757L7.61225 3.8626C7.49835 3.7487 7.31368 3.7487 7.19978 3.8626L5.99984 5.06246L4.7999 3.8626Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default CircleX
