import React from 'react'
import styled from '@emotion/styled'
import _ from 'lodash'

import { Colors } from 'frontend/utils/pulseStyles'

import { ParentType, Type } from '../../types'

interface HeaderProps {
  type: Type
  parentType?: ParentType
}

const Wrapper = styled.div({
  borderBottom: `1px solid ${Colors.COOL_GRAY_200}`,
  fontSize: 16,
  fontWeight: 500,
  height: 87,
  padding: 24,
})

const Header = ({ type, ...props }: HeaderProps) => {
  const text = `${_.startCase(type)}s `

  return (
    <Wrapper>
      <span>{text}</span>
    </Wrapper>
  )
}

export default Header
