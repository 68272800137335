import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import { useQueryString } from 'frontend/hooks'

import { LIST_HEADER } from '../../../enums'
import { List, ListHeader, ListItem } from '../../shared'
import './sortableContainerStyles.css'

const SortableList = SortableContainer(({ children }) => {
  return <List>{children}</List>
})

const SortableListItem = SortableElement((props) => {
  return <ListItem {...props} />
})

const ActiveList = ({
  indicationIdToLabel,
  indications,
  selectResource,
  setResources,
}) => {
  const { indication } = useQueryString()

  const sortResource = ({ oldIndex, newIndex }) => {
    setResources(({ treatmentPlans, ...rest }) => {
      const [listItem] = treatmentPlans.splice(oldIndex, 1)
      treatmentPlans.splice(newIndex, 0, listItem)
      return {
        treatmentPlans,
        ...rest,
      }
    })
  }

  const disableResource = (resource) => {
    setResources(({ treatmentPlans, ...rest }) => {
      treatmentPlans = treatmentPlans.filter(
        (treatmentPlan) => treatmentPlan.resource !== resource.resource
      )
      return {
        treatmentPlans,
        ...rest,
      }
    })
  }

  return (
    <>
      <ListHeader listLength={indications.length} type={LIST_HEADER.ACTIVE} />
      <SortableList
        useDragHandle
        helperClass={'sortableHelper'}
        onSortEnd={sortResource}
      >
        {indications.map((resource, index) => (
          <SortableListItem
            index={index}
            key={index}
            checked={true}
            label={indicationIdToLabel[resource.resource]}
            resource={resource}
            selectedEntity={indication}
            selectResource={selectResource}
            toggleResource={disableResource}
            type={LIST_HEADER.ACTIVE}
          />
        ))}
      </SortableList>
    </>
  )
}

export default ActiveList
