import React, { useState, useEffect } from 'react'

import Color from 'frontend/utils/color'

import DefaultCell from './DefaultCell'
import { cellTypes } from './cellTypes'

const CellContainer = (props) => {
  const {
    positionRef,
    rowInputRef,
    row,
    column,
    cell,
    cellId,
    editConfig,
  } = props
  const { onEdit, cellConfig, onFocusCellConfig, createRowCellConfig } = column

  const [cellValue, setCellValue] = useState(cell.value)
  const [isFocused, setIsFocused] = useState(false)

  const accessor = editConfig ? editConfig.accessor : 'id'

  useEffect(() => {
    setCellValue(cell.value)
  }, [cell.value])

  const onFocus = () => {
    if (row.id !== 'create') positionRef.current = cellId
    setIsFocused(true)
  }

  const onBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      handleCellValueChange(
        cellValue,
        setCellValue,
        rowInputRef,
        row,
        column,
        onEdit,
        accessor
      )
      setIsFocused(false)
    }
  }

  let activeCellConfig = cellConfig
  if (row.id === 'create') {
    activeCellConfig = createRowCellConfig
  } else if (isFocused && onFocusCellConfig) {
    activeCellConfig = onFocusCellConfig
  }

  let cellElement = <DefaultCell cellValue={cellValue} {...props} />

  if (activeCellConfig?.type) {
    const { type } = activeCellConfig

    const CellComponent = cellTypes[type]

    if (CellComponent) {
      cellElement = (
        <CellComponent
          cellConfig={activeCellConfig}
          cellValue={cellValue}
          {...props}
        />
      )
    }
  }

  const { style: cellStyle, ...restCellProps } = cell.getCellProps()
  const style = { ...cellStyle }
  const borderStyle = isFocused
    ? `2px solid ${Color.BLUE}`
    : cellStyle['border']
  style['border'] = borderStyle

  return (
    <div
      id={cellId}
      className="td"
      tabIndex={0}
      onFocus={onFocus}
      onBlur={onBlur}
      style={style}
      {...restCellProps}
    >
      {cellElement}
    </div>
  )
}

export default CellContainer

// TODO: Write out the control flow in a comment. Future: Refactor & extract nested conditionals.
const handleCellValueChange = (
  cellValue,
  setCellValue,
  rowInputRef,
  row,
  column,
  onEdit,
  accessor
) => {
  // ! cellValue is the original value the cell container holds in state
  // ! cellInputRef is the staged / edited cell value
  const cellInputRef = rowInputRef.current
    ? rowInputRef.current[column.id]
    : rowInputRef.current

  let newCellValue = undefined
  let isNewCellValue = false

  // cellInputRef is undefined on dataTable mount and onBlur or focused cell is not edited
  if (cellInputRef === undefined) return

  if (cellInputRef instanceof Array) {
    // check if length of array is the same with new and old values
    if (!cellValue || cellInputRef.length !== cellValue.length) {
      isNewCellValue = true
    }

    // continue check if new cell value
    if (!isNewCellValue) {
      for (let i = 0; i < cellInputRef.length; i++) {
        if (cellInputRef[i] !== cellValue[i]) {
          isNewCellValue = true
          break
        }
      }
    }

    if (isNewCellValue) {
      newCellValue = cellInputRef
    }
  } else if (cellInputRef instanceof Date) {
    if (cellInputRef.getTime() !== new Date(cellValue).getTime()) {
      newCellValue = cellInputRef.toString()
      isNewCellValue = true
    }
  } else {
    if (cellInputRef !== cellValue) {
      newCellValue = cellInputRef
      isNewCellValue = true
    }
  }

  if (isNewCellValue) {
    if (onEdit) {
      const input = {
        [accessor]: row.original[accessor],
        [`${column.id}`]: cellInputRef,
      }
      onEdit(input)
    }
    setCellValue(newCellValue)
  }

  // The create row needs to keep the rowInputRef intact until the entire row of data is saved.
  // The create row, on create, will have to reset rowInputRef as well.
  if (row.id !== 'create') {
    rowInputRef.current[column.id] = undefined
  }
}
