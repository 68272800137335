import React from 'react'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { transparentize } from 'polished'
import _ from 'lodash'

import { useTeamNodeResource } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'
import { Colors, Spacing } from 'frontend/utils/pulseStyles'

import Label from './Label'

interface Props {
  resources?: any
}

const Button = styled.button({
  alignItems: 'center',
  background: transparentize(0.85, Colors.PRIMARY),
  border: 'none',
  borderRadius: 4,
  color: Colors.PRIMARY,
  cursor: 'pointer',
  display: 'flex',
  fontSize: 12,
  fontWeight: 700,
  marginRight: Spacing.LARGE,
  padding: `${Spacing.SMALL} ${Spacing.NORMAL}`,
  textTransform: 'uppercase',
  ':hover': {
    background: transparentize(0.7, Colors.PRIMARY),
  },
  ':active': {
    outline: 'none',
  },
  ':focus': {
    outline: 'none',
  },
})

const SubmitButton = ({
  resources,
}: Props) => {
  const { team }: any = useParams()

  const { node, nodeType }: any = useQueryString()

  const { update, isUpdating } = useTeamNodeResource({
    queryInput: {
      nodeType,
      team,
      [nodeType]: node,
    }
  })

  const updateResources = () => {
    update({
      variables: {
        input: {
          nodeType,
          team,
          [nodeType]: node,
          resources,
        },
      },
    })
  }

  return (
    <Button
      disabled={!resources || isUpdating}
      onClick={updateResources}
    >
      <Label
        isUpdating={isUpdating}
      />
    </Button>
  )
}

export default SubmitButton
