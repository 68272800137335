import styled from '@emotion/styled'
import { transparentize } from 'polished'

interface Props {
  color: string
}

export const ToggleAllButton = styled.button({
  border: 'none',
  borderRadius: 4,
  cursor: 'pointer',
  fontSize: 10,
  fontWeight: 700,
  padding: `8px 12px`,
  textTransform: 'uppercase',
  ':active': {
    outline: 'none',
  },
  ':focus': {
    outline: 'none',
  },
}, ({ color }: Props) => ({
  color: color,
  background: transparentize(0.85, color),
  ':hover': {
    background: transparentize(0.7, color),
  },
}))
