import React from 'react'
import styled from '@emotion/styled'

const CollapsedWrapperContainer = styled.div(
  {
    writingMode: 'vertical-rl',
    transform: 'rotate(-180deg)',
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: -0.1,
    margin: '80px 7px',
    position: 'absolute',
  },
  ({ isOpen }: any) => ({
    opacity: isOpen ? 0 : 1,
    transition: isOpen ? 'none' : 'opacity 1s',
  })
)

const CollapsedWrapper = ({ label, isOpen, styles }: any) => (
  <CollapsedWrapperContainer isOpen={isOpen} style={styles}>
    {label}
  </CollapsedWrapperContainer>
)

export default CollapsedWrapper
