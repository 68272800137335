import { useMutation } from '@apollo/client'

import { CREATE_PAYER_POLICY_DOCUMENT } from 'frontend/entity-hooks/usePayerPolicyDocument/gql-tags'
import {
  useClearSearchStringParam,
  useRewriteSearchString,
} from 'frontend/hooks'

import { addDocumentToCache } from './addDocumentToCache'

interface Props {
  documentId: string
  fieldState: any
}

export const useActionBar = ({ fieldState }: Props) => {
  const clearSearchStringParam = useClearSearchStringParam()
  const rewriteSearchString = useRewriteSearchString()
  const [create] = useMutation(CREATE_PAYER_POLICY_DOCUMENT, {
    onCompleted: (data) => {
      const {
        createPayerPolicyDocument: { id },
      } = data
      rewriteSearchString({
        payer_policy_document_id: id,
        importModal: undefined,
      })
    },
    update: addDocumentToCache,
  })

  const cancel = () => clearSearchStringParam(['importModal'])

  const submit = () => {
    const input = {
      variables: {
        input: {
          file: fieldState.file,
          payer_organization: fieldState.payer_organization.id,
          book_of_business: fieldState.book_of_business.id,
          benefit_type: fieldState.benefit_type,
          document_type: fieldState.document_type,
          products: fieldState.products.map(({ product: { id } }: any) => id),
          document: fieldState.document,
        },
      },
    }

    create(input)
    cancel()
  }

  return {
    cancel,
    submit,
  }
}
