import React, { useState } from 'react'
import styled from '@emotion/styled'

import NodesPanel from './NodesPanel'
import { useTeamNode } from 'frontend/entity-hooks/ember'

const Wrapper = styled.div({
  display: 'flex',
})

const Panels = ({ team }: any) => {
  const [selectedTool, setSelectedTool] = useState<any>(null)
  const [selectedDashboard, setSelectedDashboard] = useState<any>(null)
  const [selectedPage, setSelectedPage] = useState<any>(null)

  const { data: tools, isLoading: toolsLoading }: any = useTeamNode({
    queryInput: { nodeType: 'tools', team },
  })
  const { data: dashboards, isLoading: dashboardsLoading }: any = useTeamNode({
    queryInput: { nodeType: 'dashboards', team },
  })
  const { data: pages, isLoading: pagesLoading }: any = useTeamNode({
    queryInput: { nodeType: 'pages', team },
  })
  const { data: cards, isLoading: cardsLoading }: any = useTeamNode({
    queryInput: { nodeType: 'cards', team },
  })

  if (toolsLoading || dashboardsLoading || pagesLoading || cardsLoading) {
    return <>Loading...</>
  }

  return (
    <Wrapper>
      <NodesPanel
        type="tool"
        data={tools}
        onSelect={(tool) => {
          setSelectedTool(tool)
          setSelectedDashboard(null)
          setSelectedPage(null)
        }}
        selected={selectedTool}
      />

      <NodesPanel
        type="dashboard"
        data={dashboards.filter(
          (dashboard: any) => dashboard.node.parent_id === selectedTool?.node.id
        )}
        onSelect={(dashboard) => {
          setSelectedDashboard(dashboard)
          setSelectedPage(null)
        }}
        selected={selectedDashboard}
      />

      <NodesPanel
        type="page"
        data={pages.filter((page: any) => {
          return page.node.parent_id === selectedDashboard?.node.id
        })}
        onSelect={(page) => setSelectedPage(page)}
        selected={selectedPage}
      />

      <NodesPanel
        type="card"
        data={cards.filter(
          (card: any) => card.node.parent_id === selectedPage?.node.id
        )}
        selected={null}
      />
    </Wrapper>
  )
}

export default Panels
