const BEFORE_MIN_DATE_ERROR = 'Selected year must be after 1900'
const INVALID_DATE_ERROR = 'Invalid Date'
const IS_REQUIRED_ERROR = 'This field is required'

interface DateErrorTextProps {
  isRequired: boolean
  hasUserInput: boolean
  isValidDate: boolean | null
  isBeforeMinDate: boolean | null
  value: string
}

export const getDateErrorText = ({
  isRequired,
  hasUserInput,
  isValidDate,
  isBeforeMinDate,
  value,
}: DateErrorTextProps) => {
  if (isRequired && !value) {
    return IS_REQUIRED_ERROR
  } else if (hasUserInput && !isValidDate) {
    return INVALID_DATE_ERROR
  } else if (hasUserInput && isBeforeMinDate) {
    return BEFORE_MIN_DATE_ERROR
  }

  return ''
}
