import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const DELETE_TEAM_AUTH0_USER = gql`
  mutation DeleteTeamAuth0User($input: DeleteTeamAuth0UserInput!) {
    deleteTeamAuth0User(input: $input) {
      ${schemaV0}
    }
  }
`
