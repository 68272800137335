import React from 'react'
import styled from '@emotion/styled'

import Icon from 'frontend/components/Icon'

const ToolSideBarHeader = styled.div({
  height: '100%',
  background: '#0D3C64',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 12,
})

const RotatedToolText = styled.h2({
  writingMode: 'tb',
  padding: '12px 0',
  fontSize: 13,
  lineHeight: '16px',
})

const SidebarHeader = () => {
  return (
    <ToolSideBarHeader>
      <Icon width={20} height={20} iconName="payer-1-white" />
      <RotatedToolText>Payer Policy Tracker</RotatedToolText>
    </ToolSideBarHeader>
  )
}

export default SidebarHeader
