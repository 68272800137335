import React from 'react'
import { transparentize } from 'polished'

import { useRewriteSearchString } from 'frontend/hooks'
import { usePayerPolicyDocument } from 'frontend/entity-hooks'
import { DataTable, DataTableContainer } from 'frontend/components'
import { AlphaColors, Colors } from 'frontend/utils/pulseStyles'

import { Header } from './Header'
import { getExportConfig } from './getExportConfig'
import { getColumnConfig } from './column-config'
import { getUtilityBar } from './getUtilityBar'
import { formatColumnData } from './column-config/formatColumnData'

const HEADER_TEXT = 'Payer Policy Tracker'
const EXCEL_LABEL = 'Payer Policy Tracker'
const TABLE_ID = 'payer-policy-tracker-v1'

const TABLE_STYLE_CONFIG = {
  superheaders: {
    display: 'flex',
    justifyContent: 'center',
    background: transparentize(0.9, Colors.PRIMARY),
    color: Colors.PRIMARY,
    fontSize: 11,
    letterSpacing: -0.2,
    fontWeight: 600,
    lineHeight: '16px',
    padding: 0,
    borderBottom: 'none',
    borderRight: `1px solid ${AlphaColors.Black10}`,
  },
}

const superHeaderStyle = {
  '.table .header > div:first-child': {
    background: Colors.BLUE_100,
  },
}

// const utilityBar = getUtilityBar()

const Policies = () => {
  const rewriteSearchString = useRewriteSearchString()
  const {
    data: latestPayerPolicyDocuments,
    isLoading,
  } = usePayerPolicyDocument({
    queryInput: { areLatest: true },
  })
  const formattedData = formatColumnData(latestPayerPolicyDocuments)

  const colConfig = getColumnConfig({ setModalType: rewriteSearchString })
  const exportConfig = getExportConfig()

  return (
    <DataTableContainer style={{ width: 'calc(100vw - 104px)' }}>
      <Header header={HEADER_TEXT} />
      <DataTable
        tableId={TABLE_ID}
        data={formattedData}
        isLoading={isLoading}
        isFocusable
        isPaginated
        hasReset
        hasExport
        hasRowCount
        exportConfig={exportConfig}
        excelLabel={EXCEL_LABEL}
        isHoverable
        colConfig={colConfig}
        isEditable
        editAccessor={'id'}
        paginationConfig={{
          wrapperStyle: { alignSelf: 'flex-start' },
          pageSize: 120,
        }}
        defaultColWidths={{ minWidth: 50, width: 200 }}
        // queryStringConfig={{
        //   showPageNumber: true,
        //   showSearchFilter: true,
        //   showSort: true,
        //   filterAccessorsToShow,
        // }}
        styleConfig={TABLE_STYLE_CONFIG}
        // utilityBarNodes={utilityBar}
        wrapperStyle={superHeaderStyle}
      />
    </DataTableContainer>
  )
}

export default Policies
