export const getCustomStyles = ({ customStyles }: any) => {
  return {
    '& .MuiInputBase-root': {
      height: customStyles?.height || 'auto',
      width: customStyles?.width || '100%',
      backgroundColor: customStyles?.backgroundColor || 'white',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: customStyles?.borderColor || 'black',
      borderWidth: customStyles?.borderWidth || '1px',
    },
  }
}
