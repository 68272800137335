import { useQuery } from '@apollo/client'

import { GET_CMS_PROVIDER_STAFFING } from 'frontend/api/queries'

const useCmsProviderData = () => {
  const cmsProviderData = useQuery(GET_CMS_PROVIDER_STAFFING)

  return {
    cmsProviders: {
      data: cmsProviderData.data?.cmsProviderStaffing,
      loading: cmsProviderData.loading,
    },
  }
}

export default useCmsProviderData
