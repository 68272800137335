import React from 'react'
import styled from '@emotion/styled'

import { Select } from 'frontend/components'
import { useBookOfBusiness } from 'frontend/entity-hooks'

import { formatOptions } from '../../../utils'
import { selectStyles } from '../selectStyles'
import { Label } from './shared'

interface Props {
  onChange: any
}

const LABEL_TEXT = 'Book'
const SELECT_PLACEHOLDER = 'Book'
const FIELD_NAME = 'book'

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 16,
  marginRight: 24,
  width: 196,
})

export const Book = ({ onChange }: Props) => {
  const { data: books, isLoading } = useBookOfBusiness()
  const handleChange = ({ value }: any) => {
    onChange({
      name: FIELD_NAME,
      value,
    })
  }

  const bookOptions = formatOptions(books, isLoading, 'name')

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Select
        options={bookOptions}
        styles={selectStyles}
        placeholder={SELECT_PLACEHOLDER}
        onChange={handleChange}
      />
    </Wrapper>
  )
}
