import compareDates from "frontend/utils/compareDates"

const sortDateColumn = (rowA: any, rowB: any, columnId: string): number => {
  const date1Value = rowA.values[columnId]
  const date2Value = rowB.values[columnId]
  const date1 = date1Value && new Date(date1Value)
  const date2 = date1Value && new Date(date2Value)
  return compareDates(date1, date2)
}

export default sortDateColumn
