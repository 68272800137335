import gql from 'graphql-tag'

const GET_PRODUCT_PAYER_POLICY_TRACKING_PROJECT = gql`
  query GetProductPayerPolicyTrackingProjects {
    productPayerPolicyTrackingProjects {
      id
      name
      tracking_alert_preference
      alert_emails
      created_at
      updated_at
    }
  }
`

const CREATE_PRODUCT_PAYER_POLICY_TRACKING_PROJECT = gql`
  mutation CreateProductPayerPolicyTrackingProject(
    $input: CreateProductPayerPolicyTrackingProjectInput!
  ) {
    createProductPayerPolicyTrackingProject(input: $input) {
      id
      name
      tracking_alert_preference
      alert_emails
      created_at
      updated_at
    }
  }
`

const UPDATE_PRODUCT_PAYER_POLICY_TRACKING_PROJECT = gql`
  mutation UpdateProductPayerPolicyTrackingProject(
    $input: UpdateProductPayerPolicyTrackingProjectInput!
  ) {
    updateProductPayerPolicyTrackingProject(input: $input) {
      id
      name
      tracking_alert_preference
      alert_emails
      created_at
      updated_at
    }
  }
`

const DELETE_PRODUCT_PAYER_POLICY_TRACKING_PROJECT = gql`
  mutation DeleteProductPayerPolicyTrackingProject(
    $input: DeleteProductPayerPolicyTrackingProjectInput!
  ) {
    deleteProductPayerPolicyTrackingProject(input: $input) {
      id
      name
      tracking_alert_preference
      alert_emails
      created_at
      updated_at
    }
  }
`
export default {
  GET_PRODUCT_PAYER_POLICY_TRACKING_PROJECT,
  CREATE_PRODUCT_PAYER_POLICY_TRACKING_PROJECT,
  UPDATE_PRODUCT_PAYER_POLICY_TRACKING_PROJECT,
  DELETE_PRODUCT_PAYER_POLICY_TRACKING_PROJECT,
}
