import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

import { BenefitType } from './BenefitType'
import { Book } from './Book'
import { DocumentType } from './DocumentType'
import { FileSelect } from './FileSelect'
import { Payer } from './Payer'

interface Props {
  onChange: any
}

const TITLE_TEXT = 'Policy Details'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  borderRadius: 6,
  backgroundColor: Colors.WHITE,
  padding: 24,
  height: 336,
  width: 464,
})

const BookBenefit = styled.span({
  display: 'flex',
  flexDirection: 'row',
})

const Title = styled.h1({
  fontSize: 16,
  fontWeight: 500,
  marginBottom: 24,
})

export const PolicyDetails = ({ onChange }: Props) => {
  return (
    <Wrapper>
      <Title>{TITLE_TEXT}</Title>
      <FileSelect onChange={onChange} />
      <Payer onChange={onChange} />
      <BookBenefit>
        <Book onChange={onChange} />
        <BenefitType onChange={onChange} />
      </BookBenefit>
      <DocumentType onChange={onChange} />
    </Wrapper>
  )
}
