import React from 'react'

import { SingleActionDialog } from 'frontend/components/Dialog'
import { Form, Spinner } from 'frontend/components'

const TEXT = {
  MODAL_TITLE: 'Create NCCN Panel Affiliation',
  SUBMIT_BUTTON_TEXT: 'Create',
}

const CreateModal = ({
  submit,
  isSubmitting,
  cancel,
  formConfig,
  inputRef,
  setInputRef,
  buttonRef,
}: any) => {
  const submitButtonText = isSubmitting ? <Spinner /> : TEXT.SUBMIT_BUTTON_TEXT

  return (
    <SingleActionDialog
      header={TEXT.MODAL_TITLE}
      cancelHandler={cancel}
      submitHandler={submit}
      submitText={submitButtonText}
      buttonRef={buttonRef}
    >
      <Form
        datum={inputRef.current}
        formConfig={formConfig}
        setFormState={setInputRef}
      />
    </SingleActionDialog>
  )
}

export default CreateModal
