import { CrudHookOptions } from '../utils/useCrudHookApi/interfaces'
import gqlTags from './gql-tags'

export const getPayerPolicyTrackingProjectConfig = ({
  deleteOptions = {},
  queryInput = {},
}: CrudHookOptions = {}) => ({
  createConfig: {
    tag: gqlTags.CREATE_PRODUCT_PAYER_POLICY_TRACKING_PROJECT,
    readTag: gqlTags.GET_PRODUCT_PAYER_POLICY_TRACKING_PROJECT,
  },

  readConfig: {
    tag: gqlTags.GET_PRODUCT_PAYER_POLICY_TRACKING_PROJECT,
    input: queryInput,
  },

  updateConfig: {
    tag: gqlTags.UPDATE_PRODUCT_PAYER_POLICY_TRACKING_PROJECT,
  },

  deleteConfig: {
    tag: gqlTags.DELETE_PRODUCT_PAYER_POLICY_TRACKING_PROJECT,
    readTag: gqlTags.GET_PRODUCT_PAYER_POLICY_TRACKING_PROJECT,
    mutationOptions: deleteOptions,
  },
})
