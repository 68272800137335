import React, { Dispatch, SetStateAction } from 'react'
import { Box } from '@mui/material'

import { SectionLabel } from '../SectionLabel'
import PolicyLastUpdated from './PolicyLastUpdated'
import PreviousPolicy from './PreviousPolicy'
import ImportPdfButton from './ImportPdfButton'

interface Props {
  activeTab: string
  mostRecentPolicy: any
  policyState: any
  previousApprovedPolicyOptions?: any[]
  selectedPreviousApprovedPolicy?: any
  setPolicyState: Dispatch<SetStateAction<any>>
  setSelectedPreviousApprovedPolicy: Dispatch<SetStateAction<any>>
}

const DocumentSection = ({
  activeTab,
  mostRecentPolicy,
  policyState,
  previousApprovedPolicyOptions,
  selectedPreviousApprovedPolicy,
  setPolicyState,
  setSelectedPreviousApprovedPolicy
}: Props) => {
  const height = activeTab === 'mostRecent' ? '188px' : '272px'

  return (
    <Box
      sx={{
        marginTop: '24px',
        marginLeft: '12px',
        padding: '24px 24px 12px',
        borderRadius: '8px',
        border: '1px solid #e0e0e0',
        width: '423px',
        height: height,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: '12px'
        }}
      >
        <SectionLabel label="Documents" />
        <ImportPdfButton />
      </Box>
      <PreviousPolicy
        activeTab={activeTab}
        previousApprovedPolicyOptions={previousApprovedPolicyOptions}
        selectedPreviousApprovedPolicy={selectedPreviousApprovedPolicy}
        setSelectedPreviousApprovedPolicy={setSelectedPreviousApprovedPolicy}
      />
      <PolicyLastUpdated
        activeTab={activeTab}
        mostRecentPolicy={mostRecentPolicy}
        policyState={policyState}
        setPolicyState={setPolicyState}
      />
    </Box>
  )
}

export default DocumentSection
