import { INDICATION, REGIMEN, PATHWAYS_ORGANIZATION } from '../utils'
import { isSameTempPathwaysPosition, haveSamePopulations } from './utils'

const findCreatedPathwaysPositionIndex = (
  createdPathwaysPosition,
  pathwaysPositions,
  fieldLabelMap
) => {
  let createdPathwaysPositionIndex = 0
  const tempPathOrgIndReg = {}

  /*
    Find index of pathways position's unique fields (indication, regimen, pathways_organization) combination.
    Pathways positions are ordered by indication > regimen > pathways_organization.
    Need tempPathOrgIndReg to keep track of current equal fields while iterating through unique fields.
    If new combination is found, break early.
*/
  let isNewPathOrgIndReg = false
  const pathwaysPositionKeys = [INDICATION, REGIMEN, PATHWAYS_ORGANIZATION]
  for (const key of pathwaysPositionKeys) {
    if (isNewPathOrgIndReg) break
    const labelMap = fieldLabelMap[key]
    while (
      shouldIncrementIndex(
        createdPathwaysPosition,
        pathwaysPositions,
        createdPathwaysPositionIndex,
        tempPathOrgIndReg,
        labelMap,
        key
      )
    )
      createdPathwaysPositionIndex++
    isNewPathOrgIndReg =
      createdPathwaysPositionIndex === pathwaysPositions.length ||
      !isSameTempPathwaysPosition(
        tempPathOrgIndReg,
        pathwaysPositions[createdPathwaysPositionIndex]
      ) ||
      createdPathwaysPosition[key] !==
        pathwaysPositions[createdPathwaysPositionIndex][key]
    tempPathOrgIndReg[key] = createdPathwaysPosition[key]
  }

  /*
      Find index of pathways position with matching unqiue fields + populations.
  */
  let isNewPopulations = true
  while (
    createdPathwaysPositionIndex < pathwaysPositions.length &&
    isSameTempPathwaysPosition(
      tempPathOrgIndReg,
      pathwaysPositions[createdPathwaysPositionIndex]
    )
  ) {
    if (
      haveSamePopulations(
        createdPathwaysPosition,
        pathwaysPositions[createdPathwaysPositionIndex]
      )
    ) {
      isNewPopulations = false
      break
    }
    createdPathwaysPositionIndex++
  }

  return [createdPathwaysPositionIndex, isNewPathOrgIndReg, isNewPopulations]
}

export default findCreatedPathwaysPositionIndex

const shouldIncrementIndex = (
  createdPathwaysPosition,
  pathwaysPositions,
  createdPathwaysPositionIndex,
  tempPathOrgIndReg,
  labelMap,
  key
) =>
  createdPathwaysPositionIndex < pathwaysPositions.length &&
  isSameTempPathwaysPosition(
    tempPathOrgIndReg,
    pathwaysPositions[createdPathwaysPositionIndex]
  ) &&
  labelMap[createdPathwaysPosition[key]].toLowerCase() >
    labelMap[pathwaysPositions[createdPathwaysPositionIndex][key]].toLowerCase()
