import React from 'react'

const Error = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg viewBox="-1 -2 14 14" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.2501 6C11.2501 8.8995 8.89958 11.25 6.00008 11.25C3.10059 11.25 0.750081 8.8995 0.750081 6C0.750081 3.10051 3.10059 0.750005 6.00008 0.750005C8.89958 0.750005 11.2501 3.10051 11.2501 6ZM8.44551 9.2704C7.76384 9.78094 6.91728 10.0833 6.00008 10.0833C3.74492 10.0833 1.91675 8.25517 1.91675 6C1.91675 5.08281 2.21915 4.23624 2.72969 3.55457L8.44551 9.2704ZM9.27047 8.44544L3.55465 2.72961C4.23632 2.21907 5.08289 1.91667 6.00008 1.91667C8.25524 1.91667 10.0834 3.74484 10.0834 6C10.0834 6.9172 9.78101 7.76377 9.27047 8.44544Z"
        fill={iconColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.604248 6C0.604248 3.01997 3.02004 0.604172 6.00008 0.604172C8.98012 0.604172 11.3959 3.01997 11.3959 6C11.3959 8.98004 8.98012 11.3958 6.00008 11.3958C3.02004 11.3958 0.604248 8.98004 0.604248 6ZM6.00008 0.895838C3.18113 0.895838 0.895915 3.18105 0.895915 6C0.895915 8.81896 3.18113 11.1042 6.00008 11.1042C8.81903 11.1042 11.1042 8.81896 11.1042 6C11.1042 3.18105 8.81903 0.895838 6.00008 0.895838ZM3.46723 2.61289C4.17326 2.0841 5.0503 1.77084 6.00008 1.77084C8.33579 1.77084 10.2292 3.6643 10.2292 6C10.2292 6.94979 9.91598 7.82683 9.3872 8.53286L9.28633 8.66754L3.33255 2.71376L3.46723 2.61289ZM3.77932 2.74805L9.25204 8.22076C9.68461 7.5886 9.93758 6.82403 9.93758 6C9.93758 3.82538 8.1747 2.06251 6.00008 2.06251C5.17606 2.06251 4.41148 2.31548 3.77932 2.74805ZM2.71383 3.33247L8.66761 9.28625L8.53294 9.38712C7.8269 9.91591 6.94986 10.2292 6.00008 10.2292C3.66438 10.2292 1.77091 8.33571 1.77091 6C1.77091 5.05022 2.08418 4.17318 2.61297 3.46715L2.71383 3.33247ZM2.74813 3.77925C2.31556 4.41141 2.06258 5.17598 2.06258 6C2.06258 8.17463 3.82546 9.9375 6.00008 9.9375C6.82411 9.9375 7.58868 9.68453 8.22084 9.25196L2.74813 3.77925Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default Error
