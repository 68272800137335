import { INPUT_TYPES } from 'frontend/components/Form/constants/input-type-to-component-map'

const getFormConfig = ({ productsOptions }) => {
  return [
    [{ label: 'Select a File', accessor: 'file', inputType: INPUT_TYPES.FILE }],
    [
      {
        label: 'Products',
        accessor: 'products',
        inputType: INPUT_TYPES.MULTI_SELECT,
        options: productsOptions,
      },
    ],
  ]
}

export default getFormConfig
