const getPathwaysColumnsInjectables = ({ setSelectedRowData }: {setSelectedRowData: any}) => {
  const onRowClick = ({ original }: { original: any }) => {
    setSelectedRowData(original)
  }
  return {
    onRowClick,
  }
}

export default getPathwaysColumnsInjectables
