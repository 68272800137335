import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import _ from 'lodash'

import { DeleteDialog } from 'frontend/components'
import { useSecondaryOrganizations } from 'frontend/entity-hooks'

const LABEL = 'Delete Secondary Organization'

const SEARCH_STRING_PARAMS = ['deleteSecondaryOrganization', 'secondaryOrganizationId']

const DeleteDialogSecondaryOrganization = () => {
  const location = useLocation()

  const { secondaryOrganizationId } = queryString.parse(location.search)

  const {
    data, isLoading, destroy, isDeleting,
  } = useSecondaryOrganizations({ queryInput: { id: secondaryOrganizationId } })

  const secondaryOrganization = getSecondaryOrganization(data, secondaryOrganizationId)

  const handleDelete = (handleCancel: any) => {
    const input = { id: secondaryOrganization.id }
    destroy({ variables: { input } }).then(handleCancel)
  }

  const entityDescription = secondaryOrganization?.name

  return (
    <DeleteDialog
      isDeleting={isDeleting}
      isLoading={isLoading}
      entityDescription={entityDescription}
      handleDelete={handleDelete}
      label={LABEL}
      searchStringParams={SEARCH_STRING_PARAMS}
    />
  )
}

export default DeleteDialogSecondaryOrganization

const getSecondaryOrganization = (data: any, secondaryOrganizationId: any) => {
  if (Boolean(secondaryOrganizationId) && !_.isEmpty(data)) return data[0]
  return undefined
}
