import React from 'react'

import { FieldContainer } from './styled-components'
import { FieldSectionProps } from './constants/interfaces'
import Field from './Field'

const FieldSection = ({
  datum,
  section,
  setFormState,
  style,
  children,
}: FieldSectionProps) => {
  return (
    <FieldContainer style={style}>
      {section.map((fieldConfig, idx) => (
        <Field
          key={`${idx}`}
          datum={datum}
          fieldConfig={fieldConfig}
          setFormState={setFormState}
        />
      ))}
      {children}
    </FieldContainer>
  )
}

export default FieldSection
