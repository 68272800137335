import React from 'react'
import styled from '@emotion/styled'

import { Spinner } from 'frontend/components'

interface Props {
  isUpdating: boolean
}

const Wrapper = styled.span({
  marginLeft: 12,
})

const Label = ({
  isUpdating,
}: Props) => {
  if (isUpdating) return (
    <>
      <Spinner />
      <Wrapper>
        Saving
      </Wrapper>
    </>
  )

  return (
    <>
      Save & Close
    </>
  )
}

export default Label
