import MapParams from '../interfaces/MapParams.interface'

const getLabelMap = (
  { data, accessor }: MapParams,
  { key } = { key: 'id' }
) => {
  const labelMap: any = {}
  if (!data.loading) {
    const items = data.data
    const [dataKey] = Object.keys(items)
    items[dataKey].forEach((datum: any) => {
      if (Array.isArray(accessor)) {
        for (let i = 0; i < accessor.length; i++) {
          if (accessor[i]) {
            labelMap[datum[key]] = datum[accessor[i]]
            break
          }
        }
      } else {
        labelMap[datum[key]] = datum[accessor]
      }
    })
  }
  return labelMap
}

export default getLabelMap
