import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'

import Checkbox from 'frontend/components/Checkbox'
import Spinner from 'frontend/components/Spinner'
import {
  Label,
  LabelText,
  SectionTitle,
} from 'frontend/Orion/shared/AccountModals/AccountModalButton/styledAccountModalButtonComponents'
import Input from 'frontend/components/Input'
import { SingleActionDialog } from 'frontend/components/Dialog'

import getIndexByBinarySearch from 'frontend/utils/getIndexByBinarySearch'

import { GET_PROVIDER_ORGANIZATIONS } from 'frontend/api/queries'
import { UPDATE_PROVIDER } from 'frontend/api/mutations'

import AliasesMultiSelect from './AliasesMultiSelect'
import DeleteProviderAccountForm from './DeleteProviderAccountForm'
import { StateSelect } from './shared'

const PAGE_HEADER = 'Edit Provider Account'

const FLAG_FOR_DATA_EXTRACTION = 'Flag for Data Extraction'

const editProviderAccountFormColumns = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
}

const EditProviderAccountForm = ({
  selectedProviderOrganizationData,
  closeHandler,
}) => {
  const {
    _id,
    slug,
    organization,
    organizationTiny,
    providerCancerCenter,
    state,
    city,
    oncologistsCount,
    sitesCount,
    groupPracticePacId,
    aliases,
    isFlaggedForDataExtraction,
  } = selectedProviderOrganizationData

  const [form, setForm] = useState({
    _id,
    slug,
    organization,
    organizationTiny,
    providerCancerCenter,
    state,
    city,
    oncologistsCount,
    sitesCount,
    groupPracticePacId,
    aliases,
    is_flagged_for_data_extraction: isFlaggedForDataExtraction,
  })

  const handleChange = ({ name, value }) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleCheckboxChange = ({ target: { name, value } }) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSelectChange = ({ value }, { name }) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const input = {
    ...form,
    oncologistsCount: parseInt(form.oncologistsCount),
    sitesCount: parseInt(form.sitesCount),
  }

  const updateCacheWithSortedProvidersAccounts = (
    cache,
    { data: { updateProvider } }
  ) => {
    const { providerOrganizations } = cache.readQuery({
      query: GET_PROVIDER_ORGANIZATIONS,
    })

    const sortedProviderOrganizations = [...providerOrganizations].filter(
      ({ _id }) => _id !== updateProvider._id
    )
    const newSortedAccountIndex = getIndexByBinarySearch({
      data: sortedProviderOrganizations,
      newDatum: updateProvider,
      accessor: 'organization',
    })
    sortedProviderOrganizations.splice(newSortedAccountIndex, 0, updateProvider)
    cache.writeQuery({
      query: GET_PROVIDER_ORGANIZATIONS,
      data: {
        providerOrganizations: sortedProviderOrganizations,
      },
    })
  }

  const [
    updateProviderAccount,
    { loading: isProviderAccountLoading },
  ] = useMutation(UPDATE_PROVIDER, {
    variables: { input },
    update: updateCacheWithSortedProvidersAccounts,
    onError: alert,
    onCompleted: () => {
      closeHandler()
    },
  })

  return (
    <SingleActionDialog
      header={PAGE_HEADER}
      submitText={PAGE_HEADER}
      submitHandler={updateProviderAccount}
      cancelHandler={closeHandler}
    >
      {isProviderAccountLoading ? (
        <Spinner />
      ) : (
        <div style={editProviderAccountFormColumns}>
          <div
            style={{
              display: 'flex',
              padding: '30px 30px',
              justifyContent: 'space-between',
              flexDirection: 'column',
            }}
          >
            <SectionTitle>Provider Account Info</SectionTitle>

            <Label>
              <LabelText>Name</LabelText>

              <Input
                name="organization"
                type="text"
                onChange={handleChange}
                value={form.organization}
              />
            </Label>
            <Label>
              <LabelText>Short Name</LabelText>
              <Input
                name="organizationTiny"
                type="text"
                onChange={handleChange}
                value={form.organizationTiny}
              />
            </Label>
            <Label>
              <LabelText> Slug </LabelText>
              <Input
                name="slug"
                type="text"
                onChange={handleChange}
                value={form.slug}
              />
            </Label>
            <Label>
              <LabelText> City </LabelText>
              <Input
                name="city"
                type="text"
                onChange={handleChange}
                value={form.city}
              />
            </Label>

            <Label>
              <LabelText> State</LabelText>
              <StateSelect
                name="state"
                onChange={handleSelectChange}
                defaultValue={form.state}
              />
            </Label>

            <Label>
              <LabelText> Oncologists # </LabelText>
              <Input
                name="oncologistsCount"
                type="text"
                onChange={handleChange}
                value={form.oncologistsCount}
              />
            </Label>

            <Label>
              <LabelText> Cancer Center </LabelText>
              <Input
                name="providerCancerCenter"
                type="text"
                onChange={handleChange}
                value={form.providerCancerCenter}
              />
            </Label>

            <Label>
              <LabelText> Sites # </LabelText>
              <Input
                name="sitesCount"
                type="text"
                onChange={handleChange}
                value={form.sitesCount}
              />
            </Label>

            <Label>
              <LabelText> Group Practice PAC ID </LabelText>
              <Input
                name="groupPracticePacId"
                type="text"
                onChange={handleChange}
                value={form.groupPracticePacId}
              />
            </Label>

            <Label>
              <LabelText> Aliases </LabelText>
              <AliasesMultiSelect
                name="aliases"
                onChange={handleChange}
                value={form.aliases}
              />
            </Label>

            <Checkbox
              name={'is_flagged_for_data_extraction'}
              onChange={handleCheckboxChange}
              value={form.is_flagged_for_data_extraction}
              label={FLAG_FOR_DATA_EXTRACTION}
              style={{ marginTop: 10, marginBottom: 5 }}
              labelStyle={{ fontWeight: 'bold' }}
            />

            <DeleteProviderAccountForm
              accountId={_id}
              closeHandler={closeHandler}
            />
          </div>
        </div>
      )}
    </SingleActionDialog>
  )
}

EditProviderAccountForm.propTypes = {
  selectedProviderOrganizationData: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
}

export default EditProviderAccountForm
