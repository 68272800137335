import React, { Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'

import Color from 'frontend/utils/color'

import Header from './Header'
import NodesListPanel from './NodesListPanel'
import { ParentType, Sitemap, Type } from '../../types'

interface Props {
  type: Type
  parentType?: ParentType
  sitemap?: Sitemap
  setSitemap: Dispatch<SetStateAction<Sitemap | undefined>>
}

const Wrapper = styled.div({
  flex: 1,
}, ({ type }: Props) => ({
  borderRight: type !== 'card' ?
    `2px solid ${Color.GRAY_LIGHT}` :
    undefined,
}))

const NodesPanel = ({
  ...props
}: Props) => {
  return (
    <Wrapper
      {...props}
    >
      <Header
        {...props}
      />
      <NodesListPanel
        {...props}
      />
    </Wrapper>
  )
}

export default NodesPanel
