import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'
import { useQueryString } from 'frontend/hooks'

import { DangerZone } from './DangerZone'

const Border = styled.div({
  borderBottom: `1px solid ${Colors.GRAY_200}`,
})

export const Container = () => {
  const { team } = useQueryString()

  if (!team || team === 'all-users') return null

  return (
    <>
      <Border />
      <DangerZone />
    </>
  )
}
