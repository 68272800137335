import React from 'react'
import styled from '@emotion/styled'

import { ListPanel } from 'frontend/components'
import { useClient } from 'frontend/entity-hooks/ember'

import ClientsLoadingPanel from './ClientsLoadingPanel'
import ListItem from './ListItem'

const SEARCH_STRING_KEY = 'client'
const SEARCH_STRING_ACCESSOR = 'id'

const Wrapper = styled.div({
  height: 'calc(100vh - 96px - 50px)',
})

const ClientsListPanel = () => {
  const { isLoading, data: clients } = useClient()

  if (isLoading) return <ClientsLoadingPanel />

  return (
    <Wrapper>
      <ListPanel
        data={clients}
        searchStringKey={SEARCH_STRING_KEY}
        searchStringAccessor={SEARCH_STRING_ACCESSOR}
        ListItem={ListItem}
      />
    </Wrapper>
  )
}

export default ClientsListPanel
