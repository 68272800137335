import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@pulse-analytics/pulse-design-system'
import queryString from 'query-string'

const TEXT = {
  BUTTON_LABEL: 'Create Provider KDM',
  BUTTON_KEY: 'create-provider-kdm-button',
}

const BUTTON_STYLE = { padding: 12 }

const CreateProviderKdmButton = () => {
  const history = useHistory()
  const addCreateParam = () => {
    const createParam = queryString.stringify({ createProviderKdm: true })

    history.push({ search: createParam })
  }

  return (
    <Button style={BUTTON_STYLE} onClick={addCreateParam} key={TEXT.BUTTON_KEY}>
      {TEXT.BUTTON_LABEL}
    </Button>
  )
}

export default CreateProviderKdmButton
