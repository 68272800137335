import * as GQL_TAGS from './gql-tags'

export const getPathwaysInfluencerConfigs = (
  deleteOptions = {},
  queryInput: any
) => ({
  createConfig: {
    tag: GQL_TAGS.CREATE_PATHWAYS_INFLUENCER,
    readTag: GQL_TAGS.GET_PATHWAYS_INFLUENCERS,
  },
  updateConfig: {
    tag: GQL_TAGS.UPDATE_PATHWAYS_INFLUENCER,
  },
  deleteConfig: {
    tag: GQL_TAGS.DELETE_PATHWAYS_INFLUENCER,
    readTag: GQL_TAGS.GET_PATHWAYS_INFLUENCERS,
    mutationOptions: deleteOptions,
  },
  readConfig: {
    tag: GQL_TAGS.GET_PATHWAYS_INFLUENCERS,
    input: queryInput,
  },
  readOptionsConfig: {
    tag: GQL_TAGS.GET_PATHWAYS_INFLUENCERS_OPTIONS,
  },
})
