import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import { GET_VEGA_PEOPLE_ROLES } from 'frontend/api/queries'

import Spinner from 'frontend/components/Spinner'
import DataTable from 'frontend/components/DataTable'
import MultiSelectColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/MultiSelectColumnFilter'
import customMultiSelectFilterFn from 'frontend/components/Table/custom-filters/MultiSelect/customMultiSelectFilterFn'
import { EXPAND } from 'frontend/components/DataTable/Cells/cellTypes'

import EditRoleForm from './EditRoleForm'

const NO_TYPE_NAME = 'No Type'

const RolesTable = () => {
  const [selectedRowId, setSelectedRowId] = useState(null)

  const { data: rolesData, loading: rolesLoading } = useQuery(
    GET_VEGA_PEOPLE_ROLES
  )

  const onRowClick = (row) => {
    const { original } = row
    setSelectedRowId(original.id)
  }

  let tableData
  if (!rolesLoading)
    tableData = rolesData.vegaPeopleRoles.map(
      ({ id, name, type, default_specialty_label }) => {
        const typeName = type ? type.name : NO_TYPE_NAME

        return {
          id,
          name,
          typeName,
          specialty: default_specialty_label,
        }
      }
    )

  const selectedRoleData = selectedRowId
    ? rolesData.vegaPeopleRoles.find(({ id }) => id === selectedRowId)
    : null

  const COLUMNS = [
    {
      Header: 'ID',
      accessor: 'id',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
    },
    {
      Header: 'Role',
      accessor: 'name',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
    {
      Header: 'Type',
      accessor: 'typeName',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
    {
      Header: 'Default Specialty Label',
      accessor: 'specialty',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
  ]

  return (
    <>
      {rolesLoading ? (
        <Spinner />
      ) : (
        <>
          <DataTable data={tableData} columns={COLUMNS} />
          {selectedRoleData && (
            <EditRoleForm
              selectedRoleData={selectedRoleData}
              closeHandler={() => setSelectedRowId(null)}
            />
          )}
        </>
      )}
    </>
  )
}

export default RolesTable
