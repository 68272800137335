import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const UPDATE_ALTERNATIVE_PAYMENT_MODEL_ORGANIZATION = gql`
  mutation UpdateAlternativePaymentModelOrganization($input: UpdateAlternativePaymentModelOrganizationInput!) {
    updateAlternativePaymentModelOrganization(input: $input) {
      ${schemaV0}
    }
  }
`
