import gql from 'graphql-tag'

export const GET_PRODUCT_POLICY_LINKS_OPTIONS = gql`
  query GetProductPolicyLinksOptions($input: ProductPolicyLinkOptionsInput!) {
    productPolicyLinkOptions(input: $input) {
      approval_status {
        options {
          label
          value
        }
        labelMap
      }
      link_status {
        options {
          label
          value
        }
        labelMap
      }
      link_type {
        options {
          label
          value
        }
        labelMap
      }
    }
  }
`
