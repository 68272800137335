import React from 'react'

import ExportExcelButton from 'frontend/components/ExportExcelButton'
import Icon from 'frontend/components/Icon'
import Color from 'frontend/utils/color'

interface TableExportConfigTypes {
  filename?: string
  sheetName?: string
  formatRows: any
}

const TableExport = (props: {
  rows: any[]
  hasExport: boolean
  exportConfig: TableExportConfigTypes
}) => {
  if (!props.hasExport) return null
  const {
    rows,
    exportConfig: { filename, sheetName, formatRows },
  } = props

  return (
    <div style={{ marginLeft: 24 }}>
      <ExportExcelButton
        data={formatRows(rows)}
        filename={filename || ''}
        sheetName={sheetName || ''}
        buttonStyle={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Icon
          iconName="export"
          color1={Color.PRIMARY}
          width={12}
          height={12}
          style={{ marginRight: 8 }}
        />
        Export
      </ExportExcelButton>
    </div>
  )
}

export default TableExport
