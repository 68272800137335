import { processExcelFile } from './processExcelFile'

const PDF_FILE_TYPE = 'application/pdf'
const XLSX_FILE_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const processFile = (e, name, onChange) => {
  const file = e.currentTarget.files[0]

  const reader = new FileReader()

  if (file.type === XLSX_FILE_TYPE) {
    reader.readAsArrayBuffer(file)
  } else if (file.type === PDF_FILE_TYPE) {
    reader.readAsDataURL(file)
  } else {
    alert('Unsupported file type.')
  }

  reader.onload = (e) => {
    if (file.type === XLSX_FILE_TYPE) {
      processExcelFile(e, name, onChange, file.name)
    } else if (file.type === PDF_FILE_TYPE) {
      onChange({
        fileName: file.name,
        name,
        value: reader.result,
      })
    }
  }
}
