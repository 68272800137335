import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Button } from '@pulse-analytics/pulse-design-system'

import Icon from 'frontend/components/Icon'
import Color from 'frontend/utils/color'

const BUTTON_TEXT = 'Import New Policy'

const BUTTON_STYLING = {
  margin: '10px 0 10px 24px',
  width: '155px',
  height: '30px',
  justifyContent: 'center',
}

export const ImportButton = ({ onClick }) => {
  const FLAGS = useFlags()

  if (!FLAGS.shouldShowManuallyImportPayerPolicy) return null

  return (
    <Button type="primary" style={BUTTON_STYLING} onClick={onClick}>
      <Icon height={16} width={16} iconName={'add'} color1={Color.WHITE} />
      <span style={{ paddingLeft: '6px' }}>{BUTTON_TEXT}</span>
    </Button>
  )
}
