import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Button } from '@pulse-analytics/pulse-design-system'

import Spinner from 'frontend/components/Spinner'
import DataTable from 'frontend/components/DataTable'
import Header from 'frontend/components/Header'
import ExportExcelButton from './../../../components/ExportExcelButton'
import Icon from 'frontend/components/Icon'

import Color from 'frontend/utils/color'
import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'
import CopyOneOfStringButton from './../../shared/CopyOneOfStringButton'

import { GET_PROVIDER_ORGANIZATIONS } from 'frontend/api/queries'

import CreateProviderAccountForm from './CreateProviderAccountForm'
import EditProviderAccountForm from './EditProviderAccountForm'
import getProvidersAccountsColumns from './getProvidersAccountsColumns'

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const TitleSection = styled.section({
  display: 'flex',
  justifyContent: 'space-between',
  padding: Spacing.S7,
  alignItems: 'center',
})

const ButtonSection = styled.section({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: `0 ${Spacing.S7}`,
  alignItems: 'center',
})

const PAGE_TITLE = 'PROVIDER ACCOUNTS'

const getProvidersColumnsInjectables = ({ setSelectedRowData }) => {
  const onRowClick = ({ original }) => {
    setSelectedRowData(original)
  }
  return {
    onRowClick,
  }
}

const getProvidersAccountsInjectedColumns = (setSelectedRowData) => {
  const providersAccountsTableColumnsDependencies = { setSelectedRowData }
  const providersAccountsTableColumnsInjectables = getProvidersColumnsInjectables(
    providersAccountsTableColumnsDependencies
  )
  const providersAccountsColumns = getProvidersAccountsColumns(
    providersAccountsTableColumnsInjectables
  )
  return providersAccountsColumns
}

const ProviderAccounts = () => {
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const { data, loading } = useQuery(GET_PROVIDER_ORGANIZATIONS)

  if (loading) return <Spinner />

  const providersData = data.providerOrganizations
  const providersAccountsColumns = getProvidersAccountsInjectedColumns(
    setSelectedRowData
  )

  const exportData = providersData.map(
    ({
      slug,
      organization,
      organizationTiny,
      providerCancerCenter,
      state,
      city,
      oncologistsCount,
      sitesCount,
      groupPracticePacId,
      aliases,
    }) => ({
      ['Slug']: slug,
      ['Organization']: organization,
      ['Short Name']: organizationTiny,
      ['Provider Cancer Center']: providerCancerCenter,
      ['State']: state,
      ['City']: city,
      ['Oncologists #']: oncologistsCount,
      ['Sites #']: sitesCount,
      ['Group Practice PAC ID']: groupPracticePacId,
      ['Aliases']: aliases,
    })
  )

  const closeHandler = () => {
    setSelectedRowData(null)
  }

  return (
    <Container>
      <TitleSection>
        <Header header={PAGE_TITLE} headerStyle={{ ...FontSpace.FS5 }} />

        <ButtonSection>
          <Button
            onClick={() => setIsCreateModalOpen(true)}
            style={{
              padding: `${Spacing.S2} ${Spacing.S3}`,
              margin: `${Spacing.S4} 0`,
            }}
          >
            <Icon
              iconName="add"
              color1={Color.WHITE}
              width={16}
              style={{ marginRight: 8 }}
            />{' '}
            Create Provider Account
          </Button>
          <CopyOneOfStringButton
            queryDoc={GET_PROVIDER_ORGANIZATIONS}
            dataKey="providerOrganizations"
            datumKey="slug"
            buttonStyle={{ marginRight: 15 }}
          />
          <ExportExcelButton
            filename={`ProvidersAccounts`}
            sheetName={'Provider Accounts'}
            isDisabled={!exportData.length}
            data={exportData}
          />
        </ButtonSection>
      </TitleSection>

      {isCreateModalOpen && (
        <CreateProviderAccountForm
          closeHandler={() => setIsCreateModalOpen(false)}
        />
      )}
      {selectedRowData && (
        <EditProviderAccountForm
          selectedProviderOrganizationData={selectedRowData}
          closeHandler={closeHandler}
        />
      )}

      <DataTable data={providersData} columns={providersAccountsColumns} />
    </Container>
  )
}

export default ProviderAccounts
