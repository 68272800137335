const INVALID_ACCESSORS = new Set(['delete'])

export const getColConfig = (colConfig: any) => colConfig.reduce(
  (acc: { title: string, key: string }[], col: any) => {
    const { Header, accessor } = col

    if (!INVALID_ACCESSORS.has(accessor)) {
      acc.push({
        title: Header,
        key: accessor,
      })
    }

    return acc
  }, []
)
