import React from 'react'
import _ from 'lodash'
import IndicationsPanel from './IndicationsPanel'

const IndicationsPanelContainer = ({
  selectedIndicationId,
  selectIndication,
  enabledTreatmentPlans,
  disabledTreatmentPlans,
  setStagedTreatmentPlans,
}) => {
  const enableIndication = (indication) => {
    selectIndication(indication)
    const newIndication = [{ ...indication, regimens: [] }]
    const updatedIndication = _.concat(enabledTreatmentPlans, newIndication)
    setStagedTreatmentPlans(updatedIndication)
  }

  const disableIndication = (indication) => {
    const copyEnabled = _.cloneDeep(enabledTreatmentPlans)
    const targetIdx = copyEnabled.findIndex(({ _id }) => _id === indication._id)
    copyEnabled.splice(targetIdx, 1)
    setStagedTreatmentPlans(copyEnabled)
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const copyEnabled = _.cloneDeep(enabledTreatmentPlans)
    const [removedItem] = copyEnabled.splice(oldIndex, 1)
    copyEnabled.splice(newIndex, 0, removedItem)
    setStagedTreatmentPlans(copyEnabled)
  }

  return (
    <IndicationsPanel
      selectedIndicationId={selectedIndicationId}
      selectIndication={selectIndication}
      enabledTreatmentPlans={enabledTreatmentPlans}
      disabledTreatmentPlans={disabledTreatmentPlans}
      enableIndication={enableIndication}
      disableIndication={disableIndication}
      onSortEnd={onSortEnd}
    />
  )
}

export default IndicationsPanelContainer
