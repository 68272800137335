import React from 'react'
import { transparentize } from 'polished'

import Color from 'frontend/utils/color'
import PolicyInfo from './PolicyInfo'

const PolicyContent = ({ data, policyOptions, input, setInput }) => {
  return (
    <div style={{ width: '25%', overflow: 'auto' }}>
      <div
        style={{ borderTop: `1px solid ${transparentize(0.9, Color.BLACK)}` }}
      >
        <PolicyInfo
          data={data}
          policyOptions={policyOptions}
          input={input}
          setInput={setInput}
        />
      </div>
    </div>
  )
}

export default PolicyContent
