import React from 'react'
import styled from '@emotion/styled'
import { Button } from '@pulse-analytics/pulse-design-system'

import { Dialog, Header, Spinner } from 'frontend/components'

import { useRewriteSearchString } from 'frontend/hooks'

import Color from 'frontend/utils/color'
import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'

const ActionBar = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: `${Spacing.S5} ${Spacing.S7}`,
})

const contentWrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const contentStyle = {
  height: 'auto',
  width: 450,
}

const DeleteDialog = ({
  isDeleting,
  isLoading,
  entityDescription = '',
  handleDelete,
  label,
  searchStringParams,
}) => {
  const rewriteSearchString = useRewriteSearchString()

  const handleCancel = () => {
    const deleteSearchArgs = searchStringParams.reduce((acc, param) => {
      acc[param] = undefined
      return acc
    }, {})
    rewriteSearchString(deleteSearchArgs)
  }

  const submitLabel = isLoading || isDeleting ? <Spinner /> : label

  // TODO: further componentize Header, Body, & Footer
  return (
    <Dialog
      contentWrapperStyle={contentWrapperStyle}
      contentStyle={contentStyle}
    >
      <Header
        header={label}
        style={{ padding: Spacing.S7 }}
        headerStyle={{ color: Color.RED }}
      />
      <div
        style={{
          background: Color.GRAY_LIGHT,
          height: '60vh',
          overflowY: 'scroll',
        }}
      >
        <div style={{ padding: 36, textAlign: 'center', ...FontSpace.FS3 }}>
          {getDescription(entityDescription, isLoading)}
        </div>
      </div>
      <ActionBar>
        <Button
          color={Color.GRAY_DARK}
          onClick={handleCancel}
          style={{ marginRight: Spacing.S5, padding: '7px 8px' }}
          type={'secondary'}
        >
          Cancel
        </Button>
        <Button
          color={Color.RED}
          onClick={() => handleDelete(handleCancel)}
          style={{ padding: '7px 8px' }}
        >
          {submitLabel}
        </Button>
      </ActionBar>
    </Dialog>
  )
}

export default DeleteDialog

const getDescription = (entityDescription, isLoading) =>
  isLoading ? (
    <Spinner />
  ) : (
    <>
      <p>Are you sure you want to delete {entityDescription}?</p>
      <p style={{ fontWeight: 700, marginTop: 12 }}>THIS CANNOT BE UNDONE</p>
    </>
  )
