import { gql } from '@apollo/client'

export const UPDATE_PATHWAYS_INFLUENCER = gql`
  mutation UpdatePathwaysInfluencer($input: UpdatePathwaysInfluencerInput!) {
    updatePathwaysInfluencer(input: $input) {
      id
      person
      pathways_organization
      indication_permissions
      pathways_influencer_types
      tumor_type_specialty
      internal_tdg_note
      internal_pathways_management_types
      value_chair_indications
      position
      priority
      alert_date
      alert_type
      alert_description
      is_excluded
      exclusion_reason
      start_date
      end_date
      start_quarter
      end_quarter
    }
  }
`
