import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import PushToStagingButton from './PushToStagingButton'

const Container = () => {
  const FLAGS = useFlags()

  if (!FLAGS.shouldShowProviderKdmPushToStagingButton) return null

  return <PushToStagingButton />
}

export default Container
