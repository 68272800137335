import InfluencerConnectionsWidget from './InfluencerConnectionsWidget'
import PathwaysAccountHistoryList from '../PathwaysAccountHistoryList'

const WIDGETS = [
  {
    _id: 'RELATIONAL_pathwaysInfluencerConnectionsWidget',
    label: 'Influencer Connections',
    Component: InfluencerConnectionsWidget,
  },
  {
    _id: 'RELATIONAL_historyWidget',
    label: 'History',
    Component: PathwaysAccountHistoryList,
  },
]

export default WIDGETS
