import MarketBasketPanelHeader from './MarketBasketPanelHeader'
import CategoryPanelHeader from './CategoryPanelHeader'

export const PANEL_HEIGHT = 'calc(100vh - 110px)'

const MARKET_BASKET_PANEL_CONFIG = [
  {
    searchParamConfig: {
      searchParam: 'marketBasketId',
      searchParamKey: 'id',
    },
    listWrapperStyle: {
      height: PANEL_HEIGHT,
      minHeight: PANEL_HEIGHT,
      maxHeight: PANEL_HEIGHT,
    },
    listHeaderConfig: {
      ListHeader: MarketBasketPanelHeader,
    },
  },
  {
    searchParamConfig: {
      searchParam: 'categoryId',
      searchParamKey: 'id',
    },
    listWrapperStyle: {
      height: PANEL_HEIGHT,
      minHeight: PANEL_HEIGHT,
      maxHeight: PANEL_HEIGHT,
    },
    listHeaderConfig: {
      ListHeader: CategoryPanelHeader,
    },
  },
]

export default MARKET_BASKET_PANEL_CONFIG
