import React, { MutableRefObject } from 'react'

import { Form } from 'frontend/components'
import { INPUT_TYPES } from 'frontend/components/Form/constants/input-type-to-component-map'

const FORM_CONFIG = [
  [
    {
      label: 'Email',
      accessor: 'email',
      inputType: INPUT_TYPES.TEXT,
      isRequired: true,
    },
    {
      label: 'Username',
      accessor: 'username',
      inputType: INPUT_TYPES.TEXT,
      isRequired: true,
    },
    {
      label: 'Password',
      accessor: 'password',
      inputType: INPUT_TYPES.TEXT,
      isRequired: true,
    },
    {
      label: 'Name',
      accessor: 'name',
      inputType: INPUT_TYPES.TEXT,
      isRequired: false,
    },
  ],
]

const CreateForm = ({ inputRef }: { inputRef: MutableRefObject<any> }) => {
  const setInputRef = ({ name, value }: any) => {
    inputRef.current[name] = value
  }

  return (
    <Form
      datum={inputRef.current}
      formConfig={FORM_CONFIG}
      setFormState={setInputRef}
    />
  )
}

export default CreateForm
