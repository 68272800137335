import React from 'react'

import { TextInput } from 'frontend/components'
import { useQueryString } from 'frontend/hooks'

import { Label, Wrapper } from './shared'

const Type = () => {
  const { nodeType }: any = useQueryString()

  return (
    <Wrapper>
      <Label>
        Type
      </Label>
      <TextInput
        isDisabled
        value={nodeType}
      />
    </Wrapper>
  )
}

export default Type
