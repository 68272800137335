import React from 'react'

import { TextInput } from 'frontend/components'

import { Label, Wrapper } from './shared'

interface Props {
  onChange: any
}

const LABEL_TEXT = 'First Name'
const FIELD_NAME = 'firstName'

export const FirstName = ({ onChange }: Props) => {
  const handleChange = ({ target: { value } }: any) => {
    onChange({ name: FIELD_NAME, value })
  }

  return (
    <Wrapper>
      <Label required={true}>
        {LABEL_TEXT} <span className="required">(required)</span>
      </Label>
      <TextInput onChange={handleChange} />
    </Wrapper>
  )
}
