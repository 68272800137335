import React from 'react'

import CreateModal from './CreateModal'
import { URL_PARAM_KEY } from './utils/url-param-key'
import { useShouldShowModal } from 'frontend/hooks/'
import { useFormConfig, useHandlers, useModalRef } from './utils'

const CreateModalContainer = ({ setErrorMessage }: any) => {
  const formConfig = useFormConfig()
  const { inputRef, setInputRef, buttonRef } = useModalRef()
  const { submit, isSubmitting, cancel } = useHandlers({ setErrorMessage, inputRef })

  const shouldShowModal = useShouldShowModal()
  if (!shouldShowModal(URL_PARAM_KEY)) return null

  return (
    <CreateModal
      formConfig={formConfig}
      submit={submit}
      isSubmitting={isSubmitting}
      cancel={cancel}
      setErrorMessage={setErrorMessage}
      inputRef={inputRef}
      setInputRef={setInputRef}
      buttonRef={buttonRef}
    />
  )
}

export default CreateModalContainer
