import React, { useEffect, useRef } from 'react'
import WebViewer from '@pdftron/webviewer'

interface Props {
  comparisonUrl: string
  originalUrl: string
}

const AMS_LICENSE_KEY =
  'The Dedham Group  LLC:ENTERP:Polaris Policy Link Manager::B+:AMS(20250912):92A574620437F60A8360B13AC9A2537820610FEB99186AF81C627551547C5837128A31F5C7'

const Comparison = ({
  comparisonUrl,
  originalUrl
}: Props) => {
  const viewer = useRef<any>(null)

  useEffect(() => {
    const urlOne = originalUrl
    const urlTwo = comparisonUrl

    WebViewer(
      {
        fullAPI: true,
        path: '/webviewer/lib',
      },
      viewer.current
    ).then(async (instance) => {
      try {
        const { documentViewer, PDFNet } = instance.Core
        await PDFNet.initialize(AMS_LICENSE_KEY)
        const newDoc = await PDFNet.PDFDoc.create()
        await newDoc.lock()
        const doc1 = await PDFNet.PDFDoc.createFromURL(urlOne)
        const doc2 = await PDFNet.PDFDoc.createFromURL(urlTwo)
        await newDoc.appendTextDiffDoc(doc1, doc2)
        await newDoc.unlock()

        instance.UI.loadDocument(newDoc)

        documentViewer.addEventListener('documentLoaded', () => {
          instance.UI.setLayoutMode(instance.UI.LayoutMode.FacingContinuous)
        })
      } catch (e) {
        console.log(e)
      }
    })
  }, [])

  return <div style={{ height: '100%' }} className="webviewer" ref={viewer} />
}

export default Comparison
