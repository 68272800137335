import { gql } from '@apollo/client'

import { schema } from './schemas'

export const GET_PAYER_POLICY_DOCUMENTS = gql`
  query GetPayerPolicyDocuments($input: PayerPolicyDocumentsInput) {
    payerPolicyDocuments(input: $input) {
      ${schema}
    }
  }
`
