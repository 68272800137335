import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'
import { Type } from '../../../../types'
import ButtonGroup from './ButtonGroup'

interface Props {
  isSelected: boolean
  type: Type
  data: any
  onClick?: () => void
}

interface WrapperProps {
  isSelected: boolean
}

const Wrapper = styled.div(
  {
    alignItems: 'center',
    borderBottom: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
    borderRadius: 8,
    cursor: 'pointer',
    display: 'flex',
    fontSize: 12,
    fontWeight: 500,
    height: '47px',
    letterSpacing: '-0.2px',
    lineHeight: '18px',
    justifyContent: 'space-between',
    padding: '8px 12px',
    margin: '8px 12px 0px',
  },
  ({ isSelected }: WrapperProps) => ({
    backgroundColor: isSelected
      ? transparentize(0.9, Colors.PRIMARY)
      : undefined,
    color: isSelected ? Colors.PRIMARY : Colors.BLACK,
    ':hover': isSelected
      ? undefined
      : { backgroundColor: Colors.COOL_GRAY_100 },
  })
)

const NodeListItem = ({ isSelected, type, data, onClick, ...props }: Props) => {
  return (
    <Wrapper isSelected={isSelected} onClick={() => onClick && onClick()}>
      <span>{data.node.name}</span>
      <ButtonGroup type={type} data={data} {...props} />
    </Wrapper>
  )
}

export default NodeListItem
