import React from 'react'

import { LabelCheckbox } from 'frontend/components'
import styled from '@emotion/styled'
import StatusSelector from './StatusSelector'
import { FlaggedButton } from './FlaggedButton'

interface Props {
  documentProduct: any
  policyState: any
  setPolicyState: any
}

const ProductName = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.2px;
  text-align: left;
`

export const Product = ({
  documentProduct,
  policyState,
  setPolicyState
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '46px',
        borderBottom: '.5px solid #E2EAF4',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {/* <LabelCheckbox
          label={product.brand_name}
          onChange={() => console.log('text')}
          styles={{ paddingBottom: '0px', paddingRight: '8px' }}
        /> */}
        <ProductName>
          {documentProduct.product.brand_name}
        </ProductName>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <StatusSelector
          documentProduct={documentProduct}
          policyState={policyState}
          setPolicyState={setPolicyState}
        />
        <FlaggedButton
          documentProduct={documentProduct}
          policyState={policyState}
          setPolicyState={setPolicyState}
        />
      </div>
    </div>
  )
}
