import readTag from '../../useRead/gql-tag'

const update = (
  cache: any,
  { data: { createPolarisUser } }: { data?: any} 
) => {
  const { polarisUsers } = cache.readQuery({
    query: readTag,
  })

  cache.writeQuery({
    query: readTag,
    data: {
      polarisUsers: [createPolarisUser, ...polarisUsers],
    },
  })
}

export default update
