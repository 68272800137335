interface Data {
  data: Array<any>
  newDatum: any
  accessor?: string
}

const getIndexByBinarySearch = ({ data, newDatum, accessor }: Data) => {
  let left = 0
  let index = -1
  let right = data.length - 1
  while (left <= right) {
    let mid = Math.floor((left + right) / 2)
    let datum
    let newAccessedDatum
    if (accessor) {
      datum = data[mid][accessor]
      newAccessedDatum = newDatum[accessor]
    } else {
      datum = data[mid]
      newAccessedDatum = newDatum
    }
    if (datum.toLowerCase() >= newAccessedDatum.toLowerCase()) {
      index = mid
      right = mid - 1
    } else {
      left = mid + 1
    }
  }
  return index
}

export default getIndexByBinarySearch
