import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const DELETE_TEAM = gql`
  mutation DeleteTeam($input: DeleteTeamInput_EMBER!) {
    deleteTeam_EMBER(input: $input) {
      ${schemaV0}
    }
  }
`
