import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors, Spacing } from '../utils/pulseStyles'
import Color from 'frontend/utils/color'

import { REPORTS_CONFIG } from './sidebar-link-utils'
import NavigationLinks from '../components/NavigationLinks'

const SidebarContainer = styled.div({
  width: Spacing.TOOL_SIDEBAR,
  minWidth: Spacing.TOOL_SIDEBAR,
  backgroundColor: Colors.TOOL_SIDEBAR,
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
})

const ReportsHeader = styled.div({
  alignItems: 'center',
  background: transparentize(0.3, Colors.BLACK),
  color: Color.REPORTS,
  display: 'flex',
  fontSize: 12,
  fontWeight: 700,
  padding: `${Spacing.MEDIUM} ${Spacing.EXTRA_LARGE}`,
  textTransform: 'uppercase',
})

const ReportsLogo = styled.img({
  display: 'inline',
  marginRight: Spacing.SMALL,
})

const REPORTS_LOGO_LINK =
  'https://res.cloudinary.com/pulsedatatools/image/upload/v1644948774/polaris/icons/report-color.svg'

const Sidebar = () => {
  return (
    <SidebarContainer>
      <ReportsHeader>
        <ReportsLogo src={REPORTS_LOGO_LINK} />
        Reports
      </ReportsHeader>
      <div style={{ padding: `${Spacing.NORMAL} 0` }}>
        <NavigationLinks
          sectionHeader="Export Data"
          linkConfig={REPORTS_CONFIG}
        />
      </div>
    </SidebarContainer>
  )
}

export default Sidebar
