import React from 'react'

import { useQueryString } from 'frontend/hooks'

import { ParentType, Type } from '../../../types'
import InnerContainer from './InnerContainer'

interface Props {
  type: Type
  parentType?: ParentType
}

const OuterContainer = ({
  parentType,
  ...props
}: Props) => {
  const queryString = useQueryString()

  if (parentType && !queryString[parentType]) return null

  return (
    <InnerContainer
      parentType={parentType}
      {...props}
    />
  )
}

export default OuterContainer
