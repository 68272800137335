import React, { useState } from 'react'

import { Label, Wrapper } from '../shared'
import { Checkbox } from './Checkbox'
import { Input } from './Input'
import { ResetButton } from './ResetButton'

interface Props {
  fieldState: any
  onChange: any
}

const FIELD_NAME = 'isManualPassword'
const LABEL_TEXT = 'Password'

export const Password = ({ fieldState, onChange }: Props) => {
  const { id: userId } = fieldState
  const [isManualPassword, setIsManualPassword] = useState(
    fieldState[FIELD_NAME]
  )

  const toggleCheckbox = ({ target: { checked, name } }: any) => {
    if (!checked) onChange({ name: 'password', value: undefined })
    setIsManualPassword(checked)
    onChange({ name, value: checked })
  }

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <ResetButton userId={userId} />
      <Checkbox checked={isManualPassword} onChange={toggleCheckbox} />
      <Input
        value={fieldState['password']}
        isManualPassword={isManualPassword}
        onChange={onChange}
      />
    </Wrapper>
  )
}
