import React from 'react'
import { Tag } from '@pulse-analytics/pulse-design-system'
import { transparentize } from 'polished'

import Color from 'frontend/utils/color'

const LABEL = 'Flagged for Data Extraction'
const COLOR = Color.PRIMARY

const DataExtractionFlagTag = ({ isFlagged }) => {
  if (!isFlagged) return null

  return (
    <div style={{ marginLeft: 10 }}>
      <Tag
        color={COLOR}
        style={{
          border: 'none',
          background: transparentize(0.85, COLOR),
          textTransform: 'none',
        }}
      >
        {LABEL}
      </Tag>
    </div>
  )
}

export default DataExtractionFlagTag
