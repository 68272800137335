import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import _ from 'lodash'

import Spacing from 'frontend/utils/spacing'
import Input from 'frontend/components/Input'

import CreatableMultiSelect from '../../../../Orion/shared/CreatableMultiSelect'
import QuarterPicker from '../../../QuarterPicker'

import { formatDateMonthYearDash } from 'frontend/utils/formatDate'

import {
  FieldContainer,
  FormLabel,
  FieldWrapper,
  FormWrapper,
  FlexWrapper,
  RequiredLabel,
} from './orgInfluencerConnectionComponents/ConnectionPanel/styledComponents'

import { ALERT_TYPES } from './alert-types'
import { usePathwaysInfluencerData } from 'frontend/hooks/usePathwaysInfluencersData'

const PATHWAYS_MGMT_TYPES = ['business', 'clinical', 'leadership']

const INFLUENCER_TYPES = [
  'steering_committee',
  'leadership',
  'content_manager',
  'payer_partner_leadership',
  'uson_p&t_members',
  'uhc_p&t_members',
]

const PRIORITY_LEVELS = [null, 'high', 'medium', 'low']

const PathwaysInfluencersForm = ({
  refKey,
  connectionData,
  isNewConnectionBeingCreated,
  setConnectionData,
  setWhetherUnsavedChanges,
}) => {
  const {
    pathwaysOrganizations,
    people,
    indications,
  } = usePathwaysInfluencerData()

  const { data: indicationsData, loading: isIndicationsLoading } = indications
  const {
    data: pathwaysData,
    loading: isPathwaysLoading,
  } = pathwaysOrganizations
  const { data: peopleData, loading: isPeopleLoading } = people

  if (isIndicationsLoading || isPathwaysLoading || isPeopleLoading)
    return 'Loading...'

  const globalPeople = Object.values(peopleData)
  const globalPeopleById = _.mapValues(
    _.keyBy(globalPeople, 'id'),
    ({ first_name, last_name }) => `${first_name} ${last_name}`
  )

  const globalIndications = Object.values(indicationsData)
  const globalIndicationsById = _.mapValues(
    _.keyBy(globalIndications, 'id'),
    'name'
  )

  const globalPathways = Object.values(pathwaysData)
  const globalPathwaysById = _.mapValues(_.keyBy(globalPathways, 'id'), 'name')

  if (!connectionData.person) {
    connectionData.person = connectionData.personId
  }

  const {
    person,
    pathways_organization,
    pathways_influencer_types,
    indication_permissions,
    tumor_type_specialty,
    position,
    priority,
    start_date,
    start_quarter,
    end_date,
    end_quarter,
    value_chair_indications,
    internal_tdg_note,
    internal_pathways_management_types,
    is_excluded,
    exclusion_reason,
    alert_date,
    alert_type,
    alert_description,
  } = connectionData

  const pathwaysInfluencerConnectionCopy = _.cloneDeep(connectionData || {})

  const updateOrgData = (newData) => {
    setConnectionData(newData)
    setWhetherUnsavedChanges(true)
  }

  const selectPathwaysId = (id) => {
    updateOrgData(_.merge({}, connectionData, { pathways_organization: id }))
  }

  const selectPersonId = (id) => {
    updateOrgData(_.merge({}, connectionData, { person: id }))
  }

  const handleTopLevelTextChange = ({ name, value }) => {
    updateOrgData(_.merge({}, connectionData, { [name]: value }))
  }

  const changeInternalPathwaysManagementTypes = (arr) => {
    pathwaysInfluencerConnectionCopy.internal_pathways_management_types = (
      arr || []
    ).map(({ value }) => value)
    updateOrgData(pathwaysInfluencerConnectionCopy)
  }

  const changePathwaysInfluencerTypes = (arr) => {
    pathwaysInfluencerConnectionCopy.pathways_influencer_types = (
      arr || []
    ).map(({ value }) => value)
    updateOrgData(pathwaysInfluencerConnectionCopy)
  }

  const changeInternalValueChairsIndications = (arr) => {
    pathwaysInfluencerConnectionCopy.value_chair_indications = (arr || []).map(
      ({ value }) => value
    )
    updateOrgData(pathwaysInfluencerConnectionCopy)
  }

  const handleIndicationsChange = (arr) => {
    pathwaysInfluencerConnectionCopy.indication_permissions = (arr || []).map(
      ({ value }) => value
    )
    updateOrgData(pathwaysInfluencerConnectionCopy)
  }

  const handleTimestampChange = (data) => {
    const { name, value } = data
    pathwaysInfluencerConnectionCopy[name] = value

    updateOrgData(pathwaysInfluencerConnectionCopy)
  }

  const clearTimestamp = (name) => {
    handleTimestampChange({ name, value: null })
  }

  const handleAlertDate = ({ value: newAlertDate }) => {
    pathwaysInfluencerConnectionCopy.alert_date = newAlertDate
    updateOrgData(pathwaysInfluencerConnectionCopy)
  }

  const handleAlertType = ({ value: newAlertType }) => {
    pathwaysInfluencerConnectionCopy.alert_type = newAlertType
    updateOrgData(pathwaysInfluencerConnectionCopy)
  }

  // ! Enabling both for now because short on time to debug
  const shouldDisablePathwaysSelect =
    !isNewConnectionBeingCreated || refKey !== 'pathwaysId'
  const shouldDisableInfluencerSelect =
    !isNewConnectionBeingCreated || refKey === 'pathwaysId'

  return (
    <FormWrapper>
      <FieldContainer>
        <FieldWrapper>
          <FormLabel>
            Connected Pathways Organization
            {!shouldDisablePathwaysSelect ? (
              <RequiredLabel> (required)</RequiredLabel>
            ) : null}
          </FormLabel>
          <Select
            isDisabled={shouldDisablePathwaysSelect}
            value={{
              label: globalPathwaysById[pathways_organization],
              value: pathways_organization,
            }}
            options={globalPathways.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
            onChange={({ value }) => selectPathwaysId(value)}
          />
        </FieldWrapper>
        <FieldWrapper>
          <FormLabel>
            Connected Influencer
            {!shouldDisableInfluencerSelect ? (
              <RequiredLabel> (required)</RequiredLabel>
            ) : null}
          </FormLabel>
          <Select
            isDisabled={shouldDisableInfluencerSelect}
            value={{
              label: globalPeopleById[person],
              value: person,
            }}
            options={globalPeople.map(({ id, first_name, last_name }) => ({
              label: `${first_name} ${last_name}`,
              value: id,
            }))}
            onChange={({ value }) => selectPersonId(value)}
          />
        </FieldWrapper>
      </FieldContainer>

      <FieldContainer>
        <FieldWrapper>
          <FormLabel>
            Pathways Management Type (Internal TDG Only)
            <RequiredLabel> (required)</RequiredLabel>
          </FormLabel>
          <Select
            isMulti
            value={(internal_pathways_management_types || []).map((type) => ({
              label: type,
              value: type,
            }))}
            options={PATHWAYS_MGMT_TYPES.map((type) => ({
              label: type,
              value: type,
            }))}
            onChange={changeInternalPathwaysManagementTypes}
          />
        </FieldWrapper>
        <FieldWrapper>
          <FormLabel>
            Pathways Influencer Type
            <RequiredLabel> (required)</RequiredLabel>
          </FormLabel>
          <Select
            isMulti
            value={(pathways_influencer_types || []).map((type) => ({
              label: type,
              value: type,
            }))}
            options={INFLUENCER_TYPES.map((type) => ({
              label: type,
              value: type,
            }))}
            onChange={changePathwaysInfluencerTypes}
          />
        </FieldWrapper>
        <FieldWrapper>
          <FormLabel>
            Pathways Position
            <RequiredLabel> (required)</RequiredLabel>
          </FormLabel>
          <Input
            type="text"
            value={position}
            name="position"
            onChange={({ value }) =>
              handleTopLevelTextChange({ name: 'position', value })
            }
          />
        </FieldWrapper>
      </FieldContainer>

      <FieldContainer>
        <FieldWrapper>
          <FormLabel>
            Indications (for permissions)
            <RequiredLabel> (required)</RequiredLabel>
          </FormLabel>
          <Select
            isMulti
            value={(indication_permissions || []).map((id) => ({
              label: globalIndicationsById[id],
              value: id,
            }))}
            options={(globalIndications || []).map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
            onChange={handleIndicationsChange}
          />
        </FieldWrapper>
        <FieldWrapper>
          <FormLabel>
            ClinicalPath / Value Chairs Indication(s) (Internal TDG Only)
          </FormLabel>
          <CreatableMultiSelect
            value={(value_chair_indications || []).map((str) => ({
              label: str,
              value: str,
            }))}
            handleChange={changeInternalValueChairsIndications}
          />
        </FieldWrapper>
      </FieldContainer>

      <FieldContainer>
        <FlexWrapper>
          <FieldWrapper style={{ width: '50%' }}>
            <FormLabel>Start Date</FormLabel>
            <button
              style={{ color: 'blue', marginLeft: 6, cursor: 'pointer' }}
              onClick={() => clearTimestamp('start_date')}
            >
              clear
            </button>
            <Input
              type="date"
              value={getDateInputValue(start_date)}
              name="start_date"
              onChange={handleTimestampChange}
            />
          </FieldWrapper>
          <FieldWrapper style={{ width: '50%' }}>
            <FormLabel>Start Quarter</FormLabel>
            <button
              style={{ color: 'blue', marginLeft: 6, cursor: 'pointer' }}
              onClick={() => clearTimestamp('start_quarter')}
            >
              clear
            </button>
            <QuarterPicker
              value={start_quarter}
              name="start_quarter"
              onChange={handleTimestampChange}
            />
          </FieldWrapper>
        </FlexWrapper>
        <FlexWrapper>
          <FieldWrapper style={{ width: '50%' }}>
            <FormLabel>End Date (Outdated)</FormLabel>
            <button
              style={{ color: 'blue', marginLeft: 6, cursor: 'pointer' }}
              onClick={() => clearTimestamp('end_date')}
            >
              clear
            </button>
            <Input
              type="date"
              value={getDateInputValue(end_date)}
              name="end_date"
              onChange={handleTimestampChange}
            />
          </FieldWrapper>
          <FieldWrapper style={{ width: '50%' }}>
            <FormLabel>End Quarter</FormLabel>
            <button
              style={{ color: 'blue', marginLeft: 6, cursor: 'pointer' }}
              onClick={() => clearTimestamp('end_quarter')}
            >
              clear
            </button>
            <QuarterPicker
              value={end_quarter}
              name="end_quarter"
              onChange={handleTimestampChange}
            />
          </FieldWrapper>
        </FlexWrapper>
        <FlexWrapper>
          <FieldWrapper style={{ width: '50%' }}>
            <FormLabel>Alert Date</FormLabel>
            <button
              style={{ color: 'blue', marginLeft: 6, cursor: 'pointer' }}
              onClick={() => handleAlertDate({ value: null })}
            >
              clear
            </button>
            <Input
              name="alert_date" // just to avoid prop warning, not used
              type="date"
              value={getDateInputValue(alert_date)}
              onChange={handleAlertDate}
            />
          </FieldWrapper>
          <FieldWrapper style={{ width: '50%' }}>
            <FormLabel>Alert Type</FormLabel>
            <Select
              isDisabled // ! for now alertType can only be 'Influencer'
              value={{ label: alert_type, value: alert_type }}
              options={ALERT_TYPES.map((type) => ({
                label: type,
                value: type,
              }))}
              onChange={handleAlertType}
            />
          </FieldWrapper>
        </FlexWrapper>
        <FieldWrapper>
          <FormLabel>Alert Description</FormLabel>
          <Input
            type="text"
            value={alert_description}
            name="description"
            onChange={({ value }) =>
              handleTopLevelTextChange({ name: 'alert_description', value })
            }
          />
        </FieldWrapper>
      </FieldContainer>

      <FieldContainer>
        <FieldWrapper>
          <FormLabel>Priority</FormLabel>
          <Select
            options={PRIORITY_LEVELS.map((level) => ({
              value: level,
              label: level,
            }))}
            value={{ value: priority, label: priority }}
            onChange={({ value }) =>
              handleTopLevelTextChange({ name: 'priority', value })
            }
          />
        </FieldWrapper>
        <FieldWrapper>
          <FlexWrapper>
            <FormLabel>
              <Input
                style={{ width: 'auto', marginRight: Spacing.S3 }}
                type="checkbox"
                name="isExcluded"
                value={Boolean(is_excluded)}
                onChange={({ value }) =>
                  handleTopLevelTextChange({ name: 'is_excluded', value })
                }
              />
              Exclude From Tool
            </FormLabel>
          </FlexWrapper>
        </FieldWrapper>
        <FieldWrapper>
          <FormLabel>Exclude Reason</FormLabel>
          <Input
            type="text"
            value={exclusion_reason}
            name="reason"
            onChange={({ value }) =>
              handleTopLevelTextChange({ name: 'exclusion_reason', value })
            }
          />
        </FieldWrapper>
        <FieldWrapper>
          <FormLabel>
            Internal TDG Notes [Format - YYQQ (MM/DD:____);]
          </FormLabel>
          <Input
            name="internal_tdg_note"
            type="text"
            value={internal_tdg_note}
            onChange={({ value }) =>
              handleTopLevelTextChange({ name: 'internal_tdg_note', value })
            }
          />
        </FieldWrapper>
        <FieldWrapper>
          <FormLabel>Tumor Type Specialty</FormLabel>
          <Input
            type="text"
            value={tumor_type_specialty}
            name="tumor_type_specialty"
            onChange={({ value }) =>
              handleTopLevelTextChange({ name: 'tumor_type_specialty', value })
            }
          />
        </FieldWrapper>
      </FieldContainer>
    </FormWrapper>
  )
}

PathwaysInfluencersForm.propTypes = {
  refKey: PropTypes.string.isRequired,
  orgData: PropTypes.object.isRequired,
  isNewConnectionBeingCreated: PropTypes.bool.isRequired,
  setConnectionData: PropTypes.func.isRequired,
}

export default PathwaysInfluencersForm

const getDateInputValue = (date) =>
  date ? formatDateMonthYearDash(date) : date
