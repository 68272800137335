import React from 'react'

import { DeleteDialog } from 'frontend/components'

import useProviderKdmData from './useProviderKdmData'

const LABEL = 'Delete Provider Key Decision Maker'

const SEARCH_STRING_PARAMS = ['deleteProviderKdm', 'providerKdmId']

const DeleteDialogProviderKdm = () => {
  const {
    providerKeyDecisionMakers: {
      data: [providerKdm],
      delete: destroy,
      isDeleting,
      isLoading,
    },
  } = useProviderKdmData({ searchStringParams: SEARCH_STRING_PARAMS })

  const handleDelete = () => {
    const input = { id: providerKdm.id }
    destroy({ variables: { input } })
  }

  const entityDescription = getEntityDescription(providerKdm)

  return (
    <DeleteDialog
      isDeleting={isDeleting}
      isLoading={isLoading}
      entityDescription={entityDescription}
      handleDelete={handleDelete}
      label={LABEL}
      searchStringParams={SEARCH_STRING_PARAMS}
    />
  )
}

export default DeleteDialogProviderKdm

const getEntityDescription = (data) => {
  if (!data) return ''

  const {
    person: { first_name, middle_name, last_name },
    provider_organization,
  } = data

  let personName = `${first_name}`
  if (middle_name) personName += ` ${middle_name}`
  if (last_name) personName += ` ${last_name}`

  const entityDescription = `${personName} - ${provider_organization.name}`

  return entityDescription
}
