import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import _ from 'lodash'

import DataTable from 'frontend/components/DataTable'
import { CONFIG_TABLE_WIDTH } from 'frontend/components/Table/tableWidths'

import HistoricalPositionModal from './HistoricalPositionModal'

import usePathwaysTreatmentPlanData from './utils/usePathwaysTreatmentPlanData'
import usePositioningOptionsData from './utils/usePositioningOptionsData'

import {
  usePositioningData,
  getPositioningTableColumnsInjectables,
  getPositioningTableColumns,
  getExportConfig,
} from './utils'

const Container = styled.div`
  width: ${CONFIG_TABLE_WIDTH};
`

const PAGE_TITLE = 'Latest External Pathways Positions'

const Positioning = () => {
  const history = useHistory()
  const location = useLocation()

  const [showModal, setShowModal] = useState(false)
  const positioningData = usePositioningData()
  const pathwaysTreatmentPlanData = usePathwaysTreatmentPlanData()
  const optionsData = usePositioningOptionsData()

  const loading = positioningData.loading || pathwaysTreatmentPlanData.loading

  const queryStringParams = queryString.parse(location.search)

  useEffect(() => {
    const isValidQueryStringParams =
      queryStringParams.indicationId &&
      queryStringParams.regimenId &&
      queryStringParams.pathwaysOrganizationId &&
      (queryStringParams.populationsIds ||
        queryStringParams.populationsIds === '')
    if (isValidQueryStringParams) setShowModal(true)
  }, [])

  const openHistoricalPositionModal = (row) => {
    const { pathways_organization, indication, regimen, populations } = row
    const search = queryString.stringify({
      pathwaysOrganizationId: pathways_organization,
      indicationId: indication,
      regimenId: regimen,
      populationsIds: _.isEmpty(populations) ? '' : populations,
    })

    history.push({ search })
    setShowModal(true)
  }

  const closeHandler = () => {
    history.push({ search: '' })
    setShowModal(false)
  }

  const positioningTableColumnsInjectables = getPositioningTableColumnsInjectables(
    {
      positioningData,
      ...pathwaysTreatmentPlanData,
      openHistoricalPositionModal,
    }
  )
  const positioningTableColumns = getPositioningTableColumns({
    ...positioningTableColumnsInjectables,
    ...pathwaysTreatmentPlanData,
    ...optionsData,
  })

  const positioningTableData =
    positioningData.data?.pathwaysIndicationRegimenPositions ?? []

  const onCreateRowSubmitHandler =
    positioningTableColumnsInjectables.onCreateButtonClick

  const exportConfig = getExportConfig(positioningTableColumns)

  return (
    <Container>
      {showModal && (
        <HistoricalPositionModal
          showModal={showModal}
          closeHandler={closeHandler}
          onCreateRowSubmitHandler={onCreateRowSubmitHandler}
        />
      )}
      <DataTable
        hasCreate
        hasEdit
        hasExport
        data={positioningTableData}
        columns={positioningTableColumns}
        isLoading={loading}
        createConfig={{ createRowSubmitHandler: onCreateRowSubmitHandler }}
        editConfig={{ accessor: 'id' }}
        exportConfig={exportConfig}
      />
    </Container>
  )
}

export default Positioning
