import { useQuery } from '@apollo/client'

import {
  GET_VEGA_PATHWAYS_ORGANIZATIONS,
  GET_VEGA_INDICATIONS,
  GET_VEGA_REGIMENS,
  GET_VEGA_POPULATIONS,
  GET_VEGA_LINES_OF_THERAPY,
} from 'frontend/api/queries'

import { getSelectOptionsAndMaps } from './getPositioningTableColumnsInjectables/utils'

const usePathwaysTreatmentPlanData = () => {
  const pathwaysOrgsData = useQuery(GET_VEGA_PATHWAYS_ORGANIZATIONS)
  const indicationsData = useQuery(GET_VEGA_INDICATIONS)
  const regimensData = useQuery(GET_VEGA_REGIMENS)
  const populationsData = useQuery(GET_VEGA_POPULATIONS)
  const linesData = useQuery(GET_VEGA_LINES_OF_THERAPY)

  const selectMapAndOptionsDependencies = [
    pathwaysOrgsData,
    indicationsData,
    regimensData,
    populationsData,
    linesData,
  ]

  const [
    {
      options: pathwaysOrgOptions,
      optionMap: pathwaysOrgOptionMap,
      labelMap: pathwaysOrgLabelMap,
    },
    {
      options: indicationOptions,
      optionMap: indicationOptionMap,
      labelMap: indicationLabelMap,
    },
    {
      options: regimenOptions,
      optionMap: regimenOptionMap,
      labelMap: regimenLabelMap,
    },
    {
      options: populationsOptions,
      optionMap: populationsOptionMap,
      labelMap: populationsLabelMap,
    },
    {
      options: linesOptions,
      optionMap: linesOptionMap,
      labelMap: linesLabelMap,
    },
  ] = selectMapAndOptionsDependencies.map((dependency) => {
    return getSelectOptionsAndMaps(dependency)
  })

  return {
    pathwaysOrgs: {
      data: pathwaysOrgsData.data,
      loading: pathwaysOrgsData.loading,
      options: pathwaysOrgOptions,
      optionMap: pathwaysOrgOptionMap,
      labelMap: pathwaysOrgLabelMap,
    },
    indications: {
      data: indicationsData.data,
      loading: indicationsData.loading,
      options: indicationOptions,
      optionMap: indicationOptionMap,
      labelMap: indicationLabelMap,
    },
    regimens: {
      data: regimensData.data,
      loading: regimensData.loading,
      options: regimenOptions,
      optionMap: regimenOptionMap,
      labelMap: regimenLabelMap,
    },
    populations: {
      data: populationsData.data,
      loading: populationsData.loading,
      options: populationsOptions,
      optionMap: populationsOptionMap,
      labelMap: populationsLabelMap,
    },
    lines: {
      data: linesData.data,
      loading: linesData.loading,
      options: linesOptions,
      optionMap: linesOptionMap,
      labelMap: linesLabelMap,
    },
    loading:
      pathwaysOrgsData.loading ||
      indicationsData.loading ||
      regimensData.loading ||
      populationsData.loading ||
      linesData.loading,
  }
}

export default usePathwaysTreatmentPlanData
