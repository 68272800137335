import React from 'react'
import { styled } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

const StyledSwitch = styled(Switch)({
  height: 22,
  padding: 4,
  width: 40,
  '& .MuiSwitch-switchBase': {
    color: Colors.COOL_GRAY_400,
    padding: 0,
    '&.Mui-checked': {
      color: Colors.PRIMARY,
      '& + .MuiSwitch-track': {
        backgroundColor: transparentize(0.3, Colors.PRIMARY),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    height: 20,
    width: 20,
  },
  '& .MuiSwitch-track': {
    backgroundColor: transparentize(0.7, Colors.BLACK),
  },
})

const SitemapSwitch = ({ type, data, sitemap, setSitemap }) => {
  const key = `${type}s`
  const checked = sitemap[key].has(data.id)
  const toggleSitemap = () => {
    setSitemap((sitemap) => {
      const nodes = sitemap[key]
      checked ? nodes.delete(data.id) : nodes.add(data.id)
      return { ...sitemap }
    })
  }

  return <StyledSwitch checked={checked} onChange={toggleSitemap} />
}

export default SitemapSwitch
