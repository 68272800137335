import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import _ from 'lodash'

import { GET_SOURCE_INDICATIONS } from 'frontend/api/queries'
import { NODE_TYPE_TO_PARENT_TYPE } from 'frontend/ClientManagement/Sitemap/constants'
import { Params } from 'frontend/ClientManagement/Sitemap/types'
import { useQueryString } from 'frontend/hooks'

import ActiveList from './ActiveList'
import InactiveList from './InactiveList'

interface Props {
  parentResources: any[]
  treatmentPlans: any[]
  setResources: Dispatch<SetStateAction<any>>
}

const RegimensListPanel = ({
  parentResources,
  treatmentPlans,
  setResources,
}: Props) => {
  const { team }: Params = useParams()

  const queryString: any = useQueryString()
  const { indication, node, nodeType }: { [key: string]: string } = queryString
  const parentType = NODE_TYPE_TO_PARENT_TYPE[nodeType]

  const { data: { indications } } = useQuery(GET_SOURCE_INDICATIONS)

  const regimenIdToLabel = useMemo(() => {
    return indications.reduce((
      acc: { [key: string]: string },
      { regimens }: any,
    ) => {
      for (const { _id, name } of regimens) {
        acc[_id] = name
      }
      return acc
    }, {})
  }, [indications])

  const regimens = treatmentPlans.find(
    ({ resource }) => resource === indication
  )?.children ?? []

  const enabledRegimensSet = regimens.reduce(
    (acc: Set<string>, { resource }: any) => {
      acc.add(resource)
      return acc
    }, new Set()
  )

  let inactiveRegimens
  if (parentType) {
    const parentIndication = parentResources.find(
      ({ resource: { entity_id } }: any) => entity_id === indication
    )
    inactiveRegimens = parentIndication.children.reduce((acc: any[], {
      id: teamParentResource,
      resource: {
        entity_id,
      },
    }: any) => {
      if (enabledRegimensSet.has(entity_id)) return acc

      const regimen = {
        team,
        [nodeType]: node,
        resource: entity_id,
        [`team_${parentType}_resource`]: teamParentResource,
      }
      acc.push({
        treatmentPlan: {
          team,
          [nodeType]: node,
          resource: parentIndication.resource.entity_id,
          [`team_${parentType}_resource`]: parentIndication.id,
          children: [regimen],
        },
        ...regimen
      })
      return acc
    }, [])
  } else {
    const parentIndication = indications.find(
      ({ _id }: any) => _id === indication
    )
    inactiveRegimens = parentIndication.regimens.reduce((acc: any[], { _id }: any) => {
      if (enabledRegimensSet.has(_id)) return acc

      const regimen = {
        team,
        [nodeType]: node,
        resource: _id,
      }
      acc.push({
        treatmentPlan: {
          team,
          [nodeType]: node,
          resource: parentIndication._id,
          children: [regimen],
        },
        ...regimen
      })
      return acc
    }, [])
  }

  return (
    <>
      <ActiveList
        regimenIdToLabel={regimenIdToLabel}
        regimens={regimens}
        setResources={setResources}
      />
      <InactiveList
        regimenIdToLabel={regimenIdToLabel}
        regimens={inactiveRegimens}
        setResources={setResources}
      />
    </>
  )
}

export default RegimensListPanel
