import React from 'react'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import { useClient } from 'frontend/entity-hooks/ember'
import { Colors } from 'frontend/utils/pulseStyles'

import { Params } from '../../types'

const Wrapper = styled.div({
  fontWeight: 500,
  fontSize: 12,
  marginTop: 8,
})

const ClientLabel = styled.span({
  color: Colors.COOL_GRAY_400,
  textTransform: 'uppercase',
})

const SubHeader = () => {
  const {
    client,
  }: Params = useParams()

  const { data, isLoading }: any = useClient({ queryInput: { id: client } })

  const text = isLoading ? null : data[0].name

  return (
    <Wrapper>
      <ClientLabel>Client: </ClientLabel>
      <span>{text}</span>
    </Wrapper>
  )
}

export default SubHeader
