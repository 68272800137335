import { gql } from '@apollo/client'
import { schemaV1 } from './schemas'

export const DELETE_PRODUCT_POLICY_LINK = gql`
  mutation DeleteProductPolicyLink($input: DeleteProductPolicyLinkInput!) {
    deleteProductPolicyLink(input: $input) {
      ${schemaV1}
    }
  }
`
