const getSelectedDashboardToolTitle = (selectedDashboardToolId: String, dashboardTools: any[]) => {
  const shouldNotSearchForDashboardTool = !selectedDashboardToolId || !dashboardTools
  if (shouldNotSearchForDashboardTool) return undefined

  const selectedDashboardToolObj = dashboardTools.find(
    ({ _id }: { _id: String }) => _id === selectedDashboardToolId
  )
  const selectedDashboardToolTitle = selectedDashboardToolObj.text.title ?? selectedDashboardToolObj.name

  return selectedDashboardToolTitle
}

export default getSelectedDashboardToolTitle
