import gql from 'graphql-tag'

export const GET_PAGINATED_PROVIDER_KEY_DECISION_MAKERS = gql`
  query getPaginatedProviderKeyDecisionMakers(
    $input: ProviderKeyDecisionMakersInput
  ) {
    paginatedProviderKeyDecisionMakers(input: $input) {
      ... on ProviderKeyDecisionMaker {
        id
        person
        provider_organization
        indications
        key_decision_maker_types
        is_excluded
        is_excluded_from_kdm_tool
        title
        specialty
        tdg_internal_note
        start_date
        end_date
        dynamic_status
        created_at
        updated_at
      }
      ... on PaginatedProviderKeyDecisionMaker {
        count
        next
        previous
        results {
          id
          person
          provider_organization
          indications
          key_decision_maker_types
          is_excluded
          is_excluded_from_kdm_tool
          title
          specialty
          tdg_internal_note
          start_date
          end_date
          dynamic_status
          created_at
          updated_at
        }
      }
    }
  }
`
