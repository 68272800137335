import { OptionTypeBase } from 'react-select'

import { INPUT_TYPES } from 'frontend/components/Form/constants/input-type-to-component-map'

const getFormConfig = (indicationsOptions: OptionTypeBase[]) => [
  [
    {
      accessor: 'name',
      label: 'Name',
      inputType: INPUT_TYPES.TEXT,
      isRequired: true,
    },
    {
      accessor: 'indications',
      label: 'Indications',
      inputType: INPUT_TYPES.MULTI_SELECT,
      options: indicationsOptions,
      isRequired: true,
    },
  ],
]

export default getFormConfig
