import React from 'react'

import { Label, Wrapper, Value } from './shared'

interface Props {
  fieldState: any
}

const LABEL_TEXT = 'Benefit Type'

export const BenefitType = ({ fieldState }: Props) => {
  const { benefit_type } = fieldState
  const titleCasedBenefitType =
    benefit_type.charAt(0).toUpperCase() + benefit_type.slice(1)

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Value>{titleCasedBenefitType}</Value>
    </Wrapper>
  )
}
