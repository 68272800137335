import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import NccnPanelAffiliations from './NccnPanelAffiliations'
import People from './People'
import SecondaryOrganizationsConnections from './SecondaryOrganizationsConnections'

const ROOT = '/orion/general/people-management'

const PeopleManagement = () => (
  <Switch>
    <Route path={`${ROOT}/people`} component={People} />
    <Route
      path={`${ROOT}/secondary-organization-connections`}
      component={SecondaryOrganizationsConnections}
    />
    <Route
      path={`${ROOT}/nccn-panel-affiliations`}
      component={NccnPanelAffiliations}
    />
    <Redirect to={`${ROOT}/people`} />
  </Switch>
)

export default PeopleManagement
