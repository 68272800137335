import gql from 'graphql-tag'

export const SEND_TO_SUBSCRIBED_USERS = gql`
  mutation EmberSendToSubscribedUsers(
    $input: EmberSendToSubscribedUsersInput!
  ) {
    emberSendToSubscribedUsers(input: $input) {
      message
      failedEmails
    }
  }
`

export const SEND_TO_TEST_GROUP = gql`
  mutation EmberSendToTestGroup($input: EmberSendToTestGroupInput!) {
    emberSendToTestGroup(input: $input) {
      message
    }
  }
`

export const SEND_PAYER_POLICY_UPDATES = gql`
  mutation SendPayerPolicyUpdates($input: SendPayerPolicyUpdatesInput!) {
    sendPayerPolicyUpdates(input: $input)
  }
`
