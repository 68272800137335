import React from 'react'

import { LabelCheckbox } from 'frontend/components'

import { Label, Wrapper } from './shared'

interface Props {
  fieldState: any
  onChange: any
}

const LABEL_TEXT = 'Oncology KDM Tool Permissions'
const CHECKBOX_TEXT = 'Ability to customize sub-score weights'
const CHECKBOX_NAME = 'isCustomOncologyKdmToolScorer'

export const OncologyKdmToolPermissions = ({ fieldState, onChange }: Props) => {
  const disabled = !fieldState['primaryTeam']
  const toggleCheckbox = ({ target: { checked, name } }: any) => {
    onChange({ name, value: checked })
  }

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <LabelCheckbox
        checked={fieldState[CHECKBOX_NAME]}
        disabled={disabled}
        label={CHECKBOX_TEXT}
        onChange={toggleCheckbox}
        name={CHECKBOX_NAME}
      />
    </Wrapper>
  )
}
