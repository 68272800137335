import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const GET_CLIENTS = gql`
  query GetClients($input: ClientInput_EMBER) {
    clients_EMBER(input: $input) {
      ${schemaV0}
    }
  }
`
