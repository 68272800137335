import React from 'react'

import FieldSection from './FieldSection'
import { FieldConfig } from './constants/interfaces'

const Form = ({
  datum = {},
  formConfig = [],
  setFormState = () => null,
  style = {},
  children,
}: any) => {
  return (
    <>
      {formConfig.map((section: FieldConfig[], idx: number) => (
        <FieldSection
          key={`${idx}`}
          datum={datum}
          section={section}
          setFormState={setFormState}
          style={style}
        >
          {children}
        </FieldSection>
      ))}
    </>
  )
}

export default Form
