const getInfluencersColumnsInjectables = ({
  setPersonData,
  options,
  update,
  indicationLabelMap,
  indicationsOptions,
}) => {
  const onPeopleClick = (rowData) => {
    setPersonData(rowData)
  }

  return {
    onPeopleClick,
    options,
    update,
    indicationLabelMap,
    indicationsOptions,
  }
}

export default getInfluencersColumnsInjectables
