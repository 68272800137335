export const getFormConfigOptions = ({
  people,
  providers,
  indications,
  providerKeyDecisionMakers,
}: any) => {
  const formConfigOptions = {
    peopleOptions: people.options,
    providersOptions: providers.options,
    indicationsOptions: indications.options,
    keyDecisionMakerTypeOptions:
      providerKeyDecisionMakers.keyDecisionMakerTypeOptions,
  }
  return formConfigOptions
}
