import React from 'react'

const FlagFilled = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.0169 12.832V9.29037C3.00522 9.28955 3.98451 9.45963 4.90142 9.79003L6.09138 10.2189C7.44502 10.7072 8.89094 10.9587 10.3502 10.957H10.8132C11.3042 10.957 11.7753 10.7813 12.1225 10.4687C12.4697 10.1562 12.665 9.73224 12.665 9.29037V3.66537C12.665 3.22347 12.4697 2.7995 12.1225 2.487C11.7753 2.1745 11.3041 1.9987 10.8132 1.9987H10.3502C9.36189 1.99952 8.3826 1.82943 7.46569 1.49904L6.27573 1.06935C4.92209 0.581059 3.47617 0.330414 2.0169 0.332039C0.994212 0.332039 0.165039 1.07829 0.165039 1.9987V12.832C0.165039 13.2926 0.579186 13.6654 1.09097 13.6654C1.60276 13.6654 2.0169 13.2926 2.0169 12.832Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default FlagFilled
