import { useMutation } from '@apollo/client'

import * as CRUD_UTILS from 'frontend/api/utils'
import { useRewriteSearchString } from 'frontend/hooks'
import { UseReadConfigInput } from 'frontend/interfaces'

import { getEmberNodeConfigs } from './getEmberNodeConfigs'
import {
  CREATE_EMBER_NODE,
  GET_EMBER_NODES,
  UPDATE_EMBER_NODE,
} from './gql-tags'

export interface QueryInput extends UseReadConfigInput {
  type: string
}

interface UseEmberNodeOptions {
  deleteOptions?: any
  queryInput: QueryInput
}

export const useEmberNode = ({
  queryInput,
}: UseEmberNodeOptions) => {
  const rewriteSearchString = useRewriteSearchString()

  const {
    readConfig,
  } = getEmberNodeConfigs(queryInput)

  const {
    data: emberNodeData,
    loading: isLoading,
  } = CRUD_UTILS.useRead(readConfig)

  const [
    create,
    { loading: isCreating },
  ] = useMutation(CREATE_EMBER_NODE, {
    onCompleted: () => {
      rewriteSearchString({
        nodeType: undefined,
      })
    },
    onError: alert,
    update: (cache, { data: { createEmberNode } }) => {
      cache.updateQuery({
        query: GET_EMBER_NODES,
        variables: {
          input: queryInput,
        },
      }, ({ emberNodes }) => ({
        emberNodes: [...emberNodes, createEmberNode].sort((a, b) => a.order - b.order),
      }))
    },
  })

  const [
    update,
    { loading: isUpdating },
  ] = useMutation(UPDATE_EMBER_NODE, {
    onCompleted: () => {
      rewriteSearchString({
        node: undefined,
        nodeType: undefined,
      })
    },
    onError: alert,
    update: (cache) => {
      cache.updateQuery({
        query: GET_EMBER_NODES,
        variables: {
          input: queryInput,
        },
      }, ({ emberNodes }) => ({
        emberNodes: [...emberNodes].sort((a, b) => a.order - b.order),
      }))
    },
  })

  const data = isLoading ? [] : Object.values(emberNodeData)[0]

  return {
    data,
    isLoading,
    create,
    isCreating,
    update,
    isUpdating,
  }
}
