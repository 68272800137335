import { INPUT_TYPES } from 'frontend/components/Form/constants/input-type-to-component-map'

const { SINGLE_SELECT, TEXT } = INPUT_TYPES

const START_END_DATE_FEATURE_FLAG = 'shouldShowPeopleTrackingStartEndDates'

export const getFormConfig = ({
  people,
  nccnPanels,
}: any) => [
  [
    {
      label: 'Person',
      accessor: 'member',
      inputType: SINGLE_SELECT,
      options: people.options,
      isRequired: true,
    },
    {
      label: 'NCCN Panel',
      accessor: 'panel',
      inputType: SINGLE_SELECT,
      options: nccnPanels.options,
      isRequired: true,
    },
    {
      label: 'Internal Notes',
      accessor: 'tdg_internal_note',
      inputType: TEXT,
    },
    {
      label: 'Start Date',
      accessor: 'start_date',
      inputType: INPUT_TYPES.DATE,
      isRequired: true,
      featureFlag: START_END_DATE_FEATURE_FLAG,
    },
    {
      label: 'End Date',
      accessor: 'end_date',
      inputType: INPUT_TYPES.DATE,
      featureFlag: START_END_DATE_FEATURE_FLAG
    },
  ],
]
