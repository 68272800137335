import React from 'react'
import styled from '@emotion/styled'

import CancelButton from './CancelButton'
import SubmitButton from './SubmitButton'

interface Props {
  sitemap: any
}

const Wrapper = styled.div({
  display: 'flex',
})

const ButtonGroup = (props: Props) => {
  return (
    <Wrapper>
      <CancelButton />
      <SubmitButton
        {...props}
      />
    </Wrapper>
  )
}

export default ButtonGroup
