import { INPUT_TYPES } from 'frontend/components/Form/constants/input-type-to-component-map'

const { SINGLE_SELECT, TEXT } = INPUT_TYPES

const getFormConfig = ({
  peopleOptions,
  secondaryOrganizationsOptions,
}: any) => [
  [
    {
      label: 'Person',
      accessor: 'person',
      inputType: SINGLE_SELECT,
      options: peopleOptions,
      isRequired: true,
    },
    {
      label: 'Secondary Organization',
      accessor: 'secondary_organization',
      inputType: SINGLE_SELECT,
      options: secondaryOrganizationsOptions,
      isRequired: true,
    },
    {
      label: 'Committees',
      accessor: 'committees',
      inputType: TEXT,
    },
    {
      label: 'Title',
      accessor: 'title',
      inputType: TEXT,
    },
    {
      accessor: 'start_date',
      label: 'Start Date',
      inputType: INPUT_TYPES.DATE,
      isRequired: true,
      featureFlag: 'shouldShowPeopleTrackingStartEndDates',
    },
    {
      accessor: 'end_date',
      label: 'End Date',
      inputType: INPUT_TYPES.DATE,
      featureFlag: 'shouldShowPeopleTrackingStartEndDates',
    },
    {
      label: 'Internal Notes',
      accessor: 'tdg_internal_note',
      inputType: TEXT,
    },
  ],
]

export default getFormConfig
