import React from 'react'

import { CreatableMultiSelect } from 'frontend/components'

type AliasesMultiSelectProps = {
  onChange: any,
  value: Array<String>,
}

const CREATABLE_MULTI_SELECT_COMPONENTS = {
  DropdownIndicator: null,
}

const NAME = 'aliases'

const AliasesMultiSelect = ({ onChange, value }: AliasesMultiSelectProps) => {
  const handleChange = (value: any, { name }: { name: String }) => {
    const newValue = value.map(({ value }: { value: String }) => value)
    const e = { value: newValue, name }
    onChange(e)
  }

  const convertValueArrayToOptionArray = (value: Array<String>) => {
    if (!value) return value
    const mappedValues = value.map((value) => ({ label: value, value }))
    return mappedValues
  }

  return (
    <CreatableMultiSelect
      name={NAME}
      components={CREATABLE_MULTI_SELECT_COMPONENTS}
      menuIsOpen={false}
      onChange={handleChange}
      value={convertValueArrayToOptionArray(value)}
    />
  )
}

export default AliasesMultiSelect
