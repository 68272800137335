import { DEFAULT_FORM_INPUT } from './constants'
import { PRODUCT_POLICY_FIELDS, POLICY_LINK_FIELDS } from './utils'

export const useHandlers = ({
  input,
  setInput,
  closeModal,
  createProductPolicy,
  createPayerPolicy,
}: any) => {
  const submit = useSubmit({
    input,
    setInput,
    closeModal,
    createProductPolicy,
    createPayerPolicy,
  })

  return { submit }
}

const useSubmit = ({
  input,
  setInput,
  closeModal,
  createProductPolicy,
  createPayerPolicy,
}: any) => {
  return () => {
    const productPolicyInput = PRODUCT_POLICY_FIELDS.reduce((acc: any, key) => {
      acc[key] = input[key]
      return acc
    }, {})

    const policyLinkInput = POLICY_LINK_FIELDS.reduce((acc: any, key) => {
      acc[key] = input[key]
      return acc
    }, {})

    createPayerPolicy({
      variables: { input: policyLinkInput },
    })
      .then(({ data }: any) => {
        const { id } = data.createPayerPolicyLink
        createProductPolicy({
          variables: {
            input: { ...productPolicyInput, policy_link: id },
          },
        })
      })
      .finally(() => {
        handleCancel({ setInput, closeModal })
      })
  }
}

const handleCancel = ({ setInput, closeModal }: any) => {
  setInput(DEFAULT_FORM_INPUT)
  closeModal()
}
