import { EXPAND } from 'frontend/components/DataTable/Cells/cellTypes'
import MultiSelectColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/MultiSelectColumnFilter'
import customMultiSelectFilterFn from 'frontend/components/Table/custom-filters/MultiSelect/customMultiSelectFilterFn'

const getPathwaysAccountsColumns = ({ onRowClick }) => {
  return [
    {
      Header: 'Slug',
      accessor: 'slug',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
      width: 300,
    },
    {
      Header: 'Organization',
      accessor: 'name',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
      width: 300,
    },
    {
      Header: 'Short Name',
      accessor: 'name_tiny',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
      width: 300,
    },
  ]
}

export default getPathwaysAccountsColumns
