import { useExpandOnClick } from '../../UpdateModal/utils'
import { useNccnPanelAffiliationsData } from '../../useNccnPanelAffiliationsData'
import { useAddDeleteSearchString } from '../useAddDeleteSearchString'

import { getColConfig } from './getColConfig'

export const useColConfig = () => {
  const {
    nccnPanelVotingMembers,
    people,
    nccnPanels,
  } = useNccnPanelAffiliationsData()

  const addDeleteSearchString = useAddDeleteSearchString()
  const onExpandClick = useExpandOnClick()

  const updateNccnPanelVotingMember = (input: any) => {
    nccnPanelVotingMembers.update({ variables: { input } })
  }

  return getColConfig({
    people,
    nccnPanels,
    nccnPanelVotingMember: { update: updateNccnPanelVotingMember },
    addDeleteSearchString,
    onExpandClick,
  })
}
