import React from 'react'

import { useQueryString } from 'frontend/hooks'

import TeamsListPanelInnerContainer from './TeamsListPanelInnerContainer'

const TeamsListPanelOuterContainer = () => {
  const { client: selectedClient }: { client?: string } = useQueryString()

  if (!selectedClient) return null

  return <TeamsListPanelInnerContainer />
}

export default TeamsListPanelOuterContainer
