import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import _ from 'lodash'

import { DeleteDialog } from 'frontend/components'
import { useSecondaryOrganizationPerson } from 'frontend/entity-hooks'

const LABEL = 'Delete Secondary Organization Connection'

const SEARCH_STRING_PARAMS = [
  'deleteSecondaryOrganizationPerson',
  'secondaryOrganizationPersonId',
]

const DeleteDialogSecondaryOrganizationPerson = () => {
  const location = useLocation()

  const { secondaryOrganizationPersonId } = queryString.parse(location.search)

  const {
    data,
    isLoading,
    destroy,
    isDeleting,
  } = useSecondaryOrganizationPerson({
    queryInput: { id: secondaryOrganizationPersonId },
  })

  const secondaryOrganizationPerson = getSecondaryOrganizationPerson(
    data,
    secondaryOrganizationPersonId
  )

  const handleDelete = (handleCancel: any) => {
    const input = { id: secondaryOrganizationPersonId }
    destroy({ variables: { input } }).then(handleCancel)
  }

  const entityDescription = secondaryOrganizationPerson?.name

  return (
    <DeleteDialog
      isDeleting={isDeleting}
      isLoading={isLoading}
      entityDescription={entityDescription}
      handleDelete={handleDelete}
      label={LABEL}
      searchStringParams={SEARCH_STRING_PARAMS}
    />
  )
}

export default DeleteDialogSecondaryOrganizationPerson

const getSecondaryOrganizationPerson = (
  data: any,
  secondaryOrganizationPersonId: any
) => {
  if (Boolean(secondaryOrganizationPersonId) && !_.isEmpty(data)) return data[0]
  return undefined
}
