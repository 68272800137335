import React from 'react'
import styled from '@emotion/styled'

import { Spinner } from 'frontend/components'

const SpinnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

export const LoadingState = () => (
  <SpinnerContainer>
    <Spinner />
  </SpinnerContainer>
)
