import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Button } from '@pulse-analytics/pulse-design-system'
import Icon from 'frontend/components/Icon'
import Color from 'frontend/utils/color'
import Spacing from 'frontend/utils/spacing'

export const CopyButton = ({ text, children }) => {
  const [isCopied, toggleIsCopied] = useState(false)

  const color = isCopied ? Color.GREEN : Color.PRIMARY

  const copyToClipBoard = (e) => {
    e.preventDefault()

    if (!text || isCopied) return

    navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        navigator.clipboard.writeText(text)
      } else {
        console.log('permission DENIED') // eslint-disable-line
      }
    })

    toggleIsCopied(true)

    setTimeout(() => {
      toggleIsCopied(false)
    }, 2000)
  }

  return (
    <Button
      style={{
        padding: Spacing.S2,
        margin: Spacing.S2,
      }}
      onClick={copyToClipBoard}
      type="secondary"
      color={color}
    >
      {isCopied ? (
        <span style={{ padding: '0 4px' }}>Copied</span>
      ) : (
        <>
          <span style={{ padding: '0 4px' }}>S3 Document</span>
          <Icon
            height={14}
            width={14}
            iconName={'link'}
            color1={Color.PRIMARY}
          />
        </>
      )}
    </Button>
  )
}

CopyButton.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
}

CopyButton.defaultProps = {
  text: 'Add something to copy here',
  children: null,
}
