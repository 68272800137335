import * as GQL_TAGS from './gql-tags'

export const getNccnPanelVotingMemberConfigs = (
  deleteOptions = {},
  queryInput: any
) => ({
  createConfig: {
    tag: GQL_TAGS.CREATE_NCCN_PANEL_VOTING_MEMBER,
    readTag: GQL_TAGS.GET_NCCN_PANELS_VOTING_MEMBERS,
  },
  updateConfig: {
    tag: GQL_TAGS.UPDATE_NCCN_PANEL_VOTING_MEMBER,
  },
  deleteConfig: {
    tag: GQL_TAGS.DELETE_NCCN_PANEL_VOTING_MEMBER,
    readTag: GQL_TAGS.GET_NCCN_PANELS_VOTING_MEMBERS,
    mutationOptions: deleteOptions,
  },
  readConfig: {
    tag: GQL_TAGS.GET_NCCN_PANELS_VOTING_MEMBERS,
    input: queryInput,
  },
})
