import React from 'react'
import { useQuery } from '@apollo/client'
import _ from 'lodash'
import { nest } from 'd3-collection'

import { GET_MARKET_BASKET_SURVEY_EXPORT_DATA } from 'frontend/api/queries'

import Spinner from 'frontend/components/Spinner'
import DataTable from 'frontend/components/DataTable'
import MultiSelectColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/MultiSelectColumnFilter'
import customMultiSelectFilterFn from 'frontend/components/Table/custom-filters/MultiSelect/customMultiSelectFilterFn'

const COLUMNS_BY_TYPE = {
  regimen: [
    {
      Header: 'Regimen',
      accessor: 'regimen_name',
      sortType: 'text',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
    },
  ],
  product: [
    {
      Header: 'Product (brand)',
      accessor: 'product_brand_name',
      sortType: 'text',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
    },
    {
      Header: 'Product (generic)',
      accessor: 'product_generic_name',
      sortType: 'text',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
    },
  ],
  manufacturer: [
    {
      Header: 'Manufacturer',
      accessor: 'manufacturer_name',
      sortType: 'text',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
    },
  ],
}

const SurveyTable = ({ surveyId, selectedCategory }) => {
  const { data: marketBasketsSurveysQuestions, loading } = useQuery(
    GET_MARKET_BASKET_SURVEY_EXPORT_DATA,
    {
      variables: { surveyId },
    }
  )
  if (loading) return <Spinner />

  const dataByCategory = _.groupBy(
    Object.values(marketBasketsSurveysQuestions)[0],
    'category_id'
  )

  const { value: categoryId, type } = selectedCategory
  const selectedCategoryData = dataByCategory[categoryId] || []

  const characteristics = _.uniq(
    selectedCategoryData.map(({ characteristic_name }) => characteristic_name)
  )

  const tableDataObject = nest()
    .key(
      ({ first_name, last_name, product_id, regimen_id, manufacturer_id }) =>
        `${first_name} ${last_name} ${product_id} ${regimen_id} ${manufacturer_id}`
    )
    .rollup((arr) => {
      const [
        {
          first_name,
          last_name,
          regimen_name,
          product_brand_name,
          product_generic_name,
          manufacturer_name,
        },
      ] = arr

      const datum = {
        stakeholder: `${first_name} ${last_name}`,
        regimen_name,
        product_brand_name,
        product_generic_name,
        manufacturer_name,
      }
      const characteristicData = _.keyBy(arr, 'characteristic_name')

      Object.values(characteristicData).forEach(
        ({ characteristic_name, rating }) =>
          (datum[characteristic_name] = rating)
      )

      return datum
    })
    .object(selectedCategoryData)

  const tableData = Object.values(tableDataObject)

  let columns = [
    {
      Header: 'Stakeholder',
      accessor: 'stakeholder',
      sortType: 'text',
      sticky: 'left',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
    },
  ]

  characteristics.forEach((name) => {
    columns.push({
      Header: name,
      accessor: name,
      sortType: 'text',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
    })
  })

  // * add extra columns depending on the category type
  columns = columns.concat(COLUMNS_BY_TYPE[type])
  const data = Object.values(tableData)

  return <DataTable data={data} columns={columns} />
}

export default SurveyTable
