import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const Row = ({ row, rowId, prepareRow, positionRef, editConfig }) => {
  const rowInputRef = useRef()

  useEffect(() => {
    rowInputRef.current = {}
  }, [row])

  prepareRow(row)

  return (
    <div id={rowId} {...row.getRowProps()} className="tr">
      {row.cells.map((cell, rowCellId) => {
        const cellId = `${rowId}-${rowCellId}`
        return (
          <React.Fragment key={`cell_${cellId}`}>
            {cell.render('Cell', {
              positionRef,
              rowInputRef,
              cellId,
              editConfig,
            })}
          </React.Fragment>
        )
      })}
    </div>
  )
}

Row.propTypes = {
  prepareRow: PropTypes.func.isRequired,
  positionRef: PropTypes.object.isRequired,
}

export default Row
