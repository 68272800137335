import gql from 'graphql-tag'

export const DEPRECATED_CREATE_PERSON = gql`
  mutation DEPRECATED_CreatePerson($input: DeprecatedCreatePersonInput!) {
    DEPRECATED_createPerson(input: $input) {
      _id
      firstName
      lastName
      middleName
      affiliation
      affiliationPosition
      primaryState
      email
      linkedIn
      externalLink
      twitter
      twitterUsername
      nationalProviderIdentifier
      physicianProfileId
    }
  }
`

export const DEPRECATED_UPDATE_PERSON = gql`
  mutation DEPRECATED_UpdatePerson($input: DeprecatedUpdatePersonInput!) {
    DEPRECATED_updatePerson(input: $input) {
      _id
      firstName
      lastName
      middleName
      affiliation
      affiliationPosition
      primaryState
      email
      linkedIn
      externalLink
      twitter
      twitterUsername
      nationalProviderIdentifier
      physicianProfileId
    }
  }
`

export const DEPRECATED_DELETE_PERSON = gql`
  mutation DEPRECATED_DeletePerson($input: DeprecatedDeletePersonInput!) {
    DEPRECATED_deletePerson(input: $input) {
      _id
      firstName
      lastName
      middleName
      affiliation
      affiliationPosition
      primaryState
      email
      linkedIn
      externalLink
      twitter
      twitterUsername
      nationalProviderIdentifier
      physicianProfileId
    }
  }
`
