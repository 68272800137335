import gql from 'graphql-tag'

export const GET_PATHWAYS_INFLUENCERS_HISTORY = gql`
  query GetPathwaysInfluencersHistory($input: HistoryInput) {
    pathwaysInfluencersHistory(input: $input) {
      entity_name
      changed_by {
        email
        auth0_id
        name
        username
      }
      operation
      changes
      timestamp
    }
  }
`
export default GET_PATHWAYS_INFLUENCERS_HISTORY
