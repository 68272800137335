import React from 'react'

import { useQueryString } from 'frontend/hooks'

import Header from './Header'
import { Title } from './Title'

const HeaderContainer = () => {
  const { client: selectedClient } = useQueryString()

  if (!selectedClient) return <Title>Teams</Title>

  return <Header />
}

export default HeaderContainer
