import React from 'react'

import { useShouldShowModal } from 'frontend/hooks'

import { UpdateModal } from './UpdateModal'
import { URL_PARAM_KEY, useFormData, getFormConfig, useModalRef, useHandlers } from './utils'

interface UpdateModalContainerPropTypes {
  setErrorMessage: (arg: any) => void
}

const UpdateModalContainer = ({
  setErrorMessage,
}: UpdateModalContainerPropTypes) => {
  const { inputRef, setInputRef, buttonRef } = useModalRef()
  const { submit, cancel } = useHandlers({ setErrorMessage, inputRef })
  const { data, isLoading } = useFormData({ inputRef })

  const shouldShowModal = useShouldShowModal()
  if (!shouldShowModal(URL_PARAM_KEY)) return null

  const formConfig = getFormConfig()

  return (
    <UpdateModal
      data={data}
      isLoading={isLoading}
      handleSubmit={submit}
      handleCancel={cancel}
      setData={setInputRef}
      buttonRef={buttonRef}
      formConfig={formConfig}
    />
  )
}

export default UpdateModalContainer
