import React from 'react'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'

import { GET_PAYER_PROJECTS_LIST } from 'frontend/api/queries'
import Spinner from 'frontend/components/Spinner'
import { Colors } from 'frontend/utils/pulseStyles'
import ProjectPlacard from './ProjectPlacard'
import CreateProjectButton from './CreateProjectButton'
import AdminLivesImportButton from './AdminLivesImportButton'

const PlacardContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  borderRadius: 4,
  background: Colors.COOL_GRAY_200,
  padding: 12,
  overflow: 'scroll',
})

const Wrapper = styled.div({
  background: Colors.COOL_GRAY_200,
  overflow: 'scroll',
})

const Header = styled.div({
  padding: '32px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 700,
})

const generatePlacards = ({ _id, name }) => {
  return <ProjectPlacard key={_id} projectId={_id} projectName={name} />
}

const PayerProjectsList = (props) => {
  const { data, loading } = useQuery(GET_PAYER_PROJECTS_LIST)

  if (loading) return <Spinner />

  return (
    <Wrapper>
      <Header>
        <div>
          <h1
            style={{
              fontSize: 22,
              fontWeight: 500,
              color: Colors.BLACK,
              marginBottom: 8,
            }}
          >
            Payer Project Access Import
          </h1>
          <p
            style={{
              fontSize: 12,
              fontWeight: 500,
              color: Colors.COOL_GRAY_600,
              lineHeight: '18px',
            }}
          >
            Import historical payer quality of access data. Need help setting up
            a project or addressing an issue? Contact the Pulse product team for
            assistance.
          </p>
        </div>
        <div>
          <AdminLivesImportButton />
          <CreateProjectButton />
        </div>
      </Header>
      <PlacardContainer>
        {data.payerProjectsList.map(generatePlacards)}
      </PlacardContainer>
    </Wrapper>
  )
}

export default PayerProjectsList
