import React from 'react'
import { Button } from '@pulse-analytics/pulse-design-system'

import { useRewriteSearchString } from 'frontend/hooks'

const BUTTON_LABEL = 'Create NCCN Panel'

const BUTTON_STYLE = { margin: 12, padding: 12 }

const CreateButton = () => {
  const rewriteSearchString = useRewriteSearchString()

  const addCreateParam = () => rewriteSearchString({ createNccnPanel: true })

  return (
    <Button style={BUTTON_STYLE} onClick={addCreateParam}>
      {BUTTON_LABEL}
    </Button>
  )
}

export default CreateButton
