import { getGraphQLErrorMessage } from 'frontend/utils'
import { getProductPolicyInput } from './getProductPolicyInput'
import { getPolicyLinkInput } from './getPolicyLinkInput'

export const useHandlers = (updateProps: any) => {
  const update = useUpdate(updateProps)
  return { update }
}

const useUpdate = ({
  input,
  closeModal,
  setErrorMessage,
  updateConfig,
  searchParams,
}: any) => {
  return () => {
    const { policyId, productId } = searchParams
    const { updateProductPolicy, updatePayerPolicy } = updateConfig

    const productPolicyInput = getProductPolicyInput({ input })
    const policyLinkInput = getPolicyLinkInput({ input })

    const onError = (e: any) => {
      const errorMessage = getGraphQLErrorMessage(e)
      setErrorMessage(errorMessage)
    }

    updatePayerPolicy({
      variables: { input: { id: policyId, ...policyLinkInput } },
      onError,
    }).then(() => {
      updateProductPolicy({
        variables: { input: { id: productId, ...productPolicyInput } },
        onError,
        onCompleted: closeModal,
      })
    })
  }
}
