import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import _ from 'lodash'

import { DeleteDialog } from 'frontend/components'
import { useNccnPanelVotingMember } from 'frontend/entity-hooks'

const LABEL = 'Delete NCCN Panel Affiliaton'

const SEARCH_STRING_PARAMS = [
  'deleteNccnPanelVotingMember',
  'nccnPanelVotingMemberId',
]

const DeleteDialogNccnPanelVotingMember = () => {
  const location = useLocation()

  const { nccnPanelVotingMemberId } = queryString.parse(location.search)

  const { data, isLoading, destroy, isDeleting } = useNccnPanelVotingMember({
    queryInput: { id: nccnPanelVotingMemberId },
  })

  const nccnPanelVotingMember = getNccnPanelVotingMember(data)

  const deleteNccnPanelVotingMember = (closeModal: any) => {
    const input = { id: nccnPanelVotingMemberId }
    destroy({ variables: { input } }).then(closeModal)
  }

  const entityDescription =
    nccnPanelVotingMember?.member_full_name +
    ' — ' +
    nccnPanelVotingMember?.panel.name

  return (
    <DeleteDialog
      isDeleting={isDeleting}
      isLoading={isLoading}
      entityDescription={entityDescription}
      handleDelete={deleteNccnPanelVotingMember}
      label={LABEL}
      searchStringParams={SEARCH_STRING_PARAMS}
    />
  )
}

export default DeleteDialogNccnPanelVotingMember

const getNccnPanelVotingMember = (data: any) => {
  if (_.isEmpty(data)) return undefined
  const [nccnPanelVotingMember] = data
  return nccnPanelVotingMember
}
