import _ from 'lodash'
import { zonedTimeToUtc } from 'date-fns-tz'

import {
  LAST_APPROVED_REVIEW_DATE,
  LINK,
} from 'frontend/PayerPolicyLinkMgmt/v0/utils/enums'

import { PRODUCT_POLICY_FIELDS } from '../PolicyContent/PolicyInfo/utils'

export const getProductPolicyInput = ({ input }: any) => {
  const productPolicyInput = PRODUCT_POLICY_FIELDS.reduce(
    (acc: any, key: any) => {
      let value = input[key]

      if (key === LAST_APPROVED_REVIEW_DATE && !_.isEmpty(value)) {
        value = zonedTimeToUtc(input[key], 'UTC')
      }

      if (key === LINK && _.isEmpty(value)) {
        value = null
      }

      acc[key] = value
      return acc
    },
    {}
  )
  return productPolicyInput
}
