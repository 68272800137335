import React from 'react'

const DocumentCompare = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="color1"
        d="M2.00901 18.4H7.60736L7.60729 5.6H2V4H7.35571C8.46773 4 9.36842 4.79644 9.36842 5.77778V18.2222C9.36842 19.2044 8.46773 20 7.35571 20H2.00901V18.4Z"
        fill={iconColor}
      />
      <path
        className="color1"
        d="M5.11237 10.4C5.35553 10.4 5.55265 10.5791 5.55265 10.8V11.2C5.55265 11.4209 5.35553 11.6 5.11237 11.6H2.0018V10.4H5.11237Z"
        fill={iconColor}
      />
      <path
        className="color1"
        d="M5.11237 12.8C5.35553 12.8 5.55265 12.9791 5.55265 13.2V13.6C5.55265 13.8209 5.35553 14 5.11237 14H2.0018V12.8H5.11237Z"
        fill={iconColor}
      />
      <path
        className="color1"
        d="M5.11237 15.2C5.35553 15.2 5.55265 15.3791 5.55265 15.6V16C5.55265 16.2209 5.35553 16.4 5.11237 16.4H2.00901L2.0018 15.2H5.11237Z"
        fill={iconColor}
      />
      <path
        className="color1"
        d="M21.991 18.4H16.3926L16.3927 5.6H22V4H16.6443C15.5323 4 14.6316 4.79644 14.6316 5.77778V18.2222C14.6316 19.2044 15.5323 20 16.6443 20H21.991V18.4Z"
        fill={iconColor}
      />
      <path
        className="color1"
        d="M18.8876 10.4C18.6445 10.4 18.4474 10.5791 18.4474 10.8V11.2C18.4474 11.4209 18.6445 11.6 18.8876 11.6H21.9982V10.4H18.8876Z"
        fill={iconColor}
      />
      <path
        className="color1"
        d="M18.8876 12.8C18.6445 12.8 18.4474 12.9791 18.4474 13.2V13.6C18.4474 13.8209 18.6445 14 18.8876 14H21.9982V12.8H18.8876Z"
        fill={iconColor}
      />
      <path
        className="color1"
        d="M18.8876 15.2C18.6445 15.2 18.4474 15.3791 18.4474 15.6V16C18.4474 16.2209 18.6445 16.4 18.8876 16.4H21.991L21.9982 15.2H18.8876Z"
        fill={iconColor}
      />
      <path
        className="color1"
        d="M11.4737 3.25C11.4737 3.11193 11.5915 3 11.7368 3H12.5263C12.6717 3 12.7895 3.11193 12.7895 3.25V5.75C12.7895 5.88807 12.6717 6 12.5263 6H11.7368C11.5915 6 11.4737 5.88807 11.4737 5.75V3.25Z"
        fill={iconColor}
      />
      <path
        className="color1"
        d="M11.4737 8.25C11.4737 8.11193 11.5915 8 11.7368 8H12.5263C12.6717 8 12.7895 8.11193 12.7895 8.25V10.75C12.7895 10.8881 12.6717 11 12.5263 11H11.7368C11.5915 11 11.4737 10.8881 11.4737 10.75V8.25Z"
        fill={iconColor}
      />
      <path
        className="color1"
        d="M11.4737 13.25C11.4737 13.1119 11.5915 13 11.7368 13H12.5263C12.6717 13 12.7895 13.1119 12.7895 13.25V15.75C12.7895 15.8881 12.6717 16 12.5263 16H11.7368C11.5915 16 11.4737 15.8881 11.4737 15.75V13.25Z"
        fill={iconColor}
      />
      <path
        className="color1"
        d="M11.4737 18.25C11.4737 18.1119 11.5915 18 11.7368 18H12.5263C12.6717 18 12.7895 18.1119 12.7895 18.25V20.75C12.7895 20.8881 12.6717 21 12.5263 21H11.7368C11.5915 21 11.4737 20.8881 11.4737 20.75V18.25Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default DocumentCompare
