const haveSamePopulations = (
  { populations: populationsA },
  { populations: populationsB }
) => {
  if (populationsA.length !== populationsB.length) {
    return false
  }

  const checkSet = new Set(populationsA)
  for (const population of populationsB) {
    if (!checkSet.has(population)) return false
  }

  return true
}

export default haveSamePopulations
