import { UseReadConfigInput } from 'frontend/interfaces/CrudHookOptions'

import * as CRUD_UTILS from '../../../api/utils'
import { getTeamNodeConfigs } from './getTeamNodeConfigs'

interface UseReadTeamNodeConfigInput extends UseReadConfigInput {
  team?: string
  nodeType?: 'tools' | 'dashboards' | 'pages' | 'cards'
}

interface UseTeamNodeProps {
  deleteOptions?: any
  queryInput?: UseReadTeamNodeConfigInput
}

export const useTeamNode = ({
  deleteOptions,
  queryInput,
}: UseTeamNodeProps) => {
  const { updateConfig, readConfig } = getTeamNodeConfigs(
    deleteOptions,
    queryInput
  )

  const [update, isUpdating] = CRUD_UTILS.useUpdate(updateConfig)

  const { data: readData, loading: isLoading, refetch } = CRUD_UTILS.useRead(
    readConfig
  )

  const data = readData ? Object.values(readData)[0] : []
  return {
    data,
    isLoading,
    update,
    isUpdating,
    refetch,
  }
}
