import React from 'react'

import { useQueryString, useRewriteSearchString } from 'frontend/hooks'

import NodeModal from './NodeModal'

const Container = () => {
  const rewriteSearchString = useRewriteSearchString()

  const { nodeType } = useQueryString()

  const closeModal = (): void => {
    rewriteSearchString({
      node: undefined,
      nodeType: undefined,
    })
  }

  if (!nodeType) return null

  return <NodeModal closeModal={closeModal} />
}

export default Container
