import React from 'react'

import Spinner from 'frontend/components/Spinner'
import { Button } from '@pulse-analytics/pulse-design-system'
import Dialog from 'frontend/components/Dialog'

import Color from 'frontend/utils/color'

import { buttonStyle } from './ModalHeader/ModalHeader'

const PlaceholderModal = ({ closeModal }) => {
  return (
    <Dialog>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: 12,
        }}
      >
        <Button
          onClick={closeModal}
          style={buttonStyle}
          color={Color.GRAY_DARK}
          type="secondary"
        >
          Cancel
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Spinner size={30} />
      </div>
    </Dialog>
  )
}

export default PlaceholderModal
