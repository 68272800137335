import React from 'react'

import { CreateModalButton } from 'frontend/components'
import { useSecondaryOrganizationPerson } from 'frontend/entity-hooks'

import getFormConfig from './getFormConfig'
import { getGraphQLErrorMessage } from 'frontend/utils'
import {
  useClearSearchStringParam,
  useFilterConfigFeatureFlags,
} from 'frontend/hooks'

const ENTITY = 'Secondary Organization Connections'
const CREATE_SEARCH_STRING_PARAM = 'createSecondaryOrganizationConnections'

const CreateButton = ({
  peopleOptions,
  secondaryOrganizationsOptions,
  setErrorMessage,
}: any) => {
  const clearSearchStringParam = useClearSearchStringParam()
  const { create, isCreating } = useSecondaryOrganizationPerson()
  const filterConfigFeatureFlags = useFilterConfigFeatureFlags()

  const formConfig = getFormConfig({
    peopleOptions,
    secondaryOrganizationsOptions,
  })
  const filteredFormConfig = filterConfigFeatureFlags(formConfig)

  const handleError = (e: any) => {
    const errorMessage = getGraphQLErrorMessage(e)
    if (errorMessage === undefined) {
      alert(e)
    } else {
      setErrorMessage(errorMessage)
    }
  }

  const handleCancel = () => {
    clearSearchStringParam([CREATE_SEARCH_STRING_PARAM])
  }

  const submit = async (input: any) => {
    const { committee } = input
    if (committee) {
      input.committee = committee.split(/\s*,\s*/)
    }
    await create({
      variables: { input },
      onCompleted: handleCancel,
      onError: handleError,
    })
  }

  return (
    <CreateModalButton
      isCreating={isCreating}
      entity={ENTITY}
      formConfig={filteredFormConfig}
      submit={submit}
    />
  )
}

export default CreateButton
