import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Spinner } from 'frontend/components'
import { useEmberNode } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'
import { Colors } from 'frontend/utils/pulseStyles'
import { NODE_TYPE_TO_PARENT_TYPE } from 'frontend/Orion/Administrator/NodeManagement/constants'

import { NodeState } from '../../types'

interface Props {
  nodeState: NodeState
}

const Button = styled.button({
  background: transparentize(0.85, Colors.PRIMARY),
  borderRadius: '4px',
  color: Colors.PRIMARY,
  cursor: 'pointer',
  fontSize: '12px',
  fontWeight: 700,
  marginLeft: '20px',
  padding: `8px 12px`,
  textTransform: 'uppercase',
  ':hover': {
    background: transparentize(0.7, Colors.PRIMARY),
  },
})

const SaveButton = ({ nodeState }: Props) => {
  const queryString: any = useQueryString()
  const { node, nodeType } = queryString
  const parentNodeType = NODE_TYPE_TO_PARENT_TYPE[nodeType]

  const { create, isCreating, update, isUpdating } = useEmberNode({
    queryInput: {
      type: nodeType,
      [parentNodeType]: parentNodeType
        ? queryString[parentNodeType]
        : undefined,
    },
  })

  const save = () => {
    const input: { [key: string]: any } = {
      type: nodeType,
      ...nodeState,
    }

    if (node) {
      input.id = node

      update({
        variables: {
          input,
        },
      })
    } else {
      if (parentNodeType) input[parentNodeType] = queryString[parentNodeType]

      create({
        variables: {
          input,
        },
      })
    }
  }

  const label = isCreating || isUpdating ? <Spinner /> : 'Save'

  return <Button onClick={save}>{label}</Button>
}

export default SaveButton
