import React from 'react'

import Card from 'frontend/components/Card'

const LastSurveys = () => {
  return (
    <div style={{ width: '50%' }}>
      <Card bodyStyle={{ padding: 12 }}>Last 4 Surveys</Card>
    </div>
  )
}

export default LastSurveys
