import React from 'react'

import { Colors } from 'frontend/utils/pulseStyles'

const Tag = ({ errors, users }: { errors?: string[], users?: any[] }) => {
  if (!errors && !users) return null

  let backgroundColor
  let color
  let text

  if (errors) {
    backgroundColor = Colors.RED_100
    color = Colors.RED_900
    text = `Errors found for ${errors.length} user(s).`
  }

  if (users) {
    backgroundColor = Colors.GREEN_100
    color = Colors.GREEN_900
    text = `${users.length} user(s) were successfully created.`
  }

  const style = {
    backgroundColor,
    borderRadius: '4px',
    color,
    fontWeight: 700,
    padding: '24px 12px',
  }

  return (
    <div style={style}>
      {text}
    </div>
  )
}

export default Tag
