export const getDefaultFieldState = ({
  id,
  client,
  app_metadata = {},
  firstName,
  lastName,
  username,
  email,
  defaultLanding,
  teams,
  primaryTeam,
  emailSubscriptions,
}: any) => {
  return {
    id,
    client,
    clientSources: app_metadata?.clientSources[0],
    firstName,
    lastName,
    username,
    email,
    isCustomOncologyKdmToolScorer: app_metadata?.isCustomOncologyKdmToolScorer,
    defaultLanding,
    teams,
    primaryTeam,
    emailSubscriptions,
  }
}
