import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const UPDATE_CLIENT = gql`
  mutation UpdateClient($input: UpdateClientInput_EMBER!) {
    updateClient_EMBER(input: $input) {
      ${schemaV0}
    }
  }
`
