import React from 'react'

import { TextInput } from 'frontend/components'

interface Props {
  isManualPassword: boolean
  value: string
  onChange: any
}

const FIELD_NAME = 'password'
const PLACEHOLDER_TEXT = 'Password'

export const Input = ({ isManualPassword, value, onChange }: Props) => {
  if (!isManualPassword) return null

  const handleChange = ({ target: { value } }: any) => {
    onChange({ name: FIELD_NAME, value })
  }

  return (
    <TextInput
      value={value}
      onChange={handleChange}
      placeholder={PLACEHOLDER_TEXT}
    />
  )
}
