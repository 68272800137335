import React from 'react'

const CreateRow = ({ rows, prepareRow, createRowInputRef }) => {
  const [sampleRow] = rows

  prepareRow(sampleRow)

  const { key, ...rowProps } = sampleRow.getRowProps()

  const baseRow = {
    id: 'create',
  }

  return (
    <div id={'create'} key={'row_create'} {...rowProps} className={'tr'}>
      {sampleRow.cells.map(({ column, getCellProps }, index) => {
        const { id, createRowCellConfig, Cell } = column

        // We don't want key to be passed down to the cell container, when it uses getCellProps
        const customGetCellProps = () => {
          const { key, ...cellProps } = getCellProps()

          return cellProps
        }

        const baseColumn = {
          id,
          createRowCellConfig,
        }

        return (
          <React.Fragment key={`cell_create_${id}`}>
            <Cell
              row={baseRow}
              rowInputRef={createRowInputRef}
              column={baseColumn}
              cell={{ getCellProps: customGetCellProps }}
              cellId={`-1-${index}`}
            />
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default CreateRow
