import React, { useState } from 'react'
import _ from 'lodash'

import { Label, Wrapper } from '../shared'
import { Checkbox } from './Checkbox'
import { Input } from './Input'

interface Props {
  fieldState?: any
  onChange: any
}

const FIELD_NAME = 'defaultLanding'
const LABEL_TEXT = 'Default Landing Page'

export const DefaultLandingPage = ({ fieldState, onChange }: Props) => {
  const currentPath = fieldState[FIELD_NAME]
  const [shouldLockLandingPage, setShouldLockLandingPage] = useState(
    Boolean(currentPath)
  )

  const toggleCheckbox = ({ target: { checked } }: any) => {
    if (!checked) onChange({ name: FIELD_NAME, value: undefined })
    setShouldLockLandingPage(checked)
  }

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Checkbox checked={shouldLockLandingPage} onChange={toggleCheckbox} />
      <Input
        value={currentPath}
        shouldLockLandingPage={shouldLockLandingPage}
        onChange={onChange}
      />
    </Wrapper>
  )
}
