import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

export const Label = styled.label({
  fontSize: 14,
  fontWeight: 500,
  color: Colors.GRAY_500,
  marginBottom: 4,
  width: '50%',
})
