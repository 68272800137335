import React from 'react'
import styled from '@emotion/styled'

import { SortableDragHandle } from 'frontend/components'

import { LIST_HEADER } from '../../../enums'

interface Props {
  type: LIST_HEADER,
}

const Wrapper = styled.span({
  marginRight: 12,
})

const DragHandle = ({
  type,
}: Props) => {
  if (type !== LIST_HEADER.ACTIVE) return null

  return (
    <Wrapper>
      <SortableDragHandle />
    </Wrapper>
  )
}

export default DragHandle
