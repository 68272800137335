import React from 'react'

import { Dialog } from 'frontend/components'

import DownloadSection from './DownloadSection'
import Header from './Header'
import UploadSection from './UploadSection'

const BulkCreateUsersModal = () => {
  return (
    <Dialog>
      <Header />
      <DownloadSection />
      <UploadSection />
    </Dialog>
  )
}

export default BulkCreateUsersModal
