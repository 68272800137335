import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

const BORDER = `1px solid ${transparentize(0.9, Colors.BLACK)}`
const HEADER_TEXT = 'Clients'

const TitleSection = styled.section({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 24,
  height: 50,
  borderBottom: BORDER,
})

const Header = styled.div({
  fontWeight: 600,
  fontSize: 20,
  color: Colors.BLACK,
  '@media (max-width: 1000px)': {
    display: 'none',
  },
})

const ClientsPanelHeader = () => {
  return (
    <TitleSection>
      <Header>{HEADER_TEXT}</Header>
    </TitleSection>
  )
}

export default ClientsPanelHeader
