import { useMutation } from '@apollo/client'

import * as CRUD_UTILS from 'frontend/api/utils'
import { UPDATE_TEAM } from 'frontend/entity-hooks/ember/useTeam/gql-tags'
import { useRewriteSearchString } from 'frontend/hooks'

import { getTeamSitemapConfigs } from './getTeamSitemapConfigs'
import { DELETE_TEAM_SITEMAP, GET_TEAM_SITEMAPS } from './gql-tags'

export interface QueryInput {
  team: string
}

interface UseTeamSitemapOptions {
  deleteOptions?: any
  queryInput?: QueryInput
}

export const useTeamSitemap = ({
  queryInput,
}: UseTeamSitemapOptions = {}) => {
  const rewriteSearchString = useRewriteSearchString()

  const {
    readConfig,
  } = getTeamSitemapConfigs(queryInput)

  const {
    data: teamSitemapData,
    loading: isLoading,
  } = CRUD_UTILS.useRead(readConfig)

  const [
    update,
    { loading: isUpdating },
  ] = useMutation(UPDATE_TEAM, {
    awaitRefetchQueries: true,
    onError: alert,
    refetchQueries: [
      {
        query: GET_TEAM_SITEMAPS,
        variables: {
          input: queryInput,
        },
      },
    ],
  })

  const [
    destroy,
    { loading: isDestroying },
  ] = useMutation(DELETE_TEAM_SITEMAP, {
    onCompleted: () => {
      rewriteSearchString({
        removeFromSitemaps: undefined,
      })
      alert('Removal from teams and sitemaps successful. Please refresh sitemap view for persisted changes.')
    },
    onError: alert,
  })

  const data = isLoading ? [] : Object.values(teamSitemapData)[0]

  return {
    data,
    isLoading,
    update,
    isUpdating,
    destroy,
    isDestroying,
  }
}
