import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Accounts from './Accounts'

const AlternativePaymentModel = () => (
  <Switch>
    <Route
      path={'/orion/organizations/alternative-payment-model/accounts'}
      component={Accounts}
    />
    <Redirect to={'/orion/organizations/alternative-payment-model/accounts'} />
  </Switch>
)

export default AlternativePaymentModel
