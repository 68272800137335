import React from 'react'

import ClientsPanel from './ClientsPanel'
import TeamsUsersWrapper from './TeamsUsersWrapper'

const PanelsWrapperStyle = {
  display: 'flex',
}

const PanelsWrapper = () => {
  return (
    <div style={PanelsWrapperStyle}>
      <ClientsPanel />
      <TeamsUsersWrapper />
    </div>
  )
}

export default PanelsWrapper
