const getHistoricalPositionTableColumnsInjectables = ({
  setSelectedRowToDelete,
  pathwaysPositions,
}) => {
  const onDeleteButtonClick = (row) => {
    setSelectedRowToDelete(row)
  }

  const onEdit = (input) => {
    pathwaysPositions.update({ variables: { input } })
  }
  return {
    onDeleteButtonClick,
    onEdit,
  }
}

export default getHistoricalPositionTableColumnsInjectables
