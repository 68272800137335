import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useQuery } from '@apollo/client'

import StructuralListPanels from 'frontend/components/StructuralListPanels'

import { GET_SOURCE_INDICATIONS } from 'frontend/api/queries'

import getPanels from './getPanels'
import getPanelsInjectables from './getPanelsInjectables'

const TreatmentPlans = () => {
  const location = useLocation()

  const { indicationId: selectedIndicationId } =
    (location.search && queryString.parse(location.search)) || {}

  const indicationsData = useQuery(GET_SOURCE_INDICATIONS)

  const panelsInjectables = getPanelsInjectables({
    indicationsData,
    selectedIndicationId,
  })

  const panels = getPanels(panelsInjectables)

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <StructuralListPanels panels={panels} />
    </div>
  )
}

export default TreatmentPlans
