import React from 'react'
import { Link } from 'react-router-dom'

import DefaultCell from './DefaultCell'

const LinkCell = (props) => {
  const { onEvent } = props.cellConfig

  const link = onEvent(props)

  return (
    <Link to={link}>
      <DefaultCell {...props} />
    </Link>
  )
}

export default LinkCell
