import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@pulse-analytics/pulse-design-system'

import Color from 'frontend/utils/color'
import Spacing from 'frontend/utils/spacing'

const CANCEL_BUTTON_COLOR = Color.GRAY_MEDIUM
const SUBMIT_BUTTON_COLOR = Color.GREEN

const BUTTON_STYLE = {
  padding: `${Spacing.S2} ${Spacing.S3}`,
  margin: `${Spacing.S4}`,
}

const AddCreateRowButton = ({ toggleCreateRow }) => (
  <Button onClick={toggleCreateRow} style={BUTTON_STYLE}>
    Create Position
  </Button>
)

const CancelCreateRowButton = ({ toggleCreateRow }) => (
  <Button
    onClick={toggleCreateRow}
    style={BUTTON_STYLE}
    color={CANCEL_BUTTON_COLOR}
  >
    Cancel
  </Button>
)

const SubmitCreateRowButton = ({ onCreateRowSubmit }) => (
  <Button
    onClick={onCreateRowSubmit}
    style={BUTTON_STYLE}
    color={SUBMIT_BUTTON_COLOR}
  >
    Submit New Position
  </Button>
)

const DefaultButtonGroup = ({ toggleCreateRow }) => (
  <div>
    <AddCreateRowButton toggleCreateRow={toggleCreateRow} />
  </div>
)

const CreationButtonGroup = ({
  onCreateRowSubmit,
  createRowInputRef,
  toggleCreateRow,
}) => {
  const createRowSubmitHandler = () => {
    onCreateRowSubmit(createRowInputRef.current)
    createRowInputRef.current = {}
    toggleCreateRow()
  }

  const createRowCancelHandler = () => {
    createRowInputRef.current = {}
    toggleCreateRow()
  }

  return (
    <div>
      <SubmitCreateRowButton
        onCreateRowSubmit={createRowSubmitHandler}
        createRowInputRef={createRowInputRef}
      />
      <CancelCreateRowButton toggleCreateRow={createRowCancelHandler} />
    </div>
  )
}

const ButtonGroup = ({
  hasCreate,
  isCreateActive,
  toggleCreateRow,
  onCreateRowSubmit,
  createRowInputRef,
}) => {
  let buttonGroup = null

  if (hasCreate) {
    buttonGroup = <DefaultButtonGroup toggleCreateRow={toggleCreateRow} />

    if (isCreateActive) {
      buttonGroup = (
        <CreationButtonGroup
          onCreateRowSubmit={onCreateRowSubmit}
          createRowInputRef={createRowInputRef}
          toggleCreateRow={toggleCreateRow}
        />
      )
    }
  }

  return buttonGroup
}

ButtonGroup.propTypes = {
  hasCreate: PropTypes.bool,
  isCreateActive: PropTypes.bool.isRequired,
  toggleCreateRow: PropTypes.func.isRequired,
  onCreateRowSubmit: PropTypes.func.isRequired,
  createRowInputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
}

export default ButtonGroup
