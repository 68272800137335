import gql from 'graphql-tag'

export const CREATE_VEGA_TEAM_REGION = gql`
  mutation CreateVegaTeamRegion($input: CreateVegaTeamRegionInput!) {
    createVegaTeamRegion(input: $input) {
      id
      name
      team
      created_at
      updated_at
    }
  }
`

export const UPDATE_VEGA_TEAM_REGION = gql`
  mutation UpdateVegaTeamRegion($input: UpdateVegaTeamRegionInput!) {
    updateVegaTeamRegion(input: $input) {
      id
      name
      team
      created_at
      updated_at
    }
  }
`

export const DELETE_VEGA_TEAM_REGION = gql`
  mutation DeleteVegaTeamRegion($input: DeleteVegaTeamRegionInput!) {
    deleteVegaTeamRegion(input: $input) {
      id
      name
      team
      created_at
      updated_at
    }
  }
`
