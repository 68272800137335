import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'
import { useRewriteSearchString } from 'frontend/hooks'

const StyledButton = styled.button({
  border: 'none',
  background: 'none',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 4,
  padding: '8px 8px',
  margin: 0,
  fill: transparentize(0.7, Colors.BLACK),
  cursor: 'pointer',
  ':hover': {
    fill: Colors.PRIMARY,
    background: transparentize(0.85, Colors.PRIMARY),
  },
  ':active': {
    background: transparentize(0.75, Colors.PRIMARY),
  },
})

export const EditButton = ({ datumId, entity, queryStringValue }) => {
  const rewriteSearchString = useRewriteSearchString()

  const addCreateParam = (event) => {
    event.stopPropagation()
    rewriteSearchString({ [entity]: datumId, sidePanel: queryStringValue })
  }

  return (
    <StyledButton onClick={addCreateParam}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
      >
        <path
          d="M19.0583 5.76718C18.0354 4.74427 16.3831 4.74427 15.3601 5.76718L15.0454 6.08191L18.7436 9.78009L19.0583 9.46535C20.0812 8.46868 20.0812 6.79008 19.0583 5.76718Z"
          fill="inherit"
        />
        <path
          d="M5.26228 15.8388C5.10491 15.9962 5 16.2322 5 16.4683V18.9338C5 19.4321 5.39342 19.8255 5.89176 19.8255H8.35721C8.59326 19.8255 8.82932 19.7206 8.98669 19.5632L17.642 10.9079L13.9176 7.20973L5.26228 15.8388Z"
          fill="inherit"
        />
      </svg>
    </StyledButton>
  )
}
