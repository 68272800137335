import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import _ from 'lodash'

import {
  Button,
  CondensedDataTable,
} from '@pulse-analytics/pulse-design-system'
import { FontFamily } from 'frontend/utils/pulseStyles'

import { ADD_LINK_MODAL, MANAGE_PROJECTS_MODAL } from '../PolicyLinkModals/util'
import { EXPORT_STYLE_CONFIG, getExportColConfig } from './excel-export-utils'
import { getExcelExportColConfig } from './getExcelExportColConfig'
import { getColConfig } from './getColConfig'
import { getExcelData } from './excel-export-utils'

const TABLE_ID = 'payer-policy-link-mgmt-table'

const BUTTON_STYLE = {
  padding: '8px 12px',
  fontFamily: FontFamily.NORMAL,
}

const PolicyLinkTable = ({ data, isLoading, policyOptions, updateConfig }) => {
  const history = useHistory()
  const { search } = useLocation()

  const { updateProductPolicy, updatePayerPolicy } = updateConfig

  const setModalType = ({ type, policyLinkId, productPolicyId }) => {
    const currentSearchParams = queryString.parse(search)
    currentSearchParams.modal = type

    if (policyLinkId && productPolicyId) {
      currentSearchParams.policyId = policyLinkId
      currentSearchParams.productId = productPolicyId
    }

    history.push({
      search: queryString.stringify(currentSearchParams),
    })
  }

  const handleProductPolicyUpdate = (input, datum) => {
    const { productPolicyId } = datum
    const inputWithCorrectId = { ...input, id: productPolicyId }
    updateProductPolicy({ variables: { input: inputWithCorrectId } })
  }

  const handlePolicyLinkUpdate = (input, datum) => {
    const { policyLinkId } = datum
    const inputWithCorrectId = { ...input, id: policyLinkId }
    updatePayerPolicy({ variables: { input: inputWithCorrectId } })
  }

  const colConfig = getColConfig({
    isLoading,
    handleProductPolicyUpdate,
    handlePolicyLinkUpdate,
    policyOptions,
    setModalType,
  })

  const exportExcelColConfig = getExcelExportColConfig({
    isLoading,
    policyOptions,
  })

  const exportColConfig = getExportColConfig(exportExcelColConfig)

  const exportConfig = {
    exportColConfig,
    sheetName: 'sheet1',
    styleConfig: EXPORT_STYLE_CONFIG,
    fileName: 'Payer Policy Tracker',
    dataFormatter: (tableData) =>
      getExcelData(tableData, exportColConfig, policyOptions),
  }

  let filterAccessorsToShow = []
  if (!_.isEmpty(colConfig)) {
    filterAccessorsToShow = colConfig.reduce(
      (acc, { sortType = null, accessor }) => {
        if (sortType) acc.push(accessor)
        return acc
      },
      []
    )
  }

  const styleConfig = {
    rows: [
      {
        fn: ({ shouldAlert }) => shouldAlert,
        style: {
          background: '#FFF3C9',
        },
      },
    ],
  }

  const tableData = getTableData(data, isLoading, policyOptions)

  return (
    <div style={{ overflow: 'auto', height: 'calc(100vh - 64px)' }}>
      <CondensedDataTable
        tableId={TABLE_ID}
        data={tableData}
        isLoading={isLoading}
        isFocusable
        isPaginated
        hasReset
        hasExport
        hasRowCount
        exportConfig={exportConfig}
        isHoverable
        colConfig={colConfig}
        isEditable
        editAccessor={'id'}
        paginationConfig={{
          wrapperStyle: { alignSelf: 'flex-start' },
          pageSize: 120,
        }}
        defaultColWidths={{ minWidth: 50, width: 200 }}
        queryStringConfig={{
          showPageNumber: true,
          showSearchFilter: true,
          showSort: true,
          filterAccessorsToShow,
        }}
        styleConfig={styleConfig}
        utilityBarNodes={[
          <Button
            onClick={() => setModalType({ type: ADD_LINK_MODAL })}
            style={BUTTON_STYLE}
          >
            Add Link
          </Button>,
          <Button
            onClick={() => setModalType({ type: MANAGE_PROJECTS_MODAL })}
            style={BUTTON_STYLE}
            type="secondary"
          >
            Manage Projects
          </Button>,
        ]}
      />
    </div>
  )
}

PolicyLinkTable.propTypes = {
  data: PropTypes.array,
}

PolicyLinkTable.defaultProps = {
  data: [],
}

export default PolicyLinkTable

const getTableData = (data, isLoading, policyOptions) => {
  if (isLoading) return data

  const orderedTableData = _.orderBy(data, 'created_at', ['desc'])
  const formattedTableData = orderedTableData.map(
    ({ link_status, ...rest }) => {
      return {
        link_status: policyOptions.link_status.labelMap[link_status],
        ...rest,
      }
    }
  )

  return formattedTableData
}
