import React, { Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'

import { HeaderTabs } from './HeaderTabs'
import { ModalControls } from './ModalControls'
import { HeaderLabel } from './HeaderLabel'

interface Props {
  activeTab: string
  onCancel: () => void
  onSave: () => void
  previousApprovedPolicyOptions?: any[]
  selectedPreviousApprovedPolicy?: any
  setActiveTab: Dispatch<SetStateAction<string>>
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  width: 100%;
  gap: 12px;
`

const HeaderWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
`

const PolicyModalHeader = ({
  activeTab,
  onCancel,
  onSave,
  previousApprovedPolicyOptions,
  selectedPreviousApprovedPolicy,
  setActiveTab
}: Props) => (
  <Container>
    <HeaderTabs
      activeTab={activeTab}
      previousApprovedPolicyOptions={previousApprovedPolicyOptions}
      selectedPreviousApprovedPolicy={selectedPreviousApprovedPolicy}
      setActiveTab={setActiveTab}
    />
    <HeaderWrapper>
      <HeaderLabel />
    </HeaderWrapper>
    <ModalControls onSave={onSave} onCancel={onCancel} />
  </Container>
)

export default PolicyModalHeader
