import onError from '../../onError'
import { removeEntityFromCache } from './removeEntityFromCache'

export const getMutationOptions = ({ readTag, mutationOptions }: any) => {
  return {
    update:( cache: any, data: any ) => removeEntityFromCache({ cache, data, readTag }),
    onError,
    ...mutationOptions,
  }
}
