import React from 'react'
import { DataTable } from '@pulse-analytics/pulse-design-system'
import styled from '@emotion/styled'

import PanelHeader from 'frontend/components/Panel/PanelHeader'
import { usePolarisUsers } from 'frontend/api/usePolarisUsers/usePolarisUsers'

import CreateButton from './CreateButton'
import getColConfig from './getColConfig'

const ICON_BAR_WIDTH = 60
const ORION_SIDEBAR_WIDTH = 300

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: `calc(100vw - ${ICON_BAR_WIDTH + ORION_SIDEBAR_WIDTH}px)`,
})

const Avatar = styled.img({
  clipPath: `circle(20px at center)`,
  width: 30,
})

const PAGE_TITLE = 'Polaris User Management'

const PolarisUserManagement = () => {
  const { data, isLoading, delete: destroy, update } = usePolarisUsers()

  const handleDelete = ({ name, user_id }) => {
    const shouldDelete = window.confirm(`Delete polaris user, ${name}?`)
    if (shouldDelete) {
      destroy({ variables: { input: { user_id } } })
    }
  }

  const handleUpdate = (input) => {
    update({ variables: { input } })
  }

  const handleUpdateUserMetadata = (input) => {
    const { user_id, ...body } = input
    const [key] = Object.keys(body)
    const polarisUserToUpdate = data.find(({ user_id: uid }) => uid === user_id)
    const user_metadata = {
      ...(polarisUserToUpdate.user_metadata || {}),
      [key]: body[key],
    }

    update({ variables: { input: { user_id, user_metadata } } })
  }

  const formattedData = data.map(({ user_metadata, picture, ...datum }) => {
    return {
      ...datum,
      role: user_metadata?.role,
      initials: user_metadata?.initials,
      knowledge_areas: user_metadata?.knowledge_areas,
      picture: (
        <Avatar src={user_metadata?.picture || picture} alt={datum.name} />
      ),
    }
  })

  const colConfig = getColConfig({
    handleDelete,
    handleUpdate,
    handleUpdateUserMetadata,
  })

  return (
    <Container>
      <PanelHeader title={PAGE_TITLE} />
      <DataTable
        tableId={'polaris-users-table'}
        isLoading={isLoading}
        isEditable
        editAccessor={'user_id'}
        data={formattedData}
        colConfig={colConfig}
        utilityBarNodes={[<CreateButton />]}
      />
    </Container>
  )
}

export default PolarisUserManagement
