import React, { useState } from 'react'

import { NodeState } from './types'
import Form from './Form'
import Header from './Header'

interface Props {
  closeModal: () => void
}

const ModalContent = (
  props: Props,
) => {
  const [nodeState, setNodeState] = useState<NodeState>()

  return (
    <>
      <Header
        nodeState={nodeState}
        {...props}
      />
      <Form
        nodeState={nodeState}
        setNodeState={setNodeState}
      />
    </>
  )
}

export default ModalContent
