import gql from 'graphql-tag'

export const CREATE_SECONDARY_ORGANIZATION = gql`
  mutation CreateSecondaryOrganization(
    $input: CreateSecondaryOrganizationInput!
  ) {
    createSecondaryOrganization(input: $input) {
      id
      name
      type
      alias
      committees
      tdg_internal_note
      created_at
      updated_at
    }
  }
`
