import React from 'react'
import styled from '@emotion/styled'

import TeamTag from './TeamTag'

const Wrapper = styled.div({
  padding: '2px 0 12px 16px',
})

export const Teams = ({ teams }: { teams: string[] }) => {
  return (
    <Wrapper>
      {teams.map(({ name }: any) => {
        return <TeamTag data={name} />
      })}
    </Wrapper>
  )
}
