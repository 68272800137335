import React from 'react'

import { LIST_HEADER } from '../../../enums'
import { List, ListHeader, ListItem } from '../../shared'

const ActiveList = ({ accountIdToLabel, accounts, setResources }) => {
  const disableResource = (resource) => {
    setResources(({ accounts, ...rest }) => {
      accounts = accounts.filter(
        (account) => account.resource !== resource.resource
      )
      return {
        accounts,
        ...rest,
      }
    })
  }

  return (
    <>
      <ListHeader listLength={accounts.length} type={LIST_HEADER.ACTIVE} />
      <List>
        {accounts.map((resource) => (
          <ListItem
            key={resource.resource}
            checked={true}
            label={accountIdToLabel[resource.resource]}
            resource={resource}
            toggleResource={disableResource}
            type={LIST_HEADER.ACTIVE}
          />
        ))}
      </List>
    </>
  )
}

export default ActiveList
