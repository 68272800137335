import React from 'react'
import { useEmberNode } from 'frontend/entity-hooks/ember'

import EmberToolTimestamp from './ToolTimestamp'
import Spinner from 'frontend/components/Spinner'

const ToolsTimestamps = () => {
  const queryInput = {
    type: 'tool',
  }
  const { data, isLoading } = useEmberNode({ queryInput })

  if (isLoading) return <Spinner />

  return (
    <div style={{ padding: 0 }}>
      <table style={{ width: '100%' }}>
        <tbody>
          {data.map(({ id, name, tdg_timestamp }) => (
            <EmberToolTimestamp
              key={id}
              toolId={id}
              toolName={name}
              prevTimestamp={tdg_timestamp}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ToolsTimestamps
