import React from 'react'
import { Button } from '@pulse-analytics/pulse-design-system'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'
import { useRewriteSearchString } from 'frontend/hooks'

const BUTTON_LABEL = 'Delete Team'

const ButtonStyle = {
  backgroundColor: Colors.RED_600,
  padding: '8px 12px',
}

const ButtonLabel = styled.div({
  marginLeft: 4,
})

export const DeleteButton = () => {
  const rewriteSearchString = useRewriteSearchString()

  const addDeleteParam = () => {
    rewriteSearchString({ deleteDialog: 'team' })
  }

  return (
    <Button onClick={addDeleteParam} style={ButtonStyle}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 24 24"
        style={{ margin: 3 }}
      >
        <path
          fill="#FFFFFF"
          class="icon-color-1"
          fill-rule="evenodd"
          d="M12.5625,19.5 L13.6875,19.5 C13.9981602,19.5 14.25,19.2481602 14.25,18.9375 L14.25,8.8125 C14.25,8.50183983 13.9981602,8.25 13.6875,8.25 L12.5625,8.25 C12.2518398,8.25 12,8.50183983 12,8.8125 L12,18.9375 C12,19.2481602 12.2518398,19.5 12.5625,19.5 Z M20.25,3.75 L16.3870312,3.75 L14.7932813,1.0921875 C14.3864304,0.414259443 13.6536102,-0.000376523857 12.8629687,-2.56217685e-07 L8.13703125,-2.56217685e-07 C7.34672393,-2.56217685e-07 6.61433523,0.414546092 6.20765625,1.0921875 L4.61296875,3.75 L0.75,3.75 C0.335786438,3.75 0,4.08578644 0,4.49999974 L0,5.25 C0,5.66421356 0.335786438,5.99999974 0.75,5.99999974 L1.5,5.99999974 L1.5,21.75 C1.5,22.9926407 2.50735931,23.9999997 3.75,23.9999997 L17.25,23.9999997 C18.4926407,23.9999997 19.5,22.9926407 19.5,21.75 L19.5,5.99999974 L20.25,5.99999974 C20.6642136,5.99999974 21,5.66421356 21,5.25 L21,4.49999974 C21,4.08578644 20.6642136,3.75 20.25,3.75 Z M8.055,2.38640625 C8.10599268,2.30153075 8.19785972,2.24972202 8.296875,2.24999889 L12.703125,2.24999889 C12.8019733,2.24988704 12.8936244,2.30167437 12.9445312,2.38640625 L13.7629688,3.74999889 L7.23703125,3.74999889 L8.055,2.38640625 Z M17.25,21.75 L3.75,21.75 L3.75,6 L17.25,6 L17.25,21.75 Z M7.3125,19.5 L8.4375,19.5 C8.74816017,19.5 9,19.2481602 9,18.9375 L9,8.8125 C9,8.50183983 8.74816017,8.25 8.4375,8.25 L7.3125,8.25 C7.00183983,8.25 6.75,8.50183983 6.75,8.8125 L6.75,18.9375 C6.75,19.2481602 7.00183983,19.5 7.3125,19.5 Z"
        />
      </svg>
      <ButtonLabel>{BUTTON_LABEL}</ButtonLabel>
    </Button>
  )
}
