import queryString from 'query-string'

import { LazyQueryResponse } from '../../../../interfaces'

const getNextDataPage = (next: string, { getData }: LazyQueryResponse) => {
  const [base, search] = next.split('?')
  const input = queryString.parse(search)
  setTimeout(() => getData({ variables: { input } }), 0)
}

export default getNextDataPage
