import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { Button } from '@pulse-analytics/pulse-design-system'

import Spinner from 'frontend/components/Spinner'
import { SingleActionDialog } from 'frontend/components/Dialog'

import Color from 'frontend/utils/color'
import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'

import { DELETE_PAYER_ORGANIZATION } from 'frontend/api/mutations'
import {
  GET_PAYER_ORGANIZATIONS,
  GET_PATHWAYS_ORGANIZATIONS,
  GET_VIEW_OBM_PAYER_PARTNERSHIPS,
  GET_JOIN_OBMS_AND_PAYERS,
  GET_JOIN_LBMS_AND_PAYERS,
  GET_VIEW_LBM_PAYER_PARTNERSHIPS,
} from 'frontend/api/queries'

const PAGE_HEADER = 'Delete Payer Account'

const REFETCH_QUERIES = [
  { query: GET_PATHWAYS_ORGANIZATIONS },
  { query: GET_VIEW_OBM_PAYER_PARTNERSHIPS },
  { query: GET_JOIN_OBMS_AND_PAYERS },
  { query: GET_JOIN_LBMS_AND_PAYERS },
  { query: GET_VIEW_LBM_PAYER_PARTNERSHIPS },
]

const DeletePayerAccountForm = ({ accountId, closeHandler }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const deletePayerAccountFromCache = (cache) => {
    const { payerOrganizations } = cache.readQuery({
      query: GET_PAYER_ORGANIZATIONS,
    })
    const newPayerAccounts = payerOrganizations.filter(
      ({ _id }) => _id !== accountId
    )
    cache.writeQuery({
      query: GET_PAYER_ORGANIZATIONS,
      data: {
        payerOrganizations: newPayerAccounts,
      },
    })
  }

  const [deletePayerAccount, { loading: isPayerAccountLoading }] = useMutation(
    DELETE_PAYER_ORGANIZATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: REFETCH_QUERIES,
      variables: {
        input: {
          _id: accountId,
        },
      },
      update: deletePayerAccountFromCache,
      onError: alert,
      onCompleted: () => {
        closeHandler()
      },
    }
  )

  return (
    <section>
      <h4 style={{ paddingBottom: Spacing.FS4, ...FontSpace.FS5 }}>
        {PAGE_HEADER}
      </h4>
      <p style={FontSpace.FS2}>
        Deleting a payer account removes all data associated with it and can’t
        be undone.
      </p>
      <Button
        color={Color.RED}
        onClick={() => setIsModalOpen(true)}
        style={{
          padding: `${Spacing.S2} ${Spacing.S3}`,
          margin: `${Spacing.S4} 0`,
        }}
      >
        Delete
      </Button>
      {isModalOpen && (
        <SingleActionDialog
          header={PAGE_HEADER}
          submitText="Delete Forever"
          submitHandler={deletePayerAccount}
          cancelHandler={() => setIsModalOpen(false)}
          headerStyle={{ color: Color.RED }}
          submitColor={Color.RED}
          contentStyle={{ width: 450 }}
        >
          {isPayerAccountLoading ? (
            <Spinner />
          ) : (
            <div style={{ padding: 36, textAlign: 'center', ...FontSpace.FS3 }}>
              <p>Are you sure you want to delete the payer account?</p>
              <p style={{ fontWeight: 700, marginTop: 12 }}>
                THIS CANNOT BE UNDONE
              </p>
            </div>
          )}
        </SingleActionDialog>
      )}
    </section>
  )
}

DeletePayerAccountForm.propTypes = {
  accountId: PropTypes.string.isRequired,
  closeHandler: PropTypes.func.isRequired,
}

export default DeletePayerAccountForm
