import React, { useRef, useState } from 'react'
import { useQuery } from '@apollo/client'

import { DataTable } from '@pulse-analytics/pulse-design-system'
import {
  SORT_TYPES,
  FILTER_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'
import { MultiSelectFilter } from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'

import { GET_NCR_PROVIDER_CHANNEL_SLUGS } from 'frontend/api/queries'
import getExportConfig from 'frontend/components/pulse-design-system/EditableDataTable/getExportConfig'

import {
  FieldContainer,
  FileInput,
} from '../../Orion/ImportExportData/NewImport/styledImportComponents'

import validatePermissionTeam from './validatePermissionTeam'
import onFileAdded from '../onFileAdd'

const { STRING, TEXT_ARRAY } = SORT_TYPES
const { MULTI_SELECT } = FILTER_TYPES

const COLUMNS = [
  {
    Header: 'Provider Slug',
    accessor: 'providerSlug',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
  },
  {
    Header: 'Provider Channel',
    accessor: 'providerChannel',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: TEXT_ARRAY,
  },
  {
    Header: 'Products',
    accessor: 'products',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: TEXT_ARRAY,
  },
  {
    Header: 'Indications',
    accessor: 'indications',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: TEXT_ARRAY,
  },
]

const ERROR_COLUMNS = [
  {
    Header: 'Team',
    accessor: 'team',
    sortType: 'text',
  },
  {
    Header: 'Provider Channel Products',
    accessor: 'hasProviderChannelProducts',
    sortType: 'text',
  },
  {
    Header: 'NCR Client Permissions',
    accessor: 'hasClientPermissions',
    sortType: 'text',
  },
  {
    Header: 'Client Provider Channel',
    accessor: 'hasProviderChannel',
    sortType: 'text',
  },
]

// * todo -- need to add validation for invalid sheets imported

const PermissionTeam = () => {
  const { data, error: dataError, loading: isLoading } = useQuery(
    GET_NCR_PROVIDER_CHANNEL_SLUGS
  )
  const [sheetsData, setSheetsData] = useState({})
  const [validatorStatus, setValidatorStatus] = useState(null)
  const [sheetsErrors, setSheetErrors] = useState([])

  const fileInputRefClientPermissions = useRef(null)
  const fileInputRefProviderChannelProducts = useRef(null)
  const fileInputRefProviderChannel = useRef(null)

  if (isLoading || dataError) return <div>loading</div>

  const { ncrProviderChannelSlugs } = data

  const exportConfig = getExportConfig({
    colConfig: COLUMNS,
    excelLabel: 'ncr-provider-slugs',
  })

  const onValidate = () => {
    const errors = validatePermissionTeam(sheetsData)
    setSheetErrors(errors)

    if (errors.length) {
      setValidatorStatus(
        "You have errors :( Please fix the X's below in order to see the team's permissions reflected correctly"
      )
    } else {
      setValidatorStatus("You're good to import :)")
    }
  }

  return (
    <div>
      <section style={{ padding: '6px 12px' }}>
        <p>
          This validator uses the "Provider Channel Products" sheet in the Net
          Cost Recovery Mappings workbook as the source of truth.
        </p>
        <br />
        <p>
          <b>Validator Methodology:</b>
          <ol>
            <li>
              Take the teams and products within "Provider Channel Products"
            </li>
            <li>
              Cross reference the "NCR Client Permissions" sheet confirm the
              products within "Provider Channel Products" are represented{' '}
            </li>
            <li>
              Cross reference the "Client Provider Channel" sheet confirm the
              provider channels within "Provider Channel Products" are
              represented
            </li>
          </ol>
        </p>
      </section>

      <section style={{ padding: '6px 12px' }}>
        <h4>Upload "Provider Channel Products" Sheet (NCR Mapping WB)</h4>
        <FieldContainer>
          <FileInput
            ref={fileInputRefProviderChannelProducts}
            type="file"
            onChange={() =>
              onFileAdded({
                ref: fileInputRefProviderChannelProducts,
                sheetName: 'Provider Channel Products',
                setSheetsData,
                sheetsData,
              })
            }
          />
        </FieldContainer>
      </section>

      <section style={{ padding: '6px 12px' }}>
        <h4>Upload "NCR Client Permissions" Sheet (NCR Master WB)</h4>
        <FieldContainer>
          <FileInput
            ref={fileInputRefClientPermissions}
            type="file"
            onChange={() =>
              onFileAdded({
                ref: fileInputRefClientPermissions,
                sheetName: 'NCR Client Permissions',
                setSheetsData,
                sheetsData,
              })
            }
          />
        </FieldContainer>
      </section>

      <section style={{ padding: '6px 12px' }}>
        <h4>Upload "Client Provider Channel" (NCR Mapping WB)</h4>
        <FieldContainer>
          <FileInput
            ref={fileInputRefProviderChannel}
            type="file"
            onChange={() =>
              onFileAdded({
                ref: fileInputRefProviderChannel,
                sheetName: 'Client Provider Channel',
                setSheetsData,
                sheetsData,
              })
            }
          />
        </FieldContainer>
      </section>
      <div style={{ display: 'flex', justifyContent: 'end', padding: 8 }}>
        <button
          style={{
            outline: '1px solid black',
            padding: 4,
            borderRadius: 4,
            fontWeight: 600,
          }}
          onClick={onValidate}
        >
          Validate
        </button>
      </div>
      <section style={{ outline: '1px solid black', padding: 12 }}>
        {validatorStatus && <div>{validatorStatus}</div>}
        {sheetsErrors && (
          <DataTable
            hasRowCount
            data={sheetsErrors}
            colConfig={ERROR_COLUMNS}
          />
        )}
      </section>
      <div style={{ height: 700 }}>
        <h4 style={{ padding: 24 }}>List of Providers With Claims Data</h4>
        <DataTable
          hasExport
          exportConfig={exportConfig}
          hasRowCount
          data={ncrProviderChannelSlugs}
          colConfig={COLUMNS}
        />
      </div>
    </div>
  )
}

export default PermissionTeam
