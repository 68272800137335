import React from 'react'

import Card from 'frontend/components/Card'

const SurveyStakeholderDetails = () => {
  return (
    <div style={{ width: '50%' }}>
      <Card bodyStyle={{ padding: 12 }}>Survey Stakeholder</Card>
    </div>
  )
}

export default SurveyStakeholderDetails
