import React from 'react'

import { LIST_HEADER } from '../../../enums'
import { List, ListHeader, ListItem } from '../../shared'

const InactiveList = ({ accountIdToLabel, accounts, setResources }) => {
  const enableResource = (resource) => {
    setResources(({ accounts, ...rest }) => {
      accounts.push(resource)
      return {
        accounts,
        ...rest,
      }
    })
  }

  return (
    <>
      <ListHeader listLength={accounts.length} type={LIST_HEADER.INACTIVE} />
      <List>
        {accounts.map((resource) => (
          <ListItem
            key={resource.resource}
            checked={false}
            label={accountIdToLabel[resource.resource]}
            resource={resource}
            toggleResource={enableResource}
          />
        ))}
      </List>
    </>
  )
}

export default InactiveList
