import React from 'react'

import ImportModal from './ImportModal'
import Policies from './Policies'
import SidebarHeader from '../v0/SidebarHeader'
import { useFlags } from 'launchdarkly-react-client-sdk'
import PDFViewer from './PDFViewer'

const PayerPolicyTracker = () => {
  const { payerPolicyV1 } = useFlags()

  if (!payerPolicyV1) return null

  return (
    <div style={{ display: 'flex' }}>
      <SidebarHeader />
      <Policies />
      <PDFViewer />
      <ImportModal />
    </div>
  )
}

export default PayerPolicyTracker
