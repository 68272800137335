import gql from 'graphql-tag'

export const GET_PEOPLE_HISTORY = gql`
  query GetPeopleHistory($input: HistoryInput!) {
    peopleHistory(input: $input) {
      entity_name
      changed_by {
        email
        auth0_id
        name
        username
      }
      operation
      changes
      timestamp
    }
  }
`

export default GET_PEOPLE_HISTORY
