import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'

import Color from 'frontend/utils/color'

import {
  formatDateMonthYearDash,
  convertIsoShortToDateObj,
} from 'frontend/utils/formatDate'

import Tooltip from '../../Tooltip'

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const InputField = styled.input`
  width: 100%;
  height: 100%;
  padding: 8px;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: ${({ hasError }) => (hasError ? Color.RED : Color.BLACK)};
`

const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${Color.RED};
  padding: 8px;
`

const InputCell = ({ cellConfig, cellValue, rowInputRef, column }) => {
  const columnId = column.id
  const { isDisabled, validationConfig = {} } = cellConfig
  const errorMessage = validationConfig.errorMessage || 'Input Error'
  const onValidate = validationConfig.onValidate || null
  const inputType = validationConfig.inputType || 'text'
  let initialValue = cellValue || ''

  if (inputType === 'date' && initialValue) {
    initialValue = formatDateMonthYearDash(initialValue)
  }
  const [value, setValue] = useState(initialValue)
  const [error, setError] = useState()

  const onChange = (e) => {
    const input = e.target.value
    setValue(input)

    let refInput = input
    if (inputType === 'date') {
      refInput = refInput ? convertIsoShortToDateObj(refInput) : null
    }
    rowInputRef.current[columnId] = refInput

    // validation will probably have to move to a ref
    // for it to persist on page change
    if (onValidate) {
      const isValid = onValidate(input) || _.isEmpty(input)
      if (!isValid) {
        setError(errorMessage)
      } else {
        setError(null)
      }
    }
  }

  return (
    <Container>
      {error && (
        <Tooltip
          id="validation-error"
          text={errorMessage}
          type="error"
          place="right"
        >
          <ErrorIcon size="lg" icon={faExclamationTriangle} />
        </Tooltip>
      )}
      <InputField
        hasError={Boolean(error)}
        type={inputType}
        value={value}
        onChange={onChange}
        readOnly={isDisabled}
      />
    </Container>
  )
}

InputCell.propTypes = {
  cellConfig: PropTypes.object.isRequired,
  cellValue: PropTypes.string,
  rowInputRef: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
}

export default InputCell
