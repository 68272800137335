import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import CreateModal from './CreateModal'

const CreateModalContainer = ({ setErrorMessage }: any) => {
  const isModalOpen = useIsModalOpen()

  if (!isModalOpen) return null

  return <CreateModal setErrorMessage={setErrorMessage} />
}

export default CreateModalContainer

const useIsModalOpen = () => {
  const location = useLocation()
  const { createProviderKdm } = queryString.parse(location.search)

  return Boolean(createProviderKdm)
}
