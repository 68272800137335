import React, { useEffect, useRef, useState, FC } from 'react'
import styled from '@emotion/styled'
import _ from 'lodash'

import { usePayerPolicyDocument } from 'frontend/entity-hooks'
import { useQueryString } from 'frontend/hooks'

const Container = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`

const LabelsWrapper = styled.div<{ justifyLeft: boolean }>`
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  justify-content: ${({ justifyLeft }) =>
    justifyLeft ? 'flex-start' : 'center'};
`

const Label = styled.span`
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
`

const Divider = styled.span`
  margin: 0 8px;
  color: #d2d5da;
`

export const HeaderLabel: FC = () => {
  const { payer_policy_document_id } = useQueryString()

  const { data: [mostRecentPolicy] }: any = usePayerPolicyDocument({
    queryInput: { id: payer_policy_document_id }
  })

  const wrapperRef = useRef<HTMLDivElement>(null)
  const [justifyLeft, setJustifyLeft] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      const current = wrapperRef.current
      const containerWidth = current?.parentElement?.clientWidth || 0
      const contentWidth = current?.scrollWidth || 0

      setJustifyLeft(contentWidth > containerWidth)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Container>
      <LabelsWrapper ref={wrapperRef} justifyLeft={justifyLeft}>
        <Label>
          {mostRecentPolicy.payer_organization.name}
        </Label>
        <Divider>
          |
        </Divider>
        <Label>
          {mostRecentPolicy.book_of_business.name}
        </Label>
        <Divider>
          |
        </Divider>
        <Label>
          {_.startCase(mostRecentPolicy.benefit_type)}
        </Label>
      </LabelsWrapper>
    </Container>
  )
}
