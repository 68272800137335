import styled from '@emotion/styled'
import { TabsList } from '@mui/base/TabsList'

const StyledTabsList = styled(TabsList)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  background-color: #F1F5FA;
  border-radius: 6px;
  height: 36px;
  width: 243px;
  `
)

export default StyledTabsList
