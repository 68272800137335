import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { useTeamNodeResource } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'
import { Colors, Spacing } from 'frontend/utils/pulseStyles'
import { NODE_TYPE_TO_PARENT_TYPE } from 'frontend/ClientManagement/Sitemap/constants'
import { ACCOUNTS } from 'frontend/ClientManagement/Sitemap/ResourcesModal/constants'

import SubmitButton from './SubmitButton'
import Tabs from './Tabs'
import Title from './Title'

const ModalHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: Spacing.LARGE,
})

const CancelButton = styled.button({
  background: transparentize(0.85, Colors.RED),
  border: 'none',
  borderRadius: 4,
  color: Colors.RED,
  cursor: 'pointer',
  fontSize: 12,
  fontWeight: 700,
  marginRight: Spacing.LARGE,
  padding: `${Spacing.SMALL} ${Spacing.NORMAL}`,
  textTransform: 'uppercase',
  ':hover': {
    background: transparentize(0.7, Colors.RED),
  },
  ':active': {
    outline: 'none',
  },
  ':focus': {
    outline: 'none',
  },
})

const ModalContent = ({ closeModal }) => {
  const [resources, setResources] = useState()

  const { team } = useParams()

  const { node, nodeType } = useQueryString()

  const { data, isLoading } = useTeamNodeResource({
    queryInput: {
      nodeType,
      team,
      [nodeType]: node,
    },
  })

  useEffect(() => {
    if (!isLoading) {
      const resources = data.reduce(
        (acc, resource) => {
          const teamNodeResource = {
            team,
            [nodeType]: node,
            resource: resource.resource.entity_id,
          }

          const parentType = NODE_TYPE_TO_PARENT_TYPE[nodeType]
          const parentTypeKey = parentType
            ? `team_${parentType}_resource`
            : undefined
          if (parentType)
            teamNodeResource[parentTypeKey] = resource[parentTypeKey]

          if (resource.resource.type === 'indication') {
            const children = resource.children.map((resourceChild) => {
              const child = {
                team,
                [nodeType]: node,
                resource: resourceChild.resource.entity_id,
              }

              if (parentType)
                child[parentTypeKey] = resourceChild[parentTypeKey]

              return child
            })
            teamNodeResource.children = children
            acc.treatmentPlans.push(teamNodeResource)
          } else if (ACCOUNTS.has(resource.resource.type)) {
            acc.accounts.push(teamNodeResource)
          }
          return acc
        },
        {
          treatmentPlans: [],
          accounts: [],
        }
      )
      setResources(resources)
    }
  }, [isLoading])

  return (
    <>
      <ModalHeader>
        <Title />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CancelButton onClick={closeModal}>Cancel</CancelButton>
          <SubmitButton resources={resources} />
        </div>
      </ModalHeader>
      <Tabs resources={resources} setResources={setResources} />
    </>
  )
}

export default ModalContent
