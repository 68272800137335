import gql from 'graphql-tag'

export const GET_PAYER_QUALITY_OF_ACCESSES = gql`
  query GetPayerQualityOfAccesses($input: PayerQualityOfAccessesInput) {
    payerQualityOfAccesses(input: $input) {
      _id
      accessData {
        _id
        access
        accessTiny
        score
        sortOrder
        color
        caption
      }
      tierData {
        tier
        tierRating
        tierTotal
      }
      timestamp
      policyLinkData {
        paLink
        policyLink
        siteLink
        dateTracked
      }
      additionalCriteriaData {
        criteria
        criteriaLabel
        criteriaNotes
        criteriaType
        mfrCategoryClassification
        restrictionLevel
      }
      materializedOn
      slug
      organization
      organizationTiny
      indication
      regimen
      population
      line
      book
      coverage
      isRestrictive
    }
  }
`

export default GET_PAYER_QUALITY_OF_ACCESSES
