const getLabelMapsAndOptions = ({
  peopleResponse,
  providersResponse,
  indicationsResponse,
  providerKdmOptionsResponse,
  pathwaysOrganizationsResponse,
}: any) => {

  const peopleOptions = peopleResponse.isLoading
    ? []
    : peopleResponse.data.map(
        ({ id, first_name, last_name }: any) => ({
          value: id,
          label: `${first_name} ${last_name}`,
        })
      )

  const providerOptions: any = []
  const providerLabelMap: any = {}

  providersResponse.data?.vegaProviders.forEach(({ id, name }: any) => {
    const option = { label: name, value: id }
    providerOptions.push(option)
    providerLabelMap[id] = name
  })

  const indicationsLabelMap: any = {}
  const indicationsOptions: any = []
  indicationsResponse.data?.vegaIndications.forEach(({ id, name }: any) => {
    const option = { label: name, value: id }
    indicationsOptions.push(option)
    indicationsLabelMap[id] = name
  })

  const keyDecisionMakerTypeLabelMap: any = {}
  const keyDecisionMakerTypeOptions: any = []
  providerKdmOptionsResponse.data?.providerKeyDecisionMakersOptions.actions.POST.key_decision_maker_types.child.choices.forEach(
    ({ value, display_name }: any) => {
      const option = { label: display_name, value }
      keyDecisionMakerTypeOptions.push(option)
      keyDecisionMakerTypeLabelMap[value] = display_name
    }
  )

  const pathwaysOrganizationsLabelMap: any = {}
  pathwaysOrganizationsResponse.data?.vegaPathwaysOrganizations.forEach(
    ({ id, name }: any) => {
      pathwaysOrganizationsLabelMap[id] = name
    }
  )

  return {
    indicationsLabelMap,
    indicationsOptions,
    keyDecisionMakerTypeLabelMap,
    keyDecisionMakerTypeOptions,
    peopleOptions,
    providerOptions,
    providerLabelMap,
    pathwaysOrganizationsLabelMap,
  }
}

export default getLabelMapsAndOptions
