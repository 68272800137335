import React, { useState } from 'react'
import _ from 'lodash'

import {
  FormLabel,
  FieldContainer,
  StyledInput,
  StyledButton,
} from '../../../shared/styledComponents'

interface NewFieldSectionProps {
  setNewField: (arg: any) => void
  isAddingNewField: boolean
  setIsAddingNewField: (arg: boolean) => void
}

const NewFieldSection = ({
  setNewField,
  isAddingNewField,
  setIsAddingNewField,
}: NewFieldSectionProps) => {
  const { inputs, onChangeHandler, onClickHandler } = useNewFieldSection(
    setNewField,
    setIsAddingNewField
  )

  if (!isAddingNewField) return null

  return (
    <div>
      <FieldContainer>
        <FormLabel>Key</FormLabel>
        <StyledInput
          type="text"
          value={inputs.key}
          onChange={onChangeHandler.key}
        />
      </FieldContainer>
      <FieldContainer>
        <FormLabel>Value</FormLabel>
        <StyledInput
          type="text"
          value={inputs.value}
          onChange={onChangeHandler.value}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '8px 0',
          }}
        >
          <StyledButton style={{ margin: 6 }} onClick={onClickHandler.addField}>
            Add
          </StyledButton>
          <StyledButton
            style={{ margin: 6 }}
            onClick={onClickHandler.cancelSection}
          >
            Cancel
          </StyledButton>
        </div>
      </FieldContainer>
    </div>
  )
}

export default NewFieldSection

const useNewFieldSection = (setNewField: any, setIsAddingNewField: any) => {
  const [key, setNewKey] = useState('')
  const [value, setNewValue] = useState('')

  const handleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    return setNewKey(event.target.value)
  }
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    return setNewValue(event.target.value)
  }

  const resetNewField = () => {
    setNewKey('')
    setNewValue('')
  }

  const handleAddNewField = () => {
    if (key && value) {
      const camelCasedKey = _.camelCase(key)
      setNewField([camelCasedKey, value])
      resetNewField()
    } else {
      window.alert('Please fill in both key and value')
    }
  }

  const handleCancelNewField = () => {
    setIsAddingNewField(false)
    resetNewField()
  }

  return {
    inputs: { key, value },
    onChangeHandler: {
      key: handleKeyChange,
      value: handleValueChange,
    },
    onClickHandler: {
      cancelSection: handleCancelNewField,
      addField: handleAddNewField,
    },
  }
}
