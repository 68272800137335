import React from 'react'
import styled from '@emotion/styled'

import { Header } from 'frontend/components'
import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'

const TitleSection = styled.section({
  display: 'flex',
  justifyContent: 'space-between',
  padding: Spacing.S7,
})

export const DataTableHeader = ({
  header,
  children,
}: {
  header: string
  children?: any
}) => {
  return (
    <TitleSection>
      <Header header={header} headerStyle={{ ...FontSpace.FS5 }} />
      {children}
    </TitleSection>
  )
}
