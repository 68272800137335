import { getGqlDataKey } from 'frontend/utils'

import { LazyQueryResponse, UsePaginatedReadConfig } from '../../../../interfaces'

import getNextDataPage from './getNextDataPage'
import writeDataPageToCache from './writeDataPageToCache'

const writeLazyDataToCache = (
  paginatedReadConfig: UsePaginatedReadConfig,
  lazyQueryResponse: LazyQueryResponse,
) => {
  if (!paginatedReadConfig.isPaginated) return

  const { data } = lazyQueryResponse
  const dataKey = getGqlDataKey(data)
  const paginatedData = data[dataKey]
  const [{ results: dataPage, next }] = paginatedData

  writeDataPageToCache({ dataPage, dataKey }, paginatedReadConfig, lazyQueryResponse)

  if (next) getNextDataPage(next, lazyQueryResponse)
}

export default writeLazyDataToCache
