import React from 'react'

import { useQueryString, useRewriteSearchString } from 'frontend/hooks'

import ResourcesModal from './ResourcesModal'

const ResourcesModalContainer = () => {
  const rewriteSearchString = useRewriteSearchString()

  const { node, nodeType } = useQueryString()

  const closeModal = (): void => {
    rewriteSearchString({
      node: undefined,
      nodeType: undefined,
      indication: undefined,
    })
  }

  if (!node || !nodeType) return null

  return <ResourcesModal closeModal={closeModal} />
}

export default ResourcesModalContainer
