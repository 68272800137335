import styled from '@emotion/styled'

import Spacing from 'frontend/utils/spacing'

export const ListWrapper = styled.div(
  {
    height: '100%',
    overflowY: 'scroll',
    padding: Spacing.S4,
  },
)
