import gql from 'graphql-tag'

export const GET_PAYER_POLICY_LINKS_OPTIONS = gql`
  query GetPayerPolicyLinksOptions($input: PayerPolicyLinkOptionsInput!) {
    payerPolicyLinkOptions(input: $input) {
      benefit_type {
        options {
          label
          value
        }
        labelMap
      }
      doc_type {
        options {
          label
          value
        }
        labelMap
      }
    }
  }
`
