import sortText from './sortText'
import sortFloat from './sortFloat'
import sortBool from './sortBool'
import sortTextArray from './sortTextArray'
import sortDateColumn from './sortDateColumn'
import sortMappedValues from './sortMappedValues'

const customSortTypes = {
  //!Note: Missing cell values should end up at the bottom of the descending list
  text: sortText,
  float: sortFloat,
  bool: sortBool,
  textArray: sortTextArray,
  date: sortDateColumn,
}

export { customSortTypes as default, sortMappedValues }
