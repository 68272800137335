import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

const Tag = styled.span({
  background: '#E6E8EA',
  color: Colors.GRAY_600,
  fontSize: '11px',
  fontWeight: 600,
  lineHeight: '16px',
  marginRight: 6,
  padding: '2px 8px',
  borderRadius: 2,
})

const TeamTag = ({ data }: any) => <Tag>{data}</Tag>

export default TeamTag
