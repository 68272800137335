import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'
import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'
import Tooltip from 'frontend/components/Tooltip/Tooltip'

import { getPolicyStatus } from '../getPolicyStatus'
import { TooltipTable } from './TooltipTable'

const Wrapper = styled.div({
  display: 'flex',
})

const STATUS_TO_COLOR = {
  needs_approval: '#FF8B33',
  approved: Colors.GREEN,
  incorrect_policy: Colors.RED_800,
  not_applicable: Colors.GRAY_400,
}

const Count = styled.div((props: { status: keyof typeof STATUS_TO_COLOR }) => ({
  alignItems: 'center',
  background: STATUS_TO_COLOR[props.status],
  borderRadius: 2,
  color: Colors.WHITE,
  display: 'flex',
  fontSize: 9,
  fontWeight: 700,
  height: 10,
  marginLeft: 6,
  padding: '2px',
}))

const iconStyles = {
  width: 14,
  height: 14,
  marginRight: 0.5,
}

const POLICY_STATUS_TO_COMPONENT_MAP: any = {
  needs_approval: (
    <>
      <CustomSvgIcon
        iconName="Caution"
        iconColor={'#FF8B33'}
        styles={iconStyles}
      />
      Needs Approval
    </>
  ),
  approved: (
    <>
      <CustomSvgIcon
        iconName="CircleCheck"
        iconColor={Colors.GREEN}
        styles={iconStyles}
      />
      Approved
    </>
  ),
  incorrect_policy: (
    <>
      <CustomSvgIcon
        iconName="CircleX"
        iconColor={Colors.RED_800}
        styles={iconStyles}
      />
      Incorrect Policy
    </>
  ),
  not_applicable: (
    <>
      <CustomSvgIcon
        iconName="Error"
        iconColor={Colors.GRAY_400}
        styles={iconStyles}
      />
      N/A
    </>
  ),
}

const TOOLTIP_PROPS = {
  place: 'bottom',
  effect: 'solid',
}

export const PolicyStatus = ({ payerPolicyDocument }: any) => {
  const { id, products } = payerPolicyDocument
  const policyStatuses = products.map((product: any) => product.policy_status)

  const { policyStatus, count } = getPolicyStatus(policyStatuses)

  const shouldShowCount = count > 1
  const policyStatusComponent = POLICY_STATUS_TO_COMPONENT_MAP[policyStatus]

  return (
    <Tooltip
      id={`payer-policy-policy-status-tooltip-${id}`}
      text={TooltipTable({
        products,
        iconMap: POLICY_STATUS_TO_COMPONENT_MAP,
      })}
      {...TOOLTIP_PROPS}
    >
      <Wrapper>
        {policyStatusComponent}
        {shouldShowCount && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Count status={policyStatus}>{count}</Count>
          </div>
        )}
      </Wrapper>
    </Tooltip>
  )
}
