import { INPUT_TYPES } from 'frontend/components/Form/constants/input-type-to-component-map'

export const getFormConfig = () => {
  return [
    [
      {
        accessor: 'member_name',
        label: 'Person',
        inputType: INPUT_TYPES.TEXT,
        isDisabled: true,
      },
      {
        accessor: 'panel_name',
        label: 'NCCN Panel',
        inputType: INPUT_TYPES.TEXT,
        isDisabled: true,
      },
      {
        accessor: 'start_date',
        label: 'Start Date',
        inputType: INPUT_TYPES.DATE,
        isRequired: true,
      },
      {
        accessor: 'end_date',
        label: 'End Date',
        inputType: INPUT_TYPES.DATE,
      },
    ],
  ]
}
