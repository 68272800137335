import React, { Dispatch, SetStateAction } from 'react'

import { Description } from './Description'
import { Teams } from './Teams'

interface Props {
  selectedClientId: string
  setFieldState: Dispatch<SetStateAction<any>>
}

export const InnerContainer = ({ selectedClientId, setFieldState }: Props) => {
  if (!selectedClientId)
    return <Description selectedClientId={selectedClientId} />

  return (
    <Teams selectedClientId={selectedClientId} setFieldState={setFieldState} />
  )
}
