import styled from '@emotion/styled'
import { transparentize, mix } from 'polished'
import { Colors, Spacing } from '../../../utils/pulseStyles'

export const IndicationPanelContainer = styled.div({
  borderRight: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
  borderTop: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
  maxHeight: '78vh',
  overflow: 'auto',
  width: '100%',
  background: Colors.WHITE,
})

export const ListHeader = styled.div({
  fontSize: 14,
  fontWeight: 700,
  padding: `${Spacing.NORMAL} ${Spacing.LARGE}`,
  textTransform: 'uppercase',
})

export const ActiveRow = styled.div({
  background: mix(0.8, Colors.WHITE, Colors.GREEN),
  color: Colors.GREEN,
  fontSize: 10,
  fontWeight: 700,
  lineHeight: '18px',
  padding: '8px 24px',
  position: 'sticky',
  top: 0,
  zIndex: 5,
})

export const InactiveRow = styled.div({
  background: mix(0.8, Colors.WHITE, Colors.MEDIUM_GRAY_2),
  color: Colors.MEDIUM_GRAY_2,
  fontSize: 10,
  fontWeight: 700,
  lineHeight: '18px',
  padding: '8px 24px',
  position: 'sticky',
  top: 0,
  zIndex: 5,
})

export const UnorderedList = styled.ul({
  padding: `0 ${Spacing.NORMAL}`,
})

export const ListRow = styled.li(
  {
    alignItems: 'center',
    borderBottom: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
    borderRadius: 4,
    color: Colors.BLACK,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
    padding: `${Spacing.TINY} 0`,
  },
  ({ isSelected, isActive }) => {
    return {
      backgroundColor: isSelected
        ? transparentize(0.9, Colors.PRIMARY)
        : 'transparent',
      color: isActive && isSelected ? Colors.PRIMARY : Colors.BLACK,
      ':hover': {
        backgroundColor: isSelected
          ? transparentize(0.7, Colors.MEDIUM_GRAY_2)
          : transparentize(0.92, Colors.BLACK),
      },
    }
  }
)

export const Span = styled.span(
  {
    maxWidth: '75%',
    wordBreak: 'break-word',
  },
  ({ isSelected }) => {
    return {
      fontWeight: isSelected ? 700 : 500,
      color: isSelected ? Colors.BLUE : 'black,',
    }
  }
)

export const SelectedHeader = styled.span({
  color: Colors.PRIMARY,
  textTransform: 'normal',
})
