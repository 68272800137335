import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'

import { GET_SOURCE_INDICATIONS } from 'frontend/api/queries'
import { useQueryString } from 'frontend/hooks'
import { Colors } from 'frontend/utils/pulseStyles'

const Wrapper = styled.span({
  color: Colors.PRIMARY,
})

const IndicationLabel = () => {
  const { indication }: any = useQueryString()

  const { data: { indications } } = useQuery(GET_SOURCE_INDICATIONS)

  const indicationIdToLabel = useMemo(() => {
    return indications.reduce((
      (acc: { [key: string]: string }, { _id, name }: any) => {
        acc[_id] = name
        return acc
      }
    ), {})
  }, [indications])

  const label = indicationIdToLabel[indication]

  return (
    <Wrapper>
      {label}
    </Wrapper>
  )
}

export default IndicationLabel
