import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { ParentType } from '../../../../types'
import ParentTitle from './ParentTitle'

interface ContainerProps {
  parentType?: ParentType
}

const Container = ({
  parentType,
}: ContainerProps) => {
  const location = useLocation()
  const queryStringObj = queryString.parse(location.search)

  if (!parentType || !queryStringObj[parentType]) return null

  return (
    <ParentTitle
      parentType={parentType}
    />
  )
}

export default Container
