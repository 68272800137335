import React from 'react'
import queryString from 'query-string'
import { useLocation, useParams } from 'react-router-dom'

import StructuralListPanels from 'frontend/components/StructuralListPanels'

import SwitchListItem from './shared/SwitchListItem'
import getMarketBasketsWithSubscriptionStatuses from './utils/getMarketBasketsWithSubscriptionStatuses'
import useMarketBasketSubscriptionData from './utils/useMarketBasketSubscriptionData'
import handleMarketBasketToggle from './utils/handleMarketBasketToggle'
import handleMarketBasketCategoryToggle from './utils/handleMarketBasketCategoryToggle'
import useCategorySubscriptionData from './utils/useCategorySubscriptionData'
import MARKET_BASKET_PANEL_CONFIG, {
  PANEL_HEIGHT,
} from './market-basket-panel-config'

const SubscriptionPanels = () => {
  const location = useLocation()
  const { clientTeamId } = useParams()
  const { marketBasketId, categoryId } = queryString.parse(location.search)

  const {
    marketBaskets,
    marketBasketSubscriptions,
  } = useMarketBasketSubscriptionData(clientTeamId)

  const mbSubscriptionsStatus = getMarketBasketsWithSubscriptionStatuses(
    marketBaskets.data,
    marketBasketSubscriptions.data
  )

  const isMarketBasketPanelLoading =
    marketBaskets.loading || marketBasketSubscriptions.loading

  const areTogglesDisabled =
    marketBasketSubscriptions.isSubbing || marketBasketSubscriptions.isUnsubbing

  addFirstPanelDataAndListItem({
    mbSubscriptionsStatus,
    subscriptions: marketBasketSubscriptions.data,
    subscribeToMarketBasket: marketBasketSubscriptions.subscribe,
    unsubscribeToMarketBasket: marketBasketSubscriptions.unsubscribe,
    clientTeamId,
    marketBasketId,
    areTogglesDisabled,
    isMarketBasketPanelLoading,
  })

  const {
    market_basket_subscription,
    selectedMarketBasketSubCategories,
    categorySubsMap,
    isLoadingCategorySubs,
    subscribeToMarketBasketCategory,
    isSubbing,
    unsubscribeToMarketBasketCategory,
    isUnsubbing,
  } = useCategorySubscriptionData(marketBasketId, mbSubscriptionsStatus)

  addSecondCategoryPanelDataAndListItem({
    market_basket_subscription,
    selectedMarketBasketSubCategories,
    categorySubsMap,
    categoryId,
    areTogglesDisabled,
    isLoadingCategorySubs,
    subscribeToMarketBasketCategory,
    isSubbing,
    unsubscribeToMarketBasketCategory,
    isUnsubbing,
  })

  return (
    <div style={{ display: 'flex', height: PANEL_HEIGHT }}>
      <StructuralListPanels
        panels={MARKET_BASKET_PANEL_CONFIG}
        searchParamsAncestry={['tab']}
      />
    </div>
  )
}

export default SubscriptionPanels

const addFirstPanelDataAndListItem = ({
  mbSubscriptionsStatus,
  subscriptions,
  subscribeToMarketBasket,
  unsubscribeToMarketBasket,
  clientTeamId,
  marketBasketId,
  areTogglesDisabled,
  isMarketBasketPanelLoading,
}) => {
  MARKET_BASKET_PANEL_CONFIG[0].data = mbSubscriptionsStatus
  MARKET_BASKET_PANEL_CONFIG[0].loading = isMarketBasketPanelLoading

  const handleMarketBasketSwitchToggle = (e) => {
    handleMarketBasketToggle(e, {
      subscriptions,
      subscribeToMarketBasket,
      unsubscribeToMarketBasket,
      clientTeamId,
    })
  }

  MARKET_BASKET_PANEL_CONFIG[0].listConfig = {
    ListItem: ({ data: { id, isSubscribed, name }, handleClick }) => {
      const isSelected = id === marketBasketId

      const listItemHandleClick = isSelected
        ? () => null
        : () => handleClick(id)

      return (
        <SwitchListItem
          key={id}
          onClick={listItemHandleClick}
          isDisabled={areTogglesDisabled}
          handleToggle={handleMarketBasketSwitchToggle}
          isChecked={isSubscribed}
          value={id}
          label={name}
          isSelected={isSelected}
        />
      )
    },
  }
}

const addSecondCategoryPanelDataAndListItem = ({
  market_basket_subscription,
  selectedMarketBasketSubCategories,
  subscribeToMarketBasketCategory,
  unsubscribeToMarketBasketCategory,
  categorySubsMap,
  categoryId,
  areTogglesDisabled,
  isLoadingCategorySubs,
  isSubbing,
  isUnsubbing,
}) => {
  const areCategoryTogglesDisabled = isSubbing || isUnsubbing

  if (market_basket_subscription) {
    MARKET_BASKET_PANEL_CONFIG[1].data = (
      selectedMarketBasketSubCategories || []
    ).map((category) => ({
      ...category,
      isSubscribed: Boolean(categorySubsMap[category.id]),
    }))
  } else {
    MARKET_BASKET_PANEL_CONFIG[1].data = []
  }

  MARKET_BASKET_PANEL_CONFIG[1].loading = isLoadingCategorySubs

  MARKET_BASKET_PANEL_CONFIG[1].listConfig = {
    ListItem: ({ data: { id, isSubscribed, name }, handleClick }) => {
      const isSelected = id === categoryId

      const listItemHandleClick = isSelected
        ? () => null
        : () => handleClick(id)

      const handleMarketBasketCategorySwitchToggle = (e) => {
        handleMarketBasketCategoryToggle(e, {
          market_basket_subscription,
          category_sub_id: categorySubsMap[id]?.id,
          subscribeToMarketBasketCategory,
          unsubscribeToMarketBasketCategory,
        })
      }

      return (
        <SwitchListItem
          key={id}
          onClick={listItemHandleClick}
          isDisabled={areTogglesDisabled || areCategoryTogglesDisabled}
          handleToggle={handleMarketBasketCategorySwitchToggle}
          isChecked={isSubscribed}
          value={id}
          label={name}
          isSelected={isSelected}
        />
      )
    },
  }
}
