import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'

import { Button } from '@pulse-analytics/pulse-design-system'

import { GET_VEGA_FDA_INDICATION_REGIMEN_APPROVALS } from 'frontend/api/queries'
import { DELETE_VEGA_FDA_INDICATION_REGIMEN_APPROVAL } from 'frontend/api/mutations'

import Spinner from 'frontend/components/Spinner'
import { SingleActionDialog } from 'frontend/components/Dialog'

import Color from 'frontend/utils/color'
import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'

const DeleteFdaApproval = ({ fdaApprovalId, closeHandler }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [deleteFdaApproval, { loading: isFdaApprovalLoading }] = useMutation(
    DELETE_VEGA_FDA_INDICATION_REGIMEN_APPROVAL,
    {
      variables: {
        input: {
          id: fdaApprovalId,
        },
      },
      update: (cache) => {
        const { vegaFdaIndicationRegimenApprovals } = cache.readQuery({
          query: GET_VEGA_FDA_INDICATION_REGIMEN_APPROVALS,
        })
        const newFdaApprovals = vegaFdaIndicationRegimenApprovals.filter(
          ({ id }) => id !== fdaApprovalId
        )
        cache.writeQuery({
          query: GET_VEGA_FDA_INDICATION_REGIMEN_APPROVALS,
          data: {
            vegaFdaIndicationRegimenApprovals: newFdaApprovals,
          },
        })
      },
      onError: alert,
      onCompleted: () => {
        closeHandler()
      },
    }
  )

  return (
    <section>
      <h4 style={{ paddingBottom: Spacing.FS4, ...FontSpace.FS5 }}>
        Delete FDA Approval
      </h4>
      <p style={FontSpace.FS2}>
        Deleting an FDA Approval removes all data associated with it and can’t
        be undone.
      </p>
      <Button
        color={Color.RED}
        onClick={() => setIsModalOpen(true)}
        style={{
          padding: `${Spacing.S2} ${Spacing.S3}`,
          margin: `${Spacing.S4} 0`,
        }}
      >
        Delete FDA Approval
      </Button>
      {isModalOpen && (
        <SingleActionDialog
          header="Delete FDA Approval"
          submitText="Delete Forever"
          submitHandler={deleteFdaApproval}
          cancelHandler={() => setIsModalOpen(false)}
          headerStyle={{ color: Color.RED }}
          submitColor={Color.RED}
          contentStyle={{ width: 450 }}
        >
          {isFdaApprovalLoading ? (
            <Spinner />
          ) : (
            <div style={{ padding: 36, textAlign: 'center', ...FontSpace.FS3 }}>
              <p>Are you sure you want to delete the FDA Approval?</p>
              <p style={{ fontWeight: 700, marginTop: 12 }}>
                THIS CANNOT BE UNDONE
              </p>
            </div>
          )}
        </SingleActionDialog>
      )}
    </section>
  )
}

DeleteFdaApproval.propTypes = {
  fdaApprovalId: PropTypes.string,
  closeHandler: PropTypes.func.isRequired,
}

export default DeleteFdaApproval
