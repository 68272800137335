import React from 'react'
import _ from 'lodash'
import { ObjectId } from 'bson'

import {
  DEPRECATED_CREATE_PERSON,
  DEPRECATED_UPDATE_PERSON,
  DEPRECATED_DELETE_PERSON,
} from 'frontend/api/mutations'

import {
  DEPRECATED_GET_PEOPLE,
  GET_JOIN_OBMS_AND_PEOPLE,
  GET_VIEW_OBM_INFLUENCERS,
  GET_VIEW_LBM_INFLUENCERS,
  GET_EVENTS,
  GET_MARKET_BASKETS_SURVEYS_STAKEHOLDERS,
} from 'frontend/api/queries'

import GET_PEOPLE_HISTORY from 'frontend/api/usePeople/usePeopleHistory/useRead/gql-tag'
import { GET_PEOPLE as GET_PEOPLE_V1 } from 'frontend/entity-hooks/usePerson/gql-tags'
import useMongoVegaBridge from 'frontend/hooks/useMongoVegaBridge'

import BusinessObjectModal from '../BusinessObjectModal/BusinessObjectModal'
import PathwaysInfluencerWidget from './widgets/external-data-widgets/PathwaysInfluencerWidget'
import OrganizationConnectionsWidget from './widgets/relational-widgets/OrganizationConnectionsWidget'
import ProviderInfluencerWidget from './widgets/external-data-widgets/ProviderInfluencerWidget'
import PhysiciansCompareWidget from './widgets/external-data-widgets/PhysiciansCompareWidget'
import OpenPaymentsWidget from './widgets/external-data-widgets/OpenPaymentsWidget'
import PeopleHistoryList from './widgets/PeopleHistoryList'

const WIDGETS = [
  {
    _id: 'RELATIONAL_organizationConnectionsWidget',
    label: 'Organization Connections',
    Component: OrganizationConnectionsWidget,
  },
  {
    _id: 'RELATIONAL_pathwaysInfluencerWidget',
    label: 'Pathways Influencers Sheet Data',
    Component: PathwaysInfluencerWidget,
  },
  {
    _id: 'RELATIONAL_providerInfluencerWidget',
    label: 'Provider KDM Sheet Data',
    Component: ProviderInfluencerWidget,
  },
  {
    _id: 'RELATIONAL_physiciansCompareWidget',
    label: 'Physicians Compare',
    Component: PhysiciansCompareWidget,
  },
  {
    _id: 'RELATIONAL_externalSource2Widget',
    label: 'Open Payments',
    Component: OpenPaymentsWidget,
  },
  {
    _id: 'RELATIONAL_historyWidget',
    label: 'History',
    Component: PeopleHistoryList,
  },
]

const PEOPLE_BOID = '5eea22d5adbf920fa4320487'
const HEADER_TEXT = 'People'

const PeopleModal = ({
  closeModal,
  entityId,
  vegaId,
  refetchQueries,
  afterMutationHook,
}) => (
  <BusinessObjectModal
    closeModal={closeModal}
    entityId={entityId}
    boId={PEOPLE_BOID}
    headerText={HEADER_TEXT}
    getEntityTitle={(entity) => `${entity.firstName} ${entity.lastName}`}
    mutationDocs={{
      create: DEPRECATED_CREATE_PERSON,
      update: DEPRECATED_UPDATE_PERSON,
      delete: DEPRECATED_DELETE_PERSON,
    }}
    refetchQueries={[
      ...refetchQueries,
      { query: GET_PEOPLE_V1, variables: { input: { version: 'v1' } } },
      { query: DEPRECATED_GET_PEOPLE },
      { query: GET_JOIN_OBMS_AND_PEOPLE },
      { query: GET_VIEW_OBM_INFLUENCERS },
      { query: GET_VIEW_LBM_INFLUENCERS },
      { query: GET_EVENTS },
      { query: GET_MARKET_BASKETS_SURVEYS_STAKEHOLDERS },
      ...getPeopleHistoryRefetchQuery(vegaId),
    ]}
    afterMutationHook={afterMutationHook}
    widgets={WIDGETS}
  />
)

PeopleModal.defaultProps = {
  refetchQueries: [],
}

const PeopleModalContainer = (props) => {
  const queryObject = ObjectId.isValid(props.entityId)
    ? { mongoId: props.entityId }
    : { vegaId: props.entityId }
  const { data, loading } = useMongoVegaBridge(queryObject)
  let vegaId
  let entityId = _.cloneDeep(props.entityId)
  if (!loading) {
    vegaId = data[0]?.vegaId
    entityId = data[0]?.mongoId
  } else {
    return <></>
  }

  return <PeopleModal {...props} entityId={entityId} vegaId={vegaId} />
}

export default PeopleModalContainer

const getPeopleHistoryRefetchQuery = (vegaId) => {
  if (vegaId)
    return [{ query: GET_PEOPLE_HISTORY, variables: { input: { id: vegaId } } }]
  else return []
}
