import React from 'react'
import { Button } from '@pulse-analytics/pulse-design-system'

import { useRewriteSearchString } from 'frontend/hooks'

import { LABEL } from './constants'
import Dialog from './Dialog'

const RemoveFromSitemapsButton = () => {
  const rewriteSearchString = useRewriteSearchString()

  const addSearchStringArguments = () => {
    rewriteSearchString({
      removeFromSitemaps: 'true',
    })
  }

  return (
    <>
      <Dialog />
      <Button
        onClick={addSearchStringArguments}
        style={{
          marginBottom: '24px',
          padding: '7px 8px',
        }}
      >
        {LABEL}
      </Button>
    </>
  )
}

export default RemoveFromSitemapsButton
