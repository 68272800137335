import React from 'react'

import Icon from 'frontend/components/Icon'
import Color from 'frontend/utils/color'

const DEFAULT_PROPS = {
  ICON_NAME: 'expand',
  STYLE: { display: 'flex' },
  COLOR_1: Color.PRIMARY,
  COLOR_2: Color.PRIMARY,
  WIDTH: 14,
  HEIGHT: 14,
}

export const ExpandIcon = () => {
  return (
    <Icon
      style={DEFAULT_PROPS.STYLE}
      width={DEFAULT_PROPS.WIDTH}
      height={DEFAULT_PROPS.HEIGHT}
      iconName={DEFAULT_PROPS.ICON_NAME}
      color1={DEFAULT_PROPS.COLOR_1}
      color2={DEFAULT_PROPS.COLOR_2}
    />
  )
}
