import { Options } from '../../interfaces'

export const prependNewEntityToCache = ({
  cache,
  data,
  readTag,
  variables,
}: Options) => {
  if (!readTag) return

  const createEntity = Object.values(data.data)[0]

  const query = cache.readQuery({
    query: readTag,
    variables,
  })
  // only prepend if the cache exists
  if (!query) return
  const queryName = Object.keys(query)[0]

  cache.writeQuery({
    query: readTag,
    variables,
    data: {
      [queryName]: [createEntity, ...query[queryName]],
    },
  })
}
