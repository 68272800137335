import { getPolicyDetailsColumns } from './getPolicyDetailsColumns'
import { getLinkAutomationDetailsColumns } from './getLinkAutomationDetailsColumns'

export const getColumnConfig = ({ setModalType }) => {
  return [
    {
      Header: 'Policy Details',
      id: 'Policy Details',
      columns: getPolicyDetailsColumns({ setModalType }),
    },
    {
      Header: 'Link/Automation Details',
      id: 'Link/Automation Details',
      columns: getLinkAutomationDetailsColumns({ setModalType }),
    },
  ]
}
