import React from 'react'
import styled from '@emotion/styled'

import Color from 'frontend/utils/color'

import Header from './Header'
import NodesListPanel from './NodesListPanel'
import { ParentType, Type } from '../../types'

interface Props {
  type: Type
  parentType?: ParentType
}

const Wrapper = styled.div({
  flex: 1,
}, ({ type }: Props) => ({
  borderRight: type !== 'card' ?
    `2px solid ${Color.GRAY_LIGHT}` :
    undefined,
}))

const NodesPanel = ({
  ...props
}: Props) => (
  <Wrapper
    {...props}
  >
    <Header
      {...props}
    />
    <NodesListPanel
      {...props}
    />
  </Wrapper>
)

export default NodesPanel
