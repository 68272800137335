import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import IndicationsPanel from './IndicationsPanel'
import RegimensPanel from './RegimensPanel'
import PopulationsPanel from './PopulationsPanel'
import LineOfTherapiesPanel from './LineOfTherapiesPanel'
import { ifSettingAllowed, PRIVILEGES } from '../toolPrivileges'

const TreatmentPlansTabContent = ({
  activeTool,
  baseTreatmentPlans,
  treatmentPlans,
  setStagedTreatmentPlans,
}) => {
  const defaultSelectedIndication = () => {
    const hasSavedConfiguration = treatmentPlans.length > 0
    if (hasSavedConfiguration) {
      return treatmentPlans[0]
    } else {
      return baseTreatmentPlans[0]
    }
  }

  const toolName = activeTool.label.toLowerCase()

  const [selectedIndication, setSelectedIndication] = useState(
    defaultSelectedIndication
  )
  const [selectedRegimen, setSelectedRegimen] = useState(null)

  const [selectedPopulation, setSelectedPopulation] = useState(null)

  const [selectedLine, setSelectedLine] = useState(null)

  const isPopulationPanelAllowed = () => {
    return ifSettingAllowed(toolName, PRIVILEGES.POPULATIONS)
  }

  const isRegimenPanelAllowed = () => {
    return ifSettingAllowed(toolName, PRIVILEGES.REGIMENS)
  }
  const isLineOfTherapyPanelAllowed = () => {
    return ifSettingAllowed(toolName, PRIVILEGES.LINE_OF_THERAPIES)
  }

  const enabledTreatmentPlansHash = _.mapValues(
    _.keyBy(treatmentPlans, '_id'),
    ({ regimens }) => {
      if (isPopulationPanelAllowed()) {
        return _.mapValues(_.keyBy(regimens, '_id'), ({ populations }) => {
          if (isLineOfTherapyPanelAllowed()) {
            return _.mapValues(_.keyBy(populations, '_id'), ({ lines }) => {
              return _.keyBy(lines, '_id')
            })
          } else return _.keyBy(populations, '_id')
        })
      } else {
        return _.keyBy(regimens, '_id')
      }
    }
  )

  const getSelectedId = (type) => {
    switch (type) {
      case 'indication':
        return _.size(selectedIndication) > 0 ? selectedIndication._id : null
      case 'regimen':
        return _.size(selectedRegimen) > 0 ? selectedRegimen._id : null
      case 'population':
        return _.size(selectedPopulation) > 0 ? selectedPopulation._id : null
      case 'line':
        return _.size(selectedLine) > 0 ? selectedLine._id : null
      default:
        return null
    }
  }

  let [enabledTreatmentPlans, disabledTreatmentPlans] = _.partition(
    baseTreatmentPlans,
    ({ _id }) => enabledTreatmentPlansHash[_id]
  )

  enabledTreatmentPlans = _.cloneDeep(enabledTreatmentPlans)

  enabledTreatmentPlans = _.sortBy(enabledTreatmentPlans, [
    ({ _id }) =>
      treatmentPlans.findIndex(({ _id: indicationId }) => indicationId === _id),
  ])

  enabledTreatmentPlans.forEach((indicationObj) => {
    indicationObj.regimens = indicationObj.regimens.filter(({ _id }) => {
      return enabledTreatmentPlansHash[indicationObj._id][_id]
    })

    if (isPopulationPanelAllowed()) {
      indicationObj.regimens.forEach((regimenObj) => {
        regimenObj.populations = regimenObj.populations.filter(({ _id }) => {
          return enabledTreatmentPlansHash[indicationObj._id][regimenObj._id][
            _id
          ]
        })

        if (isLineOfTherapyPanelAllowed()) {
          regimenObj.populations.forEach((populationsObj) => {
            populationsObj.lines = populationsObj.lines.filter(({ _id }) => {
              return enabledTreatmentPlansHash[indicationObj._id][
                regimenObj._id
              ][populationsObj._id][_id]
            })
          })
        }
      })
    }
    const indicationObjFromTeam = treatmentPlans.find(
      ({ _id }) => _id === indicationObj._id
    )
    const regimensFromTeam = indicationObjFromTeam.regimens

    indicationObj.regimens = _.sortBy(indicationObj.regimens, [
      ({ _id }) =>
        regimensFromTeam.findIndex(({ _id: regimenId }) => regimenId === _id),
    ])
  })

  const getFilteredTreatmentPlans = (indicationId) => {
    const id = indicationId || selectedIndication._id
    const baseTreatmentPlan = baseTreatmentPlans.find(
      (treatmentPlanObj) => treatmentPlanObj._id === id
    )
    const activeTreatmentPlan = enabledTreatmentPlans.find(
      (treatmentPlan) => treatmentPlan._id === id
    )
    return { baseTreatmentPlan, activeTreatmentPlan }
  }

  const getFilteredTreatmentPlanRegimens = (indicationId) => {
    const {
      baseTreatmentPlan,
      activeTreatmentPlan,
    } = getFilteredTreatmentPlans(indicationId)
    const hasActiveRegimen = Boolean(
      _.size(activeTreatmentPlan) > 0 &&
        activeTreatmentPlan.hasOwnProperty('regimens') &&
        activeTreatmentPlan.regimens.length > 0
    )

    const hasBaseRegimen = Boolean(
      _.size(baseTreatmentPlan) > 0 &&
        baseTreatmentPlan.hasOwnProperty('regimens') &&
        baseTreatmentPlan.regimens.length > 0
    )
    const activeRegimens = hasActiveRegimen ? activeTreatmentPlan.regimens : []
    const baseRegimens = hasBaseRegimen ? baseTreatmentPlan.regimens : []
    return { activeRegimens, baseRegimens }
  }

  const getFilteredTreatmentPlanPopulations = (regimenId) => {
    const id = regimenId || selectedRegimen._id
    const { activeRegimens, baseRegimens } = getFilteredTreatmentPlanRegimens()

    const activeSelectedRegimen = activeRegimens.find(
      (regimen) => regimen._id === id
    )

    const baseSelectedRegimen = baseRegimens.find(
      (regimen) => regimen._id === id
    )

    const hasActivePopulations =
      _.size(activeSelectedRegimen) &&
      activeSelectedRegimen.hasOwnProperty('populations') &&
      activeSelectedRegimen.populations.length > 0

    const hasBasePopulations =
      _.size(baseSelectedRegimen) &&
      baseSelectedRegimen.hasOwnProperty('populations') &&
      baseSelectedRegimen.populations.length > 0

    const activePopulations = hasActivePopulations
      ? activeSelectedRegimen.populations
      : []
    const basePopulations = hasBasePopulations
      ? baseSelectedRegimen.populations
      : []

    return { activePopulations, basePopulations }
  }

  const getDefaultRegimenByIndication = (indicationId) => {
    let defaultRegimen = null
    const { activeRegimens, baseRegimens } = getFilteredTreatmentPlanRegimens(
      indicationId
    )
    if (activeRegimens.length > 0) defaultRegimen = activeRegimens[0]
    else if (baseRegimens.length > 0) defaultRegimen = baseRegimens[0]
    return defaultRegimen
  }

  const getDefaultPopulationByRegimen = (regimenId) => {
    let defaultPopulation = null
    const {
      activePopulations,
      basePopulations,
    } = getFilteredTreatmentPlanPopulations(regimenId)

    if (activePopulations.length > 0) defaultPopulation = activePopulations[0]
    else if (basePopulations.length > 0) defaultPopulation = basePopulations[0]
    return defaultPopulation
  }

  const getDefaultLineByPopulation = (populationId) => {
    const {
      activePopulations,
      basePopulations,
    } = getFilteredTreatmentPlanPopulations()

    const activeSelectedPopulation = activePopulations.find(
      (population) => population._id === populationId
    )

    const baseSelectedPopulation = basePopulations.find(
      (population) => population._id === populationId
    )

    const hasActiveLines =
      _.size(activeSelectedPopulation) &&
      activeSelectedPopulation.hasOwnProperty('lines') &&
      activeSelectedPopulation.lines.length > 0

    if (hasActiveLines) {
      return activeSelectedPopulation.lines[0]
    } else {
      return _.size(baseSelectedPopulation) &&
        baseSelectedPopulation.hasOwnProperty('lines')
        ? baseSelectedPopulation.lines[0]
        : null
    }
  }

  useEffect(() => {
    if (isRegimenPanelAllowed()) {
      const defaultRegimen = getDefaultRegimenByIndication(
        selectedIndication._id
      )
      setSelectedRegimen(defaultRegimen)
    }
  }, [selectedIndication])

  useEffect(() => {
    if (isPopulationPanelAllowed() && _.size(selectedRegimen)) {
      const defaultPopulation = getDefaultPopulationByRegimen(
        selectedRegimen._id
      )
      setSelectedPopulation(defaultPopulation)
    }
  }, [selectedRegimen])

  useEffect(() => {
    if (isLineOfTherapyPanelAllowed() && _.size(selectedPopulation)) {
      const defaultLine = getDefaultLineByPopulation(selectedPopulation._id)
      setSelectedLine(defaultLine)
    }
  }, [selectedPopulation])

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <IndicationsPanel
          selectedIndicationId={getSelectedId('indication')}
          selectIndication={setSelectedIndication}
          enabledTreatmentPlans={enabledTreatmentPlans}
          disabledTreatmentPlans={disabledTreatmentPlans}
          setStagedTreatmentPlans={setStagedTreatmentPlans}
        />
        {isRegimenPanelAllowed() && (
          <>
            <div style={{ flex: 1 }} />
            <RegimensPanel
              selectedIndicationId={getSelectedId('indication')}
              selectedRegimenId={getSelectedId('regimen')}
              selectRegimen={setSelectedRegimen}
              baseTreatmentPlans={baseTreatmentPlans}
              setStagedTreatmentPlans={setStagedTreatmentPlans}
              enabledTreatmentPlansHash={enabledTreatmentPlansHash}
              enabledTreatmentPlans={enabledTreatmentPlans}
            />
          </>
        )}
        {isPopulationPanelAllowed() && (
          <>
            <div style={{ flex: 1 }} />
            <PopulationsPanel
              selectedIndicationId={getSelectedId('indication')}
              selectedRegimenId={getSelectedId('regimen')}
              selectedPopulationId={getSelectedId('population')}
              selectPopulation={setSelectedPopulation}
              baseTreatmentPlans={baseTreatmentPlans}
              setStagedTreatmentPlans={setStagedTreatmentPlans}
              enabledTreatmentPlans={enabledTreatmentPlans}
              enabledTreatmentPlansHash={enabledTreatmentPlansHash}
            />
          </>
        )}

        {isLineOfTherapyPanelAllowed() && (
          <LineOfTherapiesPanel
            selectedIndicationId={getSelectedId('indication')}
            selectedRegimenId={getSelectedId('regimen')}
            selectedPopulationId={getSelectedId('population')}
            selectedLineId={getSelectedId('line')}
            selectLine={setSelectedLine}
            baseTreatmentPlans={baseTreatmentPlans}
            setStagedTreatmentPlans={setStagedTreatmentPlans}
            enabledTreatmentPlans={enabledTreatmentPlans}
            enabledTreatmentPlansHash={enabledTreatmentPlansHash}
          />
        )}
      </div>
    </div>
  )
}

export default TreatmentPlansTabContent
