import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { DeleteDialog } from 'frontend/components'
import { useAlternativePaymentModelOrganizations } from 'frontend/entity-hooks'
import { useRewriteSearchString } from 'frontend/hooks'

const LABEL = 'Delete Alternative Payment Model'

const SEARCH_STRING_PARAMS = [
  'deleteAlternativePaymentModel',
  'alternativePaymentModelId',
]

const DeleteDialogAlternativePaymentModelOrganization = () => {
  const { search } = useLocation()

  const rewriteSearchString = useRewriteSearchString()

  const {
    data,
    delete: destroy,
    isDeleting,
    isLoading,
  } = useAlternativePaymentModelOrganizations()

  const { alternativePaymentModelId } = queryString.parse(search)

  const alternativePaymentModel = data
    ? data.find((apm) => apm.id === alternativePaymentModelId)
    : undefined

  const cancel = () => {
    const deleteSearchArgs = SEARCH_STRING_PARAMS.reduce((acc, param) => {
      acc[param] = undefined
      return acc
    }, {})
    rewriteSearchString(deleteSearchArgs)
  }

  const handleDelete = () => {
    const input = { id: alternativePaymentModel.id }
    destroy({ variables: { input } })
    cancel()
  }

  const entityDescription = alternativePaymentModel
    ? alternativePaymentModel.name
    : ''

  return (
    <DeleteDialog
      isDeleting={isDeleting}
      isLoading={isLoading}
      entityDescription={entityDescription}
      handleDelete={handleDelete}
      label={LABEL}
      searchStringParams={SEARCH_STRING_PARAMS}
    />
  )
}

export default DeleteDialogAlternativePaymentModelOrganization
