import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import _ from 'lodash'

import { GET_COMMUNITY_PRACTICE_NETWORKS } from 'frontend/api/queries'

import Spinner from 'frontend/components/Spinner'
import DataTable from 'frontend/components/DataTable'
import MultiSelectColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/MultiSelectColumnFilter'
import customMultiSelectFilterFn from 'frontend/components/Table/custom-filters/MultiSelect/customMultiSelectFilterFn'
import { EXPAND } from 'frontend/components/DataTable/Cells/cellTypes'

import EditCpnForm from './EditCpnForm'

const CpnTable = () => {
  const [selectedRowId, setSelectedRowId] = useState(null)
  const { data, loading } = useQuery(GET_COMMUNITY_PRACTICE_NETWORKS)

  const onRowClick = (row) => {
    const { original } = row
    setSelectedRowId(original.id)
  }
  const tableData = !loading ? data.vegaCommunityPracticeNetworks : []

  let selectedCpnData = {}
  if (selectedRowId) {
    const rowData = data.vegaCommunityPracticeNetworks.find(
      ({ id }) => id === selectedRowId
    )
    if (rowData) selectedCpnData = rowData
  }

  const closeHandler = () => {
    setSelectedRowId(null)
  }

  const COLUMNS = [
    {
      Header: 'ID',
      accessor: 'id',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
    },
    {
      Header: 'Name',
      accessor: 'name',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
  ]

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <DataTable data={tableData} columns={COLUMNS} />
          {!_.isEmpty(selectedCpnData) && (
            <EditCpnForm
              selectedCpnData={selectedCpnData}
              closeHandler={closeHandler}
            />
          )}
        </>
      )}
    </>
  )
}

export default CpnTable
