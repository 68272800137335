import { CrudHookOptions } from '../utils/useCrudHookApi/interfaces'
import { useCrudHookApi } from '../utils/useCrudHookApi'
import { getPayerPolicyTrackingProjectConfig } from './getPayerPolicyTrackingProjectConfig'

export const usePayerPolicyTrackingProjects = ({
  deleteOptions = {},
  queryInput = {},
}: CrudHookOptions = {}) => {
  const config = getPayerPolicyTrackingProjectConfig({
    deleteOptions,
    queryInput,
  })
  return useCrudHookApi(config)
}
