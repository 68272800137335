import React from 'react'

import { useQueryString } from 'frontend/hooks'

import NodeLabel from './NodeLabel'

const Container = () => {
  const { node, nodeType } = useQueryString()

  if (!node) return (
    <>
      {nodeType}
    </>
  )

  return (
    <NodeLabel />
  )
}

export default Container
