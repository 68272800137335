import React, { useRef } from 'react'
import styled from '@emotion/styled'

import { Content } from './Content'
import { Header } from './Header'

const Wrapper = styled.div({
  width: 512,
  height: 601,
})

export const NewPolicy = () => {
  const inputRef: any = useRef({})

  const setInputRef = ({ name, value }: any) => {
    if (Array.isArray(value)) {
      inputRef.current[name] = value.map(
        ({ value: productId }: any) => productId
      )
    } else inputRef.current[name] = value
  }

  return (
    <Wrapper>
      <Header fieldState={inputRef.current} />
      <Content onChange={setInputRef} />
    </Wrapper>
  )
}
