import { CrudHookOptions } from 'frontend/interfaces/CrudHookOptions'
import * as CRUD_UTILS from 'frontend/api/utils'

import { getNccnPanelVotingMemberConfigs } from './getNccnPanelVotingMemberConfigs'
import { NccnPanelVotingMemberQueryResponse, UseNccnPanelVotingMemberData } from './interfaces'

export const useNccnPanelVotingMember = ({
  deleteOptions,
  queryInput,
}: CrudHookOptions = {}) => {
  const {
    createConfig,
    deleteConfig,
    updateConfig,
    readConfig,
  } = getNccnPanelVotingMemberConfigs(deleteOptions, queryInput)

  const { data: dataResponse, loading: isLoading }: NccnPanelVotingMemberQueryResponse = CRUD_UTILS.useRead(readConfig)

  const [create, { loading: isCreating }] = CRUD_UTILS.useCreate(createConfig)
  const [destroy, { loading: isDeleting }] = CRUD_UTILS.useDelete(deleteConfig)
  const [update, { loading: isUpdating }] = CRUD_UTILS.useUpdate(updateConfig)

  const data: UseNccnPanelVotingMemberData = dataResponse ? Object.values(dataResponse)[0] : []

  return {
    data,
    create,
    isCreating,
    destroy,
    isDeleting,
    update,
    isUpdating,
    isLoading,
  }
}
