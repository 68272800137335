import { useRewriteSearchString } from 'frontend/hooks'

export const useAddDeleteSearchString = () => {
  const rewriteSearchString = useRewriteSearchString()

  const addDeleteSearchString = ({ id }: any) => {
    const deleteSearchArgs = {
      deleteSecondaryOrganizationPerson: true,
      secondaryOrganizationPersonId: id,
    }
    rewriteSearchString(deleteSearchArgs)
  }

  return addDeleteSearchString
}
