import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import _ from 'lodash'

import { CreateModalProps } from '../interfaces'
import CreateModal from './CreateModal'

const CreateModalContainer = ({ entity, ...props }: CreateModalProps) => {
  const isModalClosed = useIsModalClosed(entity)

  if (isModalClosed) return null

  return <CreateModal entity={entity} {...props} />
}

export default CreateModalContainer

const useIsModalClosed = (entity: string) => {
  const location = useLocation()
  const searchStringObj = queryString.parse(location.search)
  const searchStringKey = _.camelCase('create ' + entity)
  return !Boolean(searchStringObj[searchStringKey])
}
