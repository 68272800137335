import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'
import { CustomSvgIcon } from '../Icon/CustomSvgIcon'

const Button = styled.button({
  position: 'absolute',
  right: -20,
  top: 50,
  zIndex: 4,
  width: 36,
  height: 36,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: Colors.PRIMARY,
  borderRadius: '50%',
  border: 'none',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease, transform 0.3s ease',
})
interface Props {
  isOpen: boolean
  onClick: () => void
  isDisabled: boolean
}

const SVG_STYLE = { height: '19px', width: '19px' }

const CollapseButton = ({ isOpen, onClick, isDisabled }: Props) => {
  const iconId = isOpen ? 'ChevronLeft' : 'ChevronRight'

  return (
    <Button onClick={onClick} disabled={isDisabled}>
      <CustomSvgIcon
        iconName={iconId}
        iconColor={Colors.WHITE}
        styles={SVG_STYLE}
      />
    </Button>
  )
}

export default CollapseButton
