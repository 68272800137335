import React, { Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'

import { Spinner } from 'frontend/components'
import { useEmberNode } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'

import { NODE_TYPE_TO_PARENT_TYPE } from '../../../constants'
import { NodeState } from '../types'
import Form from './Form'

interface Props {
  nodeState: NodeState
  setNodeState: Dispatch<SetStateAction<NodeState>>
}

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

const Container = (
  props: Props,
) => {
  const queryString: any = useQueryString()
  const { node, nodeType } = queryString
  const parentNodeType = NODE_TYPE_TO_PARENT_TYPE[nodeType]

  const {
    isLoading: isParentNodeLoading,
  } = useEmberNode({
    queryInput: {
      type: parentNodeType ?? 'tool',
      id: parentNodeType ? queryString[parentNodeType] : undefined,
    },
  })

  const {
    data,
    isLoading: isNodeLoading,
  }: any = useEmberNode({
    queryInput: {
      type: nodeType,
      id: node,
      [parentNodeType]: node ? undefined : queryString[parentNodeType],
    },
  })

  if ((parentNodeType && isParentNodeLoading) || (node && isNodeLoading)) return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  )

  return (
    <Form
      node={node ? data[0] : undefined}
      {...props}
    />
  )
}

export default Container
