import React, { useState } from 'react'
import {
  CondensedDataTable,
  DataTable,
  Button,
} from '@pulse-analytics/pulse-design-system'

import Spinner from 'frontend/components/Spinner'
import useDataTableConcurrentEdit from 'frontend/hooks/useDataTableConcurrentEdit'

import Result from './Result'

import {
  getExcelTitle,
  getExportStyleConfig,
  getExportColConfig,
  getKeyToFormatterMap,
} from './excelExportUtils'
import getPositioningTableColumns from './getPositioningTableColumns'
import usePositioningData from '../Positioning/utils/usePositioningData'
import usePathwaysTreatmentPlanData from '../Positioning/utils/usePathwaysTreatmentPlanData'
import usePositioningOptionsData from '../Positioning/utils/usePositioningOptionsData'
import { getPositioningTableColumnsInjectables } from '../Positioning/utils'

const toggleButtonStyles = {
  width: 125,
  padding: 4,
  justifyContent: 'center',
  alignSelf: 'center',
  margin: 4,
}

const STYLE_CONFIGS = {
  superheaders: {
    display: 'flex',
    justifyContent: 'center',
    borderRight: '1px dotted #0E2539',
  },
}

const MockPositioning = () => {
  const [isCondensed, setCondensed] = useState(true)
  const [isPaginated, setPaginated] = useState(true)
  const [shouldCellContentWrap, setCellContentWrap] = useState(!isCondensed)
  const [isAdditionalDataLoading, setAdditionalDataLoading] = useState(false)
  const tableId = 'mock-positioning-1'

  const concurrentEditConfig = useDataTableConcurrentEdit(tableId)
  const positioningData = usePositioningData(tableId)
  const pathwaysTreatmentPlanData = usePathwaysTreatmentPlanData()
  const optionsData = usePositioningOptionsData()

  const positioningTableData =
    positioningData.data?.pathwaysIndicationRegimenPositions ?? []

  const loading = positioningData.loading || pathwaysTreatmentPlanData.loading

  if (loading) return <Spinner />

  const positioningTableColumnsInjectables = getPositioningTableColumnsInjectables(
    {
      positioningData,
      ...pathwaysTreatmentPlanData,
    }
  )

  const positioningTableColumns = getPositioningTableColumns({
    isCondensed,
    ...positioningTableColumnsInjectables,
    ...pathwaysTreatmentPlanData,
    ...optionsData,
  })

  const tableInjectables = {
    ...pathwaysTreatmentPlanData,
    ...optionsData,
  }

  const getExcelData = (tableData) => {
    const excelData = []
    const keyToFormatterMap = getKeyToFormatterMap(tableInjectables)

    tableData.forEach((datum) => {
      const formattedDatum = {}

      Object.keys(keyToFormatterMap).forEach((key) => {
        const { valFormatter, labelMap } = keyToFormatterMap[key]
        const currentVal = datum[key]
        formattedDatum[key] = valFormatter
          ? valFormatter(currentVal, labelMap)
          : currentVal
      })

      excelData.push({ ...datum, ...formattedDatum })
    })

    return excelData
  }

  const exportConfig = {
    // exportColConfig: getExportColConfig(positioningTableColumns),
    sheetName: 'sheet1',
    // styleConfig: getExportStyleConfig(),
    fileName: 'Pathways Mock Positioning',
    dataFormatter: getExcelData,
    exportTitles: ['Pathways Positioning', 'Mock'],
  }

  const toggleCondensed = () => setCondensed(!isCondensed)
  const toggleContentWrap = () => setCellContentWrap(!shouldCellContentWrap)
  const toggleAdditionalDataLoad = () => {
    if (isAdditionalDataLoading) {
      setAdditionalDataLoading(false)
      setPaginated(true)
    } else {
      setAdditionalDataLoading(true)
      setPaginated(false)
    }
  }
  const TableComponent = isCondensed ? CondensedDataTable : DataTable

  return (
    <div
      style={{
        width: 'calc(100vw - 316px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TableComponent
        tableId={tableId}
        concurrentEditConfig={concurrentEditConfig}
        data={
          isAdditionalDataLoading
            ? positioningTableData.slice(0, 30)
            : positioningTableData
        }
        isAdditionalDataLoading={isAdditionalDataLoading}
        colConfig={positioningTableColumns}
        defaultColWidths={{ minWidth: 60, width: 150 }}
        queryStringConfig={{
          showPageNumber: true,
          showSearchFilter: true,
          showSort: true,
          filterAccessorsToShow: [
            'pathways_organization',
            'indication',
            'cancer_stages',
            'position_date',
            'position_status',
          ],
        }}
        isHoverable
        isFocusable
        isEditable
        editAccessor="id"
        styleConfig={STYLE_CONFIGS}
        shouldCellContentWrap={shouldCellContentWrap}
      >
        <DataTable.UtilityBar>
          <DataTable.GlobalSearchFilter />
          <Button onClick={toggleCondensed} style={toggleButtonStyles}>
            Toggle Table{' '}
          </Button>
          <Button
            onClick={() => setPaginated(!isPaginated)}
            style={toggleButtonStyles}
          >
            Toggle Pagination
          </Button>
          <Button onClick={toggleContentWrap} style={toggleButtonStyles}>
            Toggle Wrap
          </Button>
          <Button onClick={toggleAdditionalDataLoad} style={toggleButtonStyles}>
            Toggle Additional Data Load
          </Button>

          <DataTable.ResetButton />
          <DataTable.ExportButton
            exportConfig={exportConfig}
            buttonStyle={{ margin: '0 12px' }}
          />
        </DataTable.UtilityBar>
        <DataTable.TableMain />
        {isPaginated && <DataTable.Pagination />}
      </TableComponent>
    </div>
  )
}

export default MockPositioning
