import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

export const useRewriteSearchString = () => {
  const history = useHistory()
  const { search } = useLocation()

  const rewriteSearchString = (searchArgsToPush: any) => {
    const searchArgs = queryString.parse(search)
    const newSearchArgs = {
      ...searchArgs,
      ...searchArgsToPush,
    }
    const newSearchString = queryString.stringify(newSearchArgs)
    history.push({ search: newSearchString })
  }

  return rewriteSearchString
}
