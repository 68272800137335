import styled from '@emotion/styled'
import { transparentize } from 'polished'

import Color from 'frontend/utils/color'
import Spacing from 'frontend/utils/spacing'
import FontSpace from 'frontend/utils/fontspace'

export const EventWrapper = styled.div({
  ...FontSpace.FS2,
  color: Color.BLACK,
  paddingBottom: Spacing.S3,
  paddingTop: Spacing.S3,
  margin: Spacing.S4,
  borderBottom: `1px solid ${transparentize(0.9, Color.BLACK)}`,
})

export const EventHeader = styled.span({
  padding: `${Spacing.S2} 0px ${Spacing.S2} 0px`,
  fontWeight: 500,
})

export const Accent = styled.span({
  fontWeight: 700,
  color: Color.BLACK,
})

export const Timestamp = styled.div({
  color: Color.BLACK,
  fontWeight: 400,
  margin: `0 0 ${Spacing.S1} 0`,
})
