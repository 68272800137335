import { gql } from '@apollo/client'

export const GET_VEGA_STATES = gql`
  query GetVegaStates {
    vegaStates {
      id
      full_name
      abbreviation
      created_at
      updated_at
    }
  }
`
