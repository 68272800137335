import gql from 'graphql-tag'

export const GET_SECONDARY_ORGANIZATIONS = gql`
  query GetSecondaryOrganizations($input: SecondaryOrganizationsInput) {
    secondaryOrganizations(input: $input) {
      id
      name
      type
      alias
      committees
      tdg_internal_note
      created_at
      updated_at
    }
  }
`
