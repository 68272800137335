import React from 'react'

import { Label, Wrapper, Value } from './shared'

interface Props {
  fieldState: any
}

const LABEL_TEXT = 'Document Type'

export const DocumentType = ({ fieldState }: Props) => {
  const { document_type } = fieldState
  const titleCasedDocumentType =
    document_type.charAt(0).toUpperCase() + document_type.slice(1)

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <Value>{titleCasedDocumentType}</Value>
    </Wrapper>
  )
}
