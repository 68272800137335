const trimTreatmentPlansToIds = (treatmentPlans) => {
  const result = treatmentPlans.map((treatmentPlan) => {
    let { _id: treatId, name: treatName } = treatmentPlan

    let regimens = []
    let subTypes = []
    if (treatmentPlan.regimens && treatmentPlan.regimens.length) {
      regimens = treatmentPlan.regimens.map((regimen) => {
        if (regimen.subTypes) {
          subTypes = regimen.subTypes.map((subType) => {
            return {
              _id: subType._id,
              name: subType.name,
            }
          })
        }
        return {
          _id: regimen._id,
          name: regimen.name,
          subTypes,
        }
      })
    }

    return {
      _id: treatId,
      name: treatName,
      regimens,
    }
  })

  return result
}

const trimConfigDataToModalData = (configData) => {
  const modalData = {
    client: {
      value: configData.client.clientId,
      label: configData.client.name,
    },
    teamName: configData.team,
    tool: {
      value: configData.tool.toolId,
      label: configData.tool.name,
    },
    treatmentPlans: configData.tool.toolSettings.treatmentPlans.indications,
    accounts: configData.tool.toolSettings.accounts,
  }
  return modalData
}

const trimModalDataToReqPayload = (modalData) => {
  const requestPayload = {
    client: { clientId: modalData.client.value, name: modalData.client.label },
    team: modalData.teamName,
    tool: {
      toolId: modalData.tool.value,
      name: modalData.tool.label,
      toolSettings: {
        treatmentPlans: {
          indications: modalData.treatmentPlans,
        },
        accounts: modalData.accounts,
      },
    },
  }
  return requestPayload
}

const sortArray = (array) =>
  array.length > 0
    ? array.sort((obj1, obj2) => obj1.name.localeCompare(obj2.name))
    : []

const downloadExcel = (response, filename) => {
  const dataType = response.type
  const binaryData = [response]
  const downloadLink = document.createElement('a')
  downloadLink.href = window.URL.createObjectURL(
    new Blob(binaryData, { type: dataType })
  )
  downloadLink.setAttribute('download', filename)
  document.body.appendChild(downloadLink)
  downloadLink.click()
  downloadLink.remove()
}

module.exports = {
  trimTreatmentPlansToIds,
  trimConfigDataToModalData,
  trimModalDataToReqPayload,
  sortArray,
  downloadExcel,
}
