import React from 'react'

const ChevronRight = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        fill={iconColor}
        className="icon-color-1"
        fillRule="evenodd"
        d="M-2.312,4.70457143 C-1.73066667,4.13114286 -0.921333333,4.08614286 -0.210666667,4.70457143 L5,9.52214286 L10.2106667,4.70457143 C10.9213333,4.08614286 11.732,4.13114286 12.3093333,4.70457143 C12.8906667,5.27671429 12.8533333,6.24357143 12.3093333,6.781 C11.768,7.31842857 6.04933333,12.5692857 6.04933333,12.5692857 C5.76,12.856 5.38,13 5,13 C4.62,13 4.24,12.856 3.948,12.5692857 C3.948,12.5692857 -1.768,7.31842857 -2.312,6.781 C-2.856,6.24357143 -2.89333333,5.27671429 -2.312,4.70457143 Z"
        transform="matrix(0 1 1 0 3.37 7.13)"
      />
    </svg>
  )
}
export default ChevronRight
