import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { useEmberNode } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'
import { NODE_TYPE_TO_PARENT_TYPE } from 'frontend/ClientManagement/Sitemap/constants'
import { ACCOUNTS } from 'frontend/ClientManagement/Sitemap/ResourcesModal/constants'

import ActiveList from './ActiveList'
import InactiveList from './InactiveList'
import { getAccountsQueryGqlTag } from '../../utils'

const TYPE_TO_ABBREVIATION = {
  'Oncology Benefit Manager': ' (OBM)',
  'Laboratory Benefit Manager': ' (LBM)',
  'Radiation Oncology Benefit Manager': ' (ROBM)',
}

const AccountsListPanel = ({ accounts, parentResources, setResources }) => {
  const { team } = useParams()

  const { node, nodeType, tool } = useQueryString()
  const parentType = NODE_TYPE_TO_PARENT_TYPE[nodeType]

  const { data: tools } = useEmberNode({
    queryInput: {
      type: 'tool',
      id: nodeType === 'tool' ? node : tool,
    },
  })

  const { data: organizationsData } = useQuery(
    getAccountsQueryGqlTag(tools[0].permitted_entity_types)
  )

  const [organizations] = Object.values(organizationsData)

  const accountIdToLabel = useMemo(() => {
    return organizations.reduce((acc, { _id, organization, slug, type }) => {
      let label = `${organization} (${slug})`
      const abbreviation = TYPE_TO_ABBREVIATION[type]
      if (abbreviation) label += abbreviation
      acc[_id] = label
      return acc
    }, {})
  }, [organizations])

  const enabledAccountsSet = accounts.reduce((acc, { resource }) => {
    acc.add(resource)
    return acc
  }, new Set())

  const inactiveAccounts = parentType
    ? parentResources.reduce(
        (
          acc,
          {
            id: teamParentResource,
            resource: { entity_id, type: resourceType },
          }
        ) => {
          if (!ACCOUNTS.has(resourceType) || enabledAccountsSet.has(entity_id))
            return acc

          acc.push({
            team,
            [nodeType]: node,
            resource: entity_id,
            [`team_${parentType}_resource`]: teamParentResource,
          })

          return acc
        },
        []
      )
    : organizations.reduce((acc, { _id }) => {
        if (enabledAccountsSet.has(_id)) return acc

        acc.push({
          team,
          [nodeType]: node,
          resource: _id,
        })

        return acc
      }, [])

  return (
    <>
      <ActiveList
        accountIdToLabel={accountIdToLabel}
        accounts={accounts}
        setResources={setResources}
      />
      <InactiveList
        accountIdToLabel={accountIdToLabel}
        accounts={inactiveAccounts}
        setResources={setResources}
      />
    </>
  )
}

export default AccountsListPanel
