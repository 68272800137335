import React from 'react'
import { transparentize } from 'polished'

import {
  DISPLAY_CELL_TYPES,
  FILTER_TYPES,
  SORT_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'
import { MultiSelectFilter } from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'

import { CustomSvgIcon } from 'frontend/components/Icon/CustomSvgIcon'
import { Colors } from 'frontend/utils/pulseStyles'

const { BUTTON } = DISPLAY_CELL_TYPES
const { MULTI_SELECT } = FILTER_TYPES
const { DISPLAY } = SORT_TYPES

export const getPolicyDetailsColumns = ({ setModalType }) => [
  {
    Header: 'Project',
    accessor: 'project',
    width: 128,
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    cellValueFormatter: () => 'Merck',
  },
  // {
  //   Header: 'Assignee',
  //   accessor: 'assignee',
  //   width: 128,
  //   Filter: MultiSelectFilter,
  //   filter: MULTI_SELECT,
  // },
  {
    Header: 'Payer',
    accessor: 'payer_organization_name',
    minWidth: 163,
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
  },
  {
    Header: 'Book',
    accessor: 'book_of_business_name',
    minWidth: 155,
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
  },
  {
    Header: 'Benefit Type',
    accessor: 'benefit_type',
    minWidth: 142,
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    cellValueFormatter: (value) => {
      if (!value) return null
      const titleCasedValue = value.charAt(0).toUpperCase() + value.slice(1)
      return titleCasedValue
    },
  },
  {
    Header: 'Product(s)',
    accessor: 'product_names',
    minWidth: 193,
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: DISPLAY,
    cellValueFormatter: (value) => {
      if (!value) return null
      return value.map((productId) => productId).join(', ')
    },
  },
  {
    Header: '',
    accessor: 'x',
    displayCellConfig: {
      type: BUTTON,
      style: {
        background: transparentize(0.9, Colors.PRIMARY),
        borderRadius: 4,
        padding: 2,
        cursor: 'pointer',
      },
      label: (
        <CustomSvgIcon
          iconName="SingleDocument"
          iconColor={Colors.PRIMARY}
          styles={{ width: 14, height: 14 }}
        />
      ),
      onClick: ({ id }) => {
        setModalType({ payer_policy_document_id: id })
      },
    },
    width: 50,
    disableSortBy: true,
  },
]
