import { CrudHookOptions } from './../../interfaces/CrudHookOptions'
import * as CRUD_UTILS from '../../api/utils'

import { getPayerPolicyLinkConfigs } from './getPayerPolicyLinkConfigs'

export const usePayerPolicyLink = ({
  deleteOptions,
  queryInput,
}: CrudHookOptions = {}) => {
  const {
    createConfig,
    deleteConfig,
    updateConfig,
    readConfig,
    readOptionsConfig,
  } = getPayerPolicyLinkConfigs(deleteOptions, queryInput)

  const {
    data: payerPolicyLinkOptionsData,
    loading: areOptionsLoading,
  } = CRUD_UTILS.useReadOptions(readOptionsConfig.tag, { labelMap: true })

  const { data: payerPolicyLinkData, loading: isLoading } = CRUD_UTILS.useRead(
    readConfig
  )
  const payerPolicyLinksData = payerPolicyLinkData
    ? Object.values(payerPolicyLinkData)[0]
    : []
  const [create, { loading: isCreating }] = CRUD_UTILS.useCreate(createConfig)
  const [destroy, { loading: isDeleting }] = CRUD_UTILS.useDelete(deleteConfig)
  const [update, { loading: isUpdating }] = CRUD_UTILS.useUpdate(updateConfig)

  return {
    create,
    isCreating,
    update,
    isUpdating,
    destroy,
    isDeleting,
    data: payerPolicyLinksData,
    isLoading,
    options: areOptionsLoading ? {} : payerPolicyLinkOptionsData,
    areOptionsLoading,
  }
}
