import React from 'react'

import { TextInput } from 'frontend/components'

interface Props {
  shouldLockLandingPage: boolean
  onChange: any
}

const FIELD_NAME = 'defaultLanding'

export const Input = ({ shouldLockLandingPage, onChange }: Props) => {
  if (!shouldLockLandingPage) return null

  const handleChange = ({ target: { value } }: any) => {
    onChange({ name: FIELD_NAME, value })
  }

  return <TextInput onChange={handleChange} />
}
