import React, { useState } from 'react'
import { transparentize } from 'polished'

import Color from 'frontend/utils/color'
import { useTeamNode } from 'frontend/entity-hooks/ember'

import { useNodeForm } from './useNodeForm'
import { getTextInputs } from './getTextInputs'

import {
  FormLabel,
  StyledInput,
  InputContainer,
  InputLabel,
  StyledButton,
} from './styledComponents'

const Form = ({ node, nodeType, team, closeModal }) => {
  const [nodeData, setNodeData] = useState()
  const [newTextField, setNewTextField] = useState(null)

  const { data, isLoading, update, refetch } = useTeamNode({
    queryInput: { nodeType: nodeType + 's', team: team },
  })

  const {
    handleInputChange,
    handleTextInputChange,
    handleAddTextField,
    handleRemoveTextField,
  } = useNodeForm({
    node,
    data,
    isLoading,
    nodeData,
    setNodeData,
    newTextField,
    setNewTextField,
  })

  if (isLoading) return <div>Loading...</div>

  const textInputs = getTextInputs(
    nodeData || {},
    handleTextInputChange,
    handleRemoveTextField
  )

  const handleSave = () => {
    update({
      variables: {
        input: {
          id: nodeData.id,
          order: nodeData.order,
          text: nodeData.text,
          nodeType: nodeType,
        },
      },
      onCompleted: () => refetch(),
    })
    closeModal()
  }

  return (
    <div style={{ padding: 24, background: Color.LIGHT_BLUE_GRAY_2 }}>
      <InputContainer>
        <InputLabel>Name:</InputLabel>
        <StyledInput
          disabled
          value={nodeData?.node.name || ''}
          name="name"
          type="text"
          onChange={handleInputChange}
        />
      </InputContainer>

      <InputContainer>
        <InputLabel>Type:</InputLabel>
        <StyledInput
          disabled
          value={nodeType}
          name="type"
          type="text"
          onChange={() => alert('Type field should be disabled')}
        />
      </InputContainer>

      <InputContainer>
        <InputLabel>Component Path:</InputLabel>
        <StyledInput
          disabled
          value={nodeData?.node.componentPath || ''}
          name="componentPath"
          type="text"
          onChange={handleInputChange}
        />
      </InputContainer>

      <InputContainer>
        <InputLabel>Order:</InputLabel>
        <StyledInput
          value={nodeData?.order || ''}
          name="order"
          type="number"
          onChange={handleInputChange}
        />
      </InputContainer>

      <div style={{ padding: 12, border: `1px solid ${Color.MEDIUM_GRAY_1}` }}>
        <FormLabel>Text Object</FormLabel>
        {textInputs}
        <div>
          <InputContainer>
            <InputLabel>Add New Text Field</InputLabel>
            <div>
              <span style={{ fontWeight: 700 }}>Key Name: </span>
              <input
                style={{ background: Color.WHITE, margin: 12, padding: 6 }}
                value={newTextField || ''}
                type="text"
                onChange={(e) => setNewTextField(e.target.value)}
              />
              <button
                style={{
                  padding: 6,
                  borderRadius: 4,
                  cursor: 'pointer',
                  display: 'inline',
                  background: Color.PRIMARY,
                  color: Color.WHITE,
                  fontWeight: 700,
                }}
                onClick={handleAddTextField}
              >
                Add
              </button>
            </div>
          </InputContainer>
        </div>
      </div>

      <StyledButton
        style={{
          margin: 12,
          color: 'red',
          background: transparentize(0.75, Color.RED),
        }}
        onClick={closeModal}
      >
        CANCEL
      </StyledButton>
      <StyledButton style={{ margin: 12 }} onClick={handleSave}>
        SAVE
      </StyledButton>
    </div>
  )
}

export default Form
