import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

import { AlphaColors, Colors } from 'frontend/utils/pulseStyles'
import CollapsedWrapper from './CollapsedWrapper'
import CollapseButton from './CollapseButton'

const SidePanel = styled.div(
  {
    height: 'calc(100vh - 56px)',
    background: Colors.WHITE,
    borderRight: `1px solid ${AlphaColors.Black10}`,
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'width 150ms ease-in-out',
    zIndex: 10,
  },
  ({ isOpen, panelStyles }: any) => ({
    width: isOpen ? 447 : 48,
    ...panelStyles,
  })
)

const CollapsibleSidePanel = ({
  children,
  handleButtonClick,
  isOpen,
  label,
  panelStyles,
  wrapperStyles,
  isButtonDisabled,
}: any) => {
  const [toggleSidePanel, setToggleOpen] = useState(false)
  const [showChildren, setShowChildren] = useState(false)

  const defaultHandleClick = (e: any) => {
    e.preventDefault()
    setToggleOpen(!toggleSidePanel)
  }

  const onClick = handleButtonClick || defaultHandleClick
  const isSidePanelOpen = isOpen ? isOpen : toggleSidePanel

  useEffect(() => {
    if (isSidePanelOpen) {
      const timer = setTimeout(() => {
        setShowChildren(true)
      }, 150)
      return () => clearTimeout(timer)
    } else {
      setShowChildren(false)
    }
  }, [isSidePanelOpen])

  return (
    <SidePanel isOpen={isSidePanelOpen} panelStyles={panelStyles}>
      <CollapsedWrapper
        isOpen={isSidePanelOpen}
        label={label}
        styles={wrapperStyles}
      />
      <CollapseButton
        isOpen={isSidePanelOpen}
        onClick={onClick}
        isDisabled={isButtonDisabled}
      />
      {isSidePanelOpen && showChildren && children}
    </SidePanel>
  )
}

export default CollapsibleSidePanel
