import { gql } from '@apollo/client'
import { schemaV1 } from './schemas'

export const DELETE_NCCN_PANEL_VOTING_MEMBER = gql`
  mutation DeleteNccnPanelVotingMember($input: DeleteNccnPanelVotingMemberInput!) {
    deleteNccnPanelVotingMember(input: $input) {
      ${schemaV1}
    }
  }
`
