import { MultiSelectFilter } from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'
import {
  DISPLAY_CELL_TYPES,
  EDIT_CELL_TYPES,
  FILTER_TYPES,
  SORT_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'

const { CONDENSED_BUTTON } = DISPLAY_CELL_TYPES
const { CONDENSED_TEXT_INPUT } = EDIT_CELL_TYPES
const { MULTI_SELECT } = FILTER_TYPES
const { STRING } = SORT_TYPES

const getColConfig = ({ handleUpdate, addDeleteSearchString }) => [
  {
    Header: 'Slug',
    accessor: 'slug',
    sortType: STRING,
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: handleUpdate,
    },
  },
  {
    Header: 'Organization',
    accessor: 'name',
    sortType: STRING,
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: handleUpdate,
    },
  },
  {
    Header: 'Short Name',
    accessor: 'name_tiny',
    sortType: STRING,
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: handleUpdate,
    },
  },
  {
    Header: 'Delete',
    accessor: 'delete',
    displayCellConfig: {
      type: CONDENSED_BUTTON,
      label: 'Delete',
      onClick: addDeleteSearchString,
    },
  },
]

export default getColConfig
