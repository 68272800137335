import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

const Wrapper = styled.div({
  borderBottom: `2px solid ${Colors.COOL_GRAY_200}`,
  fontWeight: 500,
  letterSpacing: '-0.1px',
  padding: 20,
  textTransform: 'uppercase',
})

const Header = () => (
  <Wrapper>
    Editing Sitemap Source Nodes
  </Wrapper>
)

export default Header
