import {
  DEFAULT_COL_HEADER_STYLES,
  ALL_BORDERS,
} from '@pulse-analytics/pulse-design-system/dist/components/ExportToExcelButton/export-to-excel-button-utils'
import _ from 'lodash'
import {
  LAST_APPROVED_REVIEW_DATE,
  LAST_TDG_REVIEW_DATE,
  LAST_IDENTIFIED_CHANGE_DATE,
} from 'frontend/PayerPolicyLinkMgmt/v0/utils/enums'

import { dateFormatter } from './getColConfig'

const COL_HEADER_HEIGHT = 30
const COL_HEADER_WIDTH = 50
const HEADER_ROW = 1

export const EXPORT_STYLE_CONFIG = [
  {
    rows: [HEADER_ROW],
    style: {
      ...DEFAULT_COL_HEADER_STYLES,
      ...ALL_BORDERS,
      alignment: {
        vertical: 'middle',
        horizontal: 'center',
      },
      height: COL_HEADER_HEIGHT,
      width: COL_HEADER_WIDTH,
    },
  },
]

export const getExportColConfig = (colConfig) => {
  const clonedConfig = _.clone(colConfig)

  const filteredConfig = clonedConfig.filter(({ accessor }) => {
    // * remove the first and last column targeting by dummy accessors
    return !['x', 'y'].includes(accessor)
  })

  const exportColConfig = filteredConfig.reduce((acc, col) => {
    if (col.columns) {
      const nestedConfig = col.columns.map(({ Header, accessor }) => ({
        title: Header,
        key: accessor,
      }))
      acc.push(...nestedConfig)
      return acc
    }

    const { Header, accessor } = col
    acc.push({
      title: Header,
      key: accessor,
    })
    return acc
  }, [])

  return exportColConfig
}

export const getExcelData = (tableData, colConfig, policyOptions) => {
  if (_.isEmpty(tableData)) return []

  return tableData.map((datum) => {
    return colConfig.reduce((acc, { key }) => {
      const keyValue = datum[key]
      const options = policyOptions[key]

      if (
        [
          LAST_APPROVED_REVIEW_DATE,
          LAST_TDG_REVIEW_DATE,
          LAST_IDENTIFIED_CHANGE_DATE,
        ].includes(key)
      ) {
        acc[key] = dateFormatter(keyValue)
      } else if (options) {
        if (_.isArray(keyValue)) {
          const mappedValues = keyValue.map((val) => options.labelMap[val])
          acc[key] = mappedValues.join(', ')
        } else {
          acc[key] = options.labelMap[keyValue]
        }
      } else {
        acc[key] = keyValue
      }
      return acc
    }, {})
  })
}
