import { useQuery } from '@apollo/client'

import { GET_VEGA_PATHWAYS_INDICATION_REGIMEN_POSITIONS_OPTIONS } from 'frontend/api/queries'

import { getSelectOptionsAndMaps } from './getPositioningTableColumnsInjectables/utils'

const EMPTY_OPTIONS_DATA = {
  cancer_stages: {
    child: { choices: [] },
  },
  position_status: {
    choices: [],
  },
  relative_access: {
    choices: [],
  },
}

const usePositioningOptionsData = () => {
  const {
    data: pathwaysPositionsOptionsData,
    loading: pathwaysPositionsOptionsLoading,
  } = useQuery(GET_VEGA_PATHWAYS_INDICATION_REGIMEN_POSITIONS_OPTIONS)

  const optionsData = pathwaysPositionsOptionsLoading
    ? EMPTY_OPTIONS_DATA
    : pathwaysPositionsOptionsData.vegaPathwaysIndicationRegimenPositionsOptions
        .actions.POST

  const selectMapAndOptionsDependencies = [
    [
      {
        data: {
          data: optionsData.cancer_stages.child.choices,
        },
        loading: pathwaysPositionsOptionsLoading,
      },
      { key: 'value', value: 'display_name' },
    ],
    [
      {
        data: {
          data: optionsData.position_status.choices,
        },
        loading: pathwaysPositionsOptionsLoading,
      },
      { key: 'value', value: 'display_name' },
    ],
    [
      {
        data: {
          data: optionsData.relative_access.choices,
        },
        loading: pathwaysPositionsOptionsLoading,
      },
      { key: 'value', value: 'display_name' },
    ],
  ]

  const [
    {
      options: cancerStagesOptions,
      optionMap: cancerStagesOptionMap,
      labelMap: cancerStagesLabelMap,
    },
    {
      options: positionStatusOptions,
      optionMap: positionStatusOptionMap,
      labelMap: positionStatusLabelMap,
    },
    {
      options: relativeAccessOptions,
      optionMap: relativeAccessOptionMap,
      labelMap: relativeAccessLabelMap,
    },
  ] = selectMapAndOptionsDependencies.map((dependency) => {
    return getSelectOptionsAndMaps(...dependency)
  })

  return {
    cancerStages: {
      options: cancerStagesOptions,
      optionMap: cancerStagesOptionMap,
      labelMap: cancerStagesLabelMap,
    },
    positionStatus: {
      options: positionStatusOptions,
      optionMap: positionStatusOptionMap,
      labelMap: positionStatusLabelMap,
    },
    relativeAccess: {
      options: relativeAccessOptions,
      optionMap: relativeAccessOptionMap,
      labelMap: relativeAccessLabelMap,
    },
    loading: pathwaysPositionsOptionsLoading,
  }
}

export default usePositioningOptionsData
