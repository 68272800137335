import React from 'react'

import { ALL_USERS_DATUM } from '../../TeamsUsersWrapper/TeamsPanel/TeamsListPanel/constants'
import { EditButton } from './EditButton'

interface Props {
  isHoveredListItem: Boolean
  isSelected?: Boolean
  datumId: String
  entity: String
  queryStringValue: String
}

export const Container = ({
  isHoveredListItem,
  isSelected,
  datumId,
  entity,
  queryStringValue,
}: Props) => {
  const isAllUsersListItem = datumId === ALL_USERS_DATUM.id

  if (isAllUsersListItem || (!isSelected && !isHoveredListItem)) return null

  return (
    <EditButton
      datumId={datumId}
      entity={entity}
      queryStringValue={queryStringValue}
    />
  )
}
