import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import { useStates } from 'frontend/entity-hooks'

const StateSelect = ({
  name,
  onChange,
  defaultValue,
}: any) => {
  const [options, setOptions] = useState([])

  const { data }: any = useStates()

  useEffect(() => {
    if (!data) return

    const newOptions = data.map(({ abbreviation }: { abbreviation: string }) => {
      return { label: abbreviation, value: abbreviation }
    })

    setOptions(newOptions)
  }, [data])

  if (defaultValue) defaultValue = { label: defaultValue }

  return (
    <Select
      defaultValue={defaultValue}
      name={name}
      onChange={onChange}
      options={options}
    />
  )
}

export default StateSelect
