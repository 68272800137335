import React from 'react'

import {
  CREATE_PATHWAYS_ORGANIZATION,
  UPDATE_PATHWAYS_ORGANIZATION,
  DELETE_PATHWAYS_ORGANIZATION,
} from 'frontend/api/mutations'

import {
  GET_PATHWAYS_ORGANIZATIONS,
  GET_EVENTS,
  GET_VEGA_PATHWAYS_INFLUENCERS,
  GET_VEGA_PATHWAYS_ORGANIZATIONS,
} from 'frontend/api/queries'

import GET_PATHWAYS_ORGANIZATIONS_HISTORY from 'frontend/api/usePathwaysOrganization/usePathwaysOrganizationsHistory/useRead/gql-tag'

import BusinessObjectModal from '../BusinessObjectModal/BusinessObjectModal'
import WIDGETS from './relational-widgets/Widgets'

const PATHWAYS_BOID = '5eac3251ac8a01743081f28d'
const HEADER_TEXT = 'Pathways Accounts'

const PathwaysModal = ({
  closeModal,
  entityId,
  refetchQueries,
  afterMutationHook,
}) => (
  <BusinessObjectModal
    isVega
    closeModal={closeModal}
    entityId={entityId}
    boId={PATHWAYS_BOID}
    headerText={HEADER_TEXT}
    getEntityTitle={(entity) => entity.name}
    mutationDocs={{
      create: CREATE_PATHWAYS_ORGANIZATION,
      update: UPDATE_PATHWAYS_ORGANIZATION,
      delete: DELETE_PATHWAYS_ORGANIZATION,
    }}
    refetchQueries={[
      ...refetchQueries,
      { query: GET_PATHWAYS_ORGANIZATIONS },
      { query: GET_VEGA_PATHWAYS_ORGANIZATIONS },
      { query: GET_VEGA_PATHWAYS_INFLUENCERS },
      { query: GET_EVENTS },
      ...getPathwaysOrgHistoryRefetchQuery(entityId),
    ]}
    afterMutationHook={afterMutationHook}
    widgets={WIDGETS}
  />
)

PathwaysModal.defaultProps = {
  refetchQueries: [],
}

export default PathwaysModal

const getPathwaysOrgHistoryRefetchQuery = (entityId) => {
  if (entityId)
    return [
      {
        query: GET_PATHWAYS_ORGANIZATIONS_HISTORY,
        variables: { input: { id: entityId } },
      },
    ]
  else return []
}
