import {
  formatData,
  getColConfig,
  getStyleConfig,
} from './utils'

const getExportConfig = ({
  colConfig,
  excelLabel,
}: {
  colConfig: any
  excelLabel: string
}) => {

  return {
    dataFormatter: (tableData: any) => formatData(tableData, colConfig),
    exportColConfig: getColConfig(colConfig),
    fileName: excelLabel,
    sheetName: excelLabel,
    styleConfig: getStyleConfig(),
  }
}

export default getExportConfig
