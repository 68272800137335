const HEAD_PRODUCTION_APP_ID = '3259922455'

const HEAP_PROPERTIES = [
  'addEventProperties',
  'addUserProperties',
  'clearEventProperties',
  'identify',
  'resetIdentity',
  'removeEventProperty',
  'setEventProperties',
  'track',
  'unsetEventProperty',
]

function setEventCb() {
  return (e) => {
    return () => {
      window.heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))
    }
  }
}

const setupHeap = () => {
  window.heap = window.heap || []
  window.heap.load = (e, config) => {
    window.heap.appid = e
    window.heap.config = config || {}

    let headerScriptElement = document.createElement('script')
    headerScriptElement.type = 'text/javascript'
    headerScriptElement.async = true
    headerScriptElement.src =
      'https://cdn.heapanalytics.com/js/heap-' + e + '.js'

    let a = document.getElementsByTagName('script')[0]

    a.parentNode.insertBefore(headerScriptElement, a)

    for (let idx = 0; idx < HEAP_PROPERTIES.length; idx++) {
      window.heap[HEAP_PROPERTIES[idx]] = setEventCb(HEAP_PROPERTIES[idx])
    }
  }

  window.heap.load(HEAD_PRODUCTION_APP_ID)
}

export default setupHeap
