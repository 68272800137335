import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import Select from 'react-select'
import { DataTable } from '@pulse-analytics/pulse-design-system'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import _ from 'lodash'
import { AlphaColors } from '@pulse-analytics/pulse-design-system/dist/foundations/Colors'

import Spinner from 'frontend/components/Spinner'
import {
  PageHeaderContainer,
  PageHeader,
} from 'frontend/Orion/ImportExportData/styledComponents'

import { GET_REPORT_TOOL_PERMISSIONS } from 'frontend/api/queries'
import useClientData from 'frontend/Reports/ClientToolAccess/useClientData'

import { formatTableData, COL_CONFIG } from './utils'

const customStyles = {
  container: ({ styles }) => ({
    ...styles,
    width: '500px',
  }),
  menu: ({ styles }) => ({ ...styles, width: '500px', background: 'white' }),
}

const LoadingIndicator = () => {
  return (
    <div style={{ paddingRight: '8px' }}>
      <Spinner />
    </div>
  )
}

const MockClientToolAccess = () => {
  const [clientName, setClientName] = useState({})
  const history = useHistory()
  const location = useLocation()

  const { clients: clientsData } = useClientData()

  const { data: clientPermissionsData, loading } = useQuery(
    GET_REPORT_TOOL_PERMISSIONS,
    {
      variables: {
        input: { clientName: clientName.value },
      },
    }
  )

  const clientToolAccessData =
    clientPermissionsData?.reportToolPermissions ?? []

  const formattedTableData = formatTableData(clientToolAccessData)

  useEffect(() => {
    const parsed = queryString.parse(location.search)
    if (!_.isEmpty(parsed) && parsed.clientName) {
      const { clientName } = parsed
      setClientName({ label: clientName, value: clientName })
    }
  }, [location.search])

  const handleChange = (option) => {
    const queryStringObj = { clientName: option.value }
    const search = queryString.stringify(queryStringObj)
    setClientName(option)
    history.push({ search })
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100vw - 316px)',
        height: '100vh',
      }}
    >
      <PageHeaderContainer>
        <PageHeader>Mock Client Tool Access </PageHeader>
      </PageHeaderContainer>
      <form>
        <Select
          styles={customStyles}
          value={clientName}
          options={clientsData.options}
          onChange={handleChange}
          isLoading={clientsData.loading}
          components={{ LoadingIndicator }}
          menuPortalTarget={document.querySelector('body')}
        />
      </form>

      <DataTable
        data={formattedTableData}
        colConfig={COL_CONFIG}
        defaultColWidths={{ minWidth: 200, width: 200 }}
        queryStringConfig={{
          showSearchFilter: true,
          filterAccessorsToShow: [
            'client',
            'tool',
            'indication',
            'regimen',
            'teams',
          ],
        }}
        isLoading={loading}
      >
        <DataTable.UtilityBar
          style={{
            justifyContent: 'flex-end',
            borderBottom: `1px solid ${AlphaColors.Black10}`,
            padding: 12,
          }}
        >
          <DataTable.GlobalSearchFilter />
          <DataTable.ResetButton />
        </DataTable.UtilityBar>
        <DataTable.TableMain />
      </DataTable>
    </div>
  )
}

export default MockClientToolAccess
