const TOAST_OPTIONS: any = {
  SUCCESS_OPTIONS: {
    icon: true,
    theme: 'dark',
    style: {
      width: '500px',
      borderRadius: '8px',
      backgroundColor: '#0E2539',
    },
  },
}

export const CREATE_TOAST = {
  SUCCESS_MESSAGE: 'New team created',
  SUCCESS_OPTIONS: TOAST_OPTIONS.SUCCESS_OPTIONS,
}

export const UPDATE_TOAST = {
  SUCCESS_MESSAGE: 'Team edits saved',
  SUCCESS_OPTIONS: TOAST_OPTIONS.SUCCESS_OPTIONS,
}
