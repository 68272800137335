import React from 'react'
import SingleActionDialog from '../SingleActionDialog'
export interface MessageDialogProps {
  message: string | undefined
  header: string
  iconLink: string
  handleSubmit: any
}

const SUBMIT_TEXT = 'Ok'

const STYLES = {
  CONTENT: {
    width: 448,
    height: 224,
    background: 'white',
  },

  CONTENT_WRAPPER: {
    display: 'flex',
    flexDirection: 'column',
  },

  DEFAULT_CANCEL_BUTTON: {
    background: 'transparent',
    border: 'none',
    fontSize: 0,
    '&:hover': {
      cursor: 'default',
      background: 'transparent',
    },
  },
  DIALOG_STYLE: {
    zIndex: 9999,
  },
}

//? NOTE: due to the prop being required and not needing a cancel button, this is a workaround to avoid TS errors
const handleCancel = () => {}

const MessageDialog = ({
  message,
  header,
  iconLink,
  handleSubmit,
}: MessageDialogProps) => {
  return (
    <SingleActionDialog
      header={header}
      submitText={SUBMIT_TEXT}
      cancelButtonStyle={STYLES.DEFAULT_CANCEL_BUTTON}
      submitHandler={handleSubmit}
      cancelHandler={handleCancel}
      contentWrapperStyle={STYLES.CONTENT_WRAPPER}
      contentStyle={STYLES.CONTENT}
      iconLink={iconLink}
      dialogStyle={STYLES.DIALOG_STYLE}
    >
      <div>{message}</div>
    </SingleActionDialog>
  )
}

export default MessageDialog
