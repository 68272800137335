import { useHistory, useLocation } from 'react-router-dom'

//!TODO: Look into replacing this logic with the useSearchParams once we upgrade to latest version of react-router-dom
export const useClearSearchStringParam = () => {
  const history = useHistory()
  const location = useLocation()

  const clearSearchString = (searchStringParams: string[]) => {
    const queryParams = new URLSearchParams(location.search)
    for (const string of searchStringParams) {
      queryParams.delete(string)
    }
    history.push({
      pathname: location.pathname,
      search: queryParams.toString(),
    })
  }
  return clearSearchString
}
