import React, { Dispatch, SetStateAction } from 'react'

import { Colors } from 'frontend/utils/pulseStyles'

import { ToggleAllButton } from '../../shared'

interface Props {
  disabled: boolean
  setResources: Dispatch<SetStateAction<any>>
}

const ToggleOffAllButton = ({
  disabled,
  setResources,
}: Props) => {
  const toggleOffAllTreatmentPlans = () => {
    setResources(({ treatmentPlans, ...rest }: any) => ({
      treatmentPlans: [],
      ...rest
    }))
  }

  return (
    <ToggleAllButton
      disabled={disabled}
      color={Colors.MEDIUM_GRAY_2}
      onClick={toggleOffAllTreatmentPlans}
    >
      Toggle off All Indications + Regimens
    </ToggleAllButton>
  )
}

export default ToggleOffAllButton
