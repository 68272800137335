import React from 'react'
import styled from '@emotion/styled'

import { useProductPolicyLink } from 'frontend/entity-hooks'

import { Button } from '@pulse-analytics/pulse-design-system'
import Dialog from 'frontend/components/Dialog'
import Spinner from 'frontend/components/Spinner'

import Color from 'frontend/utils/color'

const WarningText = styled.span({
  fontWeight: 600,
  lineHeight: '18px',
  fontSize: 12,
  padding: 24,
})

const ButtonsContainer = styled.section({
  display: 'flex',
  width: '100%',
  padding: '0 24px',
  justifyContent: 'space-around',
})

const buttonStyle = {
  padding: '8px 12px',
  margin: '0 4px',
  cursor: 'pointer',
}

const DeleteLinkModal = ({ closeModal, searchParams }) => {
  const { productId } = searchParams
  const { destroy, isDeleting } = useProductPolicyLink()

  const handleDelete = () => {
    destroy({
      variables: { input: { id: productId } },
    }).finally(() => {
      closeModal()
    })
  }

  return (
    <Dialog
      contentWrapperStyle={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      contentStyle={{
        width: 450,
        height: 150,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <WarningText>Are you sure you want to delete this row?</WarningText>
      <ButtonsContainer>
        <Button
          onClick={closeModal}
          style={{
            background: Color.GRAY_LIGHT,
            color: Color.GRAY_DARK,
            ...buttonStyle,
          }}
        >
          Cancel + Close
        </Button>
        <Button
          style={{
            background: Color.RED,
            color: Color.WHITE,
            ...buttonStyle,
          }}
          onClick={handleDelete}
        >
          {isDeleting ? <Spinner /> : 'Delete Forever'}
        </Button>
      </ButtonsContainer>
    </Dialog>
  )
}

export default DeleteLinkModal
