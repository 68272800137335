import React from 'react'
import ExportBusinessModal from './ExportBusinessModal'

const ExportModal = ({
  closeModal,
  entityId,
  setTableListData,
}) => {
  return <ExportBusinessModal entityId={entityId} closeModal={closeModal} setTableListData={setTableListData} />
}

export default ExportModal
