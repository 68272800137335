import React from 'react'
import styled from '@emotion/styled'
import format from 'date-fns/format'

import {
  FILTER_TYPES,
  SORT_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'
import { MultiSelectFilter } from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'
import {
  DataTable,
  CondensedDataTable,
  Button,
} from '@pulse-analytics/pulse-design-system'
import { AlphaColors } from '@pulse-analytics/pulse-design-system/dist/foundations/Colors'

import Color from 'frontend/utils/color'

import { usePayerPolicyTrackingProjects } from 'frontend/api'

import Dialog from 'frontend/components/Dialog'

const { STRING } = SORT_TYPES
const { MULTI_SELECT } = FILTER_TYPES
const COL_CONFIG = [
  {
    Header: 'Date Created',
    accessor: 'created_at',
    sortType: STRING,
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    cellValueFormatter: (value) => format(new Date(value), 'yyyy-MM-dd'),
  },
  {
    Header: 'Payer',
    accessor: 'name',
    sortType: STRING,
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
  },
]

const TABLE_ID = 'payer-policy-link-tracking-projects-table'

const HeaderContainer = styled.div({
  display: 'flex',
  padding: 24,
  borderBottom: `1px solid ${Color.GRAY_MEDIUM}`,
  justifyContent: 'space-between',
  alignItems: 'center',
})

const HeaderText = styled.h3({
  fontWeight: 600,
  fontSize: 20,
  lineHeight: '28px',
  letterSpacing: -0.2,
})

const HeaderSubtitle = styled.h4({
  color: Color.GRAY_DARK,
  lineHeight: '12px',
  fontSize: 12,
  fontWeight: 500,
  margin: '6px 0',
})

const ManageProjectsModal = ({ closeModal }) => {
  const { data, isLoading } = usePayerPolicyTrackingProjects()

  let tableData = []
  if (!isLoading) {
    tableData = data.productPayerPolicyTrackingProjects
  }

  return (
    <Dialog>
      <HeaderContainer>
        <section>
          <HeaderText>Manage Projects</HeaderText>
          <HeaderSubtitle>
            Manage projects, tracking preferences and email alert settings
          </HeaderSubtitle>
        </section>
        <section>
          <Button
            type="secondary"
            onClick={closeModal}
            style={{ padding: '8px 12px' }}
          >
            Close
          </Button>
        </section>
      </HeaderContainer>
      <CondensedDataTable
        tableId={TABLE_ID}
        data={tableData}
        isLoading={isLoading}
        isFocusable
        isHoverable
        hasReset
        colConfig={COL_CONFIG}
      />
    </Dialog>
  )
}

export default ManageProjectsModal
