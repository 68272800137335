import * as GQL_TAGS from './gql-tags'

export const getNccnPanelConfigs = (deleteOptions = {}, queryInput: any) => ({
  createConfig: {
    tag: GQL_TAGS.CREATE_NCCN_PANEL,
    readTag: GQL_TAGS.GET_NCCN_PANELS,
  },
  updateConfig: {
    tag: GQL_TAGS.UPDATE_NCCN_PANEL,
  },
  deleteConfig: {
    tag: GQL_TAGS.DELETE_NCCN_PANEL,
    readTag: GQL_TAGS.GET_NCCN_PANELS,
    mutationOptions: deleteOptions,
  },
  readConfig: {
    tag: GQL_TAGS.GET_NCCN_PANELS,
    input: queryInput,
  },
})
