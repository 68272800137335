import React, { useState } from 'react'

import {
  DataTableHeader,
  DataTableContainer,
  DataTable,
} from 'frontend/components'

import PeoplePowerSelect from 'frontend/components/BoPowerSelect/PeoplePowerSelect'
import { ErrorDialog } from 'frontend/components/Dialog'

import CreateModal, { CreateButton } from './CreateModal'
import DeleteDialog from './DeleteDialog'
import UpdateModal from './UpdateModal'

interface NccnPanelAffiliationsProps {
  exportConfig: any
  colConfig: any
  data: any[]
  isLoading: boolean
}

const TEXT = {
  DATA_TABLE_HEADER: 'NCCN Panel Affiliations',
  EXCEL_EXPORT_FILE_LABEL: 'NCCN Panel Affiliations',
  TABLE_ID: 'nccn-panel-affiliations-table',
}

const NccnPanelAffiliations = ({
  data,
  isLoading,
  colConfig,
  exportConfig,
}: NccnPanelAffiliationsProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const utilityBarNodes = [<CreateButton />]

  return (
    <DataTableContainer>
      <CreateModal setErrorMessage={setErrorMessage} />
      <UpdateModal setErrorMessage={setErrorMessage} />
      <ErrorDialog
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <DeleteDialog />
      <DataTableHeader header={TEXT.DATA_TABLE_HEADER}>
        <PeoplePowerSelect />
      </DataTableHeader>
      <DataTable
        data={data}
        isLoading={isLoading}
        utilityBarNodes={utilityBarNodes}
        colConfig={colConfig}
        excelLabel={TEXT.EXCEL_EXPORT_FILE_LABEL}
        exportConfig={exportConfig}
        tableId={TEXT.TABLE_ID}
      />
    </DataTableContainer>
  )
}

export default NccnPanelAffiliations
