import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

import ClientsPanelHeader from './ClientsPanelHeader'
import ClientsListPanel from './ClientsListPanel'

const BORDER = `1px solid ${transparentize(0.9, Colors.BLACK)}`

const ClientsPanelWrapper = styled.div({
  flex: 1,
  paddingBottom: 24,
  borderRight: BORDER,
  '@media (max-width: 1000px)': {
    flex: 0,
  },
})

const ClientsPanel = () => (
  <ClientsPanelWrapper>
    <ClientsPanelHeader />
    <ClientsListPanel />
  </ClientsPanelWrapper>
)

export default ClientsPanel
