import getInjectables from './getInjectables'
import injectExportConfig from './injectExportConfig'
import injectColConfig from './injectColConfig'

const getColConfigs = (
  providerKdmData: any,
  addDeleteSearchString: any,
  onExpandClick: () => void
) => {
  const injectables = getInjectables(
    providerKdmData,
    addDeleteSearchString,
    onExpandClick
  )
  const colConfig = injectColConfig(injectables)
  const exportConfig = injectExportConfig(injectables)
  return { colConfig, exportConfig }
}

export default getColConfigs
