import { INPUT_TYPES } from 'frontend/components/Form/constants/input-type-to-component-map'

const getFormConfig = () => [
  [
    {
      accessor: 'slug',
      label: 'Slug',
      inputType: INPUT_TYPES.TEXT,
      isRequired: true,
    },
    {
      accessor: 'name',
      label: 'Organization',
      inputType: INPUT_TYPES.TEXT,
      isRequired: true,
    },
    {
      accessor: 'name_tiny',
      label: 'Short Name',
      inputType: INPUT_TYPES.TEXT,
    },
  ],
]

export default getFormConfig
