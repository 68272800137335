import React from 'react'

import { DeleteDialog } from 'frontend/components'

import { useNccnPanelsData } from './utils'

const LABEL = 'Delete NCCN Panel'

const SEARCH_STRING_PARAMS = ['deleteNccnPanel', 'nccnPanelId']

const DeleteDialogNccnPanel = () => {
  const {
    nccnPanels: { data, delete: destroy, isDeleting, isLoading },
  } = useNccnPanelsData({ searchStringParams: SEARCH_STRING_PARAMS })

  const nccnPanel = data ? data[0] : undefined

  const handleDelete = () => {
    const input = { id: nccnPanel.id }
    destroy({ variables: { input } })
  }

  const entityDescription = nccnPanel ? nccnPanel.name : ''

  return (
    <DeleteDialog
      isDeleting={isDeleting}
      isLoading={isLoading}
      entityDescription={entityDescription}
      handleDelete={handleDelete}
      label={LABEL}
      searchStringParams={SEARCH_STRING_PARAMS}
    />
  )
}

export default DeleteDialogNccnPanel
