import gql from 'graphql-tag'

export const CREATE_BULK_USERS = gql`
  mutation CreateBulkUsers($input: CreateBulkUsersInput!) {
    createBulkUsers(input: $input) {
      id
      firstName
      lastName
      username
      email
      loginStatus
      teams {
        id
        name
        default_landing_page
      }
      client {
        id
        name
      }
      primaryTeam
      defaultLanding
      emailSubscriptions {
        _id
        type
      }
      isCustomOncologyKdmToolScorer
      app_metadata {
        clientSources
        isCustomOncologyKdmToolScorer
      }
    }
  }
`

export const UPDATE_USER_PRIMARY_CLIENT_TEAM = gql`
  mutation UpdateUserPrimaryClientTeam(
    $input: UpdateUserPrimaryClientTeamInput!
  ) {
    updateUserPrimaryClientTeam(input: $input) {
      _id
      firstName
      lastName
      username
      email
      client {
        _id
        name
        description
        icon
      }
      emailSubscriptions {
        _id
        type
      }
      defaultLanding {
        locked
        path
      }
      primaryClientTeamVegaId
      app_metadata {
        clientSources
        isCustomOncologyKdmToolScorer
      }
    }
  }
`
