import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import DeleteDialog from './DeleteDialog'

const DeleteDialogContainer = ({ searchStringParams, ...props }) => {
  const isModalOpen = useIsModalOpen(searchStringParams)
  if (!isModalOpen) return null
  return <DeleteDialog searchStringParams={searchStringParams} {...props} />
}

export default DeleteDialogContainer

const useIsModalOpen = (searchStringParams) => {
  const location = useLocation()
  const queryStringArgs = queryString.parse(location.search)

  for (const param of searchStringParams) {
    const arg = queryStringArgs[param]
    if (!arg) return false
  }

  return true
}
