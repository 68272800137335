import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import queryString from 'query-string'
import Select from 'react-select'

import { GET_SOURCE_TOOLS, GET_LISTS_CONFIG } from 'frontend/api/queries'

const SEARCH_BAR_PLACEHOLDER_TEXT = 'Search Lists, List Keys, List Labels'

const ListsConfigSearchBar = () => {
  const [searchOptions, setSearchOptions] = useState([])

  const history = useHistory()

  const {
    data: dashboardToolsData,
    loading: isDashboardToolsLoading,
  } = useQuery(GET_SOURCE_TOOLS)
  const { data: listsConfigData, loading: isListsConfigLoading } = useQuery(
    GET_LISTS_CONFIG
  )
  const isLoading = isDashboardToolsLoading || isListsConfigLoading

  const handleSearchSelection = ({ value }) => {
    history.push({
      search: queryString.stringify(value),
    })
  }

  const handleSearchChange = (obj) => {
    let newListsConfigs = []

    if (obj && listsConfigData) {
      listsConfigData.listsConfig.forEach((listsConfig) => {
        const isIncludedListsConfig = listsConfig.listId.includes(obj)

        listsConfig.labelKeys.forEach((labelKey) => {
          if (
            isIncludedListsConfig ||
            labelKey.labelKey.includes(obj) ||
            (labelKey.labelName && labelKey.labelName.includes(obj))
          ) {
            newListsConfigs.push({
              label: getListsConfigOptionLabel(
                listsConfig,
                labelKey,
                dashboardToolsData
              ),
              value: {
                toolId: listsConfig.toolId,
                listsConfigId: listsConfig._id,
                listItemKey: labelKey.labelKey,
              },
            })
          }
        })
      })
    }

    setSearchOptions(newListsConfigs)
  }

  const searchBarStyles = {
    container: (base) => ({ ...base, flex: 1 }),
    control: (base) => ({ ...base, height: 40 }),
    singleValue: (base) => ({ ...base, fontStyle: 'italic', opacity: 0.5 }),
  }

  return (
    <Select
      styles={searchBarStyles}
      value={{ label: SEARCH_BAR_PLACEHOLDER_TEXT }}
      onChange={handleSearchSelection}
      onInputChange={handleSearchChange}
      options={searchOptions}
      isDisabled={isLoading}
    />
  )
}

export default ListsConfigSearchBar

const getListsConfigOptionLabel = (
  listsConfig,
  labelKey,
  dashboardToolsData
) => {
  return (
    `Dashboard Tool: ${getDashboardToolLabel(
      dashboardToolsData.nodes,
      listsConfig.toolId
    )} | List: ${listsConfig.listId} ` +
    `| List Item Key: ${labelKey.labelKey} | List Item Label: ${labelKey.labelName}`
  )
}

const getDashboardToolLabel = (nodes, toolId) => {
  const dashboardTool = nodes.find(({ _id }) => _id === toolId)
  const dashboardToolLabel = dashboardTool.text.title
  return dashboardToolLabel
}
