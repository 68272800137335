import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import _ from 'lodash'

import { GET_USERS, GET_VEGA_CLIENT_TEAMS } from 'frontend/api/queries'

import Spinner from 'frontend/components/Spinner'
import Header from 'frontend/components/Header'
import MultiSelectColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/MultiSelectColumnFilter'
import customMultiSelectFilterFn from 'frontend/components/Table/custom-filters/MultiSelect/customMultiSelectFilterFn'
import { EXPAND } from 'frontend/components/DataTable/Cells/cellTypes'
import DataTable from 'frontend/components/DataTable'

import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'

import EditUserForm from './EditUserForm'

const HeaderWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: Spacing.S7,
})

const Users = () => {
  const { clientId, clientTeamId } = useParams()

  const [teamsMap, setTeamsMap] = useState({})
  const [tableData, setTableData] = useState([])
  const [selectedRowId, setSelectedRowId] = useState(null)

  const { data: teamsData, loading: teamsLoading } = useQuery(
    GET_VEGA_CLIENT_TEAMS,
    {
      variables: { clientId: clientId },
    }
  )

  const { data, loading } = useQuery(GET_USERS, {
    variables: { teamUuid: clientTeamId },
  })

  useEffect(() => {
    const teamsMap = {}

    if (!teamsLoading) {
      teamsData.vegaClientTeams.forEach(({ id, name }) => {
        teamsMap[id] = name
      })
    }

    setTeamsMap(teamsMap)
  }, [teamsData]) //eslint-disable-line

  useEffect(() => {
    const tableData = []

    if (!loading && !teamsLoading) {
      data.users.forEach(
        ({ _id, username, firstName, lastName, primaryClientTeamVegaId }) => {
          tableData.push({
            _id,
            username,
            firstName,
            lastName,
            primaryClientTeamName: primaryClientTeamVegaId
              ? teamsMap[primaryClientTeamVegaId] || null
              : null,
          })
        }
      )
    }

    setTableData(tableData)
  }, [data, teamsMap]) //eslint-disable-line

  const onRowClick = (row) => {
    const { original } = row
    setSelectedRowId(original._id)
  }

  let selectedData = {}
  if (selectedRowId) {
    const rowData = data.users.find(({ _id }) => _id === selectedRowId)
    rowData.primaryClientTeam = teamsMap[rowData.primaryClientTeamVegaId]
    if (rowData) selectedData = rowData
  }

  const closeHandler = () => {
    setSelectedRowId(null)
  }

  const COLUMNS = [
    {
      Header: 'Username',
      accessor: 'username',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      sticky: 'left',
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
    },
    {
      Header: 'Last Name',
      accessor: 'lastName',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
    },
    {
      Header: 'Primary Client Team',
      accessor: 'primaryClientTeamName',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
  ]

  const table =
    loading || teamsLoading ? (
      <>
        <Spinner />
      </>
    ) : (
      <>
        <DataTable data={tableData} columns={COLUMNS} />
        {!_.isEmpty(selectedData) && (
          <EditUserForm
            selectedUserData={selectedData}
            closeHandler={closeHandler}
          />
        )}
      </>
    )

  return (
    <div>
      <HeaderWrapper>
        <Header
          header="Users"
          subheader="Select a table row to view and edit User"
          headerStyle={{ ...FontSpace.FS5 }}
        />
      </HeaderWrapper>
      {table}
    </div>
  )
}

export default Users
