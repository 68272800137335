import React from 'react'

import Modal from 'frontend/components/Modal'

import ModalContent from './ModalContent'

interface ResourcesModalProps {
  closeModal: () => void
}

const ResourcesModal = ({ closeModal }: ResourcesModalProps) => {  
  return (
    <Modal
      disableHeader
      show
      handleClose={closeModal}
      modalStyle={{
        padding: 0,
        width: '80%',
        height: '90%',
        top: '45%',
        justifyContent: 'flex-start',
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ModalContent
        closeModal={closeModal}
      />
    </Modal>
  )
}

export default ResourcesModal
