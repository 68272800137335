import React from 'react'
import {
  MultiSelectFilter,
  SingleSelectFilter,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'

import {
  SORT_TYPES,
  FILTER_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'

export const formatTableData = (data) =>
  data.map(({ teams, _id: { client, indication, regimen, tool } }) => {
    return {
      teams,
      client,
      indication,
      regimen,
      tool,
    }
  })
const { STRING, TEXT_ARRAY } = SORT_TYPES

const { MULTI_SELECT, SINGLE_SELECT } = FILTER_TYPES

export const COL_CONFIG = [
  {
    Header: 'Client',
    accessor: 'client',
    sortType: STRING,
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
  },
  {
    Header: 'Tool',
    accessor: 'tool',
    sortType: STRING,
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
  },
  {
    Header: 'Indication',
    accessor: 'indication',
    sortType: STRING,
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
  },
  {
    Header: 'Regimen',
    accessor: 'regimen',
    width: 300,
    sortType: STRING,
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
  },
  {
    Header: 'Teams',
    accessor: 'teams',
    width: 300,
    sortType: TEXT_ARRAY,
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    // Filter: SingleSelectFilter,
    // filter: SINGLE_SELECT,
    labelMap: {
      // * For Astrazeneca only
      '[TDG] Pathways APM': 'Mapped Label for [TDG] Pathways APM ✌️',
      '[TDG] Pathways APM BC': 'Mapped Label for [TDG] Pathways APM BC ✌️',
      '[TDG] Payer BC': 'Mapped Label for [TDG] Payer BC ✌️',
      '[TDG] Daiichi Payer Gastric':
        'Mapped Label for [TDG] Daiichi Payer Gastric ✌️',
      '[TDG] Provider Daiichi BC':
        'Mapped Label for [TDG] Provider Daiichi BC ✌️',
    },
    // cellValueFormatter: (cellValue) => {
    //   return (
    //     <div
    //       style={{
    //         display: 'flex',
    //         flexDirection: 'column',
    //         alignItems: 'flex-start',
    //       }}
    //     >
    //       {cellValue.map((subValue) => (
    //         <div
    //           key={subValue}
    //           style={{
    //             background: 'lightblue',
    //             padding: '4px 8px',
    //             margin: 4,
    //             borderRadius: 10,
    //           }}
    //         >
    //           {subValue}
    //         </div>
    //       ))}
    //     </div>
    //   )
    // },
  },
]
