import React from 'react'

import { EmberDeleteDialog } from 'frontend/components'
import { useQueryString } from 'frontend/hooks'
import { useEmberUser } from 'frontend/entity-hooks/ember'

const HEADER_TEXT = 'Delete User?'
const BODY_TEXT =
  "Are you sure you'd like to delete this user? This action cannot be undone"
const DELETE_PARAMS = ['user']

export const DeleteUserDialog = () => {
  const { user }: any = useQueryString()
  const { destroy } = useEmberUser()

  const handleSubmit = () => {
    const input = { id: user }
    destroy({ variables: { input } })
  }

  return (
    <EmberDeleteDialog
      hasHazardIcon
      headerText={HEADER_TEXT}
      bodyText={BODY_TEXT}
      deleteParams={DELETE_PARAMS}
      handleSubmit={handleSubmit}
    />
  )
}
