import React, { Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors, Spacing } from 'frontend/utils/pulseStyles'

import IndicationsListPanel from './IndicationsListPanel'

interface Props {
  areParentResourcesLoading: boolean
  parentResources: any[]
  setResources: Dispatch<SetStateAction<any>>
  treatmentPlans?: any[]
}

const IndicationsPanelContainer = styled.div({
  borderRight: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
  borderTop: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
  flex: 1,
  background: Colors.WHITE,
})

const ListHeader = styled.div({
  fontSize: 16,
  fontWeight: 700,
  lineHeight: '22px',
  padding: `${Spacing.NORMAL} ${Spacing.LARGE}`,
})

const IndicationsPanel = (props: Props) => {
  return (
    <IndicationsPanelContainer>
      <ListHeader>
        Indications
      </ListHeader>
      <div style={{ height: 'calc(100% - 46px)', overflowY: 'auto' }}>
        <IndicationsListPanel
          {...props}
        />
      </div>
    </IndicationsPanelContainer>
  )
}

export default IndicationsPanel
