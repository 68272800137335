import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Accounts from './Accounts'
import AffiliateEmr from './AffiliateEmr'
import AffiliateSites340bStatus from './AffiliateSites340bStatus'
import ClinicalTrials from './ClinicalTrials/ClinicalTrialProviders'
import KeyDecisionMakers from './KeyDecisionMakers'
import CmsProviderStaff from './CmsProviderStaffing/CmsProviderStaff'

const Provider = () => (
  <Switch>
    <Route
      path={'/orion/organizations/provider/accounts'}
      component={Accounts}
    />
    <Route
      path={'/orion/organizations/provider/affiliate-emr'}
      component={AffiliateEmr}
    />
    <Route
      path={'/orion/organizations/provider/affiliate-sites-340b-status'}
      component={AffiliateSites340bStatus}
    />
    <Route
      path={'/orion/organizations/provider/clinical-trials'}
      component={ClinicalTrials}
    />
    <Route
      path={'/orion/organizations/provider/key-decision-makers'}
      component={KeyDecisionMakers}
    />
    <Route
      path={'/orion/organizations/provider/staffing-cms'}
      component={CmsProviderStaff}
    />
    <Redirect to={'/orion/organizations/provider/accounts'} />
  </Switch>
)

export default Provider
