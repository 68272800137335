import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import { GET_INSTITUTIONS } from 'frontend/api/queries'

import Spinner from 'frontend/components/Spinner'
import DataTable from 'frontend/components/DataTable'
import MultiSelectColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/MultiSelectColumnFilter'
import customMultiSelectFilterFn from 'frontend/components/Table/custom-filters/MultiSelect/customMultiSelectFilterFn'
import { EXPAND } from 'frontend/components/DataTable/Cells/cellTypes'

import EditInstitutionForm from './EditInstitutionForm'

const InstitutionsTable = () => {
  const [selectedRowId, setSelectedRowId] = useState(null)
  const { data, loading } = useQuery(GET_INSTITUTIONS)

  const onRowClick = (row) => {
    const { original } = row
    setSelectedRowId(original.id)
  }
  const tableData = !loading ? data.vegaInstitutions : []

  let selectedInstitutionData = {}
  if (selectedRowId) {
    const rowData = data.vegaInstitutions.find(({ id }) => id === selectedRowId)
    if (rowData) selectedInstitutionData = rowData
  }

  const closeHandler = () => {
    setSelectedRowId(null)
  }

  const COLUMNS = [
    {
      Header: 'ID',
      accessor: 'id',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
    },
    {
      Header: 'Name',
      accessor: 'name',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
  ]

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <DataTable data={tableData} columns={COLUMNS} />
          {selectedRowId && (
            <EditInstitutionForm
              selectedInstitutionData={selectedInstitutionData}
              closeHandler={closeHandler}
            />
          )}
        </>
      )}
    </>
  )
}

export default InstitutionsTable
