import React from 'react'

import { useQueryString } from 'frontend/hooks'
import { PolarisDialog } from 'frontend/components'

import { PrefilledPolicy } from './modals'

const ImportModal = () => {
  const { importModal }: { importModal?: boolean } = useQueryString()

  if (!importModal) return null

  return (
    <PolarisDialog>
      <PrefilledPolicy />
    </PolarisDialog>
  )
}

export default ImportModal
