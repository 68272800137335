import { INPUT_TYPES } from 'frontend/components/Form/constants/input-type-to-component-map'

export const getFormConfig = ({
  peopleOptions,
  providersOptions,
  indicationsOptions,
  keyDecisionMakerTypeOptions,
}: any) => [
    [
      {
        accessor: 'person',
        label: 'Person',
        inputType: INPUT_TYPES.SINGLE_SELECT,
        options: peopleOptions,
        isRequired: true,
      },
      {
        accessor: 'provider_organization',
        label: 'Provider',
        inputType: INPUT_TYPES.SINGLE_SELECT,
        options: providersOptions,
        isRequired: true,
      },
      {
        accessor: 'indications',
        label: 'Indications',
        inputType: INPUT_TYPES.MULTI_SELECT,
        options: indicationsOptions,
        isRequired: true,
      },
    ],
    [
      {
        accessor: 'is_excluded',
        label: 'Is Excluded',
        inputType: INPUT_TYPES.CHECKBOX,
      },
    ],
    [
      {
        accessor: 'key_decision_maker_types',
        label: 'Key Decision Maker Types',
        inputType: INPUT_TYPES.MULTI_SELECT,
        options: keyDecisionMakerTypeOptions,
        isRequired: true,
      },
      {
        accessor: 'title',
        label: 'Title',
        inputType: INPUT_TYPES.TEXT,
        isRequired: true,
      },
      {
        accessor: 'specialty',
        label: 'Specialty',
        inputType: INPUT_TYPES.TEXT,
        isRequired: true,
      },
      {
        accessor: 'tdg_internal_note',
        label: 'Internal Notes',
        inputType: INPUT_TYPES.TEXT,
      },
    ],
    [
      {
        accessor: 'start_date',
        label: 'Start Date',
        inputType: INPUT_TYPES.DATE,
        isRequired: true,
      },
      {
        accessor: 'end_date',
        label: 'End Date',
        inputType: INPUT_TYPES.DATE,
      },
    ],
  ]
