import React from 'react'
import Switch from '@material-ui/core/Switch'
import styled from '@emotion/styled'
import { withStyles } from '@material-ui/core/styles'
import { transparentize, mix } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons'
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc'

import { Colors, Spacing } from '../../../../utils/pulseStyles'

import '../sortableContainerStyles.css'

import {
  IndicationPanelContainer,
  ListHeader,
  ActiveRow,
  InactiveRow,
  UnorderedList,
  ListRow,
} from '../styledComponents'

import { Span } from '../styledComponents'

const SelectedIndication = styled.span({
  color: Colors.PRIMARY,
  textTransform: 'normal',
})

const DragHandle = sortableHandle(() => (
  <span
    style={{
      padding: `${Spacing.SMALL} ${Spacing.NORMAL}`,
      opacity: 0.3,
      fontSize: 16,
    }}
  >
    <FontAwesomeIcon icon={faArrowsAltV} color={Colors.BLACK} />
  </span>
))

const switchColor = Colors.GREEN

// Material UI Custom Switch Styling
const PhoenixSwitch = withStyles({
  switchBase: {
    color: mix(0.4, Colors.BLACK, Colors.WHITE),
    '&$checked': {
      color: switchColor,
    },
    '&$checked + $track': {
      backgroundColor: switchColor,
    },
  },
  checked: {},
  track: {
    backgroundColor: transparentize(0.7, Colors.BLACK),
  },
})(Switch)

const SortableItem = sortableElement(
  ({ regimen, disableRegimen, selectedRegimenId, selectRegimen }) => {
    const isSelected = regimen._id === selectedRegimenId
    return (
      <ListRow
        isSelected={isSelected}
        isActive
        onClick={() => selectRegimen(regimen)}
      >
        <div>
          <DragHandle />
          <Span isSelected={isSelected}>{regimen.name}</Span>
        </div>
        <PhoenixSwitch
          checked
          value={regimen._id}
          onChange={() => disableRegimen(regimen)}
        />
      </ListRow>
    )
  }
)

const SortableContainer = sortableContainer(({ children }) => {
  return <UnorderedList>{children}</UnorderedList>
})

const RegimensPanel = ({
  selectedIndicationName,
  selectedRegimenId,
  selectRegimen,
  enabledRegimens,
  disabledRegimens,
  enableRegimen,
  disableRegimen,
  onSortEnd,
}) => {
  return (
    <IndicationPanelContainer>
      <ListHeader>
        Regimens /{' '}
        <SelectedIndication>{selectedIndicationName}</SelectedIndication>
      </ListHeader>

      <ActiveRow>ACTIVE ({enabledRegimens.length})</ActiveRow>

      <SortableContainer
        onSortEnd={onSortEnd}
        helperClass="sortableHelper"
        useDragHandle
      >
        {enabledRegimens.map((regimen, index) => (
          <SortableItem
            key={regimen._id}
            index={index}
            regimen={regimen}
            disableRegimen={disableRegimen}
            selectRegimen={selectRegimen}
            selectedRegimenId={selectedRegimenId}
          />
        ))}
      </SortableContainer>

      <InactiveRow>INACTIVE ({disabledRegimens.length})</InactiveRow>
      <SortableContainer>
        {disabledRegimens.map((regimen) => {
          const isSelected = regimen._id === selectedRegimenId
          return (
            <ListRow
              isSelected={isSelected}
              key={regimen._id}
              onClick={() => selectRegimen(regimen)}
            >
              <span style={{ fontWeight: 500 }}>{regimen.name}</span>
              <PhoenixSwitch
                checked={false}
                color="primary"
                value={regimen._id}
                onChange={() => enableRegimen(regimen)}
              />
            </ListRow>
          )
        })}
      </SortableContainer>
    </IndicationPanelContainer>
  )
}

export default RegimensPanel
