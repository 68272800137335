import { useQuery } from '@apollo/client'
import _ from 'lodash'
import {
  GET_FLAT_FILE_EXPORT_INDICATIONS,
  GET_PAYER_ORGANIZATIONS,
  GET_PATHWAYS_ORGANIZATIONS,
  GET_APM_ORGANIZATIONS,
  GET_PROVIDER_ORGANIZATIONS,
  GET_POPULATIONS,
  GET_LINES,
  GET_PAYER_FLAT_FILE_TREATMENT_PLANS,
  GET_BOOKS,
  GET_OBM_ORGANIZATIONS,
  GET_LBM_ORGANIZATIONS,
} from 'frontend/api/queries'
import { useClient, useEmberNode } from 'frontend/entity-hooks/ember'

const useFlatFileList = () => {
  const { data: clientsData, isLoading: clientsLoading } = useClient()

  const { data: toolsData, isLoading: toolsLoading } = useEmberNode({
    queryInput: { type: 'tool' },
  })

  const { data: indicationsData, loading: indicationsLoading } = useQuery(
    GET_FLAT_FILE_EXPORT_INDICATIONS
  )

  const { data: apmData, loading: apmLoading } = useQuery(GET_APM_ORGANIZATIONS)

  const { data: pathwaysData, loading: pathwaysLoading } = useQuery(
    GET_PATHWAYS_ORGANIZATIONS
  )

  const { data: payerData, loading: payerLoading } = useQuery(
    GET_PAYER_ORGANIZATIONS
  )

  const { data: providerData, loading: providerLoading } = useQuery(
    GET_PROVIDER_ORGANIZATIONS
  )

  const { data: treatmentPlans, loading: treatmentPlansLoading } = useQuery(
    GET_PAYER_FLAT_FILE_TREATMENT_PLANS
  )

  const { data: populations, loading: populationsLoading } = useQuery(
    GET_POPULATIONS
  )

  const { data: lineOfTherapies, loading: lineOfTherapiesLoading } = useQuery(
    GET_LINES
  )

  const { data: bookOfBusiness, loading: bookOfBusinessLoading } = useQuery(
    GET_BOOKS
  )

  const {
    data: obmOrganizationsData,
    loading: obmOrganizationsLoading,
  } = useQuery(GET_OBM_ORGANIZATIONS)

  const {
    data: lbmOrganizationsData,
    loading: lbmOrganizationsLoading,
  } = useQuery(GET_LBM_ORGANIZATIONS)

  const loading = [
    clientsLoading,
    toolsLoading,
    indicationsLoading,
    apmLoading,
    pathwaysLoading,
    payerLoading,
    providerLoading,
    populationsLoading,
    lineOfTherapiesLoading,
    treatmentPlansLoading,
    bookOfBusinessLoading,
    obmOrganizationsLoading,
    lbmOrganizationsLoading,
  ].some((loading) => loading)

  let data = {}
  if (!loading) {
    data = {
      clients: clientsData,
      tools: toolsData,
      phoenixTreatmentPlans: indicationsData.indications,
      apmAccounts: apmData.apmOrganizations,
      pathwayAccounts: pathwaysData.pathwaysOrganizations,
      payerAccounts: payerData.payerOrganizations,
      providerAccounts: providerData.providerOrganizations,
      subTypes: populations.populations,
      lineOfTherapies: lineOfTherapies.lines,
      payerFlatFileTreatmentPlans: treatmentPlans.payerFlatFileTreatmentPlans,
      booksOfBusiness: bookOfBusiness.books,
      mbmAccounts: _.concat(
        obmOrganizationsData.obmOrganizations,
        lbmOrganizationsData.lbmOrganizations
      ),
    }
  }

  return {
    loading,
    data,
  }
}

export default useFlatFileList
