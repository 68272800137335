import * as GQL_TAGS from './gql-tags'

export const getClientConfigs = (
  deleteOptions = {},
  queryInput: any
) => ({
  createConfig: {
    tag: GQL_TAGS.CREATE_CLIENT,
    readTag: GQL_TAGS.GET_CLIENTS,
  },
  updateConfig: {
    tag: GQL_TAGS.UPDATE_CLIENT,
  },
  deleteConfig: {
    tag: GQL_TAGS.DELETE_CLIENT,
    readTag: GQL_TAGS.GET_CLIENTS,
    mutationOptions: deleteOptions,
  },
  readConfig: {
    tag: GQL_TAGS.GET_CLIENTS,
    input: queryInput,
  },
})
