import React from 'react'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

import LabelGroup from './LabelGroup'
import ButtonGroup from './ButtonGroup'

interface Props {
  sitemap: any
}

const Wrapper = styled.div({
  padding: 20,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `2px solid ${Colors.COOL_GRAY_200}`,
})

const Header = (props: Props) => {
  return (
    <Wrapper>
      <LabelGroup />
      <ButtonGroup
        {...props}
      />
    </Wrapper>
  )
}

export default Header
