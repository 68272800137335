import { Sitemap, Type } from '../types'

type Key = 'tools' | 'dashboards' | 'pages' | 'cards'

const NODE_TYPES: Type[] = ['tool', 'dashboard', 'page', 'card']

export const getSitemap = (sitemap: any): Sitemap => NODE_TYPES.reduce(
  (acc: Sitemap, nodeType: Type) => {
    const key: Key = `${nodeType}s`
    const nodes = sitemap[key].reduce((acc: Set<string>, teamNode: any) => {
      acc.add(teamNode[nodeType])
      return acc
    }, new Set())
    acc[key] = nodes
    return acc
  }, {
  tools: new Set(),
  dashboards: new Set(),
  pages: new Set(),
  cards: new Set(),
})
