import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

export const selectStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: Colors.COOL_GRAY_100,
    border: 'none',
    minHeight: 30,
    height: 30,
  }),
  input: (provided: any) => ({
    ...provided,
    margin: 0,
  }),
  menuList: (provided: any) => ({
    ...provided,
    fontSize: 12,
  }),
  menu: (provided: any) => ({
    ...provided,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontSize: 13,
    color: Colors.GRAY_600,
    padding: '0 8px',
    minHeight: 30,
    height: 30,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: Colors.GRAY_500,
    fontSize: 12,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: transparentize(0.7, Colors.BLACK),
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: 30,
  }),
  multiValue: (provided: any) => ({
    ...provided,
    color: Colors.BLACK,
    backgroundColor: transparentize(0.85, '#79818C'),
    borderRadius: 24,
    fontWeight: 600,
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
  }),
  clearIndicator: () => ({
    display: 'none',
  }),
}
