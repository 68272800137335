import React from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useQuery, useMutation } from '@apollo/client'

import { GET_SOURCE_TOOLS, GET_LISTS_CONFIG } from 'frontend/api/queries'
import { UPDATE_LISTS_CONFIG } from 'frontend/api/mutations'

import StructuralListPanels from 'frontend/components/StructuralListPanels'

import ListsConfigSearchBar from './ListsConfigSearchBar'
import UpdateListItemPanel from './UpdateListItemPanel'

import { getPanels, getPanelsInjectables } from './utils'

const ListsConfigManagement = () => {
  const location = useLocation()

  const {
    toolId: selectedDashboardTool,
    listsConfigId: selectedListsConfigId,
  } = (location.search && queryString.parse(location.search)) || {}

  const dashboardToolsData = useQuery(GET_SOURCE_TOOLS)

  const listsConfigData = useQuery(GET_LISTS_CONFIG, {
    variables: { toolId: selectedDashboardTool },
  })

  const [updateListItemsMutation] = useMutation(UPDATE_LISTS_CONFIG, {
    onError: alert,
  })

  const panelsInjectables = getPanelsInjectables({
    selectedDashboardTool,
    selectedListsConfigId,
    dashboardToolsData,
    listsConfigData,
    updateListItemsMutation,
  })

  const panels = getPanels(panelsInjectables)

  const panelHeight = 'calc(100vh - 40px)'

  return (
    <div style={{ width: '100%' }}>
      <ListsConfigSearchBar />
      <div style={{ display: 'flex', height: panelHeight }}>
        <StructuralListPanels panels={panels} />
        <UpdateListItemPanel />
      </div>
    </div>
  )
}

export default ListsConfigManagement
