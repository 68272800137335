export const formatInput = ({
  client,
  teams,
  emailSubscriptions = [],
  ...rest
}: any) => {
  const formattedTeams = teams.map(({ id, name }: any) => ({ id, name }))
  const formattedEmailSubscriptions = emailSubscriptions.map(
    ({ _id, type }: any) => ({
      _id,
      type,
    })
  )

  return {
    teams: formattedTeams,
    emailSubscriptions: formattedEmailSubscriptions,
    ...rest,
  }
}
