import React, { useState } from 'react'
import Spinner from 'frontend/components/Spinner'
import FileSaver from 'file-saver'

import { Spacing } from 'frontend/utils/pulseStyles'
import { convertIsoShortToDateObj } from 'frontend/utils/formatDate'
import { useAuth0 } from '../../../../react-auth0-spa'
import Color from '../../../utils/color'

import {
  SectionContainer,
  SectionHeader,
  StyledButton,
} from '../styledComponents'

const CARVYKTI_URL = '/api/carvykti-csv-file'

const CarvyktiPayerCsvExport = () => {
  const { accessToken } = useAuth0()
  const [carvyktiDate, setCarvyktiDate] = useState(undefined)
  const [isCarvyktiScriptLoading, setIsCarvyktiScriptLoading] = useState(false)

  const clickHandler = (url, setLoadingStatusFn) => async (e) => {
    e.preventDefault()
    setLoadingStatusFn(true)

    await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })
      .then(async (response) => ({
        blob: await response.blob(),
        filename: response.headers
          .get('Content-Disposition')
          .split('filename=')[1],
      }))
      .then(({ blob, filename }) => {
        FileSaver.saveAs(blob, filename)
      })
      .catch(console.error)

    setLoadingStatusFn(false)
  }

  const handleCarvyktiDateChange = (e) => {
    setCarvyktiDate(e.target.value)
  }

  const shouldDisableDownload = !Boolean(carvyktiDate)

  return (
    <SectionContainer>
      <SectionHeader>Carvykti Payer CSV File</SectionHeader>
      <div>
        <span style={{ paddingRight: 12 }}>Select date: </span>
        <input
          name="date"
          type="date"
          value={carvyktiDate}
          onChange={handleCarvyktiDateChange}
          style={{ marginBottom: Spacing.LARGE, marginRight: Spacing.LARGE }}
        />
      </div>
      <StyledButton
        disabled={shouldDisableDownload}
        size="small"
        onClick={clickHandler(
          CARVYKTI_URL + '/' + convertIsoShortToDateObj(carvyktiDate),
          setIsCarvyktiScriptLoading
        )}
      >
        <span style={{ margin: '0 4px 0 6px' }}>Download</span>
        {isCarvyktiScriptLoading && <Spinner fill={Color.PRIMARY} />}
      </StyledButton>
      {shouldDisableDownload && (
        <span style={{ color: 'red' }}>Select date to download</span>
      )}
    </SectionContainer>
  )
}

export default CarvyktiPayerCsvExport
