import _ from 'lodash'
interface DefaultPayerFlatFileTreatmentPlans {
  indicationId: string
  indicationName: string
  regimenId: string
  regimenName: string
  populationId: string
  populationName: string
  lineId: string
  lineName: string
}

export class PayerFlatFileTreatmentPlans {
  revamp(payerFlatFileTreatmentPlans: DefaultPayerFlatFileTreatmentPlans[]) {
    let revampRow = new Map()
    const allowedKeys = ['_id', 'name', 'regimens']
    const treatmentPlans = _.filter(
      _.cloneDeep(payerFlatFileTreatmentPlans),
      (row: any) => {
        let existingRow: any

        if (revampRow.has(row.indicationId)) {
          existingRow = revampRow.get(row.indicationId)

          const regimenIndex = existingRow.regimens.findIndex(
            (regimen: { _id: string }) => regimen._id === row.regimenId
          )
          if (regimenIndex === -1) {
            existingRow.regimens.push({
              _id: row.regimenId,
              name: row.regimenName,
              populations: [
                {
                  _id: row.populationId,
                  name: row.populationName,
                  lines: [{ _id: row.lineId, name: row.lineName }],
                },
              ],
            })
          } else {
            const populationIndex = existingRow.regimens[
              regimenIndex
            ].populations.findIndex(
              (population: { _id: string }) =>
                population._id === row.populationId
            )
            if (populationIndex === -1) {
              existingRow.regimens[regimenIndex].populations.push({
                _id: row.populationId,
                name: row.populationName,
                lines: [{ _id: row.lineId, name: row.lineName }],
              })
            } else {
              const lineIndex = existingRow.regimens[regimenIndex].populations[
                populationIndex
              ].lines.findIndex(
                (line: { _id: string }) => line._id === row.lineId
              )
              if (lineIndex === -1) {
                existingRow.regimens[regimenIndex].populations[
                  populationIndex
                ].lines.push({ _id: row.lineId, name: row.lineName })
              }
            }
          }
          return false
        }

        if (!_.isArray(row.regimens)) {
          row['regimens'] = [
            {
              _id: row.regimenId,
              name: row.regimenName,
              populations: [
                {
                  _id: row.populationId,
                  name: row.populationName,
                  lines: [{ _id: row.lineId, name: row.lineName }],
                },
              ],
            },
          ]
        }

        revampRow.set(row.indicationId, row)

        return true
      }
    ).map((item: any) => {
      item = {
        ...item,
        _id: item.indicationId,
        name: item.indicationName,
      }
      _.each(_.keys(item), (keyName: string) => {
        if (!allowedKeys.includes(keyName)) {
          delete item[keyName]
        }
      })
      return item
    })
    return treatmentPlans
  }
}
