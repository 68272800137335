import React from 'react'
import styled from '@emotion/styled'

import { useQueryString } from 'frontend/hooks'
import { Colors } from 'frontend/utils/pulseStyles'

import NodeLabel from './NodeLabel'

const Wrapper = styled.h2({
  color: Colors.BLACK,
  fontSize: 14,
  fontWeight: 700,
})

const NodeLabelWrapper = styled.span({
  color: Colors.PRIMARY,
})

const Title = () => {
  const { node } = useQueryString()

  const modalType = node ? 'Editing' : 'Creating'
  const label = `${modalType} Source Node / `

  return (
    <Wrapper>
      {label}
      <NodeLabelWrapper>
        <NodeLabel />
      </NodeLabelWrapper>
    </Wrapper>
  )
}

export default Title
