import { transparentize } from 'polished'

import {
  DateMultiSelectFilter,
  MultiSelectFilter,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'
import {
  DISPLAY_CELL_TYPES,
  EDIT_CELL_TYPES,
  FILTER_TYPES,
  SORT_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'

import { formatMonthDayYearSlash } from 'frontend/utils/formatDate'
import Color from 'frontend/utils/color'
import { ExpandIcon } from 'frontend/components/Icon'
import { StatusTag } from 'frontend/components'

const { CONDENSED_BUTTON, BUTTON } = DISPLAY_CELL_TYPES
const {
  CONDENSED_SINGLE_SELECT_INPUT,
  CONDENSED_TEXT_INPUT,
  CONDENSED_MULTI_SELECT_INPUT,
} = EDIT_CELL_TYPES
const { DATE_MULTI_SELECT, MULTI_SELECT } = FILTER_TYPES
const { DATE, DISPLAY, STRING } = SORT_TYPES

const STATUS_TO_COLOR = {
  active: Color.GREEN,
  inactive: Color.ORANGE,
}

const getColumns = ({
  onExpandClick,
  addDeleteSearchString,
  indication,
  kdmType,
  providerKdms: { update },
  isExcludedFromTools,
  providerOptions,
  providerLabelMap,
  person,
  pathwaysOrganizations,
}) => [
  {
    Header: 'Status',
    accessor: 'dynamic_status',
    sticky: 'left',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    cellValueFormatter: (value) => {
      if (!value) return null
      const upperCaseValue = value.toUpperCase()
      return <StatusTag color={STATUS_TO_COLOR[value]} value={upperCaseValue} />
    },
    sortType: STRING,
    width: 125,
  },
  {
    Header: 'First Name',
    accessor: 'person_first_name',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    sticky: getStickyStatus(1000),
    width: 140,
  },
  {
    Header: 'Last Name',
    accessor: 'person_last_name',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    sticky: getStickyStatus(1200),
    width: 140,
  },
  {
    Header: 'Provider Account',
    accessor: 'provider_organization',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: DISPLAY,
    sticky: getStickyStatus(1500),
    labelMap: providerLabelMap,
    editCellConfig: {
      type: CONDENSED_SINGLE_SELECT_INPUT,
      options: providerOptions,
      updateFn: update,
    },
    width: 200,
  },
  {
    Header: 'Title',
    accessor: 'title',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: update,
    },
    width: 200,
  },
  {
    Header: 'KDM Type(s)',
    accessor: 'key_decision_maker_types',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: DISPLAY,
    labelMap: kdmType.labelMap,
    editCellConfig: {
      type: CONDENSED_MULTI_SELECT_INPUT,
      options: kdmType.options,
      updateFn: update,
    },
    width: 200,
  },
  {
    Header: 'Specialty',
    accessor: 'specialty',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: update,
    },
    width: 200,
  },
  {
    Header: 'Indication(s)',
    accessor: 'indications',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: DISPLAY,
    labelMap: indication.labelMap,
    editCellConfig: {
      type: CONDENSED_MULTI_SELECT_INPUT,
      options: indication.options,
      updateFn: update,
    },
    width: 200,
  },
  {
    Header: 'Start Date',
    accessor: 'start_date',
    Filter: DateMultiSelectFilter,
    filter: DATE_MULTI_SELECT,
    sortType: DATE,
    cellValueFormatter: formatMonthDayYearSlash,
    width: 120,
    featureFlag: 'shouldShowPeopleTrackingStartEndDates',
  },
  {
    Header: 'End Date',
    accessor: 'end_date',
    Filter: DateMultiSelectFilter,
    filter: DATE_MULTI_SELECT,
    sortType: DATE,
    cellValueFormatter: formatMonthDayYearSlash,
    width: 120,
    featureFlag: 'shouldShowPeopleTrackingStartEndDates',
  },
  {
    Header: '',
    accessor: 'x',
    displayCellConfig: {
      type: BUTTON,
      style: {
        background: transparentize(0.9, Color.PRIMARY),
        borderRadius: 4,
        padding: 2,
        cursor: 'pointer',
      },
      label: <ExpandIcon />,
      onClick: onExpandClick,
    },
    disableSortBy: true,
    featureFlag: 'shouldShowPeopleTrackingStartEndDates',
    width: 20,
  },
  {
    Header: 'NPI',
    accessor: 'person_npi',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    featureFlag: 'shouldShowNpiKdmTable',
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: (input, row) => {
        const body = {
          id: row.person_id,
          npi: input.person_npi,
        }

        return person.update(body)
      },
    },
    width: 200,
  },
  {
    Header: 'Pathways Organization(s)',
    accessor: 'person_pathways_organizations',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: DISPLAY,
    labelMap: pathwaysOrganizations.labelMap,
    width: 200,
  },
  {
    Header: 'Twitter Handle',
    accessor: 'person_twitter_username',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: (input, row) => {
        const body = {
          id: row.person_id,
          twitter_username: input.person_twitter_username,
        }

        return person.update(body)
      },
    },
    width: 200,
  },
  {
    Header: 'External Link',
    accessor: 'person_professional_profile',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: (input, row) => {
        const body = {
          id: row.person_id,
          professional_profile: input.person_professional_profile,
        }

        return person.update(body)
      },
    },
    width: 200,
  },
  {
    Header: 'Update Log',
    accessor: 'updated_at',
    Filter: DateMultiSelectFilter,
    filter: DATE_MULTI_SELECT,
    sortType: DATE,
    cellValueFormatter: formatMonthDayYearSlash,
    width: 200,
  },
  {
    Header: 'Exclude From KDM Tool',
    accessor: 'is_excluded_from_kdm_tool',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    cellValueFormatter: (is_excluded_from_tool) =>
      is_excluded_from_tool ? 'Yes' : 'No',
    sortType: DISPLAY,
    featureFlag: 'shouldShowExcludeFromToolKdmColumn',
    editCellConfig: {
      type: CONDENSED_SINGLE_SELECT_INPUT,
      options: isExcludedFromTools.options,
      updateFn: update,
    },
    width: 200,
  },
  {
    Header: 'Exclude From All Other Tools',
    accessor: 'is_excluded',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    cellValueFormatter: (is_excluded) => (is_excluded ? 'Yes' : 'No'),
    sortType: DISPLAY,
    editCellConfig: {
      type: CONDENSED_SINGLE_SELECT_INPUT,
      options: isExcludedFromTools.options,
      updateFn: update,
    },
    width: 200,
  },
  {
    Header: 'Internal Notes',
    accessor: 'tdg_internal_note',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: update,
    },
    width: 200,
  },
  {
    Header: 'Delete',
    accessor: 'delete',
    sticky: 'left',
    displayCellConfig: {
      type: CONDENSED_BUTTON,
      label: 'Delete',
      onClick: addDeleteSearchString,
    },
    width: 200,
  },
]

export default getColumns

const getStickyStatus = (breakpoint) => {
  return window.innerWidth < breakpoint ? undefined : 'left'
}
