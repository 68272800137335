import React, { MutableRefObject } from 'react'

import { useClearSearchStringParam } from 'frontend/hooks'
import { getGraphQLErrorMessage } from 'frontend/utils'
import { useNccnPanelAffiliationsData } from '../../useNccnPanelAffiliationsData'
import { URL_PARAM_KEY } from './url-param-key'

interface useHandlersProps {
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>
  inputRef: MutableRefObject<any>
}

export const useHandlers = ({
  setErrorMessage,
  inputRef,
}: useHandlersProps) => {
  const clearSearchStringParam = useClearSearchStringParam()

  const {
    nccnPanelVotingMembers: { create, isCreating },
  } = useNccnPanelAffiliationsData()

  const cancel = () => {
    inputRef.current = {}
    clearSearchStringParam([URL_PARAM_KEY])
  }

  const handleError = (e: any) => {
    const errorMessage = getGraphQLErrorMessage(e)
    if (errorMessage === undefined) {
      alert(e)
    } else {
      setErrorMessage(errorMessage)
    }
  }

  const submit = async () => {
    const input = inputRef.current

    await create({
      variables: { input },
      onCompleted: cancel,
      onError: handleError,
    })
  }

  return {
    submit,
    isSubmitting: isCreating,
    cancel,
  }
}
