import { useEffect, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import queryString from 'query-string'

import { CrudHookOptions } from './../../interfaces/CrudHookOptions'

import * as CRUD_UTILS from '../../api/utils'

import { getProductPolicyLinkConfigs } from './getProductPolicyLinkConfigs'

export const useProductPolicyLink = ({
  isPaginated,
  deleteOptions,
  queryInput,
}: CrudHookOptions = {}) => {
  const {
    createConfig,
    deleteConfig,
    updateConfig,
    readConfig,
    readOptionsConfig,
  } = getProductPolicyLinkConfigs(deleteOptions, queryInput)

  const {
    data: productPolicyLinkOptionsData,
    loading: areOptionsLoading,
  } = CRUD_UTILS.useReadOptions(readOptionsConfig.tag, { labelMap: true })

  const [create, { loading: isCreating }] = CRUD_UTILS.useCreate(createConfig)
  const [destroy, { loading: isDeleting }] = CRUD_UTILS.useDelete(deleteConfig)
  const [update, { loading: isUpdating }] = CRUD_UTILS.useUpdate(updateConfig)

  const dataRef: any = useRef([])
  const loadingRef: any = useRef(new Set())
  const [
    queryProductPolicyLinks,
    { data: productPolicyLinksData, loading: isLoading },
  ] = useLazyQuery(readConfig.tag, {
    variables: { input: readConfig.input },
  })

  useEffect(() => {
    setTimeout(() => queryProductPolicyLinks(), 0)
  }, [])

  if (isPaginated && productPolicyLinksData)
    loadData({
      dataRef,
      loadingRef,
      productPolicyLinksData,
      queryProductPolicyLinks,
    })

  const data = isPaginated
    ? dataRef.current
    : productPolicyLinksData?.productPolicyLinks

  return {
    create,
    isCreating,
    update,
    isUpdating,
    destroy,
    isDeleting,
    data,
    isLoading,
    options: areOptionsLoading ? {} : productPolicyLinkOptionsData,
    areOptionsLoading,
  }
}

const loadData = ({
  dataRef,
  loadingRef,
  productPolicyLinksData,
  queryProductPolicyLinks,
}: any) => {
  const [productPolicyLinks] = productPolicyLinksData.productPolicyLinks
  const { results, next } = productPolicyLinks
  if (results) {
    if (!loadingRef.current.has(next)) {
      dataRef.current = [...dataRef.current, ...results]
      loadingRef.current.add(next)
      if (next) {
        const [base, search] = next.split('?')
        const input: any = queryString.parse(search)
        setTimeout(() => queryProductPolicyLinks({ variables: { input } }), 0)
      }
    }
  } else {
    dataRef.current = [productPolicyLinks]
  }
}
