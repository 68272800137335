import { MetaHistoryQueryInput } from 'frontend/interfaces'

import useRead from './useRead'

const usePathwaysInfluencersHistory = (queryInput: MetaHistoryQueryInput = {}) => {
  const {data: pathwaysInfluencersHistoryData, loading: isLoading} = useRead(queryInput)
  const data = pathwaysInfluencersHistoryData?.pathwaysInfluencersHistory
  return {
    data,
    isLoading
  }
}

export default usePathwaysInfluencersHistory
