import gql from 'graphql-tag'

export const GET_PEOPLE = gql`
  query GetPeople {
    people {
      id
      npi
      first_name
      last_name
      middle_name
      primary_state {
        id
        full_name
        abbreviation
      }
      pathways_organizations
      is_on_external_pathways
      professional_profile
      twitter_profile
      twitter_username
      perception_tool_provider
      nccn_panels
      linkedin_profile
      affiliation
      affiliationPosition
      role {
        id
        name
        default_specialty_label
      }
      created_at
      updated_at
    }
  }
`
