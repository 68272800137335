import gql from 'graphql-tag'

export const GET_INDICATIONS = gql`
  query getVegaIndications {
    vegaIndications {
      id
      name
      regimens
      is_flagged_for_data_extraction
      therapeutic_area_id
      aliases
      created_at
      updated_at
    }
  }
`
