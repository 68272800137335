import gql from 'graphql-tag'

export const CREATE_PATHWAYS_INDICATION_REGIMEN_POSITION = gql`
  mutation CreatePathwaysIndicationRegimenPosition($input: CreatePathwaysIndicationRegimenPositionInput!) {
    createPathwaysIndicationRegimenPosition(input: $input) {
      id
      pathways_organization
      indication
      regimen
      populations
      cancer_stages
      position_status
      lines_of_therapy
      relative_access
      position_note
      position_date
      alert_note
      alert_date
      created_at
      updated_at
    }
  }
`

export const UPDATE_PATHWAYS_INDICATION_REGIMEN_POSITION = gql`
  mutation UpdatePathwaysIndicationRegimenPosition($input: UpdatePathwaysIndicationRegimenPositionInput!) {
    updatePathwaysIndicationRegimenPosition(input: $input) {
      id
      pathways_organization
      indication
      regimen
      populations
      cancer_stages
      position_status
      lines_of_therapy
      relative_access
      position_note
      position_date
      alert_note
      alert_date
      created_at
      updated_at
    }
  }
`

export const DELETE_PATHWAYS_INDICATION_REGIMEN_POSITION = gql`
  mutation DeletePathwaysIndicationRegimenPosition($input: DeletePathwaysIndicationRegimenPositionInput!) {
    deletePathwaysIndicationRegimenPosition(input: $input) {
      id
      pathways_organization
      indication
      regimen
      populations
      cancer_stages
      position_status
      lines_of_therapy
      relative_access
      position_note
      position_date
      alert_note
      alert_date
      created_at
      updated_at
    }
  }
`
