import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

export const ToggleAllButtonsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: `8px 20px`,
  borderBottom: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
})
