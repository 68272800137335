import getConnectionDescriptionAndStatus from './getConnectionDescriptionAndStatus'

const joinData = ({
  groupedPathwaysOrganizationById,
  groupedPeopleById,
  groupedIndicationsById,
}) => (acc, datum) => {
  const {
    pathways_organization,
    person,
    end_date,
    is_excluded,
    exclusion_reason,
    indication_permissions,
    updated_at,
  } = datum

  const pathwaysDatum = groupedPathwaysOrganizationById[pathways_organization]
  const personDatum = groupedPeopleById[person]

  if (!personDatum || !pathwaysDatum) return

  // Append Derived Fields
  const { description, status } = getConnectionDescriptionAndStatus(
    is_excluded,
    exclusion_reason,
    end_date
  )
  const indicationPermissions = getHydratedIndicationPermissions(
    indication_permissions,
    groupedIndicationsById
  )

  acc.push({
    ...datum,
    pathwaysDatum,
    personDatum,
    updated_at: updated_at,
    exclusion_reason: description,
    status,
    indicationPermissions,
  })

  return acc
}

export default joinData

const getHydratedIndicationPermissions = (
  indication_permissions,
  groupedIndicationsById
) => {
  return indication_permissions.map(
    (indicationId) => groupedIndicationsById[indicationId].name
  )
}
