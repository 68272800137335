import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const GET_TEAMS = gql`
  query GetTeams($input: TeamInput_EMBER) {
    teams_EMBER(input: $input) {
      ${schemaV0}
    }
  }
`
