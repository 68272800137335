import React from 'react'

import { InnerContainer } from './InnerContainer'
import { PrimaryTeam } from './PrimaryTeam'

interface Props {
  fieldState: any
  onChange: any
}

export const OuterContainer = ({ fieldState, onChange }: Props) => {
  const {
    client: selectedClientId,
    teams: selectedTeams,
    primaryTeam,
  } = fieldState

  if (!selectedClientId)
    return (
      <PrimaryTeam
        options={[]}
        onChange={onChange}
        selectedPrimaryTeam={primaryTeam}
      />
    )

  return (
    <InnerContainer
      selectedClientId={selectedClientId}
      selectedTeams={selectedTeams}
      selectedPrimaryTeam={primaryTeam}
      onChange={onChange}
    />
  )
}
