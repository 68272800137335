import { useEffect } from 'react'
import _ from 'lodash'

import { UsePaginatedReadConfig } from '../interfaces'

import { useCacheData, useLazyData } from './hooks'

import emptyCacheAndGetFirstDataPage from './emptyCacheAndGetFirstDataPage'
import getDetailDatum from './getDetailDatum'

export const usePaginatedRead = (paginatedReadConfig: UsePaginatedReadConfig) => {
  // TODO: Can we deprecate isPaginated? Currently, we need it because undefined input will query full list over network.
  if (!paginatedReadConfig.isPaginated) paginatedReadConfig.isPaginated = false

  if (!paginatedReadConfig.input) paginatedReadConfig.input = {}
  if (paginatedReadConfig.isPaginated && !paginatedReadConfig.input.page_size) paginatedReadConfig.input.page_size = '2000'

  const { data: cacheData } = useCacheData(paginatedReadConfig)
  const lazyQueryResponse = useLazyData(paginatedReadConfig)

  const { isPaginated, input: queryInput } = paginatedReadConfig

  // TODO: Pull defaults out of input because changing input at top of this hook is triggering useEffect
  useEffect(() => {
    if (isPaginated) {
      emptyCacheAndGetFirstDataPage(paginatedReadConfig, lazyQueryResponse)
    } else if (queryInput.id) {
      getDetailDatum(paginatedReadConfig, lazyQueryResponse)
    }
  }, [queryInput.id])

  const data = isPaginated ? cacheData : lazyQueryResponse.data

  return { data }
}
