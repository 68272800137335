import { gql } from '@apollo/client'
import { schemaV1 } from './schemas'

export const CREATE_NCCN_PANEL_VOTING_MEMBER = gql`
  mutation CreateNccnPanelVotingMember($input: CreateNccnPanelVotingMemberInput!) {
    createNccnPanelVotingMember(input: $input) {
      ${schemaV1}
    }
  }
`
