import React, { useState } from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

import { Colors, Spacing } from '../../utils/pulseStyles'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'pointer',
  margin: `0 ${Spacing.NORMAL}`,
  borderRadius: 4,
  padding: `${Spacing.SMALL} ${Spacing.NORMAL}`,
  textDecoration: 'none',

  // shared styles
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: '-0.1px',
  lineHeight: '18px',
  color: transparentize(0.2, Colors.WHITE),

  ':hover': {
    background: transparentize(0.9, Colors.WHITE),
  },
})

const ChildContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 24,
})

const Dropdown = ({ style, label, children }) => {
  const [show, toggleShow] = useState(false)
  const handleClick = () => toggleShow(!show)

  return (
    <>
      <Wrapper style={{ style }} onClick={handleClick}>
        <span>{label}</span>
        <span>
          <FontAwesomeIcon size="lg" icon={faCaretDown} />
        </span>
      </Wrapper>
      {show && <ChildContainer>{children}</ChildContainer>}
    </>
  )
}

export default Dropdown
