import React from 'react'
// import PropTypes from 'prop-types'

import Pagination from './Pagination'
import TableExport from './TableExport'
import ButtonGroup from './ButtonGroup'
import TableHint from './TableHint'

const TableHeader = ({
  rows,
  hasCreate,
  hasExport,
  createConfig,
  isCreateActive,
  setIsCreateActive,
  createRowInputRef,
  pageIndex,
  pageSize,
  tablePropOverflow,
  exportConfig,
}) => {
  const toggleCreateRow = () => {
    setIsCreateActive((prevState) => !prevState)
  }

  return (
    <section style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Pagination {...{ pageIndex, pageSize, ...tablePropOverflow }} />
        <TableExport
          rows={rows}
          hasExport={hasExport}
          exportConfig={exportConfig}
        />
      </div>
      <TableHint>
        {hasCreate && (
          <ButtonGroup
            hasCreate={hasCreate}
            isCreateActive={isCreateActive}
            toggleCreateRow={toggleCreateRow}
            onCreateRowSubmit={createConfig.createRowSubmitHandler}
            createRowInputRef={createRowInputRef}
          />
        )}
        Hold shift while clicking column headers for multi-column sort
      </TableHint>
    </section>
  )
}

// TODO
// TableHeader.propTypes = {

// }

export default TableHeader
