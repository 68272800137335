import React from 'react'

import { ListHeader, ListTitle } from './../shared/styledComponents'

const DASHBOARD_TOOL_LABEL = 'Dashboard Tools'

const DashboardToolPanelListHeader = () => (
  <ListHeader style={{ padding: '18px 24px 19px' }}>
    <ListTitle>{DASHBOARD_TOOL_LABEL}</ListTitle>
  </ListHeader>
)

export default DashboardToolPanelListHeader
