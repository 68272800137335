import React from 'react'

import { TextInput } from 'frontend/components'

import { NodeState } from '../types'
import { Label, Wrapper } from './shared'

interface Props {
  node?: any
  nodeState: NodeState
  updateNodeState: (event: any) => void
}

const ComponentPath = ({
  node,
  nodeState,
  updateNodeState,
}: Props) => (
  <Wrapper>
    <Label>
      Component Path
    </Label>
    <TextInput
      name={'component_path'}
      onChange={updateNodeState}
      value={nodeState?.component_path ?? node?.component_path}
    />
  </Wrapper>
)

export default ComponentPath
