import React, { useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { ActionMeta, ValueType } from 'react-select';

const PLACEHOLDER = 'Type something and press enter...'

interface Option {
  label: string
  value: string
}

interface State {
  inputValue: string
  value: Option[]
}

const CreatableMultiSelect = ({ ...props }) => {
  const [state, setState] = useState({
    inputValue: '',
    value: props.value ?? [],
  })

  const handleChange = (value: ValueType<Option, true>, action: ActionMeta<Option>) => {
    const newValue = value ?? []
    setState((prevState: State) => ({ ...prevState, value: newValue }))
    props.onChange(newValue, action)
  }

  const handleInputChange = (inputValue: string) => {
    setState((prevState: State) => ({ ...prevState, inputValue }))
  }

  const handleKeyDown: any = (event: any) => {
    const { inputValue, value } = state
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        const newValue = [...value, createOption(inputValue)]
        setState({
          inputValue: '',
          value: newValue,
        })
        props.onChange(newValue, { name: props.name })
        event.preventDefault()
    }
  }

  return (
    <CreatableSelect
      {...props}
      inputValue={state.inputValue}
      isClearable
      isMulti
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder={PLACEHOLDER}
      value={state.value}
    />
  )
}

export default CreatableMultiSelect

const createOption = (label: string) => ({
  label,
  value: label,
})
