import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import _ from 'lodash'

import { GET_MANUFACTURERS, GET_MARKET_BASKETS } from 'frontend/api/queries'
import { UPDATE_MANUFACTURER } from 'frontend/api/mutations'

import { SingleActionDialog } from 'frontend/components/Dialog'
import Spinner from 'frontend/components/Spinner'
import Input from 'frontend/components/Input'

import Spacing from 'frontend/utils/spacing'

import {
  InputSection,
  FormLabel,
  BlueText,
} from '../../../MarketBaskets/MarketBasketDetail/Surveys/SurveyView/SurveyForms/utils'
import DeleteManufacturerSection from './DeleteManufacturerSection'

const EditManufacturerForm = ({ selectedManufacturerData, closeHandler }) => {
  const { id, name } = selectedManufacturerData
  const [manufacturerName, setManufacturerName] = useState(name)

  const [updateManufacturer, { loading: mutationLoading }] = useMutation(
    UPDATE_MANUFACTURER,
    {
      variables: {
        input: {
          id,
          name: manufacturerName,
        },
      },
      update: (cache) => {
        const { manufacturers } = cache.readQuery({
          query: GET_MANUFACTURERS,
        })
        const newManufacturers = _.sortBy(manufacturers, ({ name }) =>
          name.toLowerCase()
        )
        cache.writeQuery({
          query: GET_MANUFACTURERS,
          data: { manufacturers: newManufacturers },
        })
      },
      refetchQueries: [{ query: GET_MARKET_BASKETS }],
      onError: alert,
      onCompleted: () => {
        closeHandler()
      },
    }
  )

  const onTextChange = ({ value }) => {
    setManufacturerName(value)
  }

  const header = (
    <p>
      Edit <BlueText>{name}</BlueText> Manufacturer
    </p>
  )

  return (
    <SingleActionDialog
      header={header}
      submitText="Edit Manufacturer"
      submitHandler={updateManufacturer}
      cancelHandler={closeHandler}
    >
      <div style={{ padding: Spacing.S7 }}>
        {mutationLoading ? (
          <div style={{ height: 236, textAlign: 'center' }}>
            <Spinner size={32} />
          </div>
        ) : (
          <div>
            <form>
              <InputSection>
                <FormLabel>Name (required)</FormLabel>
                <Input
                  name="name"
                  type="text"
                  value={manufacturerName}
                  onChange={onTextChange}
                />
              </InputSection>
            </form>
            <DeleteManufacturerSection
              manufacturerId={id}
              name={name}
              closeHandler={closeHandler}
            />
          </div>
        )}
      </div>
    </SingleActionDialog>
  )
}

EditManufacturerForm.propTypes = {
  selectedManufacturerData: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
}

export default EditManufacturerForm
