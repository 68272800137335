import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

const Button = styled.button({
  color: transparentize(0.7, Colors.BLACK),
  cursor: 'pointer',
  fontSize: 24,
  position: 'relative',
  top: 2,
  ':hover': {
    color: Colors.PRIMARY,
    background: transparentize(0.9, Colors.PRIMARY),
  },
})

const CopyButton = ({ data }) => (
  <Button
    onClick={(e) => {
      e.stopPropagation()
      copyToClipboard(data.id)
    }}
  >
    ⧉
  </Button>
)

export default CopyButton

const copyToClipboard = (nodeId) => {
  navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
    if (result.state === 'granted' || result.state === 'prompt') {
      navigator.clipboard.writeText(nodeId)
    } else {
      console.log('permission DENIED')
    }
  })
}
