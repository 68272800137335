import React, { Dispatch, SetStateAction } from 'react'

import { Colors } from 'frontend/utils/pulseStyles'

import { ToggleAllButton } from '../../shared'

interface Props {
  disabled: boolean
  setResources: Dispatch<SetStateAction<any>>
}

const ToggleOffAllButton = ({
  disabled,
  setResources,
}: Props) => {
  const toggleOffAllAccounts = () => {
    setResources(({ accounts, ...rest }: any) => ({
      accounts: [],
      ...rest
    }))
  }

  return (
    <ToggleAllButton
      disabled={disabled}
      color={Colors.MEDIUM_GRAY_2}
      onClick={toggleOffAllAccounts}
    >
      Toggle off All Accounts
    </ToggleAllButton>
  )
}

export default ToggleOffAllButton
