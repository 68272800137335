import { transparentize } from 'polished'
import styled from '@emotion/styled';

import Color from 'frontend/utils/color'
import FontSpace from 'frontend/utils/fontspace'

export const DateInputWrapper = styled.div({
  background: Color.WHITE,
  width: 'fit-content',
  borderRadius: 4,
  ...FontSpace.FS2,
  border: `1px solid ${transparentize(0.96, Color.BLACK)}`,
  ':hover': {
    border: `1px solid ${transparentize(0.9, Color.BLACK)}`,
  },
  ':focus': {
    border: `1px solid ${transparentize(0.1, Color.PRIMARY)}`,
    outline: 'none',
  },
})
