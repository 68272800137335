import React, { useState } from 'react'
import styled from '@emotion/styled'
import {
  FieldContainer,
  FieldLabel,
} from 'frontend/Orion/ImportExportData/NewImport/styledImportComponents'
import Input from 'frontend/components/Input'
import { Colors, Spacing as Space } from 'frontend/utils/pulseStyles'
import Color from 'frontend/utils/color'
import Dialog from 'frontend/components/Dialog'
import Button from '../../../components/Button'

const payerDataDialogWrapperStyle = {
  width: '450px',
  margin: 'auto auto',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
}

const PayerExportModalMiddleSection = styled.div({
  width: '100%',
  backgroundColor: '#f8fcfc',
  textAlign: 'left',
  padding: Space.MEDIUM,
  borderTopWidth: '1px',
  borderTopColor: Colors.MEDIUM_GRAY_2,
  borderBottomWidth: '1px',
  borderBottomColor: Colors.MEDIUM_GRAY_2,
  borderStyle: 'solid',
})

const PayerHistoricalModalDialog = ({ closeDialog, downloadPayerFlatFile }) => {
  const [payerDate, setPayerDate] = useState(null)
  const exportPayerFlatFile = () => {
    downloadPayerFlatFile(payerDate)
  }

  return (
    <Dialog
      contentStyle={{ height: 'fit-content' }}
      contentWrapperStyle={payerDataDialogWrapperStyle}
    >
      <div style={{ padding: Space.MEDIUM }}>
        <p
          style={{
            color: Color.BLACK,
            fontWeight: 700,
            fontSize: '25px',
          }}
        >
          Export Payer Data
        </p>
      </div>
      <PayerExportModalMiddleSection>
        <div>
          <p
            style={{
              color: Color.BLACK,
              fontWeight: 700,
              fontSize: '20px',
            }}
          >
            Historical Data
          </p>
          <p style={{ fontSize: '13px' }}>
            Select date to export payer historical Quality of Access, Additional
            Criteria and Policy Link data. The export will include the latest
            data for the selected date.
            <br />
            <br />
            The historical date will be combined with the non-historical payer
            data into a single Excel file.
            <br />
            <br />
            Export will take an average of 30 minutes. If there are multiple exports
            in queue then it will take longer. You will be emailed the export once
            complete. If the export fails, you will receive an email accordingly.
            <br />
            <br />
          </p>
          <FieldContainer>
            <FieldLabel>Date</FieldLabel>
            <Input
              name="payerDate"
              type="date"
              onChange={(event) => setPayerDate(event.value)}
              value={payerDate}
            />
          </FieldContainer>
        </div>
      </PayerExportModalMiddleSection>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '15px',
        }}
      >
        <Button
          type={'secondary'}
          buttonStyle={{
            marginRight: '15px',
          }}
          onClick={closeDialog}
        >
          Cancel
        </Button>
        <Button
          type={'primary'}
          color="green"
          // isDisabled={payerDate ? false : true}
          onClick={exportPayerFlatFile}
        >
          Export
        </Button>
      </div>
    </Dialog>
  )
}

export default PayerHistoricalModalDialog
