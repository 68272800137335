export const getTeamOptions = ({ selectedClient, teams }: any) => {
  if (!selectedClient) return []

  return teams
    .filter((team: any) => team.client === selectedClient.id)
    .map((team: any) => ({
      value: team.id,
      label: team.name,
    }))
}
