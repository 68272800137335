import React from 'react'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'

import { GET_PROVIDER_AFFILIATES_EMR } from 'frontend/api/queries'

import Header from 'frontend/components/Header'
import DataTable from 'frontend/components/DataTable'
import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'

import getColumns from './getColumns'
// import getExportConfig from './getExportConfig'
import getExportConfig from 'frontend/utils/getExportConfig'

const HEADER = 'Affiliate EMR'
const FILENAME = 'affiliate-emr'

const ICON_BAR_WIDTH = 60
const ORION_SIDEBAR_WIDTH = 300

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: `calc(100vw - ${ICON_BAR_WIDTH + ORION_SIDEBAR_WIDTH}px)`,
})

const TitleSection = styled.section({
  padding: Spacing.S7,
})

const AffiliateEmr = () => {
  const { data, loading } = useQuery(GET_PROVIDER_AFFILIATES_EMR)

  const columns = getColumns()
  const tableData = data?.providerAffiliatesEmr ?? []
  const exportConfig = getExportConfig(FILENAME, columns)

  return (
    <Container>
      <TitleSection>
        <Header header={HEADER} headerStyle={{ ...FontSpace.FS5 }} />
      </TitleSection>
      <DataTable
        hasExport
        isLoading={loading}
        columns={columns}
        data={tableData}
        exportConfig={exportConfig}
      />
    </Container>
  )
}

export default AffiliateEmr
