import React from 'react'

import { Button } from '@pulse-analytics/pulse-design-system'

import Spacing from 'frontend/utils/spacing'
import Color from 'frontend/utils/color'

const BUTTON_STYLE = {
  padding: Spacing.S3,
  margin: Spacing.S3,
}

export const ModalControls = ({ closeModal, handleUpdate }) => {
  return (
    <section>
      <Button
        onClick={closeModal}
        style={BUTTON_STYLE}
        color={Color.GRAY_DARK}
        type="secondary"
      >
        Cancel
      </Button>
      <Button onClick={handleUpdate} style={BUTTON_STYLE}>
        Save
      </Button>
    </section>
  )
}

export default ModalControls
