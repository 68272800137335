import React, { Dispatch, SetStateAction } from 'react'
import { useQuery } from '@apollo/client'

import { GET_SOURCE_INDICATIONS } from 'frontend/api/queries'
import { useQueryString } from 'frontend/hooks'

import RegimensListPanel from './RegimensListPanel'

interface Props {
  areParentResourcesLoading: boolean
  parentResources: any[]
  setResources: Dispatch<SetStateAction<any>>
  treatmentPlans?: any[]
}

const Container = ({
  areParentResourcesLoading,
  parentResources,
  setResources,
  treatmentPlans,
}: Props) => {
  const { indication }: any = useQueryString()

  const { loading: areTreatmentPlansLoading } = useQuery(GET_SOURCE_INDICATIONS)

  if (
    areParentResourcesLoading ||
    areTreatmentPlansLoading ||
    !treatmentPlans ||
    !indication
  ) return null

  return (
    <RegimensListPanel
      parentResources={parentResources}
      treatmentPlans={treatmentPlans}
      setResources={setResources}
    />
  )
}

export default Container
