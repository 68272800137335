import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'

import { UPDATE_COMMUNITY_PRACTICE_NETWORK } from 'frontend/api/mutations'

import { SingleActionDialog } from 'frontend/components/Dialog'
import Spinner from 'frontend/components/Spinner'
import Input from 'frontend/components/Input'

import Spacing from 'frontend/utils/spacing'

import DeleteCpnSection from './DeleteCpnSection'
import {
  InputSection,
  FormLabel,
  BlueText,
} from '../../../MarketBaskets/MarketBasketDetail/Surveys/SurveyView/SurveyForms/utils'

const EditCpnForm = ({ selectedCpnData, closeHandler }) => {
  const { id, name } = selectedCpnData
  const [cpnName, setCpnName] = useState(name)

  const [
    updateCommunityPracticeNetwork,
    { loading: mutationLoading },
  ] = useMutation(UPDATE_COMMUNITY_PRACTICE_NETWORK, {
    variables: {
      input: {
        id,
        name: cpnName,
      },
    },
    onError: alert,
    onCompleted: () => {
      closeHandler()
    },
  })

  const onTextChange = ({ value }) => {
    setCpnName(value)
  }

  const header = (
    <p>
      Edit <BlueText>{name}</BlueText> Community Practice Network
    </p>
  )

  return (
    <SingleActionDialog
      header={header}
      submitText="Edit Network"
      submitHandler={updateCommunityPracticeNetwork}
      cancelHandler={closeHandler}
    >
      <div style={{ padding: Spacing.S7 }}>
        {mutationLoading ? (
          <div style={{ height: 236, textAlign: 'center' }}>
            <Spinner size={32} />
          </div>
        ) : (
          <div>
            <form>
              <InputSection>
                <FormLabel>Name (required)</FormLabel>
                <Input
                  name="name"
                  type="text"
                  value={cpnName}
                  onChange={onTextChange}
                />
              </InputSection>
            </form>
            <DeleteCpnSection
              cpnId={id}
              name={name}
              closeHandler={closeHandler}
            />
          </div>
        )}
      </div>
    </SingleActionDialog>
  )
}

EditCpnForm.propTypes = {
  selectedCpnData: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
}

export default EditCpnForm
