import React from 'react'
import styled from '@emotion/styled'

import { Spinner } from 'frontend/components'

const SpinnerContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '75vh',
})

const ClientsLoadingPanel = () => (
  <SpinnerContainer>
    <Spinner />
  </SpinnerContainer>
)

export default ClientsLoadingPanel
