import React from 'react'

import { TextInput } from 'frontend/components'

interface Props {
  isManualPassword: boolean
  onChange: any
}

const FIELD_NAME = 'password'
const PLACEHOLDER_TEXT = 'Password'

export const Input = ({ isManualPassword, onChange }: Props) => {
  if (!isManualPassword) return null

  const handleChange = ({ target: { value } }: any) => {
    onChange({ name: FIELD_NAME, value })
  }

  return <TextInput onChange={handleChange} placeholder={PLACEHOLDER_TEXT} />
}
