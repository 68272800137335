import styled from '@emotion/styled'

import Color from 'frontend/utils/color'
import Spacing from 'frontend/utils/spacing'

const TableHint = styled.span({
  margin: Spacing.S4,
  marginRight: Spacing.S7,
  fontSize: Spacing.S4,
  color: Color.MEDIUM_GRAY_2,
  fontStyle: 'italic',
})

export default TableHint
