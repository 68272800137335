import React from 'react'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'

import { GET_PROVIDER_AFFILIATES_340B } from 'frontend/api/queries'

import Header from 'frontend/components/Header'
import DataTable from 'frontend/components/DataTable'
import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'

import getColumns from './getColumns'
import getExportConfig from './getExportConfig'

const HEADER = 'Affiliate Sites 340B Status'

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const TitleSection = styled.section({
  padding: Spacing.S7,
})

const AffiliateSites340bStatus = () => {
  const { data, loading } = useQuery(GET_PROVIDER_AFFILIATES_340B)

  const columns = getColumns()
  const tableData = data?.providerAffiliates340b ?? []
  const exportConfig = getExportConfig(columns)

  return (
    <Container>
      <TitleSection>
        <Header header={HEADER} headerStyle={{ ...FontSpace.FS5 }} />
      </TitleSection>
      <DataTable
        hasExport
        isLoading={loading}
        columns={columns}
        data={tableData}
        exportConfig={exportConfig}
      />
    </Container>
  )
}

export default AffiliateSites340bStatus
