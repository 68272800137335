import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import queryString from 'query-string'

import DataTable from 'frontend/components/DataTable'
import Modal from 'frontend/components/Modal'
import Spinner from 'frontend/components/Spinner'

import { GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS } from 'frontend/api/queries'

import usePathwaysTreatmentPlanData from '../utils/usePathwaysTreatmentPlanData'
import usePositioningOptionsData from '../utils/usePositioningOptionsData'

import {
  getHistoricalPositionTableColumnsInjectables,
  getHistoricalPositionTableColumns,
} from './utils'
import { useHistoricalPositioningData } from './utils'

import DeleteRowDialog from './DeleteRowDialog'

const HistoricalPositionModal = ({
  showModal,
  closeHandler,
  onCreateRowSubmitHandler,
}) => {
  const location = useLocation()

  const [selectedRowToDelete, setSelectedRowToDelete] = useState()
  const historicalPositionsData = useHistoricalPositioningData()
  const pathwaysTreatmentPlanData = usePathwaysTreatmentPlanData()
  const optionsData = usePositioningOptionsData()

  const {
    pathwaysOrganizationId,
    indicationId,
    regimenId,
    populationsIds,
  } = queryString.parse(location.search)

  const clearSelectedRowToDelete = () => {
    setSelectedRowToDelete(undefined)
  }

  const coercedPopulationsIds = getCoercedPopulationsIds(populationsIds)

  const lockedInputs = {
    pathways_organization: pathwaysOrganizationId,
    indication: indicationId,
    regimen: regimenId,
    populations: coercedPopulationsIds,
  }

  const historicalPositionTableInjectables = getHistoricalPositionTableColumnsInjectables(
    {
      setSelectedRowToDelete,
      historicalPositionsData,
    }
  )

  const historicalPositionTableColumns = getHistoricalPositionTableColumns({
    ...historicalPositionTableInjectables,
    ...historicalPositionsData,
    ...pathwaysTreatmentPlanData,
    ...optionsData,
    lockedInputs,
  })

  const {
    data: pathwaysHistoricalPositionsData,
    loading: pathwaysHistoricalPositionsLoading,
  } = useQuery(GET_PATHWAYS_INDICATION_REGIMEN_POSITIONS, {
    variables: {
      pathwaysOrganizationId,
      indicationId,
      regimenId,
      populationsIds: coercedPopulationsIds,
    },
  })

  const isLoading =
    pathwaysHistoricalPositionsLoading || pathwaysTreatmentPlanData.loading

  return (
    <Modal
      handleClose={closeHandler}
      show={showModal}
      title={'Historical Positioning Data'}
      width={1500}
    >
      {selectedRowToDelete && (
        <DeleteRowDialog
          selectedRowToDelete={selectedRowToDelete}
          clearSelectedRowToDelete={clearSelectedRowToDelete}
        />
      )}
      {isLoading ? (
        <Spinner />
      ) : (
        <DataTable
          data={
            pathwaysHistoricalPositionsData.pathwaysIndicationRegimenPositions
          }
          columns={historicalPositionTableColumns}
          hasCreate
          hasEdit
          createConfig={{
            createRowSubmitHandler: onCreateRowSubmitHandler,
          }}
          editConfig={{ accessor: 'id' }}
        />
      )}
    </Modal>
  )
}

HistoricalPositionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  onCreateRowSubmitHandler: PropTypes.func.isRequired,
}

export default HistoricalPositionModal

const getCoercedPopulationsIds = (populationsIds) => {
  let coercedPopulationsIds = populationsIds
  if (coercedPopulationsIds === '') coercedPopulationsIds = []
  else if (!Array.isArray(coercedPopulationsIds))
    coercedPopulationsIds = [coercedPopulationsIds]

  return coercedPopulationsIds
}
