import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const DELETE_USER = gql`
  mutation DeleteUser($input: DeleteEmberUserInput!) {
    deleteEmberUser(input: $input) {
      ${schemaV0}
    }
  }
`
