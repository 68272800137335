import React from 'react'

interface Props {
  activeTab: string
  url: string
}

const MostRecent = ({
  activeTab,
  url
}: Props) => {
  if (activeTab !== 'mostRecent') return null

  return (
    <iframe
      src={url}
      width={'100%'}
      height={'calc(100% - 54px)'}
      style={{ border: 'none', marginLeft: '48px' }}
      title="PDF Viewer"
    />
  )
}

export default MostRecent
