import { transparentize } from 'polished'

import {
  MultiSelectFilter,
  DateMultiSelectFilter,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/components/ColumnFilters'
import {
  DISPLAY_CELL_TYPES,
  EDIT_CELL_TYPES,
  FILTER_TYPES,
  SORT_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'

import { formatMonthDayYearSlash } from 'frontend/utils/formatDate'

import Color from 'frontend/utils/color'
import { StatusTag } from 'frontend/components'
import { ExpandIcon } from 'frontend/components/Icon'

const { CONDENSED_BUTTON, BUTTON } = DISPLAY_CELL_TYPES
const { CONDENSED_SINGLE_SELECT_INPUT, CONDENSED_TEXT_INPUT } = EDIT_CELL_TYPES
const { MULTI_SELECT, DATE_MULTI_SELECT } = FILTER_TYPES
const { STRING, DATE } = SORT_TYPES

const STATUS_TO_COLOR = {
  active: Color.GREEN,
  inactive: Color.ORANGE,
}

export const getColConfig = ({
  people,
  secondaryOrganizations,
  handleUpdate: updateSecondaryOrganizationsConnections,
  addDeleteSearchString,
  onExpandClick,
}) => [
  {
    Header: '',
    accessor: 'x',
    sticky: 'left',
    displayCellConfig: {
      type: BUTTON,
      style: {
        background: transparentize(0.9, Color.PRIMARY),
        borderRadius: 4,
        padding: 2,
        cursor: 'pointer',
      },
      label: <ExpandIcon />,
      onClick: onExpandClick,
    },
    width: 5,
    disableSortBy: true,
    featureFlag: 'shouldShowPeopleTrackingStartEndDates',
  },
  {
    Header: 'Person',
    accessor: 'person',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    labelMap: people.labelMap,
    editCellConfig: {
      type: CONDENSED_SINGLE_SELECT_INPUT,
      options: people.options,
      updateFn: updateSecondaryOrganizationsConnections,
    },
  },
  {
    Header: 'Secondary Organization',
    accessor: 'secondary_organization',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    labelMap: secondaryOrganizations.labelMap,
    editCellConfig: {
      type: CONDENSED_SINGLE_SELECT_INPUT,
      options: secondaryOrganizations.options,
      updateFn: updateSecondaryOrganizationsConnections,
    },
  },
  {
    Header: 'Status',
    accessor: 'dynamic_status',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    width: 150,
    cellValueFormatter: (value) => {
      if (!value) return null
      const upperCaseValue = value.toUpperCase()
      return <StatusTag color={STATUS_TO_COLOR[value]} value={upperCaseValue} />
    },
  },
  {
    Header: 'Committees',
    accessor: 'committees',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: updateSecondaryOrganizationsConnections,
    },
  },
  {
    Header: 'Title',
    accessor: 'title',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: updateSecondaryOrganizationsConnections,
    },
  },
  {
    Header: 'Start Date',
    accessor: 'start_date',
    Filter: DateMultiSelectFilter,
    filter: DATE_MULTI_SELECT,
    sortType: DATE,
    cellValueFormatter: formatMonthDayYearSlash,
    width: 200,
    featureFlag: 'shouldShowPeopleTrackingStartEndDates',
  },
  {
    Header: 'End Date',
    accessor: 'end_date',
    Filter: DateMultiSelectFilter,
    filter: DATE_MULTI_SELECT,
    sortType: DATE,
    cellValueFormatter: formatMonthDayYearSlash,
    width: 200,
    featureFlag: 'shouldShowPeopleTrackingStartEndDates',
  },
  {
    Header: 'Internal Notes',
    accessor: 'tdg_internal_note',
    Filter: MultiSelectFilter,
    filter: MULTI_SELECT,
    sortType: STRING,
    editCellConfig: {
      type: CONDENSED_TEXT_INPUT,
      updateFn: updateSecondaryOrganizationsConnections,
    },
  },
  {
    Header: 'Delete',
    accessor: 'delete',
    displayCellConfig: {
      type: CONDENSED_BUTTON,
      label: 'Delete',
      onClick: addDeleteSearchString,
    },
  },
]
