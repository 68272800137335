import React from 'react'
import styled from '@emotion/styled'

import Color from 'frontend/utils/color'

const SortIcon = styled.span`
  color: ${Color.BLUE};
  font-size: 12px;
  line-height: 1.5;
`

const ColumnHeaders = ({ headerGroups }) => (
  <>
    {headerGroups.map((headerGroup) => (
      <div {...headerGroup.getHeaderGroupProps()} className="tr">
        {headerGroup.headers.map((column) => (
          <div
            {...column.getHeaderProps(column.getSortByToggleProps())}
            className="th"
          >
            <div style={{ overflowX: 'auto' }}>
              {column.render('Header')}
              <SortIcon>
                {column.isSorted ? (column.isSortedDesc ? ' ⬇︎' : ' ⬆︎') : ''}
              </SortIcon>
            </div>
            <div style={{ marginTop: 4 }} onClick={(e) => e.stopPropagation()}>
              {column.canFilter ? column.render('Filter') : null}
            </div>
          </div>
        ))}
      </div>
    ))}
  </>
)

export default ColumnHeaders
