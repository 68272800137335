import React from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@pulse-analytics/pulse-design-system'

import { PUSH_TO_STAGING_PEOPLE_ORGANIZATIONS_AFFILIATIONS } from 'frontend/api/mutations'

const TEXT = {
  BUTTON_LABEL: 'Push to Staging',
  BUTTON_KEY: 'push-to-staging-button',
}

const COLORS = {
  BACKGROUND_COLOR: '#0668D926',
  TEXT_COLOR: '#0668D9',
}

const BUTTON_STYLE = {
  padding: 12,
  background: COLORS.BACKGROUND_COLOR,
  color: COLORS.TEXT_COLOR,
}

const PushToStagingButton = () => {
  const [stagePeopleAffiliations] = useMutation(
    PUSH_TO_STAGING_PEOPLE_ORGANIZATIONS_AFFILIATIONS,
    {
      onCompleted: () => {
        alert(
          'People Affiliations Data is being pushed to staging site. Please check staging site in 5-10 minutes to view the data.'
        )
      },
      onError: alert,
    }
  )

  return (
    <Button
      key={TEXT.BUTTON_KEY}
      onClick={stagePeopleAffiliations}
      style={BUTTON_STYLE}
    >
      {TEXT.BUTTON_LABEL}
    </Button>
  )
}

export default PushToStagingButton
