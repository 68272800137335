import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@pulse-analytics/pulse-design-system'
import queryString from 'query-string'

const TEXT = {
  BUTTON_LABEL: 'Create Alternative Payment Model Organization',
  BUTTON_KEY: 'create-alternative-payment-model-organization',
}

const BUTTON_STYLE = { padding: 12 }

const CreateAlternativePaymentModelOrganizationButton = () => {
  const history = useHistory()
  const addCreateParam = () => {
    const createParam = queryString.stringify({
      createAlternativePaymentModelOrganization: true,
    })
    history.push({ search: createParam })
  }

  return (
    <Button style={BUTTON_STYLE} onClick={addCreateParam} key={TEXT.BUTTON_KEY}>
      {TEXT.BUTTON_LABEL}
    </Button>
  )
}

export default CreateAlternativePaymentModelOrganizationButton
