import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import _ from 'lodash'

import { GET_MARKET_BASKETS_SURVEYS_STAKEHOLDERS } from 'frontend/api/queries'

import Header from 'frontend/components/Header'
import Spinner from 'frontend/components/Spinner'
import DataTable from 'frontend/components/DataTable'
import MultiSelectColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/MultiSelectColumnFilter'
import customMultiSelectFilterFn from 'frontend/components/Table/custom-filters/MultiSelect/customMultiSelectFilterFn'
import { EXPAND } from 'frontend/components/DataTable/Cells/cellTypes'

import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'

import EditStakeholderForm from './EditStakeholderForm'

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const TitleSection = styled.section({
  display: 'flex',
  padding: Spacing.S7,
  alignItems: 'center',
})

const Stakeholders = () => {
  const [modalData, setModalData] = useState(null)
  const { data: stakeholdersData, loading: stakeholderLoading } = useQuery(
    GET_MARKET_BASKETS_SURVEYS_STAKEHOLDERS
  )

  const dataMap = {}
  let tableData = []
  if (!stakeholderLoading) {
    stakeholdersData.marketBasketsSurveysStakeholders.forEach(
      ({
        id,
        first_name,
        last_name,
        primary_state,
        role,
        perception_tool_provider,
        role_specialties,
      }) => {
        // * insert state data if applicable
        const stateName = primary_state ? primary_state.abbreviation : null
        const stateId = primary_state ? primary_state.id : null

        // * insert provider data if applicable
        const providerName = perception_tool_provider
          ? perception_tool_provider.name
          : null
        const providerId = perception_tool_provider
          ? perception_tool_provider.id
          : null

        // * insert role data if applicable
        const roleName = role ? role.name : null
        const roleId = role ? role.id : null

        const roleSpecialties = _.isEmpty(role_specialties)
          ? []
          : role_specialties

        const datum = {
          id,
          name: `${first_name} ${last_name}`,
          stateName,
          stateId,
          roleName,
          roleId,
          providerName,
          providerId,
          roleSpecialties,
        }
        const tableDatum = {
          ...datum,
          roleSpecialties: role_specialties.map(
            ({ specialty_label }) => specialty_label
          ),
        }

        dataMap[id] = datum
        tableData.push(tableDatum)
      }
    )
  }

  const onRowClick = (row) => {
    const {
      original: { id },
    } = row
    const data = dataMap[id]
    setModalData(data)
  }

  const COLUMNS = [
    {
      Header: 'ID',
      accessor: 'id',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
    },
    {
      Header: 'Stakeholder',
      accessor: 'name',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
    },
    {
      Header: 'Provider',
      accessor: 'providerName',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
    {
      Header: 'State',
      accessor: 'stateName',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
    {
      Header: 'Stakeholder Role',
      accessor: 'roleName',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
    {
      Header: 'Specialty Roles',
      accessor: 'roleSpecialties',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
  ]

  return (
    <Container>
      <TitleSection>
        <Header
          header="Stakeholders"
          subheader="Select a table row to view and edit a Stakeholder's state or role"
          headerStyle={{ ...FontSpace.FS5 }}
        />
      </TitleSection>
      {stakeholderLoading ? (
        <Spinner />
      ) : (
        <DataTable data={tableData} columns={COLUMNS} />
      )}
      {modalData && (
        <EditStakeholderForm
          stakeholder={modalData}
          closeModal={() => setModalData(null)}
        />
      )}
    </Container>
  )
}

export default Stakeholders
