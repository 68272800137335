import React from 'react'

const CircleCheck = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg viewBox="0 -1 14 14" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.99984 1.16667C10.2215 1.16667 12.8332 3.77834 12.8332 7C12.8332 10.2217 10.2215 12.8333 6.99984 12.8333C3.77818 12.8333 1.1665 10.2217 1.1665 7C1.1665 3.77834 3.77818 1.16667 6.99984 1.16667ZM9.66223 4.98592C9.52587 4.84309 9.29899 4.84309 9.15136 4.98592L6.01773 8.01763L4.83705 6.88629C4.70069 6.74346 4.4738 6.74346 4.32617 6.88629L3.92874 7.2708C3.78111 7.41363 3.78111 7.63313 3.92874 7.76506L5.75664 9.5444C5.90427 9.68723 6.13116 9.68723 6.26752 9.5444L10.0709 5.86468C10.2186 5.73275 10.2186 5.51325 10.0709 5.37042L9.66223 4.98592Z"
        fill={iconColor}
      />
    </svg>
  )
}

export default CircleCheck
