import { Button } from '@pulse-analytics/pulse-design-system'
import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

import { useActionBar } from './useActionBar'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
})

const cancelButtonStyle = {
  padding: '9px 12px',
  backgroundColor: Colors.GRAY_50,
  marginRight: 12,
  height: 36,
}

const saveButtonStyle = {
  padding: '9px 12px',
  height: 36,
}

export const ActionBar = ({ fieldState }) => {
  const { cancel, submit } = useActionBar({
    fieldState,
  })

  return (
    <Wrapper>
      <Button
        type="secondary"
        onClick={cancel}
        color={Colors.GRAY_500}
        style={cancelButtonStyle}
      >
        Cancel
      </Button>
      <Button type="primary" onClick={submit} style={saveButtonStyle}>
        Save
      </Button>
    </Wrapper>
  )
}
