import getInfluencersTableColumns from './getInfluencersTableColumns'
import getInfluencersColumnsInjectables from './getInfluencersColumnsInjectables'

const getInfluencersInjectedColumns = ({
  setPersonData,
  options,
  update,
  indicationLabelMap,
  indicationsOptions,
}) => {
  const pathwaysInfluencersTableColumnsInjectables = getInfluencersColumnsInjectables(
    {
      setPersonData,
      options,
      update,
      indicationLabelMap,
      indicationsOptions,
    }
  )
  const pathwaysInfluencersColumns = getInfluencersTableColumns(
    pathwaysInfluencersTableColumnsInjectables
  )

  return pathwaysInfluencersColumns
}

export default getInfluencersInjectedColumns
