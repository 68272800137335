import React, { useState } from 'react'
import styled from '@emotion/styled'

import { EditableDataTable } from 'frontend/components'

import PanelHeader from 'frontend/components/Panel/PanelHeader'
import PeoplePowerSelect from 'frontend/components/BoPowerSelect/PeoplePowerSelect'
import PeopleModal from 'frontend/components/BusinessObjectModal/PeopleModal'
import { PushToStagingButton } from 'frontend/Orion/Organizations/shared'

import { usePathwaysInfluencerData } from 'frontend/hooks/usePathwaysInfluencersData'
import getExcelInjectables from './utils/getExcelInjectables'
import getInfluencersInjectedColumns from './utils/getInfluencersInjectedColumns'

const PAGE_TITLE = 'Pathways Influencers'

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  width: `calc(100vw - 360px)`,
})

const PathwaysInfluencers = () => {
  const [personData, setPersonData] = useState()

  const { pathwaysInfluencers, indications } = usePathwaysInfluencerData()
  const {
    data: pathwaysInfluencersData,
    loading: isPathwaysInfluencersLoading,
    update,
    options,
  } = pathwaysInfluencers

  const {
    labelMap: indicationLabelMap,
    options: indicationsOptions,
  } = indications

  const tableData = formatTableData(pathwaysInfluencersData)

  const pathwaysInfluencersTableColConfig = getInfluencersInjectedColumns({
    setPersonData,
    update,
    options,
    indicationLabelMap,
    indicationsOptions,
  })

  const exportConfig = getExcelInjectables({ indicationLabelMap })

  const utilityBarNodes = [<PushToStagingButton />]

  const closeHandler = () => {
    setPersonData(null)
  }

  return (
    <Container>
      <PanelHeader title={PAGE_TITLE}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PeoplePowerSelect />
        </div>
      </PanelHeader>
      {personData && (
        <PeopleModal entityId={personData.person} closeModal={closeHandler} />
      )}
      <EditableDataTable
        tableId={'pathways-influencers-table'}
        hasReset
        hasRowCount
        isLoading={isPathwaysInfluencersLoading}
        exportConfig={exportConfig}
        colConfig={pathwaysInfluencersTableColConfig}
        data={tableData}
        excelLabel={PAGE_TITLE}
        defaultColWidths={{ minWidth: 50, width: 200 }}
        utilityBarNodes={utilityBarNodes}
      />
    </Container>
  )
}

export default PathwaysInfluencers

const formatTableData = (pathwaysInfluencersData) =>
  pathwaysInfluencersData.map(
    ({
      personDatum: { first_name, middle_name, last_name, npi, affiliation },
      pathwaysDatum: { name, slug },
      ...rest
    }) => ({
      personFirstName: first_name,
      personMiddleName: middle_name,
      personLastName: last_name,
      personNpi: npi,
      personPrimaryAffiliation: affiliation,
      pathwaysName: name,
      organization_slug: slug,
      ...rest,
    })
  )
