import socket from 'frontend/api/socket'

import { useAuth0 } from '../../../src/react-auth0-spa'

const USER_POSITION_SEND_ID = 'send_data_table_user_position'
const USER_POSITION_RECEIVE_ID = 'receive_data_table_user_position'

export const CELL_VALUE_UPDATING_SEND_ID = 'send_data_table_cell_value_updating'
const CELL_VALUE_UPDATING_RECEIVE_ID = 'receive_data_table_cell_value_updating'

const INITIAL_DATA_TABLE_STATE_SEND_ID = 'send_initial_data_table_state'

const useDataTableConcurrentEdit = (tableId) => {
  const { user } = useAuth0()

  const emitInitialStateRequest = () => {
    socket.emit(INITIAL_DATA_TABLE_STATE_SEND_ID, tableId)
  }

  const emitUserCellPosition = ({
    cellPosition,
    rows = [],
    isUserOnCell = true,
  }) => {
    if (!cellPosition) return

    const { tableId, x, y } = cellPosition

    const currentCell = rows[y].cells[x]

    const {
      column: { id: columnId },
      row: { id: rowId },
    } = currentCell

    socket.emit(USER_POSITION_SEND_ID, {
      user,
      tableId,
      columnId,
      rowId,
      isUserOnCell,
    })
  }

  const emitCellValueUpdating = (data) => {
    socket.emit(CELL_VALUE_UPDATING_SEND_ID, data)
  }

  const turnSocketOn = ({
    receiveUserCellPosition,
    receiveCellValueUpdating,
  }) => {
    socket.on(USER_POSITION_RECEIVE_ID, receiveUserCellPosition)
    socket.on(CELL_VALUE_UPDATING_RECEIVE_ID, receiveCellValueUpdating)
  }

  const turnSocketOff = ({
    receiveUserCellPosition,
    receiveCellValueUpdating,
  }) => {
    socket.off(USER_POSITION_RECEIVE_ID, receiveUserCellPosition)
    socket.off(CELL_VALUE_UPDATING_RECEIVE_ID, receiveCellValueUpdating)
  }

  return {
    currentUser: user,
    emitUserCellPosition,
    emitCellValueUpdating,
    emitInitialStateRequest,
    turnSocketOn,
    turnSocketOff,
  }
}

export default useDataTableConcurrentEdit
