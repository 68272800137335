import { gql } from '@apollo/client'

import { schemaV0 } from './schemas'

export const UPDATE_EMBER_NODE = gql`
  mutation UpdateEmberNode($input: UpdateEmberNodeInput!) {
    updateEmberNode(input: $input) {
      ${schemaV0}
    }
  }
`
