import styled from '@emotion/styled'

import { Colors } from 'frontend/utils/pulseStyles'

export const Value = styled.label({
  fontSize: 14,
  fontWeight: 500,
  color: Colors.BLACK,
  marginBottom: 4,
  width: '50%',
})
