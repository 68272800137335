import React from 'react'
import styled from '@emotion/styled'

import { Spinner } from 'frontend/components'

const Wrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  height: '75%',
  justifyContent: 'center',
})

const LoadingPanel = () => (
  <Wrapper>
    <Spinner />
  </Wrapper>
)

export default LoadingPanel
