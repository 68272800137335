import _ from 'lodash'

const validateProviderChannel = (data) => {
  const providerChannelProductsRaw = data['Provider Channel Products']
  const serviceDeliveryCostRaw = data['Service Delivery Costs']
  const prevalenceAccessChallengesRaw = data['Prevalence Access Challenges']
  const payerReimbursementCostRaw = data['Payer Reimbursement Costs']

  // * removing the first two rows
  const providerChannelProduct = providerChannelProductsRaw.slice(2)
  const serviceDeliveryCost = serviceDeliveryCostRaw.slice(2)
  const prevalenceAccessChallenges = prevalenceAccessChallengesRaw.slice(2)
  const payerReimbursementCost = payerReimbursementCostRaw.slice(2)

  const serviceDeliveryCostByProductProviderChannel = _.keyBy(
    serviceDeliveryCost,
    ({ providerChannel, product }) => `${providerChannel}-${product}`
  )
  const prevalenceAccessChallengesByProductProviderChannel = _.keyBy(
    prevalenceAccessChallenges,
    ({ providerChannel, product }) => `${providerChannel}-${product}`
  )
  const payerReimbursementCostByProduct = _.keyBy(
    payerReimbursementCost,
    'product'
  )

  // * extract all provider channel x product permutation

  const superSetProviderChannelProduct = providerChannelProduct.reduce(
    (acc, { products, providerChannel }) => {
      const splitProducts = products.split(',').map((product) => product.trim())
      splitProducts.forEach((product) => {
        acc.push({ product, providerChannel })
      })
      return acc
    },
    []
  )

  const uniqueProviderChannelProducts = _.uniqBy(
    superSetProviderChannelProduct,
    ({ product, providerChannel }) => `${product}-${providerChannel}`
  )

  // * validate if all provider channel x product permutation is present in the other sheets

  const errors = []

  uniqueProviderChannelProducts.forEach(({ product, providerChannel }) => {
    const key = `${providerChannel}-${product}`

    const hasServiceDeliveryCost = Boolean(
      serviceDeliveryCostByProductProviderChannel[key]
    )
    const hasPrevalenceAccessChallenges = Boolean(
      prevalenceAccessChallengesByProductProviderChannel[key]
    )
    const hasPayerReimbursementCost = Boolean(
      payerReimbursementCostByProduct[product]
    )

    if (
      !hasServiceDeliveryCost ||
      !hasPrevalenceAccessChallenges ||
      !hasPayerReimbursementCost
    ) {
      errors.push({
        product,
        providerChannel,
        hasServiceDeliveryCost: hasServiceDeliveryCost ? '✅' : '❌',
        hasPrevalenceAccessChallenges: hasPrevalenceAccessChallenges
          ? '✅'
          : '❌',
        hasPayerReimbursementCost: hasPayerReimbursementCost ? '✅' : '❌',
      })
    }
  })

  return errors
}

export default validateProviderChannel
