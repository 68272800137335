import { useMutation } from '@apollo/client'
import { CrudHookOptions } from '../../../interfaces/CrudHookOptions'
import * as CRUD_UTILS from '../../../api/utils'
import { toast } from 'react-toastify'

import { getClientConfigs } from './getClientConfigs'
import { CREATE_TOAST, UPDATE_TOAST } from './constants'
import { CREATE_CLIENT, UPDATE_CLIENT } from './gql-tags'

export const useClient = ({
  deleteOptions,
  queryInput,
}: CrudHookOptions = {}) => {
  const { deleteConfig, readConfig } = getClientConfigs(
    deleteOptions,
    queryInput
  )

  const [create, { loading: isCreating, error: createError }] = useMutation(
    CREATE_CLIENT,
    {
      onCompleted: () => {
        toast.success(
          CREATE_TOAST.SUCCESS_MESSAGE,
          CREATE_TOAST.SUCCESS_OPTIONS
        )
      },
    }
  )
  const [destroy, { loading: isDeleting }] = CRUD_UTILS.useDelete(deleteConfig)
  const [update, { loading: isUpdating, error: updateError }] = useMutation(
    UPDATE_CLIENT,
    {
      onCompleted: () => {
        toast.success(
          UPDATE_TOAST.SUCCESS_MESSAGE,
          UPDATE_TOAST.SUCCESS_OPTIONS
        )
      },
    }
  )

  const {
    data: clientData,
    loading: isLoading,
    error: queryError,
  } = CRUD_UTILS.useRead(readConfig)
  const clientsData = clientData ? Object.values(clientData)[0] : []

  return {
    data: clientsData,
    queryError,
    create,
    isCreating,
    createError,
    update,
    updateError,
    isUpdating,
    destroy,
    isDeleting,
    isLoading,
  }
}
