import { gql } from '@apollo/client'
import { schema } from './schemas'

export const GET_VEGA_PAYER_ORGANIZATIONS = gql`
  query GetVegaPayerOrganizations($input: VegaPayerOrganizationsInput){
    vegaPayerOrganizations(input: $input){
      ${schema}
    }
  }
`
