export const schemaV0 = `
  id
  team
  tool
  dashboard
  page
  card
  resource {
    id
    entity_id
    type
    created_at
    updated_at
  }
  parent
  team_tool_resource
  team_dashboard_resource
  team_page_resource
  order
  children {
    id
    team
    tool
    dashboard
    page
    card
    resource {
      id
      entity_id
      type
      created_at
      updated_at
    }
    parent
    team_tool_resource
    team_dashboard_resource
    team_page_resource
    order
    created_at
    updated_at
  }
  created_at
  updated_at
`
