import ExcelJS from 'exceljs'
import _ from 'lodash'

import { FILE_TYPES } from 'frontend/constants'

const ORDERED_USER_FIELDS = [
  'firstName',
  'lastName',
  'username',
  'email',
  'client',
  'teams',
  'clientSources',
  'pathwaysMonthlyEmailAlertSubscription',
  'primaryClientTeam',
]

export const upload = (
  { target: { files } }: any,
  setStatus: Function,
  createBulkUsers: Function,
) => {
  const [file] = files

  if (!file) return

  if (file.type !== FILE_TYPES.EXCEL_FILE) {
    alert('Unsupported file type.')
    return
  }

  const fileReader = new FileReader()
  fileReader.onload = (e) => processFile(e, file.name, setStatus, createBulkUsers)
  fileReader.readAsArrayBuffer(file)
}

const processFile = async (
  { target: { result } }: any,
  fileName: string,
  setStatus: Function,
  createBulkUsers: Function,
) => {
  const workbook = new ExcelJS.Workbook()
  await workbook.xlsx.load(result)
  const worksheet = workbook.getWorksheet('Bulk Create Users')

  if (!worksheet) {
    alert('Incorrect Excel file.')
    return
  }

  const users: any[] = []
  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber <= 2) return

    const user: any = {}
    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      const userField = ORDERED_USER_FIELDS[colNumber - 1]
      if (userField) user[userField] = cell.text
    })

    users.push(user)
  })

  if (_.isEmpty(users)) {
    alert('No users in Excel file.')
    return
  }

  setStatus({ fileName, tempUsers: users })
  createBulkUsers({ variables: { input: { users } } })
}
