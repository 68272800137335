import useRead from './useRead'

export const useBooksOfBusiness = () => {
  const { data: booksOfBusinessData, loading: isLoading } = useRead()

  const data = booksOfBusinessData?.booksOfBusiness

  return {
    data,
    isLoading,
  }
}
