import React from 'react'

import { GET_USERS } from 'frontend/entity-hooks/ember/useEmberUser/gql-tags'
import { EmberDeleteDialog } from 'frontend/components'
import { useQueryString } from 'frontend/hooks'
import { useTeam } from 'frontend/entity-hooks/ember'

const HEADER_TEXT = 'Delete Team?'
const BODY_TEXT =
  "Are you sure you'd like to delete this team? This action cannot be undone"
const DELETE_PARAMS = ['team', 'user']

export const DeleteTeamDialog = () => {
  const { team }: any = useQueryString()
  const { destroy } = useTeam()

  const handleSubmit = () => {
    const input = { id: team }
    destroy({
      variables: { input },
      update: (cache: any, data: any) => {
        const variables = {
          input: {
            team,
          },
        }
        const query: any = cache.readQuery({
          query: GET_USERS,
          variables,
        })

        if (query) {
          const queryName = Object.keys(query)[0]
          for (const user of query[queryName]) {
            const { teams } = user
            if (teams.length > 1) continue
            if (teams[0].id === team) {
              const userCacheId = cache.identify(user)
              cache.evict({ id: userCacheId })
              cache.gc()
            }
          }
        }
        const userTeamCacheId = `EmberUserTeam:${team}`
        cache.evict({ id: userTeamCacheId })
        cache.gc()

        const deletedTeam: any = Object.values(data.data)[0]
        const teamCacheId = cache.identify(deletedTeam)
        cache.evict({ id: teamCacheId })
        cache.gc()
      },
    })
  }

  return (
    <EmberDeleteDialog
      hasHazardIcon
      headerText={HEADER_TEXT}
      bodyText={BODY_TEXT}
      deleteParams={DELETE_PARAMS}
      handleSubmit={handleSubmit}
    />
  )
}
