import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import Select from 'react-select'

import { CREATE_VEGA_FDA_INDICATION_REGIMEN_APPROVAL } from 'frontend/api/mutations'
import { GET_VEGA_FDA_INDICATION_REGIMEN_APPROVALS } from 'frontend/api/queries'

import { SingleActionDialog } from 'frontend/components/Dialog'
import Spinner from 'frontend/components/Spinner'
import Input from 'frontend/components/Input'

import Spacing from 'frontend/utils/spacing'
import { convertIsoShortToDateObj } from 'frontend/utils/formatDate'

import {
  InputSection,
  FormLabel,
} from 'frontend/Orion/SpecializedData/ValuePerception/MarketBaskets/MarketBasketDetail/Surveys/SurveyView/SurveyForms/utils'

import useFdaApprovalData from './useFdaApprovalData'

const LoadingIndicator = () => {
  return (
    <div style={{ paddingRight: '8px' }}>
      <Spinner />
    </div>
  )
}

const CreateFdaApprovalForm = ({ closeHandler }) => {
  const [formData, setFormData] = useState({})

  const {
    indication: indicationData,
    regimen: regimenData,
    population: populationData,
    lineOfTherapy: lineOfTherapyData,
  } = useFdaApprovalData()

  const input = {
    regimen_id: formData.regimen ? formData.regimen.value : undefined,
    indication_id: formData.indication ? formData.indication.value : undefined,
    populations_ids: formData.populations
      ? formData.populations.map(({ value }) => value)
      : undefined,
    lines_of_therapy_ids: formData.lines_of_therapy
      ? formData.lines_of_therapy.map(({ value }) => value)
      : undefined,
    approval_date: formData.approval_date
      ? convertIsoShortToDateObj(formData.approval_date)
      : null,
  }

  const [createFdaApproval, { loading: isCreatingFdaApproval }] = useMutation(
    CREATE_VEGA_FDA_INDICATION_REGIMEN_APPROVAL,
    {
      variables: { input },
      update: (cache, { data: { createVegaFdaIndicationRegimenApproval } }) => {
        const { vegaFdaIndicationRegimenApprovals } = cache.readQuery({
          query: GET_VEGA_FDA_INDICATION_REGIMEN_APPROVALS,
        })

        const newFdaApprovals = [
          ...vegaFdaIndicationRegimenApprovals,
          createVegaFdaIndicationRegimenApproval,
        ]

        cache.writeQuery({
          query: GET_VEGA_FDA_INDICATION_REGIMEN_APPROVALS,
          data: {
            vegaFdaIndicationRegimenApprovals: newFdaApprovals,
          },
        })
      },
      onError: alert,
      onCompleted: () => {
        closeHandler()
      },
    }
  )

  const onIndicationChange = (option) => {
    setFormData((prevState) => ({ ...prevState, indication: option }))
  }

  const onRegimenChange = (option) => {
    setFormData((prevState) => ({ ...prevState, regimen: option }))
  }

  const onPopulationsChange = (option) => {
    option = option || []
    setFormData((prevState) => ({ ...prevState, populations: option }))
  }

  const onLinesOfTherapyChange = (option) => {
    option = option || []
    setFormData((prevState) => ({ ...prevState, lines_of_therapy: option }))
  }

  const onDateChange = ({ value }) => {
    setFormData((prevState) => ({ ...prevState, approval_date: value }))
  }

  return (
    <SingleActionDialog
      header={'Create FDA Approval'}
      submitText="Create FDA Approval"
      submitHandler={createFdaApproval}
      cancelHandler={closeHandler}
    >
      <div style={{ padding: `${Spacing.S4} ${Spacing.S7} ${Spacing.S7}` }}>
        {isCreatingFdaApproval ? (
          <Spinner />
        ) : (
          <div>
            <form>
              <InputSection>
                <FormLabel>Indication (required)</FormLabel>
                <Select
                  value={formData.indication}
                  options={indicationData.options}
                  onChange={onIndicationChange}
                  isLoading={indicationData.loading}
                  components={{ LoadingIndicator }}
                />
              </InputSection>
              <InputSection>
                <FormLabel>Regimen (required)</FormLabel>
                <Select
                  value={formData.regimen}
                  options={regimenData.options}
                  onChange={onRegimenChange}
                  isLoading={regimenData.loading}
                  components={{ LoadingIndicator }}
                />
              </InputSection>
              <InputSection>
                <FormLabel>Populations (required)</FormLabel>
                <Select
                  isMulti
                  value={formData.populations}
                  options={populationData.options}
                  onChange={onPopulationsChange}
                  isLoading={populationData.loading}
                  components={{ LoadingIndicator }}
                />
              </InputSection>
              <InputSection>
                <FormLabel>Lines of Therapy (required)</FormLabel>
                <Select
                  isMulti
                  value={formData.lineOfTherapy}
                  options={lineOfTherapyData.options}
                  onChange={onLinesOfTherapyChange}
                  isLoading={lineOfTherapyData.loading}
                  components={{ LoadingIndicator }}
                />
              </InputSection>
              <InputSection>
                <FormLabel>Approval Date (required)</FormLabel>
                <Input
                  name="approval_date"
                  type="date"
                  value={formData.approval_date}
                  onChange={onDateChange}
                />
              </InputSection>
            </form>
          </div>
        )}
      </div>
    </SingleActionDialog>
  )
}

CreateFdaApprovalForm.propTypes = {
  closeHandler: PropTypes.func.isRequired,
}

export default CreateFdaApprovalForm
