import React from 'react'

import { useTeam } from 'frontend/entity-hooks/ember'

import { PrimaryTeam } from './PrimaryTeam'

interface Props {
  fieldState: any
  onChange: any
}

export const Container = ({ fieldState, onChange }: Props) => {
  const {
    client: { id: selectedClientId },
    teams: selectedTeams = [],
    primaryTeam,
  } = fieldState

  const { data: clientTeams }: any = useTeam({
    queryInput: { client: selectedClientId },
  })

  const options = []
  for (const selectedTeam of selectedTeams) {
    const { id, name } = clientTeams.find(
      ({ id }: any) => id === selectedTeam.id
    )
    options.push({ label: name, value: id })
  }

  return (
    <PrimaryTeam
      options={options}
      onChange={onChange}
      selectedPrimaryTeam={primaryTeam}
    />
  )
}
