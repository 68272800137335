import { INPUT_TYPES } from 'frontend/components/Form/constants/input-type-to-component-map'

import {
  PAYER,
  PRODUCT,
  BOOK,
  BENEFIT_TYPE,
  DOC_TYPE,
  PRODUCT_PAYER_POLICY_TRACKING_PROJECTS,
  LINK_TYPE,
  LINK,
  LINK_STEPS,
  POLICY_SEARCH_WORD,
  LINK_NOTE,
  ASSIGNED_POLARIS_USER_AUTH_IDS,
} from '../../../utils/enums'

export const POLICY_LINK_FIELDS = [PAYER, BOOK, BENEFIT_TYPE, DOC_TYPE]

export const PRODUCT_POLICY_FIELDS = [
  PRODUCT,
  POLICY_SEARCH_WORD,
  LINK,
  LINK_NOTE,
  LINK_TYPE,
  LINK_STEPS,
  PRODUCT_PAYER_POLICY_TRACKING_PROJECTS,
  ASSIGNED_POLARIS_USER_AUTH_IDS,
]

export const POLICY_DETAILS_CONFIG = [
  {
    label: 'Payer',
    accessor: PAYER,
    inputType: INPUT_TYPES.SINGLE_SELECT,
    isRequired: true,
  },
  {
    label: 'Product',
    accessor: PRODUCT,
    inputType: INPUT_TYPES.SINGLE_SELECT,
    isRequired: true,
  },
  {
    label: 'Book',
    accessor: BOOK,
    inputType: INPUT_TYPES.SINGLE_SELECT,
    isRequired: true,
  },
  {
    label: 'Benefit Type',
    accessor: BENEFIT_TYPE,
    inputType: INPUT_TYPES.SINGLE_SELECT,
    isRequired: true,
  },
  {
    label: 'Document Type',
    accessor: DOC_TYPE,
    inputType: INPUT_TYPES.SINGLE_SELECT,
    isRequired: true,
  },
]

export const TRACKING_TEAM_CONFIG = [
  {
    label: 'Project(s)',
    accessor: PRODUCT_PAYER_POLICY_TRACKING_PROJECTS,
    inputType: INPUT_TYPES.MULTI_SELECT,
    isRequired: false,
  },
  {
    label: 'Assignee',
    accessor: ASSIGNED_POLARIS_USER_AUTH_IDS,
    inputType: INPUT_TYPES.MULTI_SELECT,
    isRequired: false,
  },
]
