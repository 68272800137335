import { useQuery } from '@apollo/client'

import { GET_VEGA_PATHWAYS_ORGANIZATIONS } from 'frontend/api/queries'
import { useIndications } from 'frontend/api'
import { usePerson } from 'frontend/entity-hooks'

import { usePathwaysInfluencer } from 'frontend/entity-hooks/usePathwaysInfluencer'

import { getJoinedPathwaysInfluencerData } from './utils'

const usePathwaysInfluencerData = () => {
  const {
    data: pathwaysInfluencersData,
    isLoading: isPathwaysInfluencersLoading,
    options,
    areOptionsLoading,
    update,
  } = usePathwaysInfluencer()

  const {
    data: pathwaysOrganizationData,
    loading: isPathwaysOrganizationsLoading,
  } = useQuery(GET_VEGA_PATHWAYS_ORGANIZATIONS)

  const { data: peopleData, isLoading: isPeopleLoading } = usePerson()

  const {
    data: indicationsData,
    isLoading: isIndicationsLoading,
    labelMap: indicationsLabels,
    options: indicationsOptions,
  } = useIndications()

  const isReadyToJoin =
    !isPathwaysInfluencersLoading &&
    !isPathwaysOrganizationsLoading &&
    !isPeopleLoading &&
    !isIndicationsLoading

  let joinedPathwaysInfluencersData = []
  if (isReadyToJoin) {
    joinedPathwaysInfluencersData = getJoinedPathwaysInfluencerData({
      pathwaysInfluencersData,
      pathwaysOrganizationData,
      peopleData,
      indicationsData,
    })
  }

  return {
    pathwaysOrganizations: {
      data: !isPathwaysOrganizationsLoading
        ? pathwaysOrganizationData.vegaPathwaysOrganizations
        : [],
      loading: isPathwaysOrganizationsLoading,
    },
    people: {
      data: peopleData,
      loading: isPeopleLoading,
    },
    indications: {
      data: !isIndicationsLoading ? indicationsData.vegaIndications : [],
      loading: isIndicationsLoading,
      labelMap: indicationsLabels,
      options: indicationsOptions,
    },
    pathwaysInfluencers: {
      data: joinedPathwaysInfluencersData,
      loading: !isReadyToJoin,
      options,
      areOptionsLoading,
      update,
    },
  }
}

export default usePathwaysInfluencerData
