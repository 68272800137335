import gql from 'graphql-tag'

export const UPDATE_SECONDARY_ORGANIZATION = gql`
  mutation UpdateSecondaryOrganization(
    $input: UpdateSecondaryOrganizationInput!
  ) {
    updateSecondaryOrganization(input: $input) {
      id
      name
      type
      alias
      committees
      tdg_internal_note
      created_at
      updated_at
    }
  }
`
