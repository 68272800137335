import React, { useState } from 'react'
import ReactSelect, {
  ActionMeta,
  OptionsType,
  OptionTypeBase,
} from 'react-select'

import { ErrorLabel } from './styled-components'
import { getLabelStyle } from './styling/getInvalidStyle'

const Select = ({
  isMulti,
  name,
  onChange,
  options,
  value: rawValue,
  getIsInvalid,
  invalidLabel,
  placeholder,
  disabled,
  style,
}: any) => {
  const defaultValue = getValueFromOptions(isMulti, rawValue, options)
  const [isInvalid, setIsInvalid] = useState(false)

  const handleChange = async (
    value: any,
    { name }: ActionMeta<OptionTypeBase>
  ) => {
    const newValue = value ?? []
    const rawValue = isMulti
      ? newValue.map(({ value }: OptionTypeBase) => value)
      : newValue.value
    onChange({ name, value: rawValue })
    if (getIsInvalid) {
      const res = await getIsInvalid(rawValue)
      setIsInvalid(res)
    }
  }

  const selectStyles = getLabelStyle(isInvalid, style)

  return (
    <>
      <ReactSelect
        isMulti={isMulti}
        value={defaultValue}
        menuPortalTarget={document.querySelector('body')}
        name={name}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        isDisabled={disabled}
        styles={selectStyles}
      />
      <ErrorLabel isInvalid={isInvalid}>{invalidLabel}</ErrorLabel>
    </>
  )
}

export default Select

const getValueFromOptions = (
  isMulti: boolean,
  rawValue: any,
  options: OptionsType<OptionTypeBase>
) => {
  if (!isMulti)
    return options.find(({ value }: OptionTypeBase) => value === rawValue)

  const value = rawValue?.reduce((acc: OptionTypeBase[], rawValue: any) => {
    const option = options.find(
      ({ value }: OptionTypeBase) => value === rawValue
    )
    if (option) acc.push(option)
    return acc
  }, [])

  return value
}
