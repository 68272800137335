import { gql } from '@apollo/client'
import { schema } from './schemas'

export const GET_VEGA_BOOKS_OF_BUSINESS = gql`
  query GetVegaBooksOfBusiness($input: VegaBooksOfBusinessInput){
    vegaBooksOfBusiness(input: $input){
      ${schema}
    }
  }
`
