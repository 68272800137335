import { MetaHistoryQueryInput } from 'frontend/interfaces'

import useRead from './useRead'

const usePathwaysOrganizationsHistory = (queryInput: MetaHistoryQueryInput = {}) =>{
  const {
    data: pathwaysOrganizationsHistoryData,
    loading: isLoading,
  } = useRead(queryInput)

  return {
    data: pathwaysOrganizationsHistoryData?.pathwaysOrganizationsHistory,
    isLoading
  }
}

export default usePathwaysOrganizationsHistory
