import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Button } from '@pulse-analytics/pulse-design-system'

import DataTable from 'frontend/components/DataTable'
import Header from 'frontend/components/Header'
import Spinner from 'frontend/components/Spinner'
import CopyOneOfStringButton from 'frontend/Orion/shared/CopyOneOfStringButton'
import ExportExcelButton from 'frontend/components/ExportExcelButton'
import Icon from 'frontend/components/Icon'

import Color from 'frontend/utils/color'
import Spacing from 'frontend/utils/spacing'

import { GET_PAYER_ORGANIZATIONS } from 'frontend/api/queries'

import CreatePayerAccountForm from './CreatePayerAccountForm'
import EditPayerAccountForm from './EditPayerAccountForm'
import getPayersAccountsColumns from './getPayersAccountsColumns'

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const TitleSection = styled.section({
  display: 'flex',
  justifyContent: 'space-between',
  padding: 24,
  alignItems: 'center',
  width: '100%',
})

const ButtonSection = styled.section({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
})

const PAGE_TITLE = 'Payer Accounts'

const getPayersColumnsInjectables = ({ setSelectedRowData }) => {
  const onRowClick = ({ original }) => {
    setSelectedRowData(original)
  }
  return {
    onRowClick,
  }
}

const getPayersAccountsInjectedColumns = (setSelectedRowData) => {
  const payersAccountsTableColumnsDependencies = { setSelectedRowData }
  const payersAccountsTableColumnsInjectables = getPayersColumnsInjectables(
    payersAccountsTableColumnsDependencies
  )
  const payersAccountsColumns = getPayersAccountsColumns(
    payersAccountsTableColumnsInjectables
  )
  return payersAccountsColumns
}

const PayerAccounts = () => {
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const { data, loading } = useQuery(GET_PAYER_ORGANIZATIONS)

  if (loading) return <Spinner />

  const { payerOrganizations } = data
  const payersColumns = getPayersAccountsInjectedColumns(setSelectedRowData)

  const closeHandler = () => {
    setSelectedRowData(null)
  }

  const exportData = payerOrganizations.map(
    ({ slug, organization, organizationTiny }) => ({
      Slug: slug,
      Organization: organization,
      'Short Name': organizationTiny,
    })
  )
  return (
    <Container>
      <TitleSection>
        <Header header={PAGE_TITLE} />

        <ButtonSection>
          <Button
            onClick={() => setIsCreateModalOpen(true)}
            style={{
              padding: `${Spacing.S2} ${Spacing.S3}`,
              margin: `${Spacing.S4} 0`,
            }}
          >
            <Icon
              iconName="add"
              color1={Color.WHITE}
              width={16}
              style={{ marginRight: 8 }}
            />
            Create Payer Account
          </Button>
          <CopyOneOfStringButton
            queryDoc={GET_PAYER_ORGANIZATIONS}
            dataKey="payerOrganizations"
            datumKey="slug"
            buttonStyle={{ marginRight: 15 }}
          />
          <ExportExcelButton
            filename={`PayerAccounts`}
            sheetName={'Payer Accounts'}
            isDisabled={!exportData.length}
            data={exportData}
          />
        </ButtonSection>
      </TitleSection>

      {isCreateModalOpen && (
        <CreatePayerAccountForm
          closeHandler={() => setIsCreateModalOpen(false)}
        />
      )}

      {selectedRowData && (
        <EditPayerAccountForm
          selectedPayerOrganizationData={selectedRowData}
          closeHandler={closeHandler}
        />
      )}

      <DataTable data={payerOrganizations} columns={payersColumns} />
    </Container>
  )
}

export default PayerAccounts
