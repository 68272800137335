import React from 'react'
import PropTypes from 'prop-types'

import Row from './Row'

const Rows = ({ page, ...props }) => {
  return (
    <>
      {page.map((row, rowId) => (
        <Row key={`row_${rowId}`} row={row} rowId={rowId} {...props} />
      ))}
    </>
  )
}

Rows.propTypes = {
  page: PropTypes.array.isRequired,
}

export default Rows
