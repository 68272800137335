import React from 'react'

import DeleteDialog from './DeleteDialog'
import Header from './Header'
import PanelsWrapper from './PanelsWrapper'
import SidePanel from './SidePanel'
import BulkCreateUsersModalContainer from './BulkCreateUsersModal'

const Home = () => {
  return (
    <>
      <Header />
      <SidePanel />
      <DeleteDialog />
      <PanelsWrapper />
      <BulkCreateUsersModalContainer />
    </>
  )
}

export default Home
