import * as GQL_TAGS from './gql-tags'

export const getSecondaryOrganizationConfigs = (
  deleteOptions = {},
  queryInput: any
) => ({
  createConfig: {
    tag: GQL_TAGS.CREATE_SECONDARY_ORGANIZATION,
    readTag: GQL_TAGS.GET_SECONDARY_ORGANIZATIONS,
  },
  updateConfig: {
    tag: GQL_TAGS.UPDATE_SECONDARY_ORGANIZATION,
  },
  deleteConfig: {
    tag: GQL_TAGS.DELETE_SECONDARY_ORGANIZATION,
    readTag: GQL_TAGS.GET_SECONDARY_ORGANIZATIONS,
    mutationOptions: deleteOptions,
  },
  readConfig: {
    tag: GQL_TAGS.GET_SECONDARY_ORGANIZATIONS,
    input: queryInput,
  },
  optionsConfig: {
    tag: GQL_TAGS.GET_SECONDARY_ORGANIZATION_OPTIONS,
  }
})
