import React from 'react'

import { Button } from '@pulse-analytics/pulse-design-system'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router'

import Spinner from 'frontend/components/Spinner'

import { PUSH_TEAM_USER_MARKET_BASKET_SUBSCRIPTIONS } from 'frontend/api/mutations'

const PushToProductionButton = () => {
  const { clientTeamId } = useParams()

  const [push, { loading }] = useMutation(
    PUSH_TEAM_USER_MARKET_BASKET_SUBSCRIPTIONS,
    {
      variables: { input: { clientTeamId } },
      onCompleted: () => alert('push success'),
    }
  )

  const buttonLabel = loading ? <Spinner /> : 'Push Subscriptions to Production'

  return (
    <Button
      type="secondary"
      style={{
        fontFamily: 'inherit',
        margin: 12,
        padding: '0 6px',
        cursor: loading ? 'not-allowed' : 'pointer',
      }}
      onClick={push}
    >
      {buttonLabel}
    </Button>
  )
}

export default PushToProductionButton
