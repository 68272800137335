import styled from '@emotion/styled'
import React from 'react'
import ModalControls from './ModalControls'
import { ImportButton } from './ImportButton'

const Container = styled.div({
  borderLeft: '1px solid #E0E0E0',
  display: 'inline-flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
})

interface HeaderControlProps {
  onClick: () => void
  closeModal: () => void
  handleUpdate: () => void
}

export const HeaderControls = ({
  onClick,
  closeModal,
  handleUpdate,
}: HeaderControlProps) => {
  return (
    <Container>
      <ImportButton onClick={onClick} />
      <ModalControls closeModal={closeModal} handleUpdate={handleUpdate} />
    </Container>
  )
}
