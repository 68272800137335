import React from 'react'

import { useTeam } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'

import TeamsListPanel from './TeamsListPanel'
import TeamsLoadingPanel from './TeamsLoadingPanel'

const TeamsListPanelInnerContainer = () => {
  const { client: selectedClient }: { client?: string } = useQueryString()

  const { isLoading: teamsLoading }: any = useTeam({
    queryInput: { client: selectedClient },
  })

  if (teamsLoading) return <TeamsLoadingPanel />

  return <TeamsListPanel />
}

export default TeamsListPanelInnerContainer
