import React from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { FontSpace } from 'frontend/utils'
import { Colors } from 'frontend/utils/pulseStyles'

interface Props {
  isDisabled?: boolean
  name?: string
  onChange?: (event: any) => void
  placeholder?: string
  type?: 'text' | 'number'
  value?: string
}

const Input = styled.input({
  background: Colors.COOL_GRAY_100,
  width: '100%',
  marginTop: 4,
  padding: 8,
  borderRadius: 4,
  ...FontSpace.FS2,
  border: `1px solid ${transparentize(0.96, Colors.BLACK)}`,
  ':hover': {
    border: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
  },
  ':disabled': {
    background: Colors.LIGHT_GRAY_1,
    cursor: 'not-allowed',
  },
  ':focus': {
    border: `1px solid ${transparentize(0.1, Colors.PRIMARY)}`,
    outline: 'none',
  },
  '::placeholder': {
    color: Colors.GRAY_300,
  },
})

export const TextInput = ({
  isDisabled,
  name,
  onChange,
  placeholder,
  type = 'text',
  value,
}: Props) => (
  <Input
    disabled={isDisabled}
    name={name}
    onChange={onChange}
    placeholder={placeholder}
    type={type}
    value={value}
  />
)
