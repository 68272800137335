import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

export const useShouldShowModal = () => {
  const location = useLocation()

  const shouldShowModal = (searchStringParam: string) => {
    const params = queryString.parse(location.search)
    return Boolean(params[searchStringParam])
  }

  return shouldShowModal
}
