import { gql } from '@apollo/client'

import { schemaV0 } from './schemas'

export const GET_EMBER_NODES = gql`
  query GetEmberNodes($input: EmberNodesInput!) {
    emberNodes(input: $input) {
      ${schemaV0}
    }
  }
`
