import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const GET_USERS = gql`
  query GetUsers($input: EmberUserInput) {
    emberUsers(input: $input) {
      ${schemaV0}
    }
  }
`
