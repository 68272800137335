import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const CREATE_SECONDARY_ORGANIZATION_PERSON = gql`
  mutation CreateSecondaryOrganizationPerson(
    $input: CreateSecondaryOrganizationPersonInput!
  ) {
    createSecondaryOrganizationPerson(input: $input) {
      ${schemaV0}
    }
  }
`
