import React from 'react'

const ChevronDown = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="chevron--down-a"
          className="color1"
          d="M3.92722016,5.40170572 L13.2400297,-2.81463546 C14.0239203,-3.50623263 15.2948581,-3.50623263 16.0787487,-2.81463546 C16.8626393,-2.12303829 16.8626393,-1.00173773 16.0787487,-0.310140556 L8.17088858,6.66666667 L16.0787487,13.6434739 C16.8626393,14.3350711 16.8626393,15.4563716 16.0787487,16.1479688 C15.2948581,16.839566 14.0239203,16.839566 13.2400297,16.1479688 L3.92722016,7.93162761 C3.13537106,7.23300897 3.13537106,6.10032436 3.92722016,5.40170572 L3.92722016,5.40170572 Z"
        />
      </defs>
      <use
        fill={iconColor}
        fill-rule="evenodd"
        transform="rotate(-90 13.5 8.167)"
        xlinkHref="#chevron--down-a"
      />
    </svg>
  )
}

export default ChevronDown
