import React, { Dispatch, SetStateAction } from 'react'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Colors } from 'frontend/utils/pulseStyles'

import Header from './Header'
import RegimensListPanel from './RegimensListPanel'

interface Props {
  areParentResourcesLoading: boolean
  parentResources: any[]
  setResources: Dispatch<SetStateAction<any>>
  treatmentPlans?: any[]
}

const Wrapper = styled.div({
  borderRight: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
  borderTop: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
  flex: 1,
  background: Colors.WHITE,
})

const RegimensPanel = (props: Props) => {
  return (
    <Wrapper>
      <Header />
      <div style={{ height: 'calc(100% - 46px)', overflowY: 'auto' }}>
        <RegimensListPanel
          {...props}
        />
      </div>
    </Wrapper>
  )
}

export default RegimensPanel
