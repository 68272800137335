import React from 'react'

import { Spinner } from 'frontend/components'
import Tooltip from 'frontend/components/Tooltip'

import StyledTab from '../Tab'
import Icon from './Icon'

interface Props {
  activeTab: string
  previousApprovedPolicyOptions?: any[]
  selectedPreviousApprovedPolicy?: any
}

const TOOLTIP_TEXT = "No previously approved policies to compare"

const Comparison = ({
  activeTab,
  previousApprovedPolicyOptions,
  selectedPreviousApprovedPolicy
}: Props) => {
  if (!previousApprovedPolicyOptions) return (
    <StyledTab
      disabled
    >
      <Spinner />
    </StyledTab>
  )

  let content = (
    <StyledTab
      disabled={!selectedPreviousApprovedPolicy}
      value="comparison"
    >
      <Icon
        activeTab={activeTab}
      />
      Comparison
    </StyledTab>
  )

  if (!selectedPreviousApprovedPolicy) content = (
    <Tooltip
      id={'payer-policy-v1-comparison-tab'}
      effect={'solid'}
      place={'right'}
      text={TOOLTIP_TEXT}
    >
      {content}
    </Tooltip>
  )

  return content
}

export default Comparison
