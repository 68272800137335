import React from 'react'

import { useEmberNode } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'

const NodeLabel = () => {
  const { node, nodeType }: any = useQueryString()

  const {
    data: nodeData,
    isLoading: isNodeDataLoading,
  }: any = useEmberNode({
    queryInput: {
      type: nodeType,
      id: node,
    },
  })

  const label = isNodeDataLoading ? null : nodeData[0].name

  return (
    <>
      {label}
    </>
  )
}

export default NodeLabel
