interface PanelInjectables {
  indicationsData: any
  selectedIndicationId: any
}

interface RegimenInjectables {
  indicationsData: any
  selectedId: any
}

const getPanelsInjectables = ({
  indicationsData,
  selectedIndicationId,
}: PanelInjectables) => {
  const regimensData = getRegimensData({
    indicationsData,
    selectedId: selectedIndicationId,
  })

  return {
    indications: {
      data: indicationsData.data?.indications,
      loading: indicationsData.loading,
      error: indicationsData.error,
    },
    regimens: {
      data: regimensData,
    },
  }
}

export default getPanelsInjectables

const getRegimensData = ({
  indicationsData,
  selectedId,
}: RegimenInjectables) => {
  let regimensData = []

  if (!indicationsData.loading && selectedId) {
    const selectedIndication = indicationsData.data.indications.find(
      ({ _id }: any) => _id === selectedId
    )

    if (selectedIndication) {
      regimensData = selectedIndication.regimens
    }
  }
  return regimensData
}
