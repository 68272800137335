import React, { useState } from 'react'

import { SingleActionDialog } from 'frontend/components/Dialog'
import Spinner from 'frontend/components/Spinner'

import usePayerPolicyLinkMgmtData from '../../usePayerPolicyLinkMgmtData'

import {
  DEFAULT_FORM_INPUT,
  MODAL_TITLE,
  MODAL_SUBHEADER,
  useHandlers,
} from './utils'
import CreateForm from './CreateForm'

const AddLinkModal = ({ closeModal }) => {
  const {
    isLoading,
    policyOptions,
    createConfig: {
      createProductPolicy,
      isProductPolicyCreating,
      createPayerPolicy,
      isPayerPolicyCreating,
    },
  } = usePayerPolicyLinkMgmtData()
  const [input, setInput] = useState(DEFAULT_FORM_INPUT)
  const { submit } = useHandlers({
    input,
    setInput,
    closeModal,
    createProductPolicy,
    createPayerPolicy,
  })

  const isSubmitDisabled = isPayerPolicyCreating || isProductPolicyCreating

  return (
    <SingleActionDialog
      submitText="Save"
      cancelHandler={closeModal}
      header={MODAL_TITLE}
      subheader={MODAL_SUBHEADER}
      contentStyle={{ width: '100%', margin: 64 }}
      submitHandler={submit}
      isSubmitDisabled={isSubmitDisabled}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <CreateForm data={policyOptions} input={input} setInput={setInput} />
      )}
    </SingleActionDialog>
  )
}

export default AddLinkModal
