import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import Users from './Users'
import ClientToolAccess from './ClientToolAccess'
import Sidebar from './Sidebar'

const Reports = () => {
  return (
    <div style={{ display: 'flex', flex: 1 }}>
      <Sidebar />
      <Switch>
        <Route path="/reports/users" component={Users} />
        <Route path="/reports/client-tool-access" component={ClientToolAccess} />
        <Redirect to="/reports/users" />
      </Switch>
    </div>
  )
}
export default Reports
