import { DataProxy } from '@apollo/client'

import { GET_USERS } from 'frontend/entity-hooks/ember/useEmberUser/gql-tags'

export const removeUserFromTeamCache = (
  teamId: string,
  user: any,
  cache: DataProxy
) => {
  const { id: userId } = user
  const variables = {
    input: {
      team: teamId,
    },
  }
  const query: any = cache.readQuery({
    query: GET_USERS,
    variables,
  })

  if (query) {
    const queryName = Object.keys(query)[0]
    const cachedData = query[queryName].filter(({ id }: any) => id !== userId)

    cache.writeQuery({
      query: GET_USERS,
      variables,
      data: {
        [queryName]: [...cachedData],
      },
    })
  }
}
