import React, { useState } from 'react'
import styled from '@emotion/styled'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { useRewriteSearchString } from 'frontend/hooks'
import { IconButton } from './IconButton'

const OPTIONS = [
  {
    label: 'User',
    value: 'createUser',
  },
  {
    label: 'Bulk Create Users',
    value: 'createBulkUsers',
  },
  {
    label: 'Team',
    value: 'createTeam',
  },
  {
    label: 'Client',
    value: 'createClient',
  },
]

const Wrapper = styled.div({
  width: 83,
})

const CreateButton = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const rewriteSearchString = useRewriteSearchString()

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleItemClick = (value: string) => {
    if (value === 'createBulkUsers') {
      rewriteSearchString({ bulkCreateUsers: 'true' })
    } else {
      rewriteSearchString({ sidePanel: value })
    }
    handleClose()
  }

  return (
    <Wrapper>
      <IconButton id="icon-button" onClick={handleClick} />
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {OPTIONS.map(({ label, value }) => (
          <MenuItem key={value} onClick={() => handleItemClick(value)}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Wrapper>
  )
}

export default CreateButton
