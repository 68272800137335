import { gql } from '@apollo/client'
import { schema } from './schemas'

export const CREATE_VEGA_PAYER_ORGANIZATION = gql`
  mutation CreateVegaPayerOrganization(
    $input: CreateVegaPayerOrganizationInput!
  ) {
    createVegaPayerOrganization(input: $input) {
      ${schema}
    }
  }
`
