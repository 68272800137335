import { gql } from '@apollo/client'
import { schemaV1 } from './schemas'

export const UPDATE_PAYER_POLICY_LINK = gql`
  mutation UpdatePayerPolicyLink($input: UpdatePayerPolicyLinkInput!) {
    updatePayerPolicyLink(input: $input) {
      ${schemaV1}
    }
  }
`
