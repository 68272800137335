import { useNccnPanels } from 'frontend/api'

import { useNccnPanelVotingMember } from 'frontend/entity-hooks'
import { usePerson } from 'frontend/entity-hooks'

export const useNccnPanelAffiliationsData = () => {
  const nccnPanelVotingMembers = useNccnPanelVotingMember()
  const people = usePerson()
  const nccnPanels = useNccnPanels({})

  return {
    nccnPanelVotingMembers,
    people,
    nccnPanels,
  }
}
