import React from 'react'
import styled from '@emotion/styled'

import { Spinner } from 'frontend/components'
import { Colors } from 'frontend/utils/pulseStyles'

const SpinnerContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 12,
  backgroundColor: Colors.WHITE,
})

const UsersLoadingPanel = () => (
  <SpinnerContainer>
    <Spinner />
  </SpinnerContainer>
)

export default UsersLoadingPanel
