import React from 'react'
import { Typography } from '@mui/material'

const PREVIOUS_POLICY = 'Previous Policy Approval Date (Left)'

const LABEL_STYLES = {
  color: '#0E2539',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 700,
}

export const PreviousPolicyLabel = () => {
  return <Typography sx={LABEL_STYLES}>{PREVIOUS_POLICY}</Typography>
}
