import { MessageDialog } from 'frontend/components'
import React from 'react'

interface ErrorDialogProps {
  errorMessage: string | undefined
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>
}

const ERROR_HEADER = 'Error'
const ICON_LINK =
  'https://res.cloudinary.com/pulsedatatools/image/upload/v1597856924/wave-app/icons/status/caution-solid.svg'

export const ErrorDialog = ({
  errorMessage,
  setErrorMessage,
}: ErrorDialogProps) => {
  const shouldShowMessageDialog = Boolean(errorMessage)

  const handleMessageDialogClose = () => {
    setErrorMessage(undefined)
  }
  return (
    <MessageDialog
      header={ERROR_HEADER}
      iconLink={ICON_LINK}
      message={errorMessage}
      showModal={shouldShowMessageDialog}
      handleSubmit={handleMessageDialogClose}
    />
  )
}
