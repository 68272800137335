import React from 'react'
import _ from 'lodash'

import MetaDataList from 'frontend/components/MetaHistory/MetaDataList'
import { usePathwaysOrganizationsHistory } from 'frontend/api/usePathwaysOrganization'

import { KEY_TO_LABEL } from './key-label-map'
import Spinner from 'frontend/components/Spinner'


const PathwaysAccountHistoryList = ({ entity }: { entity: any }) => {
  const {
    data: metaHistory,
    isLoading: isHistoryLoading,
  } = usePathwaysOrganizationsHistory({ id: entity.id })

  if (!isHistoryLoading && _.isEmpty(metaHistory)){
    return <NoHistoryMessage />
  } else if (isHistoryLoading) {
    return <Spinner />
  }

  return (
    <div style={{ width: '100%' }}>
      <MetaDataList
        data={metaHistory}
        keyToLabel={KEY_TO_LABEL}
      />
    </div>
  )
}

export default PathwaysAccountHistoryList

const NoHistoryMessage = () => {
  return (
    <div style={{ padding: 24 }}>
      No history on record.
    </div>
  )
}
