import React from 'react'

import { Button } from '@pulse-analytics/pulse-design-system'
import Spinner from 'frontend/components/Spinner'

import { useProductionPermissions } from 'frontend/api'

const TEXT = {
  BUTTON_LABEL: 'Deploy Permissions',
  BUTTON_KEY: 'deploy-permissions-button',
}

const COLORS = {
  BACKGROUND_COLOR: '#0668D926',
  TEXT_COLOR: '#0668D9',
}

const BUTTON_STYLE = {
  padding: '2px 8px',
  borderRadius: '4px',
  fontSize: '11px',
  height: '28px',
  background: COLORS.BACKGROUND_COLOR,
  color: COLORS.TEXT_COLOR,
}

const LoadingContent = () => (
  <>
    <div style={{ paddingRight: '6px' }}>Deploying</div>
    <Spinner fill={COLORS.TEXT_COLOR} size="13" />
  </>
)

const DeployPermissionsButton = () => {
  const { push, isPushing } = useProductionPermissions()

  let content = TEXT.BUTTON_LABEL
  if (isPushing) content = <LoadingContent />

  return (
    <Button
      key={TEXT.BUTTON_KEY}
      onClick={push}
      style={BUTTON_STYLE}
      isDisabled={isPushing}
    >
      {content}
    </Button>
  )
}

export default DeployPermissionsButton
