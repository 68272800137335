import { useQuery } from '@apollo/client'
import _ from 'lodash'

import {
  useBooksOfBusiness,
  usePayerOrganizations,
  usePayerPolicyTrackingProjects,
} from 'frontend/api'

import { useProductPolicyLink, usePayerPolicyLink } from 'frontend/entity-hooks'

import { usePolarisUsers } from 'frontend/api/usePolarisUsers/usePolarisUsers'
import { GET_VEGA_PRODUCTS } from 'frontend/api/queries'

import { getPayerPolicyOptions } from 'frontend/PayerPolicyLinkMgmt/v0/utils/getPayerPolicyOptions'

const usePolicyViewerData = ({ policyId, productId }) => {
  const {
    data: productPolicyData,
    isLoading: areProductPoliciesLoading,
    options: productPolicyOptions,
    areOptionsLoading: areProductPolicyOptionsLoading,
    update: updateProductPolicy,
    isUpdating: isProductPolicyUpdating,
  } = useProductPolicyLink({ queryInput: { id: productId } })
  const {
    data: payerPolicyData,
    isLoading: arePayerPoliciesLoading,
    options: payerPolicyOptions,
    areOptionsLoading: arePayerPolicyOptionsLoading,
    update: updatePayerPolicy,
    isUpdating: isPayerPolicyUpdating,
  } = usePayerPolicyLink({ queryInput: { id: policyId } })
  const { data: books, isLoading: areBooksLoading } = useBooksOfBusiness()
  const { data: payers, isLoading: arePayersLoading } = usePayerOrganizations()
  const { data: products, loading: areProductsLoading } = useQuery(
    GET_VEGA_PRODUCTS
  )
  const {
    data: projectsData,
    isLoading: areProjectsLoading,
  } = usePayerPolicyTrackingProjects()

  const { data: usersData, isLoading: areUsersLoading } = usePolarisUsers()

  const isDataLoading =
    areProductPoliciesLoading ||
    areProductPolicyOptionsLoading ||
    arePayerPoliciesLoading ||
    arePayerPolicyOptionsLoading ||
    areBooksLoading ||
    arePayersLoading ||
    areProductsLoading ||
    areProjectsLoading ||
    areUsersLoading

  let policyOptions = {}
  let data = {}

  if (!isDataLoading & !_.isEmpty(productPolicyData)) {
    // * product data (datum) must always be de-structured last to account for edge case where we have overlapping fields
    data = {
      ...payerPolicyData[0],
      ...productPolicyData[0],
      policyId,
      productId,
    }
    policyOptions = getPayerPolicyOptions({
      productPolicyOptions,
      payerPolicyOptions,
      payers,
      products,
      books,
      projectsData,
      usersData,
    })
  }

  return {
    isLoading: isDataLoading,
    data,
    policyOptions,
    updateConfig: {
      updateProductPolicy,
      isProductPolicyUpdating,
      updatePayerPolicy,
      isPayerPolicyUpdating,
    },
  }
}

export default usePolicyViewerData
