import React from 'react'

import ConnectionPanelContainer from '../../shared/widget/orgInfluencerConnectionComponents/ConnectionPanel/ConnectionPanelContainer'
import { usePathwaysInfluencersHistory } from 'frontend/api/usePathwaysOrganization'

const InfluencerConnectionPanelContainer = ({
  entityId,
  title,
  formConfig,
  selectedConnection,
  changeConnection,
  connectionsData,
  setWhetherNewConnectionBeingCreated,
  isNewConnectionBeingCreated,
  setWhetherUnsavedChanges,
}) => {
  const queryInput = selectedConnection.id
    ? { id: selectedConnection.id }
    : undefined

  const {
    data: metaHistory,
    isLoading: isHistoryLoading,
  } = usePathwaysInfluencersHistory(queryInput)

  return (
    <ConnectionPanelContainer
      metaHistory={metaHistory}
      entityId={entityId}
      title={title}
      formConfig={formConfig}
      selectedConnection={selectedConnection}
      changeConnection={changeConnection}
      setWhetherNewConnectionBeingCreated={setWhetherNewConnectionBeingCreated}
      isNewConnectionBeingCreated={isNewConnectionBeingCreated}
      connectionsData={connectionsData}
      setWhetherUnsavedChanges={setWhetherUnsavedChanges}
    />
  )
}

export default InfluencerConnectionPanelContainer
