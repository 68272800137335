import React from 'react'

import { useRewriteSearchString } from 'frontend/hooks'
import Button from 'frontend/components/Button'

const BUTTON_STYLE = {
  width: '124px',
  height: '28px',
  backgroundColor: '#0668D926',
  color: '#0668D9',
  textTransform: 'none',
  fontSize: '12px',
  fontFamily: 'Inter',
  fontWeight: 700,
  letterSpacing: '0.2px',
  lineHeight: '18px',
}

const ImportPdfButton = () => {
  const rewriteSearchString = useRewriteSearchString()
  const onClick = () => {
    rewriteSearchString({ importModal: true })
  }

  return (
    <Button buttonStyle={BUTTON_STYLE} onClick={onClick} type="secondary">
      Import Policy PDF
    </Button>
  )
}

export default ImportPdfButton
