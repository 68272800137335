import { gql } from '@apollo/client'
import { schema } from './schemas'

export const UPDATE_VEGA_PAYER_ORGANIZATION = gql`
  mutation UpdateVegaPayerOrganization($input: UpdateVegaPayerOrganizationInput!) {
    updateVegaPayerOrganization(input: $input) {
      ${schema}
    }
  }
`
