import {
  DISPLAY_CELL_TYPES,
  EDIT_CELL_TYPES,
  FILTER_TYPES,
  SORT_TYPES,
} from '@pulse-analytics/pulse-design-system/dist/components/DataTable/utils'

const { BUTTON } = DISPLAY_CELL_TYPES
const { MULTI_SELECT } = FILTER_TYPES
const { STRING, TEXT_ARRAY, NUMBER, DATE } = SORT_TYPES
const { TEXT_INPUT, SINGLE_SELECT_INPUT, MULTI_SELECT_INPUT } = EDIT_CELL_TYPES

const getColConfig = ({
  handleDelete,
  handleUpdate,
  handleUpdateUserMetadata,
}) => [
  {
    Header: 'Avatar',
    accessor: 'picture',
    width: 20,
    sticky: 'left',
  },
  {
    Header: 'Name',
    accessor: 'name',
    sortType: STRING,
    sticky: 'left',
    editCellConfig: {
      type: TEXT_INPUT,
      updateFn: handleUpdate,
    },
  },
  {
    Header: 'Role',
    accessor: 'role',
    sortType: STRING,
    sticky: 'left',
    editCellConfig: {
      type: SINGLE_SELECT_INPUT,
      updateFn: handleUpdateUserMetadata,
      options: [
        { value: 'site_admin', label: 'site_admin' },
        { value: 'tdg_leader', label: 'tdg_leader' },
        {
          value: 'tdg_consultant_contributor',
          label: 'tdg_consultant_contributor',
        },
        { value: 'mmit_user', label: 'mmit_user' },
      ],
    },
  },
  // TODO: Make multi-select and use to cater polaris user experience
  // {
  //   Header: 'Knowledge Areas',
  //   accessor: 'knowledge_areas',
  //   sortType: STRING,
  //   sticky: 'left',
  //   editCellConfig: {
  //     type: SINGLE_SELECT_INPUT,
  //     updateFn: handleUpdateUserMetadata,
  //     options: [
  //       { value: 'pathways', label: 'pathways' },
  //       { value: 'payer', label: 'payer' },
  //       { value: 'provider', label: 'provider' },
  //     ],
  //   },
  // },
  {
    Header: 'Nickname',
    accessor: 'nickname',
    sortType: STRING,
    editCellConfig: {
      type: TEXT_INPUT,
      updateFn: handleUpdate,
    },
  },
  {
    Header: 'Initials',
    accessor: 'initials',
    sortType: STRING,
    editCellConfig: {
      type: TEXT_INPUT,
      updateFn: handleUpdateUserMetadata,
    },
  },
  {
    Header: 'Email',
    accessor: 'email',
    sortType: STRING,
  },
  {
    Header: 'Username',
    accessor: 'username',
    sortType: STRING,
  },
  {
    Header: 'Total Login Count',
    accessor: 'logins_count',
    sortType: NUMBER,
  },
  {
    Header: 'Last Login',
    accessor: 'last_login',
    sortType: DATE,
  },
  {
    Header: 'Delete',
    accessor: 'delete',
    sticky: 'left',
    displayCellConfig: {
      type: BUTTON,
      label: 'Delete',
      onClick: handleDelete,
    },
  },
]

export default getColConfig
