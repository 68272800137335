import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const DELETE_CLIENT = gql`
  mutation DeleteClient($input: DeleteClientInput_EMBER!) {
    deleteClient_EMBER(input: $input) {
      ${schemaV0}
    }
  }
`
