import { LazyQueryResponse, UsePaginatedReadConfig } from '../interfaces'

const getDetailDatum = (
  { input }: UsePaginatedReadConfig,
  { getData }: LazyQueryResponse,
) => {
  getData({ variables: { input } })
}

export default getDetailDatum
