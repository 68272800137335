import dayjs, { Dayjs } from 'dayjs'
import { zonedTimeToUtc } from 'date-fns-tz'
import { getDateErrorText } from './getDateErrorText'

const DISABLE_TYPING_SLOT_PROP = {
  actionBar: {
    actions: ['accept', 'clear'],
  },
}

export const getSlotProps = (props: any, minDate: Dayjs, timeZone: string) => {
  const { value, isFocused, isRequired, placeholder } = props

  const inputDate = value ? dayjs(zonedTimeToUtc(value, timeZone)) : null
  const isValidDate = inputDate && inputDate.isValid()
  const isBeforeMinDate = inputDate && inputDate.isBefore(minDate)
  const hasUserInput = isFocused && value
  const helperText = getDateErrorText({
    isRequired,
    hasUserInput,
    isValidDate,
    isBeforeMinDate,
    value,
  })

  return {
    textField: {
      helperText,
      error: !!helperText,
      placeholder: placeholder || 'MM/DD/YYYY',
    },
    ...DISABLE_TYPING_SLOT_PROP,
  }
}
