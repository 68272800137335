import { gql } from '@apollo/client'
import { schemaV1 } from './schemas'

export const DELETE_PAYER_POLICY_LINK = gql`
  mutation DeletePayerPolicyLink($input: DeletePayerPolicyLinkInput!) {
    deletePayerPolicyLink(input: $input) {
      ${schemaV1}
    }
  }
`
