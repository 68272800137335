import getDashboardTools from './getDashboardTools'
import getListsConfigs from './getListsConfigs'
import getListItems from './getListItems'

const getPanelsInjectables = (injectablesData: any) => {
  const dashboardTools = getDashboardTools(injectablesData)
  const listsConfigs = getListsConfigs(injectablesData)
  const listItems = getListItems({
    listsConfigs,
    ...injectablesData,
  })

  return {
    dashboardTools,
    listsConfigs,
    listItems,
  }
}

export default getPanelsInjectables
