export const DATA_IMPORT_CONFIG = [
  { label: 'Import Sheets', link: '/orion/import-export/import-sheets' },
  {
    label: 'Export Data',
    link: '/orion/import-export/export',
    childLinks: [
      {
        label: 'Export Custom Data',
        link: '/export-custom-data',
      },
      {
        label: 'Export Custom Powerpoint',
        link: '/export-custom-powerpoint',
      },
    ],
  },
]

export const ORGANIZATIONS_CONFIG = [
  {
    label: 'Alternative Payment Models',
    link: '/orion/organizations/alternative-payment-model',
    childLinks: [{ label: 'Accounts', link: '/accounts' }],
  },
  {
    label: 'Medical Benefit Managers',
    link: '/orion/organizations/mbm',
    childLinks: [
      {
        label: 'Oncology Benefit Managers',
        link: '/obm',
        childLinks: [
          { label: 'Account Overview', link: '/account-overview' },
          { label: 'Services', link: '/services' },
          { label: 'Influencers', link: '/influencers' },
          { label: 'Payer Partnerships', link: '/payer-partnerships' },
        ],
      },
      {
        label: 'Laboratory Benefit Managers',
        link: '/lbm',
        childLinks: [
          { label: 'Account Overview', link: '/account-overview' },
          { label: 'Services', link: '/services' },
          { label: 'Influencers', link: '/influencers' },
          { label: 'Payer Partnerships', link: '/payer-partnerships' },
        ],
      },
    ],
  },
  {
    label: 'Pathways',
    link: '/orion/organizations/pathways',
    childLinks: [
      { label: 'Accounts', link: '/accounts' },
      {
        label: 'Positioning',
        link: '/positioning',
        showOnSuperUserOnly: true,
      },
      // TODO (Anh) Remove mock pages below once completed development of DataTable v2
      {
        label: 'Mock Positioning',
        link: '/mock-positioning',
        showOnSuperUserOnly: true,
      },
      {
        label: 'Mock Client Tool Access',
        link: '/mock-client-tool-access',
        showOnSuperUserOnly: true,
      },
      { label: 'Influencers', link: '/influencers' },
    ],
  },
  {
    label: 'Payers',
    link: '/orion/organizations/payer',
    childLinks: [
      { label: 'Accounts', link: '/accounts' },
      { label: 'Book of Business', link: '/book-of-business' },
      { label: 'Quality of Access Score', link: '/scores' },
    ],
  },
  {
    label: 'Providers',
    link: '/orion/organizations/provider',
    childLinks: [
      { label: 'Accounts', link: '/accounts' },
      {
        label: 'Affiliate EMR',
        link: '/affiliate-emr',
      },
      {
        label: 'Affiliate Sites 340B Status',
        link: '/affiliate-sites-340b-status',
      },
      { label: 'Clinical Trials', link: '/clinical-trials' },
      { label: 'Key Decision Makers', link: '/key-decision-makers' },
      { label: 'Staffing (CMS)', link: '/staffing-cms' },
      // { label: 'Provider Types', link: '/provider-types' },
      // { label: 'Provider Networks', link: '/provider-networks' },
    ],
  },
  {
    label: 'Institutions',
    link: '/orion/organizations/institutions',
    childLinks: [
      {
        label: 'NCCN Panels',
        link: '/nccn-panels',
      },
      {
        label: 'Secondary Organizations',
        link: '/secondary-organizations',
        featureFlag: 'shouldShowOrionSecondaryOrganizations',
      },
    ],
  },
]

export const GENERAL_DATA_CONFIG = [
  {
    label: 'Products',
    link: '/orion/general/products',
  },
  // {
  //   label: 'Manufacturers',
  //   link: '/orion/general/manufacturers',
  // },
  {
    label: 'FDA Approvals',
    link: '/orion/general/fda-approvals',
  },
  {
    label: 'People Management',
    link: '/orion/general/people-management',
    childLinks: [
      { label: 'People', link: '/people' },
      {
        label: 'Secondary Organization Connections',
        link: '/secondary-organization-connections',
      },
      {
        label: 'NCCN Panel Affiliations',
        link: '/nccn-panel-affiliations',
      },
    ],
  },
  {
    label: 'US States',
    link: '/orion/general/us-states',
  },
  {
    label: 'Treatment Plans',
    link: '/orion/general/treatment-plans',
    childLinks: [
      { label: 'Indications', link: '/indications' },
      { label: 'Regimens', link: '/regimens' },
      { label: 'Phoenix Treatment Plans', link: '/phoenix-tps' },
      { label: 'Treatment Plans', link: '/treatment-plans' },
      { label: 'Subtypes', link: '/subtypes' },
      { label: 'Lines', link: '/lines' },
      { label: 'Coverage Types', link: '/coverage-types' },
      { label: 'Therapeutic Areas', link: '/therapeutic-areas' },
    ],
  },
]

export const SPECIALIZED_DATA_CONFIG = [
  {
    label: 'Value Perception Tool',
    link: '/orion/specialized/value-perception',
    childLinks: [
      { label: 'Market Baskets', link: '/market-baskets' },
      { label: 'Stakeholders', link: '/stakeholders' },
      {
        label: 'Stakeholder Roles',
        link: '/stakeholder-roles',
        childLinks: [
          { label: 'Roles', link: '/roles' },
          {
            label: 'Indication Role Specialties',
            link: '/indication-role-specialties',
          },
        ],
      },
      { label: 'Providers', link: '/providers' },
      { label: 'Institutions', link: '/institutions' },
      {
        label: 'Community Practice Network',
        link: '/community-practice-network',
      },
      {
        label: 'Products',
        link: '/products',
      },
      {
        label: 'Manufacturers',
        link: '/manufacturers',
      },
      { label: 'Client Teams', link: '/client-teams' },
    ],
  },
]

export const ADMINISTRATOR_DATA_CONFIG = [
  {
    label: 'Polaris User Management',
    link: '/orion/administrator/polaris-user-mgmt',
    showOnSuperUserOnly: true,
  },
  {
    label: 'Lists Config Management',
    link: '/orion/administrator/lists-config-mgmt',
    showOnSuperUserOnly: true,
  },
  { label: 'Push Dev to Prod', link: '/orion/administrator/push-dev-prod' },
  {
    label: 'Sheet Management',
    link: '/orion/administrator/sheet-mgmt',
    showOnSuperUserOnly: true,
  },
  {
    label: 'Node Management',
    link: '/orion/administrator/node-management',
    showOnSuperUserOnly: true,
  },
  {
    label: 'Push Dev to Prod Management',
    link: '/orion/administrator/push-dev-prod-mgmt',
    showOnSuperUserOnly: true,
  },
  {
    label: 'Business Object',
    link: '/orion/administrator/business-object',
    showOnSuperUserOnly: true,
    childLinks: [
      {
        label: 'Business Object Management',
        link: '/business-object-mgmt',
      },
      {
        label: 'Business Object Modal Management',
        link: '/business-object-modal-mgmt',
      },
    ],
  },
  {
    label: 'Edit Role Node',
    link: '/orion/administrator/edit-role-node',
    showOnSuperUserOnly: true,
  },
  {
    label: 'End-User Terms Management',
    link: '/orion/administrator/user-term-mgmt',
    showOnSuperUserOnly: true,
  },
  {
    label: 'Total History',
    link: '/orion/administrator/total-history',
    showOnSuperUserOnly: true,
  },
]
