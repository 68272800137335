import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons'

import Color from 'frontend/utils/color'

import DefaultCell from './DefaultCell'

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
`

const ExpandIcon = styled(FontAwesomeIcon)`
  visibility: hidden;
  padding: 8px;
  color: ${Color.GRAY_MEDIUM};
  ${Container}:hover & {
    visibility: visible;
  }
  :hover {
    transition: 250ms ease;
    color: ${Color.GRAY_DARK};
  }
`

const ExpandCell = (props) => {
  const { row } = props
  const { onEvent } = props.cellConfig

  const onClickFireEventAndFocus = () => {
    onEvent(row)
  }

  return (
    <Container onClick={onClickFireEventAndFocus}>
      <ExpandIcon size="lg" icon={faExpandAlt} />
      <DefaultCell {...props} />
    </Container>
  )
}

export default ExpandCell
