interface OrgData {
  slug: String
  name: String
  name_tiny: String
}

const getExportData = (pathwaysOrganizationsData : any) => (
  pathwaysOrganizationsData.map(
    ({ slug, name, name_tiny }: OrgData) => ({
      ['Slug']: slug,
      ['Organization']: name,
      ['Short Name']: name_tiny,
    })
    )
  )

export default getExportData
