import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { CREATE_VEGA_TEAM_REGION } from 'frontend/api/mutations'
import { GET_VEGA_TEAMS_REGIONS } from 'frontend/api/queries'

import { SingleActionDialog } from 'frontend/components/Dialog'
import Spinner from 'frontend/components/Spinner'
import Input from 'frontend/components/Input'

import Spacing from 'frontend/utils/spacing'

import { FormLabel } from '../utils'

const MODAL_TITLE = `Create Region`

const CreateRegionForm = ({ closeHandler, handleListItemSearchUpdate }) => {
  const { clientTeamId } = useParams()

  const [formData, setFormData] = useState({
    name: undefined,
  })

  const [createRegion, { loading: mutationLoading }] = useMutation(
    CREATE_VEGA_TEAM_REGION,
    {
      update: (cache, { data: { createVegaTeamRegion } }) => {
        const { vegaTeamsRegions } = cache.readQuery({
          query: GET_VEGA_TEAMS_REGIONS,
          variables: { clientTeamId },
        })

        const newRegions = [...vegaTeamsRegions, createVegaTeamRegion]

        cache.writeQuery({
          query: GET_VEGA_TEAMS_REGIONS,
          variables: { clientTeamId },
          data: { vegaTeamsRegions: newRegions },
        })
      },
      variables: {
        input: { team_id: clientTeamId, ...formData },
      },
      onCompleted: ({ createVegaTeamRegion }) => {
        closeHandler()
        handleListItemSearchUpdate({
          teamRegionId: createVegaTeamRegion.id,
        })
      },
      onError: alert,
    }
  )

  const handleChange = ({ name, value }) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const onSubmit = (e) => {
    e.preventDefault()
    createRegion()
  }

  console.log(formData)

  return (
    <SingleActionDialog
      header={MODAL_TITLE}
      submitText={MODAL_TITLE}
      cancelHandler={closeHandler}
      submitHandler={onSubmit}
    >
      {mutationLoading ? (
        <Spinner />
      ) : (
        <form style={{ padding: Spacing.S7 }}>
          <section>
            <FormLabel>Region Name (required)</FormLabel>
            <Input
              name="name"
              type="text"
              onChange={handleChange}
              value={formData.name}
            />
          </section>
        </form>
      )}
    </SingleActionDialog>
  )
}

CreateRegionForm.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  handleListItemSearchUpdate: PropTypes.func.isRequired,
}

export default CreateRegionForm
