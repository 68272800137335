import { gql } from '@apollo/client'
import { schemaV0 } from './schemas'

export const CREATE_CLIENT = gql`
  mutation CreateClient($input: CreateClientInput_EMBER!) {
    createClient_EMBER(input: $input) {
      ${schemaV0}
    }
  }
`
