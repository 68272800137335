const getSelectOptionsAndMaps = (
  queryData,
  { key, value } = { key: 'id', value: 'name' } // default key and value for vega entities are id and name, respectively
) => {
  const options = []
  const optionMap = {}
  const labelMap = {}

  if (queryData) {
    const { data, loading } = queryData
    if (!loading) {
      const [dataKey] = Object.keys(data)
      data[dataKey].forEach((datum) => {
        const label = datum[value]
        const optionValue = datum[key]
        const option = {
          label,
          value: optionValue,
        }
        options.push(option)
        optionMap[optionValue] = option
        labelMap[optionValue] = label
      })
    }
  }

  return { options, optionMap, labelMap }
}

export default getSelectOptionsAndMaps
