import React from 'react'

const ChevronLeft = ({ iconColor }: { iconColor: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="5 4 15 15"
    >
      <defs>
        <path
          className="color1"
          id="chevron--left-a"
          d="M0.26724907,4.36751953 L4.45801336,0.259348939 C4.81076413,-0.0864496462 5.38268614,-0.0864496462 5.73543692,0.259348939 C6.08818769,0.605147524 6.08818769,1.1657978 5.73543692,1.51159639 L2.17689986,5 L5.73543692,8.48840361 C6.08818769,8.8342022 6.08818769,9.39485248 5.73543692,9.74065106 C5.38268614,10.0864496 4.81076413,10.0864496 4.45801336,9.74065106 L0.26724907,5.63248047 C-0.0890830234,5.28317115 -0.0890830234,4.71682885 0.26724907,4.36751953 L0.26724907,4.36751953 Z"
        />
      </defs>
      <use
        fill={iconColor}
        fillRule="evenodd"
        transform="translate(9 7)"
        xlinkHref="#chevron--left-a"
      />
    </svg>
  )
}

export default ChevronLeft
