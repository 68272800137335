const getDashboardTools = ({
  dashboardToolsData: {
    data,
    loading,
    error,
  },
}: any) => {
  const dashboardTools = {
    data: data?.nodes ?? [],
    loading,
    error,
  }
  return dashboardTools
}

export default getDashboardTools
