import { UseCrudHookInput } from './interfaces'

import { useCreate } from './useCreate'
import { useUpdate } from './useUpdate'
import { useDelete } from './useDelete'
import { useRead } from './useRead'
import { useReadOptions } from './useReadOptions'

export const useCrudHookApi = ({
  readConfig,
  updateConfig,
  createConfig,
  deleteConfig,
  optionsConfig = {},
}: UseCrudHookInput) => {
  const { data, loading: isLoading } = useRead(readConfig)
  const [create, { loading: isCreating }] = useCreate(createConfig)
  const [destroy, { loading: isDeleting }] = useDelete(deleteConfig)
  const [update, { loading: isUpdating }] = useUpdate(updateConfig)

  return {
    create,
    isCreating,
    update,
    isUpdating,
    destroy,
    isDeleting,
    data,
    isLoading,
  }
}
