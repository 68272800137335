import React from 'react'
import { Tag } from '@pulse-analytics/pulse-design-system'
import { transparentize } from 'polished'

import { EXPAND } from 'frontend/components/DataTable/Cells/cellTypes'
import MultiSelectColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/MultiSelectColumnFilter'
import customMultiSelectFilterFn from 'frontend/components/Table/custom-filters/MultiSelect/customMultiSelectFilterFn'

import Color from 'frontend/utils/color'

const DataExtractionFlagTag = ({ value }) => {
  let label = 'False'
  let color = Color.GRAY_DARK

  if (value) {
    label = 'True'
    color = Color.PRIMARY
  }

  return (
    <Tag
      color={color}
      style={{
        border: 'none',
        background: transparentize(0.85, color),
        textTransform: 'none',
      }}
    >
      {label}
    </Tag>
  )
}

const getProvidersAccountsColumns = ({ onRowClick }) => {
  return [
    {
      Header: 'Slug',
      accessor: 'slug',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
      width: 300,
    },
    {
      Header: 'Organization',
      accessor: 'organization',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
      width: 300,
    },
    {
      Header: 'Provider Cancer Center',
      accessor: 'providerCancerCenter',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
      width: 200,
    },
    {
      Header: 'State',
      accessor: 'state',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
      width: 100,
    },
    {
      Header: 'City',
      accessor: 'city',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
      width: 100,
    },
    {
      Header: 'Oncologists #',
      accessor: 'oncologistsCount',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
      width: 100,
    },
    {
      Header: 'Sites #',
      accessor: 'sitesCount',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
      width: 100,
    },
    {
      Header: 'Group Practice PAC ID',
      accessor: 'groupPracticePacId',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
      width: 100,
    },
    {
      Header: 'Aliases',
      accessor: 'aliases',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
      width: 100,
    },
    {
      Header: 'Data Extraction Flag',
      accessor: 'isFlaggedForDataExtraction',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      cellFormatter: (value) => <DataExtractionFlagTag value={value} />,
      sortType: 'bool',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
      width: 100,
    },
  ]
}

export default getProvidersAccountsColumns
