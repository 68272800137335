import React from 'react'
import { Box } from '@mui/material'

import { SectionLabel } from '../SectionLabel'
import { FilterButton } from './FilterButton'
import { InfoButton } from './InfoButton'
import { Product } from './Product'

interface Props {
  mostRecentPolicy: any
  policyState: any
  setPolicyState: any
}

const STYLES = {
  marginTop: '24px',
  marginLeft: '12px',
  padding: '24px',
  borderRadius: '8px',
  border: '1px solid #e0e0e0',
  width: '423px',
}

const ProductsSection = ({
  mostRecentPolicy,
  policyState,
  setPolicyState
}: Props) => {
  return (
    <Box sx={STYLES}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <SectionLabel label="Products" />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          {/* <FilterButton onClick={onClick} />
          <InfoButton onClick={onClick} /> */}
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: '16px',
        }}
      >
        {
          mostRecentPolicy.products.map(
            (documentProduct: any, index: number) => (
              <Product
                key={index}
                documentProduct={documentProduct}
                policyState={policyState}
                setPolicyState={setPolicyState}
              />
            )
          )
        }
      </Box>
    </Box>
  )
}

export default ProductsSection
