import { gql } from '@apollo/client'
import { schema } from './schemas'

export const DELETE_VEGA_BOOK_OF_BUSINESS = gql`
  mutation DeleteVegaBookOfBusiness($input: DeleteVegaBookOfBusinessInput!) {
    deleteVegaBookOfBusiness(input: $input) {
      ${schema}
    }
  }
`
