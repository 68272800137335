import gql from 'graphql-tag'

export const GET_PROVIDER_KEY_DECISION_MAKERS_OPTIONS = gql`
  query getProviderKeyDecisionMakersOptions {
    providerKeyDecisionMakersOptions
  }
`


export default GET_PROVIDER_KEY_DECISION_MAKERS_OPTIONS
