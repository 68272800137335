import * as GQL_TAGS from './gql-tags'

export const getPayerPolicyLinkConfigs = (
  deleteOptions = {},
  queryInput: any
) => ({
  createConfig: {
    tag: GQL_TAGS.CREATE_PAYER_POLICY_LINK,
    readTag: GQL_TAGS.GET_PAYER_POLICY_LINKS,
  },
  updateConfig: {
    tag: GQL_TAGS.UPDATE_PAYER_POLICY_LINK,
  },
  deleteConfig: {
    tag: GQL_TAGS.DELETE_PAYER_POLICY_LINK,
    readTag: GQL_TAGS.GET_PAYER_POLICY_LINKS,
    mutationOptions: deleteOptions,
  },
  readConfig: {
    tag: GQL_TAGS.GET_PAYER_POLICY_LINKS,
    input: queryInput,
  },
  readOptionsConfig: {
    tag: GQL_TAGS.GET_PAYER_POLICY_LINKS_OPTIONS,
  }
  })
