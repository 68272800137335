import _ from 'lodash'

import getCoercedLabel from './getCoercedLabel'

const getSelectedFilterOptions = (filterValue, labelMap) => {
  return !_.isEmpty(filterValue)
    ? filterValue.map((value) => ({
        value,
        label: getCoercedLabel(labelMap[value]),
      }))
    : null
}

export default getSelectedFilterOptions
