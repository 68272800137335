import React, { MutableRefObject, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { Form, Spinner } from 'frontend/components'
import { SingleActionDialog } from 'frontend/components/Dialog'

import { useNccnPanelsData } from '../utils'
import getFormConfig from './getFormConfig'

const MODAL_TITLE = 'Create NCCN Panel'

const CreateModal = () => {
  const inputRef: MutableRefObject<any> = useRef({})
  const history = useHistory()
  const {
    nccnPanels,
    indications: { options: indicationsOptions },
  } = useNccnPanelsData()

  const setInputRef = ({ name, value }: any) => {
    inputRef.current[name] = value
  }

  const handleCancel = () => {
    inputRef.current = {}
    history.push({ search: '' })
  }

  const handleSubmit = () => {
    const input = inputRef.current
    nccnPanels.create({ variables: { input } })
    handleCancel()
  }

  const formConfig = getFormConfig(indicationsOptions)

  const submitText = nccnPanels.isCreating ? <Spinner /> : 'Save'

  return (
    <SingleActionDialog
      header={MODAL_TITLE}
      cancelHandler={handleCancel}
      submitHandler={handleSubmit}
      submitText={submitText}
    >
      <Form
        datum={inputRef.current}
        formConfig={formConfig}
        setFormState={setInputRef}
      />
    </SingleActionDialog>
  )
}

export default CreateModal
