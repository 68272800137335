import React from 'react'
import styled from '@emotion/styled'

import Panel from './Panel'
import { ParentType, Type } from '../types'

interface Panel {
  type: Type
  parentType?: ParentType
}

const PANELS: Panel[] = [
  {
    type: 'tool',
  },
  {
    type: 'dashboard',
    parentType: 'tool',
  },
  {
    type: 'page',
    parentType: 'dashboard',
  },
  {
    type: 'card',
    parentType: 'page',
  },
]

const Wrapper = styled.div({
  display: 'flex',
})

const Panels = () => (
  <Wrapper>
    {PANELS.map((panel, index) => (
      <Panel
        key={index}
        {...panel}
      />
    ))}
  </Wrapper>
)

export default Panels
