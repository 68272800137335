import React from 'react'
import styled from '@emotion/styled'

import TeamsPanel from './TeamsPanel'
import UsersPanel from './UsersPanel'

const Wrapper = styled.div({
  display: 'flex',
  flex: 2,
})

const TeamsUsersWrapper = () => {
  return (
    <Wrapper>
      <TeamsPanel />
      <UsersPanel />
    </Wrapper>
  )
}

export default TeamsUsersWrapper
