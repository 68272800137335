import React from 'react'

export const NoSelectListPanel = ({
  data,
  searchStringAccessor,
  ListItem,
}: {
  data: any
  searchStringAccessor: string
  ListItem: any
}) => {
  return (
    <div style={{ height: '100%', overflowY: 'auto' }}>
      {data.map((datum: any) => {
        return <ListItem key={datum[searchStringAccessor]} data={datum} />
      })}
    </div>
  )
}
