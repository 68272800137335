import React from 'react'
import { ToastContainer } from 'react-toastify'
import { useFlags } from 'launchdarkly-react-client-sdk'

import 'react-toastify/dist/ReactToastify.css'

export const Toast = () => {
  const FLAGS = useFlags()
  const { polarisToastMessage } = FLAGS

  if (!polarisToastMessage) return null

  return (
    <ToastContainer
      position="bottom-left"
      autoClose={3000}
      limit={10}
      hideProgressBar={true}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover
      theme="light"
    />
  )
}
