import {
  DashboardToolPanelListHeader,
  DashboardToolPanelListItem,
} from '../DashboardToolPanel'
import { ListsPanelListHeader, ListsPanelListItem } from '../ListsPanel'
import {
  ListItemsPanelListHeader,
  ListItemsPanelListItem,
} from '../ListItemsPanel'

const panelHeight = 'calc(100vh - 40px)'

const getPanels = ({
  dashboardTools,
  listsConfigs,
  listItems,
}: {
  dashboardTools: any,
  listsConfigs: any,
  listItems: any,
}) => [
    {
      ...dashboardTools,
      searchParamConfig: {
        searchParam: 'toolId',
        searchParamKey: '_id',
      },
      listWrapperStyle: {
        height: panelHeight,
        minHeight: panelHeight,
        maxHeight: panelHeight,
      },
      listHeaderConfig: {
        ListHeader: DashboardToolPanelListHeader,
      },
      listConfig: {
        ListItem: DashboardToolPanelListItem,
      },
    },
    {
      data: listsConfigs.data,
      loading: listsConfigs.loading,
      error: listsConfigs.error,
      searchParamConfig: {
        searchParam: 'listsConfigId',
        searchParamKey: '_id',
      },
      listWrapperStyle: {
        height: panelHeight,
        minHeight: panelHeight,
        maxHeight: panelHeight,
      },
      listHeaderConfig: {
        ListHeader: ListsPanelListHeader,
        title: listsConfigs.listHeaderTitle,
      },
      listConfig: {
        ListItem: ListsPanelListItem,
      },
    },
    {
      data: listItems.data,
      loading: listItems.loading,
      error: listItems.error,
      searchParamConfig: {
        searchParam: 'listItemKey',
        searchParamKey: 'labelKey',
      },
      listWrapperStyle: {
        height: panelHeight,
        minHeight: panelHeight,
        maxHeight: panelHeight,
      },
      listHeaderConfig: {
        ListHeader: ListItemsPanelListHeader,
        title: listItems.listHeaderTitle,
      },
      listConfig: {
        ListItem: ListItemsPanelListItem,
        placeholder: {
          key: 'newListItem',
          data: {
            labelKey: 'newListItem',
          },
        },
        sortableConfig: {
          updateFunc: listItems.sortableConfigUpdateFunc,
        },
      },
    },
  ]

export default getPanels
