import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import { GET_MANUFACTURERS } from 'frontend/api/queries'

const useManufacturersSelectData = (manufacturers) => {
  const manufacturersInitialValue = manufacturers.map(({ id, name }) => ({
    label: name,
    value: id,
  }))
  const [stagedManufacturers, setStagedManufacturers] = useState(
    manufacturersInitialValue
  )
  const [manufacturersOptions, setManufacturersOptions] = useState([])

  const { data: manufacturersData, loading: manufacturersLoading } = useQuery(
    GET_MANUFACTURERS
  )

  useEffect(() => {
    let manufacturersOptions = []
    if (!manufacturersLoading) {
      manufacturersOptions = manufacturersData.manufacturers.map(
        ({ id, name }) => ({
          label: name,
          value: id,
        })
      )
    }
    setManufacturersOptions(manufacturersOptions)
  }, [manufacturersData])

  const onManufacturersChange = (value) => {
    const newValue = value || []
    setStagedManufacturers(newValue)
  }

  return {
    stagedManufacturers,
    manufacturersOptions,
    onManufacturersChange,
    manufacturersLoading,
  }
}

export default useManufacturersSelectData
