import getPathwaysAccountsColumns from "./getPathwaysAccountsColumns"
import getPathwaysColumnsInjectables from "./getPathwaysColumnsInjectables"

const getPathwaysAccountsInjectedColumns = (setSelectedRowData: any) => {
  const pathwaysAccountsTableColumnsDependencies = { setSelectedRowData }
  const pathwaysAccountsTableColumnsInjectables = getPathwaysColumnsInjectables(
    pathwaysAccountsTableColumnsDependencies
  )
  const pathwaysAccountsColumns = getPathwaysAccountsColumns(
    pathwaysAccountsTableColumnsInjectables
  )
  return pathwaysAccountsColumns
}

export default getPathwaysAccountsInjectedColumns
