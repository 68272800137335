import {
  usePerson,
  useSecondaryOrganizations,
  useSecondaryOrganizationPerson,
} from 'frontend/entity-hooks'

interface SecondaryOrganizationsConnectionsDataPropTypes {
  secondaryOrganizationPersonId?: string
  secondaryOrganizationId?: string
  personId?: string
}

export const useSecondaryOrganizationsConnectionsData = ({
  secondaryOrganizationPersonId,
  secondaryOrganizationId,
  personId,
}: SecondaryOrganizationsConnectionsDataPropTypes = {}) => {
  const secondaryOrganizationsConnections = useSecondaryOrganizationPerson({
    queryInput: secondaryOrganizationId
      ? {
          id: secondaryOrganizationPersonId,
        }
      : undefined,
  })

  const {
    data: secondaryOrganizationsData,
    isLoading: isSecondaryOrganizationsLoading,
  } = useSecondaryOrganizations({
    queryInput: secondaryOrganizationId
      ? { id: secondaryOrganizationId }
      : undefined,
  })

  const {
    data: peopleData,
    options: peopleOptions,
    labelMap: peopleLabelMap,
    isLoading: isPeopleLoading,
  } = usePerson({
    queryInput: personId ? { id: personId } : undefined,
  })

  const secondaryOrganizationsOptions: any = []
  const secondaryOrganizationsLabelMap: any = {}

  secondaryOrganizationsData.forEach(({ id, name }: any) => {
    const option = { label: name, value: id }
    secondaryOrganizationsLabelMap[id] = name
    secondaryOrganizationsOptions.push(option)
  })

  const isLoading =
    secondaryOrganizationsConnections.isLoading ||
    isSecondaryOrganizationsLoading ||
    isPeopleLoading

  return {
    secondaryOrganizationsConnections,
    people: {
      data: peopleData,
      labelMap: peopleLabelMap,
      options: peopleOptions,
    },
    secondaryOrganizations: {
      labelMap: secondaryOrganizationsLabelMap,
      options: secondaryOrganizationsOptions,
    },
    isLoading,
  }
}
