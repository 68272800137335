import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import _ from 'lodash'
import {
  GET_EXPORT_CONFIGURATIONS,
  GET_PAYER_EXPORT_QUEUES_STATUS,
} from 'frontend/api/queries'
import createButtonStyle from 'frontend/components/BusinessObjectModal/PeopleModal/createButtonStyle'
import DataTable from 'frontend/components/DataTable'
import { EXPAND } from 'frontend/components/DataTable/Cells/cellTypes'
import customMultiSelectFilterFn from 'frontend/components/Table/custom-filters/MultiSelect/customMultiSelectFilterFn'
import MultiSelectColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/MultiSelectColumnFilter'
import Color from 'frontend/utils/color'
import FontSpace from 'frontend/utils/fontspace'
import Spacing from 'frontend/utils/spacing'
import Spinner from 'frontend/components/Spinner'
import React, { useEffect, useState } from 'react'
import Button from '../../components/Button'
import ExportModal from './ExportModal'
import PayerHistoricalModalDialog from './modals/PayersHistoricalModalDialog'
import { useAuth0 } from '../../../react-auth0-spa'
import { TOOLS } from './toolPrivileges'
import { downloadExcel } from './utils'

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ExportQueueAlert = (props) => {
  return <MuiAlert elevation={1} variant="standard" {...props} />
}

const Wrapper = styled.div({
  width: '100%',
  background: Color.WHITE,
  color: Color.BLACK,
  borderBottom: `1px solid ${Color.LIGHT_GRAY_1}`,
})

const Header = styled.div({
  marginBottom: Spacing.S7,
  padding: Spacing.S7,
  paddingBottom: 0,
  height: '10vh',
  display: 'flex',
  justifyContent: 'space-between',
})

const Title = styled.div({
  fontWeight: 700,
  ...FontSpace.FS5,
})

const SubTitle = styled.div({
  fontWeight: 500,
  opacity: '0.8',
  ...FontSpace.FS2,
})

const TableContainer = styled.div({
  width: '100%',
  height: '87vh',
  display: 'block',
  fontStyle: 'italic',
})

const AddButtonStyle = {
  cursor: 'pointer',
  padding: '0 8px',
  ...FontSpace.FS3,
}

const CenteredButton = styled.div({
  display: 'inline-flex',
  justifyContent: 'flex-start',
  width: '100%',
  alignItems: 'center',
})

const ExportQueueStatus = styled.div({
  display: 'inline-flex',
  width: 'auto',
  color: '#0668D9',
  marginLeft: '20px',
  fontSize: '13px',
  fontWeight: '500',
  fontStyle: 'oblique',
})

const CREATE_BUTTON_TXT = 'Create Export Configuration'
const PAYER_ERROR_MSG =
  'Payer Export failed, please try again. If the problem persists , please contact the Pulse Product Team.'
const PAYER_SUCCESS_MSG =
  'Payer Export has started, you will receive an email with the file once complete.'
const INITIAL_ERROR_RESPONSE = { errorMessage: null, successMessage: null }

const FlatFileList = () => {
  const [currentEntityId, setCurrentEntityId] = useState(null)
  const [showModal, setModal] = useState(false)
  const [tableList, setTableList] = useState([])
  const [cellWidth, setCellWidth] = useState()
  const [isDownloading, setDownloadingStatus] = useState(null)
  const [snackbarOpen, toggleSnackbar] = useState(false)
  const [exportResponse, setExportResponse] = useState(INITIAL_ERROR_RESPONSE)
  const { errorMessage, successMessage } = exportResponse
  const { accessToken, user } = useAuth0()
  const [isPayerPopUpMessage, setPayerPopUpMessage] = useState(false)
  const [configurationId, setConfigurationId] = useState('')
  const [pendingPayerQueuesCount, setPendingPayerQueuesCount] = useState(0)
  const [pendingPayerQueuesList, setPendingPayerQueuesList] = useState([])

  const onRowClick = (row) => {
    const { original } = row
    setCurrentEntityId(original._id)
    setModal(true)
  }

  const reportWindowSize = () => {
    const SAFE_AREA_WIDTH = window.innerWidth - 79
    const CELL_WIDTH = SAFE_AREA_WIDTH / 5
    setCellWidth(CELL_WIDTH)
  }

  window.onresize = reportWindowSize

  const COLUMNS = [
    {
      Header: 'Client',
      accessor: 'client.name',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',

      width: cellWidth,
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
    {
      Header: 'Team',
      accessor: 'team',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      width: cellWidth,
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
    {
      Header: 'Tool',
      accessor: 'tool.name',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      width: cellWidth,
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
    {
      Header: 'Indications',
      accessor: 'tool.toolSettings.treatmentPlans.indications',
      Filter: (allData) => {
        const newArray = []
        allData.column.preFilteredRows.forEach((item) => {
          item.original.tool.toolSettings.treatmentPlans.indications.forEach(
            (i) => {
              newArray.push({
                values: { indications: i.name },
              })
            }
          )
        })
        return (
          <MultiSelectColumnFilter
            column={{
              preFilteredRows: newArray,
              id: 'indications',
              filterValue: allData.column.filterValue,
              setFilter: allData.column.setFilter,
            }}
          />
        )
      },
      filter: (filter, row, filterValue) => {
        const filteredArray = filter.filter((item) => {
          const filterSubArray = item.original.tool.toolSettings.treatmentPlans.indications.filter(
            (i) => filterValue.includes(i.name)
          )
          if (filterSubArray.length !== 0) return true
          return false
        })
        return filteredArray
      },
      sortType: (a, b) => {
        const firstItem =
          a.original.tool.toolSettings.treatmentPlans.indications
        const secondItem =
          b.original.tool.toolSettings.treatmentPlans.indications
        const join1 = firstItem.map(({ name }) => name).join('')
        const join2 = secondItem.map(({ name }) => name).join('')
        return join1.localeCompare(join2)
      },
      manualSortBy: true,
      width: cellWidth,
      cellFormatter: (indications) =>
        indications.map(({ name }) => name).join(', '),
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
    {
      Header: 'Action',
      accessor: '_id',
      disableFilters: true,
      width: cellWidth,
      disableSortBy: true,
      cellFormatter: (row) => {
        return (
          <CenteredButton>
            <Button
              isDisabled={isDownloading === row}
              onClick={() => exportFlatFileByConfig(row)}
            >
              {isDownloading === row ? (
                <Spinner fill="white" size={20} />
              ) : (
                'Export'
              )}
            </Button>
            <ExportQueueStatus>{getExportStatus(row)}</ExportQueueStatus>
          </CenteredButton>
        )
      },
    },
  ]

  const getExportStatus = (exportConfigId) => {
    return pendingPayerQueuesList[exportConfigId]
      ? `#${pendingPayerQueuesList[exportConfigId]} in Queue`
      : ''
  }

  const createConfiguration = () => {
    setModal(!showModal)
    setCurrentEntityId(null)
  }

  const closeSnackbar = () => {
    toggleSnackbar(false)
    setExportResponse(INITIAL_ERROR_RESPONSE)
  }

  const {
    data: exportConfigData,
    loading: exportConfigLoading,
    refetch,
  } = useQuery(GET_EXPORT_CONFIGURATIONS)

  const {
    data: payerExportQueuesStatus,
    loading: payerExportQueuesStatusLoading,
  } = useQuery(GET_PAYER_EXPORT_QUEUES_STATUS, {
    fetchPolicy: 'network-only',
    pollInterval: 10000,
  })

  const generateFileName = (toolName) => {
    const today = new Date()
    let day = today.getDate()
    let month = parseInt(today.getMonth()) + 1
    const year = today.getFullYear()
    month = month > 9 ? month : '0' + month
    day = day > 9 ? day : '0' + day
    const fileDate = month + '-' + day + '-' + year
    const filename = toolName + ` Data Export ` + fileDate
    return filename
  }

  const downloadFlatFileExport = (configId, payerDate) => {
    const selectedConfig = tableList.find((row) => row._id === configId)
    setDownloadingStatus(configId)
    const toolName = selectedConfig ? selectedConfig.tool.name : ''
    const filename = generateFileName(toolName)
    let exportFlatFileUrl = '/api/export-config/download/' + configId
    const isPayerTool = _.toLower(toolName) === TOOLS.PAYER
    const contentType = isPayerTool ? 'json' : 'vnd.ms-excel'
    if (isPayerTool && payerDate) {
      exportFlatFileUrl = exportFlatFileUrl + '/' + payerDate
    }
    fetch(exportFlatFileUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': `application/${contentType}`,
      },
    })
      .then(function (response) {
        const errorMsg = isPayerTool ? PAYER_ERROR_MSG : response.statusText
        if (!response.ok) {
          throw Error(errorMsg)
        }
        return response
      })
      .then((data) => {
        const successMsg = isPayerTool ? PAYER_SUCCESS_MSG : 'Downloading File'
        setExportResponse((prevState) => ({
          ...prevState,
          successMessage: successMsg,
        }))
        toggleSnackbar(true)
        return data.blob()
      })
      .then((response) => {
        if (!isPayerTool) {
          downloadExcel(response, filename)
        }
        setDownloadingStatus(null)
      })
      .catch((error) => {
        setExportResponse((prevState) => ({
          ...prevState,
          errorMessage: error.message,
        }))
        toggleSnackbar(true)
        setDownloadingStatus(null)
      })
  }

  const togglePayerHistoricalDialog = () => {
    setPayerPopUpMessage(!isPayerPopUpMessage)
    if (isPayerPopUpMessage === false) setDownloadingStatus(null)
  }

  const exportFlatFileByConfig = (configId) => {
    setConfigurationId(configId)
    const configSelected = tableList.find((row) => row._id === configId)
    const toolName = configSelected ? configSelected.tool.name : ''

    if (TOOLS.PAYER === _.toLower(toolName)) {
      togglePayerHistoricalDialog()
    } else {
      downloadFlatFileExport(configId)
    }
  }

  const downloadPayerFlatFile = (payerDate) => {
    downloadFlatFileExport(configurationId, payerDate)
    togglePayerHistoricalDialog()
  }

  useEffect(() => {
    reportWindowSize()

    let tmpTableList = exportConfigLoading
      ? []
      : exportConfigData.exportConfigurations
    setTableList(tmpTableList)

    const tableSelector = window.document.getElementsByClassName('table')[0]
    tableSelector.style.overflowY = 'scroll'
    tableSelector.style.overflowX = 'hidden'
    tableSelector.style.width = '100%'
    tableSelector.style.height = '100%'
  }, [exportConfigData, exportConfigLoading])

  useEffect(() => {
    if (!payerExportQueuesStatusLoading) {
      let tmpPayerPendingQueuesList = payerExportQueuesStatus.payerExportQueues
      let userQueuePosition = {}
      let totalCount = 0

      _.map(tmpPayerPendingQueuesList, (allUserQueueData, index) => {
        if (allUserQueueData.user.user_id === user.sub) {
          totalCount++
          const hasConfigId = _.has(
            userQueuePosition,
            allUserQueueData.configId
          )
          if (!hasConfigId)
            userQueuePosition[allUserQueueData.configId] = ++index
        }
      })
      setPendingPayerQueuesList(userQueuePosition)

      setPendingPayerQueuesCount(totalCount)
    }
  }, [payerExportQueuesStatus, payerExportQueuesStatusLoading])

  return (
    <div
      style={{
        display: 'flex',
        flex: '1 1 auto',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <Wrapper>
        <Header>
          <div>
            <Title> Excel Flat File Exports </Title>
            <SubTitle>
              Select a table row to view and edit an export configuration
            </SubTitle>
          </div>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {pendingPayerQueuesCount > 0 && (
                <ExportQueueAlert severity="info">
                  Number Payer Exports in queue: {pendingPayerQueuesCount}
                </ExportQueueAlert>
              )}
              &nbsp;
              <Button
                buttonStyle={createButtonStyle}
                onClick={createConfiguration}
              >
                <FontAwesomeIcon icon={faPlus} style={AddButtonStyle} />
                {CREATE_BUTTON_TXT}
              </Button>
            </div>
          </div>
        </Header>
        <TableContainer>
          <DataTable columns={COLUMNS} data={tableList}></DataTable>
        </TableContainer>
        {isPayerPopUpMessage && (
          <PayerHistoricalModalDialog
            closeDialog={togglePayerHistoricalDialog}
            downloadPayerFlatFile={downloadPayerFlatFile}
          />
        )}

        {showModal && (
          <ExportModal
            entityId={currentEntityId}
            closeModal={() => setModal(false)}
            setTableListData={refetch}
          />
        )}
      </Wrapper>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={5000}
        onClose={() => closeSnackbar()}
        open={snackbarOpen}
      >
        {errorMessage ? (
          <Alert severity="error">Export Failed : {errorMessage}</Alert>
        ) : (
          <Alert severity="success">Export Successful : {successMessage}</Alert>
        )}
      </Snackbar>
    </div>
  )
}

export default FlatFileList
