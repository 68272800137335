import React from 'react'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'
import { transparentize, mix } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons'
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import '../../TreatmentPlansTabContent/sortableContainerStyles.css'
import { Colors, Spacing } from '../../../../utils/pulseStyles'
import { Span } from '../../TreatmentPlansTabContent/styledComponents'
import {
  IndicationPanelContainer,
  ListHeader,
  ActiveRow,
  InactiveRow,
  UnorderedList,
  ListRow,
} from '../../TreatmentPlansTabContent/styledComponents'

const DragHandle = sortableHandle(() => (
  <span
    style={{
      padding: `${Spacing.SMALL} ${Spacing.NORMAL}`,
      opacity: 0.3,
      fontSize: 16,
    }}
  >
    <FontAwesomeIcon icon={faArrowsAltV} color={Colors.BLACK} />
  </span>
))

const SWITCH_COLOR = Colors.GREEN

const PhoenixSwitch = withStyles({
  switchBase: {
    color: mix(0.4, Colors.BLACK, Colors.WHITE),
    '&$checked': {
      color: SWITCH_COLOR,
    },
    '&$checked + $track': {
      backgroundColor: SWITCH_COLOR,
    },
  },
  checked: {},
  track: {
    backgroundColor: transparentize(0.7, Colors.BLACK),
  },
})(Switch)

const getOrgTypeLabel = (type) => {
  let typeLabel = ''
  if (
    ['Oncology Benefit Manager', 'Laboratory Benefit Manager'].includes(type)
  ) {
    typeLabel = type === 'Oncology Benefit Manager' ? ' (OBM)' : ' (LBM)'
  }
  return typeLabel
}

const SortableItem = sortableElement(
  ({ account, disableAccount, selectedAccountId, selectAccount }) => {
    const isSelected = account._id === selectedAccountId
    return (
      <ListRow
        isSelected={isSelected}
        isActive
        onClick={() => selectAccount(account)}
      >
        <div>
          <DragHandle />
          <Span isSelected={isSelected}>
            {account.name} ({account.slug}){getOrgTypeLabel(account.type)}
          </Span>
        </div>
        <PhoenixSwitch
          checked
          value={account._id}
          onChange={() => disableAccount(account)}
        />
      </ListRow>
    )
  }
)

const SortableContainer = sortableContainer(({ children }) => {
  return <UnorderedList>{children}</UnorderedList>
})

const AccountsPanel = ({
  enabledAccounts,
  disabledAccounts,
  selectAccount,
  selectedAccountId,
  setStagedAccounts,
  isBooksPanelAllowed,
  booksOfBusiness,
}) => {
  const enableAccount = (account) => {
    const accountToEnable = {
      _id: account._id,
      name: account.organization,
      slug: account.slug,
      type: account.type,
      books: [],
    }
    selectAccount(account)
    const updatedAccounts = [...enabledAccounts, accountToEnable]
    setStagedAccounts(updatedAccounts)
  }

  const disableAccount = (account) => {
    const updatedAccounts = enabledAccounts.filter(
      ({ _id }) => account._id !== _id
    )
    setStagedAccounts(updatedAccounts)
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const [removedItem] = enabledAccounts.splice(oldIndex, 1)
    enabledAccounts.splice(newIndex, 0, removedItem)

    setStagedAccounts(enabledAccounts)
  }

  return (
    <>
      <IndicationPanelContainer>
        <ListHeader>Accounts</ListHeader>
        <ActiveRow>ACTIVE ({enabledAccounts.length})</ActiveRow>
        <SortableContainer
          onSortEnd={onSortEnd}
          helperClass="sortableHelper"
          useDragHandle
        >
          {enabledAccounts.map((account, index) => (
            <SortableItem
              key={account._id}
              index={index}
              account={account}
              disableAccount={disableAccount}
              selectedAccountId={selectedAccountId}
              selectAccount={selectAccount}
            />
          ))}
        </SortableContainer>
        <InactiveRow>INACTIVE ({disabledAccounts.length})</InactiveRow>
        <UnorderedList>
          {disabledAccounts.map((account) => {
            const isSelected = account._id === selectedAccountId
            return (
              <ListRow
                isSelected={isSelected}
                key={account._id}
                onClick={() => selectAccount(account)}
              >
                <Span>
                  {account.organization} ({account.slug})
                  {getOrgTypeLabel(account.type)}
                </Span>
                <PhoenixSwitch
                  checked={false}
                  value={account._id}
                  onChange={() => enableAccount(account)}
                />
              </ListRow>
            )
          })}
          <div style={{ marginTop: '25px' }} />
        </UnorderedList>
      </IndicationPanelContainer>
    </>
  )
}

export default AccountsPanel
