import { formatMonthDayYearSlash } from 'frontend/utils/formatDate'

export const getExportConfig = () => [
  {
    Header: 'Person ID',
    accessor: 'member',
  },
  {
    Header: 'First Name',
    accessor: 'memberFirstName',
  },
  {
    Header: 'Middle Name',
    accessor: 'memberMiddleName',
  },
  {
    Header: 'Last Name',
    accessor: 'memberLastName',
  },
  {
    Header: 'NCCN Panel',
    accessor: 'panelName',
  },
  {
    Header: 'Status',
    accessor: 'dynamic_status',
  },
  {
    Header: 'Internal TDG Notes',
    accessor: 'tdg_internal_note',
  },
  {
    Header: 'Start Date',
    accessor: 'start_date',
    cellValueFormatter: formatMonthDayYearSlash,
  },
  {
    Header: 'End Date',
    accessor: 'end_date',
    cellValueFormatter: formatMonthDayYearSlash,
  },
  {
    Header: 'Last Updated',
    accessor: 'updated_at',
    cellValueFormatter: formatMonthDayYearSlash,
  },
]
