import gql from 'graphql-tag'
import { schemaV0 } from './schemas'

export const DELETE_NCCN_PANEL = gql`
  mutation DeleteNccnPanel($input: DeleteNccnPanelInput!) {
    deleteNccnPanel(input: $input) {
      ${schemaV0}
    }
  }
`
