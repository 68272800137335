import compareText from "frontend/utils/compareText"

const sortText = (rowA: any, rowB: any, columnId: string): number => {
  const valueA = convertToStringIfArray(rowA.values[columnId])
  const valueB = convertToStringIfArray(rowB.values[columnId])
  return compareText(valueA, valueB)
}

const convertToStringIfArray = (value: any): string => {
  return Array.isArray(value) ? value.join() : value
}

export default sortText
