import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Colors } from 'frontend/utils/pulseStyles'
import FontSpace from '../../utils/fontspace'

const HeaderText = styled.div(
  {
    fontWeight: 500,
    fontSize: 22,
    letterSpacing: '-0.4px',
    color: Colors.BLACK,
  },
  ({ headerStyle }) => headerStyle
)

const SubheaderText = styled.div(
  {
    fontSize: 12,
    lineHeight: '18px',
    letterSpacing: '0.1px',
  },
  ({ subheaderStyle }) => subheaderStyle
)

const Header = ({ header, subheader, headerStyle, subheaderStyle, style }) => (
  <div style={style}>
    <HeaderText headerStyle={headerStyle}>{header}</HeaderText>
    {subheader && (
      <SubheaderText subheaderStyle={subheaderStyle}>{subheader}</SubheaderText>
    )}
  </div>
)

Header.propTypes = {
  header: PropTypes.node.isRequired,
  subheader: PropTypes.node,
  style: PropTypes.object,
  headerStyle: PropTypes.object,
  subheaderStyle: PropTypes.object,
}

Header.defaultProps = {
  subheader: '',
  style: {},
  headerStyle: {},
  subheaderStyle: {},
}

export default Header
