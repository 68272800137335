import { useEffect } from 'react'
import _ from 'lodash'

export const useNodeForm = ({
  node,
  data,
  isLoading,
  nodeData,
  setNodeData,
  newTextField,
  setNewTextField,
}: any) => {
  useEffect(() => {
    if (!isLoading && data) {
      const selectedNode = data.find((n: any) => n.node.id === node)
      if (selectedNode) {
        setNodeData(selectedNode)
      }
    }
  }, [isLoading])

  const handleInputChange = (e: any) => {
    let value = e.target.value
    const key = e.target.name

    if (e.target.type === 'number') value = Number(value)

    setNodeData((state: any) => ({ ...state, [key]: value }))
  }

  const handleTextInputChange = (e: any) => {
    let value = e.target.value
    const key = e.target.name

    const newTextObj = { text: { ...nodeData.text, [key]: value } }

    setNodeData((state: any) => ({ ...state, ...newTextObj }))
  }

  const handleAddTextField = () => {
    const camelCasedNewTextField = _.camelCase(newTextField)
    if (
      !newTextField ||
      (nodeData.text || {})[camelCasedNewTextField] ||
      _.isEmpty(newTextField)
    ) {
      return null
    }

    setNodeData((state: any) => ({
      ...state,
      text: { ...state.text, [camelCasedNewTextField]: null },
    }))
    setNewTextField('')
  }

  const handleRemoveTextField = (key: any) => {
    setNodeData((state: any) => {
      const clonedState = _.cloneDeep(state)
      const { text, ...restState } = clonedState

      delete text[key]

      return {
        text,
        ...restState,
      }
    })
  }

  return {
    handleInputChange,
    handleTextInputChange,
    handleAddTextField,
    handleRemoveTextField,
  }
}
