import React from 'react'

import { Colors } from 'frontend/utils/pulseStyles'

import Message from './Message'

const ErrorMessages = ({ errors }: { errors?: string[] }) => {
  if (!errors) return null

  return (
    <>
      <div style={{ color: Colors.RED_900, padding: '12px 0' }}>
        Please fix and re-upload the file to finish creating users.
      </div>
      <div style={{ height: '30%', overflowY: 'auto' }}>
        {errors.map((error, index) => {
          return (
            <Message
              key={`${index}-${error}`}
              error={error}
              index={index}
            />
          )
        })}
      </div>
    </>
  )
}

export default ErrorMessages
