export const PRIVILEGES = {
  INDICATIONS: 'indications',
  REGIMENS: 'regimens',
  LINE_OF_THERAPIES: 'line of therapies',
  POPULATIONS: 'populations',
  ACCOUNTS: 'accounts',
  BOOKS: 'books of business',
  TREATMENTPLANS: 'treatmentPlans',
}

export const TOOLS = {
  PATHWAYS: 'pathways',
  APM: 'alternative payment model',
  PROVIDER: 'provider key accounts',
  MBM: 'mbm',
  PAYER: 'payer quality of access',
}

const toolPrivileges = {
  [PRIVILEGES.REGIMENS]: {
    [TOOLS.APM]: false,
  },
  [PRIVILEGES.TREATMENTPLANS]: {
    [TOOLS.MBM]: false,
  },
  [PRIVILEGES.POPULATIONS]: {
    [TOOLS.PAYER]: true,
    [TOOLS.PATHWAYS]: false,
    [TOOLS.APM]: false,
    [TOOLS.PROVIDER]: false,
    [TOOLS.MBM]: false,
  },
  [PRIVILEGES.LINE_OF_THERAPIES]: {
    [TOOLS.PAYER]: true,
    [TOOLS.PATHWAYS]: false,
    [TOOLS.APM]: false,
    [TOOLS.PROVIDER]: false,
    [TOOLS.MBM]: false,
  },
  [PRIVILEGES.BOOKS]: {
    [TOOLS.PAYER]: true,
    [TOOLS.PATHWAYS]: false,
    [TOOLS.APM]: false,
    [TOOLS.PROVIDER]: false,
    [TOOLS.MBM]: false,
  },
}

export const ifSettingAllowed = (tool, setting) => {
  if (toolPrivileges[setting][tool] === false) {
    return false
  } else {
    return true
  }
}
