import React, { Dispatch, SetStateAction } from 'react'
import _ from 'lodash'

import { Spinner } from 'frontend/components'
import { useEmberNode } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'

import InnerContainer from './InnerContainer'

interface Props {
  areParentResourcesLoading: boolean
  accounts: any[]
  parentResources: any[]
  setResources: Dispatch<SetStateAction<any>>
}

const OuterContainer = (props: Props) => {
  const { node, nodeType, tool } = useQueryString()

  const { data, isLoading }: any = useEmberNode({
    queryInput: {
      type: 'tool',
      id: nodeType === 'tool' ? node : tool,
    }
  })

  if (isLoading) return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '75%',
        justifyContent: 'center',
      }}
    >
      <Spinner />
    </div>
  )

  if (_.isEmpty(data[0].permitted_entity_types)) return null

  return (
    <InnerContainer
      {...props}
    />
  )
}

export default OuterContainer
