import React from 'react'

export interface FieldType {
  value: string
  tooltip?: JSX.Element | string
  featureFlag?: string
}

const S3_LINK_FLAG = 'showCloudfrontLinkOnSheetManagement'

const FIELD_TYPES: FieldType[] = [
  {
    value: 'string',
    tooltip: 'The string field type is for text fields.'
  },
  {
    value: 'number',
    tooltip: 'The number field type is for numerical fields, including decimals.'
  },
  {
    value: 'integer',
    tooltip: 'The integer field type is for whole numbers.'
  },
  {
    value: 'boolean',
    tooltip: 'The boolean field type is for true/false fields.'
  },
  {
    value: 'csv',
    tooltip: 'The csv field type is for comma-separated values.'
  },
  {
    value: 'date',
    tooltip: 'The date field type is for date fields.'
  },
  {
    value: 'location',
    tooltip: 'The location field type is for latitude and longitude fields.'
  },
  {
    value: 's3 Link',
    tooltip: "The S3 link type is only for URLs sourced from AWS (URLs containing amazonaws.com). For any other links, please use the string field type.",
    featureFlag: S3_LINK_FLAG,
  }
]

export default FIELD_TYPES
