const MBM_ACCOUNTS_ARRAY: string[] = [
  'lbm',
  'obm',
  'robm',
]

export const ACCOUNTS: Set<string> = new Set([
  'apm',
  'pathways',
  'payer',
  'provider',
  ...MBM_ACCOUNTS_ARRAY
])

export const MBM_ACCOUNTS: Set<string> = new Set(MBM_ACCOUNTS_ARRAY)
