import { toast as reactToast } from 'react-toastify'

const OPTIONS = {
  icon: true,
  style: {
    backgroundColor: '#0E2539',
    borderRadius: '8px',
    width: '500px'
  }
}

export const toast = {
  error: (message: string) => reactToast.error(
    message,
    {
      theme: 'dark',
      ...OPTIONS
    }
  ),
  success: (message: string) => reactToast.success(
    message,
    {
      theme: 'dark',
      ...OPTIONS
    }
  )
}
