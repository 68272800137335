import MapParams from '../interfaces/MapParams.interface'

const getOptionsMap = ({ data, loading }: MapParams) => {
  const options: any = {}
  if (!loading) {
    data.forEach((datum: any) => (options[datum.value] = datum.display_name))
  }
  return options
}

export default getOptionsMap
