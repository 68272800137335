import React from 'react'

import { LabelCheckbox } from 'frontend/components'

import { Label, Wrapper } from './shared'

interface Props {
  onChange: any
}

const LABEL_TEXT = 'Email Subscriptions'
const CHECKBOX_TEXT = 'Pathways Monthly Alert'
const CHECKBOX_NAME = 'emailSubscriptions'
const PATHWAYS_ALERT_OBJ = {
  _id: 'c89a5624-c3e1-45fd-8e7f-615256f3b2f2',
  type: 'Pathways Monthly Alert',
}

export const EmailSubscriptions = ({ onChange }: Props) => {
  const toggleCheckbox = ({ target: { checked, name } }: any) => {
    const value = []
    if (checked) value.push(PATHWAYS_ALERT_OBJ)
    onChange({ name, value })
  }

  return (
    <Wrapper>
      <Label>{LABEL_TEXT}</Label>
      <LabelCheckbox
        label={CHECKBOX_TEXT}
        onChange={toggleCheckbox}
        name={CHECKBOX_NAME}
      />
    </Wrapper>
  )
}
