import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import queryString from 'query-string'
import Select from 'react-select'
import styled from '@emotion/styled'
import _ from 'lodash'

import { Button } from '@pulse-analytics/pulse-design-system'

import {
  UPDATE_VEGA_TEAM_REGION,
  DROP_AND_CREATE_TEAM_REGION_STATES,
} from 'frontend/api/mutations'
import {
  GET_TEAMS_TEAMS_REGIONS_STATES,
  GET_VEGA_TEAMS_REGIONS,
  GET_VEGA_STATES,
} from 'frontend/api/queries'

import Spinner from 'frontend/components/Spinner'
import Input from 'frontend/components/Input'
import Spacing from 'frontend/utils/spacing'

import { FormLabel } from './utils'

const Column = styled.section({
  display: 'flex',
  flexDirection: 'column',
  padding: Spacing.S4,
})

const InputSection = styled.section({
  padding: `${Spacing.S4} 0`,
})

const UpdateRegionColumn = () => {
  const location = useLocation()
  const { clientTeamId } = useParams()
  const [stagedName, setStagedName] = useState('')
  const [stagedStates, setStagedStates] = useState([])
  const [statesOptions, setStatesOptions] = useState([])

  const teamRegionId =
    (location.search &&
      queryString.parse(location.search) &&
      queryString.parse(location.search).teamRegionId) ||
    ''

  const {
    data: teamRegionData,
    loading: teamRegionLoading,
  } = useQuery(GET_VEGA_TEAMS_REGIONS, { variables: { teamRegionId } })
  const { data: statesData, loading: statesLoading } = useQuery(GET_VEGA_STATES)
  const {
    data: teamsTeamsRegionsStatesData,
    loading: teamsTeamsRegionsStatesLoading,
  } = useQuery(GET_TEAMS_TEAMS_REGIONS_STATES, { variables: { clientTeamId } })

  /*
    useEffect for setting the team region name on team region change or query load.
  */
  useEffect(() => {
    const newStagedName = getNewStagedName(
      teamRegionId,
      teamRegionLoading,
      teamRegionData
    )
    setStagedName(newStagedName)
  }, [teamRegionLoading, teamRegionId])

  /*
    useEffect for setting the current team region states on team region change or query load.
  */
  useEffect(() => {
    const currentStates = getCurrentStates(
      teamsTeamsRegionsStatesLoading,
      teamsTeamsRegionsStatesData,
      teamRegionId
    )
    setStagedStates(currentStates)
  }, [teamsTeamsRegionsStatesLoading, teamRegionId])

  /*
    useEffect for setting the current team region states options on
    team region change, data change, or query load.
    Filters states currently selected across a team's regions.
  */
  useEffect(() => {
    const options = getStatesOptions(
      statesLoading,
      teamsTeamsRegionsStatesLoading,
      teamsTeamsRegionsStatesData,
      statesData
    )
    setStatesOptions(options)
  }, [
    statesLoading,
    teamsTeamsRegionsStatesData,
    teamsTeamsRegionsStatesLoading,
    teamRegionId,
  ])

  const [updateRegion, { loading: updateRegionLoading }] = useMutation(
    UPDATE_VEGA_TEAM_REGION,
    {
      variables: {
        input: {
          id: teamRegionId,
          name: stagedName,
        },
      },
      onError: alert,
    }
  )

  const [
    updateTeamsTeamsRegionsStates,
    { loading: updateTeamsTeamsRegionsStatesLoading },
  ] = useMutation(DROP_AND_CREATE_TEAM_REGION_STATES, {
    variables: {
      input: {
        team_id: clientTeamId,
        team_region_id: teamRegionId,
        teams_teams_regions_states: stagedStates.map(({ value }) => ({
          team_id: clientTeamId,
          team_region_id: teamRegionId,
          state_id: value,
        })),
      },
    },
    update: (cache, { data: { dropAndCreateTeamRegionStates } }) => {
      cache.writeQuery({
        query: GET_TEAMS_TEAMS_REGIONS_STATES,
        variables: { clientTeamId },
        data: { vegaTeamsTeamsRegionsStates: dropAndCreateTeamRegionStates },
      })
    },
    onError: alert,
  })

  const handleNameChange = ({ value }) => {
    setStagedName(value)
  }

  const handleStatesChange = (value) => {
    value = value || []
    setStagedStates(value)
  }

  const handleUpdate = (e) => {
    e.preventDefault()
    updateRegion()
    updateTeamsTeamsRegionsStates()
  }

  const buttonContent =
    updateRegionLoading || updateTeamsTeamsRegionsStatesLoading ? (
      <Spinner />
    ) : (
      'Save Region'
    )

  const isButtonAvailable =
    teamRegionId &&
    !teamRegionLoading &&
    !statesLoading &&
    !teamsTeamsRegionsStatesLoading

  return (
    <Column>
      <form style={{ padding: Spacing.S4 }}>
        <InputSection>
          <FormLabel>Region Name (required)</FormLabel>
          <Input
            name="name"
            type="text"
            onChange={handleNameChange}
            value={stagedName}
            disabled={!teamRegionId || teamRegionLoading}
          />
        </InputSection>
        <InputSection>
          <FormLabel>States</FormLabel>
          <Select
            isMulti
            onChange={handleStatesChange}
            value={stagedStates}
            options={statesOptions}
            isDisabled={
              !teamRegionId || statesLoading || teamsTeamsRegionsStatesLoading
            }
          />
        </InputSection>
      </form>
      <section style={{ padding: Spacing.S4 }}>
        {isButtonAvailable && (
          <Button
            type="secondary"
            onClick={handleUpdate}
            style={{ padding: '6px 12px' }}
          >
            {buttonContent}
          </Button>
        )}
      </section>
    </Column>
  )
}

export default UpdateRegionColumn

function getNewStagedName(teamRegionId, teamRegionLoading, teamRegionData) {
  let newStagedName = ''

  if (teamRegionId && !teamRegionLoading) {
    newStagedName = teamRegionData.vegaTeamsRegions[0].name
  }

  return newStagedName
}

function getCurrentStates(
  teamsTeamsRegionsStatesLoading,
  teamsTeamsRegionsStatesData,
  teamRegionId
) {
  let currentStates = []

  if (!teamsTeamsRegionsStatesLoading) {
    currentStates = teamsTeamsRegionsStatesData.vegaTeamsTeamsRegionsStates.filter(
      ({ team_region }) => team_region === teamRegionId
    )
    currentStates = currentStates.map(({ state: { id, abbreviation } }) => ({
      label: abbreviation,
      value: id,
    }))
  }

  currentStates = _.sortBy(currentStates, 'label')

  return currentStates
}

function getStatesOptions(
  statesLoading,
  teamsTeamsRegionsStatesLoading,
  teamsTeamsRegionsStatesData,
  statesData
) {
  let options = []

  if (!statesLoading && !teamsTeamsRegionsStatesLoading) {
    const teamsTeamsRegionsStatesArr = teamsTeamsRegionsStatesData.vegaTeamsTeamsRegionsStates.map(
      ({ state: { id } }) => id
    )
    const teamsTeamsRegionsStatesSet = new Set(teamsTeamsRegionsStatesArr)

    options = statesData.vegaStates.filter(
      ({ id }) => !teamsTeamsRegionsStatesSet.has(id)
    )
    options = options.map(({ id, abbreviation }) => ({
      label: abbreviation,
      value: id,
    }))
  }

  options = _.sortBy(options, 'label')

  return options
}
