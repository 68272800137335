import { EXPAND } from 'frontend/components/DataTable/Cells/cellTypes'

import MultiSelectColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/MultiSelectColumnFilter'
import customMultiSelectFilterFn from 'frontend/components/Table/custom-filters/MultiSelect/customMultiSelectFilterFn'

import MultiSelectDateColumnFilter from 'frontend/components/Table/custom-filters/MultiSelect/DateFilter/MultiSelectDateColumnFilter'
import customMultiSelectDateFilterFn from 'frontend/components/Table/custom-filters/MultiSelect/DateFilter/customMultiSelectDateFilterFn'

import { formatMonthDayYearSlash } from 'frontend/utils/formatDate'

const getFdaApprovalsColumns = ({ onRowClick }) => {
  return [
    {
      Header: 'Indication',
      accessor: 'indication',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
    {
      Header: 'Regimen',
      accessor: 'regimen',
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
    {
      Header: 'Populations',
      accessor: 'populations',
      /*
            ! This filter is not currently working as intended. We need to build a custom filterType
            ! for react-table that can generate filter options from array values.
        */
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
    {
      Header: 'Lines of Therapy',
      accessor: 'lines_of_therapy',
      /*
            ! This filter is not currently working as intended. We need to build a custom filterType
            ! for react-table that can generate filter options from array values.
        */
      Filter: MultiSelectColumnFilter,
      filter: customMultiSelectFilterFn,
      sortType: 'text',
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
    },
    {
      Header: 'Approval Date',
      accessor: 'approval_date',
      Filter: (...args) =>
        MultiSelectDateColumnFilter(args, formatMonthDayYearSlash),
      filter: customMultiSelectDateFilterFn,
      cellConfig: {
        type: EXPAND,
        onEvent: onRowClick,
      },
      sortType: 'date',
      cellFormatter: formatMonthDayYearSlash,
    },
    {
      Header: 'Date Created',
      accessor: 'created_at',
      Filter: (...args) =>
        MultiSelectDateColumnFilter(args, formatMonthDayYearSlash),
      filter: customMultiSelectDateFilterFn,
      sortType: 'date',
      cellFormatter: formatMonthDayYearSlash,
    },
    {
      Header: 'Date Updated',
      accessor: 'updated_at',
      Filter: (...args) =>
        MultiSelectDateColumnFilter(args, formatMonthDayYearSlash),
      filter: customMultiSelectDateFilterFn,
      sortType: 'date',
      cellFormatter: formatMonthDayYearSlash,
    },
  ]
}

export default getFdaApprovalsColumns
