import React from 'react'

import { useQueryString } from 'frontend/hooks'

import {
  CreateClientSidePanel,
  CreateTeamSidePanel,
  CreateUserSidePanel,
  EditClientSidePanel,
  EditTeamSidePanel,
  EditUserSidePanel,
} from './panels'

const QUERY_STRING_TO_SIDE_PANEL: any = {
  createClient: <CreateClientSidePanel />,
  editClient: <EditClientSidePanel />,
  createTeam: <CreateTeamSidePanel />,
  editTeam: <EditTeamSidePanel />,
  createUser: <CreateUserSidePanel />,
  editUser: <EditUserSidePanel />,
}

const SidePanel = () => {
  const {
    sidePanel: selectedSidePanel,
  }: { sidePanel?: string } = useQueryString()

  if (!selectedSidePanel) return null

  const sidePanel = QUERY_STRING_TO_SIDE_PANEL[selectedSidePanel]

  return sidePanel
}

export default SidePanel
