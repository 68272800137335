import { concatPersonName } from "frontend/utils"

interface GetData {
  isLoading: boolean
  Person: any
  Panel: any
  VotingMember: any
}

export const getFormData = ({
  isLoading,
  Person,
  Panel,
  VotingMember,
}: GetData) => {
  return isLoading
    ? {}
    : {
      id: VotingMember.data[0].id,
      member_name: concatPersonName(Person.data[0]),
      panel_name: Panel.data[0].name,
      start_date: VotingMember.data[0].start_date,
      end_date: VotingMember.data[0].end_date,
    }
}
