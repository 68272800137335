import React from 'react'
import format from 'date-fns/format'

import { useRewriteSearchString } from 'frontend/hooks'
import ImportPolicyPdfModal from '../../ImportPolicyPdfModal'
import { TopContainer } from './TopContainer'
import { BottomContainer } from './BottomContainer'

const formatDate = (date) => format(new Date(date), 'PP')

const PolicyModalHeader = ({
  data,
  closeModal,
  handleUpdate,
  setPolicyContentInput,
}) => {
  const rewriteSearchString = useRewriteSearchString()

  const setShowModalSearchString = (shouldShow) => {
    const args = {
      shouldShow,
    }
    rewriteSearchString(args)
  }

  const onButtonClick = () => {
    setShowModalSearchString(true)
  }

  const {
    last_approved_change_date,
    last_identified_change_date,
    policy_doc_link,
    policy_doc_link_change,
    policy_doc_link_change_source,
  } = data

  const lastApprovedDate = last_approved_change_date
    ? formatDate(last_approved_change_date)
    : 'N/A'

  return (
    <div>
      <TopContainer
        onClick={onButtonClick}
        closeModal={closeModal}
        handleUpdate={handleUpdate}
      />
      <BottomContainer
        lastIdentifiedChangeDate={last_identified_change_date}
        policyDocLinkChange={policy_doc_link_change}
        policyDocLinkChangeSource={policy_doc_link_change_source}
        policyDocLink={policy_doc_link}
        lastApprovedDate={lastApprovedDate}
      />
      <ImportPolicyPdfModal
        policyId={data.policyId}
        productId={data.product}
        productPolicyId={data.productId}
        setShowModalSearchString={setShowModalSearchString}
        setPolicyContentInput={setPolicyContentInput}
      />
    </div>
  )
}

export default PolicyModalHeader
