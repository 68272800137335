import React, { useMemo, useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import {
  useTable,
  usePagination,
  useSortBy,
  useBlockLayout,
  useFilters,
} from 'react-table'
import { useSticky } from 'react-table-sticky'
import _ from 'lodash'

import { useKeyPressPositionRef } from 'frontend/hooks'

import Spinner from 'frontend/components/Spinner.js'

import Styles from './Styles'
import TableHeader from './TableHeader'
import ColumnHeaders from './ColumnHeaders'
import CreateRow from './CreateRow'
import Rows from './Rows'
import CellContainer from './Cells/CellContainer'
import { cellTypes } from './Cells/cellTypes'

import sortTypes from './custom-sort-types'

const INITIAL_PAGE_SIZE = 50

const SpinnerContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

const DEFAULT_COLUMN = {
  Filter: DefaultColumnFilter,
  Cell: CellContainer,
}

const onPositionRefChange = ({ current: positionRefCurrent }) => {
  const element = document.getElementById(positionRefCurrent)
  if (element) {
    element.focus()
  }
}

const DataTable = ({
  data,
  columns,
  hasCreate,
  hasExport,
  createConfig,
  hasEdit,
  editConfig,
  exportConfig,
  isLoading,
}) => {
  const [isCreateActive, setIsCreateActive] = useState(false)

  const shouldStubData = hasCreate && _.isEmpty(data)

  useEffect(() => {
    if (shouldStubData) data = [{}]
  })

  // ! you *cannot* use the Cell renderer override.
  // ! to ensure all table features work correctly,
  // ! use of the cell types defined in ./Cells/cellTypes
  const columnsWithCells = useMemo(() => {
    return columns.map((column) => {
      const columnWithCell = {
        ...column,
        Cell: CellContainer,
      }

      return columnWithCell
    })
  }, [columns])

  if (!data) data = []

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    state: { pageIndex, pageSize },
    ...tablePropOverflow
  } = useTable(
    {
      columns: columnsWithCells,
      data,
      defaultColumn: DEFAULT_COLUMN,
      maxMultiSortColCount: 5,
      disableMultiRemove: true,
      sortTypes,
      initialState: { pageSize: INITIAL_PAGE_SIZE, pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    useSticky,
    usePagination,
    useBlockLayout
  )

  const createRowInputRef = useRef({})

  const maxRows = page.length - 1
  const maxColumns =
    headerGroups.reduce((acc, { headers }) => (headers.length += acc), 0) - 1

  const positionRefConfig = {
    maxRows,
    maxColumns,
  }
  const positionRefOptions = {
    onRefChange: onPositionRefChange,
  }

  const positionRef = useKeyPressPositionRef(
    positionRefConfig,
    positionRefOptions
  )

  return (
    <Styles>
      <TableHeader
        hasCreate={hasCreate}
        hasExport={hasExport}
        isCreateActive={isCreateActive}
        data={data}
        rows={rows}
        createConfig={createConfig}
        setIsCreateActive={setIsCreateActive}
        createRowInputRef={createRowInputRef}
        pageIndex={pageIndex}
        pageSize={pageSize}
        tablePropOverflow={tablePropOverflow}
        exportConfig={exportConfig}
      />
      <div {...getTableProps()} className="table sticky">
        <div className="header">
          <ColumnHeaders headerGroups={headerGroups} />
          {isCreateActive && (
            <CreateRow
              rows={rows}
              prepareRow={prepareRow}
              createRowInputRef={createRowInputRef}
              hasEdit={hasEdit}
              editConfig={editConfig}
            />
          )}
        </div>
        {isLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          <div {...getTableBodyProps()} className="body">
            <Rows
              hasEdit={hasEdit}
              page={shouldStubData ? [] : page}
              prepareRow={prepareRow}
              positionRef={positionRef}
              editConfig={editConfig}
            />
          </div>
        )}
      </div>
    </Styles>
  )
}

DataTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
      Filter: PropTypes.func,
      filter: PropTypes.func,
      sortType: PropTypes.text,
      sticky: PropTypes.oneOf(['left', 'right']),
      cellFormatter: PropTypes.func, // can only be used by cell components with a base of DefaultCell
      cellConfig: PropTypes.shape({
        type: PropTypes.oneOf(Object.keys(cellTypes)),
        onEvent: PropTypes.func,
        validationConfig: PropTypes.shape({
          inputType: PropTypes.oneOf(['date', 'text', 'number']),
          onValidate: PropTypes.func,
          errorMessage: PropTypes.string,
        }),
      }),
    })
  ).isRequired,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasExport: PropTypes.bool,
  createConfig: PropTypes.shape({
    createRowSubmitHandler: PropTypes.func.isRequired,
  }),
  editConfig: PropTypes.shape({
    accessor: PropTypes.string.isRequired,
  }),
  exportConfig: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    formatRows: PropTypes.func.isRequired,
  }),
}

DataTable.defaultProps = {
  hasCreate: false,
  hasEdit: false,
  hasExport: false,
}

export default DataTable
