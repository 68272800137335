import React from 'react'
import styled from '@emotion/styled'

import { DeleteButton } from '../../../../../../shared/DeleteButton'
import { EditButton } from '../../../../../../shared/EditButton'
import { Teams } from './Teams'
import { Username } from './Username'

interface Props {
  isHoveredListItem: boolean
  isSelected: boolean
  data: any
}

const ENTITY = 'user'
const QUERY_STRING_VALUE = 'editUser'

const Buttons = styled.div({
  display: 'flex',
  padding: '4px 4px 0 0',
})

const TopWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const UsernameWrapper = styled.div({
  padding: '12px 0 0 16px',
})

const ContentWrapper = ({ isHoveredListItem, isSelected, data }: Props) => {
  return (
    <>
      <TopWrapper>
        <UsernameWrapper>
          <Username>{data.username}</Username>
        </UsernameWrapper>
        <Buttons>
          <EditButton
            isHoveredListItem={isHoveredListItem}
            isSelected={isSelected}
            datumId={data.id}
            entity={ENTITY}
            queryStringValue={QUERY_STRING_VALUE}
          />
          <DeleteButton
            isHoveredListItem={isHoveredListItem}
            isSelected={isSelected}
            datumId={data.id}
            entity={ENTITY}
          />
        </Buttons>
      </TopWrapper>
      <Teams teams={data.teams} />
    </>
  )
}

export default ContentWrapper
