import useRead from './useRead'
import useCreate from './useCreate'
import useDelete from './useDelete'
import useUpdate from './useUpdate'
import { useAuth0 } from 'react-auth0-spa'

export const usePolarisUsers = () => {
  const { user } = useAuth0()

  const { data: polarisUsersData, loading: isLoading } = useRead()
  const [create, { loading: isCreating }] = useCreate()
  const [destroy, { loading: isDeleting }] = useDelete()
  const [update, { loading: isUpdating }] = useUpdate()

  const data = polarisUsersData?.polarisUsers || []
  const currentUser = data.find(({ user_id }: any) => user_id === user?.sub)

  return {
    currentUser,
    data,
    isLoading,
    create,
    isCreating,
    delete: destroy,
    isDeleting,
    isUpdating,
    update,
  }
}
