import React from 'react'
import styled from '@emotion/styled'

interface Props {
  header: string
}

const Wrapper = styled.div({
  padding: '10px 12px',
})

const Title = styled.h1({
  fontSize: 20,
  fontWeight: 700,
})

export const Header = ({ header }: Props) => {
  return (
    <Wrapper>
      <Title>{header}</Title>
    </Wrapper>
  )
}
