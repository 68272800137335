import gql from 'graphql-tag'

const DELETE_PROVIDER_KEY_DECISION_MAKER = gql`
  mutation deleteProviderKeyDecisionMaker(
    $input: DeleteProviderKeyDecisionMakerInput!
  ) {
    deleteProviderKeyDecisionMaker(input: $input) {
      id
      person
      provider_organization
      indications
      key_decision_maker_types
      is_excluded
      is_excluded_from_kdm_tool
      title
      specialty
      tdg_internal_note
      start_date
      end_date
      created_at
      updated_at
    }
  }
`

export default DELETE_PROVIDER_KEY_DECISION_MAKER
