import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import OrionSwitch from 'frontend/Orion/shared/OrionSwitch'

import FontSpace from 'frontend/utils/fontspace'
import Color from 'frontend/utils/color'

const ALPHA_GREEN = transparentize(0.85, Color.GREEN)

const ListItem = styled.button(
  {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    padding: 12,
    width: '100%',
    cursor: 'pointer',
  },
  ({ isSelected }) => ({
    backgroundColor: isSelected ? ALPHA_GREEN : Color.WHITE,
  })
)

const ListItemValue = styled.p(
  {
    fontWeight: 600,
    ...FontSpace.FS2,
  },
  ({ isSelected }) => ({
    color: isSelected ? Color.GREEN : Color.GRAY_DARK,
  })
)

const SwitchListItem = ({
  onClick,
  handleToggle,
  value,
  label,
  isDisabled,
  isChecked,
  isSelected,
}) => {
  return (
    <ListItem onClick={() => onClick({ label, value })} isSelected={isSelected}>
      <OrionSwitch
        isDisabled={isDisabled}
        handleToggle={handleToggle}
        isChecked={isChecked}
        value={value}
      />
      <ListItemValue isSelected={isSelected}>{label}</ListItemValue>
    </ListItem>
  )
}

SwitchListItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default SwitchListItem
