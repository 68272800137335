import {
  PAYER,
  PRODUCT,
  BOOK,
  BENEFIT_TYPE,
  DOC_TYPE,
  PRODUCT_PAYER_POLICY_TRACKING_PROJECTS,
  LINK_TYPE,
  LINK,
  LINK_STEPS,
  POLICY_SEARCH_WORD,
  LINK_NOTE,
} from '../../../utils/enums'

export const DEFAULT_FORM_INPUT = {
  // * payer policy
  [PAYER]: '',
  [BOOK]: '',
  [BENEFIT_TYPE]: '',
  [DOC_TYPE]: '',
  [LINK]: null,
  [LINK_TYPE]: undefined,
  // * product policy
  [PRODUCT]: '',
  [LINK_STEPS]: '',
  [POLICY_SEARCH_WORD]: '',
  [LINK_NOTE]: '',
  [PRODUCT_PAYER_POLICY_TRACKING_PROJECTS]: [],
}


export const MODAL_TITLE = 'Add Policy Link'
export const MODAL_SUBHEADER = 'Add new link to be automatically tracked'
