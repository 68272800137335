import React from 'react'
import styled from '@emotion/styled'

import TeamsListPanel from './TeamsListPanel'
import TeamsPanelHeader from './TeamsPanelHeader'

const Wrapper = styled.div({
  flex: 1,
})

const TeamsPanel = () => {
  return (
    <Wrapper>
      <TeamsPanelHeader />
      <TeamsListPanel />
    </Wrapper>
  )
}

export default TeamsPanel
