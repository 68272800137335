import React, { useState } from 'react'

import { FieldProps } from './constants/interfaces'
import { FormLabel, FieldWrapper } from './styled-components'

import INPUT_TYPE_TO_COMPONENT from './constants/input-type-to-component-map'
import RequiredTag from './RequiredTag'

const Field = ({ datum, fieldConfig, setFormState }: FieldProps) => {
  const initialValue = datum[fieldConfig.accessor] ?? fieldConfig.defaultValue
  const [value, setValue] = useState(initialValue)

  const Component = INPUT_TYPE_TO_COMPONENT[fieldConfig.inputType]

  const handleChange = ({ name, value }: any) => {
    if (fieldConfig.isDisabled) return
    setValue(value)
    setFormState({ name, value })
  }

  return (
    <FieldWrapper>
      <FormLabel>
        {fieldConfig.label} <RequiredTag isRequired={fieldConfig.isRequired} />
      </FormLabel>
      <Component
        value={value}
        type={fieldConfig.inputType}
        name={fieldConfig.accessor}
        onChange={handleChange}
        options={fieldConfig.options}
        isRequired={fieldConfig.isRequired}
        disabled={fieldConfig.isDisabled}
        style={fieldConfig.style}
        placeholder={fieldConfig.placeholder}
        {...fieldConfig.validation}
      />
    </FieldWrapper>
  )
}

export default Field
