import React from 'react'

import { useEmberUser } from 'frontend/entity-hooks/ember'
import { useQueryString } from 'frontend/hooks'

import UsersListPanel from './UsersListPanel'
import UsersLoadingPanel from './UsersLoadingPanel'
import { useQueryInput } from '../useQueryInput'

const UsersListPanelInnerContainer = () => {
  const queryInput: any = useQueryInput()
  const { team } = useQueryString()

  const { isLoading: usersLoading } = useEmberUser({
    queryInput: queryInput,
  })

  if (usersLoading || !team) return <UsersLoadingPanel />

  return <UsersListPanel />
}

export default UsersListPanelInnerContainer
