import { transparentize } from 'polished'

export const Colors = {
  // Base Colors
  PRIMARY: '#0668D9',
  BLACK: '#0E2539',
  WHITE: '#FFFFFF',
  NAVY: '#153B61',

  // Neutrals
  GRAY_600: '#475566',
  GRAY_500: '#636E7D',
  GRAY_400: '#808A96',
  GRAY_300: '#BBBFC3',
  GRAY_200: '#DEE0E4',
  GRAY_100: '#F1F2F3',
  GRAY_50: '#F8F9FA',
  COOL_GRAY_600: '#455C75',
  COOL_GRAY_500: '#798EA3',
  COOL_GRAY_400: '#A7B4C2',
  COOL_GRAY_300: '#D2DDEB',
  COOL_GRAY_200: '#E4ECF6',
  COOL_GRAY_100: '#F1F5FA',
  COOL_GRAY_50: '#F8F9FD',

  // New Extended Colors
  BLUE_900: '#003C84',
  BLUE_800: '#004BA6',
  BLUE_700: '#0056BE',
  BLUE_600: '#1870DA',
  BLUE_500: '#4493F2',
  BLUE_400: '#80B9FF',
  BLUE_300: '#B6D7FF',
  BLUE_200: '#D0E5FF',
  BLUE_100: '#EBF4FF',

  TEAL_900: '#09677A',
  TEAL_800: '#0B849D',
  TEAL_700: '#0EA0B6',
  TEAL_600: '#0FB6CA',
  TEAL_500: '#34C5D5',
  TEAL_400: '#63D3DF',
  TEAL_300: '#89DFE8',
  TEAL_200: '#B7ECF3',
  TEAL_100: '#ECFCFE',

  GREEN_900: '#01632F',
  GREEN_800: '#0B7F41',
  GREEN_700: '#12934E',
  GREEN_600: '#1AA95D',
  GREEN_500: '#22BF6B',
  GREEN_400: '#52CE8C',
  GREEN_300: '#82DCAC',
  GREEN_200: '#B0EACB',
  GREEN_100: '#EEFCF4',

  LIME_900: '#4C6127',
  LIME_800: '#617C32',
  LIME_700: '#6D953C',
  LIME_600: '#82B248',
  LIME_500: '#99C95F',
  LIME_400: '#A1DB58',
  LIME_300: '#B9E481',
  LIME_200: '#DDF1BB',
  LIME_100: '#F6FBEE',

  YELLOW_900: '#997A1F',
  YELLOW_800: '#B99A1D',
  YELLOW_700: '#D2B01F',
  YELLOW_600: '#E8C621',
  YELLOW_500: '#FFDB1D',
  YELLOW_400: '#FFE450',
  YELLOW_300: '#FFEB7E',
  YELLOW_200: '#FFF1A0',
  YELLOW_100: '#FFFAE0',

  ORANGE_900: '#8D4201',
  ORANGE_800: '#BC580C',
  ORANGE_700: '#E06815',
  ORANGE_600: '#FE7D1A',
  ORANGE_500: '#FF9442',
  ORANGE_400: '#FFAE70',
  ORANGE_300: '#FFBA90',
  ORANGE_200: '#FFD3BA',
  ORANGE_100: '#FFECDF',

  RED_900: '#8C2516',
  RED_800: '#AF3528',
  RED_700: '#CE4131',
  RED_600: '#E65242',
  RED_500: '#F66B5C',
  RED_400: '#F89286',
  RED_300: '#FBB9B1',
  RED_200: '#FDDAD8',
  RED_100: '#FCEEED',

  PINK_900: '#9E5390',
  PINK_800: '#BC63AB',
  PINK_700: '#D36FC0',
  PINK_600: '#E377D0',
  PINK_500: '#F781E1',
  PINK_400: '#F999E7',
  PINK_300: '#FBB1ED',
  PINK_200: '#FCCAF3',
  PINK_100: '#FEE7F9',

  PURPLE_900: '#442F9E',
  PURPLE_800: '#5842B3',
  PURPLE_700: '#725DD0',
  PURPLE_600: '#836DE1',
  PURPLE_500: '#9781F7',
  PURPLE_400: '#AC9BF9',
  PURPLE_300: '#C3B7FB',
  PURPLE_200: '#DAD2FC',
  PURPLE_100: '#EFECFD',

  // Old Extended Colors
  GREEN: '#22BF6B',
  RED: '#EE5340',
  BLUE: '#0668D9',
  ORANGE: '#FD9651',
  YELLOW: '#FFDC6C',
  PURPLE: '#7C5EFA',
  LIGHT_GRAY_1: '#DFE3EA',
  LIGHT_BLUE_GRAY_2: '#EEF4FA',
  MEDIUM_GRAY_2: '#A3BBDA',
  TOOL_SIDEBAR: '#093357',
  PHOENIX: '#FA8969',
  ORION: '#38B59A',
  DELPHI: '#30B2DC',
  APP_BACKGROUND: '#E8EBEC',
}

export const AlphaColors = {
  Black90: transparentize(0.1, Colors.BLACK),
  Black80: transparentize(0.2, Colors.BLACK),
  Black70: transparentize(0.3, Colors.BLACK),
  Black60: transparentize(0.4, Colors.BLACK),
  Black50: transparentize(0.5, Colors.BLACK),
  Black40: transparentize(0.6, Colors.BLACK),
  Black30: transparentize(0.7, Colors.BLACK),
  Black25: transparentize(0.75, Colors.BLACK),
  Black20: transparentize(0.8, Colors.BLACK),
  Black15: transparentize(0.85, Colors.BLACK),
  Black10: transparentize(0.9, Colors.BLACK),
  Black8: transparentize(0.92, Colors.BLACK),
  Black7: transparentize(0.93, Colors.BLACK),
  Black5: transparentize(0.95, Colors.BLACK),
  Blue90: transparentize(0.1, Colors.BLUE),
  Blue80: transparentize(0.2, Colors.BLUE),
  Blue70: transparentize(0.3, Colors.BLUE),
  Blue60: transparentize(0.4, Colors.BLUE),
  Blue50: transparentize(0.5, Colors.BLUE),
  Blue40: transparentize(0.6, Colors.BLUE),
  Blue30: transparentize(0.7, Colors.BLUE),
  Blue20: transparentize(0.8, Colors.BLUE),
  Blue15: transparentize(0.85, Colors.BLUE),
  Blue10: transparentize(0.9, Colors.BLUE),
  White10: transparentize(0.9, Colors.WHITE),
  White20: transparentize(0.8, Colors.WHITE),
  White30: transparentize(0.7, Colors.WHITE),
  White40: transparentize(0.6, Colors.WHITE),
  White50: transparentize(0.5, Colors.WHITE),
  White60: transparentize(0.4, Colors.WHITE),
  White70: transparentize(0.3, Colors.WHITE),
  White80: transparentize(0.2, Colors.WHITE),
  White90: transparentize(0.1, Colors.WHITE),
}

export const mediaQueries = {
  tablet: '@media only screen and (max-width: 1030px)',
  phone: '@media only screen and (max-width: 500px)',
}

export const Transitions = {
  NORMAL: '250ms ease',
}

export const ZIndexes = {
  MODAL: 1000,
  PANEL_HEADER: 100,
  MENU: 10,
  QUERY_CONTROLS: 1000,
}

export const Spacing = {
  MICRO: '2px',
  TINY: '4px',
  SMALL: '8px',
  NORMAL: '12px',
  MEDIUM: '16px',
  LARGE: '20px',
  EXTRA_LARGE: '24px',
  HUGE: '32px',
  MAX: '48px',
  TOOL_SIDEBAR: '256px',
}

export const FontFamily = {
  NORMAL: 'Inter',
}
