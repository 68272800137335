import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import ImportExportData from './ImportExportData'
import Organizations from './Organizations'
import GeneralData from './GeneralData'
import Sidebar from './Sidebar'
import SpecializedData from './SpecializedData'
import Administrator from './Administrator'

const Orion = () => (
  <div style={{ display: 'flex', flex: 1 }}>
    <Sidebar />
    <Switch>
      <Route path="/orion/import-export" component={ImportExportData} />
      <Route path="/orion/organizations" component={Organizations} />
      <Route path="/orion/general" component={GeneralData} />
      <Route path="/orion/specialized" component={SpecializedData} />
      <Route path="/orion/administrator" component={Administrator} />
      <Redirect to={'/orion/import-export'} />
    </Switch>
  </div>
)

export default Orion
